import { AppIcon } from "..";
import styles from "./styles.module.css";

const TextOrIcon = ({ text, isIcon }: { text: any; isIcon?: boolean }) => {
  if (!isIcon) {
    return <>{text}</>;
  } else {
    return <AppIcon size="sm" icon={text} className={styles.icon} />;
  }
};

export function AppToggleIconTextButton({
  checked,
  onClick,
  leftText,
  rightText,
  disabled,
  isIcon,
}: {
  checked: boolean;
  onClick: () => void;
  leftText: any;
  rightText: any;
  disabled?: boolean;
  isIcon?: boolean;
}) {
  return (
    <label>
      <input
        type="checkbox"
        className={styles.input}
        checked={checked}
        onChange={onClick}
        disabled={disabled}
      />
      <div className={`${styles.wrapper} ${styles.label}`}>
        <div>
          <TextOrIcon text={leftText} isIcon={isIcon} />
        </div>
        <div className={styles.divider} />
        <div>
          <TextOrIcon text={rightText} isIcon={isIcon} />
        </div>
        <div className={`${styles.slider} ${styles.label}`}>
          {checked ? (
            <TextOrIcon text={rightText} isIcon={isIcon} />
          ) : (
            <TextOrIcon text={leftText} isIcon={isIcon} />
          )}
        </div>
      </div>
    </label>
  );
}
