import { useState } from "react";
import checkmarkBlackIcon from "../../../assets/svgs/icons/noPadding/icon_check_active.svg";
import unCheckmarkIcon from "../../../assets/svgs/icons/noPadding/icon_check_circle_idle.svg";
import styles from "./appCheckbox.module.css";

let checkBoxTimeout: any = null;

export function AppCheckbox({
  label,
  checked,
  onClick,
  disabled,
  size = "normal",
}: {
  label?: string;
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
  size?: "normal" | "small";
}) {
  const [status, setStatus] = useState({
    checking: false,
    unchecking: false,
  });

  const preClick = () => {
    if (!disabled) {
      clearTimeout(checkBoxTimeout);
      if (checked) {
        setStatus({
          checking: false,
          unchecking: true,
        });
      } else {
        setStatus({
          checking: true,
          unchecking: false,
        });
      }
      checkBoxTimeout = setTimeout(() => {
        setStatus({
          checking: false,
          unchecking: false,
        });
      }, 400);
      onClick && onClick();
    }
  };

  return (
    <label className={`${styles.root} ${styles.label}`}>
      <input
        type="checkbox"
        checked={checked}
        className={styles.input}
        disabled={disabled}
        readOnly
      />
      <div
        className={` ${size === "small" ? styles.small : ""}`}
        onClick={() => {
          preClick();
        }}
        aria-hidden="true"
      >
        <div
          className={`${styles.init} ${!label ? styles.noLabel : ""} ${
            size === "small" ? styles.small : ""
          }
                ${
                  status.checking
                    ? styles.checking
                    : status.unchecking
                    ? styles.unchecking
                    : checked
                    ? styles.checked
                    : ""
                }
                ${disabled ? styles.disabled : ""}
              `}
        >
          <div className={`${styles.cover} ${size === "small" ? styles.small : ""}`}>
            {/* icon when checked */}
            <img
              src={checkmarkBlackIcon}
              alt=""
              className={`${styles.checkedImg} ${size === "small" ? styles.small : ""}`}
            />
          </div>

          {/* icon when unchecked */}
          {!checked && (
            <img
              src={unCheckmarkIcon}
              className={`${styles.icon} ${size === "small" ? styles.small : ""}`}
              alt=""
            />
          )}
        </div>
      </div>
      {label}
    </label>
  );
}
