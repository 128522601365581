import styles from "./styles.module.css";


interface TagTwo {
  textTitle?: string[];
  className?: string;
}

export function AppTagTwo(props: TagTwo) {
  return (
    <div className={`${styles.divTagTwoContainer} div_center ${props.className?props.className:''}`}>
      <div>
        <div>{props.textTitle&&props.textTitle.length>=1 &&props.textTitle[0]}</div>
        <div>{props.textTitle&&props.textTitle.length>1 &&props.textTitle[1]}</div>
      </div>
    </div>
  );
}
