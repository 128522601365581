import { IonPage } from "@ionic/react";
import {
  AppHeader,
  AppHomeIndicatorBar,
  AppLoading,
  AppTextLink,
  AppContent,
} from "../../../components/UiComponents";
import { MenuType } from "src/web/types";
import { DeviceModal } from "../../../components/DeviceModal/deviceModal";
import { MenuItemGroup } from "src/web/components/UiComponents/organisms";
import { useAutoDataFetchSettingControl } from "./hooks/useAutoDataFetchSettingControl";

export function AutoDataFetchSetting() {
  const {
    isLoading,
    menuList,
    openDevice,
    cancelDeviceSelection,
    navToSupportList,
    doFetchDeviceStatus,
  } = useAutoDataFetchSettingControl();

  return (
    <>
      <IonPage>
        <AppHeader />

        <AppContent>
          <div className="root-content">
            {menuList.map((item: MenuType, index: number) => (
              <MenuItemGroup
                pageTitle={index === 0 ? "データの自動取得" : undefined}
                menuData={item}
                key={index}
              />
            ))}

            <div className="page-spacer-45" />

            <AppTextLink underLine onClick={navToSupportList}>
              対応する機器・アプリを確認する
            </AppTextLink>

            <AppHomeIndicatorBar />
          </div>
        </AppContent>
      </IonPage>

      <DeviceModal
        isActive={openDevice.isOpen}
        deviceCategory={openDevice.deviceType}
        handleCancel={cancelDeviceSelection}
        handleAfterPairing={doFetchDeviceStatus}
        fetchingDataAfterPairingFlg={false}
        showNextBtnAfterError={false}
      />

      <AppLoading isOpen={isLoading} />
    </>
  );
}
