import { useIonRouter } from "@ionic/react";

interface RouterOptions {
  /**
   * Optional - Used for routing frameworks that need an 'as' parameter for routing.
   */
  as?: string;
  /**
   * Optional - If the component should be unmounted after it is transitioned away from. Defaults to false.
   */
  unmount?: boolean;
}

export function useAppRouter() {
  const router = useIonRouter();

  const push = (
    path: string,
    routerDirection?: "forward" | "back" | "root" | "none",
    routeAction?: "push" | "replace" | "pop",
    routerOptions?: RouterOptions,
  ) => {
    const indexOfParam = path.indexOf("?");

    path = `${path}${indexOfParam > -1 ? "&" : "?"}t=${new Date().getTime()}`;

    router.push(path, routerDirection, routeAction, routerOptions);
  };

  return {
    push,
    routeInfo: router.routeInfo,
    canGoBack: router.canGoBack,
    goBack: router.goBack,
  };
}
