import { IonContent, IonPage } from "@ionic/react";
import {
  AppHomeIndicatorBar,
  AppLoading,
  AppLogo,
  AppPrimaryButton,
} from "../../components/UiComponents";
import { UserBaseInfoForm } from "src/web/components/UiComponents/organisms";
import useCreateUserBaseInfoControl from "./hooks/useCreateUserBaseInfoControl";
import { useIntl } from "react-intl-phraseapp";

export default function CreateUserBaseInfo() {
  const { FIELDS_CONFIG, isLoading, isSubmitDisabled, doSubmit } = useCreateUserBaseInfoControl();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppLogo />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <UserBaseInfoForm
              title={intl.formatMessage({ id: "page.create_user_base_info.message.line1" })}
              subTitle={intl.formatMessage({ id: "page.create_user_base_info.message.line2" })}
              fieldsConfig={FIELDS_CONFIG}
            />

            <div className="page-spacer-60" />

            <AppPrimaryButton full disabled={isSubmitDisabled} onClick={doSubmit}>
              {intl.formatMessage({ id: "page.create_user_base_info.button.submit" })}
            </AppPrimaryButton>

            <AppHomeIndicatorBar />
          </div>
        </IonContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
