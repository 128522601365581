import { IonGrid, IonRow } from "@ionic/react";
import { SymptonQuestionType, RecordedAnswerType } from "src/web/types";
import { DateWithInstruction } from "../UiComponents/moleclues";
import { QuestionOptions } from "../UiComponents/organisms";
import { useQuestionControl } from "./hooks/useQuestionControl";
import { useIntl } from "react-intl-phraseapp";

export const Question = ({
  dateStr,
  question,
  checkedAnswers,
  setCheckedAnswers,
  goNextQuestion,
  handleSkip,
}: {
  dateStr: string;
  question: SymptonQuestionType;
  checkedAnswers?: RecordedAnswerType;
  setCheckedAnswers?: React.Dispatch<React.SetStateAction<RecordedAnswerType | undefined>>;
  goNextQuestion?: Function;
  handleSkip: (isSkipAnswerFlg: boolean) => void;
}) => {
  const { setAnswer, checkIsActive } = useQuestionControl(
    checkedAnswers,
    setCheckedAnswers,
    goNextQuestion,
  );

  return (
    <>
      <DateWithInstruction dateStr={dateStr} header2={question.inquiry} />

      {!question.multiselection_flag && <div className="page-spacer-45" />}

      <IonGrid>
        {question.img_name && (
          <IonRow className={`ion-justify-content-center ion-align-items-center`}>
            <img src={`./assets/pngs/${question.img_name}.png`} />
          </IonRow>
        )}

        <div className="page-spacer-45" />

        <QuestionOptions
          question={question}
          checkIsActive={checkIsActive}
          handleClick={setAnswer}
          handleSkip={handleSkip}
        />
      </IonGrid>
    </>
  );
};
