import { useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useIonViewWillEnter } from "@ionic/react";
import { getToday } from "src/web/utils/common";
import HealthCareManager from "src/web/plugin/healthcare-manager-plugin";
import { RootState } from "src/web/reducer";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { useQueryString } from "src/web/hooks/useQueryString";
import { BloodPressureDayDataType, BloodPressureType } from "src/web/types";
import { DeviceType } from "src/web/types";
import { useBloodPressureModel } from "src/web/hooks/models";

export function useBloodPressureControl() {
  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const { fetchBloodPressure, updateBloodPressure } = useBloodPressureModel();

  const params = parseQs(ionRouter.routeInfo.search);
  const currentDate = params.currentDate ? `${params.currentDate}` : getToday();

  const { device }: { device: DeviceType } = useSelector((state: RootState) => {
    return {
      device: state.sphygmomanometer,
    };
  });

  const fieldRefs = {
    ammaxBloodPressureRef: useRef<any>(),
    amminBloodPressureRef: useRef<any>(),
    amheartRateRef: useRef<any>(),
    pmmaxBloodPressureRef: useRef<any>(),
    pmminBloodPressureRef: useRef<any>(),
    pmheartRateRef: useRef<any>(),
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isPairSuccess, setIsPairSuccess] = useState(false);
  const [isOpenDevice, setIsOpenDevice] = useState(false);
  const [formData, setFormData] = useState<BloodPressureDayDataType>({
    am: {
      deviceFlg: false,
      isNewInput: true,
    },
    pm: {
      deviceFlg: false,
      isNewInput: true,
    },
  });

  const isSubmitDisabled = useMemo(
    () => !(Object.keys(formData.am).length === 6 || Object.keys(formData.pm).length === 6),
    [formData],
  );

  const handleValueChange = (type: "am" | "pm", field: string, value: string) => {
    if (field === "time") {
      formData[type][field] = value;
    } else {
      setDefaultTime(formData[type]);
      formData[type][field] = parseInt(value);
    }
    formData[type].isNewInput = true;
    setFormData({ ...formData });
    autoFocusNext(type);
  };

  const handleConnecting = () => {
    if (device && isPairSuccess) {
      // nav to device data reading page
      ionRouter.push("/reports/vitals/blood-pressure-records/fetch-from-device", "forward", "push");
    } else {
      // open device pairing modal
      setIsOpenDevice(true);
    }
  };

  const handleCancelDeviceModal = () => {
    setIsOpenDevice(false);
  };

  const autoFocusNext = (type: "am" | "pm") => {
    const fields = ["maxBloodPressure", "minBloodPressure", "heartRate"];
    const eleRefs = {
      am: [
        fieldRefs.ammaxBloodPressureRef,
        fieldRefs.amminBloodPressureRef,
        fieldRefs.amheartRateRef,
      ],
      pm: [
        fieldRefs.pmmaxBloodPressureRef,
        fieldRefs.pmminBloodPressureRef,
        fieldRefs.pmheartRateRef,
      ],
    };
    for (let i = 0; i < fields.length; i++) {
      if (!formData[type][fields[i]]) {
        const node = eleRefs[type][i].current;
        if (node && node.focus) {
          node.focus();
          break;
        }
      }
    }
  };

  const setDefaultTime = (argData: BloodPressureType): void => {
    if (argData.time) {
      return;
    }
    let currentDate = new Date();
    argData.time = `${currentDate.getHours().toPrecision().padStart(2, "0")}:${currentDate
      .getMinutes()
      .toPrecision()
      .padStart(2, "0")}`;
  };

  const handleSave = async (handlePostSave: () => void) => {
    try {
      setIsLoading(true);
      await updateBloodPressure(formData, currentDate);
      if (handlePostSave) {
        handlePostSave();
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getAnswerDatesAndCheckDevicePairing = async () => {
    try {
      setIsLoading(true);
      const { data, isPairing } = await fetchBloodPressure({ ...formData }, currentDate, device);
      setFormData(data);
      setIsPairSuccess(isPairing);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  const checkDevicePairing = async () => {
    if (device.deviceId) {
      setIsLoading(true);
      try {
        const data: any = await HealthCareManager.checkDevicePairing({
          deviceId: device.deviceId,
        });
        if (data.result && data.result === "OK") {
          setIsPairSuccess(true);
        }
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleFetchingDataFromDevice = () => {
    ionRouter.push("/reports/vitals/blood-pressure-records/fetch-from-device", "forward", "push");
  };

  const handleCancel = () => {
    ionRouter.goBack();
  };

  useIonViewWillEnter(() => {
    // get answer from DB
    getAnswerDatesAndCheckDevicePairing();
  });

  return {
    isLoading,
    currentDate,
    device,
    isPairSuccess,
    formData,
    fieldRefs,
    isSubmitDisabled,
    isOpenDevice,
    handleValueChange,
    handleConnecting,
    handleFetchingDataFromDevice,
    handleSave,
    handleCancel,
    checkDevicePairing,
    handleCancelDeviceModal,
  };
}
