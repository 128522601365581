import { useSelector } from "react-redux";
import { RootState } from "src/web/reducer";
import { getCheckMedicinesMonth } from "src/web/services";
import { useCalendarControl } from "./hooks/useCalendarControl";
import { useChangeWeekControl } from "./hooks/useChangeWeekControl";
import { useWeekChartControl } from "./hooks/useWeekChartControl";
import { useIntl } from "react-intl-phraseapp";
import {
  DateSwitcherWithComments,
  WeekScoreChartWithSummary,
} from "src/web/components/UiComponents/organisms";
import { AppCalendarFullStatusCommonSheet } from "src/web/components/UiComponents/AppCalendar/appCalendarFullStatusCommonSheet";

interface WeekChartProps {
  currentWeekStartDate: string;
  setCurrentWeekStartDate: React.Dispatch<React.SetStateAction<string>>;
  toggleWeekAndMonth: () => void;
  switchTab: (tab: any) => void;
}

export function WeekChart({
  currentWeekStartDate,
  setCurrentWeekStartDate,
  toggleWeekAndMonth,
  switchTab,
}: WeekChartProps) {
  const { userStartDate }: { userStartDate: string } = useSelector((state: RootState) => state);

  const { isOpenCalendar, handleOpenCalendar, handleCloseCalendar, handleConfirmAndCloseCalendar } =
    useCalendarControl(setCurrentWeekStartDate);

  const { formattedWeekPeriod, weeklyIngestionAdvice, gotoPreviousWeek, gotoNextWeek } =
    useChangeWeekControl(currentWeekStartDate, setCurrentWeekStartDate);

  const { isPreDateDisabled, isNextDateDisabled, lineKeys, chartData, isNoData } =
    useWeekChartControl(currentWeekStartDate);
  const intl = useIntl();

  return (
    <>
      <DateSwitcherWithComments
        text={formattedWeekPeriod}
        disableLeft={isPreDateDisabled}
        disableRight={isNextDateDisabled}
        btnLabel={intl.formatMessage({ id: "page.symptom_main_tab.month_chart.month_mode" })}
        handleLeftClick={gotoPreviousWeek}
        handleRightClick={gotoNextWeek}
        handleLabelClick={handleOpenCalendar}
        handleBtnClick={toggleWeekAndMonth}
        comments={weeklyIngestionAdvice}
      />

      <div className="page-spacer-45" />

      <WeekScoreChartWithSummary
        chartData={chartData}
        lineKeys={lineKeys}
        isNoData={isNoData}
        switchTab={switchTab}
      />

      <AppCalendarFullStatusCommonSheet
        isOpen={isOpenCalendar}
        calendarDateAvailableFrom={userStartDate}
        currentDate={currentWeekStartDate}
        handleClose={handleCloseCalendar}
        handleConfirm={handleConfirmAndCloseCalendar}
        doFetch={(date: string) => getCheckMedicinesMonth({ currentDate: date })}
        refactRes={(res: any) =>
          res.map((data: any) => {
            return {
              check_date: data.check_date.split("T")[0],
              cnt: data.cnt,
            };
          })
        }
        isLineSelectRequired={true}
      />
    </>
  );
}
