import { PickerColumn, createAnimation } from "@ionic/react";
import { AlertType, FuncStatus, PageActionCode, PageLogType, SelectOption } from "../types";
import {
  EMPTY_OPTION_NO_TEXT,
  EMPTY_OPTION_STRING_VALUE,
  EMPTY_OPTION_NUMBER_VALUE,
  DATE_FORMAT_SPLIT,
  USER_BASIC_INFO_MIN_BIRTHDAY_YEAR,
  LocalStorageKey,
} from "../constants";
import {
  LocalNotifications,
} from "@capacitor/local-notifications";
import { Storage } from "@capacitor/storage";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

/*
  convert date type to string in format YYYY/MM/DD, 
  return today by default
*/
export function getDateStrByDate(date?: Date) {
  const today = date ?? new Date();
  const month = `${today.getMonth() + 1}`.padStart(2, "0");
  const day = `${today.getDate()}`.padStart(2, "0");
  return `${today.getFullYear()}${DATE_FORMAT_SPLIT}${month}${DATE_FORMAT_SPLIT}${day}`;
}

/*
  convert date type to string in format YYYY/M/D, 
  return today by default
*/
export function getDateStrByDateNoPad(date?: Date) {
  const today = date ?? new Date();
  const month = `${today.getMonth() + 1}`;
  const day = `${today.getDate()}`;
  return `${today.getFullYear()}${DATE_FORMAT_SPLIT}${month}${DATE_FORMAT_SPLIT}${day}`;
}

/*
  get today date in string format YYYY/MM/DD
*/
export function getToday() {
  return getDateStrByDate();
}

/*
  convert date type to string in format YYYY/MM, 
  return today by default
*/
export function getYearMonthByDate(date?: Date) {
  const today = date ?? new Date();
  const month = `${today.getMonth() + 1}`.padStart(2, "0");
  return `${today.getFullYear()}${DATE_FORMAT_SPLIT}${month}`;
}

/*
  get today date in string format YYYY/MM
*/
export function getCurrentYearMonth() {
  return getYearMonthByDate();
}

/*
  get the date before or after the given date by the gap days
  return the date in string format YYYY/MM/DD
*/
export function getNDaysBeforeOrAfterByDay(gap: number, theDay?: string) {
  let targetDate = theDay ? new Date(theDay) : new Date();
  targetDate.setDate(targetDate.getDate() + gap);
  const month = `${targetDate.getMonth() + 1}`.padStart(2, "0");
  const day = `${targetDate.getDate()}`.padStart(2, "0");
  return `${targetDate.getFullYear()}${DATE_FORMAT_SPLIT}${month}${DATE_FORMAT_SPLIT}${day}`;
}

/*
  get the date before or after today by the gap days
  return the date in string format YYYY/MM/DD
*/
export function getNDaysBeforeOrAfterToday(gap: number) {
  return getNDaysBeforeOrAfterByDay(gap);
}

/*
  get now time in format HH:SS by default
  return H:SS when isH = true
*/
export function getNow(isH?: boolean) {
  const nowTime = new Date();
  const hoursH = `${nowTime.getHours()}`;
  const hoursHH = hoursH.padStart(2, "0");
  const minutes = `${nowTime.getMinutes()}`.padStart(2, "0");
  const now = isH ? `${hoursH}:${minutes}` : `${hoursHH}:${minutes}`;
  return now;
}

/*
  convert time format
  param:
    time: hhss
    isH: boolean
*/
export function formatTime(time: string, isH?: boolean) {
  const tmpTime = time.padStart(4, "0");
  const hh = isH ? parseInt(tmpTime.substring(0, 2)) : tmpTime.substring(0, 2);
  return `${hh}:${tmpTime.substring(2)}`;
}

/*
  get the gap hours between 2 days
*/
export function getHoursDiff(startDate: Date, endDate: Date) {
  const msInHour = 1000 * 60 * 60;
  return Math.ceil(Math.abs(endDate.getTime() - startDate.getTime()) / msInHour);
}

/*
  get the date in corresponding week by given date and number(0-6)
  0 for Sunday,
  1 for Monday,
  ...
  6 for Saturday
*/
export function getCorrespondDayOfWeekForGivenDate(dateInStr: string, dayNumber: number) {
  const gap = dayNumber - new Date(dateInStr).getDay();
  if (gap === 0) {
    return dateInStr;
  } else {
    return getNDaysBeforeOrAfterByDay(gap, dateInStr);
  }
}

/*
  handle API Error
*/
export function handleError(e: any) {
  console.info(e);
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

// get Blood Pressure from device
export function readBloodPressureDateFromDevice(records: any, currentDate: string) {
  let targetRecord: any = {
    am: null,
    pm: null,
  };

  const am = new Date(2000, 0, 1, 7, 0).getTime(); // 7:00
  const pm = new Date(2000, 0, 1, 18, 0).getTime(); // 18:00

  records.forEach((record: any) => {
    // filter the record by date
    if (currentDate === getDateStrByDate(new Date(record.year, record.month - 1, record.day))) {
      record.time = "" + record.hour + ":" + ("" + record.minute).padStart(2, "0");
      record.timePadded =
        ("" + record.hour).padStart(2, "0") + ":" + ("" + record.minute).padStart(2, "0");
      if (record.timePadded >= "05:00" && record.timePadded <= "11:00") {
        const target = new Date(2000, 0, 1, record.hour, record.minute).getTime();
        record.gap = target > am ? target - am : am - target;
        if (!targetRecord.am || (targetRecord.am && targetRecord.am.gap > record.gap)) {
          targetRecord.am = record;
        }
      } else if (record.timePadded >= "16:00" && record.timePadded <= "22:00") {
        const target = new Date(2000, 0, 1, record.hour, record.minute).getTime();
        record.gap = target > pm ? target - pm : pm - target;
        if (!targetRecord.pm || (targetRecord.pm && targetRecord.pm.gap > record.gap)) {
          targetRecord.pm = record;
        }
      }
    }
  });
  return targetRecord;
}

// Tutorial: https://www.youtube.com/watch?v=wV-fDUI-5LQ
export const navAnimationBuilder = (_: HTMLElement, opts?: any) => {
  // Leaving Element - Current page
  const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .keyframes([
      { offset: 0, opacity: "1", transform: "translateX(0)" },
      { offset: 0.4, opacity: "0" },
      { offset: 1, opacity: "0", transform: "translateX(-50px)" },
    ])
    .easing("cubic-bezier(0.01, 0.42, 0.35, 1)")
    .duration(500);

  // Entering Element - New Page
  const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .keyframes([
      { offset: 0, opacity: "0", transform: "translateX(50px)" },
      { offset: 0.6, opacity: "1" },
      { offset: 1, opacity: "1", transform: "translateX(0)" },
    ])
    .easing("cubic-bezier(0.01, 0.42, 0.35, 1)")
    .delay(150);

  return createAnimation().duration(600).addAnimation([leavingAnimation, enteringAnimation]);
};

// append empty key value option
export const appendBlankEmptyOptions = (type: string) => {
  if (type === "string") {
    return { value: EMPTY_OPTION_STRING_VALUE, text: EMPTY_OPTION_NO_TEXT };
  } else {
    return { value: EMPTY_OPTION_NUMBER_VALUE, text: EMPTY_OPTION_NO_TEXT };
  }
};

// append empty option for select option
export const generateBlankEmptyOptions = (options: any[], needClear?: boolean) => {
  if (needClear && options && options.length > 0) {
    if (typeof options[0] === "string") {
      return [EMPTY_OPTION_NO_TEXT, ...options];
    } else {
      return [appendBlankEmptyOptions(typeof options[0].value), ...options];
    }
  } else {
    return options;
  }
};

export interface Medicine {
  name?: string;
  dosage?: string;
  unit?: string;
  code?: string;
  instructions?: string;
  notice?: string;
}

export interface MedicineGroup {
  instructions?: string;
  dosage?: string;
  unit?: string;
  medicine: Medicine[];
}

export interface MedicineResult {
  date?: string;
  pharmacyName?: string;
  pharmacistName?: string;
  hospitalName?: string;
  doctorName?: string;
  medicineGroup: { [name: string]: MedicineGroup };
}

export interface MedicineType {
  rpNo?: string; // RP番号
  m_name?: string; // お薬の名前
  m_shape_code?: string; // 剤形
  once_quantity?: number; // 1回あたりの服用量
  once_quantity_unit?: string; // 1回あたりの服用量(单位)
  dispensing_quantity?: number; // 調剤数量
  dispensing_quantity_unit?: string; // 調剤数量(单位)
  clinical_department?: string; // 診療科
  prescribing_doctor?: string; // 処方医師名
  memos?: string; // 服用メモ
  281?: string;
  291?: string;
}

export interface PrescriptionInfoType {
  dispensing_date?: string; // 調剤日
  pharmacy?: string; // 薬局名
  pharmacist?: string; // 担当薬剤師名
  hospital?: string; // 病院名
  notes?: string; // その他 - 服用上の注意等
  medicines: MedicineType[]; // 薬list
  medicineNames?: string; // all薬names in string for current prescription
}


export interface MedicineInfo {
  start: Date;
  end: Date;
  frequency?: { type: string; unit: string; week_day: string[]; every_gap: number };
}

export const cancelAllPendingAlarms = async () => {
  // ペンディング中の情報を全部削除する
  let pendingData = await LocalNotifications.getPending();
  let cancelIds: { id: number }[] = [];

  for (let pendingNotification of pendingData.notifications) {
    cancelIds.push({ id: pendingNotification.id });
  }

  // 削除データが存在している場合
  if (cancelIds.length !== 0) {
    await LocalNotifications.cancel({
      notifications: cancelIds,
    });
  }
};

export const validateEmail = (emailAdd: string) => {
  return !!emailAdd
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const trimLeftZero = (argStr: string) => {
  if (argStr) {
    return argStr.replace(/^0+/, "");
  }
  return argStr;
};
export const setScreenLockerPasscodeToConfirm = async (value: string) => {
  await Storage.set({ key: LocalStorageKey.SCREEN_LOCKER_PASS, value: `_${value}` });
};

export const enableScreenLocker = async (value: string) => {
  await Storage.set({ key: LocalStorageKey.SCREEN_LOCKER_PASS, value });
};

export const disableScreenLocker = async () => {
  await Storage.set({ key: LocalStorageKey.SCREEN_LOCKER_PASS, value: "" });
};

export const getScreenLockerPasscode = async () => {
  return await (
    await Storage.get({ key: LocalStorageKey.SCREEN_LOCKER_PASS })
  ).value;
};

/*
  if the passcode is "" or undefined, means there is no passcode
  if the passcode with a prefix "_", means the passcode setting is in progress and is not available
  if the passcode without the prefix "_", means the passcode setting is done and is available
*/
export const isScreenLockerEnabled = async () => {
  const passCode = await getScreenLockerPasscode();
  return !!passCode && !passCode.includes("_");
};

export const getStartEndDatesOfWeek = (date: Date): Date[] => {
  const startDateOfWeek = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay(),
  );
  const endDateOfWeek = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - date.getDay() + 6,
  );
  return [startDateOfWeek, endDateOfWeek];
};

export const getStartEndDatesOfHalfYear = (date: Date) => {
  let startDateOfHalfYear: Date;
  let endDateOfHalfYear: Date;

  if (date.getMonth() <= 5) {
    // if day is in 1st half year
    startDateOfHalfYear = new Date(date.getFullYear(), 0, 1);
    endDateOfHalfYear = new Date(date.getFullYear(), 5, 30);
  } else {
    // if day is in 2nd half year
    startDateOfHalfYear = new Date(date.getFullYear(), 6, 1);
    endDateOfHalfYear = new Date(date.getFullYear(), 11, 31);
  }
  return [startDateOfHalfYear, endDateOfHalfYear];
};

export const processErrorLog = (logType: PageLogType, status: FuncStatus, e: any) => {
  // 将来Firebaseのログを処理する
  console.log(`${logType.pageCode} ${logType.pageActionCode} ${status}`);
  logEvent(analytics, "exception", {
    PageActionCode: logType.pageActionCode,
    pageCode: logType.pageCode,
    eventStatus: status,
    exception: e,
    description: e.toString(),
    fatal: true,
  });
};

export const processLog = (logType: PageLogType, status: FuncStatus) => {
  // 将来Firebaseのログを処理する
  console.log(`${logType.pageCode} ${logType.pageActionCode} ${status}`);
  // 画面初期化
  if (logType.pageActionCode === PageActionCode.C_INITIAL) {
    logEvent(analytics, "page_initial", {
      pageCode: logType.pageCode,
      eventStatus: status,
    });
  } else {
    logEvent(analytics, "page_action", {
      PageActionCode: logType.pageActionCode,
      pageCode: logType.pageCode,
      eventStatus: status,
    });
  }
};

// get color by css variable
export function getColor(key: string) {
  let root_style = document.querySelector(":root");
  let rs = getComputedStyle(root_style!);
  return rs.getPropertyValue(key).trim();
}

/* check if the object is empty */
export const loopEmptyCheckForObjectAndArray = (objToCheck: any) => {
  let isEmpty: boolean = true;

  if (!objToCheck) {
    return isEmpty;
  }

  isEmpty = Object.values(objToCheck).every((item) => Object.keys(item || {}).length === 0);
  return isEmpty;
};
