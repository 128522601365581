import React, { useState, useEffect } from "react";
import {
  IonCard,
  IonCardContent,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import { RootState } from "../../../../reducer";
import {
  AppLoading,
  AppMedicineImage,
  AppTitleWithLine,
} from "../../../../components/UiComponents";
import { useSelector, useDispatch } from "react-redux";
import { updateRefreshInfo } from "../../../../actions";
import { Storage } from "@capacitor/storage";
import { storage } from "../../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import styles from "./listOfMedicines.module.css";
import { useQueryString } from "src/web/hooks/useQueryString";
import { RefreshType } from "../../../../types";
import {
  LocalStorageKey,
  IMAGES_FOLDER,
  FIREBASE_FILE_NAMES,
  MEDICINE_LIST,
} from "../../../../constants";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import { MEDICINE_TYPE } from "../../../../constants";
import { useIntl } from "react-intl-phraseapp";
import { getMedicines } from "src/web/services";
import { useCommon } from "src/web/utils/hooks/common";
import { useTextConstants } from "src/web/constants/hooks";

interface MedicineInfo {
  type: string;
  id: number;
  medicine_id?: number;
  prescription_id: number;
  dispensing_date: string;
  m_name: string;
  dispensing_quantity?: number;
  dispensing_quantity_unit?: string;
}

let imgSrcDict: { [key: string]: string } = {};

export function ListOfMedicines() {
  const intl = useIntl();
  const { convertToDate } = useCommon();
  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  // 更新フラグ
  const refresh = params.refresh;
  const [pageIndex, setPageIndex] = useState(0);
  const [infiniteLoad, setInfiniteLoad] = useState(false);
  const [listData, setListData] = useState<Map<number, MedicineInfo>>(new Map());
  const [listKey, setListKey] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    MEDICINE_QUANTITY_UNIT,
  } = useTextConstants();

  const refreshInfo: RefreshType = useSelector((state: RootState) => {
    return state.refreshInfo;
  });

  // const [imgSrcDict, setImgSrcDict] = useState<{ [key: string]: string }>({});

  let DICT_QUANTITY_UNIT = Object.fromEntries(
    MEDICINE_QUANTITY_UNIT.map(({ value, text }) => [value, text]),
  );

  const getUrls = async (arr_id: string[]) => {
    // let ret_dict:{ [key: string]: string } = {};
    let userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });
    let promises = [];
    let vals = [];
    for (let val of arr_id) {
      let keys = val.split("-");

      // url パス
      const imageRef = ref(
        storage,
        `${IMAGES_FOLDER}/${userId.value!}/${keys[0]}/${keys[1]}/${
          FIREBASE_FILE_NAMES.medicineFileName
        }`,
      );
      imgSrcDict[val] = "";
      vals.push(val);
      try {
        // let downloadUrl = await getDownloadURL(imageRef);
        promises.push(getDownloadURL(imageRef));

        // imgSrcDict[keys[1]] = downloadUrl;
        // setImgSrcDict(imgSrcDict);
        // return downloadUrl;
        // imgSrcDict[val] = downloadUrl;
      } catch {}
      // return "";
    }
    try {
      let results = await Promise.allSettled(promises);

      for (let i = 0; i < vals.length; i++) {
        let val = vals[i];
        let result = results[i];

        if (result.status === "fulfilled") {
          imgSrcDict[val] = result.value;
        }
      }
    } catch {}
    // return ret_dict;
  };

  const getUrl = async (val: string) => {
    let userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

    // for (let datafff of imgKeyList.entries()) {
    //   datafff.length

    //   let mydata = imgKeyList.get(val);

    let keys = val.split("-");
    if (imgSrcDict[keys[1]] === "") {
      return "";
    }
    // url パス
    const imageRef = ref(
      storage,
      `${IMAGES_FOLDER}/${userId.value!}/${keys[0]}/${keys[1]}/${
        FIREBASE_FILE_NAMES.medicineFileName
      }`,
    );

    try {
      let downloadUrl = await getDownloadURL(imageRef);

      // imgSrcDict[keys[1]] = downloadUrl;
      // setImgSrcDict(imgSrcDict);
      return downloadUrl;
    } catch {}
    return "";

    // // setListData(listData);

    // setImgDict(imgDict);
  };

  // const [imgDict, setImgDict] = useState<{ [name: string]: string }>({});
  const dispatch = useDispatch();

  // お薬手帳
  const MedicineItem: React.FC<{
    info: MedicineInfo;
    // currentImgDict: { [name: string]: string };
  }> = (props) => {
    let imgKey = `${props.info.prescription_id}-${props.info.id}`;

    let imgUrl = imgSrcDict[imgKey];

    // 調剤の数量
    let dispensing = "";
    let unit = DICT_QUANTITY_UNIT[props.info.dispensing_quantity_unit!];
    if (props.info.dispensing_quantity) {
      dispensing += `${props.info.dispensing_quantity}`;
    }
    if (unit) {
      dispensing += `${unit}`;
    }
    // let initail = imgSrcDict[`${props.info.id}`];
    // if (initail === undefined) {
    //   initail = "";
    // }

    // const [imgSrc, setImgSrc] = useState(initail);

    // const setImgFunc = async () => {
    //   let src = await getUrl(imgKey);
    //   setImgSrc(src);
    // };

    useEffect(() => {
      // setImgFunc();
      // setImgSrc('asdfsadfsadf');
      // console.log(imgKey);
    }, []);

    return (
      <IonCard
        className={styles.medicine_item_iconCard}
        onClick={(event: any) => {
          // dispatch(clearMedicineInfo());
          // ionRouter.push(`/medicine-information?index=${props.idx}`, "forward", "push");
        }}
      >
        <IonCardContent
          className={styles.medicine_item_ionCardContent}
          onClick={() =>
            ionRouter.push(
              `/home/medicine-information-details?id=${props.info.id}`,
              "forward",
              "push",
            )
          }
        >
          <div className={styles.medicineList}>
            {/* データのイメージ */}
            <div className={styles.medicineDiv}>
              <AppMedicineImage src={imgUrl} size="MediumBig" grayBg={true} hideFrame={true} />
            </div>

            <div className={styles.medicineInfo}>
              <div className={styles.medicineTitle}>{props.info.m_name}</div>
              {/* 値存在の場合表示 */}
              <div className={styles.medicineFrequency}>
                {props.info.type === MEDICINE_TYPE.PRESCRIPTION
                  ? intl.formatMessage({
                      id: "page.list_of_medicines.label.prescription.dispensing_date",
                    })
                  : intl.formatMessage({
                      id: "page.list_of_medicines.label.over_the_counter.dispensing_date",
                    })}
                {convertToDate(props.info.dispensing_date)}
              </div>
              {dispensing && <div className={styles.medicineNum}>{dispensing}</div>}
            </div>
          </div>
        </IonCardContent>
      </IonCard>
    );
  };

  const getMedicineInfo = async (
    pageIndex: number,
    listData: Map<number, MedicineInfo>,
    loadFlg: boolean,
    initial: boolean = false,
  ) => {
    if (loadFlg) {
      setLoading(true);
    }
    try {
      let jsonData = await getMedicines({ page_index: pageIndex.toString() });

      setPageIndex(jsonData.index);

      let imgKeyList: string[] = [];

      let newMap: Map<number, MedicineInfo> = new Map(listData.entries());

      if (initial) {
        newMap = new Map();
      }

      for (let i = 0; i < jsonData.list.length; i++) {
        let item = jsonData.list[i] as MedicineInfo;
        let imgKey = `${item.prescription_id}-${item.id}`;
        imgKeyList.push(imgKey);
        newMap.set(item.id, item);
      }

      let keys = Array.from(newMap.keys());
      setListKey(keys);

      await getUrls(imgKeyList);
      // setImgDict({
      //   ...imgDict,
      //   ...data
      // })
      setListData(newMap);
      // ge

      // listData.keys()
      // let keys = Array.from(listData.keys());

      if (jsonData.finish_flg) {
        setInfiniteLoad(true);
      }
    } finally {
      setLoading(false);
    }
  };

  // useEffect(()=>{
  //   getUrl(listData);
  // },[listData.size]);

  useEffect(() => {
    if (refreshInfo[MEDICINE_LIST]) {
      return;
    }

    // 初期取得
    getMedicineInfo(0, new Map(), true);
  }, []);

  useEffect(() => {
    if (refreshInfo[MEDICINE_LIST]) {
      let new_refreshInfo = {
        ...refreshInfo,
      };
      new_refreshInfo[MEDICINE_LIST] = false;
      dispatch(updateRefreshInfo(new_refreshInfo));

      getMedicineInfo(0, new Map(), true, true);
    }
  }, [refreshInfo[MEDICINE_LIST]]);

  const loadData = async (ev: any) => {
    try {
      await getMedicineInfo(pageIndex + 1, listData, false);
    } finally {
      ev.target.complete();
    }
  };

  return (
    <div>
      <IonList lines="none">
        {Array.from(listData.keys()).map((item: any, index: number) => {
          let itemData = listData.get(item)!;

          let currentYear = itemData.dispensing_date.substring(0, 4);

          let showDateFlg = false;
          if (index === 0) {
            showDateFlg = true;
          } else {
            let listKey = Array.from(listData.keys());
            let preItemData = listData.get(listKey[index - 1])!;
            let preYear = preItemData.dispensing_date.substring(0, 4);

            if (preYear !== currentYear) {
              showDateFlg = true;
            }
          }
          let newYearKey = "keyYear" + currentYear;

          let itemKey = `${newYearKey}-${item}`;

          return (
            <div key={itemKey}>
              {showDateFlg && (
                <IonItem className={styles.list_item_year}>
                  <AppTitleWithLine title={currentYear} />
                  <div className="page-spacer-30" />
                </IonItem>
              )}
              <IonItem className={styles.list_item}>
                <MedicineItem info={itemData} />
              </IonItem>
            </div>
          );
        })}
      </IonList>

      <IonInfiniteScroll
        onIonInfinite={loadData}
        disabled={listData.size === 0 ? true : infiniteLoad}
      >
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="Loading more data..."
        ></IonInfiniteScrollContent>
      </IonInfiniteScroll>
      <AppLoading isOpen={loading} />
    </div>
  );
}
