import { actionConst } from "../constants";
import { DeviceType } from "../types";

interface Actions {
  type: string;
  device: DeviceType;
}

export default function sphygmomanometerReducer(state = {}, actions: Actions) {
  switch (actions.type) {
    case actionConst.CHANGE_SPHYGMOMANOMETER:
      return actions.device;
    default:
      return state;
  }
}
