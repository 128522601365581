import { DEVICE_CATEGORY } from "src/web/constants";
import { useIntl } from "react-intl-phraseapp";
import { ModalTitleWithDesc } from "../../UiComponents/organisms";
import { ModalWrapper } from "./ModalWrapper";
import { AppPrimaryButton, AppTextLink } from "../../UiComponents";
import styles from "../styles.module.css";
import omronConnectionImg from "../../../assets/images/omron_connection.png";

export function DeviceReadyToConnect({
  isOpen,
  deviceCategory,
  handleCancel,
  handleNext,
}: {
  isOpen: boolean;
  deviceCategory: string;
  handleCancel: () => void;
  handleNext: () => void;
}) {
  const intl = useIntl();

  return (
    <ModalWrapper isOpen={isOpen} handleCancel={handleCancel}>
      <div className="page-spacer-45" />

      <ModalTitleWithDesc
        title={`${
          deviceCategory === DEVICE_CATEGORY.BLOOD_PRESSURE
            ? intl.formatMessage({ id: "components.device_modal.device.blood_pressure.pairing" })
            : intl.formatMessage({ id: "components.device_modal.device.temperature.pairing" })
        }`}
        desc={`${
          deviceCategory === DEVICE_CATEGORY.BLOOD_PRESSURE
            ? intl.formatMessage({
                id: "components.device_modal.device_ready_to_connect.device.blood_pressure",
              })
            : intl.formatMessage({
                id: "components.device_modal.device_ready_to_connect.device.temperature",
              })
        }`}
      />

      <div className="page-spacer-50" />

      <div className={styles.deviceImgWrapper}>
        <img src={omronConnectionImg} className={styles.deviceConnectionImg} alt="" />
      </div>

      <div className="page-spacer-60" />

      <AppPrimaryButton full onClick={handleNext}>
        {intl.formatMessage({
          id: "components.device_modal.device_ready_to_connect.button.pairing",
        })}
      </AppPrimaryButton>

      <div className="page-spacer-15" />

      <AppTextLink hasNoArrow onClick={handleCancel}>
        {intl.formatMessage({
          id: "components.device_modal.device_ready_to_connect.button.cancel",
        })}
      </AppTextLink>
    </ModalWrapper>
  );
}
