import { IonLabel } from "@ionic/react";
import { AppPasswordInput } from "../../AppInput/appPasswordInput";
import { LabelWithContents } from "../../moleclues";
import { useIntl } from "react-intl-phraseapp";

interface ChangeEmailFormProps {
  pageTitle: string;
  oldEmail?: string | null;
  password?: string;
  errorMsg?: string;
  newEmail?: string;
  handlePasswordChange: (value: string) => void;
}

export function ChangeEmailForm({
  pageTitle,
  oldEmail,
  password,
  errorMsg,
  handlePasswordChange,
}: ChangeEmailFormProps) {
  const intl = useIntl();
  return (
    <>
      <IonLabel className="p-header1">{pageTitle}</IonLabel>

      <div className="page-spacer-45" />

      <LabelWithContents title={ intl.formatMessage({id: "page.change_email.label.current_email"}) } text={oldEmail} />

      {!!handlePasswordChange && (
        <>
          <div className="page-spacer-45" />

          <AppPasswordInput
            label={ intl.formatMessage({id: "page.change_email.label.password"}) }
            value={password}
            onIonChange={(e: any) => {
              handlePasswordChange(e.detail.value);
            }}
            error={!!errorMsg}
            errorMsg={errorMsg}
          />
        </>
      )}
    </>
  );
}
