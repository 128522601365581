import { useState } from "react";
import { IonButton } from "@ionic/react";
import { AppIcon } from "../";
import { BUTTON_TIMEOUT } from "../../../constants";
import styles from "./appSecondaryButton.module.css";

let secondaryBtnAnimationTimeout: any = null;
const secondaryBtnInitClickingState = 0;

export function AppSecondaryButton(props: any) {
  const btnSmall = props.small as boolean;
  const isDynamicWidth = props.isDynamicWidth as boolean;

  const [isClicking, setIsClicking] = useState<number>(secondaryBtnInitClickingState);

  const click = (event: any) => {
    //動画を追加する
    clearTimeout(secondaryBtnAnimationTimeout);
    setIsClicking(isClicking + 1);
    secondaryBtnAnimationTimeout = setTimeout(() => {
      setIsClicking(secondaryBtnInitClickingState);
      props.onClick && props.onClick(event);
    }, BUTTON_TIMEOUT);
  };

  return (
    <div className={props.full ? "div_center" : ""}>
      <IonButton
        key={isClicking}
        {...props}
        className={`${
          isDynamicWidth
            ? `${styles.SecondaryButtonSmall} ${styles.dynamicWidth}`
            : btnSmall
            ? styles.SecondaryButtonSmall
            : styles.SecondaryButton
        }
              ${props.transparent ? styles.transparentButton : ""}
              ${props.className}
              ${props.icon ? styles.iconBtn : ""}
              ${props.disabled === true && styles.SecondaryButtonDisabled} 
              ${
                isClicking !== secondaryBtnInitClickingState
                  ? btnSmall
                    ? styles.SecondaryButtonSmallAnimation
                    : styles.SecondaryButtonAnimation
                  : ""
              } ${props.full ? styles.fullWidth : ""}`}
        onClick={click}
      >
        <div className={styles.label}>
          {props.children}
          {props.icon && <AppIcon size="sm" icon={props.icon} className={styles.icon} />}
        </div>
      </IonButton>
    </div>
  );
}
