import { useMemo } from "react";
import { IonLabel } from "@ionic/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { AppIcon } from "../../AppIcon/appIcon";
import { ICONS } from "src/web/constants";
import { AppSmallTile } from "../..";
import { CarouselType } from "./types";
import styles from "./carouselView.module.css";

interface CarouselViewProps {
  title: string;
  subTitle?: string;
  handleSubTitleClick?: () => void;
  dataList: CarouselType[];
}

export function CarouselView({
  title,
  subTitle,
  handleSubTitleClick,
  dataList,
}: CarouselViewProps) {
  const sliderOptions = {
    slidesPerView: 2.08,
    spaceBetween: 15,
  };

  const isNormalSlider = useMemo(() => {
    if (dataList.length > 0) {
      return dataList.some((data) => !!data.title);
    } else {
      return false;
    }
  }, [dataList]);

  return (
    <>
      <div className="root-content">
        <div className="flex-row-between">
          <IonLabel className="p-body gray">{title}</IonLabel>
          {!!subTitle && (
            <div
              className="flex-row-end"
              onClick={() => {
                if (handleSubTitleClick) {
                  handleSubTitleClick();
                }
              }}
            >
              <IonLabel
                className={`p-body ${!!handleSubTitleClick ? styles.labelWithRightIcon : ""}`}
              >
                {subTitle}
              </IonLabel>
              {!!handleSubTitleClick && <AppIcon icon={ICONS.ICON.ARROW_FORWARD} size="sm" />}
            </div>
          )}
        </div>
      </div>
      <div className="page-spacer-15" />
      <div className={styles.contentLeft}>
        <Swiper
          {...sliderOptions}
          className={`${styles.slidesBarShort} ${isNormalSlider ? styles.withDesc : ""}`}
        >
          {dataList
            .filter((item) => item.isShowing)
            .map((item) => (
              <SwiperSlide
                key={item.text}
                className={styles.slideLeft}
                onClick={() => {
                  if (item.action) {
                    item.action();
                  }
                }}
              >
                <AppSmallTile
                  src={item.src}
                  tagText={item.text}
                  textTitle={item.title ? [item.title] : undefined}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
}
