import { IonPage } from "@ionic/react";
import { AppContent, AppFloatButton, AppHeader, AppLoading } from "../../components/UiComponents";
import { WithdrawView } from "src/web/components/UiComponents/organisms";
import { useAccountsWithdrawControl } from "./hooks/useAccountsWithdrawControl";
import { useIntl } from "react-intl-phraseapp";

export function AccountsWithdraw() {
  const { isLoading, dataReserveFlg, handleToggleReserveFlg, doSubmit } =
    useAccountsWithdrawControl();
  const intl = useIntl();

  const contents = [
    {
      title: intl.formatMessage({ id: "page.delete_user.message.line3" }),
      text: `${intl.formatMessage({ id: "page.delete_user.message.line4" })}\n${intl.formatMessage({
        id: "page.delete_user.message.line5",
      })}\n${intl.formatMessage({ id: "page.delete_user.message.line6" })}`,
    },
  ];

  return (
    <>
      <IonPage>
        <AppHeader />

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <WithdrawView
              title={intl.formatMessage({ id: "page.delete_user.header.withdrawal" })}
              subTitle={`${intl.formatMessage({
                id: "page.delete_user.message.line1",
              })}\n${intl.formatMessage({ id: "page.delete_user.message.line2" })}`}
              contents={contents}
              checked={dataReserveFlg}
              handleCheckboxClick={handleToggleReserveFlg}
            />

            <div className="page-spacer-30" />
          </div>

          <AppFloatButton
            label={intl.formatMessage({ id: "page.delete_user.button.withdrawal" })}
            isStatic
            onClick={doSubmit}
          />
        </AppContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
