import React from "react";
import { AppTab } from "../../AppTab/appTab";
import {
  LineBarScatterChartV2,
  ScrollableBarChartV2,
  ScrollableLineChartV2,
} from "src/web/components/Charts";
import { HighlightCover } from "src/web/pages/SymptomRecordsTab/SymptomRecordsMain/components/HighlightCover/highlightCover";
import { getYearMonthByDate } from "src/web/utils/common";

import styles from "./monthScoreChartWithSummary.module.css";
import { MedicineLineChartDataType } from "src/web/types";
import { HalfYearSummaryPanel } from "src/web/components/Charts/ChartSummaryPanel";
import { useTextConstants } from "src/web/constants/hooks";

interface MonthScoreChartWithSummaryProps {
  monthTab: string;
  barChartData?: any[];
  isNoData: boolean;
  first5MedicineChartData: MedicineLineChartDataType;
  allMedicineChartData: MedicineLineChartDataType;
  isShowMoreMedicine: boolean;
  highlightBarIndex?: number;
  handleTabChange: (tab: string) => void;
  showMoreMedicine: () => void;
  hideMoreMedicine: () => void;
  setHighlightBarIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export function MonthScoreChartWithSummary({
  monthTab,
  barChartData,
  isNoData,
  first5MedicineChartData,
  allMedicineChartData,
  isShowMoreMedicine,
  highlightBarIndex,
  handleTabChange,
  showMoreMedicine,
  hideMoreMedicine,
  setHighlightBarIndex,
}: MonthScoreChartWithSummaryProps) {
  const {
    WEEK_MONTH_CHART_TABS
  } = useTextConstants();
  return (
    <>
      <AppTab tabs={WEEK_MONTH_CHART_TABS} onChange={handleTabChange} />

      <div className="page-spacer-15" />

      <div className={styles.chartRoot}>
        {monthTab === WEEK_MONTH_CHART_TABS[1].name ? (
          <>
            {/* score bar chart for 薬 */}
            <ScrollableBarChartV2 data={barChartData} scoreType={monthTab} isNoData={isNoData} />

            {!!first5MedicineChartData &&
              !!first5MedicineChartData.data &&
              first5MedicineChartData.data.length > 0 && (
                <>
                  <div className="page-spacer-15" />
                  {/* line bar chart for 薬 */}
                  <ScrollableLineChartV2
                    chartData={isShowMoreMedicine ? allMedicineChartData : first5MedicineChartData}
                    clickTitle={hideMoreMedicine}
                  />

                  {allMedicineChartData.data.length > 5 && !isShowMoreMedicine && (
                    <div onClick={showMoreMedicine}>...</div>
                  )}
                </>
              )}
          </>
        ) : (
          <>
            {/* bar chart and scatter chart for 全体, 体温, 血圧 and 活動 */}
            <LineBarScatterChartV2
              data={barChartData}
              scoreType={monthTab}
              isNoData={isNoData}
              columns="7"
            />
          </>
        )}

        {barChartData?.map((data, index) => {
          // future date is not clickable
          if (data.tooltipTitleYYYYMM <= getYearMonthByDate(new Date())) {
            return (
              <HighlightCover
                key={index}
                index={index}
                highlightBarIndex={highlightBarIndex}
                setHighlightBarIndex={setHighlightBarIndex}
                isMedicineChart={monthTab === WEEK_MONTH_CHART_TABS[1].name}
              />
            );
          } else {
            return "";
          }
        })}
      </div>

      {!!barChartData && (!!highlightBarIndex || highlightBarIndex === 0) && (
        <>
          <div className="page-spacer-15" />
          <HalfYearSummaryPanel data={barChartData[highlightBarIndex]} scoreType={monthTab} />
        </>
      )}
    </>
  );
}
