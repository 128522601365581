// 言語jsonファイルのimport
import translation_en from "./en.json";
import translation_ja from "./ja.json";

const resources = {
  ja: {
    translation: translation_ja,
  },
  en: {
    translation: translation_en,
  },
};

export const selectMessages = () => {
  const language = navigator.language as any;
  if (language == "en") {
    return translation_en;
  } else {
    return translation_ja;
  }
};
