import { useRef } from "react";
import { useIonRouter } from "@ionic/react";
import { SCORE_TAB_KEY } from "src/web/constants";
import { ChartDataTypeV2 } from "../LineBarScatterChart/lineBarScatterChartV2";
import { useWeeklySummaryPanelControl } from "./hooks/useWeeklySummaryPanelControl";
import { useIntl } from "react-intl-phraseapp";
import { useCommon } from "src/web/utils/hooks/common";
import {
  WeeklySummaryPanelContents,
  WeeklySummaryPanelHeader,
} from "src/web/components/UiComponents/organisms";
import styles from "./chartSummaryPanel.module.css";

interface WeeklySummaryPanelProps {
  data: ChartDataTypeV2;
  scoreType: string;
  switchTab: (tab: any) => void;
}

export const WeeklySummaryPanel = ({ data, scoreType, switchTab }: WeeklySummaryPanelProps) => {
  const ionRouter = useIonRouter();
  const intl = useIntl();
  const { convertToDate } = useCommon();

  const radarChartRef: React.MutableRefObject<any> = useRef();

  const WEEK_SUMMARY_FOR_AVG = {
    [SCORE_TAB_KEY[0]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.overall",
      }),
      overall: ["BODY_TEMPERATURE", "BLOOD_PRESSURE", "ACTIVITY"],
      timelineType: "",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.overall.no_record",
      }),
    },
    [SCORE_TAB_KEY[1]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.taking_medicine",
      }),
      overall: [],
      timelineType: "TAKING_MEDICINE",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.taking_medicine.no_record",
      }),
      actionUrl: "/home/medication-record",
    },
    [SCORE_TAB_KEY[2]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.temperature",
      }),
      overall: [],
      timelineType: "BODY_TEMPERATURE",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.temperature.no_record",
      }),
    },
    [SCORE_TAB_KEY[3]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.blood_pressure",
      }),
      overall: [],
      timelineType: "BLOOD_PRESSURE",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.blood_pressure.no_record",
      }),
    },
    [SCORE_TAB_KEY[4]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.activity",
      }),
      overall: [],
      timelineType: "ACTIVITY",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.activity.no_record",
      }),
    },
  };

  const { score, levelRank, filteredTimelineList } = useWeeklySummaryPanelControl(
    scoreType,
    data,
    WEEK_SUMMARY_FOR_AVG,
    radarChartRef,
  );

  return (
    <>
      <div className={styles.weekDetailDiv}>
        <div className="page-spacer-25" />

        <WeeklySummaryPanelHeader
          formattedDate={convertToDate(data.date)}
          score={score}
          data={data}
          radarChartRef={radarChartRef}
          levelRank={levelRank}
          switchTab={switchTab}
        />

        <div className="page-spacer-60" />

        <WeeklySummaryPanelContents
          weekSummaryForAvg={WEEK_SUMMARY_FOR_AVG}
          scoreType={scoreType}
          data={data}
          filteredTimelineList={filteredTimelineList}
        />

        <div className="page-spacer-25" />
      </div>
    </>
  );
};
