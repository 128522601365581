import { useState } from "react";
import { useIonViewDidEnter } from "@ionic/react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { DEVICE_CONNECTION_APP_TYPE } from "src/web/constants";
import { MenuType, DeviceType } from "src/web/types";
import { useSelector } from "react-redux";
import { RootState } from "src/web/reducer";
import HealthCareManager from "src/web/plugin/healthcare-manager-plugin";
import { useIntl } from "react-intl-phraseapp";

export function useAutoDataFetchSettingControl() {
  const ionRouter = useAppRouter();
  const intl = useIntl();

  const { bpDevice }: { bpDevice: DeviceType } = useSelector((state: RootState) => {
    return {
      bpDevice: state.sphygmomanometer,
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [openDevice, setOpenDevice] = useState<{
    isOpen: boolean;
    deviceType?: "bp" | "tp";
  }>({
    isOpen: false,
    deviceType: undefined,
  });

  const [menuList, setMenuList] = useState<MenuType[]>([
    {
      title: intl.formatMessage({ id: "page.device_connection_main.menu.activity.title" }),
      subTitles: [
        {
          title: intl.formatMessage({
            id: "page.device_connection_main.menu.activity.steps.title",
          }),
          desc: intl.formatMessage({
            id: "page.device_connection_main.menu.activity.steps.description",
          }),
          url: `/device-connection-activity/${DEVICE_CONNECTION_APP_TYPE.STEPS}`,
        },
        {
          title: intl.formatMessage({
            id: "page.device_connection_main.menu.activity.sleep.title",
          }),
          desc: intl.formatMessage({
            id: "page.device_connection_main.menu.activity.sleep.description",
          }),
          url: `/device-connection-activity/${DEVICE_CONNECTION_APP_TYPE.SLEEP}`,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "page.device_connection_main.menu.vital.title" }),
      subTitles: [
        {
          title: intl.formatMessage({
            id: "page.device_connection_main.menu.vital.blood_pressure.title",
          }),
          desc: intl.formatMessage({
            id: "page.device_connection_main.menu.vital.blood_pressure.description",
          }),
          url: "",
          event: () =>
            setOpenDevice({
              isOpen: true,
              deviceType: "bp",
            }),
          activeStatus: { isActive: false },
        },
        {
          title: intl.formatMessage({
            id: "page.device_connection_main.menu.vital.temperature.title",
          }),
          desc: intl.formatMessage({
            id: "page.device_connection_main.menu.vital.temperature.description",
          }),
          url: "",
          event: () =>
            setOpenDevice({
              isOpen: true,
              deviceType: "tp",
            }),
          activeStatus: { isActive: false },
        },
      ],
    },
  ]);

  const navToSupportList = () => {
    ionRouter.push("/settings/auto-data-fetching/supported-devices");
  };

  const cancelDeviceSelection = () => {
    setOpenDevice({
      isOpen: false,
    });
  };

  const doFetchDeviceStatus = async () => {
    if (!bpDevice?.deviceId) {
      return;
    }

    try {
      setIsLoading(true);
      const bpDevicePairingRes = await HealthCareManager.checkDevicePairing({
        deviceId: bpDevice.deviceId,
      });
      if (bpDevicePairingRes?.result === "OK") {
        menuList[1].subTitles[0].desc = bpDevice.deviceName;
        menuList[1].subTitles[0].activeStatus = { isActive: true };
      } else {
        menuList[1].subTitles[0].desc = intl.formatMessage({
          id: "page.device_connection_main.menu.vital.blood_pressure.description",
        });
        menuList[1].subTitles[0].activeStatus = { isActive: false };
      }
      setMenuList([...menuList]);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useIonViewDidEnter(() => {
    doFetchDeviceStatus();
  });

  return {
    isLoading,
    menuList,
    openDevice,
    cancelDeviceSelection,
    navToSupportList,
    doFetchDeviceStatus,
  };
}
