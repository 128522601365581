import { IonLabel } from "@ionic/react";

interface PageTitleCenterProps {
  title: string;
  subTitle?: string;
  imgSrc?: string;
}

export function PageTitleCenter({ title, subTitle, imgSrc }: PageTitleCenterProps) {
  return (
    <>
      <IonLabel className="p-header2 pre-wrap text-center-block">{title}</IonLabel>

      {!!subTitle && (
        <>
          <div className="page-spacer-45" />
          <IonLabel className="p-body pre-wrap text-center-block">{subTitle}</IonLabel>
        </>
      )}

      {!!imgSrc && (
        <>
          <div className="page-spacer-45" />
          <div className="div_center">
            <img src={imgSrc} alt="" />
          </div>
        </>
      )}
    </>
  );
}
