import { useEffect, useState } from "react";
import { useIonAlert, useIonViewWillEnter } from "@ionic/react";
import { useSelector } from "react-redux";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { useQueryString } from "src/web/hooks/useQueryString";
import { getToday } from "src/web/utils/common";
import { useSymptomQuestionsModel, useSymptomRecordsModel } from "src/web/hooks/models";
import {
  CheckContentType,
  LoadingSymptonQuestionAndAnswerType,
  PageActionCode,
  PageCode,
  RecordedAnswerType,
  SymptonQuestionType,
} from "src/web/types";
import { RootState } from "src/web/reducer";
import { useIntl } from "react-intl-phraseapp";

export function useSymptomControl() {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const [presentAlert] = useIonAlert();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo?.search);
  const currentDate = params?.currentDate ? `${params.currentDate}` : getToday();
  let isDaily = params?.isDaily === "true";

  const { symptomQuestions }: { symptomQuestions: LoadingSymptonQuestionAndAnswerType } =
    useSelector((state: RootState) => state);

  const { isSavingLoading, fetchSymptomQuestionsAndAnswers, saveQuestionnaireAnswer } =
    useSymptomQuestionsModel();

  const { fetchSymptomQuestionnaireAnswerByDate } = useSymptomRecordsModel();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [checkedAnswers, setCheckedAnswers] = useState<RecordedAnswerType>();
  const [questionList, setQuestionList] = useState<SymptonQuestionType[]>();
  const [isShowAlert, setIsShowAlert] = useState(false);

  const getQuestionnaireAndAnswers = async () => {
    // get questions and answers
    await fetchSymptomQuestionsAndAnswers(currentDate);
  };

  const goPrevious = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const goNext = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  /*
    go next question if exist any question
    do submit when on the 日記 page
  */
  const updateAndGoNextQuestion = async (isSkipAnswerFlg?: boolean) => {
    if (questionList) {
      // 保存する
      if (currentQuestionIndex === questionList.length) {
        const isSaved = await handleSave(true);
        setIsShowAlert(!!isSaved);
        // setCurrentQuestionIndex(0); // reset to the first question
      } else if (currentQuestionIndex < questionList.length) {
        // if not the last question, go to next question
        goNext();
        // if click skip, skip the question and clear the existed answer
        if (isSkipAnswerFlg && questionList && checkedAnswers?.checkContent) {
          const theSkipQuestion = checkedAnswers.checkContent.find(
            (item: CheckContentType) => item.inquiryId === questionList[currentQuestionIndex].id,
          );
          if (theSkipQuestion && setCheckedAnswers) {
            theSkipQuestion.answerIds = null;
            setCheckedAnswers({
              ...checkedAnswers,
            });
          }
        }
      }
    }
  };

  const handleConfirm = () => {
    // if click "入力完了" button without select anything item, set the default value as "特になし"
    if (
      !!questionList &&
      checkedAnswers?.checkContent &&
      currentQuestionIndex < questionList.length
    ) {
      const answerIndex = checkedAnswers.checkContent.findIndex(
        (item: CheckContentType) => item.inquiryId === questionList[currentQuestionIndex].id,
      );
      if (answerIndex === -1 && setCheckedAnswers) {
        checkedAnswers.checkContent.push({
          inquiryId: questionList[currentQuestionIndex].id,
          answerIds: [],
        });
        setCheckedAnswers({
          ...checkedAnswers,
        });
      } else if (answerIndex >= 0 && checkedAnswers.checkContent[answerIndex].answerIds === null) {
        // change null to [] if click 特にないので次へ for the multi-option question
        checkedAnswers.checkContent[answerIndex].answerIds = [];
      }
    }
    updateAndGoNextQuestion();
  };

  const gotoQuestionByIndex = (index: number) => {
    setCurrentQuestionIndex(index);
  };

  const handleBack = () => {
    // if is first question
    if (currentQuestionIndex === 0) {
      // GO Back
      const isChanged =
        JSON.stringify(checkedAnswers) !== JSON.stringify(symptomQuestions?.data?.checkedAnswers);
      if (isChanged) {
        presentAlert({
          header: intl.formatMessage({ id: "page.questionaire.alert.discard.title" }),
          mode: "ios",
          buttons: [
            {
              text: intl.formatMessage({ id: "page.questionaire.alert.discard.cancel" }),
              role: "cancel",
              handler: () => {},
            },
            {
              text: intl.formatMessage({ id: "page.questionaire.alert.discard.confirm" }),
              role: "confirm",
              handler: () => ionRouter.goBack(),
            },
          ],
        });
      } else {
        ionRouter.goBack();
      }
    } else {
      goPrevious();
    }
  };

  const handleSave = async (isFinalSave: boolean = true) => {
    if (checkedAnswers) {
      try {
        await saveQuestionnaireAnswer(checkedAnswers, isFinalSave);
        if (!isFinalSave) {
          handleClose();
        }
        return true;
      } catch (error) {
        return false;
      }
    }
  };

  const handleClose = (isNavToReport: boolean = false) => {
    setIsShowAlert(false);
    // refresh questionnair on the report page
    fetchSymptomQuestionnaireAnswerByDate(
      {
        pageCode: PageCode.T03,
        pageActionCode: PageActionCode.T03_A01,
      },
      { currentDate },
    );
    setCurrentQuestionIndex(0);
    if (isNavToReport) {
      // 詳細情報は日付により、変更する
      ionRouter.push(`/home/reports?date=${currentDate}`, "forward", "push");
    } else {
      ionRouter.goBack();
    }
  };

  const handleNavReport = () => {
    handleClose(true);
  };

  useIonViewWillEnter(() => {
    getQuestionnaireAndAnswers();
  });

  useEffect(() => {
    if (symptomQuestions.data && setCheckedAnswers) {
      setQuestionList(symptomQuestions.data.questionList);
      setCheckedAnswers(JSON.parse(JSON.stringify(symptomQuestions.data.checkedAnswers)));
    }
  }, [symptomQuestions.data]);

  // nav to daily page when 編集日記 from report page
  useEffect(() => {
    if (isDaily && questionList) {
      isDaily = false;
      setCurrentQuestionIndex(questionList.length);
    }
  }, [questionList]);

  return {
    isSavingLoading,
    currentDate,
    currentQuestionIndex,
    checkedAnswers,
    questionList,
    isShowAlert,
    goPrevious,
    goNext,
    gotoQuestionByIndex,
    setCheckedAnswers,
    handleBack,
    handleSave,
    updateAndGoNextQuestion,
    handleConfirm,
    handleClose,
    handleNavReport,
  };
}
