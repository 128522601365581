import { actionConst } from "../constants";

export const clearLoadingDisplay = () => {
  return {
    type: actionConst.CLEAR_LOADING_DISPLAY,
  };
};

export const updateLoadingDisplay = (isLoadingDisplay: boolean) => {
  return {
    type: actionConst.UPDATE_LOADING_DISPLAY,
    state: isLoadingDisplay
  };
};
