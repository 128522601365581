import { actionConst } from "../constants";

import { PrescriptionInfoType } from "../pages/PrescriptionInformation/type";

export const clearPrescriptionInfo = () => {
  return {
    type: actionConst.CLEAR_PRESCRIPTION_INFO,
  };
};

export const updatePrescriptionInfo = (prescriptionInfo: PrescriptionInfoType) => {
  return {
    type: actionConst.UPDATE_PRESCRIPTION_INFO,
    state: prescriptionInfo,
  };
};
