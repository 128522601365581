import { useState, useEffect } from "react";
import { IonList, IonItem, IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react";
import { AppLoading, AppTitleWithLine } from "../../../../components/UiComponents";
import { PrescriptionCard } from "../../../../components/PrescriptionCard/prescriptionCard";
import { useQueryString } from "src/web/hooks/useQueryString";
import { clearPrescriptionInfo, updateRefreshInfo } from "../../../../actions";
import { RefreshType } from "../../../../types";
import { RootState } from "../../../../reducer";
import { PRESCRIPTION_LIST } from "../../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import styles from "./medicineNotebook.module.css";
import { getPrescriptions } from "src/web/services";

interface PrescriptionInfo {
  id: number;
  type: string;
  hospital: string;
  pharmacy: string;
  dispensing_date: string;
  medicine_names: string[];
}

export function MedicineNotebook() {
  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  const refreshInfo: RefreshType = useSelector((state: RootState) => {
    return state.refreshInfo;
  });
  const dispatch = useDispatch();
  // 更新フラグ
  const refresh = params.refresh;
  const [pageIndex, setPageIndex] = useState(0);
  const [infiniteLoad, setInfiniteLoad] = useState(false);
  const [listData, setListData] = useState<Map<number, PrescriptionInfo>>(new Map());
  const [listKey, setListKey] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  const getPrescriptionInfo = async (
    pageIndex: number,
    listData: Map<number, PrescriptionInfo>,
    loadFlg: boolean,
    initial: boolean = false,
  ) => {
    if (loadFlg) {
      setLoading(true);
    }
    try {
      let jsonData = await getPrescriptions({
        page_index: pageIndex.toString(),
      });

      setPageIndex(jsonData.index);

      let newMap: Map<number, PrescriptionInfo> = new Map(listData.entries());
      if (initial) {
        newMap = new Map();
      }

      for (let i = 0; i < jsonData.list.length; i++) {
        let item = jsonData.list[i] as PrescriptionInfo;
        newMap.set(item.id, item);
      }

      setListData(newMap);
      let keys = Array.from(newMap.keys());

      if (jsonData.finish_flg) {
        setInfiniteLoad(true);
      }
      setListKey(keys);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if ([refreshInfo[PRESCRIPTION_LIST]]) {
      return;
    }

    // 初期取得
    getPrescriptionInfo(0, new Map(), true);
  }, []);

  useEffect(() => {
    let new_refreshInfo = {
      ...refreshInfo,
    };
    new_refreshInfo[PRESCRIPTION_LIST] = false;
    dispatch(updateRefreshInfo(new_refreshInfo));

    // 初期取得
    getPrescriptionInfo(0, new Map(), true, true);
  }, [refreshInfo[PRESCRIPTION_LIST]]);

  const loadData = async (ev: any) => {
    try {
      await getPrescriptionInfo(pageIndex + 1, listData, false);
    } finally {
      ev.target.complete();
    }
  };

  return (
    <div>
      <IonList lines="none">
        {Array.from(listData.keys()).map((item: any, index: number) => {
          let itemData = listData.get(item)!;
          if (!itemData) {
            return <></>;
          }

          let currentYear = itemData.dispensing_date.substring(0, 4);

          let showDateFlg = false;
          if (index === 0) {
            showDateFlg = true;
          } else {
            let listKey = Array.from(listData.keys());
            let preItemData = listData.get(listKey[index - 1])!;
            let preYear = preItemData.dispensing_date.substring(0, 4);

            if (preYear !== currentYear) {
              showDateFlg = true;
            }
          }

          let newYearKey = "keyYear" + currentYear;

          let itemKey = `${newYearKey}-${item}`;

          return (
            <div key={itemKey}>
              {showDateFlg && (
                <IonItem className={styles.list_item_year}>
                  <AppTitleWithLine title={currentYear} />
                  <div className="page-spacer-30" />
                </IonItem>
              )}
              <IonItem className={styles.list_item}>
                <PrescriptionCard
                  dispensing_date={itemData.dispensing_date}
                  hospital={itemData.hospital}
                  id={itemData.id}
                  pharmacy={itemData.pharmacy}
                  type={itemData.type}
                  medicine_names={itemData.medicine_names}
                />
              </IonItem>
            </div>
          );
        })}
      </IonList>

      <IonInfiniteScroll
        onIonInfinite={loadData}
        disabled={listData.size === 0 ? true : infiniteLoad}
      >
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="Loading more data..."
        ></IonInfiniteScrollContent>
      </IonInfiniteScroll>
      <AppLoading isOpen={loading} />
    </div>
  );
}
