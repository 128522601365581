import { getDataFromApiOrLocal, postDataForSave, deleteData } from "../request/apiManager";
import { alarmInfoItemType } from "../../pages/NotificationSetting/types";
import { DEFAULT_DISEASE_ID } from "src/web/constants";

// get alerm setting
export function getAlarmSetting(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/alarms`, params);
}

// update alerm setting
export function upsertAlarmSetting(data: alarmInfoItemType, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return postDataForSave(`/api/v2/diseases/${pathDiseaseId}/alarms`, data);
}

// delete alerm setting
export function deleteAlarmSetting(data: { time_id: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return deleteData(`/api/v2/diseases/${pathDiseaseId}/alarms/${data.time_id}`);
}
