import { AppTagOne } from "../AppTag/appTagOne";

import styles from "./styles.module.css";

interface SmallTile {
  className?: string;
  onClick?: (event: any) => void;
  textTitle?: string[];
  tagText?: string;
  src: string;
}

export function AppSmallTile(props: SmallTile) {
  return (
    <div
      className={`${
        props.tagText
          ? props.textTitle
            ? styles.divContainerTag
            : styles.divContainerTagNoTitle
          : props.textTitle && props.textTitle.length > 1
          ? styles.divContainerTwoLine
          : styles.divContainer
      } ${props.className ? props.className : ""}`}
      onClick={(event) => {
        props.onClick && props.onClick(event);
      }}
    >
      {props.tagText && (
        <div className={styles.tagDiv}>
          <AppTagOne textTitle={props.tagText} />
        </div>
      )}

      <img
        src={props.src}
        alt=""
        className={`${styles.image} ${
          props.textTitle && props.textTitle.length > 1
            ? styles.image_margin_two
            : styles.image_margin_one
        }`}
      ></img>

      {props.textTitle && (
        <div className="div_center">
          <div>
            <div className={styles.title}>{props.textTitle.length >= 1 && props.textTitle[0]}</div>
            {props.textTitle.length > 1 && <div className={styles.title}>{props.textTitle[1]}</div>}
          </div>
        </div>
      )}
    </div>
  );
}
