import { useState, useEffect } from "react";
import { IonPage } from "@ionic/react";
import { useAppRouter } from "../../hooks/useAppRouter";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { AppLogo } from "../../components/UiComponents";
import { PasscodeTemplate } from "./passcodeTemplate";
import { useQueryString } from "src/web/hooks/useQueryString";
import { getScreenLockerPasscode } from "../../utils/common";
import { useAppUpdater } from "../../../web/hooks/useAppUpdater";
import { useIntl } from "react-intl-phraseapp";

const MAX_TRY_TIMES = 3;

export function ScreenLockerPasscodeConfirm() {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  const redirectUrl = params.redirectUrl as string;

  const [passcode, setPasscode] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [tryCount, setTryCount] = useState<number>(MAX_TRY_TIMES);
  const { updateApp } = useAppUpdater();

  const goNext = async () => {
    await updateApp();
    if (redirectUrl) {
      ionRouter.push(redirectUrl, "forward", "replace");
    } else {
      ionRouter.goBack();
    }
  };

  const onInputCompleted = async (val: string) => {
    const thePass = await getScreenLockerPasscode();
    if (val === thePass) {
      setErrorMsg("");
      goNext();
    } else {
      if (tryCount > 1) {
        setPasscode("");
        const updatedTryCount = tryCount - 1;
        setTryCount(updatedTryCount);
        setErrorMsg(
          `${intl.formatMessage({
            id: "page.scree_locker_confirm.error.line1",
          })}\n${intl.formatMessage(
            { id: "page.scree_locker_confirm.error.line2" },
            { updatedTryCount },
          )}`,
        );
      } else {
        signOut(auth)
          .then(() => {})
          .catch(() => {})
          .finally(() => {
            setErrorMsg("");
            setTryCount(MAX_TRY_TIMES);
            ionRouter.push("/login");
          });
      }
    }
  };

  useEffect(() => {
    // handle cancel modal: no action is required for native back
    const handleNativeBack = (ev: any) => {
      ev.detail.register(5, () => {});
    };

    document.addEventListener("ionBackButton", handleNativeBack);

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, []);

  return (
    <IonPage>
      <AppLogo />

      <PasscodeTemplate
        title={intl.formatMessage({ id: "page.scree_locker_confirm.input.title" })}
        description={intl.formatMessage({ id: "page.scree_locker_confirm.input.description" })}
        passcode={passcode}
        setPasscode={setPasscode}
        onInputCompleted={onInputCompleted}
        errorMsg={errorMsg}
      />
    </IonPage>
  );
}
