import { useMemo } from "react";
import { IonContent, IonPage } from "@ionic/react";
import {
  AppPrimaryButton,
  AppLoading,
  AppTextLink,
  AppLogo,
  AppHomeIndicatorBar,
} from "../../components/UiComponents";
import { useLoginControl } from "./hooks/useLoginControl";
import { useLoginRedirectControl } from "./hooks/useLoginRedirectControl";
import { LoginForm } from "src/web/components/UiComponents/organisms/LoginForm/loginForm";
import styles from "./login.module.css";
import loginImg from "src/web/assets/img/login/login_img.png";
import { useIntl } from "react-intl-phraseapp";

const Login = () => {
  const {
    isEmailFromUrl,
    userInfo,
    isLoading,
    hasLoginError,
    isLoginBtnDisabled,
    handleUserNameInputChange,
    handlePasswordInputChange,
    handleLogin,
  } = useLoginControl();
  const intl = useIntl();

  const { handleNavToCreateNewUser, handleNavToForgetPassword } = useLoginRedirectControl();

  const PAGE_CONFIG = useMemo(
    () =>
      isEmailFromUrl
        ? {
            title: `${intl.formatMessage({
              id: "page.login.already_registered.message.line1",
            })}\n${intl.formatMessage({
              id: "page.login.already_registered.message.line2",
            })}\n${intl.formatMessage({ id: "page.login.already_registered.message.line3" })}}`,
          }
        : {
            title: intl.formatMessage({ id: "page.login.welcome.message" }),
            imgSrc: loginImg,
          },
    [isEmailFromUrl],
  );

  return (
    <>
      <IonPage>
        <AppLogo />
        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <LoginForm
              pageConfig={PAGE_CONFIG}
              userInfo={userInfo}
              hasLoginError={hasLoginError}
              handleUserNameInputChange={handleUserNameInputChange}
              handlePasswordInputChange={handlePasswordInputChange}
              handleNavToForgetPassword={handleNavToForgetPassword}
            />

            <div className="page-spacer-60" />
            <div className={`root-content div_center`}>
              <AppPrimaryButton disabled={isLoginBtnDisabled} full onClick={handleLogin}>
                {intl.formatMessage({ id: "page.login.button.login" })}
              </AppPrimaryButton>
            </div>
            {!isEmailFromUrl && (
              <>
                <div className="page-spacer-15" />
                <div
                  className={`${styles.login_link_div} div_center`}
                  onClick={handleNavToCreateNewUser}
                >
                  <AppTextLink>
                    {intl.formatMessage({ id: "page.login.link.registeration" })}
                  </AppTextLink>
                </div>
              </>
            )}
            <AppHomeIndicatorBar />
            <div id="verifierContainer" />
          </div>
        </IonContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
};

export default Login;
