import { useState } from "react";
import { useDispatch } from "react-redux";
import { clearDailyActivityTimeline, updateDailyActivityTimeline } from "../../actions";
import { getReportAnswerByDate, getTimeLine } from "../../services";
import { FuncStatus, PageLogType } from "../../types";
import { processLog } from "../../utils/common";
import { DEFAULT_DISEASE_ID } from "../../constants";
import { updateDailyAnswersAndStatus } from "../../actions/dailyAnswersAndStatusAction";

export function useSymptomRecordsModel() {
  const dispatch = useDispatch();
  const [isLoadingTimeline, setIsLoadingTimeline] = useState(false);
  const [isLoadingQuestionnaireAnswer, setIsLoadingQuestionnaireAnswer] = useState(false);

  const fetchDailyActivityTimeline = async (
    logType: PageLogType,
    data: {
      currentDate: string;
    },
  ) => {
    setIsLoadingTimeline(true);
    processLog(logType, FuncStatus.START);
    dispatch(
      updateDailyActivityTimeline({
        isLoading: true,
      }),
    );
    try {
      const res = await getTimeLine(data);
      dispatch(
        updateDailyActivityTimeline({
          isLoading: false,
          list: res,
        }),
      );
    } catch (error: any) {
      dispatch(clearDailyActivityTimeline());
    } finally {
      processLog(logType, FuncStatus.END);
      setIsLoadingTimeline(false);
    }
  };

  const fetchSymptomQuestionnaireAnswerByDate = async (
    logType: PageLogType,
    data: {
      currentDate: string;
    },
  ) => {
    setIsLoadingQuestionnaireAnswer(true);
    dispatch(
      updateDailyAnswersAndStatus({
        isLoading: true,
      }),
    );
    processLog(logType, FuncStatus.START);
    try {
      const res = await getReportAnswerByDate({
        ...data,
        id: DEFAULT_DISEASE_ID,
      });
      const ANSWER_TYPES: any = {
        b: "symptomQuestionnaire",
        d: "symptomQuestionnaire",
        a: "bodyTemperature",
        c: "bloodPressure",
      };
      let isBloodPressureUnfinished = false;
      let isSymptomQuestionnaireUnfinished = false;

      for (let key in ANSWER_TYPES) {
        let rank = res.summary_score[`${key}_r`] === "" ? "-" : res.summary_score[`${key}_r`];
        if (!rank || rank === "-") {
          let value = ANSWER_TYPES[key];
          if (value === "bloodPressure") {
            isBloodPressureUnfinished = true;
          } else if (value === "symptomQuestionnaire") {
            isSymptomQuestionnaireUnfinished = true;
          }
        }
      }

      //TODO: mock data, to be removed later -start
      res.check_content_display.a = [
        { answer: "1000歩", inquiry: "歩数", inquiry_id: 1, multiselection_flag: 0 },
        { answer: "良好", inquiry: "睡眠", inquiry_id: 2, multiselection_flag: 0 },
      ];
      //TODO: mock data, to be removed later -end

      dispatch(
        updateDailyAnswersAndStatus({
          isLoading: false,
          data: {
            isBloodPressureUnfinished,
            isSymptomQuestionnaireUnfinished,
            questionnaireAndScore: res,
          },
        }),
      );
    } catch (error: any) {
      dispatch(
        updateDailyAnswersAndStatus({
          isLoading: false,
          data: {
            isBloodPressureUnfinished: true,
            isSymptomQuestionnaireUnfinished: true,
            questionnaireAndScore: undefined,
          },
        }),
      );
    } finally {
      processLog(logType, FuncStatus.END);
      setIsLoadingQuestionnaireAnswer(false);
    }
  };

  return {
    isLoadingSymtpomRecords: isLoadingTimeline || isLoadingQuestionnaireAnswer,
    fetchDailyActivityTimeline,
    fetchSymptomQuestionnaireAnswerByDate,
  };
}
