import { IonContent, IonPage } from "@ionic/react";
import { AppFloatButton, AppHeader, AppLoading } from "../../../components/UiComponents";
import { ChangePasswordForm } from "src/web/components/UiComponents/organisms";
import { useChangePasswordControl } from "./hooks/useChangePasswordControl";
import { useIntl } from "react-intl-phraseapp";

export function ChangePassword() {
  const { isLoading, isError, errorMsg, formData, isSubmitDisabled, handleInputChange, doSubmit } =
    useChangePasswordControl();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppHeader />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <ChangePasswordForm
              title={intl.formatMessage({ id: "page.change_password.label.change_password" })}
              subTitle={intl.formatMessage({
                id: "page.change_password.label.password_information",
              })}
              formData={formData}
              handleInputChange={handleInputChange}
              isError={isError}
              errorMsg={errorMsg}
            />

            <div className="page-spacer-30" />
          </div>

          <AppFloatButton
            label={intl.formatMessage({ id: "page.change_password.button.submit" })}
            isStatic
            disabled={isSubmitDisabled}
            onClick={doSubmit}
          />
        </IonContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
