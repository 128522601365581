import { IonModal, createAnimation } from "@ionic/react";
import { AppIcon } from "..";
import { ICONS } from "../../../constants";
import styles from "./appModalTemplate.module.css";

interface AppModalTemplateProps {
  isOpen: boolean;
  className?: string;
  handleClose?: () => void;
  showBackdrop?: boolean;
  children?: any;
}

export function AppModalTemplate({
  isOpen,
  className,
  handleClose,
  showBackdrop = true,
  children,
}: AppModalTemplateProps) {
  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation("backdropAnimation")
      .addElement(root?.querySelector("ion-backdrop")!)
      .keyframes([
        { offset: 0, opacity: "0" },
        { offset: 1, opacity: "var(--ion-backdrop-opacity)" },
      ])
      .duration(300);

    const wrapperAnimation = createAnimation("wrapperAnimation")
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0", transform: "translateY(60px)" },
        { offset: 1, opacity: "1", transform: "translateY(0)" },
      ])
      .easing("cubic-bezier(0.01, 0.42, 0.35, 1)")
      .delay(300);

    const wrapperAnimationNoMovingUp = createAnimation("wrapperAnimation")
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: "0", transform: "translateY(0)" },
        { offset: 1, opacity: "1", transform: "translateY(0)" },
      ])
      .easing("cubic-bezier(0.01, 0.42, 0.35, 1)")
      .delay(300);

    return createAnimation()
      .addElement(baseEl)
      .duration(600)
      .addAnimation([
        backdropAnimation,
        !handleClose ? wrapperAnimation : wrapperAnimationNoMovingUp,
      ]);
  };

  return (
    <IonModal
      className={`${styles.root} ${className ? className : ""}`}
      isOpen={isOpen}
      backdropDismiss={false}
      enterAnimation={enterAnimation}
      showBackdrop={showBackdrop}
    >
      <div className={`${handleClose ? styles.contentUpAnimation : ""}`}>{children}</div>

      {handleClose && (
        <AppIcon
          icon={ICONS.ICON.CLOSE_CIRCLE}
          size="lg"
          className={styles.close}
          color="white"
          onClick={handleClose}
        />
      )}
    </IonModal>
  );
}
