import { IonLabel } from "@ionic/react";
import { useHomeTitleControl } from "./hooks/useHomeTitleControl";
import { useIntl } from "react-intl-phraseapp";

export function HomeTitle() {
  const { formattedCurrentDateWithDay } = useHomeTitleControl();
  const intl = useIntl();

  return (
    <>
      <IonLabel className="p-body">{formattedCurrentDateWithDay}</IonLabel>

      <div className="page-spacer-15" />

      <IonLabel className="p-header1 text-block">
        {intl.formatMessage({ id: "page.main_tab.disease_main_tab.disease_main.greeting.day" })}
      </IonLabel>
      <IonLabel className="p-header1 text-block">
        {intl.formatMessage(
          { id: "page.main_tab.disease_main_tab.disease_main.greeting.name" },
          { name: "まどか" },
        )}
      </IonLabel>

      {/* <div className="page-spacer-15" />
      <IonLabel className='p-body gray'>手術後250日(5ヵ月)</IonLabel> */}
    </>
  );
}
