import { useState, useEffect, Fragment, useRef, useCallback } from "react";
import {
  IonPage,
  IonContent,
  IonLabel,
  useIonViewWillEnter,
  ScrollDetail,
  useIonAlert,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppCalendarNoStatusCommonSheet,
  AppHeader,
  AppIcon,
  AppInput,
  AppSheetInput,
  AppSecondaryButton,
  AppSelectInput,
  AppDoubleSelectInput,
  AppLoading,
  AppFloatButton,
  AppMedicineImage,
  AppActionSheetForPhotoGallerySelect,
} from "../../components/UiComponents";
import {
  ICONS,
  MEDICINE_INFO_NUMBER_OF_DOSES_OPTION,
  MEDICINE_INFO_USES_PRE_DAY_OPTIONS,
  MEDICINE_INFO_USES_INTERVAL_OPTIONS,
  EMPTY_OPTION_STRING_VALUE,
  NUMBER_OPTIONS,
  FIREBASE_FILE_NAMES,
  IMAGES_FOLDER,
  MEDICINE_LIST,
  MEDICINE_CHECK_LIST,
  LocalStorageKey,
  MEDICINE_TYPE,
} from "../../constants";
import { useTextConstants } from "src/web/constants/hooks";
import { useCommon } from "src/web/utils/hooks/common";
import { useAlarm } from "src/web/utils/hooks/alarm";
import {
  getToday,
  getDateStrByDate,
  generateBlankEmptyOptions,
} from "../../utils/common";

import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { CameraSource } from "@capacitor/camera";
import {
  clearMedicineInfo,
  updateMedicineInfo,
  updatePrescriptionInfo,
  updateRefreshInfo,
} from "../../actions";
import { RootState } from "../../reducer";
import {
  MedicineInfoType,
  MedicineInfoTimingType,
  FrequencyType,
  MedicineTimeingType,
} from "src/web/types";
import { PrescriptionInfoType } from "../PrescriptionInformation/type";
import { FrequencyActionSheet, TimingActionSheet, MedicineCopyModal } from "./components";
import { ErrorForm, RefreshType, SelectOption } from "../../types";
import { useQueryString } from "src/web/hooks/useQueryString";
import { storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";
import { useAppRouter } from "../../hooks/useAppRouter";
import styles from "./medicineInformation.module.css";
import { Storage } from "@capacitor/storage";
import { useIntl } from "react-intl-phraseapp";
import { getMedicines, getMedicinesById, insertOrUpdateMedicine } from "src/web/services";

export const INIT_FREQUENCY = {
  type: undefined,
  week_day: [],
  every_gap: "",
  unit: "",
};

/* the possiable url:
  1. new medicine: /medicine-information
  2. navigate from お薬手帳 page: /medicine-information?index=<index>
  3. navigate from medicine-information-details page: /medicine-information?id=<medicine_id>
*/
export function MedicineInformation() {
  const intl = useIntl();
  const {
    FORM_ERROR_TYPES,
    MEDICINE_INFO_SHAPE_CODE_OPTIONS,
    MEDICINE_QUANTITY_UNIT,
    MEDICINE_INFO_RULES_OF_USE_OPTION,
    MEDICINE_TIMING_OPTIONS,
    MEDICINE_TIMING_UNIT_OPTIONS
  } = useTextConstants();
  const { formatFrequencyText, generateLabeledEmptyOptions, confirmNotSave } = useCommon();
  const { refreshAlarm } = useAlarm();
  const [presentAlert] = useIonAlert();

  const ionRouter = useAppRouter();

  const dispatch = useDispatch();
  const { parseQs } = useQueryString();

  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const mNameRef = useRef<any>();

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  // 全画面の情報を取得する
  const summaryData: PrescriptionInfoType = useSelector((state: RootState) => {
    return state.prescriptionInformation;
  });
  const refreshInfo: RefreshType = useSelector((state: RootState) => {
    return state.refreshInfo;
  });
  const formData: MedicineInfoType = useSelector((state: RootState) => state.medicineInformation);

  const { photos, takePhoto } = usePhotoGallery();

  const params = parseQs(ionRouter.routeInfo.search);
  // 薬インデックス, default to the first record
  const medicineIdx = params.index ? parseInt(params.index as string) : 0;
  // 薬id情報
  const medicineId = params.id as string;
  // 処方id情報
  const prescriptionId = params.prescription_id as string;

  // 引数に市販薬の引数をもらう
  const [isMarketingFlg, setIsMarketingFlg] = useState(
    summaryData?.type === MEDICINE_TYPE.MARKETING,
  );

  // 初期時の情報
  const [initialValue, setInitialValue] = useState("");

  useIonViewDidEnter(() => {
    // 初期の画面情報を記録する
    setInitialValue(JSON.stringify(formData));
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const validation = {
    required: ["m_name"],
  };
  const validationMapping: any = {
    m_name: mNameRef,
  };
  const [errors, setErrors] = useState<ErrorForm>({
    m_name: { ...FORM_ERROR_TYPES.REQUIRED, ref: validationMapping.m_name },
  });

  // 薬の写真
  const [openTakePhotoActionSheet, setOpenTakePhotoActionSheet] = useState(false);

  // 服薬頻度
  const [openFrequencyActionSheet, setOpenFrequencyActionSheet] = useState<{
    isOpen: boolean;
    initValue?: FrequencyType;
  }>({
    isOpen: false,
  });

  // 回目
  const [openTimingActionSheet, setOpenTimingActionSheet] = useState<{
    isOpen: boolean;
    initValue?: any;
    index?: number;
    title?: string;
  }>({
    isOpen: false,
  });

  // 開始日時 / 終了日時
  const [openCalendar, setOpenCalendar] = useState<{
    isOpen: boolean;
    selectedDate: Date;
    field: string;
    disabledBeforeDate?: string;
    disabledAfterDate?: string;
  }>({
    isOpen: false,
    selectedDate: new Date(),
    field: "",
    disabledBeforeDate: "",
    disabledAfterDate: "",
  });

  // 開始時間 / 終了時間
  const [openStartEndTimingActionSheet, setOpenStartEndTimingActionSheet] = useState<{
    isOpen: boolean;
    initValue?: any;
    field?: string;
    title?: string;
  }>({
    isOpen: false,
  });

  // 終了日の利用できること
  const [endDateDisable, setEndDateDisable] = useState(false);

  // when change the 剤形, reset all the related dynamic fields
  const setShapeCode = (val: any) => {
    dispatch(
      updateMedicineInfo({
        ...formData,
        m_shape_code: val,
        frequency: undefined,
        dosage_num: undefined,
        medication_timings: undefined,

        one_day_quantity: undefined,
        gap_time: undefined,
        usage: undefined,
        once_quantity: undefined,
        once_quantity_unit: undefined,
      }),
    );
  };

  // const getDate = async () => {
  //   const res = await getMedicine({key: "カリーユ"})
  //   console.info(res)
  // }

  const setFormData = (fields: string[], values: any[]) => {
    fields.forEach((field: string, index: number) => {
      if (validation.required.includes(field) && !values[index]) {
        errors[field] = { ...FORM_ERROR_TYPES.REQUIRED, ref: validationMapping[field] };
      } else {
        delete errors[field];
      }

      formData[field] = values[index];
    });

    setErrors({ ...errors });

    dispatch(
      updateMedicineInfo({
        ...formData,
      }),
    );
  };

  const doSubmit = async () => {
    // console.info("errors -> ", errors);
    // console.info("do submit -> ", formData);

    if (Object.keys(errors).length === 0) {
      setIsSubmitted(false);

      const prescription_id: number | undefined = prescriptionId
        ? parseInt(prescriptionId)
        : formData["prescription_id"]
        ? formData["prescription_id"]
        : undefined;

      // 薬id
      if (medicineId || prescription_id) {
        // 薬id存在すれば、データを更新する
        let updateFlg = medicineId;

        try {
          setIsLoading(true);
          // 新規
          // let jsonData = await postData("prescription", "/api/medicine/upsertMedicine", {
          //   ...formData,
          //   id: medicineId,
          //   prescription_id,
          //   currentDate: getToday(),
          // });
          let jsonData = await insertOrUpdateMedicine({
            ...formData,
            id: parseInt(medicineId),
            prescription_id,
            currentDate: getToday(),
          });

          let new_refreshInfo = {
            ...refreshInfo,
          };
          new_refreshInfo[MEDICINE_LIST] = true;
          new_refreshInfo[MEDICINE_CHECK_LIST] = true;
          dispatch(updateRefreshInfo(new_refreshInfo));

          // 薬id
          let mdId = parseInt(medicineId);

          if (!medicineId && prescription_id) {
            mdId = parseInt(jsonData["medicine_id"]);

            // 薬idは存在している場合、
            // 配列のid位置のデータを置き換えます。
          } else if (updateFlg) {
            let newMedicineInfo = {
              ...formData,
            };

            let index = -1;
            // 薬リストのデータを更新する
            if (summaryData.medicines) {
              for (let i = 0; i < summaryData.medicines.length; i++) {
                if (summaryData.medicines[i].id === newMedicineInfo.id) {
                  index = i;
                  break;
                }
              }
              if (index > 0) {
                summaryData.medicines[index] = newMedicineInfo;
                dispatch(updatePrescriptionInfo(summaryData));
              }
            }
          }

          const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

          const imageRef = ref(
            storage,
            `${IMAGES_FOLDER}/${userId.value}/${prescription_id}/${mdId}/${FIREBASE_FILE_NAMES.medicineFileName}`,
          );

          // 画像変更
          if (formData.changeImgFlg) {
            try {
              if (!formData.photoWebviewPath) {
                await deleteObject(imageRef);
              } else {
                const response = await fetch(formData.photoWebviewPath!);
                const blob = await response.blob();
                const uploadResult = await uploadBytes(imageRef, blob);
                // パスを再設定する
                formData.urlPath = await getDownloadURL(imageRef);
              }
            } catch {}
          }

          await refreshAlarm();

          setIsLoading(false);
          ionRouter.goBack();
          return;
        } catch (error) {
          setIsLoading(false);
          ionRouter.goBack();
        }
        return;
      }

      // init a medicines property if not existed
      summaryData.medicines = summaryData.medicines ?? [];
      summaryData.medicines[medicineIdx] = formData;

      // 新規の場合
      summaryData.type = isMarketingFlg ? MEDICINE_TYPE.MARKETING : MEDICINE_TYPE.PRESCRIPTION;
      // 内容を更新します。
      dispatch(updatePrescriptionInfo(summaryData));
      // 画面の遷移先を変更します。
      if (params.index) {
        // clear medicine info
        dispatch(clearMedicineInfo());
        ionRouter.push(`/home/prescription-information`, "forward", "push");
      } else {
        ionRouter.push("/prescription-hospital?type=new", "forward", "push");
      }
    } else {
      Object.values(errors)[0].ref.current.scrollIntoView({ behavior: "smooth" });
      setIsSubmitted(true);
    }
  };

  const formatTimingText = (value?: MedicineTimeingType) => {
    if (value?.time) {
      return value?.time;
    } else if (value?.type || value?.type === EMPTY_OPTION_STRING_VALUE) {
      const selected = generateLabeledEmptyOptions(MEDICINE_TIMING_OPTIONS, true).find(
        (opt: SelectOption) => opt.value === value?.type,
      );
      return selected?.text;
    } else {
      return "";
    }
  };

  const initDynamicFrequencyList = (length: number) => {
    let tmpList = formData.medication_timings
      ? [...formData.medication_timings].slice(0, length)
      : [];
    const numOfRecordToAppend = length - tmpList.length;
    for (let i = 0; i < numOfRecordToAppend; i++) {
      tmpList.push({
        medication_timing: {
          type: undefined,
          time: "",
        },
        quntity: "",
        unit: undefined,
      });
    }
    return tmpList;
  };

  const getUrl = async (formData: any) => {
    const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });
    // url パス
    const imageRef = ref(
      storage,
      `${IMAGES_FOLDER}/${userId.value}/${formData.prescription_id}/${formData.id}/${FIREBASE_FILE_NAMES.medicineFileName}`,
    );
    try {
      return await getDownloadURL(imageRef);
    } catch {}
    return "";
  };

  const initError = (data: MedicineInfoType) => {
    Object.keys(errors).map((key) => {
      if (data[key]) {
        delete errors[key];
      }
    });
    setErrors({ ...errors });
  };

  const getMedicineInfo = useCallback(async (medicineId: string) => {
    try {
      setIsLoading(true);
      dispatch(clearMedicineInfo());
      let jsonData: MedicineInfoType = await getMedicinesById(medicineId, {
        currentDate: getToday(),
      });

      // init errors
      initError(jsonData);

      // 画像を取得する
      let picPath = await getUrl(jsonData);

      setIsMarketingFlg(jsonData.type === MEDICINE_TYPE.MARKETING);

      dispatch(
        updateMedicineInfo({
          ...jsonData,
          photoWebviewPath: picPath,
        }),
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  useIonViewWillEnter(() => {
    if (!medicineId && (summaryData?.medicines?.length ?? 0) > medicineIdx) {
      dispatch(
        updateMedicineInfo({
          ...JSON.parse(JSON.stringify(summaryData.medicines![medicineIdx])),
        }),
      );
    }

    try {
      scrollToTop();
    } catch {}
  }, [summaryData, medicineIdx, medicineId]);

  useEffect(() => {
    if (medicineId) {
      // データが存在する
      if (formData.id === parseInt(medicineId)) {
        initError(formData);
        setIsMarketingFlg(formData.type === MEDICINE_TYPE.MARKETING);
      } else {
        getMedicineInfo(medicineId);
      }
    }
    if (formData) {
      if (formData.start_date) {
        setEndDateDisable(false);
      } else {
        setEndDateDisable(true);
      }
    }
    //   getDate()
  }, [medicineId, formData.id, getMedicineInfo]);

  useEffect(() => {
    if (photos && photos.length) {
      setFormData(["photoWebviewPath"], [photos[0].webviewPath!]);
      setFormData(["changeImgFlg"], [true]);
    }
  }, [photos]);

  const calVal = (startDate: Date, cnt: number, frequency: any) => {
    // 頻度指定しない
    if (!frequency) {
      let endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + cnt - 1,
      );

      return endDate;
    }
    let type = frequency.type;

    // 指定しないと毎日の場合下記のように計算ｎします。
    if (type === "0" || type === "01") {
      let endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + cnt - 1,
      );

      return endDate;
      // 一日おき
    } else if (type === "02") {
      let endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + (cnt - 1) * 2,
      );

      return endDate;
      // 二日おき
    } else if (type === "03") {
      let endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + (cnt - 1) * 3,
      );

      return endDate;
      // 毎週ごと
    } else if (type === "04") {
      let day = startDate.getDay().toString();
      let addDay = Math.ceil(cnt / frequency.week_day.length) * 7;
      // 当日を含む場合当日を除きます。
      if (frequency.week_day.includes(day)) {
        addDay = Math.ceil((cnt - 1) / frequency.week_day.length) * 7;
      }
      let endDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + addDay,
      );
      return endDate;
      // カスタマイズの場合
    } else if (type === "05") {
      // 単位
      let unit = frequency.unit;
      let every_gap = parseInt(frequency.every_gap);
      // 置き日時はない場合
      if (isNaN(every_gap)) {
        return null;
      }
      // 日ごと
      if (unit === "01") {
        let endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + (cnt - 1) * every_gap,
        );
        return endDate;
        // 週ごと
      } else if (unit === "02") {
        let day = startDate.getDay().toString();
        let addDay = Math.ceil(cnt / frequency.week_day.length) * 7 * every_gap;
        // 当日を含む場合当日を除きます。
        if (frequency.week_day.includes(day)) {
          addDay = Math.ceil((cnt - 1) / frequency.week_day.length) * 7 * every_gap;
        }
        let endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + addDay,
        );
        return endDate;
        // 月ごと
      } else if (unit === "03") {
        let endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + (cnt - 1) * every_gap,
          startDate.getDate(),
        );
        return endDate;
        // としごと
      } else if (unit === "04") {
        let endDate = new Date(
          startDate.getFullYear() + (cnt - 1) * every_gap,
          startDate.getMonth(),
          startDate.getDate(),
        );
        return endDate;
      }
    }

    return null;
  };

  // 終了日を計算する
  const getEndDate = (val?: any[], date?: string, frequency?: any) => {
    if (date && val) {
      let dateValue = Date.parse(date);

      if (isNaN(dateValue)) {
        return null;
      }
      if (val.length !== 2) {
        return null;
      }

      let cnt = parseInt(val[0]);
      if (isNaN(cnt)) {
        return null;
      }

      if (cnt < 1) {
        return null;
      }

      let category = val[1];
      // 日分固定の場合
      if (category !== "01") {
        return null;
      }

      let startDate = new Date(dateValue);
      let endDate = calVal(startDate, cnt, frequency);
      if (endDate) {
        return getDateStrByDate(endDate);
      }
      return null;
    } else {
      return null;
    }
  };

  // スクロール関連
  const [isDisplayBorder, setIsDisplayBorder] = useState(false);

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    // setIsDisplayBorder(true);
    if (ev.detail.scrollTop === 0) {
      setIsDisplayBorder(false);
    } else {
      setIsDisplayBorder(true);
    }
  };

  // 薬コピー画面を開く
  const [openMedicineCopyModel, setOpenMedicineCopyModel] = useState<{
    isOpen: boolean;
    imgPath?: string;
    medicineInfo?: MedicineInfoType;
  }>({
    isOpen: false,
  });

  // 薬の情報を取得する
  const processGetSameMedicineInfo = async (medicineName?: string) => {
    // 空値の場合処理しない
    // 更新の場合、コピーしない
    if (!medicineName || formData.id) {
      return;
    }

    try {
      setIsLoading(true);
      //Richard
      // let jsonData = await getData("getMedicine", "/api/medicine/getMedicine", {
      //   m_name: medicineName,
      //   type: summaryData?.type ?? "",
      // });
      let jsonData = await getMedicines({
        m_name: medicineName,
        type: summaryData?.type ?? "",
      });

      const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

      // url パス
      const imageRef = ref(
        storage,
        `${IMAGES_FOLDER}/${userId.value}/${jsonData["prescription_id"]}/${jsonData["id"]!}/${
          FIREBASE_FILE_NAMES.medicineFileName
        }`,
      );
      let imgPath = "";
      try {
        imgPath = await getDownloadURL(imageRef);
      } catch {}

      // コピー画面を開く
      setOpenMedicineCopyModel({
        medicineInfo: jsonData as MedicineInfoType,
        imgPath: imgPath,
        isOpen: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <IonPage>
        <AppHeader
          backAction={() => {
            if (isLoading) return;

            // 変更の場合、確認メッセージを表示します。
            confirmNotSave(initialValue !== JSON.stringify(formData), presentAlert, ionRouter);
          }}
          isDisplayBorder={isDisplayBorder}
        >
          <AppSecondaryButton
            isDynamicWidth
            transparent={true}
            onClick={() => {
              if (isLoading) return;

              // 変更の場合、確認メッセージを表示します。
              confirmNotSave(initialValue !== JSON.stringify(formData), presentAlert, ionRouter);
            }}
          >
            {intl.formatMessage({ id: "page.mediciton_information.button.cancel" })}
          </AppSecondaryButton>
        </AppHeader>

        <IonContent ref={contentRef} scrollEvents={true} onIonScroll={handleScroll}>
          <form
            className="root-content"
            // onSubmit={handleSubmit(doSubmit)}
          >
            <div className="page-spacer-45" />
            {/* 薬タイプにより、タイトルを変更します。 */}
            <IonLabel className="p-header1">
              {isMarketingFlg
                ? intl.formatMessage({ id: "page.mediciton_information.type.over_the_counter" })
                : intl.formatMessage({ id: "page.mediciton_information.type.prescription" })}
            </IonLabel>

            <div className="page-spacer-45" />

            <div
              className={styles.medicineNameIconWrapper}
              onClick={() => {
                if (isLoading) return;

                if (formData.photoWebviewPath) {
                  // save to prescriptionInformation to avoid data lost
                  if (
                    !medicineId &&
                    summaryData.medicines &&
                    (summaryData?.medicines?.length ?? 0) > medicineIdx
                  ) {
                    summaryData.medicines[medicineIdx] = formData;
                    dispatch(updatePrescriptionInfo(summaryData));
                  }

                  ionRouter.push(`/big-img?type=1&medicineIdx=${medicineIdx}`);
                } else {
                  setOpenTakePhotoActionSheet(true);
                }
              }}
            >
              {formData.photoWebviewPath ? (
                <>
                  <AppMedicineImage
                    src={formData.photoWebviewPath}
                    size="MediumBigBig"
                    grayBg={true}
                    hideFrame={true}
                  />
                  {/* <img src={formData.photoWebviewPath} className={styles.photo} /> */}
                </>
              ) : (
                <AppIcon icon={ICONS.ICON.CAMERA} size="lg" className={styles.medicineNameIcon} />
              )}
            </div>

            <div className="page-spacer-45" />

            {/* 入力項目のタイトルを変更する */}
            <IonLabel className={styles.inputLabel} ref={mNameRef}>
              {intl.formatMessage({ id: "page.mediciton_information.medicine_name" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <AppInput
              value={formData.m_name}
              maxlength={255}
              onIonChange={(e: any) => setFormData(["m_name"], [e.detail.value])}
              handleSearch={(val: string) => {
                // 検索ボタンの内容はマスターデータを取得します。
                // 後ほど追加します。
              }}
              error={isSubmitted ? errors.m_name : null}
              handleEnter={() => {
                processGetSameMedicineInfo(formData.m_name);
              }}
              onIonBlur={(e: any) => {
                processGetSameMedicineInfo(e.currentTarget.value);
              }}
            />

            {/* 市場薬の場合、剤形は表示しない、直接裏に内服を設定する */}
            {!isMarketingFlg && (
              <>
                <div className="page-spacer-45" />

                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.medicine_form" })}
                </IonLabel>
                <div className="page-spacer-15" />

                <AppSelectInput
                  value={formData.m_shape_code}
                  options={MEDICINE_INFO_SHAPE_CODE_OPTIONS}
                  setValue={(val: string) => setShapeCode(val)}
                  // loadingの時画面
                  isDisabledOpenEvent={isLoading}
                />
              </>
            )}

            <div className="page-spacer-45" />

            {/* 頓用薬: 内滴, 頓服 */}
            {formData.m_shape_code === "02" || formData.m_shape_code === "03" ? (
              <>
                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({
                    id: "page.mediciton_information.label.max_count_per_a_day",
                  })}
                </IonLabel>
                <div className="page-spacer-15" />
                <div className={`${styles.inputWithUnit}`}>
                  <AppSelectInput
                    value={formData.one_day_quantity}
                    options={MEDICINE_INFO_USES_PRE_DAY_OPTIONS}
                    setValue={(val: string) => setFormData(["one_day_quantity"], [val])}
                    // loadingの時画面
                    isDisabledOpenEvent={isLoading}
                    size="sm"
                  />
                </div>
                <IonLabel className={styles.unit}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.unit.count" })}
                </IonLabel>

                <div className="page-spacer-45" />

                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.least_duration" })}
                </IonLabel>
                <div className="page-spacer-15" />
                <div className={`${styles.inputWithUnit}`}>
                  <AppSelectInput
                    value={formData.gap_time}
                    options={MEDICINE_INFO_USES_INTERVAL_OPTIONS}
                    setValue={(val: string) => setFormData(["gap_time"], [val])}
                    // loadingの時画面
                    isDisabledOpenEvent={isLoading}
                    size="sm"
                  />
                </div>
                <IonLabel className={styles.unit}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.unit.time" })}
                </IonLabel>

                <div className="page-spacer-45" />

                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.usage" })}
                </IonLabel>
                <div className="page-spacer-15" />
                <AppSelectInput
                  value={formData.usage}
                  options={MEDICINE_INFO_RULES_OF_USE_OPTION}
                  setValue={(val: string) => setFormData(["usage"], [val])}
                  // loadingの時画面
                  isDisabledOpenEvent={isLoading}
                />
                {/* 以下のコントロールは開始の薬名により、遠いですから、制御しないくて、問題がないです。 */}
                <div className="page-spacer-45" />

                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.once_quantity" })}
                </IonLabel>
                <div className="page-spacer-15" />

                <AppDoubleSelectInput
                  placeholder={intl.formatMessage({
                    id: "page.mediciton_information.label.once_quantity.place_holder",
                  })}
                  value1={formData.once_quantity}
                  value2={formData.once_quantity_unit}
                  options={generateBlankEmptyOptions(NUMBER_OPTIONS, true)}
                  options2={MEDICINE_QUANTITY_UNIT}
                  setValue={(val: any[]) => {
                    setFormData(
                      ["once_quantity", "once_quantity_unit"],
                      val[0] ? [...val] : [val[0], EMPTY_OPTION_STRING_VALUE],
                    );
                  }}
                  size="md"
                />

                <div className="page-spacer-45" />
              </>
            ) : // 日常薬: 内服, 注射, 外用
            formData.m_shape_code === "01" ||
              formData.m_shape_code === "04" ||
              formData.m_shape_code === "05" ? (
              <>
                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.frequency" })}
                </IonLabel>
                <div className="page-spacer-15" />
                <AppSheetInput
                  value={formatFrequencyText(formData.frequency)}
                  onIonChange={(e: any) => {
                    if (!e.detail.value) {
                      setFormData(["frequency"], [JSON.parse(JSON.stringify(INIT_FREQUENCY))]);
                    }
                  }}
                  handleActionSheet={() => {
                    setOpenFrequencyActionSheet({
                      isOpen: true,
                      initValue: formData.frequency,
                    });
                  }}
                />

                <div className="page-spacer-45" />

                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.count" })}
                </IonLabel>
                <div className="page-spacer-15" />
                <div className="flex-row-start">
                  <div className={`${styles.inputWithUnit}`}>
                    <AppSelectInput
                      value={formData.dosage_num}
                      options={MEDICINE_INFO_NUMBER_OF_DOSES_OPTION}
                      setValue={(val: any) => {
                        setFormData(
                          ["dosage_num", "medication_timings"],
                          [val, initDynamicFrequencyList(val)],
                        );
                      }}
                      size="sm"
                    />
                  </div>
                  <IonLabel className={styles.unit}>
                    {intl.formatMessage({ id: "page.mediciton_information.label.unit.count" })}
                  </IonLabel>
                </div>

                <div className="page-spacer-45" />

                {!!formData.medication_timings && formData.medication_timings.length > 0 && (
                  <>
                    <IonLabel className={styles.inputLabel}>
                      {intl.formatMessage({
                        id: "page.mediciton_information.label.timing_and_quantity",
                      })}
                    </IonLabel>
                    <div className="page-spacer-15" />
                    {formData.medication_timings && formData.medication_timings.length ? (
                      <>
                        <div className="page-spacer-7" />
                        {formData.medication_timings.length &&
                          formData.medication_timings.map(
                            (timing: MedicineInfoTimingType, index: number) => (
                              <Fragment key={index}>
                                <IonLabel className={styles.inputLabel}>
                                  {index + 1}
                                  {intl.formatMessage({
                                    id: "page.mediciton_information.label.unit.timing",
                                  })}
                                </IonLabel>
                                <div className="page-spacer-15" />
                                <div>
                                  <AppSheetInput
                                    value={formatTimingText(
                                      formData.medication_timings
                                        ? formData.medication_timings[index].medication_timing
                                        : undefined,
                                    )}
                                    onIonChange={(e: any) => {
                                      if (!e.detail.value) {
                                        formData.medication_timings![index].medication_timing = {
                                          type: "",
                                          time: "",
                                        };
                                        setFormData(
                                          ["medication_timings"],
                                          [formData.medication_timings],
                                        );
                                      }
                                    }}
                                    handleActionSheet={() => {
                                      setOpenTimingActionSheet({
                                        isOpen: true,
                                        initValue: timing.medication_timing,
                                        index,
                                        title: `${index + 1}${intl.formatMessage({
                                          id: "page.mediciton_information.label.unit.timing",
                                        })}`,
                                      });
                                    }}
                                  />
                                </div>

                                <div className="page-spacer-15" />

                                <AppDoubleSelectInput
                                  placeholder={intl.formatMessage({
                                    id: "page.mediciton_information.label.once_quantity.place_holder",
                                  })}
                                  value1={timing.quntity}
                                  value2={timing.unit}
                                  options={generateBlankEmptyOptions(NUMBER_OPTIONS, true)}
                                  options2={MEDICINE_TIMING_UNIT_OPTIONS}
                                  setValue={(val: any[]) => {
                                    const unit = val[0] ? val[1] : EMPTY_OPTION_STRING_VALUE;
                                    formData.medication_timings![index].quntity = val[0];
                                    formData.medication_timings![index].unit = unit;
                                    setFormData(
                                      ["medication_timings"],
                                      [formData.medication_timings],
                                    );
                                  }}
                                  size="md"
                                />

                                {formData.medication_timings?.length !== index + 1 && (
                                  <div className="page-spacer-30" />
                                )}
                              </Fragment>
                            ),
                          )}
                      </>
                    ) : (
                      <div className="page-spacer-11" />
                    )}

                    <div className="page-spacer-45" />
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            <IonLabel className={styles.inputLabel}>
              {intl.formatMessage({ id: "page.mediciton_information.label.dispensing_quantity" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <AppDoubleSelectInput
              value1={formData.dispensing_quantity}
              value2={formData.dispensing_quantity_unit}
              options={generateBlankEmptyOptions(NUMBER_OPTIONS, true)}
              options2={MEDICINE_QUANTITY_UNIT}
              setValue={(val: any[]) => {
                setFormData(
                  ["dispensing_quantity", "dispensing_quantity_unit"],
                  val[0] ? [...val] : [val[0], EMPTY_OPTION_STRING_VALUE],
                );

                // 終了日は存在しない場合
                if (!formData.end_date) {
                  // 時間を計算し、再設定する
                  let endDate = getEndDate(val, formData.start_date, formData.frequency);
                  if (endDate) {
                    setFormData(["end_date"], [endDate]);
                  }
                }
              }}
              size="md"
            />

            <div className="page-spacer-45" />

            {medicineId && (
              <>
                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({
                    id: "page.mediciton_information.label.remaining_quantity",
                  })}
                </IonLabel>
                <div className="page-spacer-15" />
                <AppDoubleSelectInput
                  value1={formData.remaining_quantity}
                  value2={formData.remaining_quantity_unit}
                  options={generateBlankEmptyOptions(NUMBER_OPTIONS, true)}
                  options2={MEDICINE_QUANTITY_UNIT}
                  setValue={(val: any[]) => {
                    setFormData(
                      ["remaining_quantity", "remaining_quantity_unit"],
                      val[0] ? [...val] : [val[0], EMPTY_OPTION_STRING_VALUE],
                    );
                  }}
                  size="md"
                />

                <div className="page-spacer-45" />
              </>
            )}

            <IonLabel className={styles.inputLabel}>
              {intl.formatMessage({ id: "page.mediciton_information.label.start_date" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <div className="flex-row-start">
              <div className={`${styles.inputWithUnit}`}>
                <AppInput
                  value={formData.start_date}
                  onIonChange={(event: any) => {
                    setFormData(["start_date"], [event.detail.value]);
                    // 開始時間は存在しない場合
                    // データある
                    if (event.detail.value) {
                      setEndDateDisable(false);
                    } else {
                      // 終了日をクリアする
                      setEndDateDisable(true);
                      setFormData(["end_date"], [undefined]);
                      return;
                    }

                    // 終了日は存在しない場合
                    if (!formData.end_date) {
                      // 時間を計算し、再設定する
                      let endDate = getEndDate(
                        [formData.dispensing_quantity, formData.dispensing_quantity_unit],
                        event.detail.value,
                        formData.frequency,
                      );
                      if (endDate) {
                        setFormData(["end_date"], [endDate]);
                      }
                    }
                  }}
                  handleCalendar={() => {
                    setOpenCalendar({
                      isOpen: true,
                      selectedDate: formData.start_date
                        ? new Date(formData.start_date)
                        : new Date(),
                      field: "start_date",
                      // 選択制御の日付を設定する
                      disabledBeforeDate: "",
                      disabledAfterDate: formData.end_date,
                    });
                  }}
                  size="md"
                />
              </div>
              {/* 内服, 注射, 外用 */}
              {(formData.m_shape_code === "01" ||
                formData.m_shape_code === "04" ||
                formData.m_shape_code === "05") && (
                <AppSheetInput
                  value={formatTimingText(formData.start_time)}
                  onIonChange={(e: any) => {
                    //TODO: rework on clear
                  }}
                  handleActionSheet={() => {
                    setOpenStartEndTimingActionSheet({
                      isOpen: true,
                      initValue: formData.start_time,
                      field: "start_time",
                      title: intl.formatMessage({
                        id: "page.mediciton_information.label.start_date",
                      }),
                    });
                  }}
                  size="md"
                />
              )}
            </div>

            <div className="page-spacer-45" />

            <IonLabel className={styles.inputLabel}>
              {intl.formatMessage({ id: "page.mediciton_information.label.end_date" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <div className="flex-row-start">
              <div className={`${styles.inputWithUnit}`}>
                <AppInput
                  disabled={endDateDisable}
                  value={formData.end_date}
                  onIonChange={(event: any) => {
                    // 終了日は開始日の前に設定することができないです。
                    if (formData.start_date && event.detail.value < formData.start_date) {
                      return;
                    }

                    setFormData(["end_date"], [event.detail.value]);
                  }}
                  handleCalendar={() => {
                    // 設定できない場合、表示できないです。
                    if (endDateDisable) {
                      return;
                    }
                    setOpenCalendar({
                      isOpen: true,
                      selectedDate: formData.end_date ? new Date(formData.end_date) : new Date(),
                      field: "end_date",
                      // 選択制御の日付を設定する
                      disabledBeforeDate: formData.start_date,
                      disabledAfterDate: "",
                    });
                  }}
                  size="md"
                />
              </div>
              {/* 内服, 注射, 外用 */}
              {
                (formData.m_shape_code === "01" ||
                  formData.m_shape_code === "04" ||
                  formData.m_shape_code === "05") && (
                  <AppSheetInput
                    value={formatTimingText(formData.end_time)}
                    onIonChange={(e: any) => {
                      //TODO: rework on clear
                    }}
                    handleActionSheet={() => {
                      setOpenStartEndTimingActionSheet({
                        isOpen: true,
                        initValue: formData.end_time,
                        field: "end_time",
                        title: "終了日時",
                      });
                    }}
                    size="md"
                  />
                )
                // ))
              }
            </div>

            {isMarketingFlg && (
              <>
                <div className="page-spacer-45" />
                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.expiry_date" })}
                </IonLabel>
                <div className="page-spacer-15" />
                <div className={`${styles.inputWithUnit}`}>
                  <AppInput
                    value={formData.expiry_date}
                    onIonChange={(event: any) => {
                      setFormData(["expiry_date"], [event.detail.value]);
                    }}
                    handleCalendar={() => {
                      setOpenCalendar({
                        isOpen: true,
                        selectedDate: formData.expiry_date
                          ? new Date(formData.expiry_date)
                          : new Date(),
                        field: "expiry_date",
                        // 選択制御の日付を設定する
                        disabledBeforeDate: "",
                        disabledAfterDate: "",
                      });
                    }}
                    size="md"
                  />
                </div>
              </>
            )}

            {/* 診療科と処方医師名は処方だけの項目です */}
            {!isMarketingFlg && (
              <>
                <div className="page-spacer-45" />

                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({ id: "page.mediciton_information.label.department" })}
                </IonLabel>
                <div className="page-spacer-15" />
                <AppInput
                  value={formData.clinical_department}
                  onIonChange={(e: any) => setFormData(["clinical_department"], [e.detail.value])}
                  maxlength={49}
                />

                <div className="page-spacer-45" />

                <IonLabel className={styles.inputLabel}>
                  {intl.formatMessage({
                    id: "page.mediciton_information.label.prescribing_doctor_name",
                  })}
                </IonLabel>
                <div className="page-spacer-15" />
                <AppInput
                  value={formData.prescribing_doctor}
                  onIonChange={(e: any) => {
                    setFormData(["prescribing_doctor"], [e.detail.value]);
                  }}
                  maxlength={49}
                />
              </>
            )}

            <div className="page-spacer-45" />

            <div className="flex-row-between">
              <IonLabel className={styles.inputLabel}>
                {intl.formatMessage({ id: "page.mediciton_information.label.memo" })}
              </IonLabel>
              <AppSecondaryButton
                isDynamicWidth
                transparent={true}
                onClick={() => {
                  ionRouter.push(`/medicine-information-memo?medicineIdx=${medicineIdx}`);
                }}
              >
                {intl.formatMessage({ id: "page.mediciton_information.label.memo.edit.button" })}
              </AppSecondaryButton>
            </div>
            <div className="page-spacer-15" />
            <IonLabel className={styles.memos}>
              {formData.memos ??
                intl.formatMessage({ id: "page.mediciton_information.label.memo.no_data" })}
            </IonLabel>

            <div className="page-spacer-30" />
          </form>

          <AppFloatButton
            label={
              medicineId || prescriptionId
                ? intl.formatMessage({ id: "page.mediciton_information.button.confirm_edit" })
                : isMarketingFlg
                ? intl.formatMessage({ id: "page.mediciton_information.button.prescription.next" })
                : intl.formatMessage({
                    id: "page.mediciton_information.button.over_the_counter.next",
                  })
            }
            onClick={() => doSubmit()}
          />
        </IonContent>
      </IonPage>

      <AppCalendarNoStatusCommonSheet
        isOpen={openCalendar.isOpen}
        disabledAfterDate={openCalendar.disabledAfterDate}
        disabledBeforeDate={openCalendar.disabledBeforeDate}
        closeCarlendar={() =>
          setOpenCalendar({
            ...openCalendar,
            isOpen: false,
            field: "",
            disabledAfterDate: "",
            disabledBeforeDate: "",
          })
        }
        handleConfirmDate={(dateStr: string) => {
          if (openCalendar.field) {
            setFormData([openCalendar.field], [dateStr]);
          }
        }}
        selectedDate={openCalendar.selectedDate}
      />

      <AppActionSheetForPhotoGallerySelect
        isOpen={openTakePhotoActionSheet}
        handleClose={() => setOpenTakePhotoActionSheet(false)}
        handleTakePhoto={takePhoto}
        handleTakeGallery={() => takePhoto({ type: CameraSource.Photos })}
      />

      <FrequencyActionSheet
        isOpen={openFrequencyActionSheet.isOpen}
        initValue={openFrequencyActionSheet.initValue}
        handleClose={() => {
          setOpenFrequencyActionSheet({
            isOpen: false,
            initValue: undefined,
          });
        }}
        handleConfirm={(value: any) => {
          // 空値設定しない
          if (value === null) {
            return;
          }

          setFormData(["frequency"], [value]);

          let endDate = getEndDate(
            [formData.dispensing_quantity, formData.dispensing_quantity_unit],
            formData.start_date,
            value,
          );

          if (endDate) {
            setFormData(["end_date"], [endDate]);
          }
        }}
      />

      <TimingActionSheet
        isOpen={openTimingActionSheet.isOpen}
        initValue={openTimingActionSheet.initValue}
        title={openTimingActionSheet.title}
        handleClose={() => {
          setOpenTimingActionSheet({
            isOpen: false,
          });
        }}
        handleConfirm={(value: any) => {
          if (formData.medication_timings) {
            formData.medication_timings[openTimingActionSheet.index || 0].medication_timing = value;
            setFormData(["medication_timings"], [formData.medication_timings]);
          }
        }}
      />

      <TimingActionSheet
        isOpen={openStartEndTimingActionSheet.isOpen}
        initValue={openStartEndTimingActionSheet.initValue}
        title={openStartEndTimingActionSheet.title}
        handleClose={() => {
          setOpenStartEndTimingActionSheet({
            isOpen: false,
          });
        }}
        handleConfirm={(value: any) => {
          setFormData([openStartEndTimingActionSheet.field!], [value]);
        }}
      />

      <MedicineCopyModal
        isOpen={openMedicineCopyModel.isOpen}
        imgPath={openMedicineCopyModel.imgPath}
        medicineInfo={openMedicineCopyModel.medicineInfo}
        handleCopy={(medicineInfo: MedicineInfoType) => {
          // コピー画面を閉じる
          setOpenMedicineCopyModel({
            ...openMedicineCopyModel,
            isOpen: false,
          });

          // id はそのまま利用します。
          // 他の情報を全部コピーします。
          dispatch(
            updateMedicineInfo({
              ...medicineInfo,
              id: formData.id,
              prescription_id: formData.prescription_id,
              // 開始日と終了日はコピー不要です。
              start_date: undefined,
              start_time: undefined,
              end_date: undefined,
              end_time: undefined,
              // 残量はコピーします。
            }),
          );
        }}
        handleCancel={() => {
          // コピー画面を閉じる
          setOpenMedicineCopyModel({
            ...openMedicineCopyModel,
            isOpen: false,
          });
        }}
      />

      <AppLoading isOpen={isLoading} />
    </>
  );
}
