import { useState } from "react";
import { getCorrespondDayOfWeekForGivenDate } from "src/web/utils/common";

export function useCalendarControl(
  setCurrentWeekStartDate: React.Dispatch<React.SetStateAction<string>>,
) {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);

  const handleOpenCalendar = () => {
    setIsOpenCalendar(true);
  };

  const handleCloseCalendar = () => {
    setIsOpenCalendar(false);
  };

  const handleConfirmAndCloseCalendar = (dateStr: string) => {
    setCurrentWeekStartDate(getCorrespondDayOfWeekForGivenDate(dateStr, 0));
    handleCloseCalendar();
  };

  return {
    isOpenCalendar,
    handleOpenCalendar,
    handleCloseCalendar,
    handleConfirmAndCloseCalendar,
  };
}
