import { IonContent, IonPage } from "@ionic/react";
import { AppHeader, AppLoading, AppPrimaryButton } from "../../../components/UiComponents";
import { auth } from "../../../firebase";
import { ChangeEmailForm } from "src/web/components/UiComponents/organisms/ChangeEmailForm/changeEmailForm";
import { useChangeEmailControl } from "./hooks/useChangeEmailControl";
import { EmailForm } from "src/web/components/UiComponents/organisms";
import { useIntl } from "react-intl-phraseapp";

export function ChangeEmail() {
  enum PageStepEnum {
    PasswordCheck,
    ChangeMailAddress,
  }
  const {
    pageStep,
    isLoading,
    password,
    errorMsg,
    newEmail,
    isCheckPasswordDisabled,
    isNewEmailSavingDisabled,
    handleBack,
    handlePasswordChange,
    handleEmailChange,
    doCheckPassword,
    doSubmitNewEmail,
  } = useChangeEmailControl(PageStepEnum);
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppHeader backAction={handleBack} />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            {pageStep === PageStepEnum.PasswordCheck && (
              <>
                <ChangeEmailForm
                  pageTitle={intl.formatMessage({ id: "page.change_email.label.change_email" })}
                  oldEmail={auth?.currentUser?.email}
                  password={password}
                  handlePasswordChange={handlePasswordChange}
                  errorMsg={errorMsg}
                />

                <div className="page-spacer-60" />

                <AppPrimaryButton full disabled={isCheckPasswordDisabled} onClick={doCheckPassword}>
                  {intl.formatMessage({ id: "page.change_email.button.check_password" })}
                </AppPrimaryButton>
              </>
            )}
            {pageStep === PageStepEnum.ChangeMailAddress && (
              <>
                <EmailForm
                  title={intl.formatMessage({ id: "page.change_email.label.change_email" })}
                  label={intl.formatMessage({ id: "page.change_email.label.new_email" })}
                  userName={newEmail}
                  handleUserNameInputChange={handleEmailChange}
                  isLeftAlign
                />

                <div className="page-spacer-60" />

                <AppPrimaryButton
                  full
                  disabled={isNewEmailSavingDisabled}
                  onClick={doSubmitNewEmail}
                >
                  {intl.formatMessage({ id: "page.change_email.button.submit" })}
                </AppPrimaryButton>
              </>
            )}
          </div>
        </IonContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
