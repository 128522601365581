import { useState, useEffect } from "react";
import { IonPage, IonContent, IonLabel } from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { AppHeader, AppFloatButton, AppTextarea } from "../../components/UiComponents";
import { updatePrescriptionInfo } from "../../actions";
import { RootState } from "../../reducer";
import { useAppRouter } from "../../hooks/useAppRouter";
import { PrescriptionInfoType } from "../PrescriptionInformation/type";
import styles from "./prescriptionMemo.module.css";
import { useIntl } from "react-intl-phraseapp";
import { updatePrescriptionNotesById } from "src/web/services";

export function PrescriptionMemo() {
  const ionRouter = useAppRouter();
  const intl = useIntl();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const currentData: PrescriptionInfoType = useSelector((state: RootState) => {
    return state.prescriptionInformation;
  });

  const [formData, setFormData] = useState({
    notes: currentData.notes,
    memos: currentData.memos,
  });

  const setFormDataValue = (field: string, value: any) => {
    const newData = {
      ...formData,
      [field]: value,
    };
    setFormData(newData);
  };

  useEffect(() => {}, []);

  return (
    <>
      <IonPage>
        <AppHeader />
        <IonContent>
          <div className={styles.content}>
            <div className="page-spacer-45" />
            <IonLabel className={styles.displayTitle}>
              {intl.formatMessage({
                id: "page.prescription_information.prescription.other.notes.title",
              })}
            </IonLabel>
            <div className="page-spacer-15" />
            <AppTextarea
              maxlength={200}
              value={formData.notes}
              onIonChange={(e: any) => {
                setFormDataValue("notes", e.detail.value);
              }}
            />
            <div className="page-spacer-30" />

            <AppFloatButton
              label={intl.formatMessage({ id: "page.prescription_memo.button.complete" })}
              onClick={async () => {
                const newData = {
                  ...currentData,
                  notes: formData.notes,
                };

                if (currentData.id) {
                  const updateDate = {
                    id: newData.id,
                    notes: newData.notes,
                  };

                  setLoading(true);
                  try {
                    // 新規
                    // let jsonData = await postData(
                    //   "prescription",
                    //   "/api/medicine/updatePrescriptionNotes",
                    //   updateDate,
                    // );
                    await updatePrescriptionNotesById(currentData.id, updateDate);
                    dispatch(updatePrescriptionInfo(newData));
                  } finally {
                    setLoading(false);
                  }
                } else {
                  dispatch(updatePrescriptionInfo(newData));
                }

                dispatch(updatePrescriptionInfo(newData));
                ionRouter.goBack();
              }}
            />
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
