import { useState } from "react";
import { IonPage, IonContent, IonLabel } from "@ionic/react";
import { AppHeader, AppPrimaryButton, AppTextarea } from "../../components/UiComponents";
import { useSelector, useDispatch } from "react-redux";
import { MedicineInfoType } from "src/web/types";
import { PrescriptionInfoType } from "../PrescriptionInformation/type";
import { RootState } from "../../reducer";
import { updateMedicineInfo, updatePrescriptionInfo } from "../../actions";
import { useQueryString } from "src/web/hooks/useQueryString";
import { useAppRouter } from "../../hooks/useAppRouter";
import styles from "./medicineInformationMemo.module.css";
import { useIntl } from "react-intl-phraseapp";

export function MedicineInformationMemo() {
  const intl = useIntl();
  const ionRouter = useAppRouter();

  const dispatch = useDispatch();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  // the medicine index in prescription
  const medicineIdx = params.medicineIdx as string;

  const formData: MedicineInfoType = useSelector((state: RootState) => state.medicineInformation);

  // 全画面の情報を取得する
  const prescriptionInformation: PrescriptionInfoType = useSelector((state: RootState) => {
    return state.prescriptionInformation;
  });

  const [memos, setMemos] = useState<string>(formData.memos || "");

  const handleSaveMemos = () => {
    // if can find the matching medicine record in prescriptionInformation, sync the memos as well
    if (
      prescriptionInformation?.medicines &&
      prescriptionInformation?.medicines[parseInt(medicineIdx)]
    ) {
      prescriptionInformation.medicines[parseInt(medicineIdx)].memos = memos;
      dispatch(
        updatePrescriptionInfo({
          ...prescriptionInformation,
        }),
      );
    }

    dispatch(
      updateMedicineInfo({
        ...formData,
        memos,
      }),
    );
    ionRouter.goBack();
  };

  return (
    <IonPage>
      <AppHeader />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-30" />

          <IonLabel className={styles.pageTitle}>
            {intl.formatMessage({ id: "page.medicine_information_memo.label.memos" })}
          </IonLabel>

          <div className="page-spacer-40" />

          <AppTextarea
            maxlength={200}
            value={memos}
            onIonChange={(e: any) => {
              setMemos(e.detail.value);
            }}
          />

          <div className="page-spacer-40" />

          <AppPrimaryButton full={true} onClick={handleSaveMemos}>
            {intl.formatMessage({ id: "page.medicine_information_details.labe.button.save" })}
          </AppPrimaryButton>

          <div className="page-spacer-30" />
        </div>
      </IonContent>
    </IonPage>
  );
}
