import { useState } from "react";
import { useRouteMatch } from "react-router";
import { IonPage } from "@ionic/react";
import { useAppRouter } from "../../hooks/useAppRouter";
import { AppHeader } from "../../components/UiComponents";
import { useQueryString } from "src/web/hooks/useQueryString";
import { PasscodeTemplate } from "./passcodeTemplate";
import {
  enableScreenLocker,
  getScreenLockerPasscode,
  setScreenLockerPasscodeToConfirm,
} from "../../utils/common";
import { useIntl } from "react-intl-phraseapp";

export function ScreenLockerPasscodeInit() {
  const intl = useIntl();
  const PAGE_LABELS = {
    first: {
      title: intl.formatMessage({ id: "page.scree_locker_init.first.title" }),
      description: intl.formatMessage({ id: "page.scree_locker_init.first.description" }),
    },
    second: {
      title: intl.formatMessage({ id: "page.scree_locker_init.second.title" }),
      description: intl.formatMessage({ id: "page.scree_locker_init.second.description" }),
    },
  };

  const ERROR_DESCRIPTION = intl.formatMessage({ id: "page.scree_locker_init.error" });

  const ionRouter = useAppRouter();
  const match: any = useRouteMatch();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  const error = params.error as string;

  const type: "first" | "second" = match.params.type;

  const [passcode, setPasscode] = useState<string>("");

  /*
    if the passcode is "" or undefined, means there is no passcode
    if the passcode with a prefix "_", means the passcode setting is in progress and is not available
    if the passcode without the prefix "_", means the passcode setting is done and is available
  */
  const onInputCompleted = async (val: string) => {
    if (type === "first") {
      await setScreenLockerPasscodeToConfirm(val);
      ionRouter.push("/screen-locker-passcode/second", "forward", "replace");
    } else {
      const theFirstPassStr = await getScreenLockerPasscode();
      const theFirstPass = theFirstPassStr?.split("_").pop();
      if (theFirstPass === val) {
        await enableScreenLocker(val);
        ionRouter.goBack();
      } else {
        ionRouter.push("/screen-locker-passcode/first?error=mismatch", "back", "replace");
      }
    }
  };

  return (
    <>
      <IonPage>
        <AppHeader />

        <PasscodeTemplate
          title={PAGE_LABELS[type].title}
          description={!error ? PAGE_LABELS[type].description : ERROR_DESCRIPTION}
          passcode={passcode}
          setPasscode={setPasscode}
          onInputCompleted={onInputCompleted}
        />
      </IonPage>
    </>
  );
}
