import { AppIcon } from "../../../../../components/UiComponents";
import { ICONS } from "../../../../../constants";
import styles from "../../styles.module.css";

interface FaceScoreType {
  score?: number;
  handleClick: (score: number) => void;
}

export function FaceScore({ score, handleClick }: FaceScoreType) {
  const SCORE_LIST = [
    { icon: ICONS.ICON.FACE_0, score: 0 },
    { icon: ICONS.ICON.FACE_1, score: 1 },
    { icon: ICONS.ICON.FACE_2, score: 2 },
    { icon: ICONS.ICON.FACE_3, score: 3 },
    { icon: ICONS.ICON.FACE_4, score: 4 },
    { icon: ICONS.ICON.FACE_5, score: 5 },
  ];

  return (
    <div className={`div_center ${styles.faceWrapper}`} role="facescoreroot">
      {SCORE_LIST.map((item) => (
        <div
          key={item.icon}
          className={score === item.score ? styles.currectFaceIcon : styles.faceIcon}
          onClick={() => handleClick(item.score)}
        >
          <AppIcon icon={item.icon} />
        </div>
      ))}
    </div>
  );
}
