import { IonCol, IonLabel, IonRow } from "@ionic/react";
import { SymptonQuestionType } from "src/web/types";
import { OptionButton } from "../../moleclues";
import styles from "./quesionOptions.module.css";
import { useIntl } from "react-intl-phraseapp";

interface QuestionOptionsProps {
  question: SymptonQuestionType;
  checkIsActive: (inquiryId: number, answerId: number) => boolean;
  handleClick: (inquiryId: number, answerId: number, isMultiSelect: boolean) => void;
  handleSkip: (isSkipAnswerFlg: boolean) => void;
}

export function QuestionOptions({
  question,
  checkIsActive,
  handleClick,
  handleSkip,
}: QuestionOptionsProps) {
  const intl = useIntl();
  return (
    <>
      <IonRow className="root-content">
        {question?.answers?.map((answer, index) => (
          <IonCol
            key={index}
            size={question?.answers.length === 2 ? "6" : "4"}
            className={`${styles.questionBtnCol} ${
              question?.answers.length === 2
                ? index % 2 === 0
                  ? "ion-justify-content-start"
                  : "ion-justify-content-end"
                : index % 3 === 0
                ? "ion-justify-content-start"
                : index % 3 === 1
                ? "ion-justify-content-center"
                : "ion-justify-content-end"
            }`}
          >
            <OptionButton
              label={answer.answer_option}
              isActive={checkIsActive(answer.disease_inquiry_id, answer.id)}
              onClick={() => {
                handleClick(answer.disease_inquiry_id, answer.id, !!question.multiselection_flag);
              }}
              isMultiSelect={!!question.multiselection_flag}
              inquiryId={question.id}
              isHideTopSpace={index < 3}
            />
          </IonCol>
        ))}
      </IonRow>

      {question.multiselection_flag ? (
        <div className="page-spacer-60" />
      ) : (
        <div className="page-spacer-45" />
      )}

      <IonLabel className="p-body text-center-block" onClick={() => handleSkip(true)}>
        { intl.formatMessage({id: "components.disease_qs_card.input_later"}) }
      </IonLabel>
    </>
  );
}
