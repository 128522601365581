import { IonButton } from "@ionic/react";
import styles from "./appConnectButton.module.css";
import { useIntl } from "react-intl-phraseapp";

export enum AppConnectButtonStatus {
  IDEL = 0,
  CONNECTING = 1,
  FAILED = 2,
}

export function AppConnectButton(props: any) {
  const intl = useIntl();
  const btnStatus = props.status as AppConnectButtonStatus;

  const btnDisabled = props.disabled || btnStatus === AppConnectButtonStatus.CONNECTING;

  const funcDsp = (status: AppConnectButtonStatus) => {
    if (status === AppConnectButtonStatus.IDEL) {
      return intl.formatMessage({ id: "ui_components.app_connect_button.connect" });
    } else if (status === AppConnectButtonStatus.CONNECTING) {
      return intl.formatMessage({ id: "ui_components.app_connect_button.connecting" });
    } else if (status === AppConnectButtonStatus.FAILED) {
      return intl.formatMessage({ id: "ui_components.app_connect_button.failed_connecting" });
    }
  };

  return (
    <IonButton
      {...props}
      className={`${styles.ConnectButton} 
      ${btnDisabled && styles.ConnectButtonDisabled} 
      ${btnStatus === AppConnectButtonStatus.FAILED && styles.ConnectButtonFailed}`}
      disabled={btnDisabled}
    >
      <div>{funcDsp(props.status)}</div>
    </IonButton>
  );
}
