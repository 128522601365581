import { actionConst } from "../constants";
import { AlertInfoType, AlertType } from "../types";

const initialState: AlertInfoType = { status: AlertType.NONE, title: "", msg: "" };

interface Action {
  type: actionConst;
  state: any;
}

export default function alertInfoReducer(state = initialState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_ALERT_INFO:
      return actions.state;
    case actionConst.CLEAR_ALERT_INFO:
      return { ...initialState };
    default:
      return state;
  }
}
