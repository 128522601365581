import { BUTTON_TIMEOUT } from "src/web/constants";
import { CheckContentType, RecordedAnswerType } from "src/web/types";

export function useQuestionControl(
  checkedAnswers?: RecordedAnswerType,
  setCheckedAnswers?: React.Dispatch<React.SetStateAction<RecordedAnswerType | undefined>>,
  goNextQuestion?: Function,
) {
  const setAnswer = (inquiryId: number, answerId: number, isMultiSelect: boolean) => {
    if (checkedAnswers?.checkContent && setCheckedAnswers) {
      const inquiryAnswer: CheckContentType | undefined = checkedAnswers.checkContent.find(
        (item) => item.inquiryId === inquiryId,
      );
      if (inquiryAnswer) {
        // if is an answered question
        const answerIndex: number | undefined = inquiryAnswer.answerIds?.findIndex(
          (item) => `${item}` === `${answerId}`,
        );
        if (answerIndex !== undefined && answerIndex >= 0 && inquiryAnswer.answerIds) {
          inquiryAnswer.answerIds.splice(answerIndex, 1);
        } else {
          // if is multi select, append the new value
          // if is single select, remove the old value and add the new one
          if ((isMultiSelect && inquiryAnswer.answerIds === null) || !isMultiSelect) {
            inquiryAnswer.answerIds = [answerId];
          } else {
            inquiryAnswer.answerIds?.push(answerId);
          }
        }
      } else {
        // if is NOT an answered question
        checkedAnswers.checkContent.push({
          inquiryId: inquiryId,
          answerIds: [answerId],
        });
      }
      setCheckedAnswers({
        ...checkedAnswers,
      });
      if (!isMultiSelect && goNextQuestion) {
        setTimeout(() => {
          goNextQuestion();
        }, BUTTON_TIMEOUT);
      }
    }
  };

  const checkIsActive = (inquiryId: number, answerId: number) => {
    if (checkedAnswers?.checkContent && !!checkedAnswers.checkContent.length) {
      const inquiryAnswer = checkedAnswers.checkContent.find(
        (item) => item.inquiryId === inquiryId,
      );
      if (inquiryAnswer) {
        const isActive = inquiryAnswer?.answerIds?.some((item) => item === answerId);
        return !!isActive;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return {
    setAnswer,
    checkIsActive,
  };
}
