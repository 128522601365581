import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { IonLabel, IonInput, isPlatform } from "@ionic/react";
import { Keyboard } from "@capacitor/keyboard";
import { AppIcon } from "../AppIcon/appIcon";
import { generateBlankEmptyOptions } from "../../../utils/common";
import {
  ICONS,
  EMPTY_OPTION_STRING_VALUE,
  EMPTY_OPTION_NUMBER_VALUE,
  DATE_FORMAT_SPLIT,
} from "../../../constants";
import styles from "./appInput.module.css";
import { SelectOption } from "../../../types";
import { AppTribleSelectOptions } from "../AppSelectOptions/appTribleSelectOptions";

interface AppDateSelectInputProp {
  label?: string;
  placeholder?: string;
  className?: string;
  value: any;
  defaultValue?: any;
  separatedBy?: string;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  options: SelectOption[];
  options2: SelectOption[];
  options3: SelectOption[];
  error?: any;
  errorMsg?: string;
  needClear?: boolean;
  size?: "full" | "md" | "sm" | "xs";
}

export const AppDateSelectInput = forwardRef(
  (
    {
      label,
      placeholder,
      className,
      value,
      defaultValue,
      separatedBy = DATE_FORMAT_SPLIT,
      setValue,
      options,
      options2,
      options3,
      error,
      errorMsg,
      needClear = true, // defalut to true to add "指定しない" option
      size = "full",
      ...rest
    }: AppDateSelectInputProp,
    ref?: any,
  ) => {
    const inputControl: React.MutableRefObject<any> = useRef();
    useImperativeHandle(ref, () => inputControl.current);

    const [isOpen, setIsOpen] = useState(false);

    const getTextByValue = (value: string | number, separatedBy?: string) => {
      const getText = (value: string | number, options: SelectOption[]) => {
        if (
          (value === EMPTY_OPTION_STRING_VALUE || value === EMPTY_OPTION_NUMBER_VALUE || value) &&
          options
        ) {
          if (typeof options[0] === "string") {
            return value;
          } else {
            const result: any = (options as SelectOption[]).find((opt: any) => {
              return (opt as SelectOption).value === value;
            });
            return result.text;
          }
        } else {
          return "";
        }
      };

      const allOptions = generateBlankEmptyOptions(options, needClear);
      const allOptions2 = generateBlankEmptyOptions(options2, needClear);
      const allOptions3 = generateBlankEmptyOptions(options3, needClear);

      if (value) {
        const values = `${value}`.split(separatedBy!);
        const text1 = getText(values[0], allOptions);
        const text2 = getText(values[1], allOptions2);
        const text3 = getText(values[2], allOptions3);
        return text1 || text2 || text3
          ? `${text1}${separatedBy}${text2}${separatedBy}${text3}`
          : "";
      } else {
        return "";
      }
    };

    useEffect(() => {
      // handle cancel modal: no action is required for native back
      const handleNativeBack = (ev: any) => {
        ev.detail.register(3, (processNextHandler: any) => {
          if (isOpen) {
            setIsOpen(false);
          } else {
            processNextHandler();
          }
        });
      };

      if (isOpen) {
        document.addEventListener("ionBackButton", handleNativeBack);
      } else {
        document.removeEventListener("ionBackButton", handleNativeBack);
      }

      return () => {
        document.removeEventListener("ionBackButton", handleNativeBack);
      };
    }, [isOpen]);

    return (
      <>
        {label && (
          <>
            <IonLabel className={styles.input_label}>{label}</IonLabel>
            <div className="page-spacer-15" />
          </>
        )}
        <div className={`${styles.inputWrapper} ${error ? styles.shake : ""}`}>
          <div className={`${styles.input_div} ${styles[size]}`} onClick={() => setIsOpen(true)}>
            <IonInput
              ref={inputControl}
              placeholder={placeholder}
              inputmode="none"
              className={` ${error && styles.input_error} ${
                isOpen ? styles.input_focus : styles.input_no_focus
              } ${styles.input} ${className}`}
              value={getTextByValue(value, separatedBy)}
              {...rest}
              onIonFocus={() => {
                if (isPlatform("capacitor")) {
                  Keyboard.hide();
                }
              }}
            />
            <AppIcon
              key={isOpen ? 1 : 0}
              size="sm"
              icon={ICONS.ICON.ARROW_DOWN}
              className={`${styles.input_icon} ${/*isOpen ? styles.rotateIcon : */ ""}`}
            />
          </div>
          {error && (
            <>
              <IonLabel className="p-input-label-error">{errorMsg || error.message}</IonLabel>
            </>
          )}
        </div>

        <AppTribleSelectOptions
          isOpen={isOpen}
          value={value || defaultValue}
          separatedBy={separatedBy}
          options={generateBlankEmptyOptions(options, needClear)}
          options2={generateBlankEmptyOptions(options2, needClear)}
          options3={generateBlankEmptyOptions(options3, needClear)}
          onClose={() => {
            setIsOpen(false);
          }}
          setValue={(value: any) => {
            setValue(value);
          }}
        />
      </>
    );
  },
);
