import { IonCard, IonCardContent, IonGrid, IonRow, IonCol } from "@ionic/react";
import { AppTagOne } from "../UiComponents";
import { useDispatch } from "react-redux";
import { useAppRouter } from "../../hooks/useAppRouter";
import { clearPrescriptionInfo } from "../../actions";
import { MEDICINE_TYPE } from "../../constants";
import styles from "./prescriptionCard.module.css";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";


export interface PrescriptionCardType {
  id: number;
  type: string;
  hospital: string;
  pharmacy: string;
  dispensing_date: string;
  medicine_names?: string[];
}

export function PrescriptionCard({
  id,
  type,
  hospital,
  pharmacy,
  dispensing_date,
  medicine_names,
}: PrescriptionCardType) {
  const intl = useIntl();
  const ionRouter = useAppRouter();
  const { DAY_CONVERT } = useTextConstants();

  const dispatch = useDispatch();

  const convertToDate = (date: string) => {
    let currentDate = new Date(date);
    let day = currentDate.getDay();
    let dayStr = DAY_CONVERT[day];

    return `${currentDate.getFullYear()}/${
      currentDate.getMonth() + 1
    }/${currentDate.getDate()}(${dayStr})`;
  };

  let display_text = "";
  if (medicine_names) {
    for (let i = 0; i < medicine_names.length; i++) {
      if (i > 1) {
        // display_text += "　他";
        break;
      }

      if (i > 0) {
        display_text += " / ";
      }
      display_text += medicine_names[i];
    }
  }

  return (
    <IonCard
      className={styles.prescription_item_iconCard}
      onClick={(event: any) => {
        dispatch(clearPrescriptionInfo());
        ionRouter.push(`/home/prescription-information?id=${id}`, "forward", "push");
      }}
    >
      <IonCardContent className={styles.prescription_item_ionCardContent}>
        <IonGrid>
          <IonRow>
            <IonCol size="8">
              <div>
                {hospital ? (
                  <div className={`p-header4 ${styles.text}`}>{hospital}</div>
                ) : (
                  <div className={`p-header4 ${styles.text} ${styles.blankText}`}>
                    {type === MEDICINE_TYPE.PRESCRIPTION
                      ? intl.formatMessage({
                          id: "components.prescription_card.prescription.hospital.blank",
                        })
                      : intl.formatMessage({
                          id: "components.prescription_card.over_the_counter.market.blank",
                        })}
                  </div>
                )}

                <div className="page-spacer-10" />

                <div className={`p-smallbody-regular ${styles.text}`}>{display_text}</div>

                <div className="page-spacer-5" />

                <div className={`p-smallbody-regular `}>
                  {type === MEDICINE_TYPE.PRESCRIPTION
                    ? intl.formatMessage(
                        { id: "components.prescription_card.prescription.dispensing_date" },
                        { date: convertToDate(dispensing_date) },
                      )
                    : intl.formatMessage(
                        { id: "components.prescription_card.over_the_counter.dispensing_date" },
                        { date: convertToDate(dispensing_date) },
                      )}
                </div>
              </div>
            </IonCol>
            <IonCol size="4">
              <div className={styles.right_div}>
                <AppTagOne
                  className={`p-smallbody ${styles.item_tag}`}
                  textTitle={
                    type === MEDICINE_TYPE.PRESCRIPTION
                      ? intl.formatMessage({
                          id: "components.prescription_card.prescription.type_name",
                        })
                      : intl.formatMessage({
                          id: "components.prescription_card.over_the_counter.type_name",
                        })
                  }
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
}
