import { Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart, Line } from "recharts";
import { getColor, getToday } from "../../../utils/common";
import { SCORE_TAB_KEY } from "../../../constants";
import { useSelector } from "react-redux";
import { WEEK_GRAPH_DISPLAY } from "../../../constants";
import { RootState } from "../../../reducer";
import { GraphInfoType } from "../../../types";
import { CustomCartesianGrid } from "../CustomCartesianGrid/customCartesianGrid";
import { CurveBar } from "../CurveBar/curveBar";
import {
  BloodPressureDot,
  MedicineLineDot,
  MedicineScatterDot,
  TemperatureDot,
} from "../ChartDots/chartDots";
import { WeeklySummaryPanel } from "../ChartSummaryPanel";
import { useLineBarScatterChartV2Control } from "./hooks/useLineBarScatterChartV2Control";
import styles from "./lineBarScatterChart.module.css";
import { useIntl } from "react-intl-phraseapp";

const CURRENT_TEXT_COLOR = "--ion-text-color";
const CURSOR_COLOR = "--psp-gray03";

const COLOR_MEDICINE = "--ion-color-orange";
const COLOR_TEMPERATURE = "--ion-color-green";
const COLOR_BLOOD_PRESSURE = "--psp-blue";
const COLOR_ACTIVITY = "--ion-color-purple";

export interface ChartDataTypeV2 {
  name: string;
  score: number;
  date: string;
  barTooltipVal?: any;
  lineTooltipVal?: any;
  [field: string]: any;
  dspScore: number;
  sbp?: number;
  dbp?: number;
  activityDuration?: number;
  overallData?: any;
  weekdayTimeline?: any;
}

export function LineBarScatterChartV2(props: {
  data?: ChartDataTypeV2[];
  dataNumber?: { [id: string]: number };
  scoreType: string;
  isNoData: boolean;
  columns?: "6" | "7";
  switchTab?: (tab: any) => void;
}) {
  const intl = useIntl();
  // 図の表示フラグ
  const graphInfo: GraphInfoType = useSelector((state: RootState) => {
    return state.graphInfo;
  });

  const defaultColumns: string = props.columns ?? "6";
  const GAP_X: any = {
    "6": { scoreBar: 12, scoreBarWithActivity: 6, activityBar: 0 },
    "7": { scoreBar: 16, scoreBarWithActivity: 9, activityBar: 0 },
  };

  const { activeNodeData, activeChartFocus, inactiveChartFocus } =
    useLineBarScatterChartV2Control();

  const configYaxisOnRight = {
    [SCORE_TAB_KEY[0]]: {
      domain: undefined,
      tick: undefined,
    },
    [SCORE_TAB_KEY[1]]: {
      domain: [0, 2400],
      tick: <MedicineRYAxisTick />,
    },
    [SCORE_TAB_KEY[2]]: {
      domain: [35, 39],
      tick: <TemperatureRYAxisTick />,
    },
    [SCORE_TAB_KEY[3]]: {
      domain: [40, 160],
      tick: <BloodPressureRYAxisTick />,
    },
    [SCORE_TAB_KEY[4]]: {
      domain: [0, 120],
      tick: <ActivityRYAxisTick />,
    },
  };

  return (
    <>
      <div className={`${styles.containerDiv}`}>
        <ResponsiveContainer width="100%" height={335}>
          <ComposedChart
            data={props.data}
            margin={{
              top: 0,
              bottom: 15,
              left: -35,
              right: props.scoreType === SCORE_TAB_KEY[0] ? 35 : -25,
            }}
            onClick={(data, event) => {
              if (data.activePayload && data.activePayload[0].payload.date <= getToday()) {
                // active chart focus
                activeChartFocus(data, event);
              } else {
                // remove chart focus
                inactiveChartFocus(event);
              }
            }}
          >
            <CustomCartesianGrid
              vertical={false}
              horizontalPoints={[80, 110, 140, 170, 200, 230, 260, 290, 320]}
            />

            <Tooltip
              cursor={graphInfo[WEEK_GRAPH_DISPLAY] === true ? false : <SelectedColCover />}
              content={<></>}
              trigger="click"
            />

            <XAxis
              height={80}
              dataKey="name"
              interval={0}
              axisLine={false}
              tickLine={false}
              tick={<CustomizedXAxisTick isNoData={props.isNoData} />}
              orientation="top"
            />

            <YAxis
              type="number"
              domain={[0, 120]}
              axisLine={false}
              tickLine={false}
              tickCount={9}
              tick={<CustomizedLYAxisTick />}
            />

            {/* score bar */}
            <Bar
              dataKey="dspScore"
              shape={
                <CurveBar
                  gapx={
                    props.scoreType !== SCORE_TAB_KEY[4]
                      ? GAP_X[defaultColumns].scoreBar
                      : GAP_X[defaultColumns].scoreBarWithActivity
                  }
                />
              }
            />

            {/* right Yaxis */}
            <YAxis
              hide={props.scoreType === SCORE_TAB_KEY[0]}
              domain={configYaxisOnRight[props.scoreType].domain}
              yAxisId="right-axis"
              orientation="right"
              axisLine={false}
              tickLine={false}
              tickCount={9}
              tick={configYaxisOnRight[props.scoreType].tick}
            />

            {/* medicine charts */}
            {props.scoreType === SCORE_TAB_KEY[1] &&
              props.dataNumber &&
              Object.keys(props.dataNumber).map((key, index) => {
                // 線の場合
                if (key.startsWith("0_")) {
                  return (
                    <Line
                      isAnimationActive={false}
                      key={key}
                      yAxisId="right-axis"
                      dataKey={key}
                      stroke={getColor(COLOR_MEDICINE)}
                      strokeWidth={3}
                      legendType="none"
                      activeDot={<MedicineLineDot />}
                      dot={<MedicineLineDot />}
                    />
                  );
                } else {
                  return (
                    <Line
                      isAnimationActive={false}
                      key={key}
                      yAxisId="right-axis"
                      dataKey={key}
                      stroke="none"
                      legendType="none"
                      activeDot={<MedicineScatterDot />}
                      dot={<MedicineScatterDot />}
                    />
                  );
                }
              })}

            {props.scoreType === SCORE_TAB_KEY[2] && (
              <Line
                isAnimationActive={false}
                yAxisId="right-axis"
                dataKey="temperature"
                stroke={getColor(COLOR_TEMPERATURE)}
                strokeWidth={3}
                legendType="none"
                activeDot={<TemperatureDot />}
                dot={<TemperatureDot />}
              />
            )}

            {props.scoreType === SCORE_TAB_KEY[3] && (
              <>
                <Line
                  isAnimationActive={false}
                  yAxisId="right-axis"
                  dataKey="sbp"
                  stroke={getColor(COLOR_BLOOD_PRESSURE)}
                  strokeWidth={3}
                  legendType="none"
                  activeDot={<BloodPressureDot />}
                  dot={<BloodPressureDot />}
                />
                <Line
                  isAnimationActive={false}
                  yAxisId="right-axis"
                  dataKey="dbp"
                  stroke={getColor(COLOR_BLOOD_PRESSURE)}
                  strokeWidth={3}
                  legendType="none"
                  activeDot={<BloodPressureDot />}
                  dot={<BloodPressureDot />}
                />
              </>
            )}

            {props.scoreType === SCORE_TAB_KEY[4] && (
              <Bar
                yAxisId="right-axis"
                dataKey="activityDuration"
                shape={<CurveBar color={COLOR_ACTIVITY} gapx={GAP_X[defaultColumns].activityBar} />}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>

        {props.isNoData && (
          <div className={styles.emptyMsgDiv}>
            <div className={styles.emptyMsg}>
              {intl.formatMessage({ id: "components.charts.line_bar_scatter_chart.no_record" })}
            </div>
          </div>
        )}

        {!graphInfo[WEEK_GRAPH_DISPLAY] && !!activeNodeData && (
          <>
            <div className="page-spacer-15" />
            <WeeklySummaryPanel
              data={activeNodeData}
              scoreType={props.scoreType}
              switchTab={(tab) => {
                if (props.switchTab) {
                  props.switchTab(tab);
                }
              }}
            />
          </>
        )}
      </div>
    </>
  );
}

const CustomizedXAxisTick = ({ x, y, stroke, payload, isNoData }: any) => {
  let title = payload.value as string;
  let titles: any[] = [];
  if (title !== null && title !== undefined && title.split) {
    titles = title.split(" ");
  } else {
    titles.push(title);
  }

  return (
    <g transform={`translate(${x},${30})`}>
      {!isNoData && (
        <text
          x={0}
          y={0}
          textAnchor="middle"
          fontStyle="normal"
          fontSize={10}
          fontWeight={500}
          fill={getColor(CURRENT_TEXT_COLOR)}
        >
          {titles.map((val, index) => {
            return (
              <tspan key={index} x="0" y={index * 20}>
                {val}
              </tspan>
            );
          })}
        </text>
      )}
    </g>
  );
};

const CustomizedLYAxisTick = ({ x, y, stroke, payload }: any) => {
  if (payload.value === 60 || payload.value === 120) {
    let label = 50;
    if (payload.value === 60) {
      label = 50;
    } else {
      label = 100;
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={2}
          textAnchor="end"
          fontStyle="normal"
          fontSize={10}
          fontWeight={500}
          fill={getColor(CURRENT_TEXT_COLOR)}
        >
          {label}
        </text>
      </g>
    );
  } else {
    return <></>;
  }
};

const getRightYAxisTick = (x: number, y: number, label: string, color: string) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={2} textAnchor="start" fontSize={10} fontWeight={500} fill={color}>
        {label}
      </text>
    </g>
  );
};

const MedicineRYAxisTick = ({ x, y, payload }: any) => {
  let left = payload.value / 100;
  let right = payload.value % 100;

  if (left % 3 === 0 && left % 2 !== 0) {
    return <></>;
  }

  return getRightYAxisTick(
    x,
    y,
    `${left}:${right.toString().padStart(2, "0")}`,
    getColor(COLOR_MEDICINE),
  );
};

const TemperatureRYAxisTick = ({ x, y, payload }: any) => {
  if (payload.value % 1 !== 0) {
    return <></>;
  }

  return getRightYAxisTick(x, y, `${payload.value}.0°`, getColor(COLOR_TEMPERATURE));
};

const BloodPressureRYAxisTick = ({ x, y, payload }: any) => {
  if (
    payload.value !== 160 &&
    payload.value !== 130 &&
    payload.value !== 100 &&
    payload.value !== 70 &&
    payload.value !== 40
  ) {
    return <></>;
  }

  const label = payload.value === 40 ? "mmHg" : payload.value;

  return getRightYAxisTick(
    label === "mmHg" ? x - 4 : x,
    y,
    `${label}`,
    getColor(COLOR_BLOOD_PRESSURE),
  );
};

const ActivityRYAxisTick = ({ x, y, payload }: any) => {
  if (
    payload.value !== 120 &&
    payload.value !== 90 &&
    payload.value !== 60 &&
    payload.value !== 30 &&
    payload.value !== 0
  ) {
    return <></>;
  }

  const label = payload.value === 0 ? "min" : payload.value;

  return getRightYAxisTick(x, y, `${label}`, getColor(COLOR_ACTIVITY));
};

const SelectedColCover: React.FC<any> = (props: any) => {
  const cursor_color = getColor(CURSOR_COLOR);
  const { points, top, height } = props;

  const yMargin = 15;

  // カラムの広さをチェックする
  const half_size = 17;
  const full_size = half_size * 2;

  let innerX = points[0].x - half_size;
  let innerY = 0;

  let all = height + top;

  return (
    <svg>
      <path
        opacity={0.3}
        d={`M${innerX},${innerY + yMargin}
          C${innerX} ${innerY + 5.61116} ${innerX + 5.61116} ${innerY} ${
          innerX + half_size
        } ${innerY}
          C${innerX + full_size - 5.61116} ${innerY} ${innerX + full_size} ${innerY + 5.61116} ${
          innerX + full_size
        } ${innerY + yMargin}
          V${all}
          C${innerX + full_size} ${all + 5.61116} ${innerX + full_size - 5.61116} ${
          all + half_size
        } ${innerX + half_size} ${all + yMargin}
          C${innerX + 5.61116} ${all + half_size} ${innerX} ${all + 5.61116} ${innerX} ${all}
          V${innerY}
          Z`}
        fill={cursor_color}
      />
    </svg>
  );
};
