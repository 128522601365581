import { useState, useEffect } from "react";
import { Storage } from "@capacitor/storage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/web/reducer";
import HealthCareManager from "src/web/plugin/healthcare-manager-plugin";
import { AlertType, DeviceType } from "src/web/types";
import { LocalStorageKey } from "src/web/constants";
import { getToday } from "src/web/utils/common";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { updateAlertInfo } from "src/web/actions";
import { useTextConstants } from "src/web/constants/hooks";

let fetchingFlg = false;
let cancelFetchingFlg = false;

export function useFetchBloodPressureControl() {
  const ionRouter = useAppRouter();
  const { DEVICE_ERROR } = useTextConstants();
  const dispatch = useDispatch()

  const device: DeviceType = useSelector((state: RootState) => state.sphygmomanometer);

  const [showFetchingAlert, setShowFetchingAlert] = useState(fetchingFlg);
  const [showCancelFetching, setShowCancelFetching] = useState(cancelFetchingFlg);

  const handleCancel = () => {
    fetchingFlg = false;
    setShowFetchingAlert(fetchingFlg);
    ionRouter.goBack();
  };

  const handleDoFetch = () => {
    fetchingFlg = true;
    setShowFetchingAlert(fetchingFlg);
  }

  const handleCloseAlert = async () => {
    cancelFetchingFlg = true;
    setShowCancelFetching(cancelFetchingFlg);
    fetchingFlg = false;
    setShowFetchingAlert(fetchingFlg);

    if (device.deviceId) {
      try {
        await HealthCareManager.disconnect({
          deviceId: device.deviceId,
        });
      } catch (_) {
      } finally {
        cancelFetchingFlg = false;
        setShowCancelFetching(cancelFetchingFlg);
      }
    }
  };

  const fetchingDataFromDevice = async () => {
    if (device.deviceId) {
      try {
        const payload = {
          deviceId: device.deviceId,
          from: `${getToday()} 05:00`,
          to: `${getToday()} 22:00`,
        };
        const data: any = await HealthCareManager.getBloodPressure(payload);
        if (data.datas && fetchingFlg) {
          await Storage.set({
            key: LocalStorageKey.BLOOD_PRESSURE_DEVICE_DATA,
            value: JSON.stringify(data.datas),
          });
          handleCancel();
        } else if (fetchingFlg && data.errors && !!data.errors.length) {
          fetchingFlg = false;
          setShowFetchingAlert(fetchingFlg);
          if (!cancelFetchingFlg) {
            dispatch(
              updateAlertInfo({
                status: AlertType.ERROR,
                msg: DEVICE_ERROR[`${data.errors[0].code}`],
              }),
            );
          }
        }
      } catch (_) {
        fetchingFlg = false;
        setShowFetchingAlert(fetchingFlg);
        dispatch(
          updateAlertInfo({
            status: AlertType.ERROR,
            msg: "",
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (showFetchingAlert && !cancelFetchingFlg) {
      fetchingDataFromDevice();
    }
  }, [showFetchingAlert, showCancelFetching]);

  return {
    device,
    showFetchingAlert,
    handleCancel,
    handleDoFetch,
    handleCloseAlert,
  }
}
