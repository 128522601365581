import { IonLabel } from "@ionic/react";
import { useIntl } from "react-intl-phraseapp";

export const SummaryDescription = ({ type, data }: { type: string; data: any }) => {
  const intl = useIntl();
  return (
    <>
      {type === "BODY_TEMPERATURE" ? (
        <IonLabel className="p-smallbody">{`${
          data.temperatureToday
            ? `${data.temperatureToday}°C`
            : intl.formatMessage({ id: "components.record_item.score.value.no_record" })
        } / ${
          data.temperature
            ? `${data.temperature}°C`
            : intl.formatMessage({ id: "components.record_item.score.value.no_record" })
        }`}</IonLabel>
      ) : type === "BLOOD_PRESSURE" ? (
        <>
          <div>
            <IonLabel className="p-smallbody">{`${intl.formatMessage({
              id: "components.charts.chart_summary_panel.summary_description.blood_pressure.systolic",
            })}：${
              data.sbpToday ||
              intl.formatMessage({
                id: "components.charts.chart_summary_panel.summary_description.no_record",
              })
            } / ${data.sbp} mmHg`}</IonLabel>
          </div>
          <div>
            <IonLabel className="p-smallbody">{`${intl.formatMessage({
              id: "components.charts.chart_summary_panel.summary_description.blood_pressure.diastolic",
            })}：${
              data.dbpToday ||
              intl.formatMessage({
                id: "components.charts.chart_summary_panel.summary_description.no_record",
              })
            } / ${data.dbp} mmHg`}</IonLabel>
          </div>
          <div>
            <IonLabel className="p-smallbody">{`${intl.formatMessage({
              id: "components.charts.chart_summary_panel.summary_description.blood_pressure.pulse",
            })}：${
              data.hrToday ||
              intl.formatMessage({
                id: "components.charts.chart_summary_panel.summary_description.no_record",
              })
            } / ${data.hr} bpm`}</IonLabel>
          </div>
        </>
      ) : type === "ACTIVITY" ? (
        <>
          <div>
            <IonLabel className="p-smallbody">{`${intl.formatMessage({
              id: "components.charts.chart_summary_panel.summary_description.activity.steps",
            })}：${
              data.stepsToday ||
              intl.formatMessage({
                id: "components.charts.chart_summary_panel.summary_description.no_record",
              })
            } / ${
              data.steps
                ? `${data.steps} ${intl.formatMessage({
                    id: "components.charts.chart_summary_panel.summary_description.activity.steps.unit",
                  })}`
                : intl.formatMessage({
                    id: "components.charts.chart_summary_panel.summary_description.no_record",
                  })
            }`}</IonLabel>
          </div>
          <div>
            <IonLabel className="p-smallbody">{`${intl.formatMessage({
              id: "components.charts.chart_summary_panel.summary_description.activity.duration",
            })}：${
              data.durationToday ||
              intl.formatMessage({
                id: "components.charts.chart_summary_panel.summary_description.no_record",
              })
            } / ${
              data.duration
                ? `${data.duration} ${intl.formatMessage({
                    id: "components.charts.chart_summary_panel.summary_description.activity.duration.unit",
                  })}`
                : intl.formatMessage({
                    id: "components.charts.chart_summary_panel.summary_description.no_record",
                  })
            }`}</IonLabel>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
