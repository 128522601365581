import { useState } from "react";
import checkmarkBlackIcon from "../../../assets/svgs/icons/noPadding/icon_check_active.svg";
import unCheckmarkIcon from "../../../assets/svgs/icons/noPadding/icon_check_circle_idle.svg";
import checkmarkMinusIcon from "../../../assets/svgs/icons/noPadding/icon_minus_circle.svg";
import checkmarkCloseIcon from "../../../assets/svgs/icons/noPadding/icon_close_circle.svg";
import styles from "./appCheckbox.module.css";

let checkBoxTimeout: any = null;

export const STATEFUL_STATES = {
  unchecked: "",
  checked: "0",
  halfChecked: "1",
  skip: "2",
};

export function AppStatefulCheckbox({
  label,
  checkedState,
  onClick,
  disabled,
  readonly,
  size = "normal",
}: {
  label?: string;
  checkedState: string;
  onClick?: (e?: Event) => void;
  disabled?: boolean;
  readonly?: boolean;
  size?: "normal" | "small";
}) {
  const [status, setStatus] = useState({
    checking: false,
    unchecking: false,
  });

  const preClick = (e: any) => {
    if (!disabled && !readonly) {
      clearTimeout(checkBoxTimeout);
      if (checkedState === STATEFUL_STATES.checked) {
        setStatus({
          checking: false,
          unchecking: true,
        });
      } else if (checkedState === STATEFUL_STATES.unchecked) {
        setStatus({
          checking: true,
          unchecking: false,
        });
      }
      checkBoxTimeout = setTimeout(() => {
        setStatus({
          checking: false,
          unchecking: false,
        });
      }, 400);
      onClick && onClick(e);
    }
  };

  return (
    <label className={`${styles.root} ${size === "small" ? styles.small : ""} ${styles.label}`}>
      <input
        type="checkbox"
        checked={checkedState === STATEFUL_STATES.checked}
        className={styles.input}
        disabled={disabled}
        readOnly
      />
      <div
        className={` ${size === "small" ? styles.small : ""}`}
        onClick={(e) => {
          preClick(e);
        }}
        aria-hidden="true"
      >
        <div
          className={`${styles.init} ${!label ? styles.noLabel : ""} ${
            size === "small" ? styles.small : ""
          }
            ${
              status.checking
                ? styles.checking
                : status.unchecking
                ? styles.unchecking
                : checkedState === STATEFUL_STATES.checked
                ? styles.checked
                : ""
            }
            ${disabled ? styles.disabled : ""}
          `}
        >
          <div className={`${styles.cover} ${size === "small" ? styles.small : ""}`}>
            {/* icon when checked */}
            <img
              src={checkmarkBlackIcon}
              alt=""
              className={`${styles.checkedImg} ${size === "small" ? styles.small : ""}`}
            />
          </div>

          {/* icon when unchecked */}
          {checkedState !== STATEFUL_STATES.checked && (
            <img
              src={
                checkedState === STATEFUL_STATES.halfChecked
                  ? checkmarkCloseIcon
                  : checkedState === STATEFUL_STATES.skip
                  ? checkmarkMinusIcon
                  : unCheckmarkIcon
              }
              className={`${styles.icon} ${size === "small" ? styles.small : ""}`}
              alt=""
            />
          )}
        </div>
      </div>
      {label}
    </label>
  );
}
