import {
  getDataFromApiOrLocal,
  postDataForSave,
  deleteData,
  putDataForSave,
} from "../request/apiManager";
import { RecordedAnswerType as OldRecordedAnswerType } from "../../pages/SymptomQuestionnaire/types";
import { RecordedAnswerType } from "src/web/types";
import { SymptomRecordType, DelSymptomRecordType } from "../../pages/MainTab/DiseaseMainTab/types";
import { DEFAULT_DISEASE_ID } from "../../constants";

// get all projects
export function getDiseaseInquiry(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/inquiry`, params);
}

// get draft saved answer
// export function getDiseaseInquiryDraftAnswer(params: { [key: string]: string }) {
//   return getDataFromApiOrLocal("/api/disease/getCurrentAnswer", params);
// }

// export function saveSymptom(data: OldRecordedAnswerType) {
//   return postDataForSave("/api/disease/submitAnswer", data);
// }

export function saveSymptomV2(data: RecordedAnswerType, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return postDataForSave(`/api/v2/diseases/${pathDiseaseId}/answer`, data);
}

export function getAnswerListsForCurrentMonthByDate(
  params: { [key: string]: string },
  diseaseId?: string,
) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/report/month-score`, params);
}

// export function getAnswerByDate(params: { [key: string]: string }) {
//   return getDataFromApiOrLocal("/api/disease/getAnswer", params);
// }

export function getReportAnswerByDate(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/report/current-date`, params);
}

export function getAnswerByDateV2(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/answer`, params);
}

// export function getAnswerDates(params: { [key: string]: string }) {
//   return getDataFromApiOrLocal("/api/disease/getAnswerDates", params);
// }

export function getSymptomSetting(diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/symptom-settings`);
}

export function saveSymptomSetting(data: any, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return putDataForSave(`/api/v2/diseases/${pathDiseaseId}/symptom-settings`, data);
}

export function getSymptomRecords(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/symptom-records`, params);
}

export function createAndUpdateSymptomRecords(data: SymptomRecordType, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return postDataForSave(`/api/v2/diseases/${pathDiseaseId}/symptom-records`, data);
}

export function deleteSymptomRecordsById({ id }: DelSymptomRecordType, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return deleteData(`/api/v2/diseases/${pathDiseaseId}/symptom-records/${id}`);
}

export function getTimeLine(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/timeline`, params);
}

export function getWeekChartV2(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/week-chart`, params);
}

export function getMonthChartV2(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/month-chart`, params);
}
