import { IonLabel } from "@ionic/react";

interface LabelWithContentsProps {
  title: string;
  text?: string | null;
}

export function LabelWithContents({ title, text }: LabelWithContentsProps) {
  return (
    <>
      <IonLabel className="p-body gray pre-wrap text-block">{title}</IonLabel>

      {!!text && (
        <>
          <div className="page-spacer-15" />
          <IonLabel className="p-smallbody pre-wrap text-block">{text}</IonLabel>
        </>
      )}
    </>
  );
}
