import { useEffect, useState } from "react";
import { IonPage, useIonViewDidEnter } from "@ionic/react";
import { useDispatch } from "react-redux";
import { Storage } from "@capacitor/storage";
import {
  BarcodeScanner,
  ScanOptions,
  CameraDirection,
  SupportedFormat,
} from "@capacitor-community/barcode-scanner";
import { PrescriptionInfoType } from "../../utils/common";
import { useJaHisProcess } from "src/web/utils/hooks/jahisProcess";
import { useAppRouter } from "../../hooks/useAppRouter";
import { updatePrescriptionInfo, updatePausePasscodeStatus } from "../../actions";
import { MEDICINE_TYPE, LocalStorageKey } from "../../constants";
import SelectMedicineModal from "./SelectMedicineModal/selectMedicineModal";
import styles from "./styles.module.css";
import { AppHeader } from "../../components/UiComponents";

export const QRScan = () => {
  const ionRouter = useAppRouter();
  const { JaHisProcess } = useJaHisProcess();

  const dispatch = useDispatch();

  const [jahisDatas, setJahisDatas] = useState<PrescriptionInfoType[]>();
  const [openSelectMedicineModal, setOpenSelectMedicineModal] = useState(false);

  let process = new JaHisProcess();
  let loopRead = true;

  const [pageMsg, setPageMsg] = useState("QRコードを読み込んでください");
  // 動作確認
  const [errorMsg, setErrorMsg] = useState("");

  const handleGoBack = () => {
    // restore passcode
    dispatch(updatePausePasscodeStatus(false));
    ionRouter.goBack();
  };

  const navPageAndPreload = (jahisData: PrescriptionInfoType, user_id: string) => {
    dispatch(
      updatePrescriptionInfo({
        ...jahisData,
        type: MEDICINE_TYPE.PRESCRIPTION,
        summary_fee: 0,
        user_id,
      }),
    );
    // restore passcode
    dispatch(updatePausePasscodeStatus(false));
    ionRouter.push(`/home/prescription-information`, "forward", "replace");
  };

  const scan = async () => {
    // チェック権限
    if (!(await checkPermission())) {
      // エラー表示
      alert("no permission");
      handleGoBack();
      return;
    }

    // カメラ表示
    await BarcodeScanner.hideBackground();

    let userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

    while (loopRead) {
      let result = await BarcodeScanner.startScan();
      if (result.hasContent) {
        // エラー内容をクリアする
        setErrorMsg("");
        // console.log("読み取り内容");
        // console.log(result.content);
        // alert(`読み取り内容${result.content}`);
        if (!result.content) {
          continue;
        }

        // 改行
        const newLine = result.content.includes("\r\n") ? "\r\n" : "\n";

        // 内容を処理する
        let processResult = process.readCsvData(result.content, newLine);
        if (!processResult.result) {
          // エラー発生再導入してください。
          setErrorMsg("不正の内容をスキャンしました。");
          // alert(processResult.errorMsg);
          continue;
        }

        let [page, count] = process.getReadCnt();

        if (count < 0) {
          setPageMsg(`続けて読み込んでください　${page}`);
        } else {
          setPageMsg(`続けて読み込んでください　${page}/${count}`);
        }

        if (processResult.hasFinish) {
          if (process.getType() === 1 || window.confirm("読み終了？")) {
            let tmpJahisDatas: PrescriptionInfoType[] = process.getInfo();
            setJahisDatas(tmpJahisDatas);

            loopRead = false;

            if (tmpJahisDatas.length === 1) {
              navPageAndPreload(tmpJahisDatas[0], userId.value ? userId.value : "");
            } else if (tmpJahisDatas.length > 1) {
              setOpenSelectMedicineModal(true);
            }
            return;
          } else {
            handleGoBack();
          }
        } else {
          // alert("次の情報をスキャンする");
        }
      }
    }
  };

  const prepare = async () => {
    // backのカメラを利用する
    // qr codeだけをスキャンします。
    let config: ScanOptions = {
      cameraDirection: CameraDirection.BACK,
      targetedFormats: [SupportedFormat.QR_CODE],
    };
    await BarcodeScanner.prepare(config);

    setTimeout(() => {
      scan();
    }, 0);
  };

  const checkPermission = async () => {
    // check or request permission
    const status = await BarcodeScanner.checkPermission({ force: true });

    if (status.granted) {
      // the user granted permission
      return true;
    }

    return false;
  };

  // const dummyFetch = () => {
  //   let result = {
  //     hasContent: true,
  //     content:
  //     // "JAHISTC07,1\r\n"
  //     // +"1,鈴木 太郎,1,19580303,,,,,,,\r\n"
  //     // +"5,R020410,1\r\n"
  //     // +"11,株式会社 工業会薬局 駅前店,13,4,1234567,,,,1\r\n"
  //     // +"15,薬剤師 太郎,,1\r\n"
  //     // +"51,医療法人 工業会病院,13,1,1234567,1\r\n"
  //     // +"201,1,コリオパンカプセル5mg,6,C,2,620004992,1\r\n"
  //     // +"281,1,281内容,281作者\r\n"
  //     // +"291,1,291内容,291作者\r\n"
  //     // +"301,1,【分3 毎食後服用】,5,日分,1,1,,1\r\n"
  //     // +"201,2,フェロベリン配合錠,6,C,2,620004992,1\r\n"
  //     // +"301,2,【分2 毎食後服用】,3,日分,3,1,,1\r\n"

  //     "JAHISTC07,1\r\n"
  //     +"1,鈴木 太郎,1,19580303,,,,,,,\r\n"
  //     +"5,R020407,1\r\n"
  //     +"11,株式会社 工業会薬局 駅前店,13,4,1234567,,, ,1\r\n"
  //     +"15,薬剤師 太郎,,1\r\n"
  //     +"51,医療法人 工業会病院,13,1,1234567,1\r\n"
  //     +"55,工業会 次郎,内科,1\r\n"
  //     +"201,1,コリオパンカプセル5mg,6,C,2,620004992,1\r\n"
  //     // +"201,1,フェロベリン配合錠,6,錠,2,620425801,1\r\n"
  //     +"301,1,【分3 毎食後服用】,5,日分,1,1,,1\r\n"
  //     // +"201,2,アドソルビン原末,1.5,g,2,620008284,1\r\n"
  //     // +"201,2,(局)タンナルビン「ホエイ」,1.5,g,2,612310013,1\r\n"
  //     // +"201,2,レベニン散,2,g,2,620007148,1\r\n"
  //     // +"301,2,【分3 毎食後服用】,5,日分,1,1,,1\r\n"
  //     // +"201,3,イソジンガーグル液7%,60,ml,2,620008969,1\r\n"
  //     // +"301,3,【1日3~4回 うがい】,1,調剤,5,1,,1\r\n"
  //     // +"201,4,ノボラピッド注 ペンフィル 300単位,2,筒,2,620008894 ,1\r\n"
  //     // +"301,4,【1日2回 朝14単位 夕6単位】,1,調剤,4,1, ,1\r\n"
  //     // +"201,5,ペンニードル30G/8mm(ノボ),60,本,2,710010093,1\r\n"
  //     // +"301,5,,1,調剤,9,1,,1\r\n"

  //     // +"55,佐藤 三三郎,皮科,1\r\n"
  //     // +"201,2,リンデロン-VG軟膏0.12%,10,g,2,662640418,1\r\n"
  //     // +"301,2,【患部に塗布】,1,調剤,5,1,,1\r\n"
  //     // +"201,3,容器,1,個,1,,1\r\n"
  //     // +"301,3,,1,調剤,10,1,,1\r\n"
  //     // +"421,服用忘れによりコリオパンカプセル12錠残薬あり,1\r\n"
  //     // +"501,正しい飲み方は薬袋等をご覧下さい。,1\r\n"

  //     +"5,R020410,1\r\n"
  //     +"11,株式会社 22,13,4,1234567,,, ,1\r\n"
  //     +"15,薬剤師 次郎,,1\r\n"
  //     +"51,医療法人 工業会病院,13,1,1234567,1\r\n"
  //     +"55,佐藤 三三郎,皮科,1\r\n"
  //     +"201,1,リンデロン-VG軟膏0.12%,10,g,2,662640418,1\r\n"
  //     +"301,1,【患部に塗布】,1,調剤,5,1,,1\r\n"
  //     +"201,2,容器,1,個,1,,1\r\n"
  //     +"301,2,,1,調剤,10,1,,1\r\n"
  //     +"421,服用忘れによりコリオパンカプセル12錠残薬あり,1\r\n"
  //     +"501,正しい飲み方は薬袋等をご覧下さい。,1\r\n"
  //   }
  //   console.log("読み取り内容");
  //   console.log(result.content);
  //   let processResult = process.readCsvData(result.content, "\r\n");
  //   console.info(processResult)

  //   if (processResult.hasFinish) {
  //     let tmpJahisDatas: PrescriptionInfoType[] = process.getInfo();
  //     console.log('result ->', tmpJahisDatas);

  //     console.log(JSON.stringify(tmpJahisDatas));

  //     setJahisDatas(tmpJahisDatas)

  //     if (tmpJahisDatas.length === 1) {
  //       navPageAndPreload(tmpJahisDatas[0], "test_user_id")
  //     } else if (tmpJahisDatas.length > 1) {
  //       setOpenSelectMedicineModal(true)
  //     }
  //   }
  // }

  useEffect(() => {
    // dummyFetch()
    prepare();
    return () => {
      let closeScan = async () => {
        // スキャン停止
        await BarcodeScanner.stopScan();
        await BarcodeScanner.showBackground();
      };
      closeScan();
    };
  }, []);

  useIonViewDidEnter(() => {
    // pause passcode
    dispatch(updatePausePasscodeStatus(true));
  });

  return (
    <>
      <IonPage className={styles.content}>
        <AppHeader
          isDisplayBackground={true}
          backAction={() => {
            loopRead = false;
            handleGoBack();
          }}
        />
        <div
          className={styles.container}
          onClick={async () => {
            loopRead = false;
            handleGoBack();
          }}
        >
          {/* <IonButton
            onClick={async () => {
              if (!(await checkPermission())) {
                alert("no permission");
                return;
              }
              await BarcodeScanner.hideBackground();
              let result = await BarcodeScanner.startScan();
              if (result.hasContent) {
                console.log(result.content);
                alert(result.content);
              }
            }}
          >
            start
          </IonButton> */}
          <div className={styles.barcode_scanner__area__container}>
            {/* <div className={styles.relative}>
              <p>Aim your camera at a barcode</p>
            </div> */}
            <div className={`${styles.square} ${styles.surround_cover}`}>
              <div className={`${styles.barcode_scanner__area__outer} ${styles.surround_cover}`}>
                <div className={`${styles.barcode_scanner__area__inner}`}></div>
              </div>
            </div>
            <div className={`${styles.footMessageDiv}`}>
              {errorMsg && <div>{errorMsg}</div>}
              <div>{pageMsg}</div>
            </div>
          </div>
        </div>
      </IonPage>

      <SelectMedicineModal
        isOpen={openSelectMedicineModal && !!jahisDatas && jahisDatas.length > 0}
        jahisDatas={jahisDatas}
        handleConfirm={async (jahisData: PrescriptionInfoType) => {
          let userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });
          setOpenSelectMedicineModal(false);
          navPageAndPreload(jahisData, userId.value ? userId.value : "");
        }}
        handleClose={() => {
          setOpenSelectMedicineModal(false);
          handleGoBack();
        }}
      />
    </>
  );
};
