import React, { useState, useEffect, useMemo } from "react";
import { IonLabel, IonGrid, IonRow, IonCol } from "@ionic/react";
import { AppIcon } from "..";
import { ICONS, DATE_FORMAT_SPLIT } from "../../../constants";
import {
  getCurrentYearMonth,
  getDateStrByDate,
  getToday,
  getYearMonthByDate,
} from "../../../utils/common";
import { CalendarDayType } from "../../../types";
import { MonthCheckedType } from "./types";
import styles from "./appCalendar.module.css";
import calSelectIcon from "../../../assets/svgs/calendar-legend-select.svg";
import calTodayIcon from "../../../assets/svgs/calendar-legend-today.svg";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

interface AppCalendarProps {
  currentMonth: string;
  selectedDate: string;
  calendarData?: MonthCheckedType[];
  setCurrentMonth: React.Dispatch<React.SetStateAction<string>>;
  handleSelectDateChange: (date: string) => void;
  isFutureSelectable?: boolean;
  isAllClickable?: boolean;
  showSchedulePoints?: boolean;
  calendarDateAvailableFrom?: string; // the date before the date is not available
  isLineSelectRequired?: boolean;
}

const FinshedLegend = ({ color = "#B65554" }: any) => {
  return (
    <svg width="4" height="4" viewBox="0 0 4 4" stroke="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="2" r="2" fill={color} />
    </svg>
  );
};

const ScheduledLegend = ({ color = "#B65554" }: any) => {
  return (
    <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="2" cy="2" r="1.5" stroke={color} />
    </svg>
  );
};

const getContainerClassAndInfo = (
  isLineSelectRequired: boolean,
  isCurrentLineSelected: boolean,
  rowData: CalendarDayType[],
  dateItem: CalendarDayType,
  currentIndex: number,
) => {
  const isFirstItem =
    (currentIndex === 0 && !!dateItem.fullDate) ||
    (currentIndex > 0 && !!dateItem.fullDate && !rowData[currentIndex - 1].fullDate);
  const isLastItem =
    (currentIndex === rowData.length - 1 && !!dateItem.fullDate) ||
    (currentIndex < rowData.length - 1 &&
      !!dateItem.fullDate &&
      !rowData[currentIndex + 1].fullDate);
  const isOnlyDot = isFirstItem && isLastItem;

  if (!isLineSelectRequired || !isCurrentLineSelected) {
    return {
      className: "",
      isOnlyDot,
    };
  }

  if (isFirstItem && !isLastItem) {
    return {
      className: styles.firstSelectedDot,
      isOnlyDot,
    };
  } else if (!isFirstItem && isLastItem) {
    return {
      className: styles.lastSelectedDot,
      isOnlyDot,
    };
  } else if (!isFirstItem && !isLastItem && !!dateItem.fullDate) {
    return {
      className: styles.middleSelectedDot,
      isOnlyDot,
    };
  } else {
    return {
      className: "",
      isOnlyDot,
    };
  }
};

export function AppCalendar({
  currentMonth,
  selectedDate,
  calendarData,
  setCurrentMonth,
  handleSelectDateChange,
  isFutureSelectable,
  isAllClickable,
  showSchedulePoints = true,
  calendarDateAvailableFrom,
  isLineSelectRequired = false,
}: AppCalendarProps) {
  const [dateMatrix, setDateMatrix] = useState<CalendarDayType[][]>([[]]);
  const intl = useIntl();
  const { DAY_CONVERT } = useTextConstants();

  const handleChangeMonth = (count: number) => {
    let current: Date = new Date(currentMonth);
    current.setMonth(current.getMonth() + count);
    setCurrentMonth(getDateStrByDate(current));
  };

  const legends = useMemo(() => {
    if (isLineSelectRequired) {
      return [
        {
          icon: <img src={calSelectIcon} />,
          label: intl.formatMessage({ id: "ui_components.app_calendar.legend.select" }),
        },
        {
          icon: <img src={calTodayIcon} />,
          label: intl.formatMessage({ id: "ui_components.app_calendar.legend.the_day" }),
        },
        {
          icon: <FinshedLegend />,
          label: intl.formatMessage({ id: "ui_components.app_calendar.legend.exist_record" }),
        },
      ];
    } else {
      return [
        {
          icon: <img src={calSelectIcon} />,
          label: intl.formatMessage({ id: "ui_components.app_calendar.legend.select" }),
        },
        {
          icon: <img src={calTodayIcon} />,
          label: intl.formatMessage({ id: "ui_components.app_calendar.legend.the_day" }),
        },
        {
          icon: <FinshedLegend />,
          label: intl.formatMessage({
            id: "ui_components.app_calendar.legend.exist_taking_medicine_record",
          }),
        },
        {
          icon: <ScheduledLegend />,
          label: intl.formatMessage({
            id: "ui_components.app_calendar.legend.exist_taking_medicine_plan",
          }),
        },
      ];
    }
  }, [isLineSelectRequired]);

  const fetchData = async (monthDetails?: MonthCheckedType[]) => {
    let firstDay = parseInt(currentMonth.split(DATE_FORMAT_SPLIT)[2]);
    const selectedYearMonthStr = getYearMonthByDate(new Date(currentMonth));
    let tmpDateMatrix: CalendarDayType[][] = [[]];

    // the first date of the month
    const firstDate = new Date(currentMonth);
    let firstWeekDay = firstDate.getDay();

    // the last date of the month
    const lastDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0);
    let lastWeekDay = lastDate.getDate();

    // 第一行名の設定
    let firstLine: CalendarDayType[] = [];

    for (let i = 0; i < 7; i++) {
      if (i < firstWeekDay) {
        firstLine.push({
          date: 0,
          fullDate: "",
          hasData: false,
          hasScheduled: false,
          isSelected: false,
          isCurrent: false,
        });
      } else {
        let dateStr = `${selectedYearMonthStr}/${firstDay.toString().padStart(2, "0")}`;
        firstLine.push({
          date: firstDay,
          fullDate: dateStr,
          hasData: !!monthDetails?.find(
            (detail) => detail.check_date === dateStr && detail.cnt > 0,
          ),
          hasScheduled:
            !isLineSelectRequired &&
            !!monthDetails?.find(
              (detail) =>
                detail.check_date >= getToday() &&
                detail.check_date === dateStr &&
                detail.cnt === 0,
            ),
          isCurrent: dateStr === getToday(),
          isSelected: dateStr === selectedDate,
        });
        firstDay++;
      }
    }
    tmpDateMatrix.push(firstLine);

    for (let i = 1; i < 6; i++) {
      if (firstDay > lastWeekDay) {
        break;
      }
      let newLine: CalendarDayType[] = [];
      for (let i = 0; i < 7; i++) {
        if (firstDay > lastWeekDay) {
          newLine.push({
            date: 0,
            fullDate: "",
            hasData: false,
            hasScheduled: false,
            isSelected: false,
            isCurrent: false,
          });
          continue;
        }
        let dateStr = `${selectedYearMonthStr}/${firstDay.toString().padStart(2, "0")}`;
        newLine.push({
          date: firstDay,
          fullDate: dateStr,
          hasData: !!monthDetails?.find(
            (detail) => detail.check_date === dateStr && detail.cnt > 0,
          ),
          hasScheduled:
            !isLineSelectRequired &&
            !!monthDetails?.find(
              (detail) =>
                detail.check_date >= getToday() &&
                detail.check_date === dateStr &&
                detail.cnt === 0,
            ),
          isCurrent: dateStr === getToday(),
          isSelected: dateStr === selectedDate,
        });
        firstDay++;
      }
      tmpDateMatrix.push(newLine);
    }

    // add empty row when line number is six
    if (tmpDateMatrix.length === 6) {
      let newLine: CalendarDayType[] = [];
      for (let i = 0; i < 7; i++) {
        newLine.push({
          date: 0,
          fullDate: "",
          hasData: false,
          hasScheduled: false,
          isSelected: false,
          isCurrent: false,
        });
      }

      tmpDateMatrix.push(newLine);
    }

    setDateMatrix(tmpDateMatrix);
  };

  const showDate = (data: CalendarDayType, isOnlyDot: boolean, isCurrentLineSelected: boolean) => {
    if (data.date === 0) {
      return <></>;
    } else if (data.isSelected) {
      return (
        <div
          className={`${styles.calendarDateDiv} ${
            (!isLineSelectRequired || isOnlyDot) && styles.calendarDateIsSelectBorderDiv
          }`}
        >
          <div
            className={`${(!isLineSelectRequired || isOnlyDot) && styles.calendarDateIsSelectDiv} ${
              data.isCurrent ? styles.calendarDateCurrentDiv : ""
            }`}
          >
            <div
              className={`p-body ${data.date < 10 ? styles.shortLabel : styles.label} ${
                data.isCurrent ||
                (isLineSelectRequired && isCurrentLineSelected && data.fullDate > getToday())
                  ? styles.white
                  : ""
              }`}
            >
              {data.date}
              {data.hasData ? (
                <div className={styles.finishedPoint}>
                  {data.isCurrent ? <FinshedLegend color="#FFFFFF" /> : <FinshedLegend />}
                </div>
              ) : data.hasScheduled && showSchedulePoints ? (
                <div className={styles.finishedPoint}>
                  {data.isCurrent ? <ScheduledLegend color="#FFFFFF" /> : <ScheduledLegend />}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    } else if (data.isCurrent) {
      return (
        <div
          className={`${styles.calendarDateDiv} ${styles.calendarDateCurrentDiv}`}
          onClick={() => {
            if (!calendarDateAvailableFrom || data.fullDate >= calendarDateAvailableFrom) {
              handleSelectDateChange(data.fullDate);
            }
          }}
        >
          <div
            className={`p-body ${data.date < 10 ? styles.shortLabel : styles.label} ${
              styles.white
            }`}
          >
            {data.date}
            {data.hasData ? (
              <div className={styles.finishedPoint}>
                <FinshedLegend color="#FFFFFF" />
              </div>
            ) : data.hasScheduled && showSchedulePoints ? (
              <div className={styles.finishedPoint}>
                <ScheduledLegend color="#FFFFFF" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (data.hasData) {
      return (
        <div
          className={styles.calendarDateDiv}
          onClick={() => {
            if (!calendarDateAvailableFrom || data.fullDate >= calendarDateAvailableFrom) {
              handleSelectDateChange(data.fullDate);
            }
          }}
        >
          <div className={`p-body ${data.date < 10 ? styles.shortLabel : styles.label}`}>
            {data.date}
            <div className={styles.finishedPoint}>
              <FinshedLegend />
            </div>
          </div>
        </div>
      );
    } else if (data.hasScheduled) {
      return (
        <div
          className={styles.calendarDateDiv}
          onClick={() => {
            if (!calendarDateAvailableFrom || data.fullDate >= calendarDateAvailableFrom) {
              isFutureSelectable && handleSelectDateChange(data.fullDate);
            }
          }}
        >
          <div
            className={`p-body ${data.date < 10 ? styles.shortLabel : styles.label} ${
              !isFutureSelectable ? styles.unClickable : ""
            } ${isLineSelectRequired && isCurrentLineSelected ? styles.white : ""}`}
          >
            {data.date}
            {showSchedulePoints && showSchedulePoints && (
              <div className={styles.finishedPoint}>
                <ScheduledLegend />
              </div>
            )}
          </div>
        </div>
      );
    } else {
      // 全部選択できる
      if (isAllClickable) {
        return (
          <div
            className={styles.calendarDateDiv}
            onClick={() => {
              if (
                (!calendarDateAvailableFrom || data.fullDate >= calendarDateAvailableFrom) &&
                (isFutureSelectable || data.fullDate <= getToday())
              ) {
                handleSelectDateChange(data.fullDate);
              }
            }}
          >
            <div
              className={`p-body ${data.date < 10 ? styles.shortLabel : styles.label} ${
                (calendarDateAvailableFrom && calendarDateAvailableFrom > data.fullDate) ||
                (!isFutureSelectable && data.fullDate > getToday())
                  ? styles.unClickable
                  : ""
              } ${
                isLineSelectRequired && isCurrentLineSelected && data.fullDate > getToday()
                  ? styles.white
                  : ""
              }`}
            >
              {data.date}
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.calendarDateDiv}>
            <div
              className={`p-body ${data.date < 10 ? styles.shortLabel : styles.label} ${
                styles.unClickable
              } ${
                isLineSelectRequired && isCurrentLineSelected && data.fullDate > getToday()
                  ? styles.white
                  : ""
              }`}
            >
              {data.date}
            </div>
          </div>
        );
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentMonth]);

  useEffect(() => {
    if (calendarData) {
      fetchData(calendarData);
    }
  }, [calendarData, selectedDate]);

  return (
    <>
      <div className={`flex-row-around ${styles.titleBar}`}>
        <AppIcon icon={ICONS.ICON.ARROW_CIRCLE_BACK} onClick={() => handleChangeMonth(-1)} />

        <div className={styles.dateWrapper}>
          <IonLabel className="p-smallbody text-center-block">
            {currentMonth.split(DATE_FORMAT_SPLIT)[0]}
          </IonLabel>
          <IonLabel className="p-header2 text-center-block">
            {currentMonth.split(DATE_FORMAT_SPLIT)[1]}
            {intl.formatMessage({ id: "ui_components.app_calendar.unit.month" })}
          </IonLabel>
        </div>

        <AppIcon
          icon={ICONS.ICON.ARROW_CIRCLE_FORWARD}
          disabled={
            !isFutureSelectable && currentMonth >= `${getCurrentYearMonth()}${DATE_FORMAT_SPLIT}01`
          }
          onClick={() => handleChangeMonth(1)}
        />
      </div>

      <div className="page-spacer-45" />

      <IonGrid style={{ margin: "0 auto", height: "auto", maxWidth: 340 }}>
        <IonRow>
          {Object.values(DAY_CONVERT).map((day, index) => (
            <IonCol className={styles.calendarTableHead} key={index}>
              {day}
            </IonCol>
          ))}
        </IonRow>

        <div className="page-spacer-10" />

        {dateMatrix.map((rowData, index) => {
          const isCurrentLineSelected = !!rowData.find((row) => row.fullDate === selectedDate);
          return (
            <IonRow style={{ margin: "5px 0 0 0" }} key={index} className={styles.lineSelected}>
              {rowData.map((date, i) => {
                const { className, isOnlyDot } = getContainerClassAndInfo(
                  isLineSelectRequired,
                  isCurrentLineSelected,
                  rowData,
                  date,
                  i,
                );
                return (
                  <IonCol key={i}>
                    <div className={`${styles.calendarDateDiv} ${className}`}>
                      {showDate(date, isOnlyDot, isCurrentLineSelected)}
                    </div>
                  </IonCol>
                );
              })}
            </IonRow>
          );
        })}
      </IonGrid>

      <div className="page-spacer-50" />

      <div className={`${styles.legendRoot}`}>
        {legends.map((item) => (
          <div className={styles.legend}>
            {item.icon}
            <IonLabel className="p-graph-min">{item.label}</IonLabel>
          </div>
        ))}
      </div>
    </>
  );
}
