import { AppInput } from "../..";
import { PageTitle, PageTitleCenter } from "../../moleclues";
import { useIntl } from "react-intl-phraseapp";

interface EmailFormProps {
  title: string;
  label?: string;
  userName?: string;
  error?: boolean;
  errorMsg?: string;
  isLeftAlign?: boolean;
  handleUserNameInputChange: (val: string) => void;
}

export function EmailForm({
  title,
  label,
  userName,
  error,
  errorMsg,
  isLeftAlign = false,
  handleUserNameInputChange,
}: EmailFormProps) {
  const intl = useIntl();
  return (
    <>
      {isLeftAlign ? <PageTitle title={title} /> : <PageTitleCenter title={title} />}

      {!!handleUserNameInputChange && (
        <>
          <div className="page-spacer-45" />
          <AppInput
            type="email"
            value={userName}
            label={label ?? intl.formatMessage({ id: "login.form.mail.label" })}
            onIonChange={(e: any) => {
              handleUserNameInputChange(e.detail.value);
            }}
            error={error}
            errorMsg={errorMsg}
          />
        </>
      )}
    </>
  );
}
