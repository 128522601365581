import { useEffect } from "react";
import { useAppRouter } from "src/web/hooks/useAppRouter";

export function useChangePasswordSuccessControl() {
  const iRouter = useAppRouter();

  const navToLogin = () => {
    iRouter.push("/login");
  };

  useEffect(() => {
    // handle native back:
    const handleNativeBack = (ev: any) => {
      ev.detail.register(10, () => {});
    };
    // block native back click to return to any other page
    document.addEventListener("ionBackButton", handleNativeBack);

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, []);

  return {
    navToLogin,
  };
}
