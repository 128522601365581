import React, { useRef, useEffect } from "react";
import { IonApp, IonRouterOutlet, setupIonicReact, IonRoute, useIonAlert } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { auth } from "./web/firebase";
import Login from "./web/pages/Login/login";
import Home from "./web/pages/Home";
import Loading from "./web/pages/loading";
import { SymptomQuestionnaire } from "./web/pages/SymptomQuestionnaire";
import { BloodPressure } from "./web/pages/BloodPressure";
import { FetchBloodPressure } from "./web/pages/FetchBloodPressure";
import { QRScan } from "./web/pages/QRScan";
import { MedicineInformation } from "./web/pages/MedicineInformation";
import { PrescriptionInformation } from "./web/pages/PrescriptionInformation";
import { MedicineInformationMemo } from "./web/pages/MedicineInformationMemo";
import { PrescriptionHospital } from "./web/pages/PrescriptionHospital";
import { PrescriptionFee } from "./web/pages/PrescriptionFee";
import { PrescriptionMemo } from "./web/pages/PrescriptionMemo";
import { BigImg } from "./web/pages/BigImg";

import { useSelector, useDispatch } from "react-redux";
import Chat from "./web/pages/Chat";
import ChatMenu from "./web/components/ChatMenu";

import Test from "./web/pages/Test";

// import { codePush, InstallMode, CodePush } from "capacitor-codepush";
import { Storage } from "@capacitor/storage";
import { App as CapApp, URLOpenListenerEvent } from "@capacitor/app";
import { Network } from "@capacitor/network";
import { batchPostData } from "./web/services/request/apiManager";
import { PENDING_REQ_SYNCED_STATUS, LocalStorageKey } from "./web/constants";
import { Capacitor } from "@capacitor/core";
import { LocalNotifications, ActionPerformed } from "@capacitor/local-notifications";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./web/theme/variables.css";
import "./web/theme/index.css";
import "./web/theme/paragraph.css";
import { RootState } from "./web/reducer";
import { AlertInfoType, AlertType } from "./web/types";
// import './theme/common.css';

import { isScreenLockerEnabled, navAnimationBuilder, getDateStrByDate } from "./web/utils/common";

import HealthCareManager from "./web/plugin/healthcare-manager-plugin";
import { updateDailyAlarmRedirect } from "./web/actions/updateDailyAlarmRedirectAction";
import { useIonRouter, isPlatform } from "@ionic/react";
import CreateUserEmail from "./web/pages/CreateUserEmail/createUserEmail";
import CreateUserEmailConfirm from "./web/pages/CreateUserEmailConfirm/createUserEmailConfirm";
import Welcome from "./web/pages/Welcome/welcome";
import CreateUserSetPassword from "./web/pages/CreateUserSetPassword/createUserSetPassword";
import {
  ActionCodeURL,
  isSignInWithEmailLink,
  signInWithEmailLink,
  updateEmail,
} from "firebase/auth";
import CreateUserExpired from "./web/pages/CreateUserExpired/createUserExpired";
import { useAppRouter } from "./web/hooks/useAppRouter";
import CreateUserBaseInfo from "./web/pages/CreateUserBaseInfo/createUserBaseInfo";
import ModifyUserBaseInfo from "./web/pages/ModifyUserBaseInfo/modifyUserBaseInfo";
import { AccountsWithdraw, AccountsWithdrawComplete } from "./web/pages/AccountsWithdraw";
import {
  clearAlertInfo,
  clearNewUserEmailAddress,
  updateAlertInfo,
  updateCapacityKeyboardHeight,
} from "./web/actions";
import { ChangePassword } from "./web/pages/Menu/ChangePassword/changePassword";
import { ChangePasswordSuccess } from "./web/pages/Menu/ChangePassword/changePasswordSuccess";
import {
  ForgetPasswordSendMail,
  ForgetPasswordSendMailSuccess,
} from "./web/pages/ForgetPasswordSendMail";
import { ResetPasswordSuccess } from "./web/pages/ResetPassword";
// demo page
import { Demo } from "./web/pages/Demo/demo";
import { ChangeEmail, ChangeEmailConfirm, ChangeEmailSuccess } from "./web/pages/Menu/ChangeEmail";
import { Keyboard } from "@capacitor/keyboard";
import { PrescriptionBigImage } from "./web/pages/PrescriptionBigImage/prescriptionBigImage";
import { NotificationConfig } from "./web/pages/Menu/NotificationConfig";
import {
  ScreenLocker,
  ScreenLockerPasscodeConfirm,
  ScreenLockerPasscodeInit,
} from "./web/pages/ScreenLocker";
import {
  AlexaSetting,
  Contact,
  ContactConfirm,
  ContactComplete,
  SymptomSetting,
  SupportAnnouncementIndex,
  SupportAnnouncementDetail,
  Others,
  AutoDataFetchSetting,
  IndexSetting,
  SupportedDevices,
  Faqs,
  FaqsDetail,
} from "./web/pages/Menu";
import { AppLoading } from "./web/components/UiComponents";
import { SymptomEnteringDetails } from "./web/pages/MainTab";
import { useAppUpdater } from "./web/hooks/useAppUpdater";
import { useLoadingDisplay } from "./web/hooks/useLoadingDisplay";

setupIonicReact();

export default function App() {
  const ionRouter = useIonRouter();
  const routerControl = useRef<any>();

  const dispatch = useDispatch();
  // // 全画面の情報を取得する
  // const dailyAlarmSchedule: DailyAlarmRedirectType = useSelector(
  //   (state: RootState) => state.dailyAlarmSchedule,
  // );
  const newUserEmailAddress = useSelector((state: RootState) => state.newUserEmailAddress);

  // pause passcode flag
  const isPasscodePausedForUsingCamera = useSelector(
    (state: RootState) => state.isPasscodePausedForUsingCamera,
  );

  const { updateApp } = useAppUpdater();
  const { isLoadingVisible } = useLoadingDisplay();

  const initialPlugin = async () => {
    console.log("initial plug start.");

    try {
      if (Capacitor.isNativePlatform()) {
        console.log("Health initial");
        let result = await HealthCareManager.initialize({ config: "" });
        console.log(`initialize: ${result}`);
      }
    } catch (error) {
      console.log("initial plugin error.");
    }

    console.log("initial plug over.");
  };

  const reloadCssForIosSimulatorWithAppleSilicon = () => {
    // Treatment for known Apple bug.
    // iOS Simulator on M1 Mac can't load CSS via link tag.
    // https://github.com/ionic-team/capacitor/issues/4772
    const el = document.querySelector("link[rel='stylesheet']");
    const cssFilename = el?.getAttribute("href");
    el?.remove();

    if (cssFilename) {
      let xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          document.head.innerHTML =
            document.head.innerHTML + '<style type="text/css">' + this.responseText + "</style>";
        }
      };
      xhttp.open("GET", cssFilename, true);
      xhttp.send();
    }
  };

  const getAlarmData = async () => {
    // // 次の時間を実施する
    // let now = new Date();
    // // 今の日情報を取得する
    // let currentDate = getDateStrByDate(now);
    // // 次の実行時間を算出する
    // let diffTime =
    //   new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 1).getTime() -
    //   now.getTime() -
    //   100000;
    // // 不足の場合、二時間後実行
    // if (diffTime < 0) {
    //   diffTime =
    //     new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() + 2).getTime() -
    //     now.getTime() -
    //     100000;
    // }
    // // loginしていない場合、実施しない
    // if (!auth.currentUser || auth.currentUser.isAnonymous) {
    //   setTimeout(() => {
    //     getAlarmData();
    //   }, diffTime);
    //   return;
    // }
    // データ
    // データ存在の場合
    // if (dailyAlarmData.date && dailyAlarmData.date === currentDate) {
    //   setTimeout(() => {
    //     getAlarmData();
    //   }, diffTime);
    //   return;
    // }
    // try {
    //   // アラム情報を取得する
    //   let jsonData = await getData("getAlarmList", "/api/medicine/getAlarmList", {
    //     currentDate: currentDate,
    //   });
    //   let alarmList = jsonData.alarmList;
    //   // 時間
    //   let newDailyAlarm: DailyAlarmType = { date: currentDate, alarmDict: {} };
    //   let TimeOptions = Object.assign(
    //     {},
    //     ...MEDICINE_TIMING_OPTIONS.map((x) => ({ [x["value"]]: x["text"] })),
    //   );
    //   // タイトルを取得する
    //   for (let key in alarmList) {
    //     let alarmData = alarmList[key];
    //     let title = alarmData.alarm_title;
    //     if (alarmData.type === "0") {
    //       if (key === "0") {
    //         title = "指定なし";
    //       } else {
    //         title = TimeOptions[key];
    //         if (!title) {
    //           title = key;
    //         }
    //       }
    //     }
    //     newDailyAlarm.alarmDict[key] = {
    //       time: alarmData.alarm_clock,
    //       title: title,
    //       type: alarmData.type,
    //     };
    //   }
    //   if (Object.keys(newDailyAlarm.alarmDict).length !== 0) {
    //     dispatch(updateDailyAlarmSchedule(newDailyAlarm));
    //   }
    // } catch {}
    // setTimeout(() => {
    //   getAlarmData();
    // }, diffTime);
  };

  const clearNotification = async () => {
    // let notifications = await (await LocalNotifications.getPending()).notifications;
    // if (notifications.length > 0) {
    //   await LocalNotifications.cancel({ notifications: notifications });
    // }
  };

  const scheduleNotification = async () => {
    // let now = new Date();
    // let currentDate = getDateStrByDate(now);
    // // 全部クリアする
    // await clearNotification();
    // console.log("add notification");
    // // 存在する情報は間違います。
    // if (dailyAlarmData.date !== currentDate) {
    //   console.log("empty notification");
    //   return;
    // }
    // let notifications: LocalNotificationSchema[] = [];
    // let id = 1;
    // for (let key in dailyAlarmData.alarmDict) {
    //   let notificationData = dailyAlarmData.alarmDict[key];
    //   if (!notificationData.title) {
    //     continue;
    //   }
    //   let title = `${notificationData.title}：服薬時間になりました`;
    //   let times = notificationData.time.split(":");
    //   let hour = parseInt(times[0]);
    //   let min = parseInt(times[1]);
    //   // 時間違い
    //   if (isNaN(hour) || isNaN(min)) {
    //     continue;
    //   }
    //   let notificationTime = new Date(
    //     now.getFullYear(),
    //     now.getMonth(),
    //     now.getDate(),
    //     hour,
    //     min,
    //     0,
    //     0,
    //   );
    //   if (notificationTime.getTime() < now.getTime()) {
    //     continue;
    //   }
    //   notifications.push({
    //     id: id++,
    //     title: title,
    //     body: `服薬が完了したらアプリに登録しましょう`,
    //     schedule: { at: notificationTime, repeats: false },
    //   });
    // }
    // if (notifications.length > 0) {
    //   await LocalNotifications.schedule({ notifications: notifications });
    // }
  };

  // useEffect(() => {
  //   console.log("Data start");
  //   scheduleNotification();
  //   console.log("Data end");
  // }, [dailyAlarmData]);

  const handleEmailChange = async (router: any) => {
    if (!newUserEmailAddress) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      // alert("no email data");
      return;
    }

    if (auth && auth.currentUser) {
      try {
        await updateEmail(auth.currentUser, newUserEmailAddress);
        router.push(`/settings/accounts/change-email/complete`);
      } catch (error: any) {
        dispatch(
          updateAlertInfo({
            status: AlertType.ERROR,
            msg: `エラーが発生しました。エラーコード：${error?.code}`,
          }),
        );
      }
    }

    dispatch(clearNewUserEmailAddress());
  };

  // listen snapshot
  const AppUrlListener: React.FC<any> = () => {
    const iRouter = useAppRouter();

    // let history = useHistory();
    useEffect(() => {
      CapApp.removeAllListeners();

      CapApp.addListener("appUrlOpen", async (event: URLOpenListenerEvent) => {
        console.log("reopen with url");
        // console.log(event);
        // console.log(event.url);

        let signResult = await isSignInWithEmailLink(auth, event.url);
        // console.log(`signResult ->`, signResult);
        // サインの場合
        if (signResult) {
          let email = (await Storage.get({ key: LocalStorageKey.CREATE_USER_EMAIL_ADDRESS })).value;
          if (!email) {
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            // alert("no email data");
            return;
          }

          try {
            let userCredential: any = await signInWithEmailLink(auth, email, event.url);
            // console.log(`userCredential ->`, userCredential);
            // console.log(`passwordHash ->`, userCredential.user.reloadUserInfo.passwordHash);

            // if already has password
            if (userCredential.user.reloadUserInfo.passwordHash) {
              iRouter.push(`/login?email=${email}`);
            } else {
              await Storage.set({ key: LocalStorageKey.LOGIN_UID, value: userCredential.user.uid });
              await Storage.set({
                key: LocalStorageKey.LOGIN_ACCESS_TOKEN,
                value: userCredential.user.accessToken,
              });

              iRouter.push("/welcome");
            }
          } catch (error) {
            iRouter.push("/create-user-expired");
          }
          return;
        } else {
          // alexaのurlを判断します。
          const urlParams = new URLSearchParams(event.url.substring(event.url.indexOf("?")));
          const scope = urlParams.get("scope");
          const code = urlParams.get("code");
          // alexa側の操作
          if (scope && scope.startsWith("alexa")) {
            // 処理内容はalexasetting画面に移動する
            const now = new Date();
            iRouter.push(`/menu/alexa-setting?code=${code}`);
            return;
          }

          // reset password
          const res = ActionCodeURL.parseLink(event.url);
          if (res && res.code) {
            switch (res.operation) {
              case "PASSWORD_RESET":
                iRouter.push(`/create-user-set-password?code=${res.code}`);
                break;
              case "VERIFY_AND_CHANGE_EMAIL":
                handleEmailChange(iRouter);
                break;
              default:
                break;
            }
          }
        }
      });

      CapApp.addListener("appStateChange", ({ isActive }) => {
        console.log("app status change : ", isActive);

        if (isActive && !isPasscodePausedForUsingCamera) {
          const checkScreenLocker = async () => {
            // get password for screen locker
            const passCodeAvailable = await isScreenLockerEnabled();
            if (passCodeAvailable && auth?.currentUser) {
              iRouter.push("/screen-locker-passcode-confirm");
            } else {
              updateApp();
            }
          };

          if (!window.location.pathname.includes("/screen-locker-passcode")) {
            checkScreenLocker();
          }
        }
      });

      // the below return will remove all the listeners when app starting, this can't be enabled
      // return () => {
      //   CapApp.removeAllListeners();
      // };
    }, []);
    return null;
  };

  const AlertNotification: React.FC<any> = () => {
    // 現状の実装 (presentの呼び出し)ではFunctionalComponentにするのは冗長だが、
    // 将来、Alert画面のデザインなどをカスタマイズする時にはこのような実装にする必要がある。
    // 将来に備えて予めこの形にしておく。
    const dispatch = useDispatch();

    const alertInfo: AlertInfoType = useSelector((state: RootState) => state.alertInfo);
    const [present] = useIonAlert();

    useEffect(() => {
      // 内容無しの場合、何も表示しない。
      if (alertInfo.status === AlertType.NONE) {
        return;
      }

      let header = "";
      switch (alertInfo.status) {
        case AlertType.ERROR:
          header = "エラー";
          break;
        case AlertType.WARN:
          header = "ワーニング";
          break;
        case AlertType.INFO:
          header = "インフォ";
          break;
        default:
        // 不可能
      }

      // 内容ありの場合
      // 暫定下記の内容を表示する
      present({
        header: header,
        message: alertInfo.msg,
        onDidDismiss: (e) => {
          // 表示内容をクリアする
          dispatch(clearAlertInfo());
        },
      });
      return;
    }, [alertInfo]);

    return null;
  };

  useEffect(() => {
    console.log("App Mounted");

    if (isPlatform("capacitor")) {
      window.screen.orientation.lock("portrait");
    }

    reloadCssForIosSimulatorWithAppleSilicon();

    initialPlugin();

    console.log("add listener");

    // post pending requests when the app is online
    Network.addListener("networkStatusChange", async ({ connected }) => {
      if (connected) {
        setTimeout(async () => {
          const res = await batchPostData();
          if (res === PENDING_REQ_SYNCED_STATUS.ALL_SUCCESS) {
            console.info("All the batchs executed successfully.");
          }
        }, 10000);
      }
    });

    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      (notificationAction: ActionPerformed) => {
        let extraData = notificationAction.notification.extra;
        let scheduleDate = notificationAction.notification.schedule?.at;

        // 通知時間の設定
        let notificationDate = new Date();
        // apiの時間がある場合
        if (scheduleDate) {
          // タイプは文字列の場合
          if (typeof scheduleDate === "string") {
            // 文字列は日付に変更する
            notificationDate = new Date(scheduleDate);
          } else if (scheduleDate instanceof Date) {
            // 時間の場合、そのまま設定する
            notificationDate = scheduleDate;
          }
        }

        if (auth.currentUser) {
          // 日付の情報を設定する
          dispatch(
            updateDailyAlarmRedirect({
              id: extraData.id,
              type: extraData.type,
              date: getDateStrByDate(notificationDate),
            }),
          );
          routerControl.current.history.push(
            `/home/medication-record?time=${new Date()}`,
            "forward",
            "push",
          );
        } else {
          dispatch(
            updateDailyAlarmRedirect({
              id: extraData.id,
              type: extraData.type,
              date: getDateStrByDate(notificationDate),
            }),
          );
        }
      },
    );

    if (isPlatform("capacitor")) {
      // キーボードの監視を実施する。
      Keyboard.addListener("keyboardWillShow", (info) => {
        dispatch(updateCapacityKeyboardHeight(info.keyboardHeight));
      });

      Keyboard.addListener("keyboardDidHide", () => {
        dispatch(updateCapacityKeyboardHeight(0));
      });
    }

    console.log("App Mounted Finish");

    // 定時データ取得を起動する
    getAlarmData();

    return () => {
      CapApp.removeAllListeners();
      Network.removeAllListeners();
      LocalNotifications.removeAllListeners();
      if (isPlatform("capacitor")) {
        // キーボードの監視を解除する
        Keyboard.removeAllListeners();
      }
    };
  }, []);

  // useEffect(() => {
  //   console.log("App Mounted");

  //   // let url = 'https://codepush.appcenter.ms/v0.1/public/codepush/update_check?deployment_key=URueUavrBqvaADNgZmp450w_QJCU7uAP8TEQT&app_version=1.0&package_hash=0b46b227d95521d5ec2ea366e329561fbc8697635fd77af04ed9f2ff8fa07ed5&is_companion=false&client_unique_id=80429a48c93375d9';
  //   // const options = {
  //   //   url: 'https://www.baidu.com',
  //   // };
  //   // console.log(Http);
  //   // Http.get(options).then(res=>{
  //   //   console.log('response')
  //   //   console.log(res);
  //   // }).catch(error=>{
  //   //   console.log('error')
  //   //   console.log(error);
  //   // });

  //   // CodePush.getAppVersion().then(version=>{
  //   //   console.log('version');
  //   //   console.log(version);
  //   // })

  //   // CodePush.getBinaryHash().then(hash=>{
  //   //   console.log('hash');
  //   //   console.log(hash);
  //   // })

  //   // CodePush.getNativeBuildTime().then(time=>{
  //   //   console.log('time');
  //   //   console.log(time);
  //   // })

  //   // CodePush.getPackageHash().then(hash=>{
  //   //   console.log('package hash');
  //   //   console.log(hash);
  //   // })

  //   // CodePush.restartApplication().then(()=>{
  //   //   console.log('restart')
  //   // })
  //   // codePush.getPendingPackage().then(value=>{
  //   //   alert(value);
  //   //   if (value) {
  //   //     CodePush.restartApplication();
  //   //   }
  //   // });

  //   // codePush.checkForUpdate(
  //   //   function (update) {
  //   //     if (!update) {
  //   //       console.log(update);

  //   //       console.log("The app is up to date test.");
  //   //     } else {
  //   //       present({
  //   //         header: "Alert",
  //   //         message: "An update is available! Should we download it?",
  //   //         buttons: [
  //   //           "Cancel",
  //   //           {
  //   //             text: "Ok",
  //   //             handler: (d) => {
  //   //               codePush
  //   //                 .sync(
  //   //                   {
  //   //                     ignoreFailedUpdates: false,
  //   //                     updateDialog: true,
  //   //                     installMode: 1,
  //   //                     mandatoryInstallMode: 1,
  //   //                     onSyncError: (error) => {
  //   //                       console.log("sync execute() error");
  //   //                       console.log(error);
  //   //                     },
  //   //                     onSyncStatusChanged: (result) => {
  //   //                       console.log("sync execute() status change");
  //   //                       console.log(result);
  //   //                     },
  //   //                   },
  //   //                   (result) => {
  //   //                     console.log("progress");
  //   //                     console.log(result);
  //   //                   },
  //   //                 )
  //   //                 .then((syncStatus) => {
  //   //                   console.log("sync execute()");
  //   //                   console.log(syncStatus);
  //   //                 })
  //   //                 .catch((error) => {});
  //   //               console.log("ok pressed");
  //   //             },
  //   //           },
  //   //         ],
  //   //         onDidDismiss: (e) => console.log("did dismiss"),
  //   //       });
  //   //     }
  //   //   },
  //   //   function (error) {
  //   //     console.log("Error");
  //   //     console.log(error);
  //   //   },
  //   //   "URueUavrBqvaADNgZmp450w_QJCU7uAP8TEQT",
  //   // );

  //   // console.log('start sync');
  //   // codePush.sync({ onSyncStatusChanged:(status)=>{
  //   //   console.log('sync status');
  //   //   console.log(status);
  //   // } },
  //   // {
  //   //     installMode: InstallMode.ON_NEXT_RESTART, updateDialog: true
  //   // },
  //   // function (downloadProgress) {
  //   //     console.log("Downloading " + downloadProgress.receivedBytes + " of " + downloadProgress.totalBytes + " bytes.");
  //   // }
  //   // ).then(value=>{
  //   //   console.log('sync end result');
  //   //   console.log(value);
  //   // });
  //   // codePush.sync();
  // }, []);

  return (
    <IonApp>
      <IonReactRouter ref={routerControl}>
        <AppUrlListener />

        <AlertNotification />
        <AppLoading isOpen={isLoadingVisible} />

        <IonRouterOutlet animation={navAnimationBuilder}>
          <IonRoute exact path="/" render={() => <Loading />} />
          <IonRoute exact path="/chat" render={() => <Chat />} />
          <IonRoute exact path="/chatMenus" render={() => <ChatMenu display={true} />} />
          <IonRoute exact path="/login" render={() => <Login />} />
          <IonRoute exact path="/symptom-questionnaire" render={() => <SymptomQuestionnaire />} />
          <IonRoute exact path="/qr-scan" render={() => <QRScan />} />
          <IonRoute
            exact
            path="/reports/vitals/blood-pressure-records/edit"
            render={() => <BloodPressure />}
          />
          <IonRoute
            exact
            path="/reports/vitals/blood-pressure-records/fetch-from-device"
            render={() => <FetchBloodPressure />}
          />
          <IonRoute exact path="/medicine-information" render={() => <MedicineInformation />} />
          {/* <IonRoute
            exact
            path="/medicine-information-details"
            render={() => <MedicineInformationDetails />}
          /> */}

          <IonRoute
            exact
            path="/prescription-information"
            render={() => <PrescriptionInformation />}
          />
          <IonRoute exact path="/prescription-hospital" render={() => <PrescriptionHospital />} />
          <IonRoute exact path="/prescription-fee" render={() => <PrescriptionFee />} />
          <IonRoute exact path="/prescription-memo" render={() => <PrescriptionMemo />} />
          <IonRoute exact path="/prescription-big-img" render={() => <PrescriptionBigImage />} />

          <IonRoute exact path="/big-img" render={() => <BigImg />} />

          <IonRoute exact path="/test" render={() => <Test />} />
          <IonRoute
            exact
            path="/medicine-information-memo"
            render={() => <MedicineInformationMemo />}
          />
          {/* New User */}
          <IonRoute path="/create-user-email" render={() => <CreateUserEmail />} />
          <IonRoute path="/create-user-email-confirm" render={() => <CreateUserEmailConfirm />} />
          <IonRoute path="/welcome" render={() => <Welcome />} />
          <IonRoute path="/create-user-set-password" render={() => <CreateUserSetPassword />} />
          <IonRoute path="/create-user-expired" render={() => <CreateUserExpired />} />
          <IonRoute path="/create-user-base-info" render={() => <CreateUserBaseInfo />} />
          <IonRoute path="/modify-user-base-info/:type" render={() => <ModifyUserBaseInfo />} />
          <IonRoute path="/settings/accounts/withdraw" exact render={() => <AccountsWithdraw />} />
          <IonRoute
            path="/settings/accounts/withdraw/complete"
            render={() => <AccountsWithdrawComplete />}
          />

          <IonRoute
            path="/settings/accounts/change-password/input"
            render={() => <ChangePassword />}
          />
          <IonRoute
            path="/settings/accounts/change-password/complete"
            render={() => <ChangePasswordSuccess />}
          />

          <IonRoute path="/forget-password" render={() => <ForgetPasswordSendMail />} />
          <IonRoute
            path="/forget-password-success"
            render={() => <ForgetPasswordSendMailSuccess />}
          />

          <IonRoute path="/reset-password-success" render={() => <ResetPasswordSuccess />} />

          <IonRoute path="/settings/accounts/change-email" exact render={() => <ChangeEmail />} />
          <IonRoute
            path="/settings/accounts/change-email/confirmation"
            render={() => <ChangeEmailConfirm />}
          />
          <IonRoute
            path="/settings/accounts/change-email/complete"
            render={() => <ChangeEmailSuccess />}
          />
          <IonRoute
            path="/settings/auto-data-fetching"
            exact
            render={() => <AutoDataFetchSetting />}
          />
          <IonRoute
            path="/settings/auto-data-fetching/supported-devices"
            render={() => <SupportedDevices />}
          />
          <IonRoute
            path="/settings/auto-data-fetching/index-settings/:type"
            render={() => <IndexSetting />}
          />

          <IonRoute path="/screen-locker" render={() => <ScreenLocker />} />
          <IonRoute
            path="/screen-locker-passcode/:type"
            render={() => <ScreenLockerPasscodeInit />}
          />
          <IonRoute
            path="/screen-locker-passcode-confirm"
            render={() => <ScreenLockerPasscodeConfirm />}
          />

          {/* 基本設定：通知 */}
          <IonRoute path="/settings/basic/notifications" render={() => <NotificationConfig />} />

          {/* 問い合わせ */}
          <IonRoute path="/settings/contacts/input" exact render={() => <Contact />} />
          <IonRoute path="/settings/contacts/confirm" exact render={() => <ContactConfirm />} />
          <IonRoute path="/settings/contacts/complete" exact render={() => <ContactComplete />} />

          {/* Alexa設定 */}
          <IonRoute path="/menu/alexa-setting" exact render={() => <AlexaSetting />} />

          {/* サポート：お知らせ */}
          <IonRoute
            path="/settings/supports/anouncements"
            exact
            render={() => <SupportAnnouncementIndex />}
          />
          <IonRoute
            path="/settings/supports/anouncements/:noticeId"
            render={() => <SupportAnnouncementDetail />}
          />

          {/* サポート：よくある質問 */}
          <IonRoute path="/settings/faqs" exact render={() => <Faqs />} />
          <IonRoute path="/settings/faqs/:faqId" render={() => <FaqsDetail />} />

          {/* サポート：その他 */}
          <IonRoute path="/menu/others/:id" render={() => <Others />} />

          {/* サポート：登録する症状の設定 */}
          <IonRoute path="/menu/symptom-setting" render={() => <SymptomSetting />} />

          {/* tab page */}
          <IonRoute path="/home" render={() => <Home />} />

          {/* 症状の登録 */}
          <IonRoute path="/symptom-entering-details" render={() => <SymptomEnteringDetails />} />

          <IonRoute path="/demo" render={() => <Demo />} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     userInfo: state.userInfo,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     userInfoActions: bindActionCreators(userInfoActions, dispatch),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(App);
