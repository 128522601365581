import { DEFAULT_DISEASE_ID } from "src/web/constants";
import { getDataForceFromLocal } from "../request/apiManager";

// get draft saved answer
export function fetchHealthCheckInfo(
  params: { [key: string]: string },
  diseaseId?: string,
  isForceLocal?: Boolean,
) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataForceFromLocal(
    `/api/v2/diseases/${pathDiseaseId}/report/avg-current-score`,
    params,
    !!isForceLocal,
  );
}
