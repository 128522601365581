import { useState } from "react";
import { useIonViewWillEnter } from "@ionic/react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { MenuType, FaqType, FaqContentType } from "src/web/types";
import { useFaqsModel } from "src/web/hooks/models";

export function useFaqControl() {
  const iRouter = useAppRouter();

  const { fetchFaqList } = useFaqsModel();

  const [isLoading, setIsLoading] = useState(false);
  const [filterBy, setFilterBy] = useState<string>("");
  const [lastFilterBy, setLastFilterBy] = useState<string>("");
  const [resultList, setResultList] = useState<FaqContentType[]>([]);
  const [filteredResultList, setFilteredResultList] = useState<FaqContentType[]>([]);

  const [contentListGroupByCategory, setContentListGroupByCategory] = useState<MenuType[]>();

  const fetchData = async (inFilterBy?: string) => {
    try {
      setIsLoading(true);
      const res: FaqType = await fetchFaqList();
      setResultList(res.content);
      setFilteredResultList(res.content);
      setContentListGroupByCategory(res.menuList);

      if (inFilterBy) {
        doFilter(inFilterBy, res.content);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: any) => {
    setFilterBy(e.detail.value);
  };

  const doFilter = async (inFilterBy?: string, allFaqList?: FaqContentType[]) => {
    const currentFilterBy = inFilterBy || filterBy;
    const allList = allFaqList || resultList;

    setLastFilterBy(currentFilterBy);

    if (!currentFilterBy) {
      setFilteredResultList(allList);
      iRouter.push(`/settings/faqs`, "none", "replace");
      return;
    }

    let tmpFilteredList: FaqContentType[] = [];
    allList.forEach((item: FaqContentType) => {
      if (item.title.includes(currentFilterBy) || item.content.includes(currentFilterBy)) {
        tmpFilteredList.push(item);
      }
    });
    setFilteredResultList(
      tmpFilteredList.sort((a: any, b: any) => a.faq_category_id - b.faq_category_id),
    );
    iRouter.push(`/settings/faqs?filterBy=${currentFilterBy}`, "none", "replace");
  };

  const init = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const initFilterBy = queryParams.get("filterBy");

    if (initFilterBy) {
      setFilterBy(initFilterBy);
      fetchData(initFilterBy);
    } else {
      fetchData();
    }
  };

  useIonViewWillEnter(() => {
    init();
  });

  return {
    isLoading,
    lastFilterBy,
    filterBy,
    contentListGroupByCategory,
    filteredResultList,
    handleChange,
    doFilter,
  };
}
