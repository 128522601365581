import { useState } from "react";
import { useIonViewWillEnter } from "@ionic/react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { AnouncementType } from "src/web/types";
import { useAnnouncementModel } from "src/web/hooks/models/useAnnouncementModel";

export function useSupportAnnouncementIndexControl() {
  const iRouter = useAppRouter();

  const { fetchAnnouncemenList } = useAnnouncementModel();

  const [isLoading, setIsLoading] = useState(false);
  const [resultList, setResultList] = useState<AnouncementType[]>([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res: AnouncementType[] = await fetchAnnouncemenList();
      setResultList(res);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const navToDetails = (id: number) => {
    iRouter.push(`/settings/supports/anouncements/${id}`);
  };

  useIonViewWillEnter(() => {
    fetchData();
  });

  return {
    isLoading,
    resultList,
    navToDetails,
  };
}
