import { actionConst } from "../constants";
import { LoadingSymptonQuestionAndAnswerType } from "../types";

interface Action {
  type: actionConst;
  state: LoadingSymptonQuestionAndAnswerType;
}

const initState: LoadingSymptonQuestionAndAnswerType = {
  isLoading: false,
};

export default function symptomQuestionsReducer(state = initState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_SYMPTOM_QUESTIONS:
      return actions.state;
    default:
      return state;
  }
}
