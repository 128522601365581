import { useState, useEffect } from "react";
import { IonPage, IonContent, useIonAlert, ScrollDetail, useIonViewWillEnter } from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { AppHeader, AppSecondaryButton } from "../../components/UiComponents";
import { FIREBASE_FILE_NAMES, IMAGES_FOLDER, LocalStorageKey } from "../../constants";
import { clearPrescriptionInfo, updateMedicineInfo, updatePrescriptionInfo } from "../../actions";
import { RootState } from "../../reducer";
import { MedicineInfoType } from "src/web/types";
import { PrescriptionInfoType } from "../PrescriptionInformation/type";
import { useQueryString } from "src/web/hooks/useQueryString";
import { storage } from "../../firebase";
import { Storage } from "@capacitor/storage";
import { ref, getDownloadURL } from "firebase/storage";
import { useAppRouter } from "../../hooks/useAppRouter";
import styles from "./bigImg.module.css";
import { useIntl } from "react-intl-phraseapp";

export function BigImg() {
  const intl = useIntl();
  const [presentAlert] = useIonAlert();

  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  // 表示イメージのタイプ
  const type = params.type;
  // the medicine index in prescription
  const medicineIdx = params.medicineIdx as string;

  const dispatch = useDispatch();
  // 全画面の情報を取得する
  const formData: MedicineInfoType = useSelector((state: RootState) => state.medicineInformation);

  // 全画面の情報を取得する
  const prescriptionInformation: PrescriptionInfoType = useSelector((state: RootState) => {
    return state.prescriptionInformation;
  });

  const [imgSrc, setImgSrc] = useState("");

  const getUrl = async () => {
    const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });
    // url パス
    const imageRef = ref(
      storage,
      `${IMAGES_FOLDER}/${userId.value}/${formData.prescription_id}/${formData.id}/${FIREBASE_FILE_NAMES.medicineFileName}`,
    );
    try {
      let downloadUrl = await getDownloadURL(imageRef);
      setImgSrc(downloadUrl);
    } catch {}
  };

  const handleConfirm = () => {
    // if can find the matching medicine record in prescriptionInformation, sync the image as well
    if (
      prescriptionInformation?.medicines &&
      imgSrc === prescriptionInformation?.medicines[parseInt(medicineIdx)].photoWebviewPath
    ) {
      prescriptionInformation.medicines[parseInt(medicineIdx)].photoWebviewPath = "";
      dispatch(
        updatePrescriptionInfo({
          ...prescriptionInformation,
        }),
      );
    }

    dispatch(
      updateMedicineInfo({
        ...formData,
        changeImgFlg: true,
        photoWebviewPath: "",
      }),
    );
    ionRouter.goBack();
  };

  useEffect(() => {
    // 自分設定のimg
    if (type === "1") {
      if (formData.changeImgFlg) {
        setImgSrc(formData.photoWebviewPath as string);
      } else {
        getUrl();
      }
      return;
    }
  }, []);

  // スクロール関連
  const [isDisplayBorder, setIsDisplayBorder] = useState(false);

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    // setIsDisplayBorder(true);
    if (ev.detail.scrollTop === 0) {
      setIsDisplayBorder(false);
    } else {
      setIsDisplayBorder(true);
    }
  };

  useIonViewWillEnter(() => {
    // if the prescription in redux is not match with the current medicine, delete the prescription record
    if (!!prescriptionInformation?.medicines && prescriptionInformation.medicines.length > 0) {
      const isMatchingPrescriptionRecordWithCurrentMedicine =
        prescriptionInformation.medicines.some(
          (prescriptionMedItem) => prescriptionMedItem.id === formData.id,
        );
      if (!isMatchingPrescriptionRecordWithCurrentMedicine) {
        dispatch(clearPrescriptionInfo());
      }
    }
  });

  return (
    <>
      <IonPage>
        <AppHeader isDisplayBorder={isDisplayBorder}>
          <AppSecondaryButton
            small={true}
            transparent={true}
            onClick={() => {
              presentAlert({
                header: intl.formatMessage({ id: "pages.big_img.delete_dialog.header" }),
                mode: "ios",
                buttons: [
                  {
                    text: intl.formatMessage({ id: "pages.big_img.delete_dialog.cancel" }),
                    role: "cancel",
                    handler: () => {
                      // 何も表示しない
                    },
                  },
                  {
                    text: "はい",
                    role: intl.formatMessage({ id: "pages.big_img.delete_dialog.confirm" }),
                    handler: handleConfirm,
                  },
                ],
              });
            }}
          >
            {intl.formatMessage({ id: "pages.big_img.button.delete" })}
          </AppSecondaryButton>
        </AppHeader>
        <IonContent scrollEvents={true} onIonScroll={handleScroll}>
          <div className={styles.content}>
            {imgSrc && <img src={imgSrc} className={styles.photo} />}
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
