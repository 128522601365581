import { useEffect, useState } from "react";
import { IonContent, IonLabel, IonPage } from "@ionic/react";
import {
  AppHeader,
  AppLoading,
  AppTextLink,
  AppToggleButton,
} from "../../../components/UiComponents";
import { LocalStorageKey, AlexaConfig } from "../../../constants";
import { Storage } from "@capacitor/storage";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { deleteAlexaData, getAlexaData, postAlexaData } from "../../../services/request/apiManager";
import { useQueryString } from "src/web/hooks/useQueryString";
import { useDispatch } from "react-redux";
import { PageActionCode, PageCode } from "../../../types";
import { PspAlexaAuthError, PspAlexaError } from "../../../errors";
import { useIntl } from "react-intl-phraseapp";
import { useCommon } from "src/web/utils/hooks/common";


export function AlexaSetting() {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const { parseQs } = useQueryString();
  const { processAsyncFunc } = useCommon();

  const params = parseQs(ionRouter.routeInfo.search);

  const [isLinked, setIsLinked] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  /**********************/
  /*sill状態の関連docは下記です*/
  /*https://developer.amazon.com/en-US/docs/alexa/smapi/skill-enablement.html*/
  /********************* */
  const createAlexaToken = async (code: string) => {
    const response = await postAlexaData("/api/alexa_skill/alexa_auth", "", {
      grant_type: "authorization_code",
      code: code,
    });

    // alexa認証 status 200以外は全部エラーです。
    if (response.data.alexa_status !== 200) {
      // alexa状態のエラーを投げる
      throw new PspAlexaAuthError(response.data.alexa_status, response.data?.alexa_body?.message);
    }

    const AlexaTokenInfo = {
      access_token: response.data["alexa_body"]["access_token"],
      refresh_token: response.data["alexa_body"]["refresh_token"],
      expires_in: response.data["alexa_body"]["expires_in"],
      start: new Date().getTime(),
    };

    // ストレージ情報を保存する
    await Storage.set({ key: LocalStorageKey.ALEXA_TOKEN, value: JSON.stringify(AlexaTokenInfo) });
  };

  const refreshAlexaToken = async (refresh_token: string) => {
    const response = await postAlexaData("/api/alexa_skill/alexa_auth", "", {
      grant_type: "refresh_token",
      refresh_token: refresh_token,
    });

    // alexa認証 status 200以外は全部エラーです。
    if (response.data.alexa_status !== 200) {
      // alexa状態のエラーを投げる
      throw new PspAlexaAuthError(response.data.alexa_status, response.data?.alexa_body?.message);
    }

    const AlexaTokenInfo = {
      access_token: response.data["alexa_body"]["access_token"],
      refresh_token: response.data["alexa_body"]["refresh_token"],
      expires_in: response.data["alexa_body"]["expires_in"],
      start: new Date().getTime(),
    };

    return AlexaTokenInfo;
  };

  const getAlexaToken = async () => {
    // ローカル設置から、トークン情報を取得する
    const optionString = await Storage.get({ key: LocalStorageKey.ALEXA_TOKEN });
    if (!optionString.value) {
      return null;
    }

    // トークン取得
    // タイムアウトは3600秒ですが、
    // 念のために10分前無効にします。
    const alexaToken = JSON.parse(optionString.value);

    const differ = (new Date().getTime() - alexaToken["start"]) / 1000 + 60 * 10;

    // タイムアウトの場合再取得する
    if (alexaToken["expires_in"] < differ) {
      const AlexaTokenInfo = await refreshAlexaToken(alexaToken["refresh_token"]);
      // ストレージ情報を保存する
      await Storage.set({
        key: LocalStorageKey.ALEXA_TOKEN,
        value: JSON.stringify(AlexaTokenInfo),
      });
      return AlexaTokenInfo;
    }

    return JSON.parse(optionString.value);
  };

  const toggleLinkStatus = async () => {
    setIsLoading(true);
    try {
      const alexaToken = await getAlexaToken();
      if (!isLinked) {
        const response = await postAlexaData(
          "/api/alexa_skill/enable_skill",
          alexaToken["access_token"],
          {},
        );

        // alexa status
        // 201 Created
        // 400 Bad Request
        // 401 Unauthorized
        // 403 Forbidden
        // 404 Not Found
        // 429 Too Many Requests
        // 500 Server Error
        // 503 Service Unavailable
        if (response.data.alexa_status === 201) {
          setIsLinked(true);
          return;
        }
        if (response.data.alexa_status === 401) {
          setHasToken(false);
          return;
        }
        // alexa状態のエラーを投げる
        throw new PspAlexaError(response.data.alexa_status, response.data?.alexa_body?.message);
      } else {
        const response = await deleteAlexaData(
          "/api/alexa_skill/delete_skill",
          alexaToken["access_token"],
        );

        // alexa status
        // 200 OK
        // 204 No Content
        // 400 Bad Request
        // 401 Unauthorized
        // 403 Forbidden
        // 404 Not Found
        // 429 Too Many Requests
        // 500 Server Error
        // 503 Service Unavailable
        if (response.data.alexa_status === 204 || response.data.alexa_status === 200) {
          setIsLinked(false);
          return;
        }
        if (response.data.alexa_status === 401) {
          setHasToken(false);
          return;
        }
        // alexa状態のエラーを投げる
        throw new PspAlexaError(response.data.alexa_status, response.data?.alexa_body?.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const initialPage = async () => {
    let isLinked = false;
    let hasToken = false;
    setIsLoading(true);
    try {
      // 初回目コードでトークン作成の処理を実施します。
      if (params.code) {
        await createAlexaToken(params.code as string);
      }

      // ストレージにトークンを取得する
      const alexaToken = await getAlexaToken();
      // トークン存在しない
      if (!alexaToken) {
        return;
      }

      // スキルの状態を取得する
      const response = await getAlexaData("/api/alexa_skill/status", alexaToken["access_token"]);

      // alexa status
      // 200 OK
      // 400 Bad Request
      // 401 Unauthorized
      // 403 Forbidden
      // 404 Not Found
      // 429 Too Many Requests
      // 500 Server Error
      // 503 Service Unavailable
      if (response.data.alexa_status === 200) {
        // アカウントはリンクしていない
        isLinked = response.data.alexa_body["accountLink"]["status"] !== "NOT_LINKED";
        // トークンの状態はokです。
        hasToken = true;
        return;
      }

      if (response.data.alexa_status === 404) {
        // アカウントはリンクしていない
        isLinked = false;
        // トークンの状態はokです。
        hasToken = true;
        return;
      }

      // トークンの時間は切りました。
      if (response.data.alexa_status === 401) {
        return;
      }

      throw new PspAlexaError(response.data.alexa_status, response.data?.alexa_body?.message);
    } finally {
      // 状態更新
      setIsLinked(isLinked);
      setHasToken(hasToken);
      setIsLoading(false);
    }
  };

  const linkToAlexa = async () => {
    // リンクは固定です。
    const url = encodeURI(
      `https://www.amazon.com/ap/oa?client_id=${AlexaConfig.alexa_client_id}&scope=${
        AlexaConfig.alexa_scope
      }&response_type=code&redirect_uri=${AlexaConfig.call_back_url}&state=${new Date().getTime()}`,
    );
    window.open(url, "_system", "location=yes");
  };

  // 毎回画面表示するとき起動する
  useEffect(() => {
    processAsyncFunc(
      initialPage,
      {
        pageCode: PageCode.T05_1,
        pageActionCode: PageActionCode.C_INITIAL,
      },
      dispatch,
    );
  }, [params.code]);

  return (
    <>
      <IonPage>
        <AppHeader />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="p-header1">
              {intl.formatMessage({ id: "page.menu.alexa_setting.title" })}
            </IonLabel>

            <div className="page-spacer-120" />
            <div className="page-spacer-120" />

            {hasToken ? (
              <div>
                <div className="flex-row-between">
                  <IonLabel className="p-body">
                    {intl.formatMessage({ id: "page.menu.alexa_setting.enable_skill.title" })}
                  </IonLabel>

                  <AppToggleButton
                    checked={isLinked}
                    onClick={async () => {
                      await processAsyncFunc(
                        toggleLinkStatus,
                        {
                          pageCode: PageCode.T05_1,
                          pageActionCode: PageActionCode.T05_1_A01,
                        },
                        dispatch,
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              // Alexa トークンが存在しない場合、
              // 下記のリンクを表示します。
              <AppTextLink
                onClick={async () => {
                  await processAsyncFunc(
                    linkToAlexa,
                    {
                      pageCode: PageCode.T05_1,
                      pageActionCode: PageActionCode.T05_1_A02,
                    },
                    dispatch,
                  );
                }}
              >
                {intl.formatMessage({
                  id: "page.menu.alexa_setting.text.link.alexa_account_setting",
                })}
              </AppTextLink>
            )}
          </div>
          <AppLoading isOpen={isLoading} />
        </IonContent>
      </IonPage>
    </>
  );
}
