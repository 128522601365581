import { IonBadge } from "@ionic/react";
import styles from "./appBadge.module.css";

interface IconBadge {
  status: "new" | "connected" | "not_connected";
  className?: string;
  onClick?: (event: any) => void;
  children: any;
  color: string;
}

export function AppBadge(props: IconBadge) {
  return (
    <IonBadge
      color={props.color}
      onClick={(event) => {
        props.onClick && props.onClick(event);
      }}
    >
      <div className="div_center">
        <div
          className={`${
            props.status === "new"
              ? styles.new
              : props.status === "connected"
              ? styles.connected
              : styles.not_connected
          }`}
        />
        <div className="page-width-spacer-5" />
        <div>{props.children}</div>
      </div>
    </IonBadge>
  );
}
