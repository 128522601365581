import {
  getDataFromApiOrLocal,
  postDataForSave,
  patchDataForSave,
  deleteData,
} from "../request/apiManager";
import { UserBaseInfoType } from "src/web/types";

// get user information
export function getUserInfo() {
  return getDataFromApiOrLocal("/api/v1/users/user_info");
}

// update or insert user basic information
export function updateAndInsertUserInfo(data: UserBaseInfoType) {
  return postDataForSave("/api/v1/users", data);
}

// update user basic information
export function updateUserInfo(data: UserBaseInfoType) {
  return patchDataForSave("/api/v1/users", data);
}

// delete user
export function deleteUser(data: any) {
  return deleteData("/api/v1/users", data);
}

export function signupEmailVerification(data: { email: string }) {
  return postDataForSave("/api/v1/users/signup_email_verification", data);
}

export function forgetPasswordEmailVerification(data: { email: string }) {
  return postDataForSave("/api/v1/users/forget_password_email_verification", data);
}

export function changeEmailVerification(data: { email: string; newEmail: string }) {
  return postDataForSave("/api/v1/users/change_email_verification", data);
}
