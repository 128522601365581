import { useState } from "react";
import { Bar, XAxis, YAxis, ResponsiveContainer, ComposedChart } from "recharts";
import { AppIcon } from "../../UiComponents";
import { ICONS } from "../../../constants";
import { CustomCartesianGrid } from "../CustomCartesianGrid/customCartesianGrid";
import { getColor } from "src/web/utils/common";
import { CurveBar } from "../CurveBar/curveBar";
import styles from "./scrollableBarChart.module.css";
import { useIntl } from "react-intl-phraseapp";

const TEXT_COLOR = "--text-color";

export interface BarChartDataTypeV2 {
  name: string;
  score: number;
  dspScore: number;
  tooltipTitle: string;
  tooltipMedicines: any[];
}

export function ScrollableBarChartV2({
  data,
  isNoData,
  scoreType,
  disableLeft,
  disableRight,
  handleLeftClick,
  handleRightClick,
}: {
  data?: BarChartDataTypeV2[];
  isNoData?: boolean;
  scoreType?: string;
  disableLeft?: boolean;
  disableRight?: boolean;
  handleLeftClick?: () => void;
  handleRightClick?: () => void;
}) {
  const intl = useIntl();
  const CustomizedXAxisTick = ({ x, y, stroke, payload, isNoData }: any) => {
    return (
      <g transform={`translate(${x},${0})`}>
        {!isNoData && (
          <text
            x={0}
            y={0}
            dy={28}
            textAnchor="middle"
            fontStyle="normal"
            fontSize={10}
            fontWeight={500}
            fill={getColor(TEXT_COLOR)}
          >
            {payload.value}
          </text>
        )}
      </g>
    );
  };

  const CustomizedYAxisTick = ({ x, y, stroke, payload }: any) => {
    if (payload.value === 60 || payload.value === 120) {
      let text = "50";
      if (payload.value === 120) {
        text = "100";
      }
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={2}
            textAnchor="end"
            fontStyle="normal"
            fontSize="10"
            fontWeight="500"
            fill={getColor(TEXT_COLOR)}
          >
            {text}
          </text>
        </g>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.containerDiv}>
        <ResponsiveContainer width="100%" height={335}>
          <ComposedChart data={data} margin={{ top: 0, right: 5, bottom: 15, left: 5 }}>
            <XAxis
              orientation="top"
              dataKey="name"
              axisLine={false}
              tickLine={false}
              height={80}
              tick={<CustomizedXAxisTick isNoData={isNoData} />}
            />
            <YAxis
              type="number"
              domain={[0, 120]}
              axisLine={false}
              tickLine={false}
              tickCount={9}
              tick={<CustomizedYAxisTick />}
            />
            <CustomCartesianGrid
              vertical={false}
              horizontalPoints={[80, 110, 140, 170, 200, 230, 260, 290, 320]}
            />
            <Bar dataKey="dspScore" shape={<CurveBar gapx={2} />} fill="#D3A4A4" barSize={12} />
          </ComposedChart>
        </ResponsiveContainer>

        {isNoData && (
          <div className={styles.emptyMsgDiv}>
            <div className={styles.emptyMsg}>
              {intl.formatMessage({ id: "components.charts.scrollable_bar_chart.no_record" })}
            </div>
          </div>
        )}
      </div>

      {handleLeftClick && (
        <AppIcon
          className={styles.floatLeftArrow}
          icon={ICONS.ICON.ARROW_BACK}
          size="sm"
          disabled={disableLeft}
          onClick={handleLeftClick}
        />
      )}

      {handleRightClick && (
        <AppIcon
          className={styles.floatRightArrow}
          icon={ICONS.ICON.ARROW_FORWARD}
          size="sm"
          disabled={disableRight}
          onClick={handleRightClick}
        />
      )}
    </div>
  );
}
