import { LoadingTimelineType } from "src/web/types";
import {
  Timeline,
  getLinePosition,
} from "src/web/pages/SymptomRecordsTab/SymptomRecordsMain/components/Timeline";
import { CategoryHeader } from "../../moleclues";

interface OverallTimelineProps {
  header: string;
  btnText: string;
  currentDate: string;
  isReadonly: boolean;
  dailyActivityTimeline: LoadingTimelineType;
  btnClick: () => void;
}

export function OverallTimeline({
  header,
  btnText,
  currentDate,
  isReadonly,
  dailyActivityTimeline,
  btnClick,
}: OverallTimelineProps) {
  return (
    <>
      <CategoryHeader
        header={header}
        isReadonly={isReadonly}
        btnText={btnText}
        btnClick={btnClick}
      />

      <div className="page-spacer-45" />

      {!!dailyActivityTimeline.list &&
        dailyActivityTimeline.list.map((data, index) => (
          <Timeline
            key={index}
            currentDate={currentDate}
            data={data}
            linePosition={getLinePosition(index, dailyActivityTimeline!.list!.length)}
            isReadonly={isReadonly}
          />
        ))}
    </>
  );
}
