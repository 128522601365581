import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  getSymptomRecords,
  getSymptomSetting,
  createAndUpdateSymptomRecords,
  deleteSymptomRecordsById,
} from "../../services";
import { getToday, getNow, processLog } from "../../utils/common";
import {
  SymptomModelType,
  SymptomModelListType,
  SymptomRecordType,
  RecordDetailsType,
  DelSymptomRecordType,
} from "../../pages/MainTab/DiseaseMainTab/types";
import bodyCondition from "src/web/assets/svgs/icons/image_body_condition.svg";
import { updateAlertInfo } from "../../actions";
import { AlertType, FuncStatus, PageLogType } from "../../types";

export function useSymptomModel() {
  const dispatch = useDispatch();

  const [isLoadingSymptom, setIsLoadingSymptom] = useState(false);

  // 今の症状
  /*
    get all active symptom list with score
    isOnHome: set true to show all the isActiveOnHome
  */
  const fetchSymptomListWithScoreAllActive = async (
    logType: PageLogType,
    data?: {
      isOnHome?: boolean;
      symptomId?: number; // get record for the symptom Id only if required
      registeredDate?: string;
      time?: string;
    },
  ) => {
    setIsLoadingSymptom(true);
    let list: SymptomModelListType[] = [];

    // get symptom records list
    try {
      processLog(logType, FuncStatus.START);
      const res: SymptomModelListType[] = await getSymptomSetting();
      list = res.filter((item) => {
        if (data?.isOnHome) {
          return item.isActiveOnHome;
        } else {
          return item.isActive;
        }
      });
      list.forEach((item) => (item.iconSrc = bodyCondition));
    } catch (error) {
      processLog(logType, FuncStatus.ERROR);
      setIsLoadingSymptom(false);
    }

    if (!list.length) {
      processLog(logType, FuncStatus.END);
      setIsLoadingSymptom(false);
      return undefined;
    }

    // get Pain Scale Values

    let symptomData: SymptomModelType | undefined = undefined;
    try {
      let payload: any = {
        registeredDate: data?.registeredDate ?? getToday(),
        time: data?.time ?? getNow().replace(":", "").padStart(4, "0"),
      };
      if (data?.symptomId) {
        payload.symptomId = `${data.symptomId}`;
      }
      const valueRes: SymptomModelType = await getSymptomRecords(payload);
      list.forEach((item) => {
        const isFound = valueRes.recordDetails.find(
          (scoreItem) => scoreItem.symptomId === item.symptomId,
        );
        if (isFound) {
          item.painScaleValue = isFound.painScaleValue;
        }
      });

      if (data?.symptomId) {
        list = list.filter((item) => item.symptomId === data?.symptomId);
      }

      symptomData = {
        id: valueRes.id,
        time: valueRes.time,
        comment: valueRes.comment,
        recordDetails: list,
      };
    } catch (error) {
      symptomData = {
        id: null,
        time: null,
        comment: "",
        recordDetails: list,
      };
    } finally {
      setIsLoadingSymptom(false);
      processLog(logType, FuncStatus.END);
      return symptomData;
    }
  };

  /*
    check if the record is existed
    the check is only required for edit
    return 
      true: existed
      false: not existed
  */
  const duplicatedCheck = async (data: any, id?: number | null) => {
    try {
      setIsLoadingSymptom(true);
      const valueRes: SymptomModelType = await getSymptomRecords(data);
      if (valueRes) {
        if (!id || (!!id && id !== valueRes.id)) {
          setIsLoadingSymptom(false);
          return true;
        }
      }
    } catch (error) {}
    return false;
  };

  const doPost = async (logType: PageLogType, post: (data: any) => void, payload: any) => {
    setIsLoadingSymptom(true);
    processLog(logType, FuncStatus.START);
    try {
      await post(payload);
    } catch (error: any) {
      processLog(logType, FuncStatus.ERROR);
      dispatch(
        updateAlertInfo({
          status: AlertType.ERROR,
          msg: error.message.message,
        }),
      );
      throw error;
    } finally {
      processLog(logType, FuncStatus.END);
      setIsLoadingSymptom(false);
    }
  };

  const saveSymptom = async (logType: PageLogType, data: any) => {
    const payload: SymptomRecordType = {
      registeredDate: data.nowDate,
      time: data.nowTime?.replace(":", "").padStart(4, "0"),
      isMerge: data.isMerge,
      recordDetails: [
        {
          symptomId: data.data?.symptomId,
          painScaleValue: data.currentScore,
        },
      ],
    };

    await doPost(logType, createAndUpdateSymptomRecords, payload);
  };

  /*
    save for all the records on 症状の登録 page
  */
  const batchSaveForAllSymptoms = async (logType: PageLogType, data: any) => {
    const cloneRecordDetails = JSON.parse(JSON.stringify(data.recordDetails));
    const recordDetails: RecordDetailsType[] = cloneRecordDetails.filter((item: any) => {
      const prop = ["symptomId", "painScaleValue"];
      for (let k in item) {
        if (prop.indexOf(k) < 0) {
          delete item[k];
        }
      }
      return item.painScaleValue >= 0;
    });

    const payload: SymptomRecordType = {
      registeredDate: data.registeredDate,
      time: data.time,
      comment: data.comment,
      isMerge: data.isMerge,
      recordDetails,
      id: data?.id ?? undefined,
    };

    await doPost(logType, createAndUpdateSymptomRecords, payload);
  };

  const deleteSymptomsById = async (logType: PageLogType, id: number) => {
    const payload: DelSymptomRecordType = { id };
    await doPost(logType, deleteSymptomRecordsById, payload);
  };

  return {
    isLoadingSymptom,
    fetchSymptomListWithScoreAllActive,
    saveSymptom,
    duplicatedCheck,
    batchSaveForAllSymptoms,
    deleteSymptomsById,
  };
}
