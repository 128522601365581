import { Fragment } from "react";
import { LabelWithContents, PageTitle } from "../../moleclues";

interface ContactInfoViewProps {
  pageTitle: string;
  data: { label: string; value: string }[];
}

export function ContactInfoView({ pageTitle, data }: ContactInfoViewProps) {
  return (
    <>
      <PageTitle title={pageTitle} />

      {data.map((field, index) => (
        <Fragment key={index}>
          <div className="page-spacer-45" />
          <LabelWithContents title={field.label} text={field.value} />
        </Fragment>
      ))}
    </>
  );
}
