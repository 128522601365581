import { IonBadge, IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";
import { useIntl } from "react-intl-phraseapp";
import { AppSelectInput } from "../../AppInput/appSelectInput";
import { HOUR_KV_OPTIONS, MINUTE_KV_OPTIONS, MMHG_OPTIONS, PULSE_OPTIONS } from "src/web/constants";
import { BloodPressureDayDataType } from "src/web/types";
import styles from "./bloodPressureForm.module.css";

interface BloodPressureFormProps {
  formData: BloodPressureDayDataType;
  fieldRefs: any;
  handleValueChange: (type: "am" | "pm", field: string, value: string) => void;
}

export function BloodPressureForm({
  formData,
  fieldRefs,
  handleValueChange,
}: BloodPressureFormProps) {
  const intl = useIntl();

  const getCurrentTime = (): string => {
    // 現時点の時間を返却する
    // h:mmの形式です。
    // mmの場合、0詰めは必要です。
    let now = new Date();
    return `${now.getHours()}:${now.getMinutes().toString().padStart(2, "0")}`;
  };

  return (
    <IonGrid>
      <IonRow>
        <IonCol size="4"></IonCol>
        <IonCol size="4">
          <IonBadge color="gray01" className={styles.periodBadge}>
            <IonLabel className="p-smallbody white">
              {intl.formatMessage({
                id: "page.main_tab.disease_main_tab.disease_main.blood_pressure.menu.morning",
              })}
            </IonLabel>
          </IonBadge>
        </IonCol>
        <IonCol size="4">
          <IonBadge color="gray01" className={styles.periodBadge}>
            <IonLabel className="p-smallbody white">
              {intl.formatMessage({
                id: "page.main_tab.disease_main_tab.disease_main.blood_pressure.menu.night",
              })}
            </IonLabel>
          </IonBadge>
        </IonCol>
      </IonRow>

      <div className="page-spacer-15" />

      <IonRow>
        <IonCol size="4"></IonCol>
        <IonCol size="4">
          <AppSelectInput
            placeholder="8:00"
            getDefaultValue={getCurrentTime}
            value={formData.am.time}
            setValue={(val: string) => {
              handleValueChange("am", "time", val);
            }}
            options={HOUR_KV_OPTIONS}
            options2={MINUTE_KV_OPTIONS}
            separatedBy=":"
            isLoop={true}
            needClear={false}
            readonly={!formData.am.isNewInput || !!formData.am.deviceFlg}
            size="sm"
          />
        </IonCol>

        <IonCol size="4">
          <AppSelectInput
            placeholder="19:30"
            getDefaultValue={getCurrentTime}
            value={formData.pm.time}
            setValue={(val: string) => {
              handleValueChange("pm", "time", val);
            }}
            options={HOUR_KV_OPTIONS}
            options2={MINUTE_KV_OPTIONS}
            separatedBy=":"
            isLoop={true}
            needClear={false}
            readonly={!formData.pm.isNewInput || !!formData.pm.deviceFlg}
            size="sm"
          />
        </IonCol>
      </IonRow>

      <div className="page-spacer-30" />

      <IonRow className="ion-align-items-center">
        <IonCol size="4">
          <IonLabel className="p-header4 text-block">
            {intl.formatMessage({ id: "page.blood_pressure.systolic_blood_pressure" })}
          </IonLabel>
          <IonLabel className="p-caption text-block">{ intl.formatMessage({id: "common.blood_pressure.unit"}) }</IonLabel>
        </IonCol>

        <IonCol size="4">
          <AppSelectInput
            ref={fieldRefs.ammaxBloodPressureRef}
            placeholder="130"
            defaultValue={130}
            value={formData.am.maxBloodPressure}
            setValue={(val: string) => {
              handleValueChange("am", "maxBloodPressure", val);
            }}
            options={MMHG_OPTIONS}
            needClear={false}
            readonly={!formData.am.isNewInput}
            size="sm"
          />
        </IonCol>

        <IonCol size="4">
          <AppSelectInput
            ref={fieldRefs.pmmaxBloodPressureRef}
            placeholder="130"
            defaultValue={130}
            value={formData.pm.maxBloodPressure}
            setValue={(val: string) => {
              handleValueChange("pm", "maxBloodPressure", val);
            }}
            options={MMHG_OPTIONS}
            needClear={false}
            readonly={!formData.pm.isNewInput}
            size="sm"
          />
        </IonCol>
      </IonRow>

      {/* 分割 */}
      <div className="page-spacer-15" />

      <IonRow className="ion-align-items-center">
        <IonCol size="4">
          <IonLabel className="p-header4 text-block">
            {intl.formatMessage({ id: "page.blood_pressure.diastolic_blood_pressure" })}
          </IonLabel>
          <IonLabel className="p-caption text-block">{ intl.formatMessage({id: "common.blood_pressure.unit"}) }</IonLabel>
        </IonCol>

        <IonCol size="4">
          <AppSelectInput
            ref={fieldRefs.amminBloodPressureRef}
            placeholder="80"
            defaultValue={80}
            value={formData.am.minBloodPressure}
            setValue={(val: string) => {
              handleValueChange("am", "minBloodPressure", val);
            }}
            options={MMHG_OPTIONS}
            needClear={false}
            readonly={!formData.am.isNewInput}
            size="sm"
          />
        </IonCol>

        <IonCol size="4">
          <AppSelectInput
            ref={fieldRefs.pmminBloodPressureRef}
            placeholder="80"
            defaultValue={80}
            value={formData.pm.minBloodPressure}
            setValue={(val: string) => {
              handleValueChange("pm", "minBloodPressure", val);
            }}
            options={MMHG_OPTIONS}
            needClear={false}
            readonly={!formData.pm.isNewInput}
            size="sm"
          />
        </IonCol>
      </IonRow>

      {/* 分割 */}
      <div className="page-spacer-15" />
      <hr />
      <div className="page-spacer-15" />

      <IonRow className="ion-align-items-center">
        <IonCol size="4">
          <IonLabel className="p-header4 text-block">
            {intl.formatMessage({ id: "page.blood_pressure.pulse" })}
          </IonLabel>
          <IonLabel className="p-caption text-block">{ intl.formatMessage({id: "common.pulse.unit"}) }</IonLabel>
        </IonCol>

        <IonCol size="4">
          <AppSelectInput
            ref={fieldRefs.amheartRateRef}
            placeholder="60"
            defaultValue={60}
            value={formData.am.heartRate}
            setValue={(val: string) => {
              handleValueChange("am", "heartRate", val);
            }}
            options={PULSE_OPTIONS}
            needClear={false}
            readonly={!formData.am.isNewInput}
            size="sm"
          />
        </IonCol>

        <IonCol size="4">
          <AppSelectInput
            ref={fieldRefs.pmheartRateRef}
            placeholder="60"
            defaultValue={60}
            value={formData.pm.heartRate}
            setValue={(val: string) => {
              handleValueChange("pm", "heartRate", val);
            }}
            options={PULSE_OPTIONS}
            needClear={false}
            readonly={!formData.pm.isNewInput}
            size="sm"
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
}
