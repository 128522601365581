import { actionConst } from "../constants";

interface Actions {
  type: actionConst;
  state: any;
}

export default function dailyAlarmRedirectReducer(state = "", actions: Actions) {
  switch (actions.type) {
    case actionConst.UPDATE_DAILY_ALARM_REDIRECT:
      return actions.state;
    case actionConst.CLEAR_DAILY_ALARM_REDIRECT:
      return {};
    default:
      return state;
  }
}
