import { useState } from "react";
import { WEEK_MONTH_TAB } from "src/web/constants";

export function useToggleWeekAndMonth() {
  const [weekOrMonth, setWeekOrMonth] = useState(WEEK_MONTH_TAB.WEEK);

  const toggleWeekAndMonth = () => {
    setWeekOrMonth(
      weekOrMonth === WEEK_MONTH_TAB.WEEK ? WEEK_MONTH_TAB.MONTH : WEEK_MONTH_TAB.WEEK,
    );
  };

  return {
    isWeekChart: weekOrMonth === WEEK_MONTH_TAB.WEEK,
    toggleWeekAndMonth,
  };
}
