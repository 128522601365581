import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import { store, persistorStore } from "./web/store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { setupIonicReact } from "@ionic/react";

import { IntlProvider } from "react-intl";
import { selectMessages } from "./i18n/config";

import { initializePhraseAppEditor } from "react-intl-phraseapp";

let config = {
  projectId: "488d6ec93f6543249b98ff850d18bc11",
  phraseEnabled: process.env.REACT_APP_IS_PHRASE_ENABLED == "true",
  useOldICE: true,
  prefix: "[[__",
  suffix: "__]]",
  fullReparse: true,

  baseUrl: "https://us.app.phrase.com",
  apiBaseUrl: 'https://api.us.app.phrase.com/api/v2',
  oauthEndpointUrl: "https://api.us.app.phrase.com/api/v2/authorizations",
  profileUrl: "https://us.app.phrase.com/settings/profile",
};
//8d160b05171728549e5138ec06a7164c1d466400a0c57ecaecb024b46668c6ce

initializePhraseAppEditor(config);

// Forcing the mode to be Material Design
setupIonicReact({
  rippleEffect: false,
  mode: "md",
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistorStore}>
      <React.StrictMode>
        <IntlProvider locale={navigator.language} messages={selectMessages()}>
          <App />
        </IntlProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
