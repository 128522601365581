import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import HealthCareManager, { DeviceInfoType } from "src/web/plugin/healthcare-manager-plugin";
import { BLUETOOTH, DEVICE_CATEGORY } from "src/web/constants";
import { useTextConstants } from "src/web/constants/hooks";
import { updateSphygmomanometer } from "src/web/actions";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { DeviceType } from "src/web/types";

const DEVICE_SELECTION_STEP = 1;
const DEVICE_SELECTED_STEP = 2;
const DEVICE_READY_TO_CONNECT_STEP = 3;
const DEVICE_CONNECTTING_STEP = 4;

export function useDeviceModalControl(
  isActive: boolean,
  handleCancel: () => void,
  deviceCategory?: string,
  handleAfterPairing?: () => void,
) {
  const ionRouter = useAppRouter();
  const { DEVICE_ERROR } = useTextConstants();
  const dispatch = useDispatch();

  const [tmpDevice, setTmpDevice] = useState<DeviceInfoType>();
  const [step, setStep] = useState(DEVICE_SELECTION_STEP);
  const [showError, setShowError] = useState({
    flg: false,
    message: "",
  });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleDeviceConnectionError = (message: string) => {
    setShowError({
      flg: true,
      message,
    });
  };

  // request device permission
  const requestDevicePermission = async () => {
    if (tmpDevice?.deviceId) {
      try {
        const data: any = await HealthCareManager.requestPermission({
          deviceId: tmpDevice.deviceId,
        });
        if (data.result && data.result === "OK") {
          setStep(DEVICE_READY_TO_CONNECT_STEP);
        } else if (data.permissionErrors && !!data.permissionErrors.length) {
          handleDeviceConnectionError(DEVICE_ERROR[`${data.permissionErrors[0].code}`]);
        }
      } catch (_) {
        handleDeviceConnectionError("");
      }
    }
  };

  // request device connection
  const requestDeviceConnection = async () => {
    if (tmpDevice?.deviceId) {
      try {
        const data: any = await HealthCareManager.connect({
          deviceId: tmpDevice.deviceId,
        });
        if (data.result && data.result === "OK") {
          // get device name
          const device: DeviceType | undefined = BLUETOOTH[
            deviceCategory || DEVICE_CATEGORY.BLOOD_PRESSURE
          ].find((dev) => dev.deviceId === tmpDevice.deviceId);
          // update device
          dispatch(
            updateSphygmomanometer({
              deviceId: tmpDevice.deviceId,
              deviceName: device ? device.deviceName : "",
            }),
          );
          // update pairing status
          handleAfterPairing && handleAfterPairing();
          handleCancel();
          setShowSuccessAlert(true);
        } else if (data.errors && !!data.errors.length) {
          handleDeviceConnectionError(DEVICE_ERROR[`${data.errors[0].code}`]);
        }
      } catch (_) {
        handleDeviceConnectionError("");
      }
    }
  };

  const isOpenDeviceSelection = useMemo(
    () => isActive && step === DEVICE_SELECTION_STEP,
    [isActive, step],
  );
  const isOpenDeviceSelected = useMemo(
    () => isActive && step === DEVICE_SELECTED_STEP,
    [isActive, step],
  );
  const isOpenDeviceReadyToConnect = useMemo(
    () => isActive && step === DEVICE_READY_TO_CONNECT_STEP,
    [isActive, step],
  );
  const isOpenDeviceConnecting = useMemo(
    () => isActive && step === DEVICE_CONNECTTING_STEP,
    [isActive, step],
  );

  const handleSelectionNext = (device: DeviceInfoType) => {
    setTmpDevice(device);
    setStep(DEVICE_SELECTED_STEP);
  };

  const handleReadyToConnectNext = () => {
    setStep(DEVICE_CONNECTTING_STEP);
  };

  const handleSuccessClose = () => {
    setShowSuccessAlert(false);
    setTimeout(() => {
      ionRouter.push("blood-pressure-fetching", "forward", "push");
    }, 100);
  };

  const handleSuccessNext = () => {
    setShowSuccessAlert(false);
    setTimeout(() => {
      // ionRouter.push("/home/main", "forward", "replace");
      ionRouter.goBack();
    }, 100);
  };

  const handleSuccessCancel = () => {
    // update pairing status
    handleAfterPairing && handleAfterPairing();
    setShowSuccessAlert(false);
  };

  const handleErrorClose = () => {
    setShowError({ flg: false, message: "" });
    setStep(DEVICE_SELECTION_STEP);
  };

  const handleErrorNext = () => {
    setShowError({ flg: false, message: "" });
    handleCancel();
    setTimeout(() => {
      // ionRouter.push("/home/main", "forward", "replace");
      ionRouter.goBack();
    }, 100);
  };

  const handleErrorCancel = () => {
    setShowError({ flg: false, message: "" });
    setStep(DEVICE_SELECTION_STEP);
    handleCancel();
  };

  useEffect(() => {
    if (step === DEVICE_CONNECTTING_STEP) {
      requestDeviceConnection();
    }
  }, [step]);

  useEffect(() => {
    if (!isActive) {
      setStep(DEVICE_SELECTION_STEP);
    }

    // handle native back: cancel modal
    const handleNativeBack = (ev: any) => {
      ev.detail.register(5, (processNextHandler: any) => {
        if (isActive) {
          handleCancel();
        } else {
          processNextHandler();
        }
      });
    };
    document.addEventListener("ionBackButton", handleNativeBack);

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, [isActive]);

  return {
    isOpenDeviceSelection,
    isOpenDeviceSelected,
    isOpenDeviceReadyToConnect,
    isOpenDeviceConnecting,
    tmpDevice,
    showError,
    showSuccessAlert,
    handleSelectionNext,
    requestDevicePermission,
    handleReadyToConnectNext,
    handleSuccessClose,
    handleSuccessNext,
    handleSuccessCancel,
    handleErrorClose,
    handleErrorNext,
    handleErrorCancel,
  };
}
