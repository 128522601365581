import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../web/reducer";
import {
  updateLoadingDisplay
} from "../../web/actions";

export function useLoadingDisplay() {
  const isLoadingDisplay = useSelector((state: RootState) => state.loadingDisplay);
  const dispatch = useDispatch();

  const setIsLoadingVisible = (isLoadingVisible: boolean) => {
    dispatch(updateLoadingDisplay(isLoadingVisible));
  };

  return {
    isLoadingVisible: isLoadingDisplay,
    setIsLoadingVisible
  };
}
