import { useState, useEffect, Fragment } from "react";
import { IonLabel } from "@ionic/react";
import {
  AppStatefulCheckbox,
  AppIcon,
  STATEFUL_STATES,
  AppPrimaryButton,
  AppCalendar,
} from "../../../../components/UiComponents";
import {
  ICONS,
  DATE_FORMAT_SPLIT,
} from "../../../../constants";
import {
  getCurrentYearMonth,
  getDateStrByDate,
  getToday,
  getNDaysBeforeOrAfterToday,
  getHoursDiff,
} from "../../../../utils/common";
import {
  PrescriptionCard,
  PrescriptionCardType,
} from "../../../../components/PrescriptionCard/prescriptionCard";
import {
  getCheckMedicineMonth,
  getCheckMedicineCurrent,
  getRelatedPrescription,
  getAnswerListsForCurrentMonthByDate,
} from "../../../../services";
import { useQueryString } from "src/web/hooks/useQueryString";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import { MonthCheckedType, DailyMedicineDataType, CheckMedicineCurrentResType } from "../../type";
import styles from "./records.module.css";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

export function Records({
  setLoading,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const intl = useIntl();
  const {
    DAY_CONVERT,
    MEDICINE_TIMING_OPTIONS,
  } = useTextConstants();
  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  const medicineId = params.id;

  const today = getToday();
  // const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState({
    flg: false,
    editTimeLeft: "",
  });
  const [currentMonth, setCurrentMonth] = useState<string>(
    `${getCurrentYearMonth()}${DATE_FORMAT_SPLIT}01`,
  );
  const [selectedDate, setSelectedDate] = useState<string>(today);
  const [hasAnswerForTheSelectedDate, setHasAnswerForTheSelectedDate] = useState(false);

  const [calendarData, setCalendarData] = useState<MonthCheckedType[]>();
  const [dailyMedicineData, setDailyMedicineData] = useState<DailyMedicineDataType[]>([]);
  const [relatedPrescriptionList, setRelatedPrescriptionList] = useState<PrescriptionCardType[]>();

  const generateDailyData = (res: CheckMedicineCurrentResType) => {
    let dailyList: DailyMedicineDataType[] = [];
    Object.entries(res).map(([type, list]) => {
      const typeOption = MEDICINE_TIMING_OPTIONS.find((opt) => opt.value === type);
      list.forEach((data: DailyMedicineDataType) => {
        data.type_name =
          typeOption?.text ||
          intl.formatMessage({
            id: "page.medicine_information_details.records.value.not_specified",
          });
        let time = data.time.split(":");
        data.time = `${time[0]}:${time[1]}`;
      });

      if (list.length === 0) {
        list = [
          {
            type_name:
              typeOption?.text ||
              intl.formatMessage({
                id: "page.medicine_information_details.records.value.not_specified",
              }),
            check_date: "",
            check_index: 0,
            medicine_id: 0,
            quantity: 0,
            status: "",
            time: "",
            time_type: "",
          },
        ];
      }

      dailyList = [...dailyList, ...list];
    });
    setDailyMedicineData(dailyList);
  };

  const doFetchMonth = async () => {
    setLoading(true);
    const promises: any[] = [];
    // fetch current data only on the first fetch
    if (!calendarData) {
      // fetch the details of the day
      promises.push(
        getCheckMedicineCurrent({
          currentDate: selectedDate,
          medicine_id: `${medicineId}`,
        }),
      );

      // fetch related prescription
      promises.push(
        getRelatedPrescription({
          currentDate: selectedDate,
          medicine_id: `${medicineId}`,
        }),
      );

      // get answers check if display "疾患との関係を見る" button
      promises.push(
        getAnswerListsForCurrentMonthByDate({
          id: "1",
          currentDate: selectedDate,
        }),
      );
    }

    try {
      const medicineMonthRes: MonthCheckedType[] = await getCheckMedicineMonth({
        currentDate: currentMonth,
        id: `${medicineId}`,
      });
      setCalendarData(
        medicineMonthRes.map((data) => {
          return {
            check_date: data.check_date.split("T")[0],
            cnt: data.cnt,
          };
        }),
      );
    } catch (error) {
      setCalendarData([]);
    }

    try {
      const res = await Promise.allSettled(promises);
      if (res[0]) {
        if (res[0].status === "fulfilled") {
          generateDailyData((res[0] as any).value);
        } else {
          setDailyMedicineData([]);
        }
      }

      if (res[1]) {
        if (res[1].status === "fulfilled") {
          setRelatedPrescriptionList((res[1] as any).value);
        } else {
          setRelatedPrescriptionList([]);
        }
      }

      if (res[2]) {
        if (res[2].status === "fulfilled") {
          const answers = (res[2] as any).value;
          const existed = answers.find((answer: any) => answer.check_date === selectedDate);
          setHasAnswerForTheSelectedDate(!!existed);
        } else {
          setHasAnswerForTheSelectedDate(false);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const doFetchDate = async (date: string) => {
    setLoading(true);
    try {
      const res: CheckMedicineCurrentResType = await getCheckMedicineCurrent({
        currentDate: date,
        id: `${medicineId}`,
      });
      generateDailyData(res);

      setLoading(false);
    } catch (error) {
      setDailyMedicineData([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    doFetchMonth();
  }, [currentMonth]);

  useEffect(() => {
    // disabled the record if 2 days before, or is future date
    if (selectedDate < getNDaysBeforeOrAfterToday(-1) || selectedDate > getToday()) {
      setDisabled({
        flg: true,
        editTimeLeft: "",
      });
    } else {
      setDisabled({
        flg: false,
        editTimeLeft: `${48 - getHoursDiff(new Date(`${selectedDate} 00:00`), new Date())}`,
      });
    }
  }, [selectedDate]);

  return (
    <>
      <IonLabel className="p-header3">
        {intl.formatMessage({
          id: "page.medicine_information_details.records.label.medication_records",
        })}
      </IonLabel>

      <div className="page-spacer-15" />

      <hr />

      <div className="page-spacer-30" />

      <AppCalendar
        currentMonth={currentMonth}
        setCurrentMonth={setCurrentMonth}
        selectedDate={selectedDate}
        handleSelectDateChange={(val: string) => {
          setSelectedDate(val);
          const existData = calendarData?.find((calData) => calData.check_date === val);
          if (!!existData) {
            doFetchDate(val);
          } else {
            setDailyMedicineData([]);
          }
        }}
        calendarData={calendarData}
        isFutureSelectable={true}
        isAllClickable={true}
      />

      <div className="page-spacer-45" />

      <div className="flex-row-between">
        <IonLabel className={styles.selectedDateLabel}>
          {getDateStrByDate(new Date(selectedDate))}({DAY_CONVERT[new Date(selectedDate).getDay()]})
        </IonLabel>
        {!disabled.flg && selectedDate <= getToday() && (
          <IonLabel className={styles.deadlineLabel}>
            {intl.formatMessage(
              { id: "page.medicine_information_details.records.label.edit_time_left" },
              { time: disabled.editTimeLeft },
            )}
          </IonLabel>
        )}
      </div>

      <div className="page-spacer-35" />

      {dailyMedicineData.length > 0 ? (
        dailyMedicineData.map((data, index) => (
          <Fragment key={index}>
            <div className="flex-row-between">
              {/* 13: 時刻... */}
              <IonLabel className={styles.statusName}>
                {data.time_type === "13" ? data.time : data.type_name}
              </IonLabel>

              <div className="flex-row-end">
                {data.status === STATEFUL_STATES.unchecked ? (
                  <>
                    <IonLabel className={styles.statusText}>
                      {intl.formatMessage({
                        id: "page.medicine_information_details.records.value.not_recorded",
                      })}
                    </IonLabel>
                    {!disabled.flg && (
                      <div className={styles.statusIcon}>
                        <AppIcon icon={ICONS.ICON.ARROW_FORWARD} size="sm" />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ display: "inline-block" }}>
                      <AppStatefulCheckbox
                        label={
                          data.status === STATEFUL_STATES.checked
                            ? intl.formatMessage({
                                id: "page.medicine_information_details.records.value.recorded",
                              })
                            : data.status === STATEFUL_STATES.halfChecked
                            ? intl.formatMessage({
                                id: "page.medicine_information_details.records.value.forgot",
                              })
                            : intl.formatMessage({
                                id: "page.medicine_information_details.records.value.skip",
                              })
                        }
                        checkedState={data.status}
                        size="small"
                        readonly={true}
                      />
                    </div>

                    <div className={`p-smallbody ${styles.statusTime}`}>{data.time}</div>

                    {!disabled.flg && (
                      <div className={styles.statusIcon}>
                        <AppIcon icon={ICONS.ICON.ARROW_FORWARD} size="sm" />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {!!dailyMedicineData && index !== dailyMedicineData.length - 1 && (
              <div className="page-spacer-45" />
            )}
          </Fragment>
        ))
      ) : (
        <>
          {new Date(selectedDate) <= new Date(today) ? (
            <IonLabel className={`p-body text-center-block ${styles.noData}`}>
              {intl.formatMessage({ id: "page.medicine_information_details.records.no_record" })}
            </IonLabel>
          ) : (
            <IonLabel className={`p-body text-center-block ${styles.noData}`}>
              {intl.formatMessage({ id: "page.medicine_information_details.records.not_planned" })}
            </IonLabel>
          )}
        </>
      )}

      <div className="page-spacer-60" />

      <AppPrimaryButton
        full={true}
        disabled={!hasAnswerForTheSelectedDate}
        onClick={() => ionRouter.push(`symptom-report-details?currentDate=${selectedDate}`)}
      >
        {intl.formatMessage({ id: "page.medicine_information_details.records.view_symptom" })}
      </AppPrimaryButton>

      <div className="page-spacer-60" />

      {/* 常に表示します。 */}
      <IonLabel className="p-header3">
        {intl.formatMessage({
          id: "page.medicine_information_details.records.list_prescription_records",
        })}
      </IonLabel>

      <div className="page-spacer-15" />

      <hr />

      <div className="page-spacer-30" />

      {!!relatedPrescriptionList && !!relatedPrescriptionList.length && (
        <>
          {relatedPrescriptionList &&
            relatedPrescriptionList.map((item: PrescriptionCardType, index: number) => (
              <Fragment key={index}>
                <PrescriptionCard
                  dispensing_date={item.dispensing_date}
                  hospital={item.hospital}
                  id={item.id}
                  pharmacy={item.pharmacy}
                  type={item.type}
                  medicine_names={item.medicine_names}
                />
                {!!relatedPrescriptionList && index !== relatedPrescriptionList.length - 1 && (
                  <div className="page-spacer-15" />
                )}
              </Fragment>
            ))}

          <div className="page-spacer-60" />
        </>
      )}
    </>
  );
}
