import { actionConst } from "../constants";

interface Actions {
  type: string;
  isShown: boolean;
}

export default function showHeaderBoarderReducer(state: boolean = false, actions: Actions) {
  switch (actions.type) {
    case actionConst.UPDATE_SHOW_HEADER_BORDER:
      return actions.isShown;
    default:
      return state;
  }
}
