export const MedicineLineDot = (props: any) => {
  const { cx, cy, payload, value, index, dataKey } = props;

  if (value) {
    return (
      <svg x={cx - 7} y={cy - 7} width={14} height={14} viewBox="0 0 14 14" fill="none">
        <circle cx="7" cy="7" r="7" fill="#C98B69" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25087 6.0511L9.47479 7.27502C10.0823 7.88251 10.0823 8.86743 9.47479 9.47491C8.86731 10.0824 7.88238 10.0824 7.2749 9.47491L6.05099 8.251C6.20412 7.90442 6.56535 7.43457 6.99991 7.00002C7.43445 6.56547 7.90429 6.20424 8.25087 6.0511ZM4.26857 7.04648L5.59071 8.34069L6.99992 9.7499C7.75927 10.5093 8.99042 10.5093 9.74978 9.7499C10.5091 8.99055 10.5091 7.75939 9.74978 7.00004L8.34739 5.59765L7.04095 4.26963C6.8535 4.08188 6.63753 3.9401 6.40701 3.84429C5.69471 3.5317 4.83324 3.66699 4.25006 4.25018C3.68802 4.81222 3.54197 5.63273 3.81193 6.32909C3.90676 6.59089 4.05897 6.83654 4.26857 7.04648ZM4.48805 5.42743C4.51571 5.28233 4.69137 5.25016 4.79565 5.35462C4.84978 5.40883 4.86854 5.48774 4.86027 5.56396C4.83525 5.79441 4.91094 6.03375 5.08733 6.21042L5.78414 6.90836C5.86002 6.98436 5.86002 7.10757 5.78414 7.18357C5.70827 7.25957 5.58525 7.25957 5.50938 7.18357L4.81256 6.48563C4.5254 6.198 4.41723 5.79898 4.48805 5.42743Z"
          fill="white"
        />
      </svg>
    );
  }
  return <></>;
};

export const MedicineScatterDot = (props: any) => {
  const { cx, cy, payload, value, index, dataKey } = props;

  if (value) {
    return (
      <svg x={cx - 7} y={cy - 7} width={14} height={14} viewBox="0 0 14 14" fill="none">
        <circle cx="7" cy="7" r="7" fill="#B65554" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25087 6.0511L9.47479 7.27502C10.0823 7.88251 10.0823 8.86743 9.47479 9.47491C8.86731 10.0824 7.88238 10.0824 7.2749 9.47491L6.05099 8.251C6.20412 7.90442 6.56535 7.43457 6.99991 7.00002C7.43445 6.56547 7.90429 6.20424 8.25087 6.0511ZM4.26857 7.04648L5.59071 8.34069L6.99992 9.7499C7.75927 10.5093 8.99042 10.5093 9.74978 9.7499C10.5091 8.99055 10.5091 7.75939 9.74978 7.00004L8.34739 5.59765L7.04095 4.26963C6.8535 4.08188 6.63753 3.9401 6.40701 3.84429C5.69471 3.5317 4.83324 3.66699 4.25006 4.25018C3.68802 4.81222 3.54197 5.63273 3.81193 6.32909C3.90676 6.59089 4.05897 6.83654 4.26857 7.04648ZM4.48805 5.42743C4.51571 5.28233 4.69137 5.25016 4.79565 5.35462C4.84978 5.40883 4.86854 5.48774 4.86027 5.56396C4.83525 5.79441 4.91094 6.03375 5.08733 6.21042L5.78414 6.90836C5.86002 6.98436 5.86002 7.10757 5.78414 7.18357C5.70827 7.25957 5.58525 7.25957 5.50938 7.18357L4.81256 6.48563C4.5254 6.198 4.41723 5.79898 4.48805 5.42743Z"
          fill="white"
        />
      </svg>
    );
  }
  return <></>;
};

export const TemperatureDot = (props: any) => {
  const { cx, cy, payload, value, index, dataKey } = props;

  if (value) {
    return (
      <svg x={cx - 7} y={cy - 7} width={14} height={14} viewBox="0 0 14 14" fill="none">
        <circle cx="7" cy="7" r="7" fill="#79AE95" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.20995 3.77338C3.82414 4.10539 3.77957 4.68795 4.11158 5.07376L7.08737 8.53527C7.17344 8.63518 7.27797 8.71357 7.39017 8.76736C7.55802 8.84721 7.74774 8.90172 7.93805 8.9564C8.28627 9.05645 8.63645 9.15706 8.85809 9.41448L9.73422 10.4336C9.80339 10.5135 9.92482 10.5227 10.0048 10.4535C10.0847 10.3844 10.0939 10.2629 10.0247 10.183L9.1486 9.16393C8.92698 8.90654 8.88013 8.54507 8.83358 8.18585C8.80812 7.98946 8.78276 7.79375 8.72897 7.61609C8.69208 7.4962 8.63214 7.38245 8.54606 7.28254L5.57027 3.82103C5.23826 3.43522 4.6557 3.39065 4.26836 3.72266L4.20841 3.77338H4.20995ZM5.61295 4.88726L5.1654 5.27207C5.10103 5.32742 5.09372 5.42447 5.14907 5.48884L6.64524 7.22891C6.70059 7.29328 6.79763 7.3006 6.862 7.24525L7.30955 6.86043C7.37392 6.80509 7.38123 6.70804 7.32589 6.64367L5.82971 4.90359C5.77437 4.83923 5.67732 4.83191 5.61295 4.88726ZM5.17678 4.53115C5.17678 4.67291 5.06185 4.78784 4.92009 4.78784C4.77832 4.78784 4.66339 4.67291 4.66339 4.53115C4.66339 4.38938 4.77832 4.27445 4.92009 4.27445C5.06185 4.27445 5.17678 4.38938 5.17678 4.53115Z"
          fill="white"
        />
      </svg>
    );
  }
  return <></>;
};

export const BloodPressureDot = (props: any) => {
  const { cx, cy, payload, value, index, dataKey } = props;

  if (value) {
    return (
      <svg x={cx - 7} y={cy - 7} width={14} height={14} viewBox="0 0 14 14" fill="none">
        <circle cx="7" cy="7" r="7" fill="#798EAE" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.86114 3.11133C4.32419 3.11133 3.88892 3.54661 3.88892 4.08355V9.91688C3.88892 10.4538 4.32419 10.8891 4.86114 10.8891H9.13892C9.67586 10.8891 10.1111 10.4538 10.1111 9.91688V4.08355C10.1111 3.54661 9.67586 3.11133 9.13892 3.11133H4.86114ZM5.25003 3.88911C4.92786 3.88911 4.66669 4.15027 4.66669 4.47244V8.36133C4.66669 8.68349 4.92786 8.94466 5.25003 8.94466H8.75003C9.07219 8.94466 9.33336 8.68349 9.33336 8.36133V4.47244C9.33336 4.15027 9.07219 3.88911 8.75003 3.88911H5.25003ZM6.22225 9.91688C6.22225 9.70211 6.39636 9.52799 6.61114 9.52799H7.38892C7.60369 9.52799 7.7778 9.70211 7.7778 9.91688C7.7778 10.1317 7.60369 10.3058 7.38892 10.3058H6.61114C6.39636 10.3058 6.22225 10.1317 6.22225 9.91688ZM6.73096 7.66458C6.87811 7.8158 7.1209 7.8158 7.26805 7.66458L7.2691 7.66353L7.64958 7.27185L8.29387 6.60608C8.64282 6.2375 8.64282 5.66204 8.29387 5.29345C8.1236 5.12964 7.89237 5.04353 7.65588 5.05718C7.4194 5.07083 7.19868 5.18109 7.04732 5.36276L7.00003 5.41107L6.95273 5.36486C6.80138 5.18319 6.58066 5.07188 6.34417 5.05928C6.10768 5.04563 5.87645 5.13174 5.70618 5.29555C5.35723 5.66414 5.35723 6.2396 5.70618 6.60818L6.35048 7.27395L6.73096 7.66458Z"
          fill="white"
        />
      </svg>
    );
  }
  return <></>;
};
