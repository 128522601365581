import { useIntl } from "react-intl-phraseapp";
import { IonLabel, useIonRouter } from "@ionic/react";
import { ChartDataTypeV2 } from "src/web/components/Charts";
import { getToday } from "src/web/utils/common";
import { AppSecondaryButton } from "../../AppSecondaryButton/appSecondaryButton";
import { AverageSummary } from "src/web/components/Charts/ChartSummaryPanel/components/AverageSummary/averageSummary";
import { SCORE_TAB_KEY } from "src/web/constants";
import { TimelineType } from "src/web/types";
import {
  Timeline,
  getLinePosition,
} from "src/web/pages/SymptomRecordsTab/SymptomRecordsMain/components/Timeline";
import styles from "./weeklySummaryPanelContents.module.css";

interface WeeklySummaryPanelContentsProps {
  weekSummaryForAvg: any;
  scoreType: string;
  data: ChartDataTypeV2;
  filteredTimelineList: TimelineType[] | undefined;
}

export function WeeklySummaryPanelContents({
  weekSummaryForAvg,
  scoreType,
  data,
  filteredTimelineList,
}: WeeklySummaryPanelContentsProps) {
  const ionRouter = useIonRouter();
  const intl = useIntl();

  return (
    <>
      <div className="flex-row-between">
        <div>
          <IonLabel className="p-header3">{weekSummaryForAvg[scoreType].text}</IonLabel>
        </div>
        {data.date <= getToday() && !!weekSummaryForAvg[scoreType].actionUrl && (
          <AppSecondaryButton
            isDynamicWidth
            transparent={true}
            onClick={() => {
              ionRouter.push(`${weekSummaryForAvg[scoreType].actionUrl}`);
            }}
          >
            {intl.formatMessage({
              id: "components.charts.chart_summary_panel.summary_panel.score.view_button",
            })}
          </AppSecondaryButton>
        )}
      </div>
      <div className="page-spacer-15" />
      <hr className="title-hr" />

      {!!data.overallData &&
        weekSummaryForAvg[scoreType].overall.map((key: string, index: number) => (
          <div className={`${scoreType !== "score_tab" ? styles.avgRoot : ""}`} key={index}>
            <div className="page-spacer-45" />
            <AverageSummary type={key} data={data.overallData[key]} />
          </div>
        ))}

      {scoreType !== SCORE_TAB_KEY[0] && !!filteredTimelineList?.length && (
        <>
          <div className="page-spacer-45" />
          {filteredTimelineList.map((item: TimelineType, index: number) => (
            <Timeline
              key={index}
              data={item}
              linePosition={getLinePosition(index, filteredTimelineList.length)}
              isReadonly
              isDynamicLineColor
              isShowDetails
            />
          ))}
        </>
      )}

      {((scoreType === SCORE_TAB_KEY[0] && !data.overallData) ||
        (scoreType !== SCORE_TAB_KEY[0] && !filteredTimelineList?.length)) && (
        <div>
          <div className="page-spacer-35" />
          <IonLabel className={`p-body gray text-center-block`}>
            {weekSummaryForAvg[scoreType].noDataText}
          </IonLabel>
        </div>
      )}
    </>
  );
}
