import { AppPasswordInput } from "../..";
import { PageTitle } from "../../moleclues";
import { useIntl } from "react-intl-phraseapp";

interface ChangePasswordFormProps {
  title: string;
  subTitle?: string;
  formData: {
    oldPass: string;
    firstPass: string;
    secondPass: string;
  };
  isError?: boolean;
  errorMsg?: string;
  handleInputChange: (field: string, val: string) => void;
}

export function ChangePasswordForm({
  title,
  subTitle,
  formData,
  isError,
  errorMsg,
  handleInputChange,
}: ChangePasswordFormProps) {
  const intl = useIntl();
  return (
    <>
      <PageTitle title={title} subTitle={subTitle} />

      <div className="page-spacer-45" />

      <AppPasswordInput
        label={ intl.formatMessage({id: "page.change_password.label.current_password"}) }
        value={formData.oldPass}
        onIonChange={(e: any) => {
          handleInputChange("oldPass", e.detail.value);
        }}
        error={isError}
      />

      <div className="page-spacer-45" />

      <AppPasswordInput
        label={ intl.formatMessage({id: "page.change_password.label.new_password"}) }
        value={formData.firstPass}
        onIonChange={(e: any) => {
          handleInputChange("firstPass", e.detail.value);
        }}
        error={isError}
      />

      <div className="page-spacer-45" />

      <AppPasswordInput
        label={ intl.formatMessage({id: "page.change_password.label.new_password_confirmation"}) }
        value={formData.secondPass}
        onIonChange={(e: any) => {
          handleInputChange("secondPass", e.detail.value);
        }}
        error={isError}
        errorMsg={errorMsg}
      />
    </>
  );
}
