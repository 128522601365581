import { useRef, useState, useMemo } from "react";
import { useIonViewWillEnter } from "@ionic/react";
import { USER_BASIC_INFO_TYPE } from "src/web/constants";
import { useTextConstants } from "src/web/constants/hooks";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { ErrorForm } from "src/web/types";
import { getDateStrByDate } from "src/web/utils/common";
import { useUserBaseInfoModel } from "src/web/hooks/models/useUserBaseInfoModel";

export default function useCreateUserBaseInfoControl() {
  const ionRouter = useAppRouter();
  const { FORM_ERROR_TYPES } = useTextConstants();

  const { isLoading, fetchUserBaseInfo, addOrEditUserBaseInfo } = useUserBaseInfoModel();

  const userNameRef = useRef<any>();
  const birthdayRef = useRef<any>();
  const genderRef = useRef<any>();
  const prefectureRef = useRef<any>();

  const validation = {
    required: [
      USER_BASIC_INFO_TYPE.NAME,
      USER_BASIC_INFO_TYPE.BIRTHDAY,
      USER_BASIC_INFO_TYPE.GENDER,
      USER_BASIC_INFO_TYPE.PREFECTURE,
    ],
  };

  const validationMapping: any = {
    [USER_BASIC_INFO_TYPE.NAME]: userNameRef,
    [USER_BASIC_INFO_TYPE.BIRTHDAY]: birthdayRef,
    [USER_BASIC_INFO_TYPE.GENDER]: genderRef,
    [USER_BASIC_INFO_TYPE.PREFECTURE]: prefectureRef,
  };

  const [formData, setFormData] = useState<any>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState<ErrorForm>({
    [USER_BASIC_INFO_TYPE.NAME]: {
      ...FORM_ERROR_TYPES.REQUIRED,
      ref: validationMapping[USER_BASIC_INFO_TYPE.NAME],
    },
    [USER_BASIC_INFO_TYPE.BIRTHDAY]: {
      ...FORM_ERROR_TYPES.REQUIRED,
      ref: validationMapping[USER_BASIC_INFO_TYPE.BIRTHDAY],
    },
    [USER_BASIC_INFO_TYPE.GENDER]: {
      ...FORM_ERROR_TYPES.REQUIRED,
      ref: validationMapping[USER_BASIC_INFO_TYPE.GENDER],
    },
    [USER_BASIC_INFO_TYPE.PREFECTURE]: {
      ...FORM_ERROR_TYPES.REQUIRED,
      ref: validationMapping[USER_BASIC_INFO_TYPE.PREFECTURE],
    },
  });

  const setFormDataValue = (fields: string[], values: any[]) => {
    fields.forEach((field: string, index: number) => {
      if (validation.required.includes(field) && !values[index]) {
        errors[field] = { ...FORM_ERROR_TYPES.REQUIRED, ref: validationMapping[field] };
      } else {
        delete errors[field];
      }

      // check date, if is an invalid date, show error
      if (
        field === USER_BASIC_INFO_TYPE.BIRTHDAY &&
        values[index] &&
        values[index] !== getDateStrByDate(new Date(values[index]))
      ) {
        errors[field] = { ...FORM_ERROR_TYPES.DATE, ref: validationMapping[field] };
      }

      formData[field] = values[index];
      setFormData({ ...formData });
    });

    setErrors({ ...errors });
  };

  const doSubmit = async () => {
    if (Object.keys(errors).length === 0) {
      setIsSubmitted(false);

      try {
        await addOrEditUserBaseInfo(formData);
        ionRouter.push("/home/main");
      } catch (error) {}
    } else {
      Object.values(errors)[0].ref.current.scrollIntoView({ behavior: "smooth" });
      setIsSubmitted(true);
    }
  };

  const doFetch = async () => {
    const userInfoRes = await fetchUserBaseInfo();
    if (!userInfoRes) {
      return;
    }

    setFormData({
      ...userInfoRes,
    });

    // init errors
    Object.keys(errors).map((key: string) => {
      if (userInfoRes[key]) {
        delete errors[key];
      }
    });
    setErrors({ ...errors });
  };

  const isSubmitDisabled = useMemo(() => {
    return (
      !formData[USER_BASIC_INFO_TYPE.NAME] ||
      !formData[USER_BASIC_INFO_TYPE.BIRTHDAY] ||
      !formData[USER_BASIC_INFO_TYPE.GENDER] ||
      !formData[USER_BASIC_INFO_TYPE.PREFECTURE]
    );
  }, [formData]);

  const FIELDS_CONFIG = {
    [USER_BASIC_INFO_TYPE.NAME]: {
      ref: userNameRef,
      value: formData[USER_BASIC_INFO_TYPE.NAME],
      onChange: (e: any) => setFormDataValue([USER_BASIC_INFO_TYPE.NAME], [e.detail.value]),
      error: isSubmitted && !!errors ? errors[USER_BASIC_INFO_TYPE.NAME] : null,
    },
    [USER_BASIC_INFO_TYPE.BIRTHDAY]: {
      ref: birthdayRef,
      value: formData[USER_BASIC_INFO_TYPE.BIRTHDAY],
      onChange: (val: string) => setFormDataValue([USER_BASIC_INFO_TYPE.BIRTHDAY], [val]),
      error: isSubmitted && !!errors ? errors[USER_BASIC_INFO_TYPE.BIRTHDAY] : null,
    },
    [USER_BASIC_INFO_TYPE.GENDER]: {
      ref: genderRef,
      value: formData[USER_BASIC_INFO_TYPE.GENDER],
      onChange: (val: string) => setFormDataValue([USER_BASIC_INFO_TYPE.GENDER], [val]),
      error: isSubmitted && !!errors ? errors[USER_BASIC_INFO_TYPE.GENDER] : null,
    },
    [USER_BASIC_INFO_TYPE.PREFECTURE]: {
      ref: prefectureRef,
      value: formData[USER_BASIC_INFO_TYPE.PREFECTURE],
      onChange: (val: string) => setFormDataValue([USER_BASIC_INFO_TYPE.PREFECTURE], [val]),
      error: isSubmitted && !!errors ? errors[USER_BASIC_INFO_TYPE.PREFECTURE] : null,
    },
  };

  useIonViewWillEnter(() => {
    doFetch();
  });

  return {
    FIELDS_CONFIG,
    isLoading,
    isSubmitDisabled,
    doSubmit,
  };
}
