import { postDataForSave } from "../../request/apiManager";

export function getSymptomAdvice(params: { [key: string]: string }) {
  return postDataForSave("/api/v1/personalized_advice/symptom", params);
};
export function getWeeklyIngestionAdvice(params: { [key: string]: string }) {
  return postDataForSave("/api/v1/personalized_advice/ingestion/week", params);
};
export function getMonthlyIngestionAdvice(params: { [key: string]: string }) {
  return postDataForSave("/api/v1/personalized_advice/ingestion/month", params);
};