import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MONTH_GRAPH_DISPLAY } from "src/web/constants";
import { RootState } from "src/web/reducer";
import { GraphInfoType } from "src/web/types";
import { useChangeMonthControl, useMonthChartControl } from "./hooks";
import { useIntl } from "react-intl-phraseapp";
import {
  DateSwitcherWithComments,
  MonthScoreChartWithSummary,
} from "src/web/components/UiComponents/organisms";
import { useTextConstants } from "src/web/constants/hooks";

interface MonthChartProps {
  currentMonthStartDate: string;
  setCurrentMonthStartDate: React.Dispatch<React.SetStateAction<string>>;
  toggleWeekAndMonth: () => void;
}

export function MonthChart({
  currentMonthStartDate,
  setCurrentMonthStartDate,
  toggleWeekAndMonth,
}: MonthChartProps) {
  const intl = useIntl();
  const {
    WEEK_MONTH_CHART_TABS
  } = useTextConstants();
  // 図の表示フラグ
  const graphInfo: GraphInfoType = useSelector((state: RootState) => {
    return state.graphInfo;
  });

  const {
    formattedMonthPeriod,
    isPreMonthDisabled,
    isNextMonthDisabled,
    gotoPreviousMonth,
    gotoNextMonth,
  } = useChangeMonthControl(currentMonthStartDate, setCurrentMonthStartDate);

  const {
    monthlyIngestionAdvice,
    isNoData,
    barChartData,
    first5MedicineChartData,
    allMedicineChartData,
  } = useMonthChartControl(currentMonthStartDate);

  const [monthTab, setMonthTab] = useState(WEEK_MONTH_CHART_TABS[0].name);
  const [highlightBarIndex, setHighlightBarIndex] = useState<number>();
  const [isShowMoreMedicine, setIsShowMoreMedicine] = useState(false);

  const showMoreMedicine = () => {
    setIsShowMoreMedicine(true);
    setHighlightBarIndex(undefined);
  };

  const hideMoreMedicine = () => {
    if (isShowMoreMedicine) {
      setIsShowMoreMedicine(false);
      setHighlightBarIndex(undefined);
    }
  };

  // reset highlightBarIndex when unselect
  useEffect(() => {
    if (graphInfo[MONTH_GRAPH_DISPLAY]) {
      setHighlightBarIndex(undefined);
    }
  }, [graphInfo[MONTH_GRAPH_DISPLAY]]);

  return (
    <>
      <DateSwitcherWithComments
        text={formattedMonthPeriod}
        disableLeft={isPreMonthDisabled}
        disableRight={isNextMonthDisabled}
        btnLabel={intl.formatMessage({ id: "page.symptom_main_tab.month_chart.week_mode" })}
        handleLeftClick={() => {
          gotoPreviousMonth();
          setHighlightBarIndex(undefined);
        }}
        handleRightClick={() => {
          gotoNextMonth();
          setHighlightBarIndex(undefined);
        }}
        handleBtnClick={toggleWeekAndMonth}
        comments={monthlyIngestionAdvice}
      />

      <div className="page-spacer-45" />

      <MonthScoreChartWithSummary
        monthTab={monthTab}
        barChartData={barChartData}
        isNoData={isNoData}
        first5MedicineChartData={first5MedicineChartData}
        allMedicineChartData={allMedicineChartData}
        isShowMoreMedicine={isShowMoreMedicine}
        highlightBarIndex={highlightBarIndex}
        handleTabChange={(tab: string) => {
          setMonthTab(tab);
          setHighlightBarIndex(undefined);
        }}
        showMoreMedicine={showMoreMedicine}
        hideMoreMedicine={hideMoreMedicine}
        setHighlightBarIndex={setHighlightBarIndex}
      />
    </>
  );
}
