import { useState, forwardRef, useImperativeHandle } from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import { SelectOption } from "../../../types";
import styles from "./appPicker.module.css";

interface AppTriblePickerProps {
  initVal?: string | number;
  defaultVal?: string | number;
  options: SelectOption[];
  options2: SelectOption[];
  options3: SelectOption[];
  separatedBy?: string;
}

export const AppTriblePicker = forwardRef(
  (
    { initVal, defaultVal, options, options2, options3, separatedBy = "/" }: AppTriblePickerProps,
    ref: any,
  ) => {
    useImperativeHandle(ref, () => ({
      getSelectedData: () => getSelectedData(),
    }));

    const opts = {
      direction: "vertical",
      slidesPerView: 7,
      centeredSlides: true,
      spaceBetween: 7,
    };

    let initLeftValIndex, initMiddleValIndex, initRightValIndex;

    const val =
      initVal || initVal === 0
        ? `${initVal}`.split(separatedBy)
        : defaultVal
        ? `${defaultVal}`.split(separatedBy)
        : undefined;
    initLeftValIndex = val ? options.findIndex((opt) => opt.value === val[0]) : 0;
    initMiddleValIndex = val ? options2.findIndex((opt) => opt.value === val[1]) : 0;
    initRightValIndex = val ? options3.findIndex((opt) => opt.value === val[2]) : 0;

    const [pairVal, setPairVal] = useState({
      left: initLeftValIndex,
      middle: initMiddleValIndex,
      right: initRightValIndex,
    });

    const slideLeftOpts = {
      ...opts,
      initialSlide: initLeftValIndex,
    };

    const slideMiddleOpts = {
      ...opts,
      initialSlide: initMiddleValIndex,
    };

    const slideRightOpts = {
      ...opts,
      initialSlide: initRightValIndex,
    };

    const getSelectedData = () => {
      return `${options[pairVal.left].value}${separatedBy}${
        options2[pairVal.middle].value
      }${separatedBy}${options3[pairVal.right].value}`;
    };

    return (
      <>
        <div className={`app-picker ${styles.pickerRoot}`}>
          <div className="flex-row-between">
            <IonSlides
              options={slideLeftOpts}
              className={styles.pickerWrapper}
              onIonSlideDidChange={(e: any) => {
                setPairVal({
                  ...pairVal,
                  left: e.target.swiper.realIndex,
                });
              }}
            >
              {options.map((opt: SelectOption) => (
                <IonSlide className={`${styles.pickerItem} `} key={opt.value}>
                  <div className={styles.triLeft}>{opt.text}</div>
                </IonSlide>
              ))}
            </IonSlides>

            {/* <div className={styles.divider} /> */}

            {options2 && (
              <IonSlides
                options={slideMiddleOpts}
                className={styles.pickerWrapper}
                onIonSlideDidChange={(e: any) => {
                  setPairVal({
                    ...pairVal,
                    middle: e.target.swiper.realIndex,
                  });
                }}
              >
                {options2.map((opt: SelectOption) => (
                  <IonSlide className={`${styles.pickerItem} ${styles.triMiddle}`} key={opt.value}>
                    <div className={styles.triMiddle}>{opt.text}</div>
                  </IonSlide>
                ))}
              </IonSlides>
            )}

            {/* <div className={styles.divider} /> */}

            {options3 && (
              <IonSlides
                options={slideRightOpts}
                className={styles.pickerWrapper}
                onIonSlideDidChange={(e: any) => {
                  setPairVal({
                    ...pairVal,
                    right: e.target.swiper.realIndex,
                  });
                }}
              >
                {options3.map((opt: SelectOption) => (
                  <IonSlide className={`${styles.pickerItem} ${styles.triRight}`} key={opt.value}>
                    <div className={styles.triRight}>{opt.text}</div>
                  </IonSlide>
                ))}
              </IonSlides>
            )}
          </div>
        </div>
      </>
    );
  },
);
