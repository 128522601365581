import { IonPage, IonFooter } from "@ionic/react";
import { useIntl } from "react-intl-phraseapp";
import {
  AppHeader,
  AppLoading,
  AppPrimaryButton,
  AppSecondaryButton,
  AppHomeIndicatorBar,
  AppContent,
  AppTextLink,
} from "src/web/components/UiComponents";
import AlertModal from "src/web/components/AlertModal/alertModal";
import { DeviceModal } from "src/web/components/DeviceModal/deviceModal";
import { DateWithInstruction } from "src/web/components/UiComponents/moleclues";
import { BloodPressureForm } from "src/web/components/UiComponents/organisms";
import { ConnectingStatus } from "src/web/components/ConnectingStatus/connectingStatus";
import { useAlertModalControl, useBloodPressureControl } from "./hooks";
import styles from "./styles.module.css";

export function BloodPressure() {
  const intl = useIntl();

  const {
    isLoading,
    currentDate,
    device,
    isPairSuccess,
    formData,
    fieldRefs,
    isSubmitDisabled,
    isOpenDevice,
    handleValueChange,
    handleConnecting,
    handleFetchingDataFromDevice,
    handleSave,
    handleCancel,
    checkDevicePairing,
    handleCancelDeviceModal,
  } = useBloodPressureControl();

  const { isShowAlert, handlePostSave, closeAfterSubmit, refreshAndNavToReport } =
    useAlertModalControl();

  return (
    <IonPage>
      <AppHeader>
        <ConnectingStatus
          device={device}
          isPairSuccess={isPairSuccess}
          handleConnecting={handleConnecting}
        />
      </AppHeader>

      <AppContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <DateWithInstruction
            dateStr={currentDate}
            header2={intl.formatMessage({ id: "page.blood_pressure.title" })}
            text={intl.formatMessage({ id: "page.blood_pressure.line1" })}
          />

          <div className="page-spacer-60" />

          <BloodPressureForm
            formData={formData}
            fieldRefs={fieldRefs}
            handleValueChange={handleValueChange}
          />
        </div>
      </AppContent>

      <IonFooter className="ion-no-border">
        <div className={`root-content ${styles.submitWrapper}`}>
          {device && isPairSuccess && (
            <>
              <AppSecondaryButton full onClick={handleFetchingDataFromDevice}>
                {intl.formatMessage({ id: "page.blood_pressure.data_fetch" })}
              </AppSecondaryButton>

              <div className="page-spacer-15" />
            </>
          )}
          <AppPrimaryButton
            full
            disabled={isSubmitDisabled}
            onClick={() => handleSave(handlePostSave)}
          >
            {intl.formatMessage({ id: "page.blood_pressure.register_button" })}
          </AppPrimaryButton>

          <div className="page-spacer-15" />

          <AppTextLink hasNoArrow onClick={handleCancel}>
            {intl.formatMessage({ id: "page.blood_pressure.cancel_button" })}
          </AppTextLink>

          <AppHomeIndicatorBar />
        </div>
      </IonFooter>

      <AlertModal
        showAlert={isShowAlert}
        title={intl.formatMessage({ id: "page.blood_pressure.ok_modal.title" })}
        msg={intl.formatMessage({ id: "page.blood_pressure.ok_modal.message" })}
        nextLabel={intl.formatMessage({ id: "page.blood_pressure.ok_modal.next_label" })}
        handleClose={() => closeAfterSubmit(currentDate)}
        handleNext={() => refreshAndNavToReport(currentDate)}
      />

      <DeviceModal
        isActive={isOpenDevice}
        handleCancel={handleCancelDeviceModal}
        handleAfterPairing={checkDevicePairing}
      />

      <AppLoading isOpen={isLoading} />
    </IonPage>
  );
}
