import { useState } from "react";
import { isPlatform, useIonViewWillEnter } from "@ionic/react";
import { Storage } from "@capacitor/storage";
import { LocalStorageKey } from "src/web/constants";
import { cancelAllPendingAlarms } from "src/web/utils/common";
import { useAlarm } from "src/web/utils/hooks/alarm";
import { useIntl } from "react-intl-phraseapp";

export function useNotificationConfigControl() {
  const intl = useIntl();
  const { refreshAlarm } = useAlarm();
  const [medicationNotificationChecked, setMedicationNotificationChecked] = useState(false);
  const [medicationNotificationSoundChecked, setMedicationNotificationSoundChecked] =
    useState(false);
  const [symptomRecordNotificationChecked, setSymptomRecordNotificationChecked] = useState(false);
  const [symptomRecordNotificationSoundChecked, setSymptomRecordNotificationSoundChecked] =
    useState(false);

  const dynimicMenus = () => {
    let medicationMenu = {
      title: intl.formatMessage({ id: "page.notification_config.medication_menu.title" }),
      subItems: [
        {
          title: intl.formatMessage({
            id: "page.notification_config.medication_menu.notification.title",
          }),
          value: medicationNotificationChecked,
          setValue: async (val: boolean) => {
            setMedicationNotificationChecked(val);
            await Storage.set({
              key: LocalStorageKey.GLOBAL_MEDICINE_NOTIFICATION_ENABLED,
              value: `${val}`,
            });
            if (val) {
              await refreshAlarm(true);
            } else {
              await cancelAllPendingAlarms();
            }
          },
        },
      ],
    };
    let symptomRecordMenu = {
      title: intl.formatMessage({ id: "page.notification_config.symptom_record_menu.title" }),
      subItems: [
        {
          title: intl.formatMessage({
            id: "page.notification_config.symptom_record_menu.notification.title",
          }),
          value: symptomRecordNotificationChecked,
          setValue: async (val: boolean) => {
            setSymptomRecordNotificationChecked(val);
            await Storage.set({
              key: LocalStorageKey.GLOBAL_SYMPTOM_NOTIFICATION_ENABLED,
              value: `${val}`,
            });
          },
        },
      ],
    };
    if (!isPlatform("ios")) {
      medicationMenu.subItems.push({
        title: intl.formatMessage({ id: "page.notification_config.medication_menu.sound.title" }),
        value: medicationNotificationSoundChecked,
        setValue: async (val: boolean) => {
          setMedicationNotificationSoundChecked(val);
          await Storage.set({
            key: LocalStorageKey.GLOBAL_MEDICINE_NOTIFICATION_SOUND_ENABLED,
            value: `${val}`,
          });
          // refresh alarms to update sound
          await refreshAlarm(true);
        },
      });
      symptomRecordMenu.subItems.push({
        title: intl.formatMessage({ id: "page.notification_config.syptom_record.sound.title" }),
        value: symptomRecordNotificationSoundChecked,
        setValue: async (val: boolean) => {
          setSymptomRecordNotificationSoundChecked(val);
          await Storage.set({
            key: LocalStorageKey.GLOBAL_SYMPTOM_NOTIFICATION_SOUND_ENABLED,
            value: `${val}`,
          });
        },
      });
    }
    return [medicationMenu, symptomRecordMenu];
  };

  const readCheckStatus = async () => {
    const globalMedicineNotificationEnabled = await (
      await Storage.get({ key: LocalStorageKey.GLOBAL_MEDICINE_NOTIFICATION_ENABLED })
    ).value;
    const globalSymptomNotificationEnabled = await (
      await Storage.get({ key: LocalStorageKey.GLOBAL_SYMPTOM_NOTIFICATION_ENABLED })
    ).value;

    // お薬記録 - 服薬通知
    setMedicationNotificationChecked(globalMedicineNotificationEnabled === "true");

    // 症状記録 - 症状記録通知
    setSymptomRecordNotificationChecked(globalSymptomNotificationEnabled === "true");

    if (!isPlatform("ios")) {
      const globalMedicineNotificationSoundEnabled = await (
        await Storage.get({ key: LocalStorageKey.GLOBAL_MEDICINE_NOTIFICATION_SOUND_ENABLED })
      ).value;
      const globalSymptomNotificationSoundEnabled = await (
        await Storage.get({ key: LocalStorageKey.GLOBAL_SYMPTOM_NOTIFICATION_SOUND_ENABLED })
      ).value;

      // お薬記録 - 通知サウンド
      setMedicationNotificationSoundChecked(globalMedicineNotificationSoundEnabled === "true");

      // 症状記録 - 通知サウンド
      setSymptomRecordNotificationSoundChecked(globalSymptomNotificationSoundEnabled === "true");
    }
  };

  useIonViewWillEnter(() => {
    readCheckStatus();
  });

  return {
    dynimicMenus,
  };
}
