import {
  getMonthlyIngestionAdvice,
  getWeeklyIngestionAdvice,
} from "src/web/services/api/personalized_advice/symptom";
import {
  getDateStrByDate,
  getStartEndDatesOfHalfYear,
  getStartEndDatesOfWeek,
  getToday,
} from "src/web/utils/common";

export function useAdviceModel() {
  const fetchWeeklyIngestionAdvice = async (selectedDateStr: string) => {
    let today = new Date(getToday());
    let targetDate = today;
    const selectedDate = new Date(selectedDateStr);
    const [startDateOfThisWeek, endDateOfThisWeek] = getStartEndDatesOfWeek(today);

    if (selectedDate < startDateOfThisWeek || endDateOfThisWeek < selectedDate) {
      // return today if the `selected Date` is in current week
      // return end day of week of `currentDate` if the `currentDate` is not in current week
      const endDate = getStartEndDatesOfWeek(selectedDate)[1];
      targetDate = endDate;
    }

    try {
      const res = await getWeeklyIngestionAdvice({
        snapshot_at: getDateStrByDate(targetDate),
      });
      return res.result;
    } catch (error) {
      return "";
    }
  };

  const fetchHalfYearIngestionAdvice = async (selectedDateStr: string) => {
    let today = new Date(getToday());
    let targetDate = today;
    const selectedDate = new Date(selectedDateStr);
    const [startDateOfThisHalfYear, endDateOfThisHalfYear] = getStartEndDatesOfHalfYear(today);

    if (selectedDate < startDateOfThisHalfYear || endDateOfThisHalfYear < selectedDate) {
      // return today if the `currentMonthStartDate` is in current half year
      // return end day of half year of `currentMonthStartDate` if the `currentMonthStartDate` is not in current half year
      const endDate = getStartEndDatesOfHalfYear(selectedDate)[1];
      targetDate = endDate;
    }

    try {
      const res = await getMonthlyIngestionAdvice({
        snapshot_at: getDateStrByDate(targetDate),
      });
      return res.result;
    } catch (error) {
      return "";
    }
  };

  return {
    fetchWeeklyIngestionAdvice,
    fetchHalfYearIngestionAdvice,
  };
}
