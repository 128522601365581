import { IonPage, IonContent, IonFooter } from "@ionic/react";
import {
  AppHeader,
  AppHomeIndicatorBar,
  AppLoading,
  AppPrimaryButton,
  AppTextLink,
} from "../../components/UiComponents";
import { EmailForm } from "src/web/components/UiComponents/organisms";
import { useCreateUserEmailControl } from "./hooks/useCreateUserEmailControl";
import { useIntl } from "react-intl-phraseapp";

export default function CreateUserEmail() {
  const { isLoading, email, errorMsg, isNextBtnDisabled, handleEmailInputChange, goNext, goLogin } =
    useCreateUserEmailControl();
  const intl = useIntl();

  return (
    <IonPage>
      <AppHeader />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <EmailForm
            title={`${intl.formatMessage({
              id: "page.create_user_email.message.line1",
            })}\n${intl.formatMessage({ id: "page.create_user_email.message.line2" })}`}
            userName={email}
            handleUserNameInputChange={handleEmailInputChange}
            error={!!errorMsg}
            errorMsg={errorMsg}
          />

          <div className="page-spacer-60" />

          <AppPrimaryButton full disabled={isNextBtnDisabled} onClick={goNext}>
            {intl.formatMessage({ id: "page.create_user_email.button.submit" })}
          </AppPrimaryButton>
        </div>
      </IonContent>

      <IonFooter className="ion-no-border">
        <AppTextLink onClick={goLogin}>
          {intl.formatMessage({ id: "page.create_user_email.login" })}
        </AppTextLink>
        <AppHomeIndicatorBar />
      </IonFooter>

      <AppLoading isOpen={isLoading} />
    </IonPage>
  );
}
