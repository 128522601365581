import { registerPlugin } from '@capacitor/core';

export interface ErrorType {
  message: string;
  code: string;
}

export interface DataType {
  id: number;
  timeSinceSampleStart: number;
  mcV: number;
}

export interface DeviceInfoType {
  deviceId: string;
  deviceName: string;
}

export interface TemperatureType {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  temperature: number;
  unit: string;
}

export interface StepType {
  start: string;
  end: string;
  steps: number;
}

export interface BloodPressureType {
  unit: string;
  systolic: number;
  diastolic: number;
  meanArterialPressure: number;
  pulseRate: number;
  userId: number;
  bodyMovementDetection: string;
  cuffFitDetection: string;
  irregularPulseDetection: string;
  measurementPositionDetection: string;
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
}

export interface SleepType {
  start: string;
  end: string;
  time: string;
  status: string;
}

export interface ElectroCardiogramType {
  start: string;
  end: string;
  measurements: [{ id: number, elapsedTime: string, mcV: string }]
}

export interface HealthCareManagerPlugin {
  initialize(options: { config: string }): Promise<{ result: string }>;

  connect(options: { deviceId: string }): Promise<{ result: string | ErrorType }>;

  disconnect(options: { deviceId: string }): Promise<{ result: string }>;

  getHealthData(options: { deviceId: string }): Promise<{ result: string | DataType[] }>;

  getDeviceInfo(options:{ deviceIds: string[] }): Promise<{datas: DeviceInfoType[], errors: ErrorType[]}>;

  getDeviceStatus(options:{ deviceIds: string[] }): Promise<{datas:[{deviceId: string, status: number}]}>;

  getBloodPressure(options:{ deviceId: string, from: string, to: string }): Promise<{datas: BloodPressureType[], errors: ErrorType[]}>;

  getTemperature(options:{ deviceId: string }): Promise<{ datas: TemperatureType[] }>;

  getSteps(options: {deviceId: string, type: string, from: string, to: string }) : Promise<{datas: StepType[]}>;
 
  getTimeOfSleeping(options: {deviceId: string, from: string, to: string }) : Promise<{datas: SleepType[]}>;

  getElectroCardiogram(options: {deviceId: string, from: string, to: string }) : Promise<{datas: ElectroCardiogramType[]}>;

  requestPermission(options: { deviceId: string }): Promise<{ result: string }>;

  checkDevicePairing(options: { deviceId: string }): Promise<{ result: string }>;
}

const HealthCareManager = registerPlugin<HealthCareManagerPlugin>('HealthCareManager');

// const callInitialize = async () => {
//   const result = await HealthCareManager.initialize({config: ""});
//   console.log(`initialize: ${result}`);  
// }

// callInitialize();

export default HealthCareManager;
