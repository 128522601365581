import { actionConst } from "../constants";

interface Actions {
  type: string;
  hook: string;
}

export default function timelineScrollHookReducer(state: string = "", actions: Actions) {
  switch (actions.type) {
    case actionConst.UPDATE_TIMELINE_SCROLL_HOOK:
      return actions.hook;
    case actionConst.CLEAR_TIMELINE_SCROLL_HOOK:
      return "";
    default:
      return state;
  }
}
