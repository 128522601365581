import { IonCard, IonCardContent, IonCardHeader, IonGrid, IonRow, IonCol } from "@ionic/react";
import { useRef, useEffect } from "react";
import { LEVEL_POSITION_MAP, NO_RANK, RADAR_MAP } from "../../constants";
import "./RecordItem.css";
import { useIntl } from "react-intl-phraseapp";

interface ContainerProps {
  contentData: {
    check_date: string;
    summary_score: { [name: string]: any };
  };
  handleClick?: () => void;
  handleInput?: (key: string, date: string) => void;
}

const ECharts = global.echarts;

const AVG_COLOR = "--graph-avg-color";
const CURRENT_COLOR = "--graph-current-color";
const BACKGROUND_COLOR = "--graph-background-color";
const TEXT_COLOR = "--text-color";
const SYMBOL_COLOR = "--graph-symbol-color";

function getColor(key: string) {
  let root_style = document.querySelector(":root");
  let rs = getComputedStyle(root_style!);
  return rs.getPropertyValue(key).trim();
}

export const RecordItem: React.FC<ContainerProps> = ({ contentData, handleClick, handleInput }) => {
  // let ctl_check_date = '';
  const intl = useIntl();
  const NO_RANK = intl.formatMessage({ id: "components.record_item.score.value.no_record" });

  const convertScore = (score: { [name: string]: any }) => {
    if (!("score" in score)) {
      return <>-</>;
    }

    return <>{score["score"]}</>;
  };

  const item_record_radar_chart_cust: React.MutableRefObject<any> = useRef();

  let levelRank = [NO_RANK, NO_RANK, NO_RANK, NO_RANK]; // [d症状, cバイタル, a活動, b心・体]
  let key_cnt = 0;
  for (const key in LEVEL_POSITION_MAP) {
    if (key in contentData.summary_score) {
      let index = LEVEL_POSITION_MAP[key];
      let rank = contentData.summary_score[key];
      if (rank) {
        levelRank[index!] = rank;
        key_cnt++;
      }
    }
  }

  // 判定の長さ違い
  if (key_cnt !== levelRank.length) {
    contentData.summary_score["score"] = "-";
  }

  useEffect(() => {
    let option = {
      // color: ["#FFFFFF", "#D38D8D"],
      // textStyle: {
      //   fontWeight: 500,
      //   fontSize: 13,
      // },
      radar: [
        {
          indicator: [{ max: 100 }, { max: 100 }, { max: 100 }, { max: 100 }],

          center: ["50%", "50%"],
          startAngle: 90,
          splitNumber: 4,
          // shape: "",
          // axisName: {
          //   formatter: "{value}",
          //   color: "#428BD4",
          // },
          splitArea: {
            show: true,
            areaStyle: {
              color: getColor(BACKGROUND_COLOR),
            },
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          radius: ["0%", "100%"],
        },
      ],
      series: [
        {
          type: "radar",
          emphasis: {
            lineStyle: {
              width: 4,
            },
          },
          symbol: "none",
          chartWrap: "charts",
          data: [],
        },
      ],
    };
    let local_echart =
      ECharts.getInstanceByDom(item_record_radar_chart_cust.current) ||
      ECharts.init(item_record_radar_chart_cust.current);

    // local_echart.geto
    local_echart && local_echart.setOption(option);

    return () => {
      try {
        ECharts.dispose(item_record_radar_chart_cust.current);
      } catch {}
    };
  }, []);

  useEffect(() => {
    // return;
    // if (ctl_check_date !== contentData.check_date) {
    //   ctl_check_date = contentData.check_date;
    // } else {
    //   return;
    // }

    let loc_current = [0, 0, 0, 0];
    // let loc_current_rank = ["", "", "", ""];
    for (let key in contentData.summary_score) {
      if (RADAR_MAP[key] >= 0 && RADAR_MAP[key] <= 4) {
        loc_current[RADAR_MAP[key]] = contentData.summary_score[key];
        // loc_current_rank[RADAR_MAP[key]] = contentData.summary_score[`${key}_r`];
      }
    }

    // let option = {
    //   // color: ["#FFFFFF", "#D38D8D"],
    //   // textStyle: {
    //   //   fontWeight: 500,
    //   //   fontSize: 13,
    //   // },
    //   radar: [
    //     {
    //       indicator: [{ max: 100 }, { max: 100 }, { max: 100 }, { max: 100 }],

    //       center: ["50%", "50%"],
    //       startAngle: 90,
    //       splitNumber: 4,
    //       // shape: "",
    //       // axisName: {
    //       //   formatter: "{value}",
    //       //   color: "#428BD4",
    //       // },
    //       splitArea: {
    //         show: true,
    //         areaStyle: {
    //           color: getColor(BACKGROUND_COLOR),
    //         },
    //       },
    //       axisLine: {
    //         show: false,
    //       },
    //       splitLine: {
    //         show: false,
    //       },
    //       radius: ["0%", "100%"],
    //     },
    //   ],
    //   series: [
    //     {
    //       type: "radar",
    //       emphasis: {
    //         lineStyle: {
    //           width: 4,
    //         },
    //       },
    //       symbol: "none",
    //       chartWrap: "charts",
    //       data: [
    //         {
    //           value: loc_current,
    //           name: "Avg",
    //           areaStyle: {
    //             color: getColor(CURRENT_COLOR) + "B2",
    //           },
    //           lineColor: getColor(CURRENT_COLOR),
    //         },
    //       ],
    //     },
    //   ],
    // };
    let local_echart =
      ECharts.getInstanceByDom(item_record_radar_chart_cust.current) ||
      ECharts.init(item_record_radar_chart_cust.current);

    if (local_echart) {
      let option = local_echart.getOption() as any;
      option.series[0].data = [];
      local_echart.setOption(option);

      setTimeout(() => {
        option.series[0].data = [
          {
            value: loc_current,
            name: "Avg",
            areaStyle: {
              color: getColor(CURRENT_COLOR) + "B2",
            },
            lineColor: getColor(CURRENT_COLOR),
          },
        ];
        local_echart.setOption(option);
      }, 0);
    }
    // 設定しない
    // local_echart.resize();
  }, [contentData.check_date, contentData.summary_score]);

  return (
    <IonCard
      className="recordItem_iconCard"
      onClick={(event: any) => {
        if (!event.target.attributes["data-link"]) {
          handleClick && handleClick();
        }
      }}
    >
      <IonCardHeader
        style={
          "score" in contentData.summary_score
            ? { padding: "11px 15px 11px" }
            : { padding: "15px 15px 16px" }
        }
      >
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className="month-start">
                <div>{contentData.check_date}</div>
              </div>
            </IonCol>
            <IonCol>
              <div className="score-end">
                <div>SCORE</div>
                <div className="page-width-spacer-10" />
                {/* 表示の文字スタイルを共通情報利用する */}
                <div className="p-score-panel item_group score_text">
                  {convertScore(contentData.summary_score)}
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardHeader>
      <IonCardContent style={{ padding: "0px 15px 15px" }}>
        <IonGrid>
          <IonRow>
            <IonCol size="4">
              <div className="record_item_chart-parent" id="charts">
                <div
                  ref={item_record_radar_chart_cust}
                  className="record_item_chart"
                  style={{ margin: 0, padding: 0, height: "88px", width: "88px" }}
                ></div>
              </div>
            </IonCol>
            <IonCol size="8">
              <div className="score_rank_column">
                <IonGrid>
                  <IonRow className="score_rank_title">
                    <IonCol>
                      {intl.formatMessage({ id: "components.record_item.score.symptom" })}
                    </IonCol>
                    <IonCol size="4">
                      {intl.formatMessage({ id: "components.record_item.score.vital" })}
                    </IonCol>
                    <IonCol>
                      {intl.formatMessage({ id: "components.record_item.score.activity" })}
                    </IonCol>
                    <IonCol>
                      {intl.formatMessage({ id: "components.record_item.score.heart_body" })}
                    </IonCol>
                  </IonRow>
                  <div className="page-spacer-8"></div>
                  <IonRow className="score_rank">
                    <IonCol>{levelRank[0]}</IonCol>
                    <IonCol size="4">{levelRank[1]}</IonCol>
                    <IonCol>{levelRank[2]}</IonCol>
                    <IonCol>{levelRank[3]}</IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};
