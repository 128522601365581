import { IonPage } from "@ionic/react";
import {
  AppContent,
  AppHomeIndicatorBar,
  AppLoading,
  AppLogo,
} from "../../components/UiComponents";
import { MenuItemGroup } from "src/web/components/UiComponents/organisms";
import { useMenuTabControl } from "./hooks/useMenuTabControl";
import { useIntl } from "react-intl-phraseapp";

export function MenuTab() {
  const { isLoading, menuList } = useMenuTabControl();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppLogo />

        <AppContent>
          <div className="root-content">
            {menuList.map((item, index) => (
              <MenuItemGroup
                pageTitle={
                  index === 0 ? intl.formatMessage({ id: "page.menu_tab.title" }) : undefined
                }
                menuData={item}
                key={index}
              />
            ))}

            <AppHomeIndicatorBar />
          </div>
        </AppContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
