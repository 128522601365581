import { actionConst } from "../constants";

import { AlarmType } from "../types";

export const updateAlarmSchedule = (alarm: AlarmType[]) => {
  return {
    type: actionConst.UPDATE_ALARM_SCHEDULE,
    state: alarm,
  };
};
