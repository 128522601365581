import { useSelector } from "react-redux";
import { IonFooter, IonPage } from "@ionic/react";
import {
  AppHeader,
  AppSecondaryButton,
  AppLoading,
  AppContent,
  AppHomeIndicatorBar,
} from "../../components/UiComponents";
import { RootState } from "../../reducer";
import { useIntl } from "react-intl-phraseapp";

import { useSymptomControl } from "./hooks/useSymptomControl";
import { LoadingSymptonQuestionAndAnswerType } from "src/web/types";
import { Question } from "src/web/components/Question";
import AlertModal from "src/web/components/AlertModal/alertModal";
import { useQuestionSwitchControl } from "./hooks/useQuestionSwitchControl";
import { QuestionPagination, QuestionTextArea } from "src/web/components/UiComponents/organisms";

export const SymptomQuestionnaire = () => {
  const { symptomQuestions }: { symptomQuestions: LoadingSymptonQuestionAndAnswerType } =
    useSelector((state: RootState) => state);
  const intl = useIntl();

  const {
    isSavingLoading,
    currentDate,
    currentQuestionIndex,
    checkedAnswers,
    questionList,
    isShowAlert,
    setCheckedAnswers,
    gotoQuestionByIndex,
    goPrevious,
    goNext,
    handleBack,
    handleSave,
    updateAndGoNextQuestion,
    handleConfirm,
    handleClose,
    handleNavReport,
  } = useSymptomControl();

  const { isPreviousDisabled, isNextDisabled, dynamicNextLabel } = useQuestionSwitchControl(
    currentQuestionIndex,
    checkedAnswers,
  );

  return (
    <>
      <IonPage>
        <AppHeader backAction={handleBack}>
          {!isNextDisabled && (
            <AppSecondaryButton isDynamicWidth transparent={true} onClick={() => handleSave(false)}>
              {intl.formatMessage({ id: "page.questionaire.button.save_temporally" })}
            </AppSecondaryButton>
          )}
        </AppHeader>

        <AppContent>
          <div className="page-spacer-45" />

          {!!questionList && currentQuestionIndex < questionList.length ? (
            <Question
              question={questionList[currentQuestionIndex]}
              checkedAnswers={checkedAnswers}
              setCheckedAnswers={setCheckedAnswers}
              goNextQuestion={updateAndGoNextQuestion}
              dateStr={currentDate}
              handleSkip={updateAndGoNextQuestion}
            />
          ) : (
            <div className="root-content">
              <QuestionTextArea
                dateStr={currentDate}
                checkedAnswers={checkedAnswers}
                setCheckedAnswers={setCheckedAnswers}
              />
            </div>
          )}

          <div className="page-spacer-45" />
        </AppContent>

        <IonFooter className="ion-no-border">
          <div className="page-spacer-15" />

          <QuestionPagination
            questionList={questionList}
            currentQuestionIndex={currentQuestionIndex}
            isPreviousDisabled={isPreviousDisabled}
            isNextDisabled={isNextDisabled}
            dynamicLabel={dynamicNextLabel}
            handleConfirm={handleConfirm}
            goPrevious={goPrevious}
            goNext={goNext}
            gotoQuestionByIndex={gotoQuestionByIndex}
          />

          <AppHomeIndicatorBar />
        </IonFooter>
      </IonPage>

      <AlertModal
        showAlert={isShowAlert}
        title={intl.formatMessage({ id: "page.symptom_summary.alert.complete.title" })}
        msg={intl.formatMessage({ id: "page.symptom_summary.alert.complete.message" })}
        nextLabel={intl.formatMessage({ id: "page.symptom_summary.alert.complete.next_label" })}
        handleClose={handleClose}
        handleNext={handleNavReport}
      />

      <AppLoading isOpen={isSavingLoading || symptomQuestions.isLoading} />
    </>
  );
};
