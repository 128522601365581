export type ChartRadarDataType = {
  current_date: string;

  avg: any[];
  avgRank: any[];
  avgScore: number;

  current: any[];
  currentRank: any[];
  currentScore: number | string;
};

export type ChartRadarConfigType = {
  splitNumber: 3 | 4 | 5,
  indicator: { name: string, max: number, color: string }[],

};

export const START_ANGLE = [0, 0, 0, 270, 90, 270];
export const SYMBOL_POSITION = [[], [], [],
// 三角の位置
[[-3.5, -15],
[-15, 1.5],
[5, 2.5]],
// 四角の位置
[[-3.5, 5],
[5, -5],
[-3.5, -15],
[-15, -5]],
// 五角の位置
[[-3.5, -15],
[-10, -7.5],
[-10, -0.3],
[0, 0],
[5, -5.5],
],

];

