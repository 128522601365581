import { useState, useMemo } from "react";
import { useIonViewWillEnter } from "@ionic/react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { useDispatch } from "react-redux";
import { auth } from "src/web/firebase";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { validateEmail } from "src/web/utils/common";
import { updateAlertInfo, updateNewUserEmailAddress } from "src/web/actions";
import { AlertType } from "src/web/types";
import { useIntl } from "react-intl-phraseapp";
import { useUserModel } from "src/web/hooks/models";

export function useChangeEmailControl(PageStepEnum: any) {
  const iRouter = useAppRouter();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { handlechangeEmailVerification } = useUserModel();

  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState<string>();
  const [newEmail, setNewEmail] = useState<string>();
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  // 画面状態を設定する
  const [pageStep, setPageStep] = useState(PageStepEnum.PasswordCheck);

  const handleBack = () => {
    // パスワードチェックの場合であれば、前の画面に移動する
    if (pageStep === PageStepEnum.PasswordCheck) {
      iRouter.goBack();
    } else {
      // パスワード設定画面を初期化する
      setPassword("");
      setPageStep(PageStepEnum.PasswordCheck);
    }
  };

  const doSubmitNewEmail = async () => {
    if (!auth?.currentUser?.email || !newEmail || !isValidEmail) {
      return;
    }

    try {
      setIsLoading(true);
      // send verification mail to the new email
      await handlechangeEmailVerification(auth?.currentUser?.email, newEmail);

      // cache the email
      dispatch(updateNewUserEmailAddress(newEmail));

      iRouter.push("/settings/accounts/change-email/confirmation");
    } catch (error: any) {
      dispatch(
        updateAlertInfo({
          status: AlertType.ERROR,
          msg: intl.formatMessage({ id: "page.change_email.submit.error.general.message" }),
        }),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const doCheckPassword = async () => {
    if (!auth?.currentUser?.email || !password) {
      return;
    }

    setErrorMsg("");

    const credential = EmailAuthProvider.credential(auth.currentUser.email, password);

    try {
      setIsLoading(true);
      // verify old password
      await reauthenticateWithCredential(auth.currentUser, credential);
      // メールのアドレスを初期化する
      setNewEmail("");
      setPageStep(PageStepEnum.ChangeMailAddress);
    } catch (error: any) {
      if (error?.code === "auth/wrong-password") {
        setErrorMsg(
          intl.formatMessage({ id: "page.change_email.submit.error.wrong_password.message" }),
        );
      } else {
        dispatch(
          updateAlertInfo({
            status: AlertType.ERROR,
            msg: intl.formatMessage(
              { id: "page.change_email.submit.error.general.message" },
              { error_code: error?.code },
            ),
          }),
        );
      }
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordChange = (value: string) => {
    if (errorMsg) {
      setErrorMsg("");
    }
    setPassword(value);
  };

  const handleEmailChange = (value: string) => {
    setIsValidEmail(validateEmail(value));
    setNewEmail(value);
  };

  const isCheckPasswordDisabled = useMemo(() => {
    return !password || !!errorMsg;
  }, [password, errorMsg]);

  const isNewEmailSavingDisabled = useMemo(() => {
    return !newEmail || !isValidEmail || newEmail === auth?.currentUser?.email;
  }, [newEmail, isValidEmail]);

  useIonViewWillEnter(() => {
    setPageStep(PageStepEnum.PasswordCheck);
    setPassword("");
  });

  return {
    pageStep,
    isLoading,
    password,
    errorMsg,
    newEmail,
    isCheckPasswordDisabled,
    isNewEmailSavingDisabled,
    handleBack,
    handlePasswordChange,
    handleEmailChange,
    doCheckPassword,
    doSubmitNewEmail,
  };
}
