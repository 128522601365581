import { IonLabel } from "@ionic/react";
import { MenuType } from "src/web/types";
import { LineItemWithIcon } from "../../moleclues";

interface MenuItemGroupProps {
  pageTitle?: string;
  menuData: MenuType;
  isGray?: boolean;
}

export function MenuItemGroup({ pageTitle, menuData, isGray }: MenuItemGroupProps) {
  return (
    <>
      {pageTitle ? (
        <>
          <div className="page-spacer-45" />
          <IonLabel className="p-header1">{pageTitle}</IonLabel>
        </>
      ) : (
        <div className="page-spacer-15" />
      )}

      <div className="page-spacer-50" />

      <IonLabel className="p-header3">{menuData.title}</IonLabel>

      <div className="page-spacer-15" />

      <hr />

      {menuData.subTitles.map((subItem, index) => (
        <LineItemWithIcon item={subItem} isGray={isGray} key={index} />
      ))}
    </>
  );
}
