import { IonContent, IonPage } from "@ionic/react";
import { AppLogo, AppPrimaryButton } from "../../components/UiComponents";
import { PageTitleCenter } from "src/web/components/UiComponents/moleclues";
import { useAccountsWithdrawCompleteControl } from "./hooks/useAccountsWithdrawCompleteControl";
import { useIntl } from "react-intl-phraseapp";

export function AccountsWithdrawComplete() {
  const { handleBackToLogin } = useAccountsWithdrawCompleteControl();
  const intl = useIntl();

  return (
    <IonPage>
      <AppLogo />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <PageTitleCenter
            title={intl.formatMessage({ id: "page.delete_user_success.message.line1" })}
            subTitle={intl.formatMessage({ id: "page.delete_user_success.message.line2" })}
          />

          <div className="page-spacer-60" />

          <AppPrimaryButton full onClick={handleBackToLogin}>
            {intl.formatMessage({ id: "page.delete_user_success.button.back_to_login" })}
          </AppPrimaryButton>
        </div>
      </IonContent>
    </IonPage>
  );
}
