import { useEffect } from "react";
import { IonModal, createAnimation, IonContent, IonLabel } from "@ionic/react";
import { useSelector } from "react-redux";
import { DEFAULT_SHEET_MODAL_SAFE_TOP } from "../../../constants";
import { useIntl } from "react-intl-phraseapp";
import { RootState } from "src/web/reducer";
import styles from "./appActionSheet.module.css";

interface AppFixedActionSheetProps {
  isOpen: boolean;
  title?: string;
  className?: string;
  sheetHeight?: number;
  handleClose: () => void;
  handleConfirm?: () => void;
  confirmationLabel?: string;
  isConfirmDisabled?: boolean;
  scrollable?: boolean;
  children?: any;
}

export function AppFixedActionSheet({
  isOpen,
  title,
  className,
  sheetHeight,
  handleClose,
  handleConfirm,
  confirmationLabel,
  isConfirmDisabled,
  scrollable,
  children,
}: AppFixedActionSheetProps) {
  const intl = useIntl();

  const capacityKeyboardHeight = useSelector((state: RootState) => state.capacityKeyboardHeight);

  // get ionic safe top from css variable
  const ionSafeTop =
    getComputedStyle(document.documentElement).getPropertyValue("--ion-safe-area-top") ||
    `${DEFAULT_SHEET_MODAL_SAFE_TOP}px`;

  const defaultBreakpoint = 1;

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation("backdropAnimation")
      .addElement(root?.querySelector("ion-backdrop")!)
      .keyframes([
        { offset: 0, opacity: "0" },
        { offset: 1, opacity: `calc(var(--ion-backdrop-opacity) * ${defaultBreakpoint})` },
      ])
      .duration(300);

    const wrapperAnimation = createAnimation("wrapperAnimation")
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: 1, transform: "translateY(100%)" },
        { offset: 1, opacity: 1, transform: `translateY(${100 - defaultBreakpoint * 100}%)` },
      ])
      .easing("cubic-bezier(0.01, 0.42, 0.35, 1)")
      .delay(300);

    return createAnimation()
      .addElement(baseEl)
      .duration(600)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  useEffect(() => {
    // handle cancel modal: no action is required for native back
    const handleNativeBack = (ev: any) => {
      ev.detail.register(3, (processNextHandler: any) => {
        if (isOpen) {
          handleClose && handleClose();
        } else {
          processNextHandler();
        }
      });
    };

    if (isOpen) {
      document.addEventListener("ionBackButton", handleNativeBack);
    } else {
      document.removeEventListener("ionBackButton", handleNativeBack);
    }

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, [isOpen]);

  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      className={`${styles.root} ${className ? className : ""}`}
      initialBreakpoint={defaultBreakpoint}
      breakpoints={[0, defaultBreakpoint]}
      onIonModalDidDismiss={handleClose}
      enterAnimation={enterAnimation}
      handle={false}
      style={{
        "--ion-safe-area-top": sheetHeight
          ? `${window.innerHeight + capacityKeyboardHeight - sheetHeight}px`
          : ionSafeTop,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <IonContent className={styles.contentWrapper}>
        <div className={`${styles.content} ${styles.fixedContent}`}>
          {title && (
            <>
              <div className={styles.header}>
                <IonLabel className={styles.back} onClick={handleClose}>
                  {intl.formatMessage({ id: "ui_components.app_fixed_action_sheet.cancel" })}
                </IonLabel>
                <IonLabel className={styles.title}>{title}</IonLabel>
                {handleConfirm && (
                  <IonLabel
                    className={`${styles.cancel} ${isConfirmDisabled ? styles.labelDisabled : ""}`}
                    onClick={handleConfirm}
                  >
                    {confirmationLabel ??
                      intl.formatMessage({ id: "ui_components.app_fixed_action_sheet.confirm" })}
                  </IonLabel>
                )}
              </div>

              {!scrollable && <div className="page-spacer-45" />}
            </>
          )}

          {children}
        </div>
      </IonContent>
    </IonModal>
  );
}
