import {
  getDataFromApiOrLocal,
  postDataForSave,
  putDataForSave,
  deleteData,
} from "../request/apiManager";
import { CheckMedicineReqType } from "../../pages/MedicationRecordTab/components/MedicationManagement/types";
import { DEFAULT_DISEASE_ID } from "src/web/constants";
import { MedicineInfoType } from "src/web/types";
import { PrescriptionInfoType } from "src/web/pages/PrescriptionInformation/type";

// fetch data for medication-record -> 服薬管理 page
export function getCurrentDateMedicine(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(
    `/api/v2/diseases/${pathDiseaseId}/medicines/current_date_medicine`,
    params,
  );
}

export function getMedicines(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/medicines`, params);
}

export function getMedicinesById(
  id: string,
  params: { [key: string]: string },
  diseaseId?: string,
) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/medicines/${id}`, params);
}

export function insertOrUpdateMedicine(data: MedicineInfoType, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return postDataForSave(`/api/v2/diseases/${pathDiseaseId}/medicines`, data);
}

export function saveCheckMedicineInfo(data: CheckMedicineReqType, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return postDataForSave(`/api/v2/diseases/${pathDiseaseId}/medication_record_details/check`, data);
}

export function deleteMedicineById(id: string, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return deleteData(`/api/v2/diseases/${pathDiseaseId}/medicines/${id}`);
}

// get calendar data by medicine on medicine-information-details -> 記録 tab
export function getCheckMedicineMonth(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(
    `/api/v2/diseases/${pathDiseaseId}/medication_record_details`,
    params,
  );
}

// get calendar data for all medicines on medication-record -> 服薬管理 tab
export function getCheckMedicinesMonth(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(
    `/api/v2/diseases/${pathDiseaseId}/medication_record_details/monthly`,
    params,
  );
}

// get details data for select date on medicine-information-details -> 記録 tab
export function getCheckMedicineCurrent(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(
    `/api/v2/diseases/${pathDiseaseId}/medication_record_details/details`,
    params,
  );
}

// get the prescription by medicine
export function getRelatedPrescription(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/prescriptions/related`, params);
}

// get the daily medicine schedule
export function getAlarm(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/alarms/enabled`, params);
}

export function getMonthChart(params: { [key: string]: string }) {
  return getDataFromApiOrLocal("/api/medicine/getMonthChart", params);
}

export function getPrescriptions(params: { [key: string]: string }, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/prescriptions`, params);
}

export function getPrescriptionById(id: string, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return getDataFromApiOrLocal(`/api/v2/diseases/${pathDiseaseId}/prescriptions/${id}`);
}

export function deletePrescriptionById(id: string, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return deleteData(`/api/v2/diseases/${pathDiseaseId}/prescriptions/${id}`);
}

export function createPrescription(data: PrescriptionInfoType, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return postDataForSave(`/api/v2/diseases/${pathDiseaseId}/prescriptions`, data);
}

export function updatePrescriptionFeeById(id: number, data: any, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return putDataForSave(
    `/api/v2/diseases/${pathDiseaseId}/prescriptions/${id}/prescription_fee`,
    data,
  );
}

export function updatePrescriptionNotesById(id: number, data: any, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return putDataForSave(
    `/api/v2/diseases/${pathDiseaseId}/prescriptions/${id}/prescription_notes`,
    data,
  );
}

export function updatePrescriptionHospitalById(id: number, data: any, diseaseId?: string) {
  const pathDiseaseId = diseaseId ?? DEFAULT_DISEASE_ID;
  return putDataForSave(
    `/api/v2/diseases/${pathDiseaseId}/prescriptions/${id}/prescription_hospital_info`,
    data,
  );
}
