import { IonBadge, IonLabel } from "@ionic/react";
import { AppSecondaryButton } from "../../AppSecondaryButton/appSecondaryButton";
import styles from "./categoryHeader.module.css";

interface CategoryHeaderProps {
  rank?: string;
  isShowRank?: boolean;
  header: string;
  isReadonly?: boolean;
  btnText: string;
  btnClick: () => void;
}

export function CategoryHeader({
  rank,
  isShowRank = false,
  header,
  isReadonly = false,
  btnText,
  btnClick,
}: CategoryHeaderProps) {
  return (
    <>
      <div className="flex-row-between">
        <div className="flex-row-start">
          {isShowRank && (
            <IonBadge color="primary" className={`p-score-diagram ${styles.rank}`}>
              {rank ?? " "}
            </IonBadge>
          )}
          <IonLabel className="p-header3">{header}</IonLabel>
        </div>
        {!isReadonly && (
          <AppSecondaryButton isDynamicWidth transparent={true} onClick={btnClick}>
            {btnText}
          </AppSecondaryButton>
        )}
      </div>

      <div className="page-spacer-15" />

      <hr className="title-hr" />
    </>
  );
}
