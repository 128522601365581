import { IonModal } from "@ionic/react";

import styles from "./styles.module.css";
import { MedicineInfoType } from "src/web/types";
import { AppPrimaryButton } from "../../../../components/UiComponents";
import { Schedule } from "../../../MedicineInformationDetails/components";
import { MedicineCopyItem } from "../MedicineCopyItem/medicineCopyItem";
import { useIntl } from "react-intl-phraseapp";

export function MedicineCopyModal({
  isOpen,
  imgPath,
  medicineInfo,
  handleCopy,
  handleCancel,
}: {
  isOpen: boolean;
  imgPath?: string;
  medicineInfo?: MedicineInfoType;
  handleCopy: (medicineInfo: MedicineInfoType) => void;
  handleCancel: () => void;
}) {
  const intl = useIntl();
  return (
    <IonModal isOpen={isOpen} className={styles.alertWrapper} backdropDismiss={false}>
      <div className={styles.body}>
        <div className={`p-header4 ${styles.title}`}>
          {intl.formatMessage({
            id: "page.medicine_information.medicine_copy_modal.message1.line1",
          })}
          <br />
          {intl.formatMessage({
            id: "page.medicine_information.medicine_copy_modal.message1.line2",
          })}
        </div>
        {/* 分割 */}
        <div className="page-spacer-30" />
        {/* 薬表示内容 */}
        <MedicineCopyItem info={medicineInfo} imgPath={imgPath} />
        <div className="page-spacer-30" />
        <div className={`p-smallbody ${styles.subTitle}`}>
          {intl.formatMessage({
            id: "page.medicine_information.medicine_copy_modal.message2.line1",
          })}
          <br />
          {intl.formatMessage({
            id: "page.medicine_information.medicine_copy_modal.message2.line2",
          })}
        </div>
        <div className="page-spacer-30" />
        <div className={styles.medicineDetailInfo}>
          <Schedule data={medicineInfo} isCopyList={true} />
        </div>
        <div className="page-spacer-30" />
        <AppPrimaryButton
          full
          onClick={() => {
            handleCopy(medicineInfo!);
          }}
        >
          {intl.formatMessage({ id: "page.medicine_information.medicine_copy_modal.button.copy" })}
        </AppPrimaryButton>
        <div className="page-spacer-15" />
        <div className={`p-header4 ${styles.navDetailBtn}`} onClick={handleCancel}>
          {intl.formatMessage({
            id: "page.medicine_information.medicine_copy_modal.button.not_copy",
          })}
        </div>
      </div>
    </IonModal>
  );
}
