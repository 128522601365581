import { AppPasswordInput } from "../..";
import { PageTitleCenter } from "../../moleclues";
import { useIntl } from "react-intl-phraseapp";

interface PasswordFormProps {
  title: string;
  subTitle?: string;
  imgSrc?: string;
  firstPass?: string;
  secondPass?: string;
  error?: boolean;
  errorMsg?: string;
  handleFirstPassInputChange?: (val: string) => void;
  handleSecondPassInputChange?: (val: string) => void;
}

export function PasswordForm({
  title,
  subTitle,
  imgSrc,
  firstPass,
  secondPass,
  error,
  errorMsg,
  handleFirstPassInputChange,
  handleSecondPassInputChange,
}: PasswordFormProps) {
  const intl = useIntl();
  return (
    <>
      <PageTitleCenter title={title} subTitle={subTitle} imgSrc={imgSrc} />

      {!!handleFirstPassInputChange && (
        <>
          <div className="page-spacer-45" />

          <AppPasswordInput
            value={firstPass}
            label={intl.formatMessage({ id: "page.create_user_set_password.label.password" })}
            onIonChange={(e: any) => {
              handleFirstPassInputChange(e.detail.value);
            }}
            error={error}
            errorMsg={!handleSecondPassInputChange ? errorMsg : ""}
          />
        </>
      )}

      {!!handleSecondPassInputChange && (
        <>
          <div className="page-spacer-45" />

          <AppPasswordInput
            label={intl.formatMessage({
              id: "page.create_user_set_password.label.password.confirmation",
            })}
            value={secondPass}
            onIonChange={(e: any) => {
              handleSecondPassInputChange(e.detail.value);
            }}
            error={error}
            errorMsg={errorMsg}
          />
        </>
      )}
    </>
  );
}
