import { useMemo } from "react";
import { USER_BASIC_INFO_TYPE } from "src/web/constants";

export function useUserBaseInfoFormControl({
  labels,
  type,
  title,
}: {
  labels: any;
  type?: string;
  title?: string;
}) {
  const headTitle = useMemo(() => {
    if (title) {
      return title;
    } else if (type) {
      return labels[type];
    } else {
      return "";
    }
  }, [title, type]);

  const FIELDS_SORTBY = useMemo(
    () =>
      type
        ? [type]
        : [
            USER_BASIC_INFO_TYPE.NAME,
            USER_BASIC_INFO_TYPE.BIRTHDAY,
            USER_BASIC_INFO_TYPE.GENDER,
            USER_BASIC_INFO_TYPE.PREFECTURE,
          ],
    [type],
  );

  return {
    headTitle,
    FIELDS_SORTBY,
  };
}
