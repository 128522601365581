import { Fragment, useState } from "react";
import { useIonViewWillEnter } from "@ionic/react";
import {
  USER_BASIC_INFO_TYPE,
} from "src/web/constants";
import { PageTitle } from "../../moleclues";
import { AppInput } from "../../AppInput/appInput";
import { AppDateSelectInput } from "../../AppInput/appDateSelectInput";
import { AppSelectInput } from "../../AppInput/appSelectInput";
import { SelectOption } from "src/web/types";
import { useUserBaseInfoFormControl } from "./hooks/useUserBaseInfoFormControl";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";
import { useCommon } from "src/web/utils/hooks/common";

interface UserBaseInfoFormProps {
  title?: string;
  subTitle?: string;
  fieldsConfig: any;
  type?: string;
}

export function UserBaseInfoForm({ title, subTitle, fieldsConfig, type }: UserBaseInfoFormProps) {
  const [yearOptions, setYearOptions] = useState<SelectOption[]>([]);
  const intl = useIntl();
  const {
    ALL_DATE_OPTIONS,
    ALL_MONTH_OPTIONS,
    GENDER_OPTIONS,
    PREFECTURE_OPTIONS,
  } = useTextConstants();
  const { generateBirthdayYearOptions } = useCommon();

  const PAGE_LABEL = {
    [USER_BASIC_INFO_TYPE.NAME]: intl.formatMessage({
      id: "page.create_user_base_info.label.name",
    }),
    [USER_BASIC_INFO_TYPE.BIRTHDAY]: intl.formatMessage({
      id: "page.create_user_base_info.label.birthday",
    }),
    [USER_BASIC_INFO_TYPE.GENDER]: intl.formatMessage({
      id: "page.create_user_base_info.label.gender",
    }),
    [USER_BASIC_INFO_TYPE.PREFECTURE]: intl.formatMessage({
      id: "page.create_user_base_info.label.prefecture",
    }),
  };

  const { headTitle, FIELDS_SORTBY } = useUserBaseInfoFormControl({
    labels: PAGE_LABEL,
    type,
    title,
  });

  const FIELDS = {
    [USER_BASIC_INFO_TYPE.NAME]: (
      <AppInput
        ref={fieldsConfig[USER_BASIC_INFO_TYPE.NAME].ref}
        label={PAGE_LABEL[USER_BASIC_INFO_TYPE.NAME]}
        maxLength={20}
        value={fieldsConfig[USER_BASIC_INFO_TYPE.NAME].value}
        onIonChange={fieldsConfig[USER_BASIC_INFO_TYPE.NAME].onChange}
        error={fieldsConfig[USER_BASIC_INFO_TYPE.NAME].error}
      />
    ),
    [USER_BASIC_INFO_TYPE.BIRTHDAY]: (
      <AppDateSelectInput
        ref={fieldsConfig[USER_BASIC_INFO_TYPE.BIRTHDAY].ref}
        label={PAGE_LABEL[USER_BASIC_INFO_TYPE.BIRTHDAY]}
        value={fieldsConfig[USER_BASIC_INFO_TYPE.BIRTHDAY].value}
        setValue={fieldsConfig[USER_BASIC_INFO_TYPE.BIRTHDAY].onChange}
        options={yearOptions}
        options2={ALL_MONTH_OPTIONS}
        options3={ALL_DATE_OPTIONS}
        needClear={false}
        error={fieldsConfig[USER_BASIC_INFO_TYPE.BIRTHDAY].error}
      />
    ),
    [USER_BASIC_INFO_TYPE.GENDER]: (
      <AppSelectInput
        ref={fieldsConfig[USER_BASIC_INFO_TYPE.GENDER].ref}
        label={PAGE_LABEL[USER_BASIC_INFO_TYPE.GENDER]}
        value={fieldsConfig[USER_BASIC_INFO_TYPE.GENDER].value}
        options={GENDER_OPTIONS}
        setValue={fieldsConfig[USER_BASIC_INFO_TYPE.GENDER].onChange}
        needClear={false}
        size="md"
        error={fieldsConfig[USER_BASIC_INFO_TYPE.GENDER].error}
      />
    ),
    [USER_BASIC_INFO_TYPE.PREFECTURE]: (
      <AppSelectInput
        ref={fieldsConfig[USER_BASIC_INFO_TYPE.PREFECTURE].ref}
        label={PAGE_LABEL[USER_BASIC_INFO_TYPE.PREFECTURE]}
        defaultValue="13" // default to 東京都
        value={fieldsConfig[USER_BASIC_INFO_TYPE.PREFECTURE].value}
        options={PREFECTURE_OPTIONS}
        setValue={fieldsConfig[USER_BASIC_INFO_TYPE.PREFECTURE].onChange}
        needClear={false}
        error={fieldsConfig[USER_BASIC_INFO_TYPE.PREFECTURE].error}
      />
    ),
  };

  useIonViewWillEnter(() => {
    setYearOptions(generateBirthdayYearOptions());
  });

  return (
    <>
      <PageTitle title={headTitle} subTitle={subTitle} />

      {FIELDS_SORTBY.map((type) => (
        <Fragment key={type}>
          <div className="page-spacer-45" />

          {FIELDS[type]}
        </Fragment>
      ))}
    </>
  );
}
