import React from "react";
import { IonRow } from "@ionic/react";
import styles from "./styles.module.css";

interface AppPaginationProps {
  pages: any[];
  currentIndex: number;
  onClick: React.Dispatch<React.SetStateAction<number>>;
}

export function AppPagination({ pages, currentIndex, onClick }: AppPaginationProps) {
  return (
    <IonRow className={`ion-justify-content-center ion-align-items-center ${styles.root}`}>
      {pages.map((_, index) => (
        <div key={index} className={styles.dot} onClick={() => onClick(index)}>
          {currentIndex === index && <div className={styles.dotChecked} />}
        </div>
      ))}
    </IonRow>
  );
}
