import { useState, useEffect } from "react";
import { IonLabel, IonInput, isPlatform } from "@ionic/react";
import { AppIcon } from "../AppIcon/appIcon";
import { Keyboard } from "@capacitor/keyboard";
import { ICONS } from "../../../constants";
import styles from "./appInput.module.css";
import { SelectOption } from "../../../types";
import { AppSelectOptions } from "..";

interface AppDoubleSelectInputProp {
  label?: string;
  placeholder?: string;
  className?: string;
  value1: any;
  value2: any;
  separatedBy?: string;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  options: SelectOption[];
  options2: SelectOption[];
  error?: any;
  errorMsg?: string;
  needClear?: boolean;
  showBoarder?: boolean;
  size?: "full" | "md" | "sm" | "xs";
}

export const AppDoubleSelectInput = ({
  label,
  placeholder,
  className,
  value1,
  value2,
  separatedBy,
  setValue,
  options,
  options2,
  error,
  errorMsg,
  needClear = true, // defalut to true to add "指定しない" option
  showBoarder = false, // always show boarder
  size = "full",
  ...rest
}: AppDoubleSelectInputProp) => {
  const [isOpen, setIsOpen] = useState(false);

  const getTextByValue = (val1: string | number, val2: string | number) => {
    if (!val1 && !val2) {
      return "";
    }

    let text = "";
    if (val1) {
      const result: any = (options as SelectOption[]).find((opt: any) => {
        return (opt as SelectOption).value === val1;
      });
      if (result) {
        text = result.text;
      }
    }

    if (val2) {
      const result: any = (options2 as SelectOption[]).find((opt: any) => {
        return (opt as SelectOption).value === val2;
      });
      if (result) {
        text = text ? `${text}  ${result.text}` : result.text;
      }
    }
    return text;
  };

  useEffect(() => {
    // handle cancel modal: no action is required for native back
    const handleNativeBack = (ev: any) => {
      ev.detail.register(3, (processNextHandler: any) => {
        if (isOpen) {
          setIsOpen(false);
        } else {
          processNextHandler();
        }
      });
    };

    if (isOpen) {
      document.addEventListener("ionBackButton", handleNativeBack);
    } else {
      document.removeEventListener("ionBackButton", handleNativeBack);
    }

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, [isOpen]);

  return (
    <>
      {label && (
        <>
          <IonLabel className={styles.input_label}>{label}</IonLabel>
          <div className="page-spacer-15" />
        </>
      )}
      <div className={`${styles.inputWrapper} ${error ? styles.shake : ""}`}>
        <div className={`${styles.input_div} ${styles[size]}`} onClick={() => setIsOpen(true)}>
          <IonInput
            inputmode="none"
            placeholder={placeholder}
            className={` ${error && styles.input_error} ${
              isOpen || showBoarder ? styles.input_focus : styles.input_no_focus
            } ${styles.input} ${className}`}
            value={getTextByValue(value1, value2)}
            {...rest}
            onIonFocus={() => {
              if (isPlatform("capacitor")) {
                Keyboard.hide();
              }
            }}
          />
          <AppIcon
            key={isOpen ? 1 : 0}
            size="sm"
            icon={ICONS.ICON.ARROW_DOWN}
            className={`${styles.input_icon} ${/*isOpen ? styles.rotateIcon : */ ""}`}
          />
        </div>
        {error && (
          <>
            <IonLabel className="p-input-label-error">{errorMsg || error.message}</IonLabel>
          </>
        )}
      </div>

      <AppSelectOptions
        isOpen={isOpen}
        value1={value1}
        value2={value2}
        options={options}
        options2={options2 ?? undefined}
        onClose={() => {
          setIsOpen(false);
        }}
        setValue={(value: any) => {
          setValue(value);
        }}
      />
    </>
  );
};
