import { useState } from "react";
import { IonPage, IonLabel, IonContent, useIonViewWillEnter, ScrollDetail } from "@ionic/react";
import { useDispatch } from "react-redux";
import { AppHeader, AppLoading, AppSecondaryButton, AppTab } from "../../components/UiComponents";
import { ImgSlider, Records, Schedule, DetailsInfo } from "./components";
import { getToday } from "../../utils/common";
import { getMedicinesById } from "../../services";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { Storage } from "@capacitor/storage";
import { useQueryString } from "src/web/hooks/useQueryString";
import { useAppRouter } from "../../hooks/useAppRouter";
import { clearMedicineInfo } from "../../actions";
import { LocalStorageKey, IMAGES_FOLDER, FIREBASE_FILE_NAMES } from "../../constants";
import { MedicineInfoType } from "src/web/types";
import { useIntl } from "react-intl-phraseapp";

export function MedicineInformationDetails() {
  const ionRouter = useAppRouter();
  const intl = useIntl();

  const dispatch = useDispatch();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  const medicineId = params.id;
  const medicineIdx = params.index;

  const tabs = [
    {
      name: "RECORDS",
      text: intl.formatMessage({ id: "page.medicine_information_details.tabs.records" }),
    },
    {
      name: "SCHEDULE",
      text: intl.formatMessage({ id: "page.medicine_information_details.tabs.schedule" }),
    },
    {
      name: "DETAILS",
      text: intl.formatMessage({ id: "page.medicine_information_details.tabs.details" }),
    },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].name);

  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingRecords, setLoadingRecords] = useState(false);

  const [medicineDetails, setMedicineDetails] = useState<MedicineInfoType>();
  const [imgList, setImgList] = useState<string[]>([]);

  const doFetch = async () => {
    setLoadingMain(true);

    try {
      const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });
      const res = await getMedicinesById(`${medicineId}`, {
        currentDate: getToday(),
      });
      setMedicineDetails(res);

      // get images
      const imageRef = ref(
        storage,
        `${IMAGES_FOLDER}/${userId.value}/${res.prescription_id}/${medicineId}/${FIREBASE_FILE_NAMES.medicineFileName}`,
      );
      getDownloadURL(imageRef)
        .then((url) => {
          setImgList((prev) => [...prev, url]);
        })
        .catch(() => {
          setImgList([""]);
        });
      setLoadingMain(false);
    } catch (e) {
      setLoadingMain(false);
    }
  };

  useIonViewWillEnter(() => {
    doFetch();
    // clear medicine info in redux
    dispatch(clearMedicineInfo());
  });

  // スクロール関連
  const [isDisplayBorder, setIsDisplayBorder] = useState(false);

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    // setIsDisplayBorder(true);
    if (ev.detail.scrollTop === 0) {
      setIsDisplayBorder(false);
    } else {
      setIsDisplayBorder(true);
    }
  };

  return (
    <>
      <IonPage>
        <AppHeader isDisplayBorder={isDisplayBorder}>
          <AppSecondaryButton
            transparent={true}
            isDynamicWidth
            onClick={() => {
              setImgList([]);
              ionRouter.push(`/medicine-information?id=${medicineId}`, "forward", "push");
            }}
          >
            {intl.formatMessage({ id: "page.medicine_information_details.button.edit.label" })}
          </AppSecondaryButton>
        </AppHeader>

        <IonContent scrollEvents={true} onIonScroll={handleScroll}>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="p-header1">{medicineDetails?.m_name}</IonLabel>

            <div className="page-spacer-30" />

            <ImgSlider imageList={imgList} />

            <div className="page-spacer-30" />

            <AppTab tabs={tabs} onChange={setCurrentTab} />

            <div className="page-spacer-45" />

            {currentTab === tabs[0].name ? (
              <Records setLoading={setLoadingRecords} />
            ) : currentTab === tabs[1].name ? (
              <Schedule data={medicineDetails} />
            ) : (
              <DetailsInfo data={medicineDetails} />
            )}
          </div>
        </IonContent>
      </IonPage>

      <AppLoading isOpen={loadingMain || loadingRecords} />
    </>
  );
}
