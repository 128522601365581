import { createGesture, IonCard, IonCardContent, IonLabel } from "@ionic/react";
import {
  AppStatefulCheckbox,
  AppMedicineImage,
  STATEFUL_STATES,
  AppIcon,
} from "../../../../components/UiComponents";
import { CheckMedicineInfoCardType } from "../MedicationManagement/types";
import {
  MEDICINE_CATEGORY,
  DEFAULT_MEDICINE_GAP_TIME,
  ICONS,
} from "../../../../constants";
import { getNow, trimLeftZero } from "../../../../utils/common";
import { saveCheckMedicineInfo } from "../../../../services";
import styles from "./medicationStatusCard.module.css";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

interface MedicationStatusCardProps {
  showCheckbox?: boolean;
  data?: CheckMedicineInfoCardType;
  dataList?: CheckMedicineInfoCardType[];
  medicineCategory?: number;
  currentDate?: string;
  disabled?: boolean;
  saveUpdate?: (status: string, time: string) => void;
  handleCardClick?: (id: number) => void;
  outOpFlg?: number;
  handleEditClick?: () => void;
  showEditBox?: boolean;
  showWhite?: boolean;
}

export function MedicationStatusCard({
  showCheckbox = true,
  data,
  dataList,
  medicineCategory,
  currentDate,
  disabled = false,
  saveUpdate,
  handleCardClick,
  outOpFlg,
  handleEditClick,
  showEditBox = true,
  showWhite = true,
}: MedicationStatusCardProps) {
  const intl = useIntl();
  const {
    MEDICINE_TIMING_UNIT_OPTIONS
  } = useTextConstants();
  const getDetails = () => {
    const unitCode = data?.medicineDetails?.medication_timings?.length
      ? data?.medicineDetails?.medication_timings[data.check_index!].unit
      : undefined;
    let unit = "";
    if (unitCode) {
      unit = MEDICINE_TIMING_UNIT_OPTIONS.find((opt) => opt.value === unitCode)!.text;
    }

    switch (data?.category) {
      case MEDICINE_CATEGORY.COUNTER_MEDICINE: // 頓用薬
        return `${data.quantity}${unit} / `;

      case MEDICINE_CATEGORY.EVERYDAY_MEDICINE: // 日常薬
        return `${data.quantity}${unit}`;

      default: // その他の薬
        return <>&nbsp;</>;
    }
  };

  const getStatus = () => {
    if (data?.category === MEDICINE_CATEGORY.COUNTER_MEDICINE) {
      // 頓用薬
      if (data.check_index === 0 && !data.status) {
        return intl.formatMessage({ id: "page.medication_status_card.status.no_record" });
      } else if (data.status) {
        const check_time_arr = data.check_time ? data.check_time.split(":") : [];
        if (check_time_arr.length === 3) {
          check_time_arr.pop();
        }
        return intl.formatMessage(
          { id: "page.medication_status_card.status.recorded" },
          { time: trimLeftZero(check_time_arr.join(":")) },
        );
      } else {
        const allRecords = dataList?.filter(
          (item) => item.category === data?.category && item.id === data.id,
        );

        // if the first record is not checked, show "服薬状況未登録" for all the other records
        if (allRecords && !allRecords[0].check_time) {
          return intl.formatMessage({ id: "page.medication_status_card.status.no_record" });
        } else {
          // if any record is checked, the estimation time of the coming records will be calculated based on the last checked record
          const allCheckedRecords = allRecords?.filter((item) => !!item.status);
          const lastCheckedRecord = allCheckedRecords?.slice(-1)[0];

          const indexGap = data.check_index! - lastCheckedRecord?.check_index!;
          const gapTime = data.medicineDetails?.gap_time || DEFAULT_MEDICINE_GAP_TIME;

          const estimatedTime = `${`${
            parseInt(lastCheckedRecord!.check_time!.split(":")[0]) + indexGap * gapTime
          }`.padStart(2, "0")}:${lastCheckedRecord!.check_time!.split(":")[1]}`;

          return intl.formatMessage(
            { id: "page.medication_status_card.status.estimated" },
            { time: estimatedTime },
          );
        }
      }
    } else {
      // 日常薬 && その他の薬
      switch (data?.status) {
        case STATEFUL_STATES.checked:
          const check_time_arr = data.check_time ? data.check_time.split(":") : [];
          if (check_time_arr.length === 3) {
            check_time_arr.pop();
          }
          return intl.formatMessage(
            { id: "page.medication_status_card.status.recorded" },
            { time: trimLeftZero(check_time_arr.join(":")) },
          );
        case STATEFUL_STATES.halfChecked:
          return intl.formatMessage({ id: "page.medication_status_card.status.forgot" });
        case STATEFUL_STATES.skip:
          return intl.formatMessage({ id: "page.medication_status_card.status.skip" });
        default:
          return intl.formatMessage({ id: "page.medication_status_card.status.no_record" });
      }
    }
  };

  /* disabled the item only for 頓用薬 && has at least 2 steps after && the next step is checked */
  const chkIsDisabled = () => {
    if (data?.category === MEDICINE_CATEGORY.COUNTER_MEDICINE) {
      // 頓用薬
      const allRecords = dataList?.filter(
        (item) => item.category === data?.category && item.id === data.id,
      );
      if (allRecords) {
        if ((data.check_index || 0) + 2 > allRecords.length) {
          return false;
        } else {
          const nextItem = allRecords.find(
            (item) => (item.check_index || 0) - 1 === data.check_index,
          );
          return !!nextItem && !!nextItem.check_time;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleCheck = (e: Event, status: string) => {
    e.preventDefault();
    e.stopPropagation();

    saveCheckMedicineInfo({
      id: data!.id!,
      status,
      check_index: data!.check_index!,
      current_date: currentDate ?? "",
      current_time: getNow(),
    });
    saveUpdate && saveUpdate(status, getNow());
  };

  // 薬アイテム情報
  const medicine_item_ref: React.MutableRefObject<any> = useRef();

  useEffect(() => {
    const ANIMATION_SIZE = -54;

    const medicine_element_style = medicine_item_ref.current.style;

    if (!showEditBox) {
      return;
    }

    const moveGesture = createGesture({
      el: medicine_item_ref.current,
      threshold: 0,
      gestureName: "my-gesture",
      onStart: (ev) => {
        medicine_element_style.transform = "";
      },
      onMove: (ev) => {
        // 右に移動することはしない
        if (ev.deltaX > 0) {
          return;
        }
        // 最大左のサイズは54です。
        if (ev.deltaX < ANIMATION_SIZE) {
          medicine_element_style.transform = `translate3d(${ANIMATION_SIZE}px, 0, 0px)`;
          return;
        }
        // 移動を実施する
        medicine_element_style.transform = `translate3d(${ev.deltaX}px, 0, 0px)`;
      },
      onEnd: (ev) => {
        // まえの状態に戻ります。
        // if (ev.deltaX >= ANIMATION_SIZE) {
        //   medicine_element_style.transform = "";
        // }
      },
    });

    moveGesture.enable();
    return () => {
      moveGesture.destroy();
    };
  }, []);

  useEffect(() => {
    const medicine_element_style = medicine_item_ref.current.style;

    if (outOpFlg === -1) {
      medicine_element_style.transform = "";
      return;
    }
  }, [outOpFlg]);

  return (
    <div>
      {showEditBox && (
        <div className={styles.delete_item_div}>
          <AppIcon
            icon={ICONS.ICON.WRITE}
            size="sm"
            color="white"
            disabled={false}
            onClick={handleEditClick}
          />
        </div>
      )}
      <IonCard
        ref={medicine_item_ref}
        className={`${
          showWhite
            ? styles.medicationStatusCard_iconCard_white
            : styles.medicationStatusCard_iconCard_gray
        } ${styles.medicationStatusCard_iconCard}`}
        onClick={() => handleCardClick && handleCardClick(data?.id!)}
      >
        <IonCardContent
          className={`${
            showWhite
              ? styles.medicationStatusCard_iconCard_white
              : styles.medicationStatusCard_iconCard_gray
          } ${styles.medicationStatusCard_ionCardContent} flex-row-start white-card`}
        >
          <AppMedicineImage
            src={data?.medicineDetails?.imgUrl}
            size="MediumBig"
            grayBg={showWhite}
            hideFrame={true}
          />
          <div className={styles.textWrapper}>
            <IonLabel className={styles.headerLabel}>{data?.medicineDetails?.m_name}</IonLabel>

            <div className="page-spacer-10" />

            <IonLabel className={styles.text}>{getDetails()}</IonLabel>

            <div className="page-spacer-5" />

            <IonLabel className={styles.text}>{getStatus()}</IonLabel>
          </div>
          {showCheckbox && (
            <div>
              <AppStatefulCheckbox
                checkedState={data?.status || ""}
                onClick={(e: any) => {
                  if (medicineCategory === MEDICINE_CATEGORY.EVERYDAY_MEDICINE) {
                    if (!data?.status) {
                      handleCheck(e, STATEFUL_STATES.checked);
                    } else if (parseInt(data?.status || "0") + 1 <= MEDICINE_CATEGORY.OTHERS) {
                      handleCheck(e, `${parseInt(data?.status || "0") + 1}`);
                    } else {
                      handleCheck(e, STATEFUL_STATES.unchecked);
                    }
                  } else {
                    if (!data?.status) {
                      handleCheck(e, STATEFUL_STATES.checked);
                    } else {
                      handleCheck(e, STATEFUL_STATES.unchecked);
                    }
                  }
                }}
                disabled={disabled || chkIsDisabled()}
              />
            </div>
          )}
        </IonCardContent>
      </IonCard>
    </div>
  );
}
