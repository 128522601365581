import Markdown from "markdown-to-jsx";
import { MarkdownA } from "./components";

export function AppMarkdown(props: any) {
  const options = {
    overrides: {
      a: {
        component: MarkdownA,
      },
    },
  };

  return <>{props.children ? <Markdown options={options}>{props.children}</Markdown> : ""}</>;
}
