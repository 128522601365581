import { AppIcon } from "../AppIcon/appIcon";
import { ICONS } from "../../../constants";
import styles from "./styles.module.css";

interface LargeTile {
  className?: string;
  onClick?: (event: any) => void;
  textTitle?: string;
  textSubTitle?: string;
  textSubTitleTime?: string;
  onPlay?: (videoSrc: string) => void;
  videoSrc?: string;
  src: string;
}

export function AppLargeTile(props: LargeTile) {
  return (
    <div
      className={`${styles.divContainer} ${props.className}`}
      onClick={(event) => {
        props.onClick && props.onClick(event);
      }}
    >
      <div className="page-spacer-15" />
      <div className={styles.imageDiv}>
        <img src={props.src} alt="" className={styles.image}></img>
      </div>
      <div className="page-spacer-15" />
      <div className={styles.textDive}>
        <div className={styles.title}>{props.textTitle}</div>
        <div className="page-spacer-5" />
        <div className={styles.subTitle}>
          <div>{props.textSubTitle}</div>
          <div className="page-width-spacer-10" />
          <div>{props.textSubTitleTime}</div>
        </div>
        <div className="page-spacer-15" />
      </div>
      <div className={styles.divPlay}>
        <AppIcon
          icon={ICONS.ICON.PLAY_CIRCLE}
          size="sm"
          onClick={() => {
            props.onPlay && props.onPlay(props.videoSrc ? props.videoSrc : "");
          }}
        />
      </div>
    </div>
  );
}
