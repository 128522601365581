import { IonLabel } from "@ionic/react";
import { AppFixedActionSheet, AppLoading } from "../../../../../components/UiComponents";
import { FaceScore } from "../FaceScore/faceScore";
import { SymptomModelListType } from "../../types";
import { useCurrentSymptomActionsheetControl } from "./hooks/useCurrentSymptomActionsheetControl";
import { useIntl } from "react-intl-phraseapp";

export interface OpenCurrentSymptomType {
  isOpen: boolean;
  data?: SymptomModelListType;
  nowDate?: string;
  nowTime?: string;
}

interface CurrentSymptomActionsheetProps {
  data: OpenCurrentSymptomType;
  handleClose: () => void;
}

export function CurrentSymptomActionsheet({ data, handleClose }: CurrentSymptomActionsheetProps) {
  const intl = useIntl();
  const { isLoading, currentScore, isConfirmDisabled, setCurrentScore, handleComfirm } =
    useCurrentSymptomActionsheetControl(data, handleClose);

  return (
    <>
      <AppFixedActionSheet
        isOpen={data.isOpen}
        sheetHeight={280}
        handleClose={handleClose}
        handleConfirm={handleComfirm}
        confirmationLabel={intl.formatMessage({
          id: "page.main_tab.disease_main_tab.current_symptom_action_sheet.confirm",
        })}
        isConfirmDisabled={isConfirmDisabled}
        title={intl.formatMessage({
          id: "page.main_tab.disease_main_tab.current_symptom_action_sheet.title",
        })}
      >
        <div className="flex-row-between">
          <div>
            <IonLabel className="p-body">{data.data?.content}</IonLabel>
          </div>
          <div>
            <IonLabel className="p-smallbody">{data?.nowTime}</IonLabel>
          </div>
        </div>

        <div className="page-spacer-30" />

        <FaceScore score={currentScore} handleClick={setCurrentScore} />
      </AppFixedActionSheet>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
