import { useState } from "react";
import { useRouteMatch } from "react-router";
import { IonContent, IonLabel, IonPage, useIonViewWillEnter } from "@ionic/react";
import { AppMarkdown } from "../../../components/AppMarkdown";
import { AppHeader, AppHomeIndicatorBar, AppLoading } from "../../../components/UiComponents";
import { getOtherDocumentList } from "../../../services/api/documents";
import { DEFAULT_LANGUAGE } from "../../../constants";
import { OthersInfoType } from "src/web/types";

export function Others() {
  const match: any = useRouteMatch();
  const id: string = match.params.id;

  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState<OthersInfoType>();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res: OthersInfoType[] = await getOtherDocumentList({
        language: DEFAULT_LANGUAGE,
      });
      const currentInfo = res.find((data: OthersInfoType) => `${data.id}` === id);
      if (currentInfo) {
        setInfo(currentInfo);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useIonViewWillEnter(() => {
    fetchData();
  });

  return (
    <>
      <IonPage>
        <AppHeader />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="p-header1">{info?.title}</IonLabel>

            <div className="page-spacer-45" />

            <AppMarkdown>{info?.content}</AppMarkdown>

            <AppHomeIndicatorBar />
          </div>
        </IonContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
