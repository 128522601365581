import useCollapse from "react-collapsed";
import { AppIcon } from "../../../../components/UiComponents";
import { ICONS } from "../../../../constants";
import styles from "./collapseItem.module.css";

export const CollapseItem = ({ label, content }: any) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <>
      <div className={`flex-row-start ${styles.collapseHeader}`}>
        {label}

        {content && (
          <div className={`${styles.collapse} flex-row-end`} {...getToggleProps()}>
            <span className={`${styles.icon} ${isExpanded ? styles.collapseExpended : ""}`}>
              <AppIcon icon={ICONS.ICON.ARROW_DOWN} size="sm" />
            </span>
          </div>
        )}
      </div>

      {content && <section {...getCollapseProps()}>{content}</section>}
    </>
  );
};
