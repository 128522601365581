
export class PspHttpError extends Error {
    status: number;
 
    constructor(status: number) {
        super();
        this.status = status;
    }
}

export class PspHttpAuthError extends Error {
}

export class PspAlexaError extends Error {

    status: number;
    message: string;
 
    constructor(status: number, message: string) {
        super();
        this.status = status;
        this.message = message;
    }
}

export class PspAlexaAuthError extends Error {

    status: number;
    message: string;
 
    constructor(status: number, message: string) {
        super();
        this.status = status;
        this.message = message;
    }
}

