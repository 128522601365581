import playSvg from "../../../assets/images/play.svg";
import styles from "./styles.module.css";

import { AppTagTime } from "../AppTag/appTagTime"

interface LargeImage {
  src: string;
  videoSrc?: string;
  length: string;
  className?: string;
  onClick?: (videoSrc: string) => void;
}

export function AppLargeImage(props: LargeImage) {
  return (
    <div className={`${styles.divContainer} ${props.className ? props.className : ''}`}>
        <img src={props.src} className={styles.img} alt="" />
        <div className={styles.overlay} >
          <div style={{flex:"1 1 0px"}} />
          <div style={{flex:"1 1 0px", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <img className={styles.imgPlay} src={playSvg} alt="" onClick={() => {
              props.onClick && props.onClick(props.videoSrc ? props.videoSrc : '');
            }} />
          </div>
          <div style={{flex:"1 1 0px"}}>
            <AppTagTime textTitle={props.length} className={styles.time} />
          </div>
        </div>
    </div>
  );
}
