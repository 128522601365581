import { DeviceErrorType, ErrorForm } from "src/web/types";
import { useIntl } from "react-intl-phraseapp";

export function useTextConstants() {
    const intl = useIntl();

    const FORM_ERROR_TYPES: ErrorForm = {
        REQUIRED: {
          type: "required",
          message: intl.formatMessage({id: "common.form.validation.required"}),
        },
        DATE: {
          type: "date",
          message: intl.formatMessage({id: "common.form.validation.invalid_date"}),
        },
        // NUMBER: {
        //   type: "number",
        //   message: "入力してください",
        // }
    };

    // 服薬頻度 - 単位
    const FREQUENCY_UNIT: { [name: string]: string } = {
        "01": intl.formatMessage({id: "common.frequency_unit.per_a_day"}),
        "02": intl.formatMessage({id: "common.frequency_unit.per_a_week"}),
        "03": intl.formatMessage({id: "common.frequency_unit.per_a_month"}),
        "04": intl.formatMessage({id: "common.frequency_unit.per_a_year"}),
    };

    const MEDICINE_REPEATING_PERIOD_OPTIONS = [
        { value: "01", text: intl.formatMessage({id: "common.frequency_unit.per_a_day"}) },
        { value: "02", text: intl.formatMessage({id: "common.frequency_unit.per_a_week"}) },
        { value: "03", text: intl.formatMessage({id: "common.frequency_unit.per_a_month"}) },
        { value: "04", text: intl.formatMessage({id: "common.frequency_unit.per_a_year"}) },
    ];

    const FREQUENCY: { [name: string]: string } = {
        "01": intl.formatMessage({id: "common.frequency.everyday"}),
        "02": intl.formatMessage({id: "common.frequency.every_two_days"}),
        "03": intl.formatMessage({id: "common.frequency.every_three_days"}),
        "04": intl.formatMessage({id: "common.frequency.selected_weekday"}),
        "05": intl.formatMessage({id: "common.frequency.custom"}),
    };

    // 服薬頻度
    const MEDICINE_FREQUENCY_OPTIONS = [
        { value: "01", text: intl.formatMessage({id: "common.medicine_frequency_options.everyday"}) },
        { value: "02", text: intl.formatMessage({id: "common.medicine_frequency_options.every_two_days"}) },
        { value: "03", text: intl.formatMessage({id: "common.medicine_frequency_options.every_three_days"}) },
        { value: "04", text: intl.formatMessage({id: "common.medicine_frequency_options.selected_weekday"}) },
        { value: "05", text: intl.formatMessage({id: "common.medicine_frequency_options.custom"}) },
    ];
    
    const DATE_CONVERT: { [name: number]: string } = {
        0: intl.formatMessage({id: "common.week.date.sun"}),
        1: intl.formatMessage({id: "common.week.date.mon"}),
        2: intl.formatMessage({id: "common.week.date.tue"}),
        3: intl.formatMessage({id: "common.week.date.wed"}),
        4: intl.formatMessage({id: "common.week.date.thu"}),
        5: intl.formatMessage({id: "common.week.date.fri"}),
        6: intl.formatMessage({id: "common.week.date.sat"}),
    };
    
    const DAY_CONVERT: { [name: number]: string } = {
        0: intl.formatMessage({id: "common.week.day.sun"}),
        1: intl.formatMessage({id: "common.week.day.mon"}),
        2: intl.formatMessage({id: "common.week.day.tue"}),
        3: intl.formatMessage({id: "common.week.day.wed"}),
        4: intl.formatMessage({id: "common.week.day.thu"}),
        5: intl.formatMessage({id: "common.week.day.fri"}),
        6: intl.formatMessage({id: "common.week.day.sat"}),
    };

    const NOT_INPUTED = intl.formatMessage({id: "common.text.not_inputed"});
    const NOT_TEXT = intl.formatMessage({id: "common.text.not_text"});
    const EMPTY_OPTION_TEXT = intl.formatMessage({id: "common.text.empty_option_text"});

    const DEVICE_ERROR: DeviceErrorType = {
        "400": intl.formatMessage({id: "common.text.error.400"}),
        "404": intl.formatMessage({id: "common.text.error.404"}),
        "500": intl.formatMessage({id: "common.text.error.500"}),
        "504": intl.formatMessage({id: "common.text.error.504"}),
        "600": intl.formatMessage({id: "common.text.error.600"}),
        "601": intl.formatMessage({id: "common.text.error.601"}),
        "602": intl.formatMessage({id: "common.text.error.602"}),
        "603": intl.formatMessage({id: "common.text.error.603"}),
        "700": intl.formatMessage({id: "common.text.error.700"}),
        "701": intl.formatMessage({id: "common.text.error.701"}),
        "702": intl.formatMessage({id: "common.text.error.702"}),
        "704": intl.formatMessage({id: "common.text.error.704"}),
        "705": intl.formatMessage({id: "common.text.error.705"}),
        "706": intl.formatMessage({id: "common.text.error.706"}),
        "707": intl.formatMessage({id: "common.text.error.707"}),
        "708": intl.formatMessage({id: "common.text.error.708"}),
    };

    const MEDICINE_INFO_SHAPE_CODE_OPTIONS = [
        { value: "01", text: intl.formatMessage({id: "common.medicine_info_shape.internal_medicine"})  },
        { value: "02", text: intl.formatMessage({id: "common.medicine_info_shape.inner_drop"})         },
        { value: "03", text: intl.formatMessage({id: "common.medicine_info_shape.prn"})                },
        { value: "04", text: intl.formatMessage({id: "common.medicine_info_shape.injection"})          },
        { value: "05", text: intl.formatMessage({id: "common.medicine_info_shape.external_medicine"})  },
        { value: "06", text: intl.formatMessage({id: "common.medicine_info_shape.soaking"})            },
        { value: "07", text: intl.formatMessage({id: "common.medicine_info_shape.hot_water"})          },
        { value: "08", text: intl.formatMessage({id: "common.medicine_info_shape.ingredient"})         },
        { value: "09", text: intl.formatMessage({id: "common.medicine_info_shape.other"})              },
    ];

    const MEDICINE_QUANTITY_UNIT = [
        { value: "01", text: intl.formatMessage({id: "common.medicine_quantity_unit.day"}) },
        { value: "02", text: intl.formatMessage({id: "common.medicine_quantity_unit.prescription"}) },
        { value: "03", text: intl.formatMessage({id: "common.medicine_quantity_unit.count"}) },
    ];

    const MEDICINE_INFO_RULES_OF_USE_OPTION = [
        { value: "01", text: intl.formatMessage({id: "common.medicine_info_rule_options.01"}) },
        { value: "02", text: intl.formatMessage({id: "common.medicine_info_rule_options.02"}) },
        { value: "03", text: intl.formatMessage({id: "common.medicine_info_rule_options.03"}) },
        { value: "04", text: intl.formatMessage({id: "common.medicine_info_rule_options.04"}) },
        { value: "05", text: intl.formatMessage({id: "common.medicine_info_rule_options.05"}) },
        { value: "06", text: intl.formatMessage({id: "common.medicine_info_rule_options.06"}) },
        { value: "07", text: intl.formatMessage({id: "common.medicine_info_rule_options.07"}) },
        { value: "08", text: intl.formatMessage({id: "common.medicine_info_rule_options.08"}) },
        { value: "09", text: intl.formatMessage({id: "common.medicine_info_rule_options.09"}) },
        { value: "10", text: intl.formatMessage({id: "common.medicine_info_rule_options.10"}) },
        { value: "11", text: intl.formatMessage({id: "common.medicine_info_rule_options.11"}) },
        { value: "12", text: intl.formatMessage({id: "common.medicine_info_rule_options.12"}) },
        { value: "13", text: intl.formatMessage({id: "common.medicine_info_rule_options.13"}) },
        { value: "14", text: intl.formatMessage({id: "common.medicine_info_rule_options.14"}) },
        { value: "15", text: intl.formatMessage({id: "common.medicine_info_rule_options.15"}) },
        { value: "16", text: intl.formatMessage({id: "common.medicine_info_rule_options.16"}) },
        { value: "17", text: intl.formatMessage({id: "common.medicine_info_rule_options.17"}) },
        { value: "18", text: intl.formatMessage({id: "common.medicine_info_rule_options.18"}) },
        { value: "19", text: intl.formatMessage({id: "common.medicine_info_rule_options.19"}) },
        { value: "20", text: intl.formatMessage({id: "common.medicine_info_rule_options.20"}) },
        { value: "21", text: intl.formatMessage({id: "common.medicine_info_rule_options.21"}) },
        { value: "22", text: intl.formatMessage({id: "common.medicine_info_rule_options.22"}) },
        { value: "23", text: intl.formatMessage({id: "common.medicine_info_rule_options.23"}) },
        { value: "24", text: intl.formatMessage({id: "common.medicine_info_rule_options.24"}) },
        { value: "25", text: intl.formatMessage({id: "common.medicine_info_rule_options.25"}) },
        { value: "26", text: intl.formatMessage({id: "common.medicine_info_rule_options.26"}) },
        { value: "27", text: intl.formatMessage({id: "common.medicine_info_rule_options.27"}) },
        { value: "28", text: intl.formatMessage({id: "common.medicine_info_rule_options.28"}) },
        { value: "29", text: intl.formatMessage({id: "common.medicine_info_rule_options.29"}) },
        { value: "30", text: intl.formatMessage({id: "common.medicine_info_rule_options.30"}) },
        { value: "31", text: intl.formatMessage({id: "common.medicine_info_rule_options.31"}) },
        { value: "32", text: intl.formatMessage({id: "common.medicine_info_rule_options.32"}) },
        { value: "33", text: intl.formatMessage({id: "common.medicine_info_rule_options.33"}) },
        { value: "34", text: intl.formatMessage({id: "common.medicine_info_rule_options.34"}) },
        { value: "35", text: intl.formatMessage({id: "common.medicine_info_rule_options.35"}) },
        { value: "36", text: intl.formatMessage({id: "common.medicine_info_rule_options.36"}) },
        { value: "37", text: intl.formatMessage({id: "common.medicine_info_rule_options.37"}) },
        { value: "38", text: intl.formatMessage({id: "common.medicine_info_rule_options.38"}) },
        { value: "39", text: intl.formatMessage({id: "common.medicine_info_rule_options.39"}) },
        { value: "40", text: intl.formatMessage({id: "common.medicine_info_rule_options.40"}) },
        { value: "41", text: intl.formatMessage({id: "common.medicine_info_rule_options.41"}) },
        { value: "42", text: intl.formatMessage({id: "common.medicine_info_rule_options.42"}) },
        { value: "43", text: intl.formatMessage({id: "common.medicine_info_rule_options.43"}) },
        { value: "44", text: intl.formatMessage({id: "common.medicine_info_rule_options.44"}) },
        { value: "45", text: intl.formatMessage({id: "common.medicine_info_rule_options.45"}) },
        { value: "46", text: intl.formatMessage({id: "common.medicine_info_rule_options.46"}) },
        { value: "47", text: intl.formatMessage({id: "common.medicine_info_rule_options.47"}) },
        { value: "48", text: intl.formatMessage({id: "common.medicine_info_rule_options.48"}) },
        { value: "49", text: intl.formatMessage({id: "common.medicine_info_rule_options.49"}) },
        { value: "50", text: intl.formatMessage({id: "common.medicine_info_rule_options.50"}) },
        { value: "51", text: intl.formatMessage({id: "common.medicine_info_rule_options.51"}) },
        { value: "52", text: intl.formatMessage({id: "common.medicine_info_rule_options.52"}) },
        { value: "53", text: intl.formatMessage({id: "common.medicine_info_rule_options.53"}) },
        { value: "54", text: intl.formatMessage({id: "common.medicine_info_rule_options.54"}) },
        { value: "55", text: intl.formatMessage({id: "common.medicine_info_rule_options.55"}) },
        { value: "56", text: intl.formatMessage({id: "common.medicine_info_rule_options.56"}) },
        { value: "57", text: intl.formatMessage({id: "common.medicine_info_rule_options.57"}) },
    ];

    const MEDICINE_TIMING_OPTIONS = [
        { value: "01", text: intl.formatMessage({id: "common.medicine_timing_options.01"}) },
        { value: "02", text: intl.formatMessage({id: "common.medicine_timing_options.02"}) },
        { value: "03", text: intl.formatMessage({id: "common.medicine_timing_options.03"}) },
        { value: "04", text: intl.formatMessage({id: "common.medicine_timing_options.04"}) },
        { value: "05", text: intl.formatMessage({id: "common.medicine_timing_options.05"}) },
        { value: "06", text: intl.formatMessage({id: "common.medicine_timing_options.06"}) },
        { value: "07", text: intl.formatMessage({id: "common.medicine_timing_options.07"}) },
        { value: "08", text: intl.formatMessage({id: "common.medicine_timing_options.08"}) },
        { value: "09", text: intl.formatMessage({id: "common.medicine_timing_options.09"}) },
        { value: "10", text: intl.formatMessage({id: "common.medicine_timing_options.10"}) },
        { value: "11", text: intl.formatMessage({id: "common.medicine_timing_options.11"}) },
        { value: "12", text: intl.formatMessage({id: "common.medicine_timing_options.12"}) },
        { value: "13", text: intl.formatMessage({id: "common.medicine_timing_options.13"}) },
    ];

    const MEDICINE_TIMING_UNIT_OPTIONS = [
        { value: "01", text: intl.formatMessage({id: "common.medicine_timing_unit_options.01"}) },
        { value: "02", text: intl.formatMessage({id: "common.medicine_timing_unit_options.02"}) },
        { value: "03", text: intl.formatMessage({id: "common.medicine_timing_unit_options.03"}) },
        { value: "04", text: intl.formatMessage({id: "common.medicine_timing_unit_options.04"}) },
        { value: "05", text: intl.formatMessage({id: "common.medicine_timing_unit_options.05"}) },
        { value: "06", text: intl.formatMessage({id: "common.medicine_timing_unit_options.06"}) },
        { value: "07", text: intl.formatMessage({id: "common.medicine_timing_unit_options.07"}) },
        { value: "08", text: intl.formatMessage({id: "common.medicine_timing_unit_options.08"}) },
        { value: "09", text: intl.formatMessage({id: "common.medicine_timing_unit_options.09"}) },
        { value: "10", text: intl.formatMessage({id: "common.medicine_timing_unit_options.10"}) },
        { value: "11", text: intl.formatMessage({id: "common.medicine_timing_unit_options.11"}) },
        { value: "12", text: intl.formatMessage({id: "common.medicine_timing_unit_options.12"}) },
        { value: "13", text: intl.formatMessage({id: "common.medicine_timing_unit_options.13"}) },
        { value: "14", text: intl.formatMessage({id: "common.medicine_timing_unit_options.14"}) },
        { value: "15", text: intl.formatMessage({id: "common.medicine_timing_unit_options.15"}) },
    ];

    const NO_RANK = intl.formatMessage({id: "common.text.no_rank"});

    const PREFECTURE_OPTIONS = [
        { value: "1", text: intl.formatMessage({id: "common.prefecture_options.1"}) },
        { value: "2", text: intl.formatMessage({id: "common.prefecture_options.2"}) },
        { value: "3", text: intl.formatMessage({id: "common.prefecture_options.3"}) },
        { value: "4", text: intl.formatMessage({id: "common.prefecture_options.4"}) },
        { value: "5", text: intl.formatMessage({id: "common.prefecture_options.5"}) },
        { value: "6", text: intl.formatMessage({id: "common.prefecture_options.6"}) },
        { value: "7", text: intl.formatMessage({id: "common.prefecture_options.7"}) },
        { value: "8", text: intl.formatMessage({id: "common.prefecture_options.8"}) },
        { value: "9", text: intl.formatMessage({id: "common.prefecture_options.9"}) },
        { value: "10", text: intl.formatMessage({id: "common.prefecture_options.10"}) },
        { value: "11", text: intl.formatMessage({id: "common.prefecture_options.11"}) },
        { value: "12", text: intl.formatMessage({id: "common.prefecture_options.12"}) },
        { value: "13", text: intl.formatMessage({id: "common.prefecture_options.13"}) },
        { value: "14", text: intl.formatMessage({id: "common.prefecture_options.14"}) },
        { value: "15", text: intl.formatMessage({id: "common.prefecture_options.15"}) },
        { value: "16", text: intl.formatMessage({id: "common.prefecture_options.16"}) },
        { value: "17", text: intl.formatMessage({id: "common.prefecture_options.17"}) },
        { value: "18", text: intl.formatMessage({id: "common.prefecture_options.18"}) },
        { value: "19", text: intl.formatMessage({id: "common.prefecture_options.19"}) },
        { value: "20", text: intl.formatMessage({id: "common.prefecture_options.20"}) },
        { value: "21", text: intl.formatMessage({id: "common.prefecture_options.21"}) },
        { value: "22", text: intl.formatMessage({id: "common.prefecture_options.22"}) },
        { value: "23", text: intl.formatMessage({id: "common.prefecture_options.23"}) },
        { value: "24", text: intl.formatMessage({id: "common.prefecture_options.24"}) },
        { value: "25", text: intl.formatMessage({id: "common.prefecture_options.25"}) },
        { value: "26", text: intl.formatMessage({id: "common.prefecture_options.26"}) },
        { value: "27", text: intl.formatMessage({id: "common.prefecture_options.27"}) },
        { value: "28", text: intl.formatMessage({id: "common.prefecture_options.28"}) },
        { value: "29", text: intl.formatMessage({id: "common.prefecture_options.29"}) },
        { value: "30", text: intl.formatMessage({id: "common.prefecture_options.30"}) },
        { value: "31", text: intl.formatMessage({id: "common.prefecture_options.31"}) },
        { value: "32", text: intl.formatMessage({id: "common.prefecture_options.32"}) },
        { value: "33", text: intl.formatMessage({id: "common.prefecture_options.33"}) },
        { value: "34", text: intl.formatMessage({id: "common.prefecture_options.34"}) },
        { value: "35", text: intl.formatMessage({id: "common.prefecture_options.35"}) },
        { value: "36", text: intl.formatMessage({id: "common.prefecture_options.36"}) },
        { value: "37", text: intl.formatMessage({id: "common.prefecture_options.37"}) },
        { value: "38", text: intl.formatMessage({id: "common.prefecture_options.38"}) },
        { value: "39", text: intl.formatMessage({id: "common.prefecture_options.39"}) },
        { value: "40", text: intl.formatMessage({id: "common.prefecture_options.40"}) },
        { value: "41", text: intl.formatMessage({id: "common.prefecture_options.41"}) },
        { value: "42", text: intl.formatMessage({id: "common.prefecture_options.42"}) },
        { value: "43", text: intl.formatMessage({id: "common.prefecture_options.43"}) },
        { value: "44", text: intl.formatMessage({id: "common.prefecture_options.44"}) },
        { value: "45", text: intl.formatMessage({id: "common.prefecture_options.45"}) },
        { value: "46", text: intl.formatMessage({id: "common.prefecture_options.46"}) },
        { value: "47", text: intl.formatMessage({id: "common.prefecture_options.47"}) },
    ];

    const GENDER_OPTIONS = [
        { value: "1", text: intl.formatMessage({id: "common.gender_options.1"}) },
        { value: "2", text: intl.formatMessage({id: "common.gender_options.2"}) },
        { value: "3", text: intl.formatMessage({id: "common.gender_options.3"}) },
        { value: "4", text: intl.formatMessage({id: "common.gender_options.4"}) },
    ];

    const ALL_MONTH_OPTIONS = [
        { value: "01", text: intl.formatMessage({id: "common.all_month_options.01"}) },
        { value: "02", text: intl.formatMessage({id: "common.all_month_options.02"}) },
        { value: "03", text: intl.formatMessage({id: "common.all_month_options.03"}) },
        { value: "04", text: intl.formatMessage({id: "common.all_month_options.04"}) },
        { value: "05", text: intl.formatMessage({id: "common.all_month_options.05"}) },
        { value: "06", text: intl.formatMessage({id: "common.all_month_options.06"}) },
        { value: "07", text: intl.formatMessage({id: "common.all_month_options.07"}) },
        { value: "08", text: intl.formatMessage({id: "common.all_month_options.08"}) },
        { value: "09", text: intl.formatMessage({id: "common.all_month_options.09"}) },
        { value: "10", text: intl.formatMessage({id: "common.all_month_options.10"}) },
        { value: "11", text: intl.formatMessage({id: "common.all_month_options.11"}) },
        { value: "12", text: intl.formatMessage({id: "common.all_month_options.12"}) },
    ];

    const ALL_DATE_OPTIONS = [
        { value: "01", text: intl.formatMessage({id: "common.all_date_options.01"}) },
        { value: "02", text: intl.formatMessage({id: "common.all_date_options.02"}) },
        { value: "03", text: intl.formatMessage({id: "common.all_date_options.03"}) },
        { value: "04", text: intl.formatMessage({id: "common.all_date_options.04"}) },
        { value: "05", text: intl.formatMessage({id: "common.all_date_options.05"}) },
        { value: "06", text: intl.formatMessage({id: "common.all_date_options.06"}) },
        { value: "07", text: intl.formatMessage({id: "common.all_date_options.07"}) },
        { value: "08", text: intl.formatMessage({id: "common.all_date_options.08"}) },
        { value: "09", text: intl.formatMessage({id: "common.all_date_options.09"}) },
        { value: "10", text: intl.formatMessage({id: "common.all_date_options.10"}) },
        { value: "11", text: intl.formatMessage({id: "common.all_date_options.11"}) },
        { value: "12", text: intl.formatMessage({id: "common.all_date_options.12"}) },
        { value: "13", text: intl.formatMessage({id: "common.all_date_options.13"}) },
        { value: "14", text: intl.formatMessage({id: "common.all_date_options.14"}) },
        { value: "15", text: intl.formatMessage({id: "common.all_date_options.15"}) },
        { value: "16", text: intl.formatMessage({id: "common.all_date_options.16"}) },
        { value: "17", text: intl.formatMessage({id: "common.all_date_options.17"}) },
        { value: "18", text: intl.formatMessage({id: "common.all_date_options.18"}) },
        { value: "19", text: intl.formatMessage({id: "common.all_date_options.19"}) },
        { value: "20", text: intl.formatMessage({id: "common.all_date_options.20"}) },
        { value: "21", text: intl.formatMessage({id: "common.all_date_options.21"}) },
        { value: "22", text: intl.formatMessage({id: "common.all_date_options.22"}) },
        { value: "23", text: intl.formatMessage({id: "common.all_date_options.23"}) },
        { value: "24", text: intl.formatMessage({id: "common.all_date_options.24"}) },
        { value: "25", text: intl.formatMessage({id: "common.all_date_options.25"}) },
        { value: "26", text: intl.formatMessage({id: "common.all_date_options.26"}) },
        { value: "27", text: intl.formatMessage({id: "common.all_date_options.27"}) },
        { value: "28", text: intl.formatMessage({id: "common.all_date_options.28"}) },
        { value: "29", text: intl.formatMessage({id: "common.all_date_options.29"}) },
        { value: "30", text: intl.formatMessage({id: "common.all_date_options.30"}) },
        { value: "31", text: intl.formatMessage({id: "common.all_date_options.31"}) },
    ];

    const SCORE_TAB_KEY = [
        "score_tab",
        "medicine_tab",
        "body_temperature_tab",
        "blood_pressure_tab",
        "activity_tab",
    ];

    const WEEK_MONTH_CHART_TABS = [
        { name: SCORE_TAB_KEY[0], text: intl.formatMessage({id: "common.chart_tabs.total"}) },
        { name: SCORE_TAB_KEY[1], text: intl.formatMessage({id: "common.chart_tabs.medicine"}) },
        { name: SCORE_TAB_KEY[2], text: intl.formatMessage({id: "common.chart_tabs.temperature"}) },
        { name: SCORE_TAB_KEY[3], text: intl.formatMessage({id: "common.chart_tabs.blood_pressure"}) },
        { name: SCORE_TAB_KEY[4], text: intl.formatMessage({id: "common.chart_tabs.activity"}) },
    ];

    return {
        FORM_ERROR_TYPES,
        FREQUENCY_UNIT,
        MEDICINE_REPEATING_PERIOD_OPTIONS,
        FREQUENCY,
        MEDICINE_FREQUENCY_OPTIONS,
        DATE_CONVERT,
        DAY_CONVERT,
        NOT_INPUTED,
        NOT_TEXT,
        EMPTY_OPTION_TEXT,
        DEVICE_ERROR,
        MEDICINE_INFO_SHAPE_CODE_OPTIONS,
        MEDICINE_QUANTITY_UNIT,
        MEDICINE_INFO_RULES_OF_USE_OPTION,
        MEDICINE_TIMING_OPTIONS,
        MEDICINE_TIMING_UNIT_OPTIONS,
        NO_RANK,
        PREFECTURE_OPTIONS,
        GENDER_OPTIONS,
        ALL_MONTH_OPTIONS,
        ALL_DATE_OPTIONS,
        SCORE_TAB_KEY,
        WEEK_MONTH_CHART_TABS
    }
}