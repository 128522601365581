import AlertModal from "../../components/AlertModal/alertModal";
import { DEVICE_CATEGORY } from "../../constants";
import {
  DeviceSelection,
  DeviceSelected,
  DeviceReadyToConnect,
  DeviceConnecting,
} from "./components";
import { useDeviceModalControl } from "./hooks/useDeviceModalControl";
import { useIntl } from "react-intl-phraseapp";

export function DeviceModal({
  isActive,
  fetchingDataAfterPairingFlg = true,
  deviceCategory,
  showNextBtnAfterError = true,
  handleCancel,
  handleAfterPairing,
}: {
  isActive: boolean;
  fetchingDataAfterPairingFlg?: boolean;
  deviceCategory?: string;
  showNextBtnAfterError?: boolean;
  handleCancel: () => void;
  handleAfterPairing?: () => void;
}) {
  const intl = useIntl();
  const {
    isOpenDeviceSelection,
    isOpenDeviceSelected,
    isOpenDeviceReadyToConnect,
    isOpenDeviceConnecting,
    tmpDevice,
    showError,
    showSuccessAlert,
    handleSelectionNext,
    requestDevicePermission,
    handleReadyToConnectNext,
    handleSuccessClose,
    handleSuccessNext,
    handleSuccessCancel,
    handleErrorClose,
    handleErrorNext,
    handleErrorCancel,
  } = useDeviceModalControl(isActive, handleCancel, deviceCategory, handleAfterPairing);

  return (
    <>
      <DeviceSelection
        isOpen={isOpenDeviceSelection}
        deviceCategory={deviceCategory || DEVICE_CATEGORY.BLOOD_PRESSURE}
        handleCancel={handleCancel}
        handleNext={handleSelectionNext}
      />

      <DeviceSelected
        isOpen={isOpenDeviceSelected}
        deviceCategory={deviceCategory || DEVICE_CATEGORY.BLOOD_PRESSURE}
        device={tmpDevice}
        handleCancel={handleCancel}
        handleNext={requestDevicePermission}
      />

      <DeviceReadyToConnect
        isOpen={isOpenDeviceReadyToConnect}
        deviceCategory={deviceCategory || DEVICE_CATEGORY.BLOOD_PRESSURE}
        handleCancel={handleCancel}
        handleNext={handleReadyToConnectNext}
      />

      <DeviceConnecting
        isOpen={isOpenDeviceConnecting}
        deviceCategory={deviceCategory || DEVICE_CATEGORY.BLOOD_PRESSURE}
        handleCancel={handleCancel}
      />

      {/* success alert */}
      {fetchingDataAfterPairingFlg ? (
        <AlertModal
          showAlert={showSuccessAlert}
          title={intl.formatMessage({ id: "components.device_modal.success_modal.title" })}
          closeLabel={intl.formatMessage({
            id: "components.device_modal.success_modal.close_label",
          })}
          nextLabel={intl.formatMessage({ id: "components.device_modal.success_modal.next_label" })}
          handleClose={handleSuccessClose}
          handleNext={handleSuccessNext}
          handleCancel={handleSuccessCancel}
        />
      ) : (
        <AlertModal
          showAlert={showSuccessAlert}
          title={intl.formatMessage({ id: "components.device_modal.success_modal.title" })}
          handleCancel={handleSuccessCancel}
        />
      )}

      {/* error alert */}
      {showNextBtnAfterError ? (
        <AlertModal
          isError={true}
          showAlert={showError.flg}
          title={intl.formatMessage({ id: "components.device_modal.error_modal.title" })}
          msg={showError.message}
          closeLabel={intl.formatMessage({ id: "components.device_modal.error_modal.close_label" })}
          nextLabel={intl.formatMessage({ id: "components.device_modal.error_modal.next_label" })}
          handleClose={handleErrorClose}
          handleNext={handleErrorNext}
        />
      ) : (
        <AlertModal
          isError={true}
          showAlert={showError.flg}
          title={intl.formatMessage({ id: "components.device_modal.error_modal.title" })}
          msg={showError.message}
          closeLabel={intl.formatMessage({ id: "components.device_modal.error_modal.close_label" })}
          handleClose={handleErrorClose}
          handleCancel={handleErrorCancel}
        />
      )}
    </>
  );
}
