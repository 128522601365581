import { useMemo } from "react";
import { CalendarDayType } from "src/web/types";

interface useAppCalendarControlType {
  dateMatrix?: CalendarDayType[][];
  disabledBeforeDate?: string;
  disabledAfterDate?: string;
}

export function useAppCalendarControl({
  dateMatrix,
  disabledBeforeDate,
  disabledAfterDate,
}: useAppCalendarControlType) {
  const isLeftArrowDisabled = useMemo(() => {
    if (!disabledBeforeDate || !dateMatrix) {
      return false;
    }

    const firstDateArr: CalendarDayType[] | undefined = dateMatrix.find((item) => item.length > 0);
    if (!firstDateArr) {
      return false;
    }

    let firstDateOfCurrentMonth: CalendarDayType | undefined = firstDateArr.find(
      (item) => item.date !== 0,
    );
    if (!firstDateOfCurrentMonth) {
      return false;
    }

    return disabledBeforeDate >= firstDateOfCurrentMonth.fullDate;
  }, [dateMatrix, disabledBeforeDate]);

  const isRightArrowDisabled = useMemo(() => {
    if (!disabledAfterDate || !dateMatrix) {
      return false;
    }

    const lastDateArr: CalendarDayType[] = dateMatrix[dateMatrix.length - 1];
    let lastDateOfCurrentMonth: CalendarDayType = lastDateArr[0];
    for (let i = 1; i < lastDateArr.length; i++) {
      if (lastDateArr[i].date !== 0) {
        lastDateOfCurrentMonth = lastDateArr[i];
      } else {
        break;
      }
    }
    if (!lastDateOfCurrentMonth) {
      return false;
    }

    return disabledAfterDate <= lastDateOfCurrentMonth.fullDate;
  }, [dateMatrix, disabledAfterDate]);

  return {
    isLeftArrowDisabled,
    isRightArrowDisabled,
  };
}
