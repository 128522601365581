import { actionConst } from "../constants";
import { LoadingTimelineType } from "src/web/types";

export const clearDailyActivityTimeline = () => {
  return {
    type: actionConst.CLEAR_DAILY_ACTIVITY_TIMELINE,
  };
};

export const updateDailyActivityTimeline = (state: LoadingTimelineType) => {
  return {
    type: actionConst.UPDATE_DAILY_ACTIVITY_TIMELINE,
    state,
  };
};
