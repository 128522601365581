import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/web/reducer";
import { useQueryString } from "src/web/hooks/useQueryString";
import { useAppRouter } from "../../../../../../hooks/useAppRouter";
import { TimelineType } from "src/web/types";
import { useCommon } from "../../../../../../utils/hooks/common";

export function useTimelineControl(
  data: TimelineType,
  doScrollIntoView: () => void,
  isReadonly?: boolean,
  currentDate?: string,
) {
  const ionRouter = useAppRouter();
  const { TIMELINE_CATEGORIES } = useCommon();
  const { parseQs } = useQueryString();

  const timelineScrollHook = useSelector((state: RootState) => state.timelineScrollHook);

  const handleNavToDetail = () => {
    if (isReadonly) {
      return;
    }

    switch (data.type) {
      case TIMELINE_CATEGORIES.SYMPTOM.type:
        ionRouter.push(`/symptom-entering-details?date=${currentDate}&time=${data.time}`);
        break;
      case TIMELINE_CATEGORIES.BLOOD_PRESSURE.type:
        ionRouter.push(`/reports/vitals/blood-pressure-records/edit?currentDate=${currentDate}`);
        break;
      case TIMELINE_CATEGORIES.TAKING_MEDICINE.type:
        ionRouter.push(`/home/medication-record?date=${currentDate}`);
        break;
      default:
        break;
    }
  };

  const handleAutoScroll = async () => {
    if (timelineScrollHook) {
      const params = parseQs(timelineScrollHook);
      if (params?.time === data?.time && params?.type === data?.type) {
        doScrollIntoView();
      }
    }
  };

  useEffect(() => {
    handleAutoScroll();
  }, [data]);

  return {
    handleNavToDetail: !isReadonly ? handleNavToDetail : undefined,
  };
}
