import { getColor } from "src/web/utils/common";

const CURRENT_BAR_SCORE_COLOR = "--psp-pink";

export const CurveBar: React.FC<any> = (props: any) => {
  const { fill, x, y, width, height, color, gapx } = props;

  // 零点の場合空値にする
  if (!height) {
    return <svg />;
  }

  // widthは固定14ですので、14以外の実装はしないです。
  // 全ての長さ
  let allY = y + height;
  // 最小の長さ
  let minY = allY - 8;
  // 基礎の長さ
  let basicY = y;
  // 8未満の場合8にする
  if (height < 8) {
    basicY = minY;
  }
  // xは調整しない、将来広さを調整すると、中心として、調整します。
  let basicX = x;

  let innerX = basicX + (gapx ?? 0);
  let innerY = basicY + 2;

  return (
    <svg>
      <path
        d={`M${innerX},${innerY + 5}
          C${innerX} ${innerY + 2.23857} ${innerX + 2.23858} ${innerY} ${innerX + 5} ${innerY}
          C${innerX + 7.76142} ${innerY} ${innerX + 10} ${innerY + 2.23858} ${innerX + 10} ${
          innerY + 5
        }
          V${allY - 1}
          H${innerX}
          V${innerY + 5}
          Z`}
        fill={getColor(color || CURRENT_BAR_SCORE_COLOR)}
      />
    </svg>
  );
};
