import { useRef } from "react";
import { IonModal, createAnimation, IonContent, IonLabel } from "@ionic/react";
import { useSelector } from "react-redux";
import { DEFAULT_SHEET_MODAL_SAFE_TOP } from "../../../constants";
import { SelectOption } from "../../../types";
import { AppTriblePicker } from "../AppPicker/appTriblePicker";
import styles from "./appSelectOptions.module.css";
import { useIntl } from "react-intl-phraseapp";
import { RootState } from "src/web/reducer";

interface AppTribleSelectOptionsProps {
  isOpen: boolean;
  title?: string;
  className?: string;
  value: any;
  options: SelectOption[];
  options2: SelectOption[];
  options3: SelectOption[];
  separatedBy?: string;
  onClose: () => void;
  setValue: (value: any) => void;
}

const SHEET_HEIGHT = 300;

export function AppTribleSelectOptions({
  isOpen,
  className,
  value,
  options,
  options2,
  options3,
  separatedBy = "/",
  onClose,
  setValue,
}: AppTribleSelectOptionsProps) {
  const intl = useIntl();

  const pickerRef = useRef<any>();

  const capacityKeyboardHeight = useSelector((state: RootState) => state.capacityKeyboardHeight);

  // get ionic safe top from css variable
  const ionSafeTop =
    getComputedStyle(document.documentElement).getPropertyValue("--ion-safe-area-top") ||
    `${DEFAULT_SHEET_MODAL_SAFE_TOP}px`;

  const defaultBreakpoint = 1;

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    let value = pickerRef.current.getSelectedData();
    setValue(value);
    onClose();
  };

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation("backdropAnimation")
      .addElement(root?.querySelector("ion-backdrop")!)
      .keyframes([
        { offset: 0, opacity: "0" },
        { offset: 1, opacity: `calc(var(--ion-backdrop-opacity) * ${defaultBreakpoint})` },
      ])
      .duration(300);

    const wrapperAnimation = createAnimation("wrapperAnimation")
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: 1, transform: "translateY(100%)" },
        { offset: 1, opacity: 1, transform: `translateY(${100 - defaultBreakpoint * 100}%)` },
      ])
      .easing("cubic-bezier(0.01, 0.42, 0.35, 1)")
      .delay(300);

    return createAnimation()
      .addElement(baseEl)
      .duration(600)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      className={`${styles.root} ${className ? className : ""}`}
      initialBreakpoint={defaultBreakpoint}
      breakpoints={[0, defaultBreakpoint]}
      onIonModalDidDismiss={handleClose}
      enterAnimation={enterAnimation}
      handle={false}
      style={{
        "--ion-safe-area-top": SHEET_HEIGHT
          ? `${window.innerHeight + capacityKeyboardHeight - SHEET_HEIGHT}px`
          : ionSafeTop,
      }}
    >
      <IonContent className={styles.contentWrapper}>
        <div className={`flex-row-between ${styles.header}`}>
          <IonLabel className={styles.label} onClick={handleClose}>
            {intl.formatMessage({ id: "ui_components.app_select_options.button.cancel" })}
          </IonLabel>
          <IonLabel className={styles.label} onClick={handleConfirm}>
            {intl.formatMessage({ id: "ui_components.app_select_options.button.confirm" })}
          </IonLabel>
        </div>

        <div className={styles.content}>
          <div className="page-spacer-25" />

          <AppTriblePicker
            ref={pickerRef}
            initVal={value}
            options={options}
            options2={options2}
            options3={options3}
            separatedBy={separatedBy}
          />
        </div>
      </IonContent>
    </IonModal>
  );
}
