import { actionConst } from "../constants";
import { ContactType } from "../types";

const initialState: ContactType = {};

interface Action {
  type: actionConst;
  state: ContactType;
}

export default function contactReducer(state = initialState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_CONTACT:
      return actions.state;
    case actionConst.CLEAR_CONTACT:
      return {};
    default:
      return state;
  }
}
