import { useState, useEffect, useRef } from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRow,
  IonList,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react";
import {
  AppPrimaryButton,
  AppSecondaryButton,
  AppTrackingButton,
  AppCheckbox,
  AppIcon,
  AppTextLink,
  AppConnectButton,
  AppConnectButtonStatus,
  AppRadiobox,
  AppToggleButton,
  AppToggleIconTextButton,
  AppPNButton,
  AppQuantitySelector,
  AppPageSelector,
  AppChartRadar,
  ChartRadarDataType,
  AppHeader,
  AppTab,
  AppScrollableTab,
  AppInput,
  // AppTimePicker,
  AppPagination,
  AppBadge,
  AppLargeTile,
  AppSmallTile,
  AppTagOne,
  AppTagTwo,
  AppTagTime,
  AppTagColor,
  AppTagButton,
  AppImageTile,
  AppModal,
  AppVideo,
  AppMedicineImage,
  AppPanelImage,
  AppSmallImage,
  AppLargeImage,
  AppLoading,
  AppActionSheet,
  AppQR,
  AppDoublePicker,
  AppPicker,
} from "../../components/UiComponents";
import { Camera, CameraResultType } from "@capacitor/camera";
import { useAppRouter } from "../../hooks/useAppRouter";
import largeImg from "../../assets/images/largeImg.png";
import smallImg from "../../assets/images/smallImg.png";
import backgroundImg from "../../assets/images/backgroundImg.png";
import medicineImg from "../../assets/images/medicineImg.png";
import panelImg from "../../assets/images/panelImg.png";
import smallImg2 from "../../assets/images/smallImg2.png";
import largeImg2 from "../../assets/images/largeImg2.png";

import { LocalNotifications, LocalNotificationSchema } from "@capacitor/local-notifications";

import { ICONS, MINUTE_STEP_KV_OPTIONS, HOUR_KV_OPTIONS } from "../../constants";
import styles from "./demo.module.css";

const testvideo = require("../../assets/video/test.mp4");

export function Demo() {
  const extraIconKey = [""];

  const mainTabs = [
    { name: "MAIN", text: "MAIN" },
    { name: "ICONS", text: "ICONS" },
    { name: "PICKER", text: "PICKER" },
  ];
  const [mainTab, setMainTab] = useState(mainTabs[0].name);

  const ionRouter = useAppRouter();

  const amTimeRef = useRef<any>();
  const pmTimeRef = useRef<any>();

  const [playStatus, setPlayStatus] = useState("");
  const [checked, setChecked] = useState(true);
  const [number, setNumber] = useState(0);
  const [isError, setIsError] = useState(false);

  const [radioChecked, setRadioChecked] = useState(1);
  const [time, setTime] = useState({ am: "", pm: "" });
  const pages = ["p1", "p2", "p3", "p4", "p5", "p6", "p7"];
  const [pageIndex, setPageIndex] = useState(0);

  const [isOpenLoad, setIsOpenLoad] = useState(false);

  const [isOpenRQ, setIsOpenRQ] = useState(false);

  const [imgSrc, setImgSrc] = useState("");

  const [radarData, setRadarData] = useState<ChartRadarDataType>({
    current_date: "",

    avg: [],
    avgRank: [],
    avgScore: 0,

    current: [],
    currentRank: [],
    currentScore: 0,
  });

  const [triangleRadarData, setTriangleRadarData] = useState<ChartRadarDataType>({
    current_date: "",

    avg: [],
    avgRank: [],
    avgScore: 0,

    current: [],
    currentRank: [],
    currentScore: 0,
  });

  const [pentagonRadarData, setPentagonRadarData] = useState<ChartRadarDataType>({
    current_date: "",

    avg: [],
    avgRank: [],
    avgScore: 0,

    current: [],
    currentRank: [],
    currentScore: 0,
  });

  const [connectStatus, setConnectStatus] = useState(AppConnectButtonStatus.IDEL);

  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openActionSheet, setOpenActionSheet] = useState(false);

  const [data, setData] = useState<string[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);

  const tabs = [
    { name: "SCORE", text: "スコアの推移" },
    { name: "REPORT", text: "レポート" },
  ];
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].name);

  const tabs2 = [
    { name: "SCORE", text: "全体" },
    { name: "REPORT", text: "体" },
    { name: "AAA", text: "心" },
    { name: "BBB", text: "活動" },
    { name: "CCC", text: "バイタル" },
    { name: "DDD", text: "バイタル" },
    { name: "EEE", text: "バイタル" },
    { name: "FFF", text: "バイタル" },
  ];
  const [currentTab2, setCurrentTab2] = useState<string>(tabs2[0].name);

  const doublePickerRef = useRef<any>();
  const singlePickerRef = useRef<any>();
  const [doublePicker, setDoublePicker] = useState("");
  const [singlePicker, setSinglePicker] = useState("");

  const autoFocusNext = () => {
    if (pmTimeRef.current) {
      pmTimeRef.current.setFocus();
    }
  };

  const slideOpts = {
    slidesPerView: 2.3,
    spaceBetween: 1,
  };
  const OneSlideOpts = {
    slidesPerView: 1.2,
    spaceBetween: 1,
  };

  const video_control: React.MutableRefObject<any> = useRef();

  // function onScanSuccess(decodedText: any, decodedResult: any) {
  //   // handle the scanned code as you like, for example:
  //   console.log(`Code matched = ${decodedText}`, decodedResult);
  // }

  // function onScanFailure(error: any) {
  //   // handle scan failure, usually better to ignore and keep scanning.
  //   // for example:
  //   // console.warn(`Code scan error = ${error}`);
  // }

  const pushData = () => {
    const max = data.length + 10;
    const min = max - 10;
    const newData = [];
    for (let i = min; i < max; i++) {
      newData.push(`Item ${i + 1}`);
    }
    const tmpData = [...data, ...newData];
    setData(tmpData);
    return tmpData.length;
  };
  const loadData = (ev: any) => {
    setTimeout(() => {
      const newLength = pushData();
      ev.target.complete();
      if (newLength === 50) {
        setInfiniteDisabled(true);
      }
    }, 1000);
  };

  useEffect(() => {
    // let config: any =
    //   { fps: 10, qrbox: { width: 250, height: 250 } }
    // let html5QrcodeScanner = new Html5QrcodeScanner(
    //   "reader",
    //   config,
    //   false);
    // html5QrcodeScanner.render(onScanSuccess, onScanFailure);

    pushData();
  }, []);

  return (
    <>
      <IonPage>
        <IonContent className={styles.root}>
          <div className="page-spacer-30" />

          <AppTab tabs={mainTabs} onChange={setMainTab} />

          {mainTab === "MAIN" ? (
            <>
              <div className={styles.title}>Local Notification</div>
              <div className="div_center">
                <AppPrimaryButton
                  onClick={async () => {
                    const checkResult = await LocalNotifications.checkPermissions();
                    if (checkResult.display !== "granted") {
                      const requestResult = await LocalNotifications.requestPermissions();
                      if (requestResult.display !== "granted") {
                        return;
                      }
                    }

                    let notice: LocalNotificationSchema = {
                      title: "test title",
                      body: "text body",
                      id: 1,
                    };
                    await LocalNotifications.schedule({ notifications: [notice] });
                  }}
                >
                  Send Notification
                </AppPrimaryButton>
              </div>

              <div className={styles.title}>photo</div>
              <div className="div_center">
                <AppPrimaryButton
                  disabled="false"
                  small="true"
                  onClick={async () => {
                    const image = await Camera.getPhoto({
                      quality: 100,
                      allowEditing: false,
                      resultType: CameraResultType.DataUrl,
                    });

                    // Here you get the image as result.
                    const theActualPicture = image.dataUrl;
                    if (theActualPicture) {
                      setImgSrc(theActualPicture);
                    }
                  }}
                >
                  take photo
                </AppPrimaryButton>
                <img src={imgSrc} alt="" />
              </div>
              <div className="page-spacer-20" />
              <div className={styles.title}>qr</div>
              <div className="div_center">
                <AppPrimaryButton
                  disabled="false"
                  small="true"
                  onClick={() => {
                    setIsOpenRQ(true);
                  }}
                >
                  scan1
                </AppPrimaryButton>

                <AppPrimaryButton
                  disabled="false"
                  small="true"
                  onClick={() => {
                    ionRouter.push(`/qr-scan`, "forward", "push");
                  }}
                >
                  scan new
                </AppPrimaryButton>
              </div>

              <div className="page-spacer-20" />
              <div className={styles.title}>Transition:</div>
              <div className="div_center">
                <AppPrimaryButton
                  onClick={() => {
                    ionRouter.push(`/test`, "forward", "push");
                  }}
                >
                  Nav page
                </AppPrimaryButton>
              </div>

              <div className={styles.title}>Graph:</div>
              <div className="score-chart-panel">
                <AppChartRadar
                  config={{
                    indicator: [
                      { name: "活動", max: 100, color: "black" },
                      { name: "体", max: 100, color: "black" },
                      { name: "バイタル", max: 100, color: "black" },
                      { name: "心", max: 100, color: "black" },
                    ],
                    splitNumber: 4,
                  }}
                  score={radarData}
                />
              </div>

              <div className="page-spacer-20" />
              <div className={`div_center`}>
                <AppPrimaryButton
                  disabled="false"
                  onClick={() => {
                    setRadarData({
                      current_date: "2022-01-01",
                      avg: [45, 65, 60, 70],
                      avgRank: ["D", "C", "C", "C"],
                      avgScore: 64,

                      current: [80, 45, 95, 60],
                      currentRank: ["A", "D", "A", "D"],
                      currentScore: 80,
                    });
                  }}
                >
                  Primary Button
                </AppPrimaryButton>
              </div>

              <div className="page-spacer-20" />
              <div className={`div_center`}>
                <AppPrimaryButton
                  disabled="false"
                  icon={ICONS.ICON.FAVORITE}
                  onClick={() => {
                    setRadarData({
                      current_date: "",

                      avg: [],
                      avgRank: [],
                      avgScore: 0,

                      current: [],
                      currentRank: [],
                      currentScore: 0,
                    });
                  }}
                >
                  Primary Button 2
                </AppPrimaryButton>
              </div>

              <div className="page-spacer-20" />
              <div className="score-chart-panel">
                <AppChartRadar
                  config={{
                    indicator: [
                      { name: "心", max: 100, color: "black" },
                      { name: "活動", max: 100, color: "black" },
                      { name: "体", max: 100, color: "black" },
                    ],
                    splitNumber: 3,
                  }}
                  score={triangleRadarData}
                />
              </div>

              <div className="page-spacer-20" />
              <div className={`div_center`}>
                <AppPrimaryButton
                  disabled="false"
                  small="true"
                  onClick={() => {
                    setTriangleRadarData({
                      current_date: "2022-01-01",
                      avg: [45, 65, 60],
                      avgRank: ["D", "C", "C"],
                      avgScore: 66,

                      current: [80, 45, 95],
                      currentRank: ["A", "D", "A"],
                      currentScore: 88,
                    });
                  }}
                >
                  ボタンラベル
                </AppPrimaryButton>
              </div>

              <div className="page-spacer-20" />
              <div className={`div_center`}>
                <AppPrimaryButton
                  disabled="false"
                  icon={ICONS.ICON.FAVORITE}
                  small="true"
                  onClick={() => {
                    setTriangleRadarData({
                      current_date: "",

                      avg: [],
                      avgRank: [],
                      avgScore: 0,

                      current: [],
                      currentRank: [],
                      currentScore: 0,
                    });
                  }}
                >
                  ボタンラベル
                </AppPrimaryButton>
              </div>

              <div className="score-chart-panel">
                <AppChartRadar
                  config={{
                    indicator: [
                      { name: "体", max: 100, color: "black" },
                      { name: "バイタル", max: 100, color: "black" },
                      { name: "ラベル", max: 100, color: "black" },
                      { name: "ラベル", max: 100, color: "black" },
                      { name: "心", max: 100, color: "black" },
                    ],
                    splitNumber: 5,
                  }}
                  score={pentagonRadarData}
                />
              </div>
              <div className="page-spacer-20" />
              <div className={`div_center`}>
                <AppSecondaryButton
                  disabled="false"
                  onClick={() => {
                    setPentagonRadarData({
                      current_date: "2022-01-01",
                      avg: [45, 65, 60, 70, 80],
                      avgRank: ["D", "C", "C", "A", "B"],
                      avgScore: 65,

                      current: [80, 45, 95, 40, 20],
                      currentRank: ["A", "D", "A", "C", "D"],
                      currentScore: 81,
                    });
                  }}
                >
                  SecondaryButton
                </AppSecondaryButton>
              </div>

              <div className="page-spacer-20" />
              <div className={`div_center`}>
                <AppSecondaryButton
                  disabled="false"
                  icon={ICONS.ICON.FAVORITE}
                  onClick={() => {
                    setPentagonRadarData({
                      current_date: "",

                      avg: [],
                      avgRank: [],
                      avgScore: 0,

                      current: [],
                      currentRank: [],
                      currentScore: 0,
                    });
                  }}
                >
                  SecondaryButton 2
                </AppSecondaryButton>
              </div>

              <div className="page-spacer-20" />
              <div className={`div_center`}>
                <AppSecondaryButton
                  small="true"
                  disabled="false"
                  onClick={() => {
                    setIsOpenLoad(true);
                    setTimeout(() => {
                      setIsOpenLoad(false);
                    }, 5000);
                  }}
                >
                  Loading
                </AppSecondaryButton>
              </div>

              <div className="page-spacer-20" />
              <div className={`div_center`}>
                <AppSecondaryButton small="true" icon={ICONS.ICON.FAVORITE}>
                  ボタンラベル
                </AppSecondaryButton>
              </div>

              <div className={styles.title}>Previous / Next Button:</div>
              <div className={`div_center`}>
                <AppPNButton />
              </div>

              <div className={styles.title}>Quantity Selector:</div>
              <div className={`div_center`}>
                <AppQuantitySelector number={number} setNumber={setNumber} />
              </div>

              <div className={styles.title}>Page Selector:</div>
              <div className={`div_center`}>
                <AppPageSelector checked={checked} onClick={() => setChecked(!checked)} />
              </div>

              <div className={styles.title}>Checkbox:</div>
              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppTrackingButton
                  label="TRACKING "
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                  isMultiBtn={true}
                />
                <AppTrackingButton
                  label="TRACKING"
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                  isMultiBtn={true}
                  disabled={true}
                />
              </div>
              <div className="page-spacer-20" />
              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppCheckbox
                  label="TRACKING"
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                />
                <AppCheckbox
                  label="TRACKING"
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                  size="small"
                />
                <AppCheckbox
                  label="TRACKING"
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                  size="small"
                  disabled={true}
                />
              </div>

              <div className={styles.title}>Radiobox:</div>
              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppTrackingButton
                  label="TRACKING"
                  checked={radioChecked === 3}
                  onClick={() => setRadioChecked(3)}
                />
                <AppTrackingButton
                  label="TRACKING"
                  checked={radioChecked === 3}
                  onClick={() => setRadioChecked(3)}
                  disabled={true}
                />
              </div>

              <div className="page-spacer-20" />

              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppRadiobox
                  checked={radioChecked === 1}
                  onClick={() => setRadioChecked(1)}
                  label="テキストエリア"
                />
                <AppRadiobox
                  checked={radioChecked === 1}
                  onClick={() => setRadioChecked(1)}
                  label="テキストエリア"
                  disabled={true}
                />
              </div>
              <div className="page-spacer-20" />
              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppRadiobox
                  checked={radioChecked === 2}
                  onClick={() => setRadioChecked(2)}
                  label="テキストエリア"
                  type="vertical"
                />
                <AppRadiobox
                  checked={radioChecked === 2}
                  onClick={() => setRadioChecked(2)}
                  label="テキストエリア"
                  type="vertical"
                  disabled={true}
                />
              </div>

              <div className={styles.title}>TextLink:</div>
              <div className={`div_center`}>
                <AppTextLink>新規登録の方はこちら</AppTextLink>
              </div>
              <div className="page-spacer-5" />
              <div className={`div_center`}>
                <AppTextLink underLine={true}>テキストが入ります</AppTextLink>
              </div>
              <div className="page-spacer-5" />
              <div className={`div_center`}>
                <AppTextLink small={true}>続きを読む</AppTextLink>
              </div>

              <div className={styles.title}>Connect:</div>
              <div className={`div_center`}>
                <AppConnectButton
                  status={connectStatus}
                  onClick={() => {
                    if (
                      connectStatus === AppConnectButtonStatus.IDEL ||
                      connectStatus === AppConnectButtonStatus.FAILED
                    ) {
                      setConnectStatus(AppConnectButtonStatus.CONNECTING);
                      setTimeout(() => {
                        setConnectStatus(AppConnectButtonStatus.FAILED);
                      }, 1000);
                    }
                  }}
                />
              </div>

              <div className={styles.title}>Toggle Button:</div>
              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppToggleButton checked={checked} onClick={() => setChecked(!checked)} />
                <AppToggleButton
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                  disabled={true}
                />
              </div>
              <div className="page-spacer-20" />
              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppToggleIconTextButton
                  leftText="3ヵ月表示"
                  rightText="1ヵ月表示"
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                />
                <AppToggleIconTextButton
                  leftText="3ヵ月表示"
                  rightText="1ヵ月表示"
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                  disabled={true}
                />
              </div>
              <div className="page-spacer-20" />
              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppToggleIconTextButton
                  isIcon={true}
                  leftText={ICONS.ICON.ORIENTATION_VERTICAL}
                  rightText={ICONS.ICON.ORIENTATION_HORIZONTAL}
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                />
                <AppToggleIconTextButton
                  isIcon={true}
                  leftText={ICONS.ICON.ORIENTATION_VERTICAL}
                  rightText={ICONS.ICON.ORIENTATION_HORIZONTAL}
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                  disabled={true}
                />
              </div>

              <div className={styles.title}>Header:</div>
              <AppHeader>
                <div style={{ display: "flex" }}>
                  <AppIcon icon={ICONS.ICON.FAVORITE} />
                  <div style={{ width: 30 }} />
                  <AppIcon icon={ICONS.ICON.SEARCH} />
                  <div style={{ width: 25 }} />
                </div>
              </AppHeader>

              <div className={styles.title}>Main Tab: {currentTab}</div>
              <AppTab tabs={tabs} onChange={setCurrentTab} />

              <div className={styles.title}>Sub Tab (Scrollable): {currentTab2}</div>
              <AppScrollableTab tabs={tabs2} onChange={setCurrentTab2} />

              <div className={styles.title}>Dropdown (Time):</div>
              {/* <div className={`div_center`}>
                AM:&nbsp;&nbsp;
                <AppTimePicker
                  ref={amTimeRef}
                  value={time.am}
                  setValue={(val: any) =>
                    setTime({
                      ...time,
                      am: val,
                    })
                  }
                  onIonChange={autoFocusNext}
                />
              </div>
              <div className={`div_center`}>
                PM:&nbsp;&nbsp;
                <AppTimePicker
                  ref={pmTimeRef}
                  value={time.pm}
                  setValue={(val: any) =>
                    setTime({
                      ...time,
                      pm: val,
                    })
                  }
                  onIonChange={() => false}
                />
              </div>
              <div className={`div_center`}>
                disabled:&nbsp;&nbsp;
                <AppTimePicker
                  value={time.am}
                  setValue={(val: any) =>
                    setTime({
                      ...time,
                      am: val,
                    })
                  }
                  onIonChange={() => false}
                  disabled={true}
                />
              </div> */}

              <div className="page-spacer-20" />
              <div className={styles.title}>Input Field:</div>
              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppInput
                  label="ラベルラベル"
                  error={isError ? { type: undefined } : undefined}
                  errorMsg="エラーの内容"
                  onIonChange={(event: any) => {
                    setIsError(false);
                  }}
                ></AppInput>
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}
              >
                <AppPrimaryButton
                  disabled="false"
                  small="true"
                  onClick={() => {
                    setIsError(!isError);
                  }}
                >
                  error
                </AppPrimaryButton>
              </div>

              <div className="page-spacer-20" />

              <div className={styles.title}>Pagination:</div>
              <AppPagination
                pages={pages}
                currentIndex={pageIndex}
                onClick={(currentPageIndex: any) => setPageIndex(currentPageIndex)}
              />

              <div className="page-spacer-20" />

              <div className={styles.title}>Badge:</div>
              <div className="flex-row-around">
                <AppBadge color="white" status="new">
                  New
                </AppBadge>
                <AppBadge color="white" status="connected">
                  Connected
                </AppBadge>
                <AppBadge color="white" status="not_connected">
                  Not Connected
                </AppBadge>
              </div>

              <div className="page-spacer-20" />

              <div className={styles.title}>Large Tile:</div>
              <div className="flex-row-around">
                <AppLargeTile
                  src={largeImg}
                  textTitle="テキストが入りますテキストが入ります"
                  textSubTitle="所要時間"
                  textSubTitleTime="10分"
                />
              </div>
              <div className={styles.title}>Small Tile:</div>
              <div className="page-spacer-10" />
              <div className="flex-row-around">
                <AppSmallTile src={smallImg} textTitle={["テキスト1行"]} />
                <AppSmallTile src={smallImg} textTitle={["テキスト1行", "テキスト2行"]} />
              </div>
              <div className="page-spacer-10" />
              <div className="flex-row-around">
                <AppSmallTile src={smallImg} tagText="ラベル" textTitle={["テキストテキスト"]} />
              </div>

              <div className={styles.title}>Small Title:</div>
              <div className="page-spacer-10" />
              <div className="flex-row-around">
                <AppImageTile
                  tagTitle="暮らしのヒント"
                  textTitle="食生活の注意点"
                  textSubTitle="所要時間"
                  textSubTitleTime="10分"
                  src={backgroundImg}
                  onPlay={() => {
                    video_control.current.play();
                  }}
                />
              </div>

              <div className="page-spacer-20" />

              <div className={styles.title}>Tag:</div>
              <div className="flex-row-around">
                <AppTagOne textTitle="ラベル" />
                <AppTagTwo textTitle={["ラベル", "ラベル"]} />
                <AppTagTime textTitle="0:00" />
                <AppTagColor color="purple-hue01" textTitle="ラベル" />
                <AppTagColor color="orange-hue01" textTitle="ラベル" />
              </div>
              <div className="page-spacer-10" />
              <div className="flex-row-around">
                <AppTagButton>ラベル</AppTagButton>

                <AppTagButton disabled="true">ラベル</AppTagButton>
              </div>

              <div className="page-spacer-20" />

              <div className={styles.title}>Medicine Image:</div>
              <div className="flex-row-around">
                <AppMedicineImage src={medicineImg} size="Large" />
                <AppMedicineImage src={medicineImg} size="Medium" />
                <AppMedicineImage src={medicineImg} size="Small" />
              </div>

              <div className="page-spacer-20" />

              <div className={styles.title}>Panel Image:</div>
              <div className="flex-row-around">
                <AppPanelImage src={panelImg} />
              </div>

              <div className="page-spacer-20" />

              <div className={styles.title}>Large Image:</div>
              <div className="flex-row-around">
                <AppLargeImage src={largeImg2} length="3:24" />
              </div>

              <div className={styles.title}>Small Image:</div>
              <div className="flex-row-around">
                <AppSmallImage
                  src={smallImg2}
                  title="キャプションが入りますキャプションが入りますキャプションが入ります。"
                />
              </div>
              <div className="page-spacer-20" />

              <div className={styles.title}>Modal:</div>
              <div className="div_center">
                <AppPrimaryButton
                  onClick={() => {
                    setOpenModal1(true);
                  }}
                >
                  Open Modal (No Close)
                </AppPrimaryButton>
              </div>
              <div className="page-spacer-20" />
              <div className="div_center">
                <AppPrimaryButton
                  onClick={() => {
                    setOpenModal2(true);
                  }}
                >
                  Open Modal (Close Button)
                </AppPrimaryButton>
              </div>
              <div className="page-spacer-20" />
              <div className="div_center">
                <AppPrimaryButton
                  onClick={() => {
                    setOpenActionSheet(true);
                  }}
                >
                  Open Action Sheet
                </AppPrimaryButton>
              </div>

              <div className={styles.title}>Infinite Scroll(Step: 5, Max: 50):</div>
              <IonList>
                {data.map((item: any, index: number) => {
                  return (
                    <IonItem key={index}>
                      <IonLabel>{item}</IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>

              <IonInfiniteScroll onIonInfinite={loadData} disabled={isInfiniteDisabled}>
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data..."
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </>
          ) : mainTab === "ICONS" ? (
            <>
              <div className={styles.title}>Image Icon 54px</div>
              <IonGrid>
                <IonRow>
                  {Object.entries(ICONS.IMAGE).map(([key, value]: any) => {
                    if (extraIconKey.findIndex((exKey) => key === exKey) === -1) {
                      return (
                        <IonCol size="4" className={styles.col}>
                          <AppIcon icon={value} size="illustration" />
                          <IonLabel className={styles.name}>{key}</IonLabel>
                        </IonCol>
                      );
                    } else {
                      return "";
                    }
                  })}
                </IonRow>
              </IonGrid>

              <div className={styles.title}>Floating Icon</div>
              <IonGrid>
                <IonRow>
                  {Object.entries(ICONS.FLOATING).map(([key, value]: any) => {
                    if (extraIconKey.findIndex((exKey) => key === exKey) === -1) {
                      return (
                        <IonCol size="4" className={styles.col}>
                          <AppIcon icon={value} size="illustration" />
                          <IonLabel className={styles.name}>{key}</IonLabel>
                        </IonCol>
                      );
                    } else {
                      return "";
                    }
                  })}
                </IonRow>
              </IonGrid>

              <div className={styles.title}>Tracking Icon</div>
              <IonGrid>
                <IonRow>
                  {Object.entries(ICONS.TRACKING).map(([key, value]: any) => {
                    if (extraIconKey.findIndex((exKey) => key === exKey) === -1) {
                      return (
                        <IonCol size="4" className={styles.col}>
                          <AppIcon icon={value} size="md" />
                          <IonLabel className={styles.name}>{key}</IonLabel>
                        </IonCol>
                      );
                    } else {
                      return "";
                    }
                  })}
                </IonRow>
              </IonGrid>

              <div className={styles.title}>Large 48px</div>
              <IonGrid>
                <IonRow>
                  {Object.entries(ICONS.ICON).map(([key, value]: any) => {
                    if (extraIconKey.findIndex((exKey) => key === exKey) === -1) {
                      return (
                        <IonCol size="4" className={styles.col}>
                          <AppIcon icon={value} size="lg" />
                          <IonLabel className={styles.name}>{key}</IonLabel>
                        </IonCol>
                      );
                    } else {
                      return "";
                    }
                  })}
                </IonRow>
              </IonGrid>

              <div className={styles.title}>Medium 36px</div>
              <IonGrid>
                <IonRow>
                  {Object.entries(ICONS.ICON).map(([key, value]: any) => {
                    if (extraIconKey.findIndex((exKey) => key === exKey) === -1) {
                      return (
                        <IonCol size="4" className={styles.col}>
                          <AppIcon icon={value} />
                          <IonLabel className={styles.name}>{key}</IonLabel>
                        </IonCol>
                      );
                    } else {
                      return "";
                    }
                  })}
                </IonRow>
              </IonGrid>

              <div className={styles.title}>Small 24px</div>
              <IonGrid>
                <IonRow>
                  {Object.entries(ICONS.ICON).map(([key, value]: any) => {
                    if (extraIconKey.findIndex((exKey) => key === exKey) === -1) {
                      return (
                        <IonCol size="4" className={styles.col}>
                          <AppIcon icon={value} size="sm" />
                          <IonLabel className={styles.name}>{key}</IonLabel>
                        </IonCol>
                      );
                    } else {
                      return "";
                    }
                  })}
                </IonRow>
              </IonGrid>

              <div className={styles.title}>Extra Small 16px</div>
              <IonGrid>
                <IonRow>
                  {Object.entries(ICONS.ICON).map(([key, value]: any) => {
                    if (extraIconKey.findIndex((exKey) => key === exKey) === -1) {
                      return (
                        <IonCol size="4" className={styles.col}>
                          <AppIcon icon={value} size="xs" />
                          <IonLabel className={styles.name}>{key}</IonLabel>
                        </IonCol>
                      );
                    } else {
                      return "";
                    }
                  })}
                </IonRow>
              </IonGrid>
              <div className="page-spacer-50" />
            </>
          ) : (
            <>
              <div className={styles.title}>Double Picker</div>
              <AppDoublePicker
                ref={doublePickerRef}
                initVal={doublePicker}
                options={HOUR_KV_OPTIONS}
                options2={MINUTE_STEP_KV_OPTIONS}
                separatedBy=":"
              />
              <div className="page-spacer-15" />
              <div className="div_center">
                <AppPrimaryButton onClick={() => alert(doublePickerRef.current.getSelectedData())}>
                  Double Picker Value
                </AppPrimaryButton>
              </div>

              <div className={styles.title}>Single Picker</div>
              <AppPicker ref={singlePickerRef} initVal={singlePicker} options={HOUR_KV_OPTIONS} />
              <div className="page-spacer-10" />
              <div className="div_center">
                <AppPrimaryButton onClick={() => alert(singlePickerRef.current.getSelectedData())}>
                  Single Picker Value
                </AppPrimaryButton>
              </div>
            </>
          )}
        </IonContent>
      </IonPage>

      <AppModal
        isOpen={openModal1}
        title="症状の記録が完了しました"
        desc="本治療薬データは販売停止によりご利用になれません。"
        confirmBtnText="ボタンラベル"
        textLink="新規登録の方はこちら"
        handleTextLink={() => setOpenModal1(false)}
        handleConfirm={() => setOpenModal1(false)}
      />

      <AppModal
        isOpen={openModal2}
        icon={ICONS.IMAGE.SPECIAL_RECORD_COMPLETED}
        title="症状の記録が完了しました"
        confirmBtnText="ボタンラベル"
        textLink="新規登録の方はこちら"
        handleTextLink={() => setOpenModal2(false)}
        handleConfirm={() => setOpenModal2(false)}
        handleClose={() => setOpenModal2(false)}
      />

      <AppQR
        isOpen={isOpenRQ}
        onClose={(code: string) => {
          setIsOpenRQ(false);
        }}
      />
      <AppVideo src={testvideo} ref={video_control} />
      <AppLoading isOpen={isOpenLoad} />
      <AppActionSheet isOpen={openActionSheet} handleClose={() => setOpenActionSheet(false)} />
    </>
  );
}
