import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  updateScoreTransitionWeekChart,
  updateScoreTransitionMonthChart,
  updateAvgCurrentScore,
} from "src/web/actions";
import { getWeekChartV2, getMonthChartV2, fetchHealthCheckInfo } from "../../services";
import { lineDataType } from "src/web/types";
import { ScoreType } from "src/web/components/ScoreChart";
import { RADAR_MAP } from "src/web/constants";

export function useSymptomScoreModel() {
  const dispatch = useDispatch();

  const [isLoadingSymptomScore, setIsLoadingSymptomScore] = useState(false);

  const fetchWeekChart = async (currentDate: string) => {
    setIsLoadingSymptomScore(true);
    dispatch(
      updateScoreTransitionWeekChart({
        isLoading: true,
      }),
    );
    try {
      const res: any = await getWeekChartV2({ currentDate });
      // const res: any = {
      //   "barData":{"2023/04/02":{},"2023/04/03":{},"2023/04/04":{},"2023/04/05":{},"2023/04/06":{},"2023/04/07":{},"2023/04/08":{}},
      //   "lineData":{"2023/04/02":{},"2023/04/03":{},"2023/04/04":{},"2023/04/05":{},"2023/04/06":{},"2023/04/07":{},"2023/04/08":{}},
      //   "lineKey":[],
      //   "minDate":"2022/05/04",
      //   "weekdayTimeLine":{"2023/04/02":[],"2023/04/03":[],"2023/04/04":[],"2023/04/05":[],"2023/04/06":[],"2023/04/07":[],"2023/04/08":[]},
      //   "overallData":{},
      //   "overallDataEveryDay":{"2023/04/02":{},"2023/04/03":{},"2023/04/04":{},"2023/04/05":{},"2023/04/06":{},"2023/04/07":{},"2023/04/08":{}}
      // }
      dispatch(
        updateScoreTransitionWeekChart({
          isLoading: false,
          data: res,
        }),
      );
    } catch (error) {
      dispatch(
        updateScoreTransitionWeekChart({
          isLoading: false,
        }),
      );
    } finally {
      setIsLoadingSymptomScore(false);
    }
  };

  const combineDuplicatedMedicineData = (res: any) => {
    let medicineNameKey: { [name: string]: string } = {};
    let mergeLineData: lineDataType = {};
    // 同じ名前の薬を合併する
    for (let key in res.lineData) {
      let lineData = res.lineData[key];
      if (lineData.m_name in medicineNameKey) {
        // マージしたのidとして、総合データを取得する
        let medicineId = medicineNameKey[lineData.m_name];
        let sameLineData = mergeLineData[medicineId];
        // 固定値をループする
        for (let i = 0; i < 12; i++) {
          if (lineData.month_check[i] === 1) {
            sameLineData.month_check[i] = 1;
          }
        }
        // 存在しない場合
      } else {
        medicineNameKey[lineData.m_name] = key;
        mergeLineData[key] = lineData;
      }
    }
    return mergeLineData;
  };

  const fetchMonthChart = async (currentDate: string) => {
    setIsLoadingSymptomScore(true);
    dispatch(
      updateScoreTransitionMonthChart({
        isLoading: true,
      }),
    );
    try {
      const res: any = await getMonthChartV2({ currentDate });
      // const res: any = {
      //   "barData":{"2023/01":{},"2023/02":{},"2023/03":{},"2023/04":{},"2023/05":{},"2023/06":{}},
      //   "lineData":{"2023/01":{},"2023/02":{},"2023/03":{},"2023/04":{},"2023/05":{},"2023/06":{}},
      //   "overallData":{},
      //   "overallDataEveryMonth":{"2023/01":{},"2023/02":{},"2023/03":{},"2023/04":{},"2023/05":{},"2023/06":{}},
      //   "halfYearAverageScore": 0,
      //   "startDate":"2023/01",
      // }

      res.lineData = combineDuplicatedMedicineData(res);

      dispatch(
        updateScoreTransitionMonthChart({
          isLoading: false,
          data: res,
        }),
      );
    } catch (error) {
      dispatch(
        updateScoreTransitionMonthChart({
          isLoading: false,
        }),
      );
    } finally {
      setIsLoadingSymptomScore(false);
    }
  };

  const fetchAvgCurrentScore = async (currentDate: string, scoreData: ScoreType) => {
    const directMap = new Map<string, number>([
      ["a", 0],
      ["b", 1],
      ["c", 2],
      ["d", 3],
    ]);

    dispatch(
      updateAvgCurrentScore({
        isLoading: true,
      }),
    );
    try {
      const res = await fetchHealthCheckInfo({ currentDate });
      dispatch(
        updateAvgCurrentScore({
          isLoading: false,
          data: res,
        }),
      );

      const resCurrent = res.current;
      if (resCurrent) {
        let loc_current = [0, 0, 0, 0];
        let loc_current_rank = ["", "", "", ""];
        for (let key in RADAR_MAP) {
          let index = directMap.get(key) ?? -1;
          if (index >= 0 && index <= 4) {
            loc_current[RADAR_MAP[key]] = resCurrent[key];
            loc_current_rank[RADAR_MAP[key]] = resCurrent[`${key}_r`];
          }
        }
        if (resCurrent["score"]) {
          scoreData.current = loc_current;
          scoreData.currentRank = loc_current_rank;
          scoreData.currentScore = resCurrent["score"];
        }
      }

      const resAvg = res.avg;
      if (resAvg) {
        let loc_avg = [0, 0, 0, 0];
        let loc_avg_rank = ["", "", "", ""];
        for (let key in RADAR_MAP) {
          let index = directMap.get(key) ?? -1;
          if (index >= 0 && index <= 4) {
            loc_avg[RADAR_MAP[key]] = resAvg[key];
            loc_avg_rank[RADAR_MAP[key]] = resAvg[`${key}_r`];
          }
        }
        scoreData.avg = loc_avg;
        scoreData.avgRank = loc_avg_rank;
        scoreData.avgScore = resAvg["score"];
      }

      return scoreData;
    } catch (error) {
      dispatch(
        updateAvgCurrentScore({
          isLoading: false,
        }),
      );
      return null;
    }
  };

  return {
    isLoadingSymptomScore,
    fetchWeekChart,
    fetchMonthChart,
    fetchAvgCurrentScore,
  };
}
