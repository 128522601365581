import { IonLabel } from "@ionic/react";
import { SubMenuType } from "src/web/types";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { AppIcon } from "../../AppIcon/appIcon";
import { ICONS } from "src/web/constants";
import styles from "./lineItemWithIcon.module.css";

interface LineItemWithIconProps {
  item: SubMenuType;
  isGray?: boolean;
}

export function LineItemWithIcon({ item, isGray }: LineItemWithIconProps) {
  const ionRouter = useAppRouter();

  return (
    <>
      <div className="page-spacer-45" />

      <div
        className="flex-row-between"
        onClick={() => {
          if (item.event) {
            item.event();
          } else if (item.url) {
            ionRouter.push(item.url);
          }
        }}
      >
        <div>
          <IonLabel className={`p-body ${isGray ? "gray" : ""}`}>{item.title}</IonLabel>
        </div>

        <div className="flex-row-end">
          {!!item.activeStatus && (
            <div
              className={`connection-dot ${
                item.activeStatus.isActive ? "connection-dot-active" : ""
              }`}
            />
          )}
          {!!item.desc && (
            <IonLabel className={`p-smallbody ${styles.desc} ${isGray ? "gray" : ""}`}>
              {item.desc}
            </IonLabel>
          )}
          {!item.hideIcon && <AppIcon icon={item.iconSrc ?? ICONS.ICON.ARROW_FORWARD} size="sm" />}
        </div>
      </div>
    </>
  );
}
