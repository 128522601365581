import { IonLabel } from "@ionic/react";

interface PageTitleProps {
  title: string;
  subTitle?: string;
  imgSrc?: string;
  isGray?: boolean;
}

export function PageTitle({ title, subTitle, imgSrc, isGray }: PageTitleProps) {
  return (
    <>
      <IonLabel className="p-header1 pre-wrap text-block">{title}</IonLabel>

      {!!subTitle && (
        <>
          <div className="page-spacer-45" />
          <IonLabel className={`p-body pre-wrap text-block ${isGray ? "gray" : ""}`}>
            {subTitle}
          </IonLabel>
        </>
      )}

      {!!imgSrc && (
        <>
          <div className="page-spacer-45" />
          <div className="div_center">
            <img src={imgSrc} alt="" />
          </div>
        </>
      )}
    </>
  );
}
