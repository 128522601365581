import { IonButton, IonCol, IonContent, IonGrid, IonModal, IonPage, IonRow } from "@ionic/react";
import { useState } from "react";
import { ButtonList } from "../pages/Chat";
import ChatButton from "./ChatButton";

interface ContainerProps {
  display: boolean;
}

const ChatMenu: React.FC<ContainerProps> = ({ display }) => {
  const [showModal, setShowModal] = useState(display);

  return (
    <IonModal
      isOpen={showModal}
      initialBreakpoint={0.3}
      breakpoints={[0, 0.3, 1]}
      onDidDismiss={() => setShowModal(false)}
    >
      <IonGrid>
        <IonRow>
          {ButtonList.slice(0, 3).map((content) => {
            return <ChatButton content={content}></ChatButton>;
          })}

          {ButtonList.slice(3, 6).map((content) => {
            return <ChatButton content={content}></ChatButton>;
          })}
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default ChatMenu;
