import { AppTagOne } from "../AppTag/appTagOne";
import { AppIcon } from "../AppIcon/appIcon";
import { ICONS } from "../../../constants";
import styles from "./styles.module.css";

interface ImageTile {
  className?: string;
  tagTitle?: string;
  textTitle?: string;
  textSubTitle?: string;
  textSubTitleTime?: string;
  onFavorite?: (videoSrc: string) => void;
  onPlay?: (videoSrc: string) => void;
  videoSrc?: string;
  src: string;
}

export function AppImageTile(props: ImageTile) {
  return (
    <div className={`${styles.divContainer} ${props.className}`}>
      <img className={styles.img} src={props.src} alt="" />
      <div className={styles.divContainerTool}>
        <div className={styles.divTag}>
          <AppTagOne textTitle={props.tagTitle} />
        </div>
        <div className={styles.divTitle}>
          <div className={styles.title}>{props.textTitle}</div>
          <div className="page-spacer-5" />
          <div className={styles.subTitle}>
            <div>{props.textSubTitle}</div>
            <div className="page-width-spacer-10" />
            <div>{props.textSubTitleTime}</div>
          </div>
        </div>
        <div className={styles.divFavorite}>
          <AppIcon
            icon={ICONS.ICON.FAVORITE}
            onClick={() => {
              props.onFavorite && props.onFavorite(props.videoSrc ? props.videoSrc : "");
            }}
          />
        </div>
        <div className={styles.divPlay}>
          <AppIcon
            icon={ICONS.ICON.PLAY_CIRCLE}
            onClick={() => {
              props.onPlay && props.onPlay(props.videoSrc ? props.videoSrc : "");
            }}
          />
        </div>
      </div>
    </div>
  );
}
