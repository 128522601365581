import { IonLabel } from "@ionic/react";

interface AlertHeadlineProps {
  title: string;
  desc?: string;
}

export function AlertHeadline({ title, desc }: AlertHeadlineProps) {
  return (
    <>
      <IonLabel className="p-header3 text-center-block pre-wrap">{title}</IonLabel>

      {desc && (
        <>
          <div className="page-spacer-15" />
          <IonLabel className="p-body text-center-block pre-wrap">{desc}</IonLabel>
        </>
      )}
    </>
  );
}
