import { actionConst } from "../constants";
import { LoadingTimelineType } from "src/web/types";

interface Action {
  type: actionConst;
  state: LoadingTimelineType;
}

const initialState: LoadingTimelineType = {
  isLoading: false,
};

export default function dailyActivityTimelineReducer(state = initialState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_DAILY_ACTIVITY_TIMELINE:
      return actions.state;
    case actionConst.CLEAR_DAILY_ACTIVITY_TIMELINE:
      return initialState;
    default:
      return state;
  }
}
