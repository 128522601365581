import { IonModal, createAnimation, IonLabel } from "@ionic/react";
import { ICONS, DEFAULT_SHEET_MODAL_SAFE_TOP } from "../../../constants";
import { getWindowDimensions } from "../../../utils/common";
import { AppIcon } from "../AppIcon/appIcon";
import styles from "./appActionSheet.module.css";

interface AppActionSheetProps {
  isOpen: boolean;
  title?: string;
  className?: string;
  sheetHeight?: number;
  handleClose: () => void;
  children?: any;
}

// default to half of the screen height
// const DEFAULT_INIT_BREAKPOINT =
//   getWindowDimensions().height / 2 / (getWindowDimensions().height - 52);
const DEFAULT_INIT_BREAKPOINT = 1;

export function AppActionSheet({
  isOpen,
  title,
  className,
  sheetHeight,
  handleClose,
  children,
}: AppActionSheetProps) {
  // get ionic safe top from css variable
  const ionSafeTop = getComputedStyle(document.documentElement).getPropertyValue(
    "--ion-safe-area-top",
  );
  const ionSaveTopNumber = ionSafeTop ? parseInt(ionSafeTop) : DEFAULT_SHEET_MODAL_SAFE_TOP;

  const initialBreakpoint = sheetHeight
    ? sheetHeight / (getWindowDimensions().height - ionSaveTopNumber)
    : DEFAULT_INIT_BREAKPOINT;

  const enterAnimation = (baseEl: HTMLElement) => {
    const root = baseEl.shadowRoot;

    const backdropAnimation = createAnimation("backdropAnimation")
      .addElement(root?.querySelector("ion-backdrop")!)
      .keyframes([
        { offset: 0, opacity: "0" },
        { offset: 1, opacity: `calc(var(--ion-backdrop-opacity) * ${initialBreakpoint})` },
      ])
      .duration(300);

    const wrapperAnimation = createAnimation("wrapperAnimation")
      .addElement(root?.querySelector(".modal-wrapper")!)
      .keyframes([
        { offset: 0, opacity: 1, transform: "translateY(100%)" },
        { offset: 1, opacity: 1, transform: `translateY(${100 - initialBreakpoint * 100}%)` },
      ])
      .easing("cubic-bezier(0.01, 0.42, 0.35, 1)")
      .delay(300);

    return createAnimation()
      .addElement(baseEl)
      .duration(600)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  };

  return (
    <IonModal
      isOpen={isOpen}
      backdropDismiss={false}
      className={`${styles.root} ${className ? className : ""}`}
      initialBreakpoint={initialBreakpoint}
      breakpoints={[0, initialBreakpoint, 1]}
      onIonModalDidDismiss={handleClose}
      enterAnimation={enterAnimation}
    >
      <div className={styles.content}>
        {title && (
          <>
            <div className={styles.header}>
              <AppIcon
                icon={ICONS.ICON.ARROW_CIRCLE_BACK}
                size="lg"
                className={styles.backBtn}
                onClick={handleClose}
              />
              <IonLabel className={styles.title}>{title}</IonLabel>
            </div>

            <div className="page-spacer-45" />
          </>
        )}

        {children}
      </div>
    </IonModal>
  );
}
