import styles from "./styles.module.css";

interface PanelImage {
  src: string;
  className?: string;
}

export function AppPanelImage(props: PanelImage) {
  return (
    <div className={`${styles.divContainer} div_center ${props.className ? props.className : ''}`}>
      <div>
        <img src={props.src} className={`${styles.img}`} alt="" />
      </div>
    </div>
  );
}
