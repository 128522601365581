import { useState, useEffect } from "react";
import { useIonViewWillEnter } from "@ionic/react";
import { useSelector } from "react-redux";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { useAlarmModel, useSymptomModel, useSymptomScoreModel } from "src/web/hooks/models";
import { RootState } from "src/web/reducer";
import { getDateStrByDate, getNow, getToday } from "src/web/utils/common";
import { SymptomModelType } from "../types";
import { CarouselType } from "src/web/components/UiComponents/organisms/CarouselView";
import { OpenCurrentSymptomType } from "../components/CurrentSymptomActionsheet/currentSymptomActionsheet";
import { ChartRadarDataType } from "src/web/components/UiComponents";
import { AlarmType, PageActionCode, PageCode } from "src/web/types";
import { ALARM_READY_TIME_IN_MINUTES, RADAR_MAP } from "src/web/constants";
import { ScoreType } from "src/web/components/ScoreChart";
import { getSymptomAdvice } from "src/web/services/api/personalized_advice/symptom";

import medicineImg from "src/web/assets/svgs/icons/image_medicine.svg";
import bloodPressureImg from "src/web/assets/svgs/icons/image_blood_pressure.svg";
import thermometerImg from "src/web/assets/svgs/icons/image_thermometer.svg";
import bodyConditionImg from "src/web/assets/svgs/icons/image_mind_and_body_condition.svg";
import susumeImg from "src/web/assets/img/hintcard/susume.png";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

let checkLastCheckDateTimeout: any = null;

export function useDiseaseMainControl() {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const {
    MEDICINE_TIMING_OPTIONS,
  } = useTextConstants();

  const currentDate: string = getToday();

  const { fetchSymptomListWithScoreAllActive } = useSymptomModel();
  const { fetchAvgCurrentScore } = useSymptomScoreModel();
  const { fetchAlarmInfo } = useAlarmModel();

  const { alarmSchedule, avgCurrentScore } = useSelector((state: RootState) => {
    return {
      alarmSchedule: state.alarmSchedule,
      avgCurrentScore: state.avgCurrentScore,
    };
  });

  const videoSlidersList = [susumeImg, susumeImg, susumeImg, susumeImg];

  // 毎日記録ボタン
  const [isShowReport, setIsShowReport] = useState<boolean>(false);
  const [symptomAdvice, setSymptomAdvice] = useState<string>("");
  const [openCurrentSymptonActionSheet, setOpenCurrentSymptonActionSheet] =
    useState<OpenCurrentSymptomType>({
      isOpen: false,
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [symptomSliderData, setSymptomSliderData] = useState<CarouselType[]>([]);
  const [lastCheckDate, setLastCheckDate] = useState<string>(currentDate);
  const [actionList, setActionList] = useState<CarouselType[]>([
    {
      text: intl.formatMessage({
        id: "page.main_tab.disease_main_tab.disease_main.taking_medicine.menu.title",
      }),
      src: medicineImg,
      title: "",
      action: undefined,
      isShowing: false,
    },
    {
      text: intl.formatMessage({
        id: "page.main_tab.disease_main_tab.disease_main.blood_pressure.menu.title",
      }),
      src: bloodPressureImg,
      title: intl.formatMessage({
        id: "page.main_tab.disease_main_tab.disease_main.blood_pressure.menu.night",
      }),
      action: () => handlePageNav(`/reports/vitals/blood-pressure-records/edit?isFromHome=true`),
      isShowing: false,
    },
    {
      text: intl.formatMessage({
        id: "page.main_tab.disease_main_tab.disease_main.temperature.menu.title",
      }),
      src: thermometerImg,
      title: intl.formatMessage(
        { id: "page.main_tab.disease_main_tab.disease_main.temperature.menu.sub_title" },
        { hours: 1 },
      ),
      action: () => {},
      isShowing: true,
    },
    {
      text: intl.formatMessage({
        id: "page.main_tab.disease_main_tab.disease_main.questionnaire.menu.title",
      }),
      src: bodyConditionImg,
      title: " ",
      action: () => handlePageNav(`/symptom-questionnaire?currentDate=${getToday()}`),
      isShowing: false,
    },
  ]);
  // タイプを変更する
  const [score, setScore] = useState<ChartRadarDataType>({
    current_date: currentDate,
    avg: [],
    avgRank: [],
    avgScore: 0,
    current: [],
    currentRank: [],
    currentScore: 0,
  });

  const handlePageNav = (
    path: string,
    routerDirection?: "forward" | "back" | "root" | "none",
    routeAction?: "push" | "replace" | "pop",
  ) => {
    clearTimeout(checkLastCheckDateTimeout);
    ionRouter.push(path, routerDirection, routeAction);
  };

  const handleNavToSymptomRecordMain = () => {
    handlePageNav(`/home/reports?date=${getToday()}`, "forward", "push");
  };

  const handleNavToSymptomEnteringPage = () => {
    handlePageNav("/symptom-entering-details");
  };

  /*
    compare with the alarm schedules and show dg if the scheduled alarm is within 60 mins
    this function will change the showActionButton without call the set function to re-render
    need to manually call the set if necessary
  */
  const checkIfNeedToShowDG = (alarms: AlarmType[]) => {
    const now = new Date().getTime();
    const showAlarm = alarms.find((alarm: AlarmType) => {
      const target = new Date(`${getToday()} ${alarm.time}`).getTime();
      const gapMinutes = (target - now) / (1000 * 60);
      return gapMinutes > 0 && gapMinutes <= ALARM_READY_TIME_IN_MINUTES;
    });

    if (showAlarm) {
      const option = MEDICINE_TIMING_OPTIONS.find((opt) => opt.value === showAlarm.type);
      const subTitle = option ? `${option.text}(${showAlarm.time})` : `${showAlarm.type}`;
      actionList[0] = {
        ...actionList[0],
        title: subTitle,
        action: () => {
          handlePageNav(
            `/home/medication-record?alarm=${option ? option.value : showAlarm.type}`,
            "forward",
            "replace",
          );
        },
        isShowing: true,
      };
    } else {
      actionList[0] = {
        ...actionList[0],
        isShowing: false,
      };
    }
  };

  /* refresh the alarm schedule if the page is open and the day is changed to the next day
   loop to check every 5 minutes */
  const funBatchCheck = () => {
    clearTimeout(checkLastCheckDateTimeout);
    checkLastCheckDateTimeout = setTimeout(() => {
      if (lastCheckDate < getToday()) {
        doFetchData();
      } else {
        checkIfNeedToShowDG(alarmSchedule);
        setActionList([...actionList]);
      }
      funBatchCheck();
    }, 5 * 60 * 1000);
  };

  // 今の症状
  const getActiveSymptomData = async () => {
    const data: SymptomModelType | undefined = await fetchSymptomListWithScoreAllActive(
      {
        pageCode: PageCode.T01,
        pageActionCode: PageActionCode.T01_A01,
      },
      {
        isOnHome: true,
      },
    );
    if (data) {
      setSymptomSliderData(
        data.recordDetails.map((item) => {
          return {
            text: item.content,
            src: item.iconSrc,
            isShowing: true,
            action: () => {
              setOpenCurrentSymptonActionSheet({
                isOpen: true,
                data: item,
                nowDate: getToday(),
                nowTime: getNow(),
              });
            },
          };
        }),
      );
    }
  };

  // get score
  const getHealthCheckInfo = async () => {
    // 空値の設定
    const emptyScore: ScoreType = {
      current_date: currentDate,
      avg: [],
      avgRank: [],
      avgScore: 0,
      current: [],
      currentRank: [],
      currentScore: 0,
    };
    let initScore: ScoreType = JSON.parse(JSON.stringify(emptyScore));

    setLastCheckDate(currentDate);
    setScore(emptyScore);

    await fetchAlarmInfo(currentDate);

    const scoreData: ScoreType | null = await fetchAvgCurrentScore(currentDate, initScore);
    if (scoreData) {
      setScore(scoreData);
    }
  };

  const doFetchData = async () => {
    try {
      setIsLoading(true);
      // score chart and アクションリスト
      await getHealthCheckInfo();

      // 今の症状
      await getActiveSymptomData();

      getSymptomAdvice({
        snapshot_at: getDateStrByDate(new Date()),
      }).then((res) => {
        setSymptomAdvice(res.result);
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseActionSheet = () => {
    setOpenCurrentSymptonActionSheet({
      isOpen: false,
    });
  };

  useIonViewWillEnter(async () => {
    await doFetchData();
  });

  // 画面ロードするとき実行
  useEffect(() => {
    const handleNativeBack = (ev: any) => {
      ev.detail.register(10, () => {});
    };
    // block native back click to return to login page
    document.addEventListener("ionBackButton", handleNativeBack);

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
      clearTimeout(checkLastCheckDateTimeout);
    };
  }, []);

  useEffect(() => {
    if (alarmSchedule) {
      checkIfNeedToShowDG(alarmSchedule);
      // refresh the alarm schedule if the page is open and the day is changed to the next day
      funBatchCheck();
    }

    if (alarmSchedule && avgCurrentScore.data) {
      // set slider item in アクションリスト
      const currentScoreData = avgCurrentScore.data.current;
      if (currentScoreData) {
        // 血圧
        actionList[1].isShowing = !currentScoreData.c_r;
        // 心と体の調子
        actionList[3].isShowing = !currentScoreData.b_r;
      } else {
        actionList[1].isShowing = true; // 血圧
        actionList[3].isShowing = true; // 心と体の調子
      }
      setActionList([...actionList]);
      setIsShowReport(avgCurrentScore.data.hasCurrent);
    }
  }, [alarmSchedule, avgCurrentScore]);

  return {
    isLoading,
    score,
    symptomAdvice,
    isShowReport,
    symptomSliderData,
    actionList,
    openCurrentSymptonActionSheet,
    videoSlidersList,
    handleNavToSymptomRecordMain,
    handleNavToSymptomEnteringPage,
    handleCloseActionSheet,
  };
}
