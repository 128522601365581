import { Fragment } from "react";
import { IonContent, IonFooter, IonLabel, useIonViewWillEnter } from "@ionic/react";
import { KeyboardButton } from "./keyboardButton";
import btnDel from "../../assets/svgs/btn-delete.svg";
import styles from "./screenLocker.module.css";

interface PasscodeTemplateProps {
  title: string;
  description: string;
  passcode: string;
  setPasscode: React.Dispatch<React.SetStateAction<string>>;
  errorMsg?: string;
  onInputCompleted: (val: string) => void;
}

export function PasscodeTemplate({
  title,
  description,
  passcode,
  setPasscode,
  errorMsg,
  onInputCompleted,
}: PasscodeTemplateProps) {
  const handleInput = async (val?: string) => {
    if (val && passcode.length >= 4) {
      return;
    }

    if (!val) {
      setPasscode(passcode.substring(0, passcode.length - 1));
    } else {
      const newPass = `${passcode}${val}`;
      setPasscode(newPass);

      if (newPass.length === 4) {
        onInputCompleted(newPass);
      }
    }
  };

  useIonViewWillEnter(() => {
    setPasscode("");
  });

  return (
    <>
      <IonContent>
        <div className={`root-content ${styles.content}`}>
          <div className="page-spacer-45" />

          <IonLabel className="p-header2 text-center-block">{title}</IonLabel>

          <div className="page-spacer-15" />

          <IonLabel className="p-body text-center-block pre-wrap">{description}</IonLabel>

          {errorMsg ? (
            <>
              <div className="page-spacer-5" />
              <IonLabel className="p-page-label-error-block pre-wrap">{errorMsg}</IonLabel>
              <div className="page-spacer-45" />
            </>
          ) : (
            <div className="page-spacer-90" />
          )}

          <div className="div_center">
            {Array.from(Array(4)).map((_, index) => (
              <Fragment key={index}>
                {index < passcode.length ? (
                  <div className={`${styles.dot} ${styles.pink}`} />
                ) : (
                  <div className={styles.dot} />
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </IonContent>

      <IonFooter className="ion-no-border ">
        <div className={styles.keyboardWrapper}>
          {Array.from(Array(9)).map((_, index) => (
            <KeyboardButton key={index} onClick={() => handleInput(`${index + 1}`)}>
              {index + 1}
            </KeyboardButton>
          ))}

          <div />
          <KeyboardButton onClick={() => handleInput(`0`)}>0</KeyboardButton>
          <div className={`div_center ${styles.deleteBtn}`} onClick={() => handleInput()}>
            <img src={btnDel} />
          </div>

          <div className="page-spacer-45" />
        </div>
      </IonFooter>
    </>
  );
}
