import { IonPage } from "@ionic/react";
import {
  AppHeader,
  AppHomeIndicatorBar,
  AppLoading,
  AppContent,
} from "../../../components/UiComponents";
import { AppMarkdown } from "../../../components/AppMarkdown";
import { useSupportAnnouncementDetailControl } from "./hooks/useSupportAnnouncementDetailControl";
import { PageTitle } from "src/web/components/UiComponents/moleclues";

export function SupportAnnouncementDetail() {
  const { isLoading, info } = useSupportAnnouncementDetailControl();

  return (
    <>
      <IonPage>
        <AppHeader />

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <PageTitle title={info?.title || ""} subTitle={info?.update_time} />

            <div className="page-spacer-15" />

            <AppMarkdown>{info?.content}</AppMarkdown>

            <AppHomeIndicatorBar />
          </div>
        </AppContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
