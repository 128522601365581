import { AppArrowSwitcher } from "../../AppArrowSwitcher/appArrowSwitcher";
import { IonItem } from "@ionic/react";
import { RecordItem } from "src/web/components/RecordItem";
import styles from "./dateSwitcherWithSummaryCard.module.css";

interface DateSwitcherWithSummaryCardProps {
  text: string;
  isDisableNextDay: boolean;
  currentDate: string;
  summary_score: { [name: string]: any };
  handleLeftClick: () => void;
  handleRightClick: () => void;
  handleLabelClick: () => void;
}

export function DateSwitcherWithSummaryCard({
  text,
  isDisableNextDay,
  currentDate,
  summary_score,
  handleLeftClick,
  handleRightClick,
  handleLabelClick,
}: DateSwitcherWithSummaryCardProps) {
  return (
    <>
      <AppArrowSwitcher
        text={text}
        handleLeftClick={handleLeftClick}
        handleRightClick={handleRightClick}
        disableRight={isDisableNextDay}
        handleLabelClick={handleLabelClick}
      />

      <div className="page-spacer-15" />

      <IonItem className={styles.reportItem} lines="none">
        <RecordItem
          contentData={{
            check_date: currentDate,
            summary_score: summary_score,
          }}
        />
      </IonItem>
    </>
  );
}
