export default class actionConst {
  static UPDATE_SYMPTOM_ANSWER = "UPDATE_SYMPTOM_ANSWER";
  static RESET_SYMPTOM_ANSWER = "RESET_SYMPTOM_ANSWER";

  static UPDATE_SYMPTOM_QUESTIONS = "UPDATE_SYMPTOM_QUESTIONS";

  static CHANGE_SPHYGMOMANOMETER = "CHANGE_SPHYGMOMANOMETER";

  static UPDATE_DEVICE_RECORDS = "UPDATE_DEVICE_RECORDS";
  static RESET_DEVICE_RECORDS = "RESET_DEVICE_RECORDS";

  static BACK_HISTORY = "BACK_HISTORY";

  static CLEAR_MEDICINE_INFO = "CLEAR_MEDICINE_INFO";
  static UPDATE_MEDICINE_INFO = "UPDATE_MEDICINE_INFO";

  static CLEAR_PRESCRIPTION_INFO = "CLEAR_PRESCRIPTION_INFO";
  static UPDATE_PRESCRIPTION_INFO = "UPDATE_PRESCRIPTION_INFO ";

  static CLEAR_REFRESH_INFO = "CLEAR_REFRESH_INFO";
  static UPDATE_REFRESH_INFO = "UPDATE_REFRESH_INFO";

  static CLEAR_GRAPH_INFO = "CLEAR_GRAPH_INFO";
  static UPDATE_GRAPH_INFO = "UPDATE_GRAPH_INFO";

  static UPDATE_ALARM_SCHEDULE = "UPDATE_ALARM_SCHEDULE";

  static UPDATE_DAILY_ALARM_REDIRECT = "UPDATE_DAILY_ALARM_REDIRECT";
  static CLEAR_DAILY_ALARM_REDIRECT = "CLEAR_DAILY_ALARM_REDIRECT";

  static UPDATE_KEYBOARD_OPEN_STATUS = "UPDATE_KEYBOARD_OPEN_STATUS";

  static UPDATE_CAPACITY_KEYBOARD_HEIGHT = "UPDATE_CAPACITY_KEYBOARD_HEIGHT";

  static UPDATE_PAUSE_PASSCODE_STATUS = "UPDATE_PAUSE_PASSCODE_STATUS";

  static CLEAR_ALERT_INFO = "CLEAR_ALERT_INFO";
  static UPDATE_ALERT_INFO = "UPDATE_ERROR_INFO";

  static UPDATE_SHOW_HEADER_BORDER = "UPDATE_SHOW_HEADER_BORDER";

  static CLEAR_DAILY_ACTIVITY_TIMELINE = "CLEAR_DAILY_ACTIVITY_TIMELINE";
  static UPDATE_DAILY_ACTIVITY_TIMELINE = "UPDATE_DAILY_ACTIVITY_TIMELINE";

  static UPDATE_DAILY_ANSWERS_AND_STATUS = "UPDATE_DAILY_ANSWERS_AND_STATUS";

  static CLEAR_SYMPTOM_SETTING = "CLEAR_SYMPTOM_SETTING";
  static UPDATE_SYMPTOM_SETTING = "UPDATE_SYMPTOM_SETTING";

  static CLEAR_TIMELINE_SCROLL_HOOK = "CLEAR_TIMELINE_SCROLL_HOOK";
  static UPDATE_TIMELINE_SCROLL_HOOK = "UPDATE_TIMELINE_SCROLL_HOOK";

  static UPDATE_SCORE_TRANSITION_WEEK_CHART = "UPDATE_SCORE_TRANSITION_WEEK_CHART";
  static UPDATE_SCORE_TRANSITION_MONTH_CHART = "UPDATE_SCORE_TRANSITION_MONTH_CHART";

  static USER_START_DATE = "USER_START_DATE";
  static CLEAR_LOADING_DISPLAY = "CLEAR_LOADING";
  static UPDATE_LOADING_DISPLAY = "UPDATE_LOADING";

  static UPDATE_AVG_CURRENT_SCORE = "UPDATE_AVG_CURRENT_SCORE";

  static CLEAR_NEW_USER_EMAIL_ADDRESS = "CLEAR_NEW_USER_EMAIL_ADDRESS";
  static UPDATE_NEW_USER_EMAIL_ADDRESS = "UPDATE_NEW_USER_EMAIL_ADDRESS";

  static CLEAR_CONTACT = "CLEAR_CONTACT";
  static UPDATE_CONTACT = "UPDATE_CONTACT";
}
