import { useEffect, useState } from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import { AppMedicineImage } from "../../../../components/UiComponents";
import styles from "./imgSlider.module.css";

export function ImgSlider({ imageList }: { imageList: string[] }) {
  const slideOpts = {
    // loop: true,
    centeredSlides: false,
  };

  const [list, setList] = useState([""]);

  useEffect(() => {
    if (imageList && imageList.length) {
      setList(imageList);
    }
  }, [imageList]);

  return (
    <IonSlides
      pager={list && list.length > 1}
      options={slideOpts}
      className={`medicine-img-sliders ${styles.sliders}`}
    >
      {list.map((image, index) => (
        <IonSlide className={styles.slider} key={index}>
          <AppMedicineImage src={image} size="XLarge" hideFrame={true} />
        </IonSlide>
      ))}
    </IonSlides>
  );
}
