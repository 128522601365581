import { useEffect } from "react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { useQueryString } from "src/web/hooks/useQueryString";

export function useContactCompleteControl() {
  const iRouter = useAppRouter();
  const { parseQs } = useQueryString();

  // 問い合わせ番号
  const params = parseQs(iRouter.routeInfo.search);

  const handleFinish = () => {
    iRouter.push("/home/menu");
  };

  useEffect(() => {
    const handleNativeBack = (ev: any) => {
      ev.detail.register(11, () => {
        iRouter.push("/home/menu");
      });
    };
    document.addEventListener("ionBackButton", handleNativeBack);

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, []);

  return {
    no: params.no,
    handleFinish,
  };
}
