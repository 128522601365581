import { useState, forwardRef, useImperativeHandle } from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import { SelectOption } from "../../../types";
import styles from "./appPicker.module.css";

interface AppPickerProps {
  initVal?: any;
  options: SelectOption[];
}

export const AppPicker = forwardRef(({ initVal, options }: AppPickerProps, ref: any) => {
  useImperativeHandle(ref, () => ({
    getSelectedData: () => getSelectedData(),
  }));

  const initValIndex = initVal ? options.findIndex((opt) => opt.value === initVal) : 0;

  const slideOpts = {
    direction: "vertical",
    slidesPerView: 7,
    centeredSlides: true,
    spaceBetween: 7,
    initialSlide: initValIndex,
  };

  const [current, setCurrent] = useState<number>(initValIndex);

  const getSelectedData = () => {
    return options[current]?.value;
  };

  return (
    <div className={`app-picker ${styles.pickerRoot}`}>
      <IonSlides
        options={slideOpts}
        className={styles.pickerWrapper}
        onIonSlideDidChange={(e: any) => setCurrent(e.target.swiper.realIndex)}
      >
        {options.map((opt: SelectOption) => (
          <IonSlide className={styles.pickerItem} key={opt.value}>
            {opt.text}
          </IonSlide>
        ))}
      </IonSlides>
    </div>
  );
});
