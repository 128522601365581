import { useDispatch } from "react-redux";
import HealthCareManager from "src/web/plugin/healthcare-manager-plugin";
import { getReportAnswerByDate, saveBloodPressure } from "src/web/services";
import { formatTime } from "src/web/utils/common";
import { Storage } from "@capacitor/storage";
import { readBloodPressureDateFromDevice } from "src/web/utils/common";
import { LocalStorageKey } from "src/web/constants";
import {
  AlertType,
  BloodPressureDayDataType,
  BloodPressureRequestType,
  BloodPressureType,
  DeviceType,
} from "src/web/types";
import { updateAlertInfo } from "src/web/actions";

export function useBloodPressureModel() {
  const dispatch = useDispatch();

  const fetchBloodPressure = async (
    data: BloodPressureDayDataType,
    currentDate: string,
    device: DeviceType,
  ) => {
    let formData = { ...data };
    let isPairSuccess = false;

    try {
      const { check_content_display } = await getReportAnswerByDate({
        currentDate,
      });
      const bpInfo = check_content_display.c.bp;
      if (bpInfo.am) {
        bpInfo.am.time = formatTime(bpInfo.am.time, true);
      }
      if (bpInfo.pm) {
        bpInfo.pm.time = formatTime(bpInfo.pm.time, true);
      }
      formData = {
        ...formData,
        ...bpInfo,
      };
    } catch (_) {}

    if (device?.deviceId) {
      try {
        const devicePairingRes = await HealthCareManager.checkDevicePairing({
          deviceId: device.deviceId,
        });
        isPairSuccess = !!devicePairingRes.result && devicePairingRes.result === "OK";
        if (isPairSuccess) {
          // merge the device data if any
          const deviceRecordStr = await (
            await Storage.get({ key: LocalStorageKey.BLOOD_PRESSURE_DEVICE_DATA })
          ).value;
          if (deviceRecordStr) {
            const deviceRecords = JSON.parse(deviceRecordStr);
            const filteredRecord = readBloodPressureDateFromDevice(deviceRecords, currentDate);
            // clear the device record after read
            await Storage.set({ key: LocalStorageKey.BLOOD_PRESSURE_DEVICE_DATA, value: "" });

            if (formData.am.isNewInput && filteredRecord.am) {
              formData.am.deviceFlg = true;
              formData.am.time = filteredRecord.am.time;
              formData.am.maxBloodPressure = filteredRecord.am.systolic;
              formData.am.minBloodPressure = filteredRecord.am.diastolic;
              formData.am.heartRate = filteredRecord.am.pulseRate;
            }
            if (formData.pm.isNewInput && filteredRecord.pm) {
              formData.pm.deviceFlg = true;
              formData.pm.time = filteredRecord.pm.time;
              formData.pm.maxBloodPressure = filteredRecord.pm.systolic;
              formData.pm.minBloodPressure = filteredRecord.pm.diastolic;
              formData.pm.heartRate = filteredRecord.pm.pulseRate;
            }
          }
        }
      } catch (_) {}
    }

    return {
      data: formData,
      isPairing: isPairSuccess,
    };
  };

  const convertData = (bloodData: BloodPressureType) => {
    let ret: BloodPressureType = {
      deviceFlg: !!bloodData.deviceFlg,
      time: bloodData.time,
      maxBloodPressure: bloodData.maxBloodPressure,
      minBloodPressure: bloodData.minBloodPressure,
      heartRate: bloodData.heartRate,
      isNewInput: bloodData.isNewInput,
    };
    return ret;
  };

  const updateBloodPressure = async (formData: BloodPressureDayDataType, currentDate: string) => {
    const payload: BloodPressureRequestType = {
      currentDate,
      bp: {
        am:
          Object.keys(formData.am).length === 6 ||
          (!formData.am.isNewInput && Object.keys(formData.am).length === 5)
            ? convertData(formData.am)
            : undefined,
        pm:
          Object.keys(formData.pm).length === 6 ||
          (!formData.pm.isNewInput && Object.keys(formData.pm).length === 5)
            ? convertData(formData.pm)
            : undefined,
      },
    };

    try {
      await saveBloodPressure(payload);
    } catch (error: any) {
      dispatch(
        updateAlertInfo({
          status: AlertType.ERROR,
          msg: error.message.message,
        }),
      );
      throw error;
    }
  };

  return {
    fetchBloodPressure,
    updateBloodPressure,
  };
}
