import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateAlertInfo } from "src/web/actions";
import { useTextConstants } from "src/web/constants/hooks";
import { getUserInfo, updateAndInsertUserInfo, updateUserInfo } from "src/web/services";
import { AlertType, UserBaseInfoType } from "src/web/types";
import { getToday } from "src/web/utils/common";

export function useUserBaseInfoModel() {
  const {
    GENDER_OPTIONS,
    PREFECTURE_OPTIONS
  } = useTextConstants();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const fetchUserBaseInfo = async () => {
    try {
      setIsLoading(true);
      const userInfoRes: any = await getUserInfo();
      return userInfoRes;
    } catch (error) {
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserBaseInfoWithDesc = async () => {
    try {
      setIsLoading(true);
      const userInfoRes: any = await getUserInfo();
      userInfoRes.user_name = userInfoRes.user_name || "";
      userInfoRes.birthday = userInfoRes.birthday || "";
      if (userInfoRes.gender) {
        const genderText = GENDER_OPTIONS.find((opt) => opt.value === userInfoRes.gender);
        userInfoRes.genderDesc = genderText ? genderText.text : "";
      }
      if (userInfoRes.prefecture) {
        const prefectureText = PREFECTURE_OPTIONS.find(
          (opt) => opt.value === userInfoRes.prefecture,
        );
        userInfoRes.prefectureDesc = prefectureText ? prefectureText.text : "";
      }
      return userInfoRes;
    } catch (error) {
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const addOrEditUserBaseInfo = async (data: any) => {
    const payload: UserBaseInfoType = {
      ...data,
      startDate: data.startDate ?? getToday(),
    };

    try {
      setIsLoading(true);
      await updateAndInsertUserInfo(payload);
    } catch (error: any) {
      dispatch(
        updateAlertInfo({
          status: AlertType.ERROR,
          msg: error.message.message,
        }),
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const editUserBaseInfo = async (type: string, value: string) => {
    try {
      setIsLoading(true);
      await updateUserInfo({
        [type]: value,
      });
    } catch (error: any) {
      dispatch(
        updateAlertInfo({
          status: AlertType.ERROR,
          msg: error.message.message,
        }),
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    fetchUserBaseInfo,
    fetchUserBaseInfoWithDesc,
    addOrEditUserBaseInfo,
    editUserBaseInfo,
  };
}
