import { IonLabel } from "@ionic/react";
import { generateBlankEmptyOptions, getDateStrByDateNoPad } from "../../../../utils/common";
import {
  MEDICINE_CATEGORY,
  MEDICINE_TYPE,
} from "../../../../constants";
import {
  MedicineInfoType,
  MedicineInfoTimingType,
  MedicineTimeingType,
} from "src/web/types";
import { SelectOption } from "../../../../types";
import styles from "./schedule.module.css";
import { AppMedicineDetail1 } from "../../../../components/UiComponents/moleclues";
import { useIntl } from "react-intl-phraseapp";
import { useCommon } from "src/web/utils/hooks/common";
import { useTextConstants } from "src/web/constants/hooks";

interface ScheduleProps {
  data?: MedicineInfoType;
  // コピーリストのスタイルを追加します。
  isCopyList?: boolean;
}

const SubTitleLine = ({ title }: { title: string }) => {
  return (
    <>
      <IonLabel className="p-body">{title}</IonLabel>
      <div className="page-spacer-15" />
    </>
  );
};

export function Schedule({ data, isCopyList = false }: ScheduleProps) {
  const intl = useIntl();
  const {
    NOT_INPUTED,
    NOT_TEXT,
    EMPTY_OPTION_TEXT,
    MEDICINE_QUANTITY_UNIT,
    MEDICINE_INFO_RULES_OF_USE_OPTION,
    MEDICINE_TIMING_OPTIONS,
    MEDICINE_TIMING_UNIT_OPTIONS
  } = useTextConstants();
  const { formatFrequencyText } = useCommon();
  // 日常薬 or 頓用薬 or その他の薬
  const checkShapeType = (shapeCode?: string) => {
    switch (shapeCode) {
      case "01": // 内服
      case "04": // 注射
      case "05": // 外用
        return MEDICINE_CATEGORY.EVERYDAY_MEDICINE;
      case "02": // 内滴
      case "03": // 頓服
        return MEDICINE_CATEGORY.COUNTER_MEDICINE;
      default:
        return MEDICINE_CATEGORY.OTHERS;
    }
  };

  // 服薬タイミング
  const formatTimingTextLines = (timings: MedicineInfoTimingType[]) => {
    const formatTimingText = (data: MedicineInfoTimingType) => {
      const timingOptions = generateBlankEmptyOptions(MEDICINE_TIMING_OPTIONS, true);
      const timingTypeItem: SelectOption = timingOptions.find(
        (opt) => opt.value === data.medication_timing?.type,
      );
      if (data.quntity) {
        const timingUnitItem: any = MEDICINE_TIMING_UNIT_OPTIONS.find(
          (opt) => opt.value === data.unit,
        );
        // 13: 時刻...
        return `${
          timingTypeItem
            ? timingTypeItem.value !== "13"
              ? timingTypeItem.text
              : data.medication_timing?.time
            : EMPTY_OPTION_TEXT
        } / ${data.quntity} ${timingUnitItem ? timingUnitItem.text : ""}`;
      } else {
        return timingTypeItem ? timingTypeItem.text : EMPTY_OPTION_TEXT;
      }
    };

    // sort the list by following the options sort
    let sortedList = timings.filter(
      (timing: MedicineInfoTimingType) => !timing.medication_timing?.type,
    );

    const sortbyList = generateBlankEmptyOptions(MEDICINE_TIMING_OPTIONS, true);
    sortbyList.forEach((sortBy: SelectOption) => {
      const resultList = timings.filter(
        (timing: MedicineInfoTimingType) => timing.medication_timing?.type === sortBy.value,
      );
      sortedList = [...sortedList, ...resultList];
    });

    if (sortedList.length) {
      return sortedList.map((timing: MedicineInfoTimingType, index: number) => (
        <>
          <IonLabel className="p-smallbody">{formatTimingText(timing)}</IonLabel>
          <br />
        </>
      ));
    } else {
      return <IonLabel className="p-smallbody">{NOT_INPUTED}</IonLabel>;
    }
  };

  // 用法
  const formatUsage = (usage?: string) => {
    if (usage) {
      const usageItem = MEDICINE_INFO_RULES_OF_USE_OPTION.find((opt) => opt.value === usage);
      return usageItem ? usageItem.text : "";
    } else {
      return NOT_INPUTED;
    }
  };

  // 1回あたりの服用量
  const formatOnceQuantity = ({ quantity, unit }: { quantity: number; unit?: string }) => {
    if (quantity && unit) {
      const unitItem = MEDICINE_QUANTITY_UNIT.find((opt) => opt.value === unit);
      return `${quantity} ${unitItem ? unitItem.text : ""}`;
    } else {
      return NOT_INPUTED;
    }
  };

  // 調剤数量
  const formatDispensingQuantity = (data?: MedicineInfoType) => {
    if (data && (data.dispensing_quantity || data.dispensing_quantity === 0)) {
      const unitItem = MEDICINE_QUANTITY_UNIT.find(
        (opt) => opt.value === data.dispensing_quantity_unit,
      );
      return `${data.dispensing_quantity} ${unitItem ? unitItem.text : ""}`;
    } else {
      return NOT_INPUTED;
    }
  };

  // 薬の残量
  const formatRemainingQuantity = (data?: MedicineInfoType) => {
    if (data && (data.dispensing_quantity || data.dispensing_quantity === 0)) {
      const unitItem = MEDICINE_QUANTITY_UNIT.find(
        (opt) => opt.value === data.dispensing_quantity_unit,
      );
      return `${
        data.remaining_quantity || data.remaining_quantity === 0
          ? data.remaining_quantity
          : data.dispensing_quantity
      } / ${data.dispensing_quantity} ${unitItem ? unitItem.text : ""}`;
    } else {
      return NOT_INPUTED;
    }
  };

  // 開始日時 or 終了日時
  const formatDate = ({
    theDate,
    theTime,
  }: {
    theDate?: string;
    theTime?: MedicineTimeingType;
  }) => {
    let outputStr = NOT_TEXT;
    if (theDate) {
      outputStr = getDateStrByDateNoPad(new Date(theDate));
      if (theTime) {
        const options = generateBlankEmptyOptions(MEDICINE_TIMING_OPTIONS, true);
        const timeItem: SelectOption = options.find(
          (opt: SelectOption) => opt.value === theTime?.type,
        );
        if (timeItem) {
          // 13: 時刻...
          const outputTimeStr = timeItem.value !== "13" ? timeItem.text : theTime.time;
          return (
            <>
              <IonLabel className="p-smallbody">{outputStr}</IonLabel>
              <IonLabel className={`p-smallbody ${styles.time}`}>{outputTimeStr}</IonLabel>
            </>
          );
        }
      }
    }
    return <IonLabel className="p-smallbody">{outputStr}</IonLabel>;
  };

  return (
    <div>
      {checkShapeType(data?.m_shape_code) === MEDICINE_CATEGORY.EVERYDAY_MEDICINE ? (
        <>
          {/* 日常薬 */}
          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.frequency",
            })}
          >
            {data?.frequency ? formatFrequencyText(data?.frequency) : NOT_INPUTED}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />

          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.dosage_num",
            })}
          >
            {data?.dosage_num
              ? `${data?.dosage_num} ${intl.formatMessage({
                  id: "page.medicine_information_details.schedule.label.dosage_num.unit",
                })}`
              : NOT_INPUTED}{" "}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />

          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.medication_timing",
            })}
          >
            {!!data && !!data.medication_timings && data.medication_timings.length > 0
              ? formatTimingTextLines(data.medication_timings)
              : NOT_INPUTED}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />
        </>
      ) : checkShapeType(data?.m_shape_code) === MEDICINE_CATEGORY.COUNTER_MEDICINE ? (
        <>
          {/* 頓用薬 */}
          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.one_dayquantity",
            })}
          >
            {data?.one_day_quantity
              ? `${data?.one_day_quantity}  ${intl.formatMessage({
                  id: "page.medicine_information_details.schedule.label.one_dayquantity.unit",
                })}`
              : NOT_INPUTED}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />

          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.gap_time",
            })}
          >
            {data?.gap_time
              ? `${data?.gap_time}  ${intl.formatMessage({
                  id: "page.medicine_information_details.schedule.label.gap_time.unit",
                })}`
              : NOT_INPUTED}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />

          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.usage",
            })}
          >
            {data?.usage ? formatUsage(data?.usage) : NOT_INPUTED}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />

          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.once_quantity",
            })}
          >
            {data?.once_quantity
              ? formatOnceQuantity({
                  quantity: data?.once_quantity,
                  unit: data?.once_quantity_unit,
                })
              : NOT_INPUTED}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />
        </>
      ) : (
        <></>
      )}

      {/* コピーリスト画面の場合、残量と開始日時と終了日時は表示しない */}
      {!isCopyList ? (
        <>
          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.remaining_quantity",
            })}
          >
            {formatRemainingQuantity(data)}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />

          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.start_date",
            })}
          >
            {formatDate({
              theDate: data?.start_date,
              theTime: data?.start_time,
            })}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />

          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.end_date",
            })}
          >
            {formatDate({
              theDate: data?.end_date,
              theTime: data?.end_time,
            })}
          </AppMedicineDetail1>

          <div className="page-spacer-45" />
        </>
      ) : (
        <>
          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.dispensing_quantity",
            })}
          >
            {formatDispensingQuantity(data)}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />
        </>
      )}

      {/* 判断項目を修正します。 */}
      {data?.type === MEDICINE_TYPE.PRESCRIPTION && (
        <>
          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.clinical_department",
            })}
          >
            {data?.clinical_department ? data?.clinical_department : NOT_INPUTED}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />

          <AppMedicineDetail1
            title={intl.formatMessage({
              id: "page.medicine_information_details.schedule.label.prescribing_doctor",
            })}
          >
            {data?.prescribing_doctor ? data?.prescribing_doctor : NOT_INPUTED}
          </AppMedicineDetail1>
          <div className="page-spacer-45" />
        </>
      )}

      <AppMedicineDetail1
        title={intl.formatMessage({ id: "page.medicine_information_details.schedule.label.memos" })}
      >
        {data?.memos ? data?.memos : NOT_INPUTED}
      </AppMedicineDetail1>
    </div>
  );
}
