import { combineReducers } from "redux";
import symptomQuestionsReducer from "./symptomQuestionsReducer";
import sphygmomanometerReducer from "./sphygmomanometerReduce";
import medicineInformationReducer from "./medicineInfoReducer";
import prescriptionInformationReducer from "./prescriptionInfoReducer";
import refreshInfoReducer from "./refreshInfoReducer";
import alarmScheduleReducer from "./alarmScheduleReducer";
import graphInfoReducer from "./graphInfoReducer";
import dailyAlarmRedirectReducer from "./dailyAlarmRedirectReducer";
import capacityKeyboardHeightReducer from "./capacityKeyboardHeightReducer";
import pausePasscodeReducer from "./pausePasscodeReducer";
import showHeaderBoarderReducer from "./showHeaderBoarderReducer";
import alertInfoReducer from "./alertInfoReducer";
import dailyActivityTimelineReducer from "./dailyActivityTimelineReducer";
import dailyAnswersAndStatusReducer from "./dailyAnswersAndStatusReducer";
import symptomSettingReducer from "./symptomSettingReducer";
import timelineScrollHookReducer from "./timelineScrollHookReducer";
import scoreTransitionWeekChartReducer from "./scoreTransitionWeekChartReducer";
import scoreTransitionMonthChartReducer from "./scoreTransitionMonthChartReducer";
import userStartDateReducer from "./userStartDateReducer";
import loadingDisplayReducer from "./loadingDisplayReducer";
import avgCurrentScoreReducer from "./avgCurrentScoreReducer";
import newUserEmailAddressReducer from "./newUserEmailAddressReducer";
import contactReducer from "./contactReducer";

export const rootReducers = combineReducers({
  symptomQuestions: symptomQuestionsReducer,
  sphygmomanometer: sphygmomanometerReducer,
  medicineInformation: medicineInformationReducer,
  prescriptionInformation: prescriptionInformationReducer,
  refreshInfo: refreshInfoReducer,
  alarmSchedule: alarmScheduleReducer,
  graphInfo: graphInfoReducer,
  dailyAlarmRedirect: dailyAlarmRedirectReducer,
  capacityKeyboardHeight: capacityKeyboardHeightReducer,
  isPasscodePausedForUsingCamera: pausePasscodeReducer,
  alertInfo: alertInfoReducer,
  isShowHeaderBoarder: showHeaderBoarderReducer,
  dailyActivityTimeline: dailyActivityTimelineReducer,
  dailyAnswersAndStatus: dailyAnswersAndStatusReducer,
  symptomSetting: symptomSettingReducer,
  timelineScrollHook: timelineScrollHookReducer,
  scoreTransitionWeekChart: scoreTransitionWeekChartReducer,
  scoreTransitionMonthChart: scoreTransitionMonthChartReducer,
  userStartDate: userStartDateReducer,
  loadingDisplay: loadingDisplayReducer,
  avgCurrentScore: avgCurrentScoreReducer,
  newUserEmailAddress: newUserEmailAddressReducer,
  contact: contactReducer,
});

export type RootState = ReturnType<typeof rootReducers>;
