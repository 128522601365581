import { useState, useEffect } from "react";
import { AppLoading, AppCalendar, AppFixedActionSheet } from "../../../components/UiComponents";
import { DATE_FORMAT_SPLIT } from "../../../constants";
// import { getCheckMedicinesMonth } from "../../../services";
import { MonthCheckedType } from "./types";

interface AppCalendarFullStatusCommonSheetProps {
  isOpen: boolean;
  calendarDateAvailableFrom?: string;
  currentDate: string;
  handleClose: () => void;
  handleConfirm: (val: string) => void;
  doFetch: (data: any) => any;
  refactRes: (res: any) => any;
  isFutureClickable?: boolean;
  isLineSelectRequired?: boolean;
}

export function AppCalendarFullStatusCommonSheet({
  isOpen,
  calendarDateAvailableFrom,
  currentDate,
  handleClose,
  handleConfirm,
  doFetch,
  refactRes,
  isFutureClickable = false,
  isLineSelectRequired = false,
}: AppCalendarFullStatusCommonSheetProps) {
  let dateArr = currentDate.split(DATE_FORMAT_SPLIT);
  dateArr[2] = "01";

  const [isLoading, setIsLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState<string>(dateArr.join(DATE_FORMAT_SPLIT));
  const [calendarData, setCalendarData] = useState<MonthCheckedType[]>();
  const [selectedDate, setSelectedDate] = useState<string>(currentDate);

  const doFetchDate = async (date: string) => {
    if (currentMonth) {
      setIsLoading(true);
      try {
        const res = await doFetch(date);

        setCalendarData(refactRes(res));

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      doFetchDate(currentMonth);
    }
  }, [currentMonth, isOpen]);

  useEffect(() => {
    if (isOpen) {
      let dateArr = currentDate.split(DATE_FORMAT_SPLIT);
      dateArr[2] = "01";
      setCurrentMonth(dateArr.join(DATE_FORMAT_SPLIT));
      setSelectedDate(currentDate);
    }
  }, [currentDate, isOpen]);

  return (
    <AppFixedActionSheet
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={() => handleConfirm(selectedDate)}
      title=" "
      sheetHeight={600}
    >
      <AppCalendar
        currentMonth={currentMonth}
        setCurrentMonth={setCurrentMonth}
        selectedDate={selectedDate}
        handleSelectDateChange={(val: string) => {
          setSelectedDate(val);
        }}
        calendarData={calendarData}
        isAllClickable={true}
        showSchedulePoints={true}
        isFutureSelectable={isFutureClickable}
        calendarDateAvailableFrom={calendarDateAvailableFrom}
        isLineSelectRequired={isLineSelectRequired}
      />

      <AppLoading isOpen={isLoading} />
    </AppFixedActionSheet>
  );
}
