import React, { createRef } from "react";
import {
    IonModal,
} from '@ionic/react';
// import { PluginListenerHandle } from '@capacitor/core';
// import { Motion } from '@capacitor/motion';
import styles from "./styles.module.css";
// import { getWindowDimensions } from "../../../utils/common";

interface Video {
    src: string;
    // onClick?:()=>void;
}

// let motionHandler: PluginListenerHandle;

export class AppVideo extends React.Component<Video>  {
    videoContainer: React.RefObject<HTMLDivElement>;
    videoControl: React.RefObject<HTMLVideoElement>;
    modeControl: React.RefObject<HTMLIonModalElement>;
    defaultDirection:number;
    play = () => {
        let element = this.modeControl.current;
        if (!element) {
            return;
        }
        element.isOpen = true;
    }

    stop = () => {

        let videoCtrl = this.videoControl.current;

        if (!videoCtrl) {
            return;
        }
        videoCtrl.pause();
        videoCtrl.currentTime = 0;


        let element = this.modeControl.current;
        if (!element) {
            return;
        }

        if (!element.isOpen) {
            return;
        }

        element.isOpen = false;

    }

    constructor(props: Video) {
        super(props);
        // this.state = props;
        this.videoContainer = createRef();
        this.videoControl = createRef();
        this.modeControl = createRef();
        this.defaultDirection = 0;
    }

    // rotateScreen = ()=>{
    //     console.log('Current orientation is ' + window.screen.orientation.type);
    // }

    async componentDidMount() {

        // window.screen.orientation.addEventListener('change', this.rotateScreen);

        // motionHandler = await Motion.addListener('orientation', event => {
        //     let videoCtrl = this.videoControl.current;

        //     if (!videoCtrl) {
        //         return;
        //     }

        //     if (event.beta === null) {
        //         return;
        //     }

        //     // 垂直の方向
        //     if (event.beta > 45 && event.beta < 135) {
        //         if (this.defaultDirection === 0) {
        //             return;
        //         }
        //         this.defaultDirection = 0;
        //         videoCtrl.style.transform = '';
        //         videoCtrl.style.width = `${getWindowDimensions().width}px`;
        //         videoCtrl.style.height = `${getWindowDimensions().height}px`;
        //         videoCtrl.style.marginLeft = "0px";
        //         videoCtrl.style.marginTop = "0px";
        //         console.log(videoCtrl.style.transform);
        //         console.log("rotate to 0 new");
        //         return;
        //     // 右の方向
        //     } else if (event.beta <= 45 && event.beta >= -45) {
        //         if (this.defaultDirection === 1) {
        //             return;
        //         }
        //         this.defaultDirection = 1;
        //         let marginValue = (getWindowDimensions().height - getWindowDimensions().width) / 2;
        //         videoCtrl.style.transform = 'rotate(270deg)';
        //         videoCtrl.style.width = `${getWindowDimensions().height}px`;
        //         videoCtrl.style.height = `${getWindowDimensions().width}px`;
        //         videoCtrl.style.marginLeft = `${-1 * marginValue}px`;
        //         videoCtrl.style.marginTop = `${marginValue}px`;
        //         console.log("rotate to 270 new");
        //     // 逆垂直の方向
        //     } else if (event.beta < -45 && event.beta > -135) {
        //         if (this.defaultDirection === 2) {
        //             return;
        //         }
        //         this.defaultDirection = 2
        //         videoCtrl.style.transform = 'rotate(180deg)';
        //         videoCtrl.style.width = `${getWindowDimensions().width}px`;
        //         videoCtrl.style.height = `${getWindowDimensions().height}px`;
        //         videoCtrl.style.marginLeft = "0px";
        //         videoCtrl.style.marginTop = "0px"; 
        //         console.log(videoCtrl.style.transform);
        //         console.log("rotate to 180 new");
        //     } else {
        //         if (this.defaultDirection === 3) {
        //             return;
        //         }
        //         this.defaultDirection = 3;
        //         let marginValue = (getWindowDimensions().height - getWindowDimensions().width) / 2;
        //         videoCtrl.style.transform = 'rotate(90deg)';
        //         videoCtrl.style.width = `${getWindowDimensions().height}px`;
        //         videoCtrl.style.height = `${getWindowDimensions().width}px`;
        //         videoCtrl.style.marginLeft = `${-1 * marginValue}px`;
        //         videoCtrl.style.marginTop = `${marginValue}px`;
        //         console.log("rotate to 90 new");
        //     }
        //     return;
        // });
    }

    componentWillUnmount() {
        // if (motionHandler) {
        //     motionHandler.remove();
        // }
    }

    render() {
        return (
            <IonModal ref={this.modeControl} className={styles.model} onDidPresent={() => {
                let videoCtrl = this.videoControl.current;

                if (!videoCtrl) {
                    return;
                }

                // let marginValue = (getWindowDimensions().height - getWindowDimensions().width) /2 ;

                // videoCtrl.style.transform = 'rotate(90deg)'; 
                // videoCtrl.style.width = `${getWindowDimensions().height}px`;
                // videoCtrl.style.height = `${getWindowDimensions().width}px`;
                // videoCtrl.style.marginLeft = `${-1*marginValue}px`;
                // videoCtrl.style.marginTop = `${marginValue}px`;

                videoCtrl.pause();
                videoCtrl.currentTime = 0;
                videoCtrl.play();
            }}>
                <div ref={this.videoContainer} className={styles.divContainer} onClick={() => {
                    this.stop();
                }}>
                    <video className={styles.video} ref={this.videoControl} src={this.props.src} onEnded={() => {
                        // this.stop();
                    }} />
                </div>
            </IonModal>
        );
    }
}
