import { useEffect, useState } from "react";
import { useIonAlert, isPlatform } from "@ionic/react";
import { ConfirmationResult, RecaptchaVerifier } from "firebase/auth";
import { analytics, auth } from "src/web/firebase";
import { useDispatch, useSelector } from "react-redux";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { useAppUpdater } from "src/web/hooks/useAppUpdater";
import { useQueryString } from "src/web/hooks/useQueryString";
import { RootState } from "src/web/reducer";
import { setUserId } from "firebase/analytics";
import { DailyAlarmRedirectType } from "src/web/types";
import { useLoginModel } from "src/web/hooks/models/useLoginModel";
import { useAlarm } from "src/web/utils/hooks/alarm";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { clearDailyAlarmRedirect } from "src/web/actions/updateDailyAlarmRedirectAction";


declare global {
  var recaptchaVerifier: RecaptchaVerifier;
  var confirmationResult: ConfirmationResult;
}

// put variable as the global variable to avoid render impact
let autoFilledPassword = "";

export function useLoginControl() {
  const router = useAppRouter();
  const [present] = useIonAlert();
  const dispatch = useDispatch();
  const { updateApp } = useAppUpdater();
  const { parseQs } = useQueryString();
  const { doSignIn, getUserStartDate } = useLoginModel();
  const { refreshAlarm } = useAlarm();

  const params = parseQs(router.routeInfo.search);

  // 全画面の情報を取得する
  const dailyAlarmRedirect: DailyAlarmRedirectType = useSelector(
    (state: RootState) => state.dailyAlarmRedirect,
  );

  const [userInfo, setUserInfo] = useState({
    userName: (params.email as string) ?? "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoginError, setHasLoginError] = useState(false);
  // const [disableLoginButton, setDisableLoginButton] = useState(false);

  const handleUserNameInputChange = (userName: string) => {
    setHasLoginError(false);
    setUserInfo({ ...userInfo, userName });
  };

  const handlePasswordInputChange = (password: string) => {
    setHasLoginError(false);
    setUserInfo({ ...userInfo, password });
  };

  const handleLogin = async () => {
    const currentPassword = autoFilledPassword || userInfo.password;
    if (!userInfo.userName || !currentPassword) {
      present({
        header: "Alert",
        message: "Please input userName or password",
        onDidDismiss: (e) => {
          global.console.log("did dismiss");
        },
      });
      return;
    }

    try {
      setIsLoading(true);

      // sign in firebase with username and password
      const user = await doSignIn(auth, userInfo.userName, currentPassword);
      if (!user) {
        throw new Error("");
      }
      // 設定id
      setUserId(analytics, user.uid);

      // get user start date
      const userBaseInfo = await getUserStartDate();
      if (!userBaseInfo?.startDate) {
        throw new Error("");
      }

      await refreshAlarm();
      setIsLoading(false);

      // update app version
      if (Capacitor.isNativePlatform()) {
        await updateApp();
      }
      // nav the page to input the user base infomation, if they were empty
      if (
        !userBaseInfo.userName ||
        !userBaseInfo.birthday ||
        !userBaseInfo.gender ||
        !userBaseInfo.prefecture
      ) {
        router.push("/create-user-base-info");
        return;
      }

      if (dailyAlarmRedirect.id) {
        dispatch(clearDailyAlarmRedirect());
        router.push(`/home/medication-record?time=${new Date()}`, "forward", "replace");
      } else {
        // await Storage.set({key:LocalStorageKey.LOGIN_EMAIL, value: JSON.stringify(userRecord.user.uid)});
        router.push("/home/main", "forward", "replace");
      }
    } catch (error) {
      setHasLoginError(true);
      setIsLoading(false);
    }
  };

  const toDisableLoginBtn = () => {
    return !userInfo.userName || (!userInfo.password && !autoFilledPassword);
  };

  useEffect(() => {
    // ログインボタンの初期化
    // setDisableLoginButton(!checkInput(userInfo.userName, userInfo.password));

    global.recaptchaVerifier = new RecaptchaVerifier(
      "verifierContainer",
      {
        size: "invisible",
        callback: (response: any) => {},
      },
      auth,
    );

    const handlePasswordAutoFill = (e: any) => {
      autoFilledPassword = (e.target as any).value;
      setHasLoginError(false);
      if (isPlatform("capacitor")) {
        setTimeout(() => {
          Keyboard.hide();
        }, 100);
      }
    };

    if (Capacitor.getPlatform() === "ios") {
      setTimeout(() => {
        try {
          document
            .getElementById("pwd")!
            .children[0].addEventListener("change", handlePasswordAutoFill);
        } catch {}
      }, 200); // Need some time for the ion-input to create the input element
    }

    // handle native back:
    const handleNativeBack = (ev: any) => {
      ev.detail.register(10, () => {});
    };
    // block native back click to return to any other page
    document.addEventListener("ionBackButton", handleNativeBack);

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
      document
        .getElementById("pwd")!
        .children[0].removeEventListener("change", handlePasswordAutoFill);
    };
  }, []);

  useEffect(() => {
    if (params?.email) {
      setUserInfo({
        userName: params.email as string,
        password: "",
      });
    }
  }, [params?.email]);

  return {
    isEmailFromUrl: !!params?.email,
    userInfo,
    isLoading,
    hasLoginError,
    isLoginBtnDisabled: toDisableLoginBtn(),
    handleUserNameInputChange,
    handlePasswordInputChange,
    handleLogin,
  };
}
