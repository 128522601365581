import { IonLabel } from "@ionic/react";
import { ICONS } from "../../../constants";
import { AppIcon } from "../AppIcon/appIcon";
import { AppFixedActionSheet } from "./appFixedActionSheet";
import { useIntl } from "react-intl-phraseapp";

interface AppActionSheetForPhotoGallerySelectProps {
  isOpen: boolean;
  handleClose: () => void;
  handleTakePhoto: () => void;
  handleTakeGallery: () => void;
}

export function AppActionSheetForPhotoGallerySelect({
  isOpen,
  handleClose,
  handleTakePhoto,
  handleTakeGallery,
}: AppActionSheetForPhotoGallerySelectProps) {
  const intl = useIntl();
  return (
    <AppFixedActionSheet
      isOpen={isOpen}
      sheetHeight={260}
      title={intl.formatMessage({
        id: "ui_components.app_action_sheet_for_photo_gallery_select.register_photo",
      })}
      handleClose={handleClose}
      handleConfirm={handleClose}
    >
      <div
        className="flex-row-between"
        onClick={() => {
          handleTakePhoto();
          handleClose();
        }}
      >
        <IonLabel className="p-body">
          {intl.formatMessage({
            id: "ui_components.app_action_sheet_for_photo_gallery_select.take_photo",
          })}
        </IonLabel>

        <AppIcon icon={ICONS.ICON.ARROW_FORWARD} size="sm" />
      </div>

      <div className="page-spacer-30" />

      <div
        className="flex-row-between"
        onClick={() => {
          handleTakeGallery();
          handleClose();
        }}
      >
        <IonLabel className="p-body">
          {intl.formatMessage({
            id: "ui_components.app_action_sheet_for_photo_gallery_select.select_photo",
          })}
        </IonLabel>

        <AppIcon icon={ICONS.ICON.ARROW_FORWARD} size="sm" />
      </div>
    </AppFixedActionSheet>
  );
}
