import { useState } from "react";
import { useAppRouter } from "../../hooks/useAppRouter";
import { useQueryString } from "src/web/hooks/useQueryString";
import {
  IonContent,
  IonLabel,
  IonPage,
  ScrollDetail,
  useIonAlert,
  useIonViewWillEnter,
} from "@ionic/react";
import { AppHeader, AppSecondaryButton } from "../../components/UiComponents";
import { Storage } from "@capacitor/storage";
import { storage } from "../../firebase";
import { LocalStorageKey } from "../../constants";
import { UserPhoto } from "../../hooks/usePhotoGallery";
import { deleteObject, getDownloadURL, ref } from "firebase/storage";
import { useIntl } from "react-intl-phraseapp";

export function PrescriptionBigImage() {
  const intl = useIntl();
  const [presentAlert] = useIonAlert();
  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  const localWebviewPath = params.webviewPath as string;
  const refFullPath = params.refFullPath as string;

  const [isDisplayBorder, setIsDisplayBorder] = useState(false);
  const [url, setUrl] = useState<string>();
  const [errorMsg, setErrorMsg] = useState("");

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    // setIsDisplayBorder(true);
    if (ev.detail.scrollTop === 0) {
      setIsDisplayBorder(false);
    } else {
      setIsDisplayBorder(true);
    }
  };

  const handleDelete = async () => {
    setErrorMsg("");
    // if the photo is a local temp photo, delete from storage
    // if the photo is online photo, delete it online
    if (localWebviewPath) {
      // is local temp photo
      const tmpPhotosStr = await (
        await Storage.get({ key: LocalStorageKey.PRESCRIPTION_LOCAL_PHOTOS })
      ).value;
      if (tmpPhotosStr) {
        const tmpPhotos: UserPhoto[] = JSON.parse(tmpPhotosStr);
        const index = tmpPhotos.findIndex((photo) => photo.webviewPath === localWebviewPath);
        if (index >= 0) {
          tmpPhotos.splice(index, 1);
          await Storage.set({
            key: LocalStorageKey.PRESCRIPTION_LOCAL_PHOTOS,
            value: JSON.stringify(tmpPhotos),
          });
        }
      }
      ionRouter.goBack();
    } else if (refFullPath) {
      const imageRef = ref(storage, refFullPath);
      deleteObject(imageRef)
        .then(() => {
          ionRouter.goBack();
        })
        .catch((error) => {
          setErrorMsg(
            intl.formatMessage({ id: "page.prescription_big_image.alert.error_message" }),
          );
        });
    }
  };

  useIonViewWillEnter(() => {
    if (refFullPath) {
      const imageRef = ref(storage, refFullPath);
      getDownloadURL(imageRef).then((url) => {
        setUrl(url);
      });
    }
  });

  return (
    <IonPage>
      <AppHeader isDisplayBorder={isDisplayBorder}>
        <AppSecondaryButton
          small={true}
          transparent={true}
          onClick={() => {
            presentAlert({
              header: intl.formatMessage({ id: "page.prescription_big_image.alert.title" }),
              mode: "ios",
              buttons: [
                {
                  text: intl.formatMessage({ id: "page.prescription_big_image.alert.cancel" }),
                  role: "cancel",
                  handler: () => {},
                },
                {
                  text: intl.formatMessage({ id: "page.prescription_big_image.alert.confirm" }),
                  role: "confirm",
                  handler: handleDelete,
                },
              ],
            });
          }}
        >
          {intl.formatMessage({ id: "page.prescription_big_image.alert.button.delete" })}
        </AppSecondaryButton>
      </AppHeader>

      <IonContent scrollEvents={true} onIonScroll={handleScroll}>
        {errorMsg && (
          <>
            <IonLabel className="p-page-label-error-block">{errorMsg}</IonLabel>
            <div className="page-spacer-15" />
          </>
        )}
        {localWebviewPath ? (
          <img src={localWebviewPath} style={{ width: "100%", height: "auto" }} />
        ) : (
          <img src={url} style={{ width: "100%", height: "auto" }} />
        )}
      </IonContent>
    </IonPage>
  );
}
