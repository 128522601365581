import { IonContent, IonPage } from "@ionic/react";
import { AppHeader, AppLoading, AppPrimaryButton } from "../../components/UiComponents";
import { EmailForm } from "src/web/components/UiComponents/organisms";
import { useForgetPasswordSendMailControl } from "./hooks/useForgetPasswordSendMailControl";
import { useIntl } from "react-intl-phraseapp";

export function ForgetPasswordSendMail() {
  const {
    isLoading,
    email,
    isError,
    errorMsg,
    isSendBtnDisabled,
    handleEmailInputChange,
    sendMail,
  } = useForgetPasswordSendMailControl();
  const intl = useIntl();

  return (
    <IonPage>
      <AppHeader />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <EmailForm
            title={`${intl.formatMessage({
              id: "page.forget_password_send_mail.message.line1",
            })}\n${intl.formatMessage({
              id: "page.forget_password_send_mail.message.line2",
            })}\n${intl.formatMessage({ id: "page.forget_password_send_mail.message.line3" })}`}
            userName={email}
            handleUserNameInputChange={handleEmailInputChange}
            error={isError}
            errorMsg={errorMsg}
          />

          <div className="page-spacer-60" />

          <AppPrimaryButton full disabled={isSendBtnDisabled} onClick={sendMail}>
            {intl.formatMessage({ id: "page.forget_password_send_mail.button.submit" })}
          </AppPrimaryButton>
        </div>
      </IonContent>

      <AppLoading isOpen={isLoading} />
    </IonPage>
  );
}
