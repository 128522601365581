import { IonBadge, IonLabel } from "@ionic/react";
import { useIntl } from "react-intl-phraseapp";
import { DeviceType } from "src/web/types";

interface ConnectingStatusProps {
  device: DeviceType;
  isPairSuccess?: boolean;
  handleConnecting?: () => void;
}

export function ConnectingStatus({
  device,
  isPairSuccess = true,
  handleConnecting,
}: ConnectingStatusProps) {
  const intl = useIntl();

  return (
    <IonBadge color="transparent" className="connection-wrapper" onClick={() => handleConnecting && handleConnecting()}>
      {device && isPairSuccess ? (
        <>
          <div className="connection-dot connection-dot-active" />
          <IonLabel className="p-smallbody">{device.deviceName}</IonLabel>
        </>
      ) : (
        <>
          <div className="connection-dot" />
          <IonLabel className="p-smallbody">
            {intl.formatMessage({ id: "page.blood_pressure.no_connection" })}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {intl.formatMessage({ id: "components.device_modal.device_selection.title" })}
          </IonLabel>
        </>
      )}
    </IonBadge>
  );
}
