import { useDispatch } from "react-redux";
import { updateAlarmSchedule } from "src/web/actions/alarmScheduleAction";
import { getAlarm } from "src/web/services";
import { AlarmType } from "src/web/types";

export function useAlarmModel() {
  const dispatch = useDispatch();

  const fetchAlarmInfo = async (currentDate: string) => {
    try {
      const res = await getAlarm({ currentDate });
      if (!res) {
        return null;
      }

      let alarmList: AlarmType[] = [];
      Object.entries(res).forEach(([type, time]) => {
        if (time) {
          alarmList.push({
            time: time as string,
            type,
          });
        }
      });
      alarmList.sort((a: AlarmType, b: AlarmType) => {
        if (a.time < b.time) {
          return -1;
        } else if (a.time > b.time) {
          return 1;
        } else {
          return 0;
        }
      });

      //save alarm schedule to redux
      dispatch(updateAlarmSchedule(alarmList));

      return alarmList;
    } catch (error) {
      return null;
    }
  };

  return {
    fetchAlarmInfo,
  };
}
