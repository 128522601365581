import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useIonViewWillEnter } from "@ionic/react";
import { useQueryString } from "src/web/hooks/useQueryString";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import { useTokenRefresh } from "../../../../hooks/models";
import { getToday, getDateStrByDate } from "../../../../utils/common";
import { RECORD } from "../symptomRecordsMain";
import { useSymptomRecordsModel } from "src/web/hooks/models/useSymptomRecordsModel";
import { PageCode, PageActionCode, GraphInfoType, LoadingAnswerAndStatusType } from "src/web/types";
import { useSymptomScoreModel } from "src/web/hooks/models/useSymptomScoreModel";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/web/reducer";
import { MONTH_GRAPH_DISPLAY, WEEK_GRAPH_DISPLAY } from "src/web/constants";
import { updateGraphInfo } from "src/web/actions";

export function useSymptomRecordsMainControl() {
  const location = useLocation();

  const ionRouter = useAppRouter();

  const dispatch = useDispatch();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);

  const { isLoadingToken, checkAndAutoRefreshTokenIfNecessary } = useTokenRefresh();

  const {
    isLoadingSymtpomRecords,
    fetchDailyActivityTimeline,
    fetchSymptomQuestionnaireAnswerByDate,
  } = useSymptomRecordsModel();

  const { isLoadingSymptomScore, fetchWeekChart, fetchMonthChart } = useSymptomScoreModel();

  // 図の表示フラグ
  const {
    graphInfo,
    dailyAnswersAndStatus,
  }: { graphInfo: GraphInfoType; dailyAnswersAndStatus: LoadingAnswerAndStatusType } = useSelector(
    (state: RootState) => {
      return state;
    },
  );

  const [showRecordOrScoreFlg, setShowRecordOrScoreFlg] = useState<string>(
    (params.tab as string) ?? RECORD,
  );
  const [currentDateForRecordTab, setCurrentDateForRecordTab] = useState<string>(
    (params.date as string) ?? getToday(),
  );
  const today = new Date();
  const weekStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay(),
  );
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState(getDateStrByDate(weekStartDate));
  const [currentMonthStartDate, setCurrentMonthStartDate] = useState<string>(
    `${new Date(currentWeekStartDate).getFullYear()}/${
      new Date(currentWeekStartDate).getMonth() < 6 ? "01" : "07"
    }/01`,
  );

  const doFetchForRecordTab = async () => {
    // fetch timeline
    if (currentDateForRecordTab) {
      // manually check and refresh token if necessary
      await checkAndAutoRefreshTokenIfNecessary();

      fetchDailyActivityTimeline(
        {
          pageCode: PageCode.T03,
          pageActionCode: PageActionCode.T03_A01,
        },
        { currentDate: currentDateForRecordTab },
      );

      fetchSymptomQuestionnaireAnswerByDate(
        {
          pageCode: PageCode.T03,
          pageActionCode: PageActionCode.T03_A01,
        },
        { currentDate: currentDateForRecordTab },
      );
    }
  };

  const doFetchForWeekScore = async () => {
    if (currentWeekStartDate) {
      fetchWeekChart(currentWeekStartDate);
    }
  };

  const doFetchForMonthScore = async () => {
    if (currentMonthStartDate) {
      fetchMonthChart(currentMonthStartDate);
    }
  };

  const hideChartTooltip = (e: any) => {
    if (e.defaultPrevented) {
      return;
    }
    // 図の非表示フラグ
    let new_graphInfo = {
      ...graphInfo,
    };
    new_graphInfo[WEEK_GRAPH_DISPLAY] = true;
    new_graphInfo[MONTH_GRAPH_DISPLAY] = true;

    dispatch(updateGraphInfo(new_graphInfo));
  };

  useEffect(() => {
    doFetchForRecordTab();
  }, [currentDateForRecordTab]);

  useEffect(() => {
    // fetch for week data
    doFetchForWeekScore();
  }, [currentWeekStartDate]);

  useEffect(() => {
    // fetch for month data
    doFetchForMonthScore();
  }, [currentMonthStartDate]);

  useEffect(() => {
    const params = parseQs(window.location.search);
    if (params.date) {
      setCurrentDateForRecordTab(params.date as string);
    }
  }, [location]);

  useIonViewWillEnter(() => {
    const params = parseQs(window.location.search);
    if (!params.date) {
      setCurrentDateForRecordTab(getToday());
    }
  });

  return {
    currentDateForRecordTab,
    showRecordOrScoreFlg,
    isLoading:
      isLoadingToken ||
      isLoadingSymtpomRecords ||
      isLoadingSymptomScore ||
      dailyAnswersAndStatus.isLoading,
    switchTab: (tab: any) => {
      setShowRecordOrScoreFlg(tab);
    },
    currentWeekStartDate,
    setCurrentWeekStartDate,
    currentMonthStartDate,
    setCurrentMonthStartDate,
    hideChartTooltip,
  };
}
