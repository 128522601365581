import { IonSlides, IonSlide, IonLabel } from "@ionic/react";
import { AppLargeTile } from "../..";
import styles from "./carouselView.module.css";

interface CarouselWideViewProps {
  title: string;
  dataList: any[];
}

export function CarouselWideView({ title, dataList }: CarouselWideViewProps) {
  const OneSlideOpts = {
    slidesPerView: "auto",
    spaceBetween: 15,
  };

  return (
    <>
      <div className="root-content">
        <div className="flex-row-between">
          <IonLabel className="p-body gray">{title}</IonLabel>
        </div>
      </div>
      <div className="page-spacer-15" />
      <div className={styles.contentLeft}>
        <IonSlides options={OneSlideOpts} className={styles.slidesWide}>
          {dataList.map((item, index) => (
            <IonSlide key={index} className={styles.slideWide}>
              <AppLargeTile
                src={item}
                textTitle="テキストが入りますテキストが入ります"
                textSubTitle="所要時間"
                textSubTitleTime="10分"
              />
            </IonSlide>
          ))}
        </IonSlides>
      </div>
    </>
  );
}
