import { IonLabel } from "@ionic/react";
import { AppTextLink } from "../../AppTextLink/appTextLink";

interface ContentsWithTextLinkProps {
  text: string;
  desc?: string;
  linkText?: string;
  handleLink?: () => void;
}

export function ContentsWithTextLink({
  text,
  desc,
  linkText,
  handleLink,
}: ContentsWithTextLinkProps) {
  return (
    <>
      <IonLabel className="p-body text-block pre-wrap">{text}</IonLabel>

      {!!linkText && (
        <>
          <div className="page-spacer-30" />

          <AppTextLink align="left" onClick={handleLink}>
            {linkText}
          </AppTextLink>
        </>
      )}

      {!!desc && (
        <>
          <div className="page-spacer-30" />
          <IonLabel className="p-body text-block pre-wrap">{desc}</IonLabel>
        </>
      )}
    </>
  );
}
