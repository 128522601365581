import { useState } from "react";
import { IonButton } from "@ionic/react";
import styles from "./styles.module.css";

let tagBtnAnimationTimeout: any = null;
const tagBtnInitClickingState = 0;

export function AppTagButton(props: any) {
  const [isClicking, setIsClicking] = useState<number>(tagBtnInitClickingState);

  const click = (event: any) => {
    //動画を追加する
    clearTimeout(tagBtnAnimationTimeout);
    setIsClicking(isClicking + 1);
    tagBtnAnimationTimeout = setTimeout(() => {
      setIsClicking(tagBtnInitClickingState);
    }, 500);
    props.onClick && props.onClick(event);
  };

  return (
    <IonButton
      key={isClicking}
      {...props}
      className={`${styles.TagButton}
            ${props.className} 
            ${props.disabled === true && styles.TagButtonDisabled} 
            ${isClicking !== tagBtnInitClickingState
                ? styles.TagButtonAnimation
                : ""
            }`}
      onClick={click}
    >
      <div className={styles.label}>
        {props.children}
      </div>
    </IonButton>
  );
}
