export * from "./symptomSettingTypes";
export * from "./symptomScoreTransitionTypes";
export * from "./symptomRecordTypes";
export * from "./symptomQuestionnaireTypes";
export * from "./userBaseInfoTypes";
export * from "./menuTypes";
export * from "./bloodPressureTypes";
export * from "./medicineTypes";
export * from "./notificationSettingTypes";

export enum AlertType {
  NONE,
  ERROR,
  WARN,
  INFO,
}

export interface AlertInfoType {
  status: AlertType;
  title?: string;
  msg: string;
}

export interface GraphInfoType {
  [code: string]: any;
}

export interface RefreshType {
  [code: string]: boolean;
}

export interface UserInfo {
  name: string;
}

export interface UserActionType {
  type: string;
  user: UserInfo;
}

export interface StateType {
  userInfo: UserInfo;
}

export interface DeviceErrorType {
  [code: string]: string;
}

export interface DeviceType {
  deviceId?: string;
  deviceName?: string;
  status?: number;
}

export interface AppTabType {
  name: string;
  text: string;
}

export interface SelectOption {
  value: string | number;
  text: string;
}

export interface ErrorForm {
  [field: string]: ErrorField;
}

export interface ErrorField {
  type?: "required" | "number" | "date";
  message?: string;
  ref?: any;
}

export interface CalendarDayType {
  date: number;
  fullDate: string;
  hasData?: boolean;
  hasScheduled?: boolean;
  isSelected: boolean;
  isCurrent: boolean;
  isDisabled?: boolean;
}

export interface AlarmType {
  time: string;
  type: string;
}

export interface DailyAlarmRedirectType {
  id: string;
  type: string;
  date: string;
}

// Mの意味はメニュー画面
// T: Page Tab
export enum PageCode {
  T01 = "T01", // ホームTab > /home/main
  T02 = "T02", // お薬記録Tab > /home/medication-record
  T03 = "T03", // 症状記録Tab > /home/reports
  T04 = "T04", // 基礎情報Tab > /home/basic-info
  T05 = "T05", // メニューTab > /home/menu

  T01_1 = "T01_1", // 症状の登録

  T05_1 = "T05_1", // Alexa設定画面
}

// 共通の動作はCからです。
export enum PageActionCode {
  // 画面開始の初期化
  C_INITIAL = "C_INITIAL",

  // 特別の操作
  // ページコード＋画面操作
  // スキル連携操作
  T05_1_A01 = "T05_1_A01",
  // トークンの連携操作
  T05_1_A02 = "T05_1_A02",

  // /home/main: get current symptom
  T01_A01 = "T01_A01",
  // /home/main action sheet: save single current symptom
  T01_A02 = "T01_A02",
  // /symptom-entering-details: get current symptom list
  T01_1_A01 = "T01_1_A01",
  // /symptom-entering-details: save current symptom list
  T01_1_A02 = "T01_1_A02",
  // /symptom-entering-details: delete current symptom list
  T01_1_A03 = "T01_1_A03",

  T03_A01 = "T03_A01", // fetch timeline
}

export enum FuncStatus {
  START = "START",
  ERROR = "ERROR",
  END = "END",
}

// 画面ログのタイプ
export interface PageLogType {
  // 画面コード
  pageCode: PageCode;
  // 動作コード
  pageActionCode: PageActionCode;
}

export interface LoadingDisplay {
  isLoadingVisible: boolean;
}
