import { actionConst } from "../constants";

interface Actions {
  type: string;
  startDate: string;
}

export default function userStartDateReducer(state: string = "", actions: Actions) {
  switch (actions.type) {
    case actionConst.USER_START_DATE:
      return actions.startDate;
    default:
      return state;
  }
}
