import { getToday } from "src/web/utils/common";
import { useCommon } from "src/web/utils/hooks/common";

export function useHomeTitleControl() {
  const { convertToDate } = useCommon();
  const formattedCurrentDateWithDay = convertToDate(getToday());

  return {
    formattedCurrentDateWithDay,
  };
}
