import { actionConst } from "../constants";

interface Actions {
  type: string;
  keyboardHeight: number;
}

export default function capacityKeyboardHeightReducer(state: number = 0, actions: Actions) {
  switch (actions.type) {
    case actionConst.UPDATE_CAPACITY_KEYBOARD_HEIGHT:
      return actions.keyboardHeight;
    default:
      return state;
  }
}
