import { IonContent, IonPage } from "@ionic/react";
import { AppHeader } from "../components/UiComponents";

export default function Test() {
  return (
    <IonPage>
      <AppHeader />
      <IonContent>
        <h1 style={{ textAlign: "center" }}>
          Here is the testing page for page transition animation.
        </h1>
      </IonContent>
    </IonPage>
  );
}
