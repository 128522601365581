import { IonPage } from "@ionic/react";
import {
  AppContent,
  AppFloatButton,
  AppHeader,
  AppInput,
  AppTextarea,
} from "../../../components/UiComponents";
import { PageTitle } from "src/web/components/UiComponents/moleclues";
import { ContentsWithTextLink } from "src/web/components/UiComponents/organisms";
import { useContactControl } from "./hooks/useContactControl";
import styles from "./contact.module.css";
import { useIntl } from "react-intl-phraseapp";

export function Contact() {
  const { formData, isSubmitDisabled, handleBack, navToFaq, handleValueChange, doSubmit } =
    useContactControl();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppHeader backAction={handleBack} />

        <AppContent>
          <div className="root-content">
            <form>
              <div className="page-spacer-45" />

              <PageTitle title={intl.formatMessage({ id: "page.menu.inquiry.inquiry.label" })} />

              <div className="page-spacer-45" />

              <ContentsWithTextLink
                text={intl.formatMessage({ id: "page.menu.inquiry.inquiry.description" })}
                desc={`${intl.formatMessage({
                  id: "page.menu.inquiry.faq.description.line1",
                })}\n${intl.formatMessage({ id: "page.menu.inquiry.faq.description.line2" })}}`}
                linkText={intl.formatMessage({ id: "page.menu.inquiry.faq.label" })}
                handleLink={navToFaq}
              />

              <div className="page-spacer-45" />

              <hr />

              <div className="page-spacer-45" />

              <AppInput
                label={intl.formatMessage({ id: "page.menu.inquiry.form.name" })}
                value={formData.name}
                maxlength={255}
                onIonChange={(e: any) => {
                  handleValueChange("name", e.detail.value);
                }}
              />

              <div className="page-spacer-45" />

              <AppInput
                label={intl.formatMessage({ id: "page.menu.inquiry.form.email" })}
                value={formData.mail}
                className={styles.inputDisabled}
                disabled={true}
              />

              <div className="page-spacer-45" />

              <AppInput
                label={intl.formatMessage({ id: "page.menu.inquiry.form.inquiry_title" })}
                value={formData.title}
                maxlength={255}
                onIonChange={(e: any) => {
                  handleValueChange("title", e.detail.value);
                }}
              />

              <div className="page-spacer-45" />

              <AppTextarea
                label={intl.formatMessage({ id: "page.menu.inquiry.form.inquiry_content" })}
                value={formData.content}
                maxlength={1000}
                onIonChange={(e: any) => {
                  handleValueChange("content", e.detail.value);
                }}
              />

              <div className="page-spacer-30" />
            </form>
          </div>

          <AppFloatButton
            label={intl.formatMessage({ id: "page.menu.inquiry.form.button.submit" })}
            disabled={isSubmitDisabled}
            onClick={doSubmit}
          />
        </AppContent>
      </IonPage>
    </>
  );
}
