import { signOut } from "@firebase/auth";
import { useState } from "react";
import { auth } from "src/web/firebase";
import { useUserModel } from "src/web/hooks/models";
import { useAppRouter } from "src/web/hooks/useAppRouter";
export function useAccountsWithdrawControl() {
  const iRouter = useAppRouter();

  const { handleDeleteUser } = useUserModel();

  const [isLoading, setIsLoading] = useState(false);
  const [dataReserveFlg, setDataReserveFlg] = useState(false);

  const handleToggleReserveFlg = () => {
    setDataReserveFlg(!dataReserveFlg);
  };

  const doSubmit = async () => {
    try {
      setIsLoading(true);

      // delete user
      await handleDeleteUser(dataReserveFlg);

      // signout
      await signOut(auth);
      iRouter.push("/settings/accounts/withdraw/complete");
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    dataReserveFlg,
    handleToggleReserveFlg,
    doSubmit,
  };
}
