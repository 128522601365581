import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IonContent, ScrollDetail, useIonViewWillEnter } from "@ionic/react";
import { updateShowHeaderBorder } from "../../../actions/showHeaderBoarder";
import { RootState } from "../../../reducer";

export function AppContent({ disableAutoScrollToTop, children, ...rest }: any) {
  const dispatch = useDispatch();

  const contentRef = useRef<any>();

  const isShowHeaderBorder = useSelector((state: RootState) => state.isShowHeaderBoarder);

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    if (ev.detail.scrollTop > 0 !== isShowHeaderBorder) {
      dispatch(updateShowHeaderBorder(ev.detail.scrollTop > 0));
    }
  };

  useIonViewWillEnter(() => {
    if (!disableAutoScrollToTop && contentRef.current?.scrollIntoView) {
      contentRef.current.scrollIntoView();
    }
  });

  return (
    <IonContent {...rest} scrollEvents={true} onIonScroll={handleScroll}>
      <div ref={contentRef} />
      {children}
    </IonContent>
  );
}
