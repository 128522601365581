import { useRef } from "react";
import { IonLabel } from "@ionic/react";
import { AppIcon } from "../../../../../components/UiComponents";
import { formatTime } from "../../../../../utils/common";
import { useCommon } from "../../../../../utils/hooks/common";
import { useTimelineControl } from "./hooks/useTimelineControl";
import { ICONS } from "../../../../../constants";
import { TimelineType } from "src/web/types";
import styles from "./timeline.module.css";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

interface TimelineProps {
  currentDate?: string;
  data: TimelineType;
  linePosition: "first" | "middle" | "last" | "none";
  isReadonly?: boolean;
  isDynamicLineColor?: boolean;
  isShowDetails?: boolean;
}

export function Timeline({
  currentDate,
  data,
  linePosition,
  isReadonly = false,
  isDynamicLineColor = false,
  isShowDetails = false, // if true, show 最高血圧 and 最低血圧 instead of 血圧
}: TimelineProps) {
  const timelineRef = useRef<any>();
  const intl = useIntl();
  const { TIMELINE_CATEGORIES } = useCommon();

  const doScrollIntoView = () => {
    timelineRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const { handleNavToDetail } = useTimelineControl(data, doScrollIntoView, isReadonly, currentDate);

  const ICON_STYLE: any = {
    [TIMELINE_CATEGORIES.SYMPTOM.type]: { icon: ICONS.TRACKING.BODY_CONDITION },
    [TIMELINE_CATEGORIES.ACTIVITY.type]: { icon: ICONS.TRACKING.EXERCISE },
    [TIMELINE_CATEGORIES.BLOOD_PRESSURE.type]: { icon: ICONS.TRACKING.BLOOD_PRESSURE },
    [TIMELINE_CATEGORIES.BODY_TEMPERATURE.type]: { icon: ICONS.TRACKING.THERMOMETER },
    [TIMELINE_CATEGORIES.TAKING_MEDICINE.type]: { icon: ICONS.TRACKING.MEDICINE },
  };

  return (
    <div className={styles.timelineWrapper} ref={timelineRef}>
      <div
        className={`${styles.timelineLeft} p-body ${styles[linePosition]} ${
          isDynamicLineColor ? styles[`${data.type.toLowerCase()}Line`] : ""
        }`}
      >
        {formatTime(data.time, true)}
        <div className={styles.icon}>
          <AppIcon icon={ICON_STYLE[data.type].icon} size="md" />
        </div>
      </div>

      <div className={styles.timelineRight}>
        <div
          role="navToEdit"
          className="flex-row-between"
          onClick={() => {
            if (!isReadonly && handleNavToDetail) {
              handleNavToDetail();
            }
          }}
        >
          <Title
            type={data.type}
            subType={
              data.type === TIMELINE_CATEGORIES.TAKING_MEDICINE.type ? data.data.type : undefined
            }
          />
          {!isReadonly && (
            <div role="navIcon">
              <AppIcon icon={ICONS.ICON.ARROW_FORWARD} size="sm" />
            </div>
          )}
        </div>
        <div className="page-spacer-15" />

        <Description type={data.type} data={data.data} isShowDetails={isShowDetails} />

        {linePosition !== "last" && linePosition !== "none" && <div className="page-spacer-45" />}
      </div>
    </div>
  );
}

const Title = ({ type, subType }: { type: string; subType?: string }) => {
  const { TIMELINE_CATEGORIES } = useCommon();
  const {
    MEDICINE_TIMING_OPTIONS,
  } = useTextConstants();
  const getTypeLabel = (type: string) => {
    const labelObj = Object.values(TIMELINE_CATEGORIES).find((value) => value.type === type);
    return labelObj ? labelObj.label : "";
  };

  const getSubLabel = (type: string, subType?: string) => {
    if (type && subType) {
      const labelObj = MEDICINE_TIMING_OPTIONS.find((opt) => opt.value === subType);
      return labelObj ? ` / ${labelObj.text}` : "";
    } else {
      return "";
    }
  };

  return (
    <div>
      <IonLabel className="p-body gray">
        {getTypeLabel(type)}
        {getSubLabel(type, subType)}
      </IonLabel>
    </div>
  );
};

const Description = ({
  type,
  data,
  isShowDetails,
}: {
  type: string;
  data: any;
  isShowDetails: boolean;
}) => {
  const intl = useIntl();
  const { TIMELINE_CATEGORIES } = useCommon();
  return (
    <>
      {type === TIMELINE_CATEGORIES.TAKING_MEDICINE.type ? (
        <>
          {data.data.map((item: string, index: number) => (
            <div key={index} className={styles.descriptionOverflowLabel}>
              <IonLabel className={`p-smallbody ${styles.descriptionOverflowLabel}`}>
                {item}
              </IonLabel>
            </div>
          ))}
        </>
      ) : type === TIMELINE_CATEGORIES.BODY_TEMPERATURE.type ? (
        <>
          {data.map((item: string, index: number) => (
            <div key={index}>
              <IonLabel className="p-smallbody">{item}&#8451;</IonLabel>
            </div>
          ))}
        </>
      ) : type === TIMELINE_CATEGORIES.SYMPTOM.type ? (
        <>
          {data.symptom.map((item: string, index: number) => (
            <div
              className={`flex-row-start ${
                index < data.symptom.length - 1 ? styles.symptomItem : ""
              }`}
              key={index}
            >
              {!!ICONS.ICON[`FACE_${item[0]}`] && (
                <AppIcon icon={ICONS.ICON[`FACE_${item[0]}`]} size="sm" />
              )}
              <IonLabel className={`p-smallbody ${styles.descLabelWithMargin}`}>{item[1]}</IonLabel>
            </div>
          ))}
          {!!data.comment && (
            <>
              <div className="page-spacer-15" />
              <IonLabel className="p-smallbody pre-wrap">{data.comment}</IonLabel>
            </>
          )}
        </>
      ) : type === TIMELINE_CATEGORIES.BLOOD_PRESSURE.type ? (
        <>
          {isShowDetails ? (
            <>
              <div>
                <IonLabel className="p-smallbody">{`${intl.formatMessage({
                  id: "page.symptom_records_main.blood_pressure.sbp.label",
                })}：${data.sbp} ${intl.formatMessage({
                  id: "common.blood_pressure.unit",
                })}`}</IonLabel>
              </div>
              <div>
                <IonLabel className="p-smallbody">{`${intl.formatMessage({
                  id: "page.symptom_records_main.blood_pressure.dbp.label",
                })}：${data.dbp} ${intl.formatMessage({
                  id: "common.blood_pressure.unit",
                })}`}</IonLabel>
              </div>
            </>
          ) : (
            <div>
              <IonLabel className="p-smallbody">{`${intl.formatMessage({
                id: "page.timeline.category.blood_pressure",
              })}：${data.sbp} / ${data.dbp} ${intl.formatMessage({
                id: "common.blood_pressure.unit",
              })}`}</IonLabel>
            </div>
          )}
          <div>
            <IonLabel className="p-smallbody">{`${intl.formatMessage({
              id: "page.symptom_records_main.blood_pressure.pulse.label",
            })}：${data.hr} ${intl.formatMessage({ id: "common.pulse.unit" })}`}</IonLabel>
          </div>
        </>
      ) : type === TIMELINE_CATEGORIES.ACTIVITY.type ? (
        <>
          <div>
            <IonLabel className="p-smallbody">{`${intl.formatMessage({
              id: "page.timeline.activity.label",
            })}：${data.duration}${intl.formatMessage({
              id: "page.timeline.activity.unit",
            })}`}</IonLabel>
          </div>
          {!!data.steps && (
            <div>
              <IonLabel className="p-smallbody">{`${intl.formatMessage({
                id: "page.symptom_records_main.walks.label",
              })}：${data.steps} ${intl.formatMessage({ id: "common.walks.unit" })}`}</IonLabel>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
