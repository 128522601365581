import { useState } from "react";
import { AppIcon } from "../";
import { ICONS } from "../../../constants";
import styles from "./styles.module.css";
import { useIntl } from "react-intl-phraseapp";

interface AppQuantitySelectorProps {
  number: number;
  setNumber: React.Dispatch<React.SetStateAction<number>>;
}

let updatingTimeout: any = null;
let initUpdating = 0;

export function AppQuantitySelector({ number, setNumber }: AppQuantitySelectorProps) {
  const intl = useIntl();
  const [updating, setUpdating] = useState(initUpdating);

  const triggerAnimation = () => {
    clearTimeout(updatingTimeout);
    setUpdating(updating + 1);
    updatingTimeout = setTimeout(() => {
      setUpdating(initUpdating);
    }, 300);
  };

  const handleMinus = () => {
    if (number > 0 && setNumber) {
      triggerAnimation();
      setTimeout(() => {
        setNumber(number - 1);
      }, 100);
    }
  };

  const handleAdd = () => {
    if (setNumber) {
      triggerAnimation();
      setTimeout(() => {
        setNumber(number + 1);
      }, 100);
    }
  };

  return (
    <div className={styles.root}>
      <AppIcon
        size="sm"
        icon={ICONS.ICON.MINUS_CIRCLE}
        disabled={number <= 0}
        onClick={handleMinus}
      ></AppIcon>
      <div key={updating} className={`${styles.number} ${updating ? styles.numberAnimation : ""}`}>
        {number}
        {intl.formatMessage({ id: "ui_components.app_quantity_selector.unit" })}
      </div>
      <AppIcon size="sm" icon={ICONS.ICON.PLUS_CIRCLE} onClick={handleAdd}></AppIcon>
    </div>
  );
}
