import { IonButton, IonCol } from "@ionic/react";

interface ContainerProps {
  content: string;
}

const ChatButton: React.FC<ContainerProps> = ({ content }) => {
  return (
    <IonCol sizeMd="3">
      <IonButton size="default" mode="ios" color="tertiary" expand="block">
        {content}
      </IonButton>
    </IonCol>
  );
};

export default ChatButton;
