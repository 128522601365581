import { useIonViewWillEnter } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { updateAlertInfo } from "../../../../actions";
import { updateSymptomSetting } from "../../../../actions/symptomSettingAction";
import SymptomSettingModel from "../../../../models/SymptomSettingModel";
import { RootState } from "../../../../reducer";
import { SymptomRecordType, SymptionSettingDataType } from "../../../../types";

export function useSymptomSettingControl() {
  const dispatch = useDispatch();
  const symptomSettingData: SymptionSettingDataType = useSelector(
    (state: RootState) => state.symptomSetting,
  );

  const model = new SymptomSettingModel();

  const fetchData = async () => {
    dispatch(
      updateSymptomSetting({
        ...symptomSettingData,
        isLoading: true,
      }),
    );
    const data = await model.fetchAllSymptomList();
    dispatch(
      updateSymptomSetting({
        isLoading: false,
        ...data,
      }),
    );
  };

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    // if no destination or the destination is same as source
    if (
      !destination ||
      (destination.index === source.index && destination.droppableId === source.droppableId)
    ) {
      return;
    }

    let item = undefined;
    if (source.droppableId === "home") {
      item = symptomSettingData.homeList?.splice(source.index, 1)[0];
    } else {
      item = symptomSettingData.otherList?.splice(source.index, 1)[0];
    }
    if (item) {
      item.isActiveOnHome = destination.droppableId === "home";
      if (destination.droppableId === "home") {
        item.isActive = true;
        symptomSettingData.homeList?.splice(destination.index, 0, item);
      } else {
        symptomSettingData.otherList?.splice(destination.index, 0, item);
      }
    }

    saveChange();
  };

  const handleToggleChange = (data: SymptomRecordType) => {
    data.isActive = !data.isActive;
    saveChange(data.symptomId);
  };

  const saveChange = async (symptomId?: number) => {
    dispatch(
      updateSymptomSetting({
        ...symptomSettingData,
        // isLoading: true
      }),
    );
    const res = await model.saveSymptomSetting(symptomSettingData, symptomId);
    // when Error happened, res will be the error details
    // in this case, call the API to fetch the data from DB to sync the data to the frontend
    if (res) {
      // show error massage
      dispatch(updateAlertInfo(res));
      // sync the data from DB
      const data = await model.fetchAllSymptomList();
      dispatch(
        updateSymptomSetting({
          isLoading: false,
          ...data,
        }),
      );
    }
  };

  const isDisableDrag = (list?: any[]) => {
    return !list?.length || list?.length <= 1;
  };

  useIonViewWillEnter(() => {
    fetchData();
  });

  return {
    onDragEnd,
    handleToggleChange,
    isDisableDrag,
  };
}
