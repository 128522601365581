import { useRef, forwardRef, useImperativeHandle } from "react";
import { IonInput, IonLabel, isPlatform } from "@ionic/react";
import { Keyboard } from "@capacitor/keyboard";
import { AppIcon } from "../AppIcon/appIcon";
import { ICONS } from "../../../constants";
import styles from "./appInput.module.css";

export const AppSheetInput = forwardRef((props: any, ref?: any) => {
  // const [isFocus, setIsFocus] = useState<boolean>(false);

  const size: "full" | "md" | "sm" | "xs" = props.size || "full";

  const inputControl: React.MutableRefObject<any> = useRef();
  useImperativeHandle(ref, () => inputControl.current);

  return (
    <>
      {props.label && (
        <>
          <IonLabel className={styles.input_label}>{props.label}</IonLabel>
          <div className="page-spacer-15" />
        </>
      )}
      <div className={`${styles.inputWrapper} ${props.error ? styles.shake : ""}`}>
        <div className={`${styles.input_div} ${styles[size]}`}>
          <IonInput
            {...props}
            className={` ${props.error && styles.input_error} ${styles.input}`}
            onIonChange={(event) => {
              props.onIonChange && props.onIonChange(event);
            }}
            onIonFocus={() => {
              if (isPlatform("capacitor")) {
                Keyboard.hide();
              }
              // setIsFocus(true);
              // setTimeout(() => {
              // props.handleActionSheet();
              // }, PICKER_TIMEOUT);
            }}
            onClick={() => {
              props.handleActionSheet();
            }}
            // onIonBlur={(event) => {
            //   // setIsFocus(false);
            //   props.onIonBlur && props.onIonBlur(event);
            // }}
            ref={inputControl}
            inputmode="none"
          />
          {!props.hideArrow && (
            <AppIcon
              size="sm"
              icon={ICONS.ICON.ARROW_FORWARD}
              className={`${styles.input_icon} ${props.error && styles.icon_error}`}
              onMouseDown={(e) => {
                e.preventDefault();
                props.handleActionSheet();
              }}
            />
          )}
        </div>
        {props.error && (
          <>
            <IonLabel className="p-input-label-error">
              {props.errorMsg || props.error.message}
            </IonLabel>
          </>
        )}
      </div>
    </>
  );
});
