import { useIntl } from "react-intl-phraseapp";
import { DeviceInfoType } from "src/web/plugin/healthcare-manager-plugin";
import { ModalWrapper } from "./ModalWrapper";
import { BLUETOOTH, DEVICE_CATEGORY } from "src/web/constants";
import { ModalListItemGroup } from "../../UiComponents/organisms";

export function DeviceSelection({
  isOpen,
  deviceCategory,
  handleCancel,
  handleNext,
}: {
  isOpen: boolean;
  deviceCategory: string;
  handleCancel: () => void;
  handleNext: (device: DeviceInfoType) => void;
}) {
  const intl = useIntl();

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={`${intl.formatMessage({ id: "components.device_modal.device_selection.title" })}${
        deviceCategory === DEVICE_CATEGORY.BLOOD_PRESSURE
          ? intl.formatMessage({ id: "components.device_modal.device.blood_pressure" })
          : intl.formatMessage({ id: "components.device_modal.device.temperature" })
      }`}
      screenHeight={290 + 70 * BLUETOOTH[deviceCategory].length}
      handleCancel={handleCancel}
    >
      <ModalListItemGroup list={BLUETOOTH[deviceCategory]} handleNext={handleNext} />
    </ModalWrapper>
  );
}
