import { IonLabel, IonPage } from "@ionic/react";
import {
  AppHeader,
  AppLoading,
  AppContent,
  AppSelectInput,
  AppTextarea,
  AppFloatButton,
  AppSecondaryButton,
} from "../../../components/UiComponents";
import { FaceScore } from "../DiseaseMainTab/components/FaceScore/faceScore";
import { HOUR_KV_OPTIONS, MINUTE_KV_OPTIONS } from "src/web/constants";
import { useSymptomEnteringDetailsControl } from "./hooks/useSymptomEnteringDetailsControl";
import { useIntl } from "react-intl-phraseapp";

export function SymptomEnteringDetails() {
  const intl = useIntl();
  const {
    isLoading,
    symptomDetails,
    formattedCurrentDateWithDay,
    currentTime,
    isDeletable,
    setCurrentTime,
    handleBack,
    handleDel,
    handleScoreChange,
    handleCommentChange,
    checkDisableSubmit,
    handleDuplicatedCheckAndSave,
  } = useSymptomEnteringDetailsControl();

  return (
    <>
      <IonPage>
        <AppHeader backAction={handleBack}>
          <AppSecondaryButton
            isDynamicWidth
            transparent={true}
            onClick={() => {
              if (isLoading) return;
              // 変更の場合、確認メッセージを表示します。
              if (isDeletable) {
                handleDel();
              } else {
                handleBack();
              }
            }}
          >
            {isDeletable
              ? intl.formatMessage({ id: "page.symptom_entering_details.header.button.delete" })
              : intl.formatMessage({ id: "page.symptom_entering_details.header.button.cancel" })}
          </AppSecondaryButton>
        </AppHeader>

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="p-header1">
              {intl.formatMessage({ id: "page.symptom_entering_details.label.register_symptoms" })}
            </IonLabel>

            <div className="page-spacer-50" />

            <IonLabel className="p-header3">{formattedCurrentDateWithDay}</IonLabel>

            <div className="page-spacer-15" />

            <hr />

            <div className="page-spacer-30" />

            <IonLabel className="p-body">
              {intl.formatMessage({ id: "page.symptom_entering_details.label.time" })}
            </IonLabel>

            <div className="page-spacer-15" />

            <AppSelectInput
              value={currentTime}
              setValue={setCurrentTime}
              options={HOUR_KV_OPTIONS}
              options2={MINUTE_KV_OPTIONS}
              separatedBy=":"
              needClear={false}
              size="md"
            />

            <div className="page-spacer-45" />

            {!!symptomDetails?.recordDetails && (
              <div role="list">
                {symptomDetails.recordDetails.map((item) => (
                  <div role="listitem" key={item.symptomId}>
                    <IonLabel className="p-body">{item.content}</IonLabel>

                    <div className="page-spacer-20" />

                    <FaceScore
                      score={item.painScaleValue}
                      handleClick={(score) => {
                        handleScoreChange(score, item, symptomDetails);
                      }}
                    />

                    <div className="page-spacer-50" />
                  </div>
                ))}
              </div>
            )}

            <IonLabel className="p-body">
              {intl.formatMessage({ id: "page.symptom_entering_details.label.memo" })}
            </IonLabel>

            <div className="page-spacer-20" />

            <AppTextarea
              data-testid="memo"
              maxlength={200}
              value={symptomDetails?.comment}
              onIonChange={(e: any) => {
                handleCommentChange(e.detail.value);
                // symptomDetails &&
                //   setSymptomDetails({
                //     ...symptomDetails,
                //     comment: e.detail.value,
                //   });
              }}
            />

            <div className="page-spacer-30" />

            <AppFloatButton
              label={intl.formatMessage({ id: "page.symptom_entering_details.button.submit" })}
              disabled={checkDisableSubmit()}
              onClick={handleDuplicatedCheckAndSave}
            />
          </div>
        </AppContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
