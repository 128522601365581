import { useRef, useState, useEffect } from "react";
import { IonLabel } from "@ionic/react";
import {
  AppDoublePicker,
  AppFixedActionSheet,
  AppToggleButton,
} from "../../../../components/UiComponents";
import { HOUR_KV_OPTIONS, MINUTE_STEP_KV_OPTIONS } from "../../../../constants";
import { alarmInfoItemType } from "../../types";
import styles from "./editAlarmSheetLite.module.css";
import { useIntl } from "react-intl-phraseapp";

interface EditAlarmSheetLiteProps {
  isOpen: boolean;
  alarm?: alarmInfoItemType;
  handleClose: () => void;
  handleConfirm: (value: alarmInfoItemType) => void;
}

export function EditAlarmSheetLite({
  isOpen,
  alarm,
  handleClose,
  handleConfirm,
}: EditAlarmSheetLiteProps) {
  const intl = useIntl();
  const timeRef = useRef<any>();

  const [alarm_flg, setAlarm_flg] = useState<boolean>(true);

  useEffect(() => {
    if (alarm) {
      setAlarm_flg(alarm.alarm_flg);
    }
  }, [alarm]);

  return (
    <AppFixedActionSheet
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={() => {
        if (alarm) {
          const data: alarmInfoItemType = {
            ...alarm,
            alarm_flg,
            alarm_clock: timeRef.current.getSelectedData(),
          };
          handleConfirm(data);
        }
      }}
      title={alarm?.alarm_title}
      sheetHeight={480}
    >
      <IonLabel className="p-body">
        {intl.formatMessage({ id: "page.notification_setting.collapse_item.action_sheet.time" })}
      </IonLabel>

      <div className="page-spacer-15" />

      <AppDoublePicker
        ref={timeRef}
        initVal={alarm?.alarm_clock}
        options={HOUR_KV_OPTIONS}
        options2={MINUTE_STEP_KV_OPTIONS}
        separatedBy=":"
        isLoop={true}
      />

      <div className="page-spacer-45" />

      <div className="flex-row-between">
        <IonLabel className="p-body">
          {intl.formatMessage({
            id: "page.notification_setting.collapse_item.action_sheet.button.snooze",
          })}
        </IonLabel>

        <AppToggleButton checked={!!alarm_flg} onClick={() => setAlarm_flg(!alarm_flg)} />
      </div>
    </AppFixedActionSheet>
  );
}
