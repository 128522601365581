import { Fragment, useRef, useState, useEffect } from "react";
import { AppFixedActionSheet, AppIcon, AppDoublePicker } from "../../../../components/UiComponents";
import { IonLabel } from "@ionic/react";
import {
  ICONS,
  HOUR_KV_OPTIONS,
  MINUTE_STEP_KV_OPTIONS,
  ACTION_SHEET_MIDDLE_SIZE,
} from "../../../../constants";
import { INIT_FREQUENCY } from "../../medicineInformation";
import styles from "./timingActionSheet.module.css";
import { SelectOption } from "../../../../types";
import { useIntl } from "react-intl-phraseapp";
import { useCommon } from "src/web/utils/hooks/common";
import { useTextConstants } from "src/web/constants/hooks";

interface TimingType {
  type?: string;
  time: string;
}

interface TimingActionSheetProps {
  isOpen: boolean;
  initValue?: TimingType;
  title?: string;
  handleClose: () => void;
  handleConfirm: (value: any) => void;
  needClear?: boolean;
}

export function TimingActionSheet({
  isOpen,
  initValue,
  title,
  handleClose,
  handleConfirm,
  needClear = true,
}: TimingActionSheetProps) {
  const intl = useIntl();
  const { generateLabeledEmptyOptions } = useCommon();
  const {
    MEDICINE_TIMING_OPTIONS,
  } = useTextConstants();
  const opts = {
    allowTouchMove: false,
    autoHeight: true,
    spaceBetween: 1,
  };
  const timerRef = useRef<any>();

  const timingOptions = needClear
    ? generateLabeledEmptyOptions(MEDICINE_TIMING_OPTIONS, needClear)
    : MEDICINE_TIMING_OPTIONS;

  const [show2ndStep, setShow2ndStep] = useState(false);

  const [current, setCurrent] = useState<TimingType>({
    type: undefined,
    time: "",
  });

  const [actionSheetHeight, setActionSheetHeight] = useState<number>();

  const handleConfirmOnStep1 = () => {
    switch (current.type) {
      case undefined:
        break;
      case "13": // 時刻...
        // nav to step 2
        setShow2ndStep(true);
        // サイズ更新する場合
        setActionSheetHeight(ACTION_SHEET_MIDDLE_SIZE);
        break;
      default:
        handleConfirmOnStep2();
        break;
    }
  };

  // confirm and close
  const handleConfirmOnStep2 = () => {
    const result =
      current.type === "13" // 時刻...
        ? {
            ...current,
            time: timerRef.current.getSelectedData(),
          }
        : current;
    handleConfirm(result);
    handleClose();
  };

  useEffect(() => {
    if (!isOpen) {
      setShow2ndStep(false);
      setCurrent(JSON.parse(JSON.stringify(INIT_FREQUENCY)));
    } else {
      if (initValue) {
        setCurrent(JSON.parse(JSON.stringify(initValue)));
      }
      // サイズを初期化する
      setActionSheetHeight(0);
    }
  }, [isOpen]);

  return (
    <AppFixedActionSheet isOpen={isOpen} handleClose={handleClose} sheetHeight={actionSheetHeight}>
      <div className={`${styles.root} ${show2ndStep ? styles.moveLeft : ""}`}>
        <div className={styles.sliderWrapper}>
          <div className={styles.header}>
            <IonLabel className={styles.cancel} onClick={handleClose}>
              {intl.formatMessage({ id: "page.timing_action_sheet.header.cancel" })}
            </IonLabel>
            <IonLabel className={styles.title}>{title}</IonLabel>
            <IonLabel className={styles.confrim} onClick={handleConfirmOnStep1}>
              {intl.formatMessage({ id: "page.timing_action_sheet.header.confirm" })}
            </IonLabel>
          </div>

          <div className={`${styles.body} ${styles.scrollable}`}>
            <div className="page-spacer-45" />

            {timingOptions.map((data: SelectOption, index: number) => (
              <Fragment key={data.value}>
                <div
                  className={styles.optionLine}
                  onClick={() => {
                    if (data.value !== current.type) {
                      setCurrent({
                        type: `${data.value}`,
                        time: "",
                      });
                    }

                    // nav to step 2 if 時刻...
                    if (data.value === "13") {
                      setShow2ndStep(true);
                      // サイズを更新する
                      setActionSheetHeight(ACTION_SHEET_MIDDLE_SIZE);
                    }
                  }}
                >
                  <IonLabel className={styles.item}>{data.text}</IonLabel>
                  {`${data.value}` === `${current.type}` && (
                    <AppIcon icon={ICONS.ICON.CHECK} size="sm" />
                  )}
                </div>
                {index !== timingOptions.length - 1 && <div className="page-spacer-30" />}
              </Fragment>
            ))}

            <div className="page-spacer-45" />
          </div>
        </div>

        <div className={styles.sliderWrapper}>
          <div className={styles.header}>
            <AppIcon
              icon={ICONS.ICON.ARROW_CIRCLE_BACK}
              className={styles.backIcon}
              onClick={() => {
                // nav to step 1
                setShow2ndStep(false);
                // もとのサイズに戻ります。
                setActionSheetHeight(0);
              }}
            />
            <IonLabel className={styles.title}>
              {intl.formatMessage({ id: "page.timing_action_sheet.header.time_slider.time" })}
            </IonLabel>
            <IonLabel className={styles.confrim} onClick={handleConfirmOnStep2}>
              {intl.formatMessage({ id: "page.timing_action_sheet.header.time_slider.confirm" })}
            </IonLabel>
          </div>

          <div className={`${styles.body} ${styles.scrollable}`}>
            <div className="page-spacer-45" />

            <AppDoublePicker
              ref={timerRef}
              initVal={current.time}
              defaultVal={"7:00"}
              options={HOUR_KV_OPTIONS}
              options2={MINUTE_STEP_KV_OPTIONS}
              separatedBy=":"
              isLoop={true}
            />
          </div>
        </div>
      </div>
    </AppFixedActionSheet>
  );
}
