import { useState } from "react";
import { useIonAlert, useIonViewWillEnter } from "@ionic/react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { useVersion } from "src/web/hooks/useVersion";
import { useMenuModel, useUserBaseInfoModel } from "src/web/hooks/models";
import { signOut } from "firebase/auth";
import { auth } from "src/web/firebase";
import { MenuType } from "src/web/types";
import { USER_BASIC_INFO_TYPE } from "src/web/constants";
import { useIntl } from "react-intl-phraseapp";

export function useMenuTabControl() {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const [presentAlert] = useIonAlert();
  const { getCurrentVersion } = useVersion();
  const { fetchUserBaseInfoWithDesc } = useUserBaseInfoModel();
  const { fetchOtherDocumentForMenu } = useMenuModel();

  const [isLoading, setIsLoading] = useState(false);
  const [menuList, setMenuList] = useState<MenuType[]>([
    {
      title: intl.formatMessage({ id: "page.menu_tab.account.title" }),
      subTitles: [
        {
          title: intl.formatMessage({ id: "page.menu_tab.account.change_email.title" }),
          url: "/settings/accounts/change-email",
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.account.change_password.title" }),
          url: "/settings/accounts/change-password/input",
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.account.connect_account.title" }),
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.account.logout.title" }),
          hideIcon: true,
          event: () => {
            presentAlert({
              header: intl.formatMessage({ id: "page.menu_tab.account.logout.alert.title" }),
              mode: "ios",
              buttons: [
                {
                  text: intl.formatMessage({ id: "page.menu_tab.account.logout.alert.cancel" }),
                  role: "cancel",
                  handler: () => {},
                },
                {
                  text: intl.formatMessage({ id: "page.menu_tab.account.logout.alert.confirm" }),
                  role: "confirm",
                  handler: () => {
                    signOut(auth)
                      .then(() => {})
                      .catch(() => {})
                      .finally(() => {
                        ionRouter.push("/login");
                      });
                  },
                },
              ],
            });
          },
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.account.withdraw.title" }),
          url: "/settings/accounts/withdraw",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "page.menu_tab.user_profile.title" }),
      subTitles: [
        {
          title: intl.formatMessage({ id: "page.menu_tab.user_profile.name.title" }),
          url: `/modify-user-base-info/${USER_BASIC_INFO_TYPE.NAME}`,
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.user_profile.birthday.title" }),
          url: `/modify-user-base-info/${USER_BASIC_INFO_TYPE.BIRTHDAY}`,
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.user_profile.gender.title" }),
          url: `/modify-user-base-info/${USER_BASIC_INFO_TYPE.GENDER}`,
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.user_profile.prefecture.title" }),
          url: `/modify-user-base-info/${USER_BASIC_INFO_TYPE.PREFECTURE}`,
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "page.menu_tab.basic_setting.title" }),
      subTitles: [
        {
          title: intl.formatMessage({ id: "page.menu_tab.basic_setting.passcode.title" }),
          url: "/screen-locker",
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.basic_setting.notification.title" }),
          url: "/settings/basic/notifications",
        },
        {
          title: intl.formatMessage({
            id: "page.menu_tab.basic_setting.notification_setting.title",
          }),
          url: "/menu/symptom-setting",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "page.menu_tab.device_app_connection.title" }),
      subTitles: [
        {
          title: intl.formatMessage({
            id: "page.menu_tab.device_app_connection.auto_data_fetching.title",
          }),
          url: "/settings/auto-data-fetching",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "page.menu_tab.ai_assistant.title" }),
      subTitles: [
        {
          title: intl.formatMessage({ id: "page.menu_tab.ai_assistant.siri_setting.title" }),
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.ai_assistant.google_setting.title" }),
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.ai_assistant.alexa_setting.title" }),
          url: "/menu/alexa-setting",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "page.menu_tab.support.title" }),
      subTitles: [
        {
          title: intl.formatMessage({ id: "page.menu_tab.support.announcement.title" }),
          url: "/settings/supports/anouncements",
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.support.tutorial.title" }),
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.support.faq.title" }),
          url: "/settings/faqs",
        },
        {
          title: intl.formatMessage({ id: "page.menu_tab.support.inquiry.title" }),
          url: "/settings/contacts/input",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "page.menu_tab.others.title" }),
      subTitles: [
        {
          title: intl.formatMessage({ id: "page.menu_tab.others.version.title" }),
          hideIcon: true,
        },
      ],
    },
  ]);

  const doFetch = async () => {
    setIsLoading(true);

    // fetching ユーザー情報
    const userInfoData = await fetchUserBaseInfoWithDesc();
    if (userInfoData) {
      const MAPS = [
        USER_BASIC_INFO_TYPE.NAME,
        USER_BASIC_INFO_TYPE.BIRTHDAY,
        "genderDesc",
        "prefectureDesc",
      ];
      for (let i = 0; i < 4; i++) {
        menuList[1].subTitles[i].desc = userInfoData[MAPS[i]];
      }
    }

    // その他
    if (menuList[6].subTitles.length === 1) {
      // if その他 menu list not fetched
      const othersList = await fetchOtherDocumentForMenu();
      menuList[6].subTitles = [...othersList, ...menuList[6].subTitles];
    }

    // その他: app version
    try {
      const version = (await getCurrentVersion()).version;
      if (version) {
        menuList[6].subTitles[menuList[6].subTitles.length - 1].desc = version;
      }
    } catch (error) {}

    setIsLoading(false);

    setMenuList([...menuList]);
  };

  useIonViewWillEnter(() => {
    doFetch();
  });

  return {
    isLoading,
    menuList,
  };
}
