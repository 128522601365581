import { IonContent, IonLabel, IonPage } from "@ionic/react";
import { AppFloatButton, AppLogo } from "../../../components/UiComponents";
import { useChangePasswordSuccessControl } from "./hooks/useChangePasswordSuccessControl";
import { useIntl } from "react-intl-phraseapp";

export function ChangePasswordSuccess() {
  const intl = useIntl();
  const { navToLogin } = useChangePasswordSuccessControl();

  return (
    <IonPage>
      <AppLogo />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <IonLabel className="text-center-block p-header2">
            {intl.formatMessage({ id: "page.change_password_success.message.line1" })}
          </IonLabel>
          <IonLabel className="text-center-block p-header2">
            {intl.formatMessage({ id: "page.change_password_success.message.line2" })}
          </IonLabel>

          <div className="page-spacer-30" />

          <AppFloatButton
            label={intl.formatMessage({ id: "page.change_email_success.login" })}
            isStatic
            onClick={navToLogin}
          />
        </div>
      </IonContent>
    </IonPage>
  );
}
