import { actionConst } from "../constants";
import { LoadingWeekChartType } from "../types";

interface Actions {
  type: actionConst;
  state: LoadingWeekChartType;
}

const initState = {
  isLoading: false,
};

export default function scoreTransitionWeekChartReducer(state = initState, actions: Actions) {
  switch (actions.type) {
    case actionConst.UPDATE_SCORE_TRANSITION_WEEK_CHART:
      return actions.state;
    default:
      return state;
  }
}
