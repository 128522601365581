import { IonLabel } from "@ionic/react";
import { AppIcon } from "src/web/components/UiComponents";
import { ICONS } from "src/web/constants";
import styles from "../../chartSummaryPanel.module.css";
import { SummaryDescription } from "../SummaryDescription/summaryDescription";
import { useIntl } from "react-intl-phraseapp";

interface AverageSummaryProps {
  type: string;
  data: any;
  isMonthChart?: boolean;
}

export const AverageSummary = ({ type, data, isMonthChart = false }: AverageSummaryProps) => {
  const intl = useIntl();
  const config: any = {
    BODY_TEMPERATURE: {
      icon: ICONS.TRACKING.THERMOMETER,
      label: `${intl.formatMessage({
        id: "components.charts.chart_summary_panel.average_summary.temperature",
      })} ${
        isMonthChart
          ? intl.formatMessage({
              id: "components.charts.chart_summary_panel.average_summary.unit.month_chart",
            })
          : intl.formatMessage({
              id: "components.charts.chart_summary_panel.average_summary.unit.week_chart",
            })
      }`,
    },
    BLOOD_PRESSURE: {
      icon: ICONS.TRACKING.BLOOD_PRESSURE,
      label: `${intl.formatMessage({
        id: "components.charts.chart_summary_panel.average_summary.blood_pressure",
      })} ${
        isMonthChart
          ? intl.formatMessage({
              id: "components.charts.chart_summary_panel.average_summary.unit.month_chart",
            })
          : intl.formatMessage({
              id: "components.charts.chart_summary_panel.average_summary.unit.week_chart",
            })
      }`,
    },
    ACTIVITY: {
      icon: ICONS.TRACKING.EXERCISE,
      label: `${intl.formatMessage({
        id: "components.charts.chart_summary_panel.average_summary.activity",
      })} ${
        isMonthChart
          ? intl.formatMessage({
              id: "components.charts.chart_summary_panel.average_summary.unit.month_chart",
            })
          : intl.formatMessage({
              id: "components.charts.chart_summary_panel.average_summary.unit.week_chart",
            })
      }`,
    },
  };

  return (
    <div className={styles.avgLineWrapper}>
      <div className={styles.avgIcon}>
        <AppIcon icon={config[type].icon} />
      </div>
      <div>
        <IonLabel className={`p-body gray text-block ${styles.avgTitle}`}>
          {config[type].label}
        </IonLabel>
        <div className="page-spacer-15" />
        <SummaryDescription type={type} data={data} />
      </div>
    </div>
  );
};
