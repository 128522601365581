import { useState } from "react";
import { useIonAlert } from "@ionic/react";
import { useVersion } from "./useVersion";
import { useLoadingDisplay } from "./useLoadingDisplay";
import { useIntl } from "react-intl-phraseapp";

export function useAppUpdater() {
  const intl = useIntl();
  const [presentAlert] = useIonAlert();
  const [presentNotificationAlert] = useIonAlert();
  const { getLatestVersion, getCurrentVersion, checkIsUpdatable, updateVersion } = useVersion();

  const { setIsLoadingVisible } = useLoadingDisplay();

  const updateApp = async () => {
    const currentVersionInfo = await getCurrentVersion();
    let latestVersionInfo: any = null;
    try {
      latestVersionInfo = await getLatestVersion(currentVersionInfo);
    } catch (exception) {
      console.log(exception);
      return;
    }
    if (!checkIsUpdatable(latestVersionInfo, currentVersionInfo)) {
      return;
    }

    return new Promise((resolve, reject) => {
      presentAlert({
        header: intl.formatMessage(
          { id: "hooks.use_app_updater.dialog.header" },
          { version: latestVersionInfo.version },
        ),
        mode: "ios",
        buttons: [
          {
            text: intl.formatMessage({ id: "hooks.use_app_updater.dialog.cancel" }),
            role: "cancel",
            handler: () => {
              resolve(false);
            },
          },
          {
            text: intl.formatMessage({ id: "hooks.use_app_updater.dialog.confirm" }),
            role: "confirm",
            handler: async () => {
              setIsLoadingVisible(true);
              try {
                await updateVersion(latestVersionInfo, () => {
                  setIsLoadingVisible(false);
                  return new Promise((r) => setTimeout(r, 500));
                });
                resolve(true);
              } catch (exception) {
                // 失敗のアラートを出す
                setIsLoadingVisible(false);
                await presentNotificationAlert({
                  header: intl.formatMessage(
                    { id: "hooks.use_app_updater.dialog.header" },
                    { error: JSON.stringify(exception, Object.getOwnPropertyNames(exception)) },
                  ),
                  mode: "ios",
                  buttons: [
                    {
                      text: intl.formatMessage({
                        id: "hooks.use_app_updater.error_dialog.confirm",
                      }),
                      role: "confirm",
                      handler: () => {
                        resolve(true);
                      },
                    },
                  ],
                });
              }
            },
          },
        ],
      });
    });
  };

  return {
    updateApp,
  };
}
