import { actionConst } from "../constants";
import { LoadingAnswerAndStatusType } from "src/web/types";

interface Action {
  type: actionConst;
  state: LoadingAnswerAndStatusType;
}

const initialState = {
  isLoading: false,
};

export default function dailyAnswersAndStatusReducer(state = initialState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_DAILY_ANSWERS_AND_STATUS:
      return actions.state;
    default:
      return state;
  }
}
