import { AppCheckbox } from "../../AppCheckbox/appCheckbox";
import { PageTitle } from "../../moleclues";
import { TitleWithDescription } from "../";
import { useIntl } from "react-intl-phraseapp";

interface WithdrawViewProps {
  title: string;
  subTitle?: string;
  contents: {
    title: string;
    text: string;
  }[];
  checked?: boolean;
  handleCheckboxClick?: () => void;
}

export function WithdrawView({
  title,
  subTitle,
  contents,
  checked = false,
  handleCheckboxClick,
}: WithdrawViewProps) {
  const intl = useIntl();
  return (
    <>
      <PageTitle title={title} subTitle={subTitle} />

      <div className="page-spacer-45" />

      {contents.map((data, index) => (
        <TitleWithDescription title={data.title} text={data.text} key={index} />
      ))}

      {!!handleCheckboxClick && (
        <>
          <div className="page-spacer-45" />
          <AppCheckbox
            label={intl.formatMessage({ id: "page.delete_user.label.reserve" })}
            checked={checked}
            onClick={handleCheckboxClick}
            size="small"
          />
        </>
      )}
    </>
  );
}
