import { IonIcon } from "@ionic/react";
import styles from "./appIcon.module.css";

interface IconProps {
  icon: string;
  size?: "xl" | "lg" | "md" | "sm" | "xs" | "xxs" | "illustration" | "special";
  className?: string;
  onClick?: () => void;
  onMouseDown?: (e: any) => void;
  disabled?: boolean;
  /* set to true if want to change the icon color, otherwise the icon will be black as default
    the flag is not working on some of the icons if the svg file is using fill instead of stroke property */
  color?: "black" | "white" | "others";
}

export function AppIcon({
  icon,
  size = "md",
  className,
  onClick,
  disabled,
  onMouseDown,
  color = "black",
}: IconProps) {
  let disableFlg = disabled === true;
  return (
    // <div className={`${styles.root} ${disabled ? styles.disabled : ""}`}>
    <IonIcon
      icon={require(`../../../assets/svgs/icons/${icon}.svg`)}
      className={`${color === "black" ? styles.black : color === "white" ? styles.white : ""} 
        ${size ? styles[size] : ""}
        ${className ? className : ""}
        ${disableFlg && styles.disabledIcon}`}
      onMouseDown={(e) => {
        onMouseDown && onMouseDown(e);
      }}
      onClick={() => {
        if (!disableFlg) {
          onClick && onClick();
        }
      }}
    />
    // </div>
  );
}
