import { Fragment } from "react";
import { IonLabel } from "@ionic/react";
import { SCORE_TAB_KEY } from "src/web/constants";
import { ChartDataTypeV2 } from "../LineBarScatterChart/lineBarScatterChartV2";
import { AverageSummary } from "./components/AverageSummary/averageSummary";
import { useIntl } from "react-intl-phraseapp";
import { DateWithScoreHeaderLine } from "src/web/components/UiComponents/moleclues";
import styles from "./chartSummaryPanel.module.css";

interface HalfYearSummaryPanelProps {
  data: ChartDataTypeV2;
  scoreType: string;
}

export function HalfYearSummaryPanel({ data, scoreType }: HalfYearSummaryPanelProps) {
  const intl = useIntl();
  const HALF_YEAR_SUMMARY_FOR_AVG = {
    [SCORE_TAB_KEY[0]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.overall",
      }),
      overall: ["BODY_TEMPERATURE", "BLOOD_PRESSURE", "ACTIVITY"],
      timelineType: "",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.overall.no_record",
      }),
    },
    [SCORE_TAB_KEY[1]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.taking_medicine",
      }),
      overall: [],
      timelineType: "TAKING_MEDICINE",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.taking_medicine.no_record",
      }),
    },
    [SCORE_TAB_KEY[2]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.temperature",
      }),
      overall: [],
      timelineType: "BODY_TEMPERATURE",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.temperature.no_record",
      }),
    },
    [SCORE_TAB_KEY[3]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.blood_pressure",
      }),
      overall: [],
      timelineType: "BLOOD_PRESSURE",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.blood_pressure.no_record",
      }),
    },
    [SCORE_TAB_KEY[4]]: {
      text: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.activity",
      }),
      overall: [],
      timelineType: "ACTIVITY",
      noDataText: intl.formatMessage({
        id: "components.charts.chart_summary_panel.summary_panel.tab.activity.no_record",
      }),
    },
  };

  return (
    <>
      <div className={styles.weekDetailDiv}>
        <div className="page-spacer-25" />

        <DateWithScoreHeaderLine
          date={data.tooltipTitle}
          score={data.score}
          label={intl.formatMessage({
            id: "components.score_chart.score.average",
          })}
        />

        <div className="page-spacer-45" />
        <IonLabel className="p-header3">{HALF_YEAR_SUMMARY_FOR_AVG[scoreType].text}</IonLabel>
        <div className="page-spacer-15" />
        <hr className="title-hr" />

        {scoreType === SCORE_TAB_KEY[0] && (
          <>
            <div className="page-spacer-50" />
            <div className={styles.avgHalfYearScore}>
              <IonLabel className="text-block p-body gray">
                {intl.formatMessage({
                  id: "components.charts.chart_summary_panel.summary_panel.score.yearly_average",
                })}
              </IonLabel>
              <div className="page-spacer-8" />
              <IonLabel className="text-block p-score-panel item_group">
                {data.halfYearAverageScore}
              </IonLabel>
            </div>
          </>
        )}

        {scoreType === SCORE_TAB_KEY[0] &&
          !!data.overallData &&
          HALF_YEAR_SUMMARY_FOR_AVG[scoreType].overall.map((key, index) => (
            <div className={`${scoreType !== "score_tab" ? styles.avgRoot : ""}`} key={index}>
              <div className="page-spacer-45" />
              <AverageSummary type={key} data={data.overallData[key]} isMonthChart />
            </div>
          ))}

        {scoreType !== SCORE_TAB_KEY[0] && (
          <>
            {data.tooltipDetails[scoreType].map((item: any, index: number) => (
              <Fragment key={index}>
                <div className="page-spacer-45" />
                <IonLabel className="text-block p-body gray">{item.name}</IonLabel>
                <div className="page-spacer-8" />
                {item.list.map((text: string) => (
                  <IonLabel className="text-block p-smallbody" key={text}>
                    {text}
                  </IonLabel>
                ))}
              </Fragment>
            ))}
          </>
        )}

        <div className="page-spacer-25" />
      </div>
    </>
  );
}
