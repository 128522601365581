import { IonLabel } from "@ionic/react";
import { useMemo } from "react";
import { useCommon } from "src/web/utils/hooks/common";

interface DateWithInstructionProps {
  dateStr: string;
  header2: string;
  text?: string;
}

export function DateWithInstruction({ dateStr, header2, text }: DateWithInstructionProps) {
  const { convertToDate } = useCommon();
  const formattedDate = useMemo(() => convertToDate(dateStr), [dateStr]);

  return (
    <>
      <IonLabel className="p-body text-center-block pre-wrap">{formattedDate}</IonLabel>

      <div className="page-spacer-15" />

      <IonLabel className="p-header2 text-center-block pre-wrap">{header2}</IonLabel>

      {!!text && (
        <>
          <div className="page-spacer-15" />
          <IonLabel className="p-body text-center-block pre-wrap">{text}</IonLabel>
        </>
      )}
    </>
  );
}
