import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DATE_FORMAT_SPLIT, MONTH_PERIOD, SCORE_TAB_KEY } from "src/web/constants";
import { useAdviceModel } from "src/web/hooks/models";
import {
  getDateStrByDateNoPad,
  getYearMonthByDate,
  loopEmptyCheckForObjectAndArray,
} from "src/web/utils/common";
import { RootState } from "src/web/reducer";
import {
  LoadingMonthChartType,
  MonthBarChartDataType,
  MedicineLineChartDataType,
  MonthChartType,
} from "src/web/types";
import { useIntl } from "react-intl-phraseapp";

export function useMonthChartControl(currentMonthStartDate: string) {
  const intl = useIntl();
  const { scoreTransitionMonthChart }: { scoreTransitionMonthChart: LoadingMonthChartType } =
    useSelector((state: RootState) => state);

  const { fetchHalfYearIngestionAdvice } = useAdviceModel();

  const [monthlyIngestionAdvice, setMonthlyIngestionAdvice] = useState<string>("");
  const [isNoData, setIsNoData] = useState(false);
  const [barChartData, setBarChartData] = useState<any[]>();
  const [allMedicineChartData, setAllMedicineChartData] = useState<MedicineLineChartDataType>({
    xData: [],
    data: [],
  });
  const [first5MedicineChartData, setFirst5MedicineChartData] = useState<MedicineLineChartDataType>(
    {
      xData: [],
      data: [],
    },
  );

  const getMonthlyAdvice = async () => {
    const advice = await fetchHalfYearIngestionAdvice(currentMonthStartDate);
    setMonthlyIngestionAdvice(advice);
  };

  const refactChartData = () => {
    const getNextMonthKey = (yearMonth: string) => {
      const monthDate = new Date(`${yearMonth}${DATE_FORMAT_SPLIT}01`);
      monthDate.setMonth(monthDate.getMonth() + 1);
      return {
        key: getYearMonthByDate(monthDate),
        month: monthDate.getMonth() + 1,
      };
    };

    // generate medicine period information in details card
    const generatePeriodMedicineInfo = (
      periodStartMonth: string,
      { startIndex, endIndex }: { startIndex: number; endIndex: number },
    ) => {
      const periodStartMonthArray = periodStartMonth.split(DATE_FORMAT_SPLIT);

      let startMonth = parseInt(periodStartMonthArray[1]) + startIndex / 2;
      let startDate = startMonth % 1 > 0 ? 16 : 1;

      let endMonth = parseInt(periodStartMonthArray[1]) + endIndex / 2;
      let endDate = endMonth % 1 > 0 ? 0 : 15;

      if (!endDate) {
        const firstDayOfNextMonth = new Date(
          `${periodStartMonthArray[0]}${DATE_FORMAT_SPLIT}${Math.floor(
            endMonth,
          )}${DATE_FORMAT_SPLIT}1`,
        );
        firstDayOfNextMonth.setMonth(firstDayOfNextMonth.getMonth() + 1);
        firstDayOfNextMonth.setDate(firstDayOfNextMonth.getDate() - 1);
        endDate = firstDayOfNextMonth.getDate();
      }

      return `${periodStartMonthArray[0]}${DATE_FORMAT_SPLIT}${Math.floor(
        startMonth,
      )}${DATE_FORMAT_SPLIT}${startDate}~${
        periodStartMonthArray[0]
      }${DATE_FORMAT_SPLIT}${Math.floor(endMonth)}${DATE_FORMAT_SPLIT}${endDate}`;
    };

    // generate 体温の記録
    const generateTemperatureData = (
      year: string,
      month: string,
      overallData: any,
      temperature: any,
    ) => {
      return [
        {
          name: intl.formatMessage(
            { id: "page.symptom_records_main.temperature.average.year" },
            { year },
          ),
          list: [
            `${
              overallData.BODY_TEMPERATURE.temperature
                ? `${overallData.BODY_TEMPERATURE.temperature}℃`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
          ],
        },
        {
          name: intl.formatMessage(
            { id: "page.symptom_records_main.temperature.average.week" },
            { month: parseInt(month) },
          ),
          list: [
            `${
              temperature
                ? intl.formatMessage({ id: "common.temperature.unit" }, { temperature })
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
          ],
        },
      ];
    };

    // generate 血圧の記録
    const generateBloodPressure = (
      year: string,
      month: string,
      overallData: any,
      sbp?: string,
      dbp?: string,
      hr?: string,
    ) => {
      return [
        {
          name: intl.formatMessage(
            { id: "page.symptom_records_main.blood_pressure.average.year" },
            { year },
          ),
          list: [
            `intl.formatMessage({id: "page.symptom_records_main.blood_pressure.sbp.label"})：${
              overallData.BLOOD_PRESSURE.sbp
                ? `${overallData.BLOOD_PRESSURE.sbp} ${intl.formatMessage({
                    id: "common.blood_pressure.unit",
                  })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
            `intl.formatMessage({id: "page.symptom_records_main.blood_pressure.dbp.label"})：${
              overallData.BLOOD_PRESSURE.dbp
                ? `${overallData.BLOOD_PRESSURE.dbp} ${intl.formatMessage({
                    id: "common.blood_pressure.unit",
                  })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
            `intl.formatMessage({id: "page.symptom_records_main.blood_pressure.pulse.label"})：${
              overallData.BLOOD_PRESSURE.hr
                ? `${overallData.BLOOD_PRESSURE.hr} ${intl.formatMessage({
                    id: "common.pulse.unit",
                  })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
          ],
        },
        {
          name: intl.formatMessage(
            { id: "page.symptom_records_main.blood_pressure.average.month" },
            { month: parseInt(month) },
          ),
          list: [
            `${intl.formatMessage({ id: "page.symptom_records_main.blood_pressure.sbp.label" })}：${
              sbp
                ? `${sbp} ${intl.formatMessage({ id: "common.blood_pressure.unit" })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
            `${intl.formatMessage({ id: "page.symptom_records_main.blood_pressure.dbp.label" })}：${
              dbp
                ? `${dbp} ${intl.formatMessage({ id: "common.blood_pressure.unit" })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
            `${intl.formatMessage({
              id: "page.symptom_records_main.blood_pressure.pulse.label",
            })}：${
              hr
                ? `${hr} ${intl.formatMessage({ id: "common.pulse.unit" })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
          ],
        },
      ];
    };

    // generate 活動の記録
    const generateActivity = (
      year: string,
      month: string,
      overallData: any,
      steps?: string,
      duration?: string,
    ) => {
      return [
        {
          name: intl.formatMessage(
            { id: "page.symptom_records_main.activity.average.year" },
            { year },
          ),
          list: [
            `${intl.formatMessage({ id: "page.symptom_records_main.walks.label" })}：${
              overallData.ACTIVITY.steps
                ? `${overallData.ACTIVITY.steps} ${intl.formatMessage({ id: "common.walks.unit" })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
            `${intl.formatMessage({ id: "page.symptom_records_main.activity.label" })}：${
              overallData.ACTIVITY.duration
                ? `${overallData.ACTIVITY.duration} ${intl.formatMessage({
                    id: "common.activity.unit",
                  })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
          ],
        },
        {
          name: intl.formatMessage(
            { id: "page.symptom_records_main.activity.average.month" },
            { month: parseInt(month) },
          ),
          list: [
            `${intl.formatMessage({ id: "page.symptom_records_main.walks.label" })}：${
              steps
                ? `${steps} ${intl.formatMessage({ id: "common.walks.unit" })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
            `${intl.formatMessage({ id: "page.symptom_records_main.activity.label" })}：${
              duration
                ? `${duration} ${intl.formatMessage({ id: "common.activity.unit" })}`
                : intl.formatMessage({ id: "page.symptom_records_main.no_score" })
            }`,
          ],
        },
      ];
    };

    // generate 全体, 体温, 血圧 and 活動 棒図
    const generateBarChart = (monthData: MonthChartType) => {
      // 棒図のデータ
      const barData = monthData.barData;

      // 体温, 血圧 and 活動 for every month
      const overallDataEveryMonth = monthData.overallDataEveryMonth;

      // 体温, 血圧 and 活動 for year
      const overallData = monthData.overallData;

      let nextMonthKey: any = {
        key: monthData.startDate,
        month: new Date(`${monthData.startDate}${DATE_FORMAT_SPLIT}01`).getMonth() + 1,
      };
      let sortedKey = [nextMonthKey];

      for (let i = 1; i < MONTH_PERIOD; i++) {
        nextMonthKey = getNextMonthKey(nextMonthKey.key);
        sortedKey.push(nextMonthKey);
      }

      let tmpBarData: MonthBarChartDataType[] = [];
      // x axis data for medicine 線図
      let tmpLineXData: any[] = [];
      sortedKey.forEach(({ key, month }, index) => {
        let tooltipMedicines: any[] = []; // 服薬の記録

        Object.values(scoreTransitionMonthChart!.data?.lineData).forEach((item: any) => {
          // 下記の判断はこの月でデータを表示する
          if (item.month_check[index * 2] || item.month_check[index * 2 + 1]) {
            // 全ての範囲をチェックする
            let indexArray: Array<{ startIndex: number; endIndex: number }> = [];
            let startIndex = -1;
            for (let i = 0; i < 12; i++) {
              // データ存在
              if (item.month_check[i]) {
                if (startIndex < 0) {
                  startIndex = i;
                }
                // 時間帯切りかつ開始期間がある
              } else if (startIndex >= 0) {
                indexArray.push({
                  startIndex: startIndex,
                  endIndex: i - 1,
                });
                // 存在しない
                startIndex = -1;
              }
            }

            // 最後日の判断を追加する
            if (startIndex >= 0) {
              indexArray.push({
                startIndex: startIndex,
                endIndex: 11,
              });
            }

            // 服薬の記録
            const medicineListItems = indexArray.map((indexs, index) =>
              generatePeriodMedicineInfo(
                `${currentMonthStartDate.split(DATE_FORMAT_SPLIT)[0]}${DATE_FORMAT_SPLIT}${parseInt(
                  currentMonthStartDate.split(DATE_FORMAT_SPLIT)[1],
                )}`,
                indexs,
              ),
            );
            tooltipMedicines.push({
              name: item.m_name,
              list: medicineListItems,
            });
          }
        });

        let [currentYear, currentMonth] = key.split(DATE_FORMAT_SPLIT);
        // スコア
        let score = barData[key].score || 0;
        // 体温
        const temperature = overallDataEveryMonth[key]?.BODY_TEMPERATURE
          ? overallDataEveryMonth[key].BODY_TEMPERATURE.temperature
          : undefined;
        // 最高血圧, 最低血圧, 脈拍
        const { sbp, dbp, hr } = overallDataEveryMonth[key]?.BLOOD_PRESSURE
          ? overallDataEveryMonth[key].BLOOD_PRESSURE
          : { sbp: undefined, dbp: undefined, hr: undefined };
        // 活動, 歩数
        const { duration, steps } = overallDataEveryMonth[key]?.ACTIVITY
          ? overallDataEveryMonth[key]?.ACTIVITY
          : { duration: undefined, steps: undefined };

        // 体温の記録
        const tooltipTemperature: any[] = generateTemperatureData(
          currentYear,
          currentMonth,
          overallData,
          temperature,
        );

        // 血圧の記録
        const tooltipBloodPressure: any[] = generateBloodPressure(
          currentYear,
          currentMonth,
          overallData,
          sbp,
          dbp,
          hr,
        );

        // 活動の記録
        const tooltipActivity: any[] = generateActivity(
          currentYear,
          currentMonth,
          overallData,
          steps,
          duration,
        );

        tmpBarData.push({
          name: `${month}${intl.formatMessage({ id: "ui_components.app_calendar.unit.month" })}`,
          score,
          dspScore: score * 1.2,
          tooltipTitle: `${currentYear}${DATE_FORMAT_SPLIT}${parseInt(currentMonth)}`,
          tooltipTitleYYYYMM: key,
          tooltipDetails: {
            [SCORE_TAB_KEY[1]]: tooltipMedicines, // 服薬の記録
            [SCORE_TAB_KEY[2]]: tooltipTemperature, // 体温の記録
            [SCORE_TAB_KEY[3]]: tooltipBloodPressure, // 血圧の記録
            [SCORE_TAB_KEY[4]]: tooltipActivity, // 活動の記録
          },
          temperature,
          sbp,
          dbp,
          activityDuration: duration,
          halfYearAverageScore: monthData?.halfYearAverageScore,
          overallData: {
            BODY_TEMPERATURE: {
              ...monthData.overallData.BODY_TEMPERATURE,
              temperatureToday: temperature,
            },
            BLOOD_PRESSURE: {
              ...monthData.overallData.BLOOD_PRESSURE,
              sbpToday: sbp,
              dbpToday: dbp,
              hrToday: hr,
            },
            ACTIVITY: {
              ...monthData.overallData.ACTIVITY,
              durationToday: duration,
              stepsToday: steps,
            },
          },
        });

        // x axis data for medicine 線図
        tmpLineXData.push({
          name: `${month}${intl.formatMessage({ id: "ui_components.app_calendar.unit.month" })}`,
        });
      });

      setBarChartData(tmpBarData);
      return tmpLineXData;
    };

    // generate 薬 線図
    const generateMedicineChart = (monthData: MonthChartType) => {
      let tempLineData: any[] = [];

      Object.entries(monthData.lineData).map(([id, detail]: any) => {
        let singleLineChartdata: any[] = [];
        for (let i = 0; i < detail.month_check.length; i++) {
          let record: any = undefined;
          if (detail.month_check[i]) {
            let count = 0;
            /* check if the current is included in the the previous node count
              include it only when it was not counted
            */
            if (!!detail.month_check[i - 1]) {
              record = {};
            } else {
              let j = i;
              for (; j < detail.month_check.length; j++) {
                if (detail.month_check[j]) {
                  count += 1;
                } else {
                  break;
                }
              }

              let tooltipPeriodStartDate = new Date(currentMonthStartDate);
              tooltipPeriodStartDate.setMonth(
                tooltipPeriodStartDate.getMonth() + Math.floor(i / 2),
              );
              tooltipPeriodStartDate.setDate(i % 2 === 0 ? 1 : 16);

              let tooltipPeriodEndDate = new Date(currentMonthStartDate);
              tooltipPeriodEndDate.setMonth(tooltipPeriodEndDate.getMonth() + Math.floor(j / 2));
              if (j % 2 === 0) {
                tooltipPeriodEndDate.setDate(tooltipPeriodEndDate.getDate() - 1);
              } else {
                tooltipPeriodEndDate.setDate(15);
              }

              record = {
                y: 1,
                yName: detail.m_name,
                value: count,
                tooltip: {
                  title: detail.m_name,
                  content: `${getDateStrByDateNoPad(
                    tooltipPeriodStartDate,
                  )}~${getDateStrByDateNoPad(tooltipPeriodEndDate)}`,
                },
              };
            }
          } else {
            record = {};
          }
          singleLineChartdata.push(record);
        }

        tempLineData.push(singleLineChartdata);
      });
      return tempLineData;
    };

    if (!scoreTransitionMonthChart.data && !scoreTransitionMonthChart.isLoading) {
      setIsNoData(true);
      return;
    }

    const monthData = scoreTransitionMonthChart.data;
    if (monthData) {
      setIsNoData(checkIsNoData(monthData));

      // 全体, 体温, 血圧 and 活動 棒図
      const xData: any = generateBarChart(monthData);

      // medicine 線図
      const data: any = generateMedicineChart(monthData);

      setAllMedicineChartData({
        xData,
        data,
      });

      setFirst5MedicineChartData({
        xData,
        data: data.slice(0, 5),
      });
    }
  };

  // check for no data
  const checkIsNoData = (data: any) => {
    const params = ["barData", "lineData", "overallDataEveryMonth"];
    const isEmpty =
      params.every((p: string) => loopEmptyCheckForObjectAndArray(data[p])) ||
      Object.keys(data.overallData || {}).length === 0;

    return isEmpty;
  };

  useEffect(() => {
    // switch month
    getMonthlyAdvice();
  }, [currentMonthStartDate]);

  useEffect(() => {
    refactChartData();
  }, [scoreTransitionMonthChart.data]);

  return {
    monthlyIngestionAdvice,
    barChartData,
    first5MedicineChartData,
    allMedicineChartData,
    isNoData,
  };
}
