import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSymptomModel } from "../../../../../../hooks/models";
import { useAppRouter } from "../../../../../../hooks/useAppRouter";
import { OpenCurrentSymptomType } from "../currentSymptomActionsheet";
import { PageActionCode, PageCode } from "../../../../../../types";
import { updateTimelineScrollHook } from "src/web/actions/timelineScrollHookAction";

export function useCurrentSymptomActionsheetControl(
  data: OpenCurrentSymptomType,
  handleClose: () => void,
) {
  const ionRouter = useAppRouter();

  const dispatch = useDispatch();

  const { isLoadingSymptom, saveSymptom } = useSymptomModel();

  const [currentScore, setCurrentScore] = useState<number>();
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(currentScore === undefined);

  const handleComfirm = async () => {
    if (isConfirmDisabled) {
      return;
    }

    try {
      await saveSymptom(
        {
          pageCode: PageCode.T01,
          pageActionCode: PageActionCode.T01_A02,
        },
        { ...data, currentScore, isMerge: true },
      );
      dispatch(
        updateTimelineScrollHook(
          `?time=${data?.nowTime?.replace(":", "").padStart(4, "0")}&type=SYMPTOM`,
        ),
      );
      handleClose();
      ionRouter.push(`/home/reports`);
    } catch (error) {}
  };

  useEffect(() => {
    setIsConfirmDisabled(currentScore === undefined);
  }, [currentScore]);

  useEffect(() => {
    if (!data.isOpen) {
      setCurrentScore(undefined);
    }
  }, [data.isOpen]);

  return {
    isLoading: isLoadingSymptom,
    currentScore,
    isConfirmDisabled,
    setCurrentScore,
    handleComfirm,
  };
}
