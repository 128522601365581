import { useState } from "react";
import { useRouteMatch } from "react-router";
import { useIonViewWillEnter } from "@ionic/react";
import { AnouncementType } from "src/web/types";
import { useAnnouncementModel } from "src/web/hooks/models/useAnnouncementModel";

export function useSupportAnnouncementDetailControl() {
  const match: any = useRouteMatch();
  const noticeId: string = match.params.noticeId;

  const { fetchAnnouncemenContents } = useAnnouncementModel();

  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState<AnouncementType>();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = await fetchAnnouncemenContents(noticeId);
      if (data) {
        setInfo(data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useIonViewWillEnter(() => {
    fetchData();
  });

  return {
    isLoading,
    info,
  };
}
