import { useState } from "react";
import { IonPage, IonContent, IonFab, IonFabButton } from "@ionic/react";
import {
  AppFixedActionSheet,
  AppIcon,
  AppLogo,
  AppTab,
  ActionSheetCard,
} from "../../components/UiComponents";
import { MedicationManagement, ListOfMedicines, MedicineNotebook } from "./components";
import { ICONS, MEDICINE_TYPE, LocalStorageKey } from "../../constants";
import { clearMedicineInfo, updateMedicineInfo, updatePrescriptionInfo } from "../../actions";
import { PrescriptionInfoType } from "../PrescriptionInformation/type";
import { useDispatch } from "react-redux";
import { Storage } from "@capacitor/storage";
import { useAppRouter } from "../../hooks/useAppRouter";
import styles from "./medicationRecordTab.module.css";
import { useIntl } from "react-intl-phraseapp";

export function MedicationRecordTab() {
  const intl = useIntl();
  const iRouter = useAppRouter();
  const dispatch = useDispatch();

  const [outOpFlg, setOutOpFlg] = useState(0);

  const tabs = [
    {
      name: "MANAGEMENT",
      text: intl.formatMessage({ id: "page.medication_record_tab.tab.management" }),
    },
    {
      name: "VIEW",
      text: intl.formatMessage({ id: "page.medication_record_tab.tab.list_medicine" }),
    },
    {
      name: "BOOK",
      text: intl.formatMessage({ id: "page.medication_record_tab.tab.list_prescription" }),
    },
  ];

  const actionSheets = [
    {
      title: intl.formatMessage({
        id: "page.medication_record_tab.action_sheet.prescription_qr.title",
      }),
      icon: ICONS.IMAGE.QRCODE,
      desc: intl.formatMessage({
        id: "page.medication_record_tab.action_sheet.prescription_qr.description",
      }),
      onClick: async () => {
        setOpenFabActionSheet(false);

        dispatch(clearMedicineInfo());

        iRouter.push("/qr-scan");
      },
    },
    {
      title: intl.formatMessage({
        id: "page.medication_record_tab.action_sheet.prescription_link.title",
      }),
      icon: ICONS.IMAGE.DATA_INPUT,
      desc: intl.formatMessage({
        id: "page.medication_record_tab.action_sheet.prescription_link.description",
      }),
    },
    {
      title: intl.formatMessage({
        id: "page.medication_record_tab.action_sheet.prescription_manual.title",
      }),
      icon: ICONS.IMAGE.PRESCRIPTION,
      desc: intl.formatMessage({
        id: "page.medication_record_tab.action_sheet.prescription_manual.description",
      }),
      onClick: async () => {
        setOpenFabActionSheet(false);
        // 内容をクリアする
        // 新規情報
        let userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

        let val: PrescriptionInfoType = {
          type: MEDICINE_TYPE.PRESCRIPTION,
          user_id: userId.value ? userId.value : "",
          summary_fee: 0, //費用の初期値は0です。
        };
        dispatch(updatePrescriptionInfo(val));

        dispatch(clearMedicineInfo());
        iRouter.push("/medicine-information");
      },
    },
    {
      title: intl.formatMessage({
        id: "page.medication_record_tab.action_sheet.over_the_counter.title",
      }),
      icon: ICONS.IMAGE.PHARMACY_DRUG,
      desc: intl.formatMessage({
        id: "page.medication_record_tab.action_sheet.over_the_counter.description",
      }),
      onClick: async () => {
        setOpenFabActionSheet(false);
        // 内容をクリアする
        // 新規情報
        let userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

        let val: PrescriptionInfoType = {
          type: MEDICINE_TYPE.MARKETING,
          user_id: userId.value ? userId.value : "",
          summary_fee: 0, //費用の初期値は0です。
        };
        dispatch(updatePrescriptionInfo(val));

        dispatch(
          updateMedicineInfo({
            m_shape_code: "01", // 内服
          }),
        );
        iRouter.push("/medicine-information");
      },
    },
  ];

  const [currentTab, setCurrentTab] = useState<string>(tabs[0].name);
  const [openFabActionSheet, setOpenFabActionSheet] = useState(false);

  return (
    <>
      <IonPage
        onClick={() => {
          setOutOpFlg(0);
          setTimeout(() => {
            setOutOpFlg(-1);
          }, 0);
        }}
      >
        <AppLogo />
        <div className="page-spacer-30" />

        <div className="view-padding">
          <AppTab tabs={tabs} onChange={setCurrentTab} />
        </div>

        <IonContent
          scrollEvents={true}
          onIonScrollStart={() => {
            setOutOpFlg(0);
            setTimeout(() => {
              setOutOpFlg(-1);
            }, 0);
          }}
        >
          <div className="root-content">
            {/* <div className="page-spacer-30" /> */}

            {currentTab === tabs[0].name ? (
              <MedicationManagement outOpFlg={outOpFlg} />
            ) : currentTab === tabs[1].name ? (
              <ListOfMedicines />
            ) : (
              <MedicineNotebook />
            )}
          </div>

          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton
              className="fab-button"
              color="primary"
              onClick={() => setOpenFabActionSheet(true)}
            >
              <AppIcon icon={ICONS.FLOATING.MEDICINE_CIRCLE} size="xl" />
            </IonFabButton>
          </IonFab>
        </IonContent>
      </IonPage>

      <AppFixedActionSheet
        isOpen={openFabActionSheet}
        sheetHeight={520}
        handleClose={() => setOpenFabActionSheet(false)}
        title={intl.formatMessage({ id: "page.medication_record_tab.action_sheet.title" })}
      >
        <div className={styles.cardActionSheetRoot}>
          {actionSheets.map((sheet, index) => (
            <ActionSheetCard
              title={sheet.title}
              icon={sheet.icon}
              desc={sheet.desc}
              key={index}
              onClick={() => {
                sheet.onClick && sheet.onClick();
              }}
            />
          ))}
        </div>
      </AppFixedActionSheet>
    </>
  );
}
