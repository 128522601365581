import { actionConst } from "../constants";

interface Actions {
  type: string;
  isPaused: boolean;
}

export default function pausePasscodeReducer(state: boolean = false, actions: Actions) {
  switch (actions.type) {
    case actionConst.UPDATE_PAUSE_PASSCODE_STATUS:
      return actions.isPaused;
    default:
      return state;
  }
}
