import { useState, useEffect, useRef } from "react";
import {
  IonPage,
  IonContent,
  IonLabel,
  IonCard,
  IonCardContent,
  useIonViewWillEnter,
  createGesture,
  useIonAlert,
  ScrollDetail,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppHeader,
  AppIcon,
  AppSecondaryButton,
  AppLoading,
  AppFloatButton,
  AppMedicineImage,
  AppActionSheetForPhotoGallerySelect,
} from "../../components/UiComponents";
import {
  ICONS,
  IMAGES_FOLDER,
  LocalStorageKey,
  FIREBASE_FILE_NAMES,
  PRESCRIPTION_LIST,
  MEDICINE_LIST,
  MEDICINE_CHECK_LIST,
} from "../../constants";
import { useTextConstants } from "src/web/constants/hooks";
import {
  clearMedicineInfo,
  clearPrescriptionInfo,
  updateMedicineInfo,
  updatePrescriptionInfo,
  updateRefreshInfo,
} from "../../actions";
import { RootState } from "../../reducer";
import { OnlinePhotoType, PrescriptionInfoType } from "./type";
import { MedicineInfoType } from "src/web/types";
import styles from "./prescriptionInformation.module.css";
import { useQueryString } from "src/web/hooks/useQueryString";
import { getDateStrByDate } from "../../utils/common";
import { useCommon } from "src/web/utils/hooks/common";
import { useAlarm } from "src/web/utils/hooks/alarm";
import { storage } from "../../firebase";
import { Storage } from "@capacitor/storage";
import { ref, getDownloadURL, uploadBytes, uploadBytesResumable, listAll } from "firebase/storage";
import { RefreshType, GraphInfoType } from "../../types";
// import { async } from "@firebase/util";
import { useAppRouter } from "../../hooks/useAppRouter";
import { MEDICINE_TYPE } from "../../constants";
import { usePhotoGallery, UserPhoto } from "../../hooks/usePhotoGallery";
import { AppMedicineDetail1 } from "../../components/UiComponents/moleclues";
import { useIntl } from "react-intl-phraseapp";
import {
  createPrescription,
  deleteMedicineById,
  deletePrescriptionById,
  getPrescriptionById,
} from "src/web/services";

export function PrescriptionInformation() {
  const intl = useIntl();
  const { refreshAlarm } = useAlarm();
  const { FREQUENCY_UNIT, FREQUENCY, DAY_CONVERT, NOT_INPUTED } = useTextConstants();
  const { confirmNotSave } = useCommon();
  const dispatch = useDispatch();

  const [presentAlert] = useIonAlert();
  const { parseQs } = useQueryString();
  const { photos, setPhotos, takePhoto, takeGallery } = usePhotoGallery();

  const ionRouter = useAppRouter();

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const convertDisplay = (val?: string) => {
    // 内容なしの場合自動的に未入力を表示します。
    if (!val) {
      return NOT_INPUTED;
    }
    return val;
  };

  const convertDisplayNum = (val?: number, isZero: boolean = false) => {
    // 数字を文字単位に変更する
    if (!Number.isInteger(val)) {
      if (isZero) {
        return "0 " + intl.formatMessage({ id: "page.prescription_information.cost.unit" });
      }

      return NOT_INPUTED;
    }

    let formatData = String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formatData + " " + intl.formatMessage({ id: "page.prescription_information.cost.unit" });
  };

  const convertDosageNum = (dosage_num: any, dispensing_quantity: any) => {
    let displayTxt = "";
    // データがある場合表示する
    if (dosage_num) {
      displayTxt += `${intl.formatMessage({
        id: "page.prescription_information.one_day",
      })}${dosage_num}${intl.formatMessage({ id: "page.prescription_information.count" })}`;
    }

    // データがある場合表示する
    if (dispensing_quantity) {
      displayTxt += `${dispensing_quantity}${intl.formatMessage({
        id: "page.prescription_information.quantity.unit",
      })}`;
    }
    return displayTxt;
  };

  const convertFrequency = (val: any) => {
    // 頻度
    // データ存在なし
    // 空値
    if (!val || !val.type) {
      return "";
    }

    // 毎週とカスタム
    // 以外の場合そのまま表示
    if (val.type !== "04" && val.type !== "05") {
      return FREQUENCY[val.type];
    }

    // 毎週の場合
    if (val.type === "04") {
      if (val.week_day && val.week_day.length > 0) {
        return (
          intl.formatMessage({ id: "page.prescription_information.weekday.unit" }) +
          "：" +
          val.week_day.map((x: number) => DAY_CONVERT[x]).join("、")
        );
      } else {
        return intl.formatMessage({ id: "page.prescription_information.weekday.unit" });
      }
    }

    // カスタム
    let gapTxt = "";
    if (val.every_gap) {
      gapTxt += `${val.every_gap} `;
    }

    // 単位存在する場合
    if (val.unit) {
      gapTxt += `${FREQUENCY_UNIT[val.unit]}`;

      if (val.unit === "02" && val.week_day && val.week_day.length > 0) {
        gapTxt += "：" + val.week_day.map((x: number) => DAY_CONVERT[x]).join("、");
      }
    }

    return gapTxt;
  };

  useIonViewWillEnter(async () => {
    const tmpPhotos = await (
      await Storage.get({ key: LocalStorageKey.PRESCRIPTION_LOCAL_PHOTOS })
    ).value;
    if (tmpPhotos) {
      setPhotos(JSON.parse(tmpPhotos));
      await Storage.set({ key: LocalStorageKey.PRESCRIPTION_LOCAL_PHOTOS, value: "" });
    } else {
      // fetch photos online
      fetchOnlinePhotos(true);
    }
  });

  const params = parseQs(ionRouter.routeInfo.search);
  // 処方箋id
  const prescription_id = params.id;

  const [outOpFlg, setOutOpFlg] = useState(0);
  const [openTakePhotoActionSheet, setOpenTakePhotoActionSheet] = useState(false);
  const [onlinePhotos, setOnlinePhotos] = useState<OnlinePhotoType[]>([]);

  const formData: PrescriptionInfoType = useSelector((state: RootState) => {
    return state.prescriptionInformation;
  });

  // 初期時の情報
  const [initialValue, setInitialValue] = useState("");
  useIonViewDidEnter(() => {
    // 初期の画面情報を記録する
    setInitialValue(JSON.stringify(formData));
  });

  const refreshInfo: RefreshType = useSelector((state: RootState) => {
    return state.refreshInfo;
  });

  // ロード情報
  const [loading, setLoading] = useState(false);

  const getUrl = async (userId: string, medicineId: number) => {
    // url パス
    const imageRef = ref(
      storage,
      `${IMAGES_FOLDER}/${userId}/${prescription_id}/${medicineId}/${FIREBASE_FILE_NAMES.medicineFileName}`,
    );
    try {
      let downloadUrl = await getDownloadURL(imageRef);
      return downloadUrl;
    } catch {}
  };

  const getPrescriptionData = async (loadFlg: boolean) => {
    if (loadFlg) {
      setLoading(true);
    }
    try {
      // 更新情報
      let userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

      let jsonData = await getPrescriptionById(prescription_id ? prescription_id.toString() : "");

      // 情報取得
      let prescriptionInfo = jsonData as PrescriptionInfoType;
      if (jsonData["dispensing_date"]) {
        prescriptionInfo.dispensing_date = jsonData["dispensing_date"];
      }

      prescriptionInfo.user_id = userId.value as string;

      if (prescriptionInfo.medicines) {
        for (let medicationInfo of prescriptionInfo.medicines) {
          if (medicationInfo.id) {
            medicationInfo.urlPath = await getUrl(prescriptionInfo.user_id, medicationInfo.id);
          }
        }
      }

      dispatch(updatePrescriptionInfo(prescriptionInfo));
    } finally {
      setLoading(false);
    }
  };

  const sortByTimeAsc = (list: OnlinePhotoType[]) => {
    if (list.length === 0) {
      return list;
    }
    return list.sort((a, b) => {
      let aInt: number = parseInt(a.fullPath.split("-").pop() || "0");
      let bInt: number = parseInt(b.fullPath.split("-").pop() || "0");
      return aInt - bInt;
    });
  };

  const fetchOnlinePhotos = async (refresh?: boolean) => {
    if (prescription_id) {
      if (refresh) {
        setOnlinePhotos([]);
      }
      let tmpOnlinePhotos: OnlinePhotoType[] = refresh ? [] : onlinePhotos;
      let userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });
      const imageRef = ref(
        storage,
        `${IMAGES_FOLDER}/${userId.value}/${prescription_id}/${FIREBASE_FILE_NAMES.prescriptionFileName}`,
      );
      listAll(imageRef).then((res) => {
        res.items.forEach((img) => {
          if (
            tmpOnlinePhotos.length === 0 ||
            !tmpOnlinePhotos.some((photo) => photo.fullPath === img.fullPath)
          ) {
            getDownloadURL(img).then((url) => {
              setOnlinePhotos((prev) => [...prev, { fullPath: img.fullPath, url: url }]);
            });
          }
        });
      });
    }
  };

  const handleAutoUpload = async (uploadPhotoList: any[]) => {
    const prescriptionImagePromises: any[] = [];
    const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

    uploadPhotoList.forEach(async (photo) => {
      const fullPath = `${IMAGES_FOLDER}/${userId.value}/${prescription_id}/${FIREBASE_FILE_NAMES.prescriptionFileName}/${photo.fileName}`;
      const prescriptionImageRef = ref(storage, fullPath);

      const response = await fetch(photo.webviewPath!);
      const blob = await response.blob();

      const uploadTask = uploadBytesResumable(prescriptionImageRef, blob);
      prescriptionImagePromises.push(uploadTask);

      uploadTask.on(
        "state_changed",
        ({ bytesTransferred, totalBytes }) => {
          const progress = (bytesTransferred / totalBytes) * 100;
          console.info(progress);
        },
        (error) => {
          handleImageUploadError();
          console.info(error);
        },
        () => {
          getDownloadURL(prescriptionImageRef).then((url) => {
            setOnlinePhotos((prev) => [...prev, { fullPath, url }]);
          });
        },
      );
    });

    setLoading(true);
    Promise.all(prescriptionImagePromises)
      .then((res) => {
        setLoading(false);
      })
      .catch(() => {
        handleImageUploadError();
        setLoading(false);
      });
  };

  // お薬リスト
  const MedicineItem: React.FC<{ info: MedicineInfoType; idx: number }> = (props) => {
    // 頻度の表示
    let freq = convertFrequency(props.info.frequency);
    // 服薬関連
    let dosage = convertDosageNum(props.info.dosage_num, props.info.dispensing_quantity);
    // 薬アイテム情報
    const medicine_item_ref: React.MutableRefObject<any> = useRef();

    //
    // const [urlPath, setUrlPath] = useState("");

    const dispatch = useDispatch();

    // 図の表示フラグ
    const graphInfoRefresh: GraphInfoType = useSelector((state: RootState) => {
      return state.graphInfo;
    });

    // const getUrl = async () => {
    //   // url パス
    //   const imageRef = ref(
    //     storage,
    //     `${IMAGES_FOLDER}/${formData.user_id}/${prescription_id}/${props.info.id}/${FIREBASE_FILE_NAMES.medicineFileName}`,
    //   );
    //   try {
    //     let downloadUrl = await getDownloadURL(imageRef);
    //     setUrlPath(downloadUrl);
    //   } catch { }
    // };
    // if (props.info.id) {
    //   getUrl();
    // }

    let localPath = props.info.urlPath
      ? props.info.urlPath
      : props.info.photoWebviewPath
      ? props.info.photoWebviewPath
      : "";

    const deleteMedicine = () => {
      presentAlert({
        header:
          `${props.info.m_name}` +
          intl.formatMessage({ id: "page.prescription_information.delete.alert.title.line1" }) +
          "\n" +
          intl.formatMessage({ id: "page.prescription_information.delete.alert.title.line2" }),
        mode: "ios",
        buttons: [
          {
            text: intl.formatMessage({ id: "page.prescription_information.delete.alert.cancel" }),
            role: "cancel",
            handler: () => {
              // 何も表示しない
              const medicine_element_style = medicine_item_ref.current.style;
              medicine_element_style.transform = "";
            },
          },
          {
            text: intl.formatMessage({ id: "page.prescription_information.delete.alert.confirm" }),
            role: "confirm",
            handler: async () => {
              setLoading(true);
              try {
                let medicine_id = props.info.id;
                if (medicine_id) {
                  await deleteMedicineById(`${medicine_id}`);

                  let newPrescriptionInfo = {
                    ...formData,
                  };

                  let length = newPrescriptionInfo.medicines?.length;

                  for (let i = 0; length && i < length; i++) {
                    let medicineInfo = (newPrescriptionInfo.medicines as any[])[i];
                    if (medicineInfo && medicineInfo.id === medicine_id) {
                      newPrescriptionInfo.medicines?.splice(i, 1);
                      break;
                    }
                  }
                  dispatch(updatePrescriptionInfo(newPrescriptionInfo));

                  let new_refreshInfo = {
                    ...refreshInfo,
                  };
                  new_refreshInfo[PRESCRIPTION_LIST] = true;
                  new_refreshInfo[MEDICINE_LIST] = true;
                  new_refreshInfo[MEDICINE_CHECK_LIST] = true;
                  dispatch(updateRefreshInfo(new_refreshInfo));

                  await refreshAlarm();
                } else {
                  // データを削除します。
                  let idx = props.idx;
                  let newPrescriptionInfo = {
                    ...formData,
                  };
                  newPrescriptionInfo.medicines?.splice(idx, 1);
                  dispatch(updatePrescriptionInfo(newPrescriptionInfo));
                }
              } finally {
                setLoading(false);
              }
            },
          },
        ],
      });
    };

    useEffect(() => {
      const ANIMATION_SIZE = -54;

      const medicine_element_style = medicine_item_ref.current.style;

      const moveGesture = createGesture({
        el: medicine_item_ref.current,
        threshold: 0,
        gestureName: "my-gesture",
        onStart: (ev) => {
          medicine_element_style.transform = "";
        },
        onMove: (ev) => {
          // 右に移動することはしない
          if (ev.deltaX > 0) {
            return;
          }
          // 最大左のサイズは54です。
          if (ev.deltaX < ANIMATION_SIZE) {
            medicine_element_style.transform = `translate3d(${ANIMATION_SIZE}px, 0, 0px)`;
            return;
          }
          // 移動を実施する
          medicine_element_style.transform = `translate3d(${ev.deltaX}px, 0, 0px)`;
        },
        onEnd: (ev) => {
          // まえの状態に戻ります。
          // if (ev.deltaX >= ANIMATION_SIZE) {
          //   medicine_element_style.transform = "";
          // }
        },
      });

      moveGesture.enable();
      return () => {
        moveGesture.destroy();
      };
    }, []);

    useEffect(() => {
      const medicine_element_style = medicine_item_ref.current.style;

      if (outOpFlg === -1) {
        medicine_element_style.transform = "";
        return;
      }
    }, [outOpFlg]);

    return (
      <div>
        <div className={styles.delete_item_div}>
          <AppIcon
            icon={ICONS.ICON.CLOSE_CIRCLE}
            size="sm"
            color="white"
            onClick={() => {
              deleteMedicine();
            }}
          />
        </div>
        <IonCard
          ref={medicine_item_ref}
          className={styles.prescription_item_iconCard}
          onClick={(event: any) => {
            if (props.info.id) {
              ionRouter.push(
                `/home/medicine-information-details?id=${props.info.id}`,
                "forward",
                "push",
              );
            } else {
              ionRouter.push(`/medicine-information?index=${props.idx}`, "forward", "push");
            }
          }}
        >
          <IonCardContent className={styles.prescription_item_ionCardContent}>
            <div className={styles.medicineList}>
              {/* データのイメージ */}
              <div className={styles.medicineDiv}>
                {localPath && (
                  <AppMedicineImage
                    src={localPath}
                    size="MediumBig"
                    grayBg={true}
                    hideFrame={true}
                  />
                )}
                {/* {localPath && <img src={localPath} className={styles.medicineImg} />} */}
              </div>
              <div className={styles.medicineInfo}>
                <div className={`p-body ${styles.medicineTitle}`}>{props.info.m_name}</div>
                {/* 値存在の場合表示 */}
                {freq && (
                  <IonLabel className={`p-smallbody ${styles.medicineFrequency}`}>{freq}</IonLabel>
                )}
                {dosage && (
                  <IonLabel className={`p-smallbody ${styles.medicineNum}`}>{dosage}</IonLabel>
                )}
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      </div>
    );
  };

  useEffect(() => {
    // 初期値を取得する
    // 存在するかどうか判断する
    if (prescription_id) {
      if (prescription_id === String(formData.id)) {
        // id 一致の場合何もしないです。
      } else {
        getPrescriptionData(true);
      }
    } else {
      // なにもしない
      // reducerの情報を取得する
    }
  }, [prescription_id]);

  // スクロール関連
  const [isDisplayBorder, setIsDisplayBorder] = useState(false);

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    // setIsDisplayBorder(true);
    if (ev.detail.scrollTop === 0) {
      setIsDisplayBorder(false);
    } else {
      setIsDisplayBorder(true);
    }
  };

  const handleShowLocalPhoto = async ({
    photo,
    refFullPath,
  }: {
    photo?: UserPhoto;
    refFullPath?: string;
  }) => {
    if (photo) {
      await Storage.set({
        key: LocalStorageKey.PRESCRIPTION_LOCAL_PHOTOS,
        value: JSON.stringify(photos),
      });
      ionRouter.push(`/prescription-big-img?webviewPath=${photo.webviewPath}`);
    } else {
      ionRouter.push(`/prescription-big-img?refFullPath=${refFullPath}`);
    }
  };

  const handleImageUploadError = () => {
    presentAlert({
      header: intl.formatMessage({ id: "page.prescription_information.delete.alert.error.title" }),
      mode: "ios",
      buttons: [
        {
          text: intl.formatMessage({
            id: "page.prescription_information.delete.alert.error.cancel",
          }),
          role: "cancel",
        },
      ],
    });
  };

  return (
    <>
      <IonPage
        onClick={() => {
          if (outOpFlg <= 0) {
            setOutOpFlg(outOpFlg - 1);
          } else {
            setOutOpFlg(-1);
          }
        }}
      >
        <AppHeader isDisplayBorder={isDisplayBorder}>
          {/* 編集以外の場合キャンセルボタンを表示する */}
          {!prescription_id && (
            <AppSecondaryButton
              isDynamicWidth
              transparent={true}
              onClick={() => {
                // idなしは新規の場合、
                if (!formData.id) {
                  // true(いつも確認メッセージを表示する)の理由は新規の場合、前の薬情報が存在するので、変更のことは判断する必要がないです。
                  // urlはtop画面のurlです。
                  confirmNotSave(true, presentAlert, ionRouter, "home/medication-record?refresh=1");
                  return;
                }

                // 編集からの場合
                // 変更を判断し、変更があれば、メッセージ表示します。
                // 戻るの先は前画面です。
                confirmNotSave(initialValue !== JSON.stringify(formData), presentAlert, ionRouter);
              }}
            >
              {intl.formatMessage({ id: "page.prescription_information.button.cancel" })}
            </AppSecondaryButton>
          )}
        </AppHeader>
        <IonContent
          scrollEvents={true}
          onIonScroll={handleScroll}
          onIonScrollStart={() => {
            if (outOpFlg <= 0) {
              setOutOpFlg(outOpFlg - 1);
            } else {
              setOutOpFlg(-1);
            }
          }}
          ref={contentRef}
        >
          <div className={`${styles.content}`}>
            {/* データタイトル */}
            <div className="page-spacer-45" />
            <div
              className={`p-header1 ${
                prescription_id && !formData.hospital ? styles.pageEmptyTitle : ""
              }`}
            >
              {prescription_id
                ? !formData.hospital
                  ? formData.type === MEDICINE_TYPE.PRESCRIPTION
                    ? intl.formatMessage({
                        id: "page.prescription_information.prescription.place.empty.prescription",
                      })
                    : intl.formatMessage({
                        id: "page.prescription_information.prescription.place.empty.market",
                      })
                  : formData.hospital
                : intl.formatMessage({ id: "page.prescription_information.prescription.place" })}
            </div>
            <div className="page-spacer-45" />

            {/* 薬リスト */}
            <IonLabel className="p-header3">
              {intl.formatMessage({ id: "page.prescription_information.prescription.title" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <hr />
            <div className="page-spacer-30" />

            {formData.medicines?.length !== 0 ? (
              formData.medicines?.map((item: any, index: number) => {
                return (
                  <div key={index}>
                    <MedicineItem info={item} idx={index} />
                    <div className="page-spacer-15" />
                  </div>
                );
              })
            ) : (
              <div className="page-spacer-15" />
            )}

            <div className="div_center">
              <AppSecondaryButton
                transparent
                full
                onClick={async () => {
                  let idx = formData.medicines?.length;
                  if (!idx) {
                    idx = 0;
                  }

                  if (prescription_id) {
                    // 更新情報
                    let userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });
                    dispatch(
                      updateMedicineInfo({
                        user_id: userId,
                      }),
                    );
                    ionRouter.push(
                      `/medicine-information?index=${idx}&prescription_id=${prescription_id}`,
                      "forward",
                      "push",
                    );
                  } else {
                    if (formData.type === MEDICINE_TYPE.PRESCRIPTION) {
                      dispatch(clearMedicineInfo());
                    } else {
                      dispatch(
                        updateMedicineInfo({
                          m_shape_code: "01", // 内服
                        }),
                      );
                    }
                    ionRouter.push(`/medicine-information?index=${idx}`, "forward", "push");
                  }
                }}
              >
                {intl.formatMessage({
                  id: "page.prescription_information.prescription.button.add_medicine",
                })}
              </AppSecondaryButton>
            </div>
            <div className="page-spacer-60" />
            <div className="flex-row-between">
              <IonLabel className="p-header3">
                {formData.type === MEDICINE_TYPE.PRESCRIPTION
                  ? intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.title.prescription",
                    })
                  : intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.title.market",
                    })}
              </IonLabel>
              <AppSecondaryButton
                isDynamicWidth
                transparent={true}
                disabled="false"
                onClick={() => {
                  ionRouter.push("/prescription-hospital?updFlg=true", "forward", "push");
                }}
              >
                {intl.formatMessage({
                  id: "page.prescription_information.prescription.button.edit",
                })}
              </AppSecondaryButton>
            </div>
            <div className="page-spacer-15" />
            <hr />
            <div className="page-spacer-30" />
            <AppMedicineDetail1
              title={
                formData.type === MEDICINE_TYPE.PRESCRIPTION
                  ? intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.date.prescription",
                    })
                  : intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.date.market",
                    })
              }
            >
              {formData.dispensing_date}
            </AppMedicineDetail1>
            <div className="page-spacer-45" />
            <AppMedicineDetail1
              title={
                formData.type === MEDICINE_TYPE.PRESCRIPTION
                  ? intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.place.prescription",
                    })
                  : intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.place.market",
                    })
              }
            >
              {convertDisplay(formData.pharmacy)}
            </AppMedicineDetail1>
            <div className="page-spacer-45" />
            <AppMedicineDetail1
              title={
                formData.type === MEDICINE_TYPE.PRESCRIPTION
                  ? intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.pharmacist.prescription",
                    })
                  : intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.pharmacist.market",
                    })
              }
            >
              {convertDisplay(formData.pharmacist)}
            </AppMedicineDetail1>
            <div className="page-spacer-45" />
            <AppMedicineDetail1
              title={
                formData.type === MEDICINE_TYPE.PRESCRIPTION
                  ? intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.hospital_name.prescription",
                    })
                  : intl.formatMessage({
                      id: "page.prescription_information.prescription.prescription.hospital_namet.market",
                    })
              }
            >
              {convertDisplay(formData.hospital)}
            </AppMedicineDetail1>
            <div className="page-spacer-60" />
            {/* 関連書類 */}
            <div className="flex-row-between">
              <IonLabel className="p-header3">
                {intl.formatMessage({
                  id: "page.prescription_information.prescription.documents.title",
                })}
              </IonLabel>
            </div>
            <div className="page-spacer-15" />
            <hr />
            <div className="page-spacer-45" />
            <AppMedicineDetail1
              title={intl.formatMessage({
                id: "page.prescription_information.prescription.documents.register_photo.title",
              })}
            >
              {intl.formatMessage({
                id: "page.prescription_information.prescription.documents.register_photo.desc",
              })}
            </AppMedicineDetail1>
            <div className="page-spacer-15" />

            <div className={styles.imgWrapper}>
              {[...onlinePhotos, ...photos].length < 9 && (
                <div
                  className={`${styles.imgAdd} div_center`}
                  onClick={() => setOpenTakePhotoActionSheet(true)}
                >
                  <AppIcon icon={ICONS.ICON.PLUS_CIRCLE} size="lg" />
                </div>
              )}
              {/* existed photos */}
              {sortByTimeAsc(onlinePhotos).map((photo, index) => (
                <img
                  src={photo.url}
                  className={styles.photo}
                  key={index}
                  onClick={() => handleShowLocalPhoto({ refFullPath: photo.fullPath })}
                />
              ))}
              {/* new photos to upload */}
              {photos.map((photo, index) => (
                <img
                  src={photo.webviewPath}
                  className={styles.photo}
                  key={index}
                  onClick={() => handleShowLocalPhoto({ photo })}
                />
              ))}
            </div>

            <div className="page-spacer-60" />
            {/* 費用 */}
            <div className="flex-row-between">
              <IonLabel className="p-header3">
                {intl.formatMessage({
                  id: "page.prescription_information.prescription.prescription_fee.title",
                })}
              </IonLabel>
              <AppSecondaryButton
                isDynamicWidth
                transparent={true}
                disabled="false"
                onClick={() => {
                  ionRouter.push("/prescription-fee", "forward", "push");
                }}
              >
                {intl.formatMessage({
                  id: "page.prescription_information.prescription.prescription_fee.button.edit",
                })}
              </AppSecondaryButton>
            </div>
            <div className="page-spacer-15" />
            <hr />
            {/* 処方薬の場合表示 */}
            {formData.type === MEDICINE_TYPE.PRESCRIPTION && (
              <>
                <div className="page-spacer-30" />
                <AppMedicineDetail1
                  title={intl.formatMessage({ id: "page.prescription_fee.hospital_fee" })}
                >
                  {convertDisplayNum(formData.hospital_fee)}
                </AppMedicineDetail1>
              </>
            )}
            <div
              className={`${
                formData.type === MEDICINE_TYPE.PRESCRIPTION ? "page-spacer-45" : "page-spacer-30"
              }`}
            />
            <AppMedicineDetail1
              title={
                formData.type === MEDICINE_TYPE.PRESCRIPTION
                  ? intl.formatMessage({ id: "page.prescription_fee.total.prescription" })
                  : intl.formatMessage({ id: "page.prescription_fee.total.market" })
              }
            >
              {convertDisplayNum(formData.pharmacy_fee)}
            </AppMedicineDetail1>
            {/* 処方薬の場合表示 */}
            {formData.type === MEDICINE_TYPE.PRESCRIPTION && (
              <>
                <div className="page-spacer-45" />
                <AppMedicineDetail1
                  title={intl.formatMessage({ id: "page.prescription_fee.moving_fee" })}
                >
                  {convertDisplayNum(formData.moving_fee)}
                </AppMedicineDetail1>
              </>
            )}
            {/* 処方薬の場合表示 */}
            {formData.type === MEDICINE_TYPE.PRESCRIPTION && (
              <>
                <div className="page-spacer-45" />
                <AppMedicineDetail1
                  title={intl.formatMessage({ id: "page.prescription_fee.total" })}
                >
                  {convertDisplayNum(formData.summary_fee, true)}
                </AppMedicineDetail1>
              </>
            )}
            <div className="page-spacer-60" />
            {/* その他 */}
            <div className="flex-row-between">
              <IonLabel className="p-header3">
                {intl.formatMessage({ id: "page.prescription_information.prescription.other" })}
              </IonLabel>
              <AppSecondaryButton
                isDynamicWidth
                transparent={true}
                disabled="false"
                onClick={() => {
                  ionRouter.push("/prescription-memo", "forward", "push");
                }}
              >
                {intl.formatMessage({
                  id: "page.prescription_information.prescription.other.button.edit",
                })}
              </AppSecondaryButton>
            </div>
            <div className="page-spacer-15" />
            <hr />
            <div className="page-spacer-30" />
            <IonLabel className={`p-smallbody ${styles.displayWrap}`}></IonLabel>
            <AppMedicineDetail1
              title={intl.formatMessage({
                id: "page.prescription_information.prescription.other.notes.title",
              })}
            >
              {convertDisplay(formData.notes)}
            </AppMedicineDetail1>
            <div className="page-spacer-30" />
          </div>

          <AppFloatButton
            label={
              prescription_id
                ? intl.formatMessage({
                    id: "page.prescription_information.prescription.other.button.delete",
                  })
                : intl.formatMessage({
                    id: "page.prescription_information.prescription.other.button.register",
                  })
            }
            isSecond={prescription_id ? true : false}
            isStatic={prescription_id ? true : false}
            onClick={async () => {
              setLoading(true);
              try {
                // 処方と市販薬の削除
                if (prescription_id) {
                  presentAlert({
                    header: `${
                      formData.type === MEDICINE_TYPE.PRESCRIPTION
                        ? intl.formatMessage({
                            id: "page.prescription_information.prescription.classification.prescription",
                          })
                        : intl.formatMessage({
                            id: "page.prescription_information.prescription.classification.market",
                          })
                    }${intl.formatMessage({
                      id: "page.prescription_information.prescription.alert.message",
                    })}`,
                    mode: "ios",
                    buttons: [
                      {
                        text: intl.formatMessage({
                          id: "page.prescription_information.prescription.alert.cancel",
                        }),
                        role: "cancel",
                        handler: () => {},
                      },
                      {
                        text: intl.formatMessage({
                          id: "page.prescription_information.prescription.alert.confirm",
                        }),
                        role: "confirm",
                        handler: async () => {
                          await deletePrescriptionById(prescription_id);

                          let new_refreshInfo = {
                            ...refreshInfo,
                          };
                          new_refreshInfo[PRESCRIPTION_LIST] = true;
                          dispatch(updateRefreshInfo(new_refreshInfo));
                          // 情報をクリアする
                          dispatch(clearPrescriptionInfo());

                          await refreshAlarm();

                          // 新規情報
                          ionRouter.push(
                            `/home/medication-record?refresh=1&time=${new Date()}`,
                            "forward",
                            "replace",
                          );
                        },
                      },
                    ],
                  });
                  return;
                }

                // 存在しない
                if (!formData.id) {
                  // 作成日を設定する
                  formData.currentDate = getDateStrByDate(new Date());
                  // 新規
                  let jsonData = await createPrescription(formData);

                  // 処方箋のid
                  let prescription_id = jsonData["prescription_id"];

                  let new_refreshInfo = {
                    ...refreshInfo,
                  };
                  new_refreshInfo[PRESCRIPTION_LIST] = true;
                  new_refreshInfo[MEDICINE_LIST] = true;
                  new_refreshInfo[MEDICINE_CHECK_LIST] = true;
                  dispatch(updateRefreshInfo(new_refreshInfo));

                  // 薬配列
                  let medicines = jsonData["medicines"];

                  const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

                  // upload medicine photos
                  let index = 0;
                  for (let mdInfo of formData.medicines ? formData.medicines : []) {
                    index++;
                    // 空値のイメージを登録しない
                    if (!mdInfo.changeImgFlg || !mdInfo.photoWebviewPath) {
                      continue;
                    }

                    let mdId = medicines[index - 1];
                    const imageRef = ref(
                      storage,
                      `${IMAGES_FOLDER}/${userId.value}/${prescription_id}/${mdId}/${FIREBASE_FILE_NAMES.medicineFileName}`,
                    );
                    const response = await fetch(mdInfo.photoWebviewPath!);
                    const blob = await response.blob();
                    const uploadResult = await uploadBytes(imageRef, blob);
                  }

                  // upload prescriptionInformation photos
                  const prescriptionImagePromises: any[] = [];

                  photos.forEach(async (photo) => {
                    const prescriptionImageRef = ref(
                      storage,
                      `${IMAGES_FOLDER}/${userId.value}/${prescription_id}/${FIREBASE_FILE_NAMES.prescriptionFileName}/${photo.fileName}`,
                    );

                    const response = await fetch(photo.webviewPath!);
                    const blob = await response.blob();

                    const uploadTask = uploadBytesResumable(prescriptionImageRef, blob);
                    prescriptionImagePromises.push(uploadTask);
                  });

                  Promise.all(prescriptionImagePromises)
                    .then(() => {
                      // console.info("done");
                    })
                    .catch(() => {
                      handleImageUploadError();
                    })
                    .finally(async () => {
                      // エラー処理？
                      // 情報をクリアする
                      dispatch(clearPrescriptionInfo());

                      await refreshAlarm();

                      // 新規情報
                      ionRouter.push(
                        `/home/medication-record?refresh=1&time=${new Date()}`,
                        "forward",
                        "replace",
                      );
                    });
                }
              } finally {
                setLoading(false);
              }
            }}
          />
        </IonContent>
      </IonPage>

      <AppActionSheetForPhotoGallerySelect
        isOpen={openTakePhotoActionSheet}
        handleClose={() => setOpenTakePhotoActionSheet(false)}
        handleTakePhoto={() => {
          if (prescription_id) {
            takePhoto({ handleUpload: handleAutoUpload });
          } else {
            takePhoto();
          }
        }}
        handleTakeGallery={async () => {
          if (prescription_id) {
            takeGallery({ limit: 9, handleUpload: handleAutoUpload });
          } else {
            takeGallery({ limit: 9 });
          }
        }}
      />

      <AppLoading isOpen={loading} />
    </>
  );
}
