import { useState, useEffect } from "react";
import { IonGrid, IonRow, IonCol, IonLabel } from "@ionic/react";
import { getYearMonthByDate, getDateStrByDate } from "../../../utils/common";
import { AppFixedActionSheet } from "../AppActionSheet/appFixedActionSheet";
import { AppIcon } from "../AppIcon/appIcon";
import { useAppCalendarControl } from "./hooks/useAppCalendarControl";
import { ICONS } from "../../../constants";
import { CalendarDayType } from "../../../types";
import styles from "./appCalendar.module.css";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

interface AppCalendarNoStatusCommonSheetProps {
  isOpen: boolean;
  selectedDate: Date;
  closeCarlendar: () => void;
  handleConfirmDate: (dateStr: string) => void;
  disabledBeforeDate?: string;
  disabledAfterDate?: string;
  getCheckedDates?: (currentDate: string) => Promise<string[]>;
}

export function AppCalendarNoStatusCommonSheet({
  isOpen,
  selectedDate,
  closeCarlendar,
  handleConfirmDate,
  disabledBeforeDate,
  disabledAfterDate,
  getCheckedDates,
}: AppCalendarNoStatusCommonSheetProps) {
  const intl = useIntl();
  const { DAY_CONVERT } = useTextConstants();
  const [currentDate, setCurrentDate] = useState(selectedDate);
  const [searchDate, setSearchDate] = useState("");
  const [searchCheckDates, setSearchCheckDates] = useState<string[]>([]);
  const [dateMatrix, setDateMatrix] = useState<CalendarDayType[][]>([[]]);

  const { isLeftArrowDisabled, isRightArrowDisabled } = useAppCalendarControl({
    dateMatrix,
    disabledAfterDate,
  });

  const checkDisabledFlag = (strDate: string, checkDates: string[]) => {
    if (getCheckedDates && !checkDates.includes(strDate)) {
      return true;
    }

    if (disabledBeforeDate) {
      return strDate < disabledBeforeDate;
    }

    if (disabledAfterDate) {
      return strDate > disabledAfterDate;
    }

    return false;
  };

  const handleChangeMonth = (count: number) => {
    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + count, 1);
    setCurrentDate(nextMonth);
  };

  const showDate = (date: CalendarDayType) => {
    if (date.date === 0) {
      return <></>;
    } else if (date.isSelected) {
      return (
        <div className={styles.calendarDateIsSelectDiv}>
          <IonLabel color="white">{date.date}</IonLabel>
        </div>
      );
    } else {
      return (
        <IonLabel
          color="black"
          className={`${date.isDisabled ? styles.disableCalendarDateColor : ""}`}
        >
          {date.date}
        </IonLabel>
      );
    }
  };

  useEffect(() => {
    // handle cancel modal: no action is required for native back
    const handleNativeBack = (ev: any) => {
      ev.detail.register(3, (processNextHandler: any) => {
        if (isOpen) {
          closeCarlendar();
        } else {
          processNextHandler();
        }
      });
    };

    if (isOpen) {
      document.addEventListener("ionBackButton", handleNativeBack);
    } else {
      document.removeEventListener("ionBackButton", handleNativeBack);
    }

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, [isOpen]);

  useEffect(() => {
    const processDate = async (currentDate: Date) => {
      const selectedDate = getDateStrByDate(currentDate);
      const selectedYearMonthStr = getYearMonthByDate(currentDate);
      let tmpDateMatrix: CalendarDayType[][] = [[]];

      // the first date of the month
      let startDay = 1;
      const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), startDay);
      let initialDay = startDate.getDay();

      // the last date of the month
      const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
      let endDay = endDate.getDate();

      // 第一行名の設定
      let firstLine: CalendarDayType[] = [];

      // チェック日付
      let checkDates: string[] = [];
      if (getCheckedDates) {
        if (searchDate === selectedDate.substring(0, 7)) {
          checkDates = searchCheckDates;
        } else {
          checkDates = await getCheckedDates(selectedDate);
          setSearchDate(selectedDate.substring(0, 7));
          setSearchCheckDates(checkDates);
        }
      }

      for (let i = 0; i < 7; i++) {
        if (i < initialDay) {
          firstLine.push({
            date: 0,
            fullDate: "",
            hasData: false,
            isSelected: false,
            isCurrent: false,
          });
        } else {
          let dateStr = `${selectedYearMonthStr}/${startDay.toString().padStart(2, "0")}`;
          const isDisabled = checkDisabledFlag(dateStr, checkDates);
          firstLine.push({
            date: startDay,
            fullDate: dateStr,
            hasData: false,
            isCurrent: false,
            isSelected: !isDisabled && dateStr === selectedDate,
            isDisabled: isDisabled,
          });
          startDay++;
        }
      }
      tmpDateMatrix.push(firstLine);

      for (let i = 1; i < 6; i++) {
        if (startDay > endDay) {
          break;
        }
        let newLine: CalendarDayType[] = [];
        for (let i = 0; i < 7; i++) {
          if (startDay > endDay) {
            newLine.push({
              date: 0,
              fullDate: "",
              hasData: false,
              isSelected: false,
              isCurrent: false,
            });
            continue;
          }
          let dateStr = `${selectedYearMonthStr}/${startDay.toString().padStart(2, "0")}`;
          const isDisabled = checkDisabledFlag(dateStr, checkDates);
          newLine.push({
            date: startDay,
            fullDate: dateStr,
            hasData: false,
            isCurrent: false,
            isSelected: !isDisabled && dateStr === selectedDate,
            isDisabled: isDisabled,
          });
          startDay++;
        }
        tmpDateMatrix.push(newLine);
      }

      setDateMatrix(tmpDateMatrix);
    };

    processDate(currentDate);
  }, [currentDate]);

  useEffect(() => {
    setCurrentDate(selectedDate);
  }, [selectedDate]);

  return (
    <AppFixedActionSheet
      isOpen={isOpen}
      handleClose={closeCarlendar}
      handleConfirm={() => {
        const selectedDate = getDateStrByDate(currentDate);
        if (getCheckedDates && !searchCheckDates.includes(selectedDate)) {
          return;
        }

        handleConfirmDate(selectedDate);
        closeCarlendar();
      }}
      title=" "
      sheetHeight={560}
    >
      <div className={`flex-row-around ${styles.titleBar}`}>
        <AppIcon
          icon={ICONS.ICON.ARROW_CIRCLE_BACK}
          disabled={isLeftArrowDisabled}
          onClick={() => {
            if (!isLeftArrowDisabled) {
              handleChangeMonth(-1);
            }
          }}
        />

        <div className={styles.dateWrapper}>
          <IonLabel className="p-smallbody text-center-block">{currentDate.getFullYear()}</IonLabel>
          <IonLabel className="p-header2 text-center-block">
            {currentDate.getMonth() + 1}
            {intl.formatMessage({ id: "ui_components.app_calendar.unit.month" })}
          </IonLabel>
        </div>

        <AppIcon
          icon={ICONS.ICON.ARROW_CIRCLE_FORWARD}
          disabled={isRightArrowDisabled}
          onClick={() => {
            if (!isRightArrowDisabled) {
              handleChangeMonth(1);
            }
          }}
        />
      </div>

      <div className="page-spacer-30" />

      <div>
        <IonGrid style={{ margin: 0, height: "auto" }}>
          <IonRow>
            {Object.values(DAY_CONVERT).map((day, index) => (
              <IonCol className={styles.calendarTableHead} key={index}>
                {day}
              </IonCol>
            ))}
          </IonRow>
          {dateMatrix.map((rowData, index) => {
            return (
              <IonRow style={{ margin: "15px 0 0 0" }} key={index}>
                {rowData.map((date, i) => {
                  return (
                    <IonCol key={i}>
                      <div
                        className={`${styles.calendarDateDiv}`}
                        onClick={() => {
                          // date.isDisableの場合、クリック無効にする
                          if (date.fullDate && !date.isDisabled) {
                            setCurrentDate(new Date(Date.parse(date.fullDate)));
                          }
                        }}
                      >
                        {showDate(date)}
                      </div>
                    </IonCol>
                  );
                })}
              </IonRow>
            );
          })}
        </IonGrid>
      </div>

      <div className="page-spacer-60" />
    </AppFixedActionSheet>
  );
}
