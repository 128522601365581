import { useState, useEffect } from "react";
import styles from "./appTrackingButton.module.css";

export function AppTrackingButton({
  label,
  checked,
  onClick,
  disabled,
  isMultiBtn,
  className,
}: {
  label: string;
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
  isMultiBtn?: boolean;
  className?: string;
}) {
  const [checkStatus, setCheckStatus] = useState({
    checking: false,
    unchecking: false,
    checked,
  });
  const [lastCheckedState, setLastCheckedState] = useState(checked); // the logic for block animation in some scenario

  useEffect(() => {
    if (checked && checked !== lastCheckedState) {
      setCheckStatus({
        checking: true,
        unchecking: false,
        checked: false,
      });
    } else if (!checked && checked !== lastCheckedState) {
      setCheckStatus({
        checking: false,
        unchecking: true,
        checked: false,
      });
    } else {
      setCheckStatus({
        checking: false,
        unchecking: false,
        checked,
      });
    }
    setLastCheckedState(checked);
  }, [checked]);

  return (
    <label>
      <input type="checkbox" checked={checked} className={styles.input} disabled={disabled} />
      <div
        className={`
          ${className && className}
          ${styles.init}
          ${isMultiBtn ? styles.multiBtn : ""}
          ${
            checkStatus.checking
              ? styles.checking
              : checkStatus.unchecking
              ? styles.unchecking
              : checkStatus.checked
              ? styles.checked
              : ""
          }
          ${disabled ? styles.disabled : ""}
        `}
        onClick={() => {
          if (!disabled) {
            onClick && onClick();
          }
        }}
        aria-hidden="true"
      >
        <span className={styles.label}>{label}</span>
      </div>
      {/* <span className={styles.input}>{label}</span> */}
    </label>
  );
}
