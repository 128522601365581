import { IonLabel, IonPage } from "@ionic/react";
import {
  AppHeader,
  AppContent,
  AppLoading,
  AppHomeIndicatorBar,
} from "src/web/components/UiComponents";
import { useFaqControl } from "./hooks/useFaqControl";
import { PageTitleWithSearch } from "src/web/components/UiComponents/moleclues";
import { MenuItemGroup } from "src/web/components/UiComponents/organisms";
import { LineItem } from "./components/LineItem/lineItem";
import { useIntl } from "react-intl-phraseapp";

export function Faqs() {
  const {
    isLoading,
    lastFilterBy,
    filterBy,
    filteredResultList,
    handleChange,
    doFilter,
    contentListGroupByCategory,
  } = useFaqControl();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppHeader />

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <PageTitleWithSearch
              title={intl.formatMessage({ id: "page.faq.header" })}
              value={filterBy}
              handleChange={handleChange}
              handleFilter={doFilter}
            />

            {!!lastFilterBy && (
              <>
                {filteredResultList.length > 0 ? (
                  <>
                    <div className="page-spacer-45" />
                    <IonLabel className="p-header4">
                      {intl.formatMessage(
                        { id: "page.faq.search_result.found" },
                        { word: lastFilterBy, count: filteredResultList.length },
                      )}
                    </IonLabel>
                  </>
                ) : (
                  <>
                    <div className="page-spacer-45" />
                    <IonLabel className="p-body text-block">
                      {intl.formatMessage(
                        { id: "page.faq.search_result.no_records" },
                        { word: lastFilterBy },
                      )}
                    </IonLabel>
                    <div className="page-spacer-45" />
                    <IonLabel className="p-body text-block pre-wrap">
                      {`${intl.formatMessage({
                        id: "page.faq.search_result.no_records.information.line1",
                      })}\n${intl.formatMessage({
                        id: "page.faq.search_result.no_records.information.line2",
                      })}\n${intl.formatMessage({
                        id: "page.faq.search_result.no_records.information.line3",
                      })}\n${intl.formatMessage({
                        id: "page.faq.search_result.no_records.information.line4",
                      })}`}
                    </IonLabel>
                  </>
                )}
              </>
            )}

            {!lastFilterBy
              ? contentListGroupByCategory?.map((item, index) => (
                  <MenuItemGroup menuData={item} key={index} isGray />
                ))
              : filteredResultList.map((res) => (
                  <LineItem item={res} filterBy={filterBy} key={res.faq_id} />
                ))}
          </div>

          <AppHomeIndicatorBar />
        </AppContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
