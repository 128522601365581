import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";
import {
  EMPTY_OPTION_STRING_VALUE,
  DATE_FORMAT_SPLIT,
  EMPTY_OPTION_NUMBER_VALUE,
  USER_BASIC_INFO_MIN_BIRTHDAY_YEAR,
} from "src/web/constants";
import {
  FrequencyType,
  SelectOption,
  AlertType,
  FuncStatus,
  PageLogType,
} from "src/web/types";
import { Dispatch } from "redux";
import { updateAlertInfo } from "src/web/actions";
import { PspAlexaAuthError, PspAlexaError, PspHttpAuthError, PspHttpError } from "src/web/errors";
import { processLog, processErrorLog } from "src/web/utils/common";


export function useCommon() {
  const intl = useIntl();
  const { DAY_CONVERT, MEDICINE_FREQUENCY_OPTIONS, MEDICINE_REPEATING_PERIOD_OPTIONS, EMPTY_OPTION_TEXT, MEDICINE_TIMING_OPTIONS } = useTextConstants();

  const TIMELINE_CATEGORIES = {
    SYMPTOM: {
      type: "SYMPTOM",
      label: intl.formatMessage({ id: "page.timeline.category.symptom" }),
    },
    ACTIVITY: {
      type: "ACTIVITY",
      label: intl.formatMessage({ id: "page.timeline.category.activity" }),
    },
    BLOOD_PRESSURE: {
      type: "BLOOD_PRESSURE",
      label: intl.formatMessage({ id: "page.timeline.category.blood_pressure" }),
    },
    BODY_TEMPERATURE: {
      type: "BODY_TEMPERATURE",
      label: intl.formatMessage({ id: "page.timeline.category.temperature" }),
    },
    TAKING_MEDICINE: {
      type: "TAKING_MEDICINE",
      label: intl.formatMessage({ id: "page.timeline.category.medication" }),
    },
  };

  const GRAPH_SCORE_CATEGORY_DICT = {
    b: intl.formatMessage({ id: "components.charts.line_bar_scatter_chart.score.body" }),
    d: intl.formatMessage({ id: "components.charts.line_bar_scatter_chart.score.heart" }),
    a: intl.formatMessage({ id: "components.charts.line_bar_scatter_chart.score.activity" }),
    c: intl.formatMessage({ id: "components.charts.line_bar_scatter_chart.score.vital" }),
  };

  const formatFrequencyText = (value?: FrequencyType) => {
    const getWeekDayText = (weekdayArr: string[]) => {
      if (weekdayArr && weekdayArr.length) {
        const daysOptions = Object.entries(DAY_CONVERT).map(([key, value]: [string, string]) => {
          return { value: key, text: value };
        });
        let weekDayTexts: string[] = [];
        daysOptions.forEach((daysOp) => {
          weekdayArr.forEach((weekVal) => {
            if (daysOp.value === weekVal) {
              weekDayTexts.push(daysOp.text);
            }
          });
        });
        return weekDayTexts.length
          ? weekDayTexts.join("、")
          : intl.formatMessage({ id: "page.mediciton_information.weekday" });
      } else {
        // 曜日選択しない場合、何も表示しないです。
        return "";
      }
    };
  
    if (value) {
      switch (value.type) {
        case EMPTY_OPTION_STRING_VALUE: // 指定しない
        case "01": // 毎日
        case "02": // 1日おき
        case "03": // 2日おき
          let currentFrq = generateLabeledEmptyOptions(MEDICINE_FREQUENCY_OPTIONS, true).find(
            (opt: SelectOption) => opt.value === value.type,
          );
          if (currentFrq) {
            return currentFrq.text;
          } else {
            return "";
          }
        case "04": // 曜日で選択...
          return getWeekDayText(value.week_day);
        case "05": // カスタム...
          if (value.every_gap || value.unit || (value.week_day && value.week_day.length)) {
            let text = "";
            if (value.every_gap) {
              text = `${value.every_gap}`;
            }
            if (value.unit) {
              let currentPeriod = MEDICINE_REPEATING_PERIOD_OPTIONS.find(
                (opt) => opt.value === value.unit,
              );
  
              // 空値の場合
              // 内容は設定しない
              let week_day_string = getWeekDayText(value.week_day);
              if (week_day_string) {
                week_day_string = "：" + week_day_string;
              }
  
              text = `${text}${currentPeriod?.text}${week_day_string}`;
              // if (value.unit === "02") {
              //   text += `：${getWeekDayText(value.week_day)}`;
              // }
            } else if (text) {
              text = `${text}`;
            } else {
              text = intl.formatMessage({ id: "page.mediciton_information.custom" });
            }
            return text;
          } else {
            return intl.formatMessage({ id: "page.mediciton_information.custom" });
          }
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  /*
    convert date type to string in format YYYY/MM/DD(weekday), 
    return today by default
  */
  function convertToDate(date: string) {
    let currentDate = date ? new Date(date) : new Date();
    let day = currentDate.getDay();
    let dayStr = DAY_CONVERT[day];

    return `${currentDate.getFullYear()}${DATE_FORMAT_SPLIT}${
      currentDate.getMonth() + 1
    }${DATE_FORMAT_SPLIT}${currentDate.getDate()}(${dayStr})`;
  }

  // append empty key value option
  const appendLabeledEmptyOptions = (type: string) => {
    if (type === "string") {
      return { value: EMPTY_OPTION_STRING_VALUE, text: EMPTY_OPTION_TEXT };
    } else {
      return { value: EMPTY_OPTION_NUMBER_VALUE, text: EMPTY_OPTION_TEXT };
    }
  };

  // append empty option for sheet option
  const generateLabeledEmptyOptions = (options: any[], needClear?: boolean) => {
    if (needClear) {
      if (typeof options[0] === "string") {
        return [EMPTY_OPTION_TEXT, ...options];
      } else {
        return [appendLabeledEmptyOptions(typeof options[0].value), ...options];
      }
    } else {
      return options;
    }
  };

  const generateBirthdayYearOptions = () => {
    let tmpYearOptions: SelectOption[] = [];
    const length = new Date().getFullYear() - USER_BASIC_INFO_MIN_BIRTHDAY_YEAR + 1;
    for (let i = 0; i < length; i++) {
      tmpYearOptions.push({
        value: `${USER_BASIC_INFO_MIN_BIRTHDAY_YEAR + i}`,
        text: `${USER_BASIC_INFO_MIN_BIRTHDAY_YEAR + i}${intl.formatMessage({ id: "common.unit.year"})}`,
      });
    }
    return tmpYearOptions;
  };


  const doPasswordValidation = (firstPass: string, secondPass: string) => {
    let errorMsg = "";
    const mandatoryAndMatchCheck = !!firstPass && !!secondPass && firstPass === secondPass;
    if (!mandatoryAndMatchCheck) {
      errorMsg = intl.formatMessage({id: "common.password_validation.message.unmatch"});
    }
    const matchLetters =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~#?!@$%^&*-._=:;<>,./\(\)\[\]\{\}\|\+]).{10,20}$/g;
    const complexityCheck = !!firstPass.match(matchLetters);
    if (!errorMsg && !complexityCheck) {
      errorMsg = intl.formatMessage({id: "common.password_validation.message.weak_password"});
    }
    return {
      isValid: mandatoryAndMatchCheck && complexityCheck,
      errorMsg,
    };
  };

  const confirmNotSave = (
    isChange: boolean,
    presentAlert: any,
    ionRouter: any,
    url?: string,
  ) => {
    // 変更しない場合、直接返却する
    if (!isChange) {
      if (url) {
        ionRouter.push(url, "forward", "push");
      } else {
        ionRouter.goBack();
      }
      return;
    }
  
    if (isChange) {
      presentAlert({
        header: intl.formatMessage({id: "common.confirm_not_save.message"}),
        mode: "ios",
        buttons: [
          {
            text: intl.formatMessage({id: "common.confirm_not_save.no"}),
            role: "cancel",
            handler: () => {},
          },
          {
            text: intl.formatMessage({id: "common.confirm_not_save.yes"}),
            role: "confirm",
            handler: () => {
              if (url) {
                ionRouter.push(url, "forward", "push");
              } else {
                ionRouter.goBack();
              }
            },
          },
        ],
      });
    }
  };

  const processAsyncFunc = async (
    func: () => Promise<void>,
    logType: PageLogType,
    dispatch: Dispatch<any>,
  ) => {
    processLog(logType, FuncStatus.START);
    try {
      await func();
    } catch (e: any) {
      // todo firebase analysis log
      if (e instanceof PspHttpError) {
        dispatch(
          updateAlertInfo({
            status: AlertType.ERROR,
            msg: intl.formatMessage({id: "common.error.general.http_error"}),
          }),
        );
      } else if (e instanceof PspHttpAuthError) {
        // TODO login 画面へ
      } else if (e instanceof PspAlexaAuthError) {
        dispatch(
          updateAlertInfo({
            status: AlertType.ERROR,
            msg: `${intl.formatMessage({id: "common.error.general.alexa_auth_error"})}${
              e.status ? "Code:" + e.status : ""
            }${e.message ?? ""}`,
          }),
        );
      } else if (e instanceof PspAlexaError) {
        dispatch(
          updateAlertInfo({
            status: AlertType.ERROR,
            msg: `${intl.formatMessage({id: "common.error.general.alexa_com_error"})}${
              e.status ? "Code:" + e.status : ""
            }${e.message ?? ""}`,
          }),
        );
      }
      processErrorLog(logType, FuncStatus.ERROR, e);
    }
    processLog(logType, FuncStatus.END);
  };


  return {
    TIMELINE_CATEGORIES,
    GRAPH_SCORE_CATEGORY_DICT,
    formatFrequencyText,
    convertToDate,
    appendLabeledEmptyOptions,
    generateLabeledEmptyOptions,
    generateBirthdayYearOptions,
    doPasswordValidation,
    confirmNotSave,
    processAsyncFunc
  };
}
