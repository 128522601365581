import { Auth, signInWithEmailAndPassword } from "firebase/auth";
import { Storage } from "@capacitor/storage";
import { useDispatch } from "react-redux";
import { updateUserStartDate } from "src/web/actions";
import { LocalStorageKey } from "src/web/constants";
import { UserBaseInfoType } from "src/web/types";
import { getUserInfo } from "src/web/services";

export function useLoginModel() {
  const dispatch = useDispatch();

  const doSignIn = async (auth: Auth, name: string, password: string) => {
    try {
      const userRecord = await signInWithEmailAndPassword(auth, name, password);
      const gcpUserInfo = userRecord.user as any;

      // save uid and token
      await Storage.set({ key: LocalStorageKey.LOGIN_UID, value: gcpUserInfo.uid });
      await Storage.set({
        key: LocalStorageKey.LOGIN_ACCESS_TOKEN,
        value: gcpUserInfo.accessToken,
      });

      return gcpUserInfo;
    } catch (error) {
      return null;
    }
  };

  // fetch login user start date
  const getUserStartDate = async () => {
    try {
      const userInfoRes: UserBaseInfoType = await getUserInfo();
      dispatch(updateUserStartDate(userInfoRes.startDate!));
      return userInfoRes;
    } catch (error) {
      return null;
    }
  };

  return {
    doSignIn,
    getUserStartDate,
  };
}
