import styles from "./styles.module.css";

interface MedicineImage {
  size: "XLarge" | "Large" | "MediumBig" | "Medium" | "Small" | string;
  src?: string;
  grayBg?: boolean;
  className?: string;
  hideFrame?: boolean;
}

export function AppMedicineImage(props: MedicineImage) {
  return (
    <div
      className={`${styles["div" + props.size]} div_center 
      ${props.hideFrame ? styles.noFrame : ""}
      ${props.className ? props.className : ""} ${props.grayBg ? styles.grayBg : ""}`}
    >
      <div className={`${styles.divCrop} ${styles["img" + props.size]}`}>
        {props.src && (
          <img
            src={props.src || ""}
            className={styles.imgDisplay}
            alt=""
            onLoad={(e) => {
              let image = e.target as HTMLImageElement;
              let size = 44;
              switch (props.size) {
                case "XLarge":
                  size = 200;
                  break;
                case "Large":
                  size = 150;
                  break;
                case "MediumBigBig":
                  size = 100;
                  break;
                case "MediumBig":
                  size = 90;
                  break;
                case "Medium":
                  size = 60;
                  break;
              }

              if (image.width > image.height) {
                image.style.height = `${size}px`;
              } else {
                image.style.width = `${size}px`;
              }
            }}
          />
        )}
      </div>
    </div>
  );
}
