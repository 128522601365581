import { useEffect } from "react";
import { IonGrid, IonRow, IonButton, IonLabel, IonModal, IonIcon } from "@ionic/react";
import { BUTTON_TIMEOUT } from "../../constants";
import finishImg from "../../assets/svgs/finish.svg";
import errorImg from "../../assets/svgs/error.svg";
import rightArrowImg from "../../assets/svgs/small-right-arrow-black.svg";
import closeBtn from "../../assets/svgs/close-button-white.svg";
import styles from "./styles.module.css";
import { AlertHeadline } from "../UiComponents/organisms";

import { useIntl } from "react-intl-phraseapp";

export default function AlertModal({
  showAlert,
  title,
  msg,
  closeLabel,
  nextLabel,
  handleClose,
  handleNext,
  handleCancel,
  isError = false,
}: {
  showAlert: boolean;
  title: string;
  msg?: string;
  closeLabel?: string;
  nextLabel?: string;
  handleClose?: () => void;
  handleNext?: () => void;
  handleCancel?: () => void;
  isError?: boolean;
}) {
  const intl = useIntl();

  useEffect(() => {
    // handle cancel modal: no action is required for native back
    const handleNativeBack = (ev: any) => {
      ev.detail.register(5, () => {});
    };
    document.addEventListener("ionBackButton", handleNativeBack);

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, []);

  return (
    <IonModal isOpen={showAlert} className={styles.alertWrapper} backdropDismiss={false}>
      <div className={styles.body}>
        <IonGrid>
          <IonRow
            className={`ion-justify-content-center ion-align-items-center ${styles.alertImgWrapper}`}
          >
            {isError ? (
              <>
                <img src={errorImg} className={styles.alertErrorImg} />
                <div className="page-spacer-45" />
              </>
            ) : (
              <>
                <img src={finishImg} className={styles.alertFinishImg} />
                <div className="page-spacer-30" />
              </>
            )}

            <AlertHeadline title={title} desc={msg} />

            {handleClose && (
              <>
                <div className="page-spacer-45" />

                <IonButton
                  className={styles.alertCloseBtn}
                  onClick={() => {
                    setTimeout(() => {
                      handleClose();
                    }, BUTTON_TIMEOUT);
                  }}
                >
                  {closeLabel
                    ? closeLabel
                    : intl.formatMessage({ id: "components.alertmodal.close_label" })}
                </IonButton>
              </>
            )}

            {handleNext && (
              <>
                <div className="page-spacer-15" />

                <IonLabel color="black" className={styles.navDetailBtn} onClick={handleNext}>
                  {nextLabel
                    ? nextLabel
                    : intl.formatMessage({ id: "components.alertmodal.next_label" })}
                  <IonIcon icon={rightArrowImg} className={styles.rightArrow} />
                </IonLabel>
              </>
            )}
          </IonRow>
        </IonGrid>
      </div>

      {handleCancel && (
        <>
          {/* 分割 */}
          <div className="page-spacer-40" />

          <IonIcon icon={closeBtn} className={styles.close} onClick={handleCancel} />
        </>
      )}
    </IonModal>
  );
}
