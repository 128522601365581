import { actionConst } from "../constants";

export const updateNewUserEmailAddress = (address: string) => {
  return {
    type: actionConst.UPDATE_NEW_USER_EMAIL_ADDRESS,
    address,
  };
};

export const clearNewUserEmailAddress = () => {
  return {
    type: actionConst.CLEAR_NEW_USER_EMAIL_ADDRESS,
  };
};
