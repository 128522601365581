import { useEffect, useState } from "react";
import { RADAR_MAP } from "src/web/constants";
import { TimelineType } from "src/web/types";
import { getColor } from "src/web/utils/common";
import { ChartDataTypeV2 } from "../../LineBarScatterChart/lineBarScatterChartV2";
import { useIntl } from "react-intl-phraseapp";

const BACKGROUND_COLOR = "--graph-background-color";
const CURRENT_COLOR = "--graph-current-color";

// グローバルのコントロール
const ECharts = global.echarts;

export function useWeeklySummaryPanelControl(
  scoreType: string,
  data: ChartDataTypeV2,
  weeklyTooltipForAvg: any,
  item_record_radar_chart_cust: any,
) {
  const intl = useIntl();
  const NO_RANK = intl.formatMessage({
    id: "components.charts.chart_summary_panel.summary_description.no_record",
  });
  let objVal = data.barTooltipVal;

  const levelMap: { [name: string]: number } = {
    a_r: 2,
    b_r: 0,
    c_r: 3,
    d_r: 1,
  };

  const [filteredTimelineList, setFilteredTimelineList] = useState<TimelineType[]>();

  useEffect(() => {
    setFilteredTimelineList(
      data.weekdayTimeline.filter(
        (item: TimelineType) => item.type === weeklyTooltipForAvg[scoreType].timelineType,
      ),
    );
  }, [data.weekdayTimeline, scoreType]);

  useEffect(() => {
    // レーダー図の初期化
    let option = {
      radar: [
        {
          // 四軸の最大値は100
          indicator: [{ max: 100 }, { max: 100 }, { max: 100 }, { max: 100 }],
          // 中心点指定
          center: ["50%", "50%"],
          // 90degree開始
          startAngle: 90,
          // 4方向
          splitNumber: 4,
          splitArea: {
            show: true,
            areaStyle: {
              color: getColor(BACKGROUND_COLOR),
            },
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          radius: ["0%", "100%"],
        },
      ],
      series: [
        {
          type: "radar",
          emphasis: {
            lineStyle: {
              width: 4,
            },
          },
          symbol: "none",
          chartWrap: "charts",
          data: [],
        },
      ],
    };
    let local_echart =
      ECharts.getInstanceByDom(item_record_radar_chart_cust.current) ||
      ECharts.init(item_record_radar_chart_cust.current);

    // local_echart.geto
    local_echart && local_echart.setOption(option);

    return () => {
      try {
        ECharts.dispose(item_record_radar_chart_cust.current);
      } catch {}
    };
  }, []);

  useEffect(() => {
    if (!objVal || (objVal && Object.keys(objVal).length === 0)) {
      return;
    }

    let local_echart =
      ECharts.getInstanceByDom(item_record_radar_chart_cust.current) ||
      ECharts.init(item_record_radar_chart_cust.current);
    // なしの場合、実施しない
    if (!local_echart) {
      return;
    }
    // 値を算出する
    let arrData: any[] = [0, 0, 0, 0];
    let scoreVal = data.barTooltipVal;

    if (scoreVal) {
      for (let key in RADAR_MAP) {
        arrData[RADAR_MAP[key]] = scoreVal[key];
      }
    }
    // alert(arrData)
    local_echart &&
      local_echart.setOption({
        series: [
          {
            data: [
              {
                value: arrData,
                name: "Avg",
                areaStyle: {
                  color: getColor(CURRENT_COLOR) + "B2",
                },
                lineColor: getColor(CURRENT_COLOR),
              },
            ],
          },
        ],
      });
  }, [data.barTooltipVal]);

  let levelRank = [NO_RANK, NO_RANK, NO_RANK, NO_RANK];
  let key_cnt = 0;
  for (const key in levelMap) {
    if (key in objVal) {
      let index = levelMap[key];
      let rank = objVal[key];
      if (rank) {
        levelRank[index!] = rank;
        key_cnt++;
      }
    }
  }

  let score = String(data.score);

  // 判定の長さ違い
  if (key_cnt !== levelRank.length) {
    score = "-";
  }

  if (!score) {
    score = "-";
  }

  let keys: any[] = [];
  let lineData: { [id: string]: any } = {};

  if (data.lineTooltipVal) {
    lineData = {
      ...data.lineTooltipVal,
    };

    // 1と2はデータが存在する
    if ("1" in lineData || "2" in lineData) {
      let firstLine = lineData["0_0"];

      if (!firstLine) {
        firstLine = [];
      }

      if (lineData["1"]) {
        firstLine = firstLine.concat(lineData["1"]);
        delete lineData["1"];
      }

      if (lineData["2"]) {
        firstLine = firstLine.concat(lineData["2"]);
        delete lineData["2"];
      }

      lineData["0_0"] = firstLine;
    }

    keys = Object.keys(lineData).sort();
  }

  return {
    score,
    levelRank,
    filteredTimelineList,
  };
}
