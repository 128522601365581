import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIonViewWillEnter } from "@ionic/react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { ContactType } from "src/web/types";
import { RootState } from "src/web/reducer";
import { clearContact } from "src/web/actions";
import { useIntl } from "react-intl-phraseapp";

export function useContactConfirmControl() {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const dispatch = useDispatch();

  const contactData = useSelector((state: RootState) => state.contact);

  const [isLoading, setIsLoading] = useState(false);
  const [fieldsAndData, setFieldsAndData] = useState([
    { label: intl.formatMessage({ id: "page.menu.inquiry_confirm.form.name" }), value: "" },
    { label: intl.formatMessage({ id: "page.menu.inquiry_confirm.form.email" }), value: "" },
    {
      label: intl.formatMessage({ id: "page.menu.inquiry_confirm.form.inquiry_title" }),
      value: "",
    },
    {
      label: intl.formatMessage({ id: "page.menu.inquiry_confirm.form.inquiry_content" }),
      value: "",
    },
  ]);

  const initData = async () => {
    if (contactData && JSON.stringify(contactData) !== "{}") {
      const formData: ContactType = JSON.parse(JSON.stringify(contactData));
      fieldsAndData[0].value = formData.name!;
      fieldsAndData[1].value = formData.mail!;
      fieldsAndData[2].value = formData.title!;
      fieldsAndData[3].value = formData.content!;
      setFieldsAndData([...fieldsAndData]);
    }
  };

  const doSubmit = async () => {
    setIsLoading(true);
    try {
      const inquiryNo = getInquiryNo();
      //TODO: api call here

      dispatch(clearContact());
      ionRouter.push(`/settings/contacts/complete?no=${inquiryNo}`, "forward", "replace");
    } finally {
      setIsLoading(false);
    }
  };

  const goToEdit = () => {
    ionRouter.goBack();
  };

  const getInquiryNo = () => {
    const padLeft = (val: number, length: number = 2) => {
      return val.toString().padStart(length, "0");
    };

    const date = new Date();
    const year = date.getFullYear();
    const month = padLeft(date.getMonth() + 1);
    const day = padLeft(date.getDate());
    const hh = padLeft(date.getHours());
    const mm = padLeft(date.getMinutes());
    const ss = padLeft(date.getSeconds());
    return `${year}${month}${day}${hh}${mm}${ss}`;
  };

  useIonViewWillEnter(() => {
    initData();
  });

  return {
    isLoading,
    fieldsAndData,
    doSubmit,
    goToEdit,
  };
}
