import React from "react";
import { DateWithScoreHeaderLine } from "../../moleclues";
import { WeeklySummaryChart } from "..";
import { ChartDataTypeV2 } from "src/web/components/Charts";

interface WeeklySummaryPanelHeaderProps {
  formattedDate: string;
  score: string;
  data: ChartDataTypeV2;
  radarChartRef: React.MutableRefObject<any>;
  levelRank: string[];
  switchTab: (tab: any) => void;
}

export function WeeklySummaryPanelHeader({
  formattedDate,
  score,
  data,
  radarChartRef,
  levelRank,
  switchTab,
}: WeeklySummaryPanelHeaderProps) {
  return (
    <>
      <DateWithScoreHeaderLine date={formattedDate} score={score} />

      <div className="page-spacer-50" />

      <WeeklySummaryChart
        data={data}
        radarChartRef={radarChartRef}
        levelRank={levelRank}
        switchTab={switchTab}
      />
    </>
  );
}
