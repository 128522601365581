import { useState, useMemo } from "react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { Storage } from "@capacitor/storage";
import { LocalStorageKey } from "src/web/constants";
import { validateEmail } from "src/web/utils/common";
import { useIntl } from "react-intl-phraseapp";
import { useUserModel } from "src/web/hooks/models";

export function useCreateUserEmailControl() {
  const iRouter = useAppRouter();
  const intl = useIntl();

  const { handleSendSignInLinkEmail } = useUserModel();

  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const handleEmailInputChange = (val: string) => {
    setIsValid(validateEmail(val));
    setEmail(val);
  };

  const goNext = async () => {
    if (isValid) {
      setErrorMsg("");
      setIsLoading(true);

      try {
        // sent mail
        await handleSendSignInLinkEmail(email);

        // cache the email
        await Storage.set({ key: LocalStorageKey.CREATE_USER_EMAIL_ADDRESS, value: email });

        setIsLoading(false);
        iRouter.push("/create-user-email-confirm", "forward", "push");
      } catch (error: any) {
        setIsLoading(false);
        if (error?.code) {
          setErrorMsg(
            intl.formatMessage({ id: "page.create_user_email.submit.error.general.message" }),
          );
        }
      }
    }
  };

  const goLogin = () => {
    iRouter.goBack();
  };

  const isNextBtnDisabled = useMemo(() => {
    return !email || !isValid;
  }, [email, isValid]);

  return {
    isLoading,
    email,
    errorMsg,
    isNextBtnDisabled,
    handleEmailInputChange,
    goNext,
    goLogin,
  };
}
