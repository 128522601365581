import { useState } from "react";
import { IonLabel } from "@ionic/react";
import { Clamped } from "react-clamped";
import { AppModalTemplate, AppPrimaryButton } from "../../../components/UiComponents";
import { getDateStrByDateNoPad, PrescriptionInfoType } from "../../../utils/common";
import styles from "./selectMedicineModal.module.css";
import { useIntl } from "react-intl-phraseapp";

interface SelectMedicineModalType {
  isOpen: boolean;
  jahisDatas?: PrescriptionInfoType[];
  handleClose: () => void;
  handleConfirm: (prescriptionInfo: PrescriptionInfoType) => void;
}

export default function SelectMedicineModal({
  isOpen,
  jahisDatas,
  handleClose,
  handleConfirm,
}: SelectMedicineModalType) {
  const [selectedMedicineIndex, setSelectedMedicineIndex] = useState<number | undefined>();
  const intl = useIntl();

  return (
    <AppModalTemplate isOpen={isOpen} showBackdrop={false}>
      <div className={styles.content}>
        <IonLabel className={`p-header4 ${styles.title}`}>
          {intl.formatMessage({ id: "page.select_medicine_modal.description.line1" })}
        </IonLabel>
        <IonLabel className={`p-header4 ${styles.title}`}>
          {intl.formatMessage({ id: "page.select_medicine_modal.description.line2" })}
        </IonLabel>

        <div className="page-spacer-45" />

        <div className={styles.scrollableLines}>
          {!!jahisDatas &&
            jahisDatas.map((lineData, index) => (
              <>
                {index > 0 && <div className="page-spacer-15" />}

                <div
                  className={`${styles.card} ${
                    selectedMedicineIndex === index ? styles.selectedCard : ""
                  }`}
                  onClick={() => setSelectedMedicineIndex(index)}
                >
                  {lineData.hospital && (
                    <IonLabel
                      className={`p-header4 ${
                        selectedMedicineIndex !== undefined && selectedMedicineIndex !== index
                          ? styles.notActiveLabel
                          : ""
                      }`}
                    >
                      {lineData.hospital}
                    </IonLabel>
                  )}

                  {lineData.hospital && lineData.medicineNames && (
                    <div className="page-spacer-10" />
                  )}

                  {lineData.medicineNames && (
                    <Clamped
                      className={`p-smallbody ${
                        selectedMedicineIndex !== undefined && selectedMedicineIndex !== index
                          ? styles.notActiveLabel
                          : ""
                      }`}
                      element="div"
                      clamp={1}
                    >
                      {lineData.medicineNames}
                    </Clamped>
                  )}

                  {lineData.dispensing_date && (lineData.hospital || lineData.medicineNames) && (
                    <div className="page-spacer-10" />
                  )}

                  {lineData.dispensing_date && (
                    <IonLabel
                      className={`p-smallbody  ${
                        selectedMedicineIndex !== undefined && selectedMedicineIndex !== index
                          ? styles.notActiveLabel
                          : ""
                      }`}
                    >
                      {intl.formatMessage({
                        id: "page.prescription_information.prescription.prescription.date.prescription",
                      })}
                      ：{getDateStrByDateNoPad(new Date(lineData.dispensing_date))}
                    </IonLabel>
                  )}
                </div>
              </>
            ))}
        </div>

        <div className="page-spacer-45" />

        <AppPrimaryButton
          full={true}
          disabled={selectedMedicineIndex === undefined}
          onClick={() =>
            jahisDatas &&
            selectedMedicineIndex !== undefined &&
            handleConfirm(jahisDatas[selectedMedicineIndex])
          }
        >
          {intl.formatMessage({ id: "page.select_medicine_modal.button.select" })}
        </AppPrimaryButton>

        <div className="page-spacer-30" />

        <IonLabel
          className={`p-body ${styles.title}`}
          onClick={() => {
            setSelectedMedicineIndex(undefined);
            handleClose();
          }}
        >
          {intl.formatMessage({ id: "page.select_medicine_modal.button.cancel" })}
        </IonLabel>
      </div>
    </AppModalTemplate>
  );
}
