import { IonPage, IonFooter } from "@ionic/react";
import {
  AppContent,
  AppHeader,
  AppHomeIndicatorBar,
  AppLoading,
  AppPrimaryButton,
  AppTextLink,
} from "src/web/components/UiComponents";
import { ContactInfoView } from "src/web/components/UiComponents/organisms";
import { useContactConfirmControl } from "./hooks/useContactConfirmControl";
import { useIntl } from "react-intl-phraseapp";

export function ContactConfirm() {
  const { isLoading, fieldsAndData, doSubmit, goToEdit } = useContactConfirmControl();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppHeader />

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <ContactInfoView
              pageTitle={intl.formatMessage({ id: "page.menu.inquiry_confirm.title" })}
              data={fieldsAndData}
            />

            <div className="page-spacer-30" />
          </div>
        </AppContent>

        <IonFooter className="ion-no-border">
          <div className="root-content">
            <div className="page-spacer-30" />
            <AppPrimaryButton full onClick={doSubmit}>
              {intl.formatMessage({ id: "page.menu.inquiry.form.button.submit" })}
            </AppPrimaryButton>

            <div className="page-spacer-15" />

            <AppTextLink hasNoArrow onClick={goToEdit}></AppTextLink>
            {intl.formatMessage({ id: "page.menu.inquiry.form.button.back" })}
            <AppHomeIndicatorBar />
          </div>
        </IonFooter>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
