import { useState, useEffect } from "react";
import { XAxis, YAxis, ScatterChart, Scatter } from "recharts";
import { Clamped } from "react-clamped";
import styles from "./scrollableLineChart.module.css";
import { MedicineLineChartDataType } from "src/web/types";

const yAxisWidth = 60;
const chartMargin = 5;
const defaultPageSpace = 25;

interface ScrollableLineChartV2Type {
  chartData: MedicineLineChartDataType;
  clickTitle?: () => void;
}

export function ScrollableLineChartV2({ chartData, clickTitle }: ScrollableLineChartV2Type) {
  let ionPagePadding = getComputedStyle(document.documentElement).getPropertyValue(
    "--psp-page-left-right-space",
  );

  const [chartWidth, setChartWidth] = useState(window.innerWidth - parseInt(ionPagePadding) * 2);
  const [lengthForEachScatter, setLengthForEachScatter] = useState(
    (chartWidth -
      yAxisWidth -
      chartMargin * 2 -
      (defaultPageSpace - parseInt(ionPagePadding)) * 2) /
      (chartData.xData.length * 2),
  );

  const [page, setPage] = useState(0);

  // const [selectedMedicine, setSelectedMedicine] = useState<{
  //   data?: any[];
  //   cx?: number;
  //   dataIndex?: number;
  // }>({
  //   data: undefined,
  //   cx: undefined,
  //   dataIndex: undefined,
  // });

  const CustomizedScatter: React.FC<any> = (props: any) => {
    // console.info(`CustomizedScatter ->`, props.cx)
    // const dataIndex = props.points.findIndex((point: any) => point.cx === props.cx);

    if (props.payload.y) {
      return (
        <svg
          x={props.cx - lengthForEachScatter / 2}
          y={props.cy - 2}
          fill="#C98B69"
          // onClick={() => handleMedicineSelect(props.data, props.cx, dataIndex)}
        >
          {/* <rect
            width={lengthForEachScatter * props.payload.value}
            height="25"
            fill="transparent"
          /> */}
          <rect width={lengthForEachScatter * props.payload.value} height="10" rx="5" ry="5" />
        </svg>
      );
    } else {
      return <></>;
    }
  };

  const CustomizedYAxisLabel: React.FC<any> = (props: any) => {
    const yData = props.chartData.find((item: any) => !!item.y);
    return (
      <svg xmlns="http://www.w3.org/2000/svg">
        <foreignObject
          width="50"
          height="42"
          onClick={() => {
            if (clickTitle) {
              clickTitle();
            }
          }}
        >
          <Clamped className="p-graph-min" element="div" clamp={3}>
            {yData ? yData.yName : ""}
          </Clamped>
        </foreignObject>
      </svg>
    );
  };

  // const CustomInfoTooltip: React.FC<any> = (props) => {
  //   // console.info(props)
  //   if (props.active && props.data === props.selectedData.data) {
  //     return (
  //       <div className={styles.tootip}>
  //         <div className={styles.tootipTitle}>
  //           {props.selectedData.data[props.selectedData.dataIndex].tooltip.title}
  //         </div>
  //         <div className="page-spacer-5" />
  //         <div className={styles.tooltipContent}>
  //           {props.selectedData.data[props.selectedData.dataIndex].tooltip.content}
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  // const handleMedicineSelect = (data: any[], cx: number, dataIndex: number) => {
  //   setSelectedMedicine({
  //     data,
  //     cx,
  //     dataIndex,
  //   });
  // };

  useEffect(() => {
    function handleResize() {
      ionPagePadding = getComputedStyle(document.documentElement).getPropertyValue(
        "--psp-page-left-right-space",
      );
      setChartWidth(window.innerWidth - parseInt(ionPagePadding) * 2);
      setLengthForEachScatter(
        (window.innerWidth -
          parseInt(ionPagePadding) * 2 -
          yAxisWidth -
          chartMargin * 2 -
          (defaultPageSpace - parseInt(ionPagePadding)) * 2) /
          (chartData.xData.length * 2),
      );
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={styles.root}>
        {chartData.data.map((chart, index) => {
          // if (index >= page * PAGE_SIZE && index < page * PAGE_SIZE + PAGE_SIZE) {
          return (
            <div className={styles.chartRoot} key={index}>
              <ScatterChart
                width={chartWidth - (defaultPageSpace - parseInt(ionPagePadding)) * 2}
                height={42}
                // onClick={(data, event) => {
                //   activeGraphTooltip(event);
                // }}
              >
                <XAxis
                  type="category"
                  interval={0}
                  tick={false}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  type="number"
                  dataKey="y"
                  width={yAxisWidth}
                  tick={false}
                  tickLine={false}
                  axisLine={false}
                  label={<CustomizedYAxisLabel chartData={chart} value="アナストロゾール" />}
                />
                {/* <Tooltip
                    trigger="click"
                    cursor={false}
                    wrapperStyle={{ zIndex: 10 }}
                    content={
                      graphInfo[MONTH_LINE_GRAPH_DISPLAY] === true ? (
                        <></>
                      ) : (
                        <CustomInfoTooltip data={chart} selectedData={selectedMedicine} />
                      )
                    }
                  /> */}
                <Scatter data={chart} shape={<CustomizedScatter data={chart} />} />
              </ScatterChart>

              <div
                className={styles.lineBackground}
                style={{
                  width:
                    chartWidth -
                    yAxisWidth -
                    chartMargin * 2 -
                    (defaultPageSpace - parseInt(ionPagePadding)) * 2,
                }}
              />
            </div>
          );
          // } else {
          //   return "";
          // }
        })}

        {/* <AppIcon
          className={styles.floatUpArrow}
          icon={ICONS.ICON.ARROW_UP}
          size="sm"
          disabled={page <= 0}
          onClick={() => setPage(page - 1)}
        />

        <AppIcon
          className={styles.floatDownArrow}
          icon={ICONS.ICON.ARROW_DOWN}
          size="sm"
          disabled={!!chartData.data && page >= Math.ceil(chartData.data.length / PAGE_SIZE) - 1}
          onClick={() => setPage(page + 1)}
        /> */}
      </div>
    </>
  );
}
