import { useState, useMemo } from "react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { validateEmail } from "src/web/utils/common";
import { useIntl } from "react-intl-phraseapp";
import { useUserModel } from "src/web/hooks/models";

export function useForgetPasswordSendMailControl() {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const { handleSendPasswordResetEmail } = useUserModel();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const handleEmailInputChange = (val: string) => {
    setIsValid(validateEmail(val));
    setEmail(val);
  };

  const isError = useMemo(() => {
    return isSubmitted && !isValid;
  }, [isSubmitted, isValid]);

  const isSendBtnDisabled = useMemo(() => {
    return !email || !isValid;
  }, [email, isValid]);

  const sendMail = async () => {
    if (isValid) {
      setIsSubmitted(true);
      setErrorMsg("");
      setIsLoading(true);

      try {
        // sent mail
        await handleSendPasswordResetEmail(email);
        setIsLoading(false);
        ionRouter.push(`/forget-password-success?email=${email}`);
      } catch (error: any) {
        setIsValid(false);
        setIsLoading(false);
        setErrorMsg(
          intl.formatMessage({ id: "page.forget_password_send_mail.error.message.general" }),
        );
      }
    }
  };

  return {
    isLoading,
    email,
    isError,
    errorMsg,
    isSendBtnDisabled,
    handleEmailInputChange,
    sendMail,
  };
}
