import { SymptonQuestionType } from "src/web/types";
import { AppPrimaryButton } from "../../AppPrimaryButton/appPrimaryButton";
import { IonRow } from "@ionic/react";
import { AppIcon } from "../../AppIcon/appIcon";
import { ICONS } from "src/web/constants";
import { AppPagination } from "../../AppPagination/appPagination";

interface QuestionPaginationProps {
  questionList?: SymptonQuestionType[];
  currentQuestionIndex: number;
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
  dynamicLabel: (question: SymptonQuestionType) => string;
  handleConfirm: () => void;
  goPrevious: () => void;
  goNext: () => void;
  gotoQuestionByIndex: (index: number) => void;
}

export function QuestionPagination({
  questionList,
  currentQuestionIndex,
  isPreviousDisabled,
  isNextDisabled,
  dynamicLabel,
  handleConfirm,
  goPrevious,
  goNext,
  gotoQuestionByIndex,
}: QuestionPaginationProps) {
  return (
    <>
      {!!questionList &&
        (currentQuestionIndex === questionList.length ||
          !!questionList[currentQuestionIndex].multiselection_flag) && (
          <div className="root-content">
            <AppPrimaryButton full onClick={handleConfirm}>
              {/* display "次へ" if select anything */}
              {dynamicLabel(questionList[currentQuestionIndex])}
            </AppPrimaryButton>

            <div className="page-spacer-50" />
          </div>
        )}
      {!!questionList?.length && (
        <>
          <IonRow className={`ion-justify-content-center ion-align-items-center`}>
            <AppIcon
              icon={ICONS.ICON.ARROW_BACK}
              onClick={goPrevious}
              disabled={isPreviousDisabled}
            />
            <AppPagination
              pages={[...questionList, {}]}
              currentIndex={currentQuestionIndex}
              onClick={(nextPageIndex: any) => gotoQuestionByIndex(nextPageIndex)}
            />
            <AppIcon icon={ICONS.ICON.ARROW_FORWARD} onClick={goNext} disabled={isNextDisabled} />
          </IonRow>
        </>
      )}
    </>
  );
}
