import { getDataFromApiOrLocal } from "../request/apiManager";

// get お知らせ in menu tab page
export function getNoticeList(params: any) {
  return getDataFromApiOrLocal("/api/v1/contents/notices", params);
}

// get よくある質問 in menu tab page
export function getFaqList(params: any) {
  return getDataFromApiOrLocal("/api/v1/contents/faqs", params);
}

// get その他 in menu tab page
export function getOtherDocumentList(params: any) {
  return getDataFromApiOrLocal("/api/v1/contents/other-documents", params);
}
