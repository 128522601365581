import { IonLabel, IonPage, isPlatform } from "@ionic/react";
import {
  AppContent,
  AppHeader,
  AppTextLink,
  AppToggleButton,
} from "../../../../components/UiComponents";
import { useIndexSettingControl } from "./hooks/useIndexSettingControl";
import { useIntl } from "react-intl-phraseapp";

export function IndexSetting() {
  const { isStep, checked, dataResult, toggleCheck, handleNavToSupportList } =
    useIndexSettingControl();
  const intl = useIntl();

  return (
    <IonPage>
      <AppHeader />

      <AppContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <IonLabel className="p-header1">
            {isStep
              ? intl.formatMessage({ id: "page.device_connection_activity.label.ios.app" })
              : intl.formatMessage({ id: "page.device_connection_activity.label.google.app" })}
          </IonLabel>

          <div className="page-spacer-45" />

          <div className="flex-row-between">
            <IonLabel className="p-body">
              {isPlatform("ios")
                ? intl.formatMessage({ id: "page.device_connection_activity.label.ios.app" })
                : intl.formatMessage({ id: "page.device_connection_activity.label.google.app" })}
            </IonLabel>

            <AppToggleButton checked={checked} onClick={toggleCheck} />
          </div>

          <div className="page-spacer-60" />

          <AppTextLink underLine onClick={handleNavToSupportList}>
            {intl.formatMessage({ id: "page.device_connection_activity.see_app_device" })}
          </AppTextLink>

          <p>------------------------------------------</p>
          <p>{JSON.stringify(dataResult)}</p>
          <p>------------------------------------------</p>
        </div>
      </AppContent>
    </IonPage>
  );
}
