import { IonContent, IonLabel, IonPage } from "@ionic/react";
import { AppHeader, AppTextLink } from "../../components/UiComponents";
import { useAppRouter } from "../../hooks/useAppRouter";
import { useQueryString } from "src/web/hooks/useQueryString";
import { useIntl } from "react-intl-phraseapp";

export function ForgetPasswordSendMailSuccess() {
  const intl = useIntl();
  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  const email = params.email as string;

  return (
    <IonPage>
      <AppHeader />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-45" />
          <IonLabel className="p-header4 text-center-block">{email}</IonLabel>
          <div className="page-spacer-45" />

          <IonLabel className="text-center-block p-header2">
            {intl.formatMessage({ id: "page.forget_password_send_mail_success.message.line1" })}
          </IonLabel>
          <IonLabel className="text-center-block p-header2">
            {intl.formatMessage({ id: "page.forget_password_send_mail_success.message.line2" })}
          </IonLabel>
          <IonLabel className="text-center-block p-header2">
            {intl.formatMessage({ id: "page.forget_password_send_mail_success.message.line3" })}
          </IonLabel>
          <IonLabel className="text-center-block p-header2">
            {intl.formatMessage({ id: "page.forget_password_send_mail_success.message.line4" })}
          </IonLabel>

          <div className="page-spacer-45" />

          <AppTextLink onClick={() => ionRouter.goBack()}>
            {intl.formatMessage({ id: "page.forget_password_send_mail_success.link.resend_mail" })}
          </AppTextLink>
        </div>
      </IonContent>
    </IonPage>
  );
}
