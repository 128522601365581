import { useRef, forwardRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { IonTextarea, IonLabel } from "@ionic/react";
import styles from "./appInput.module.css";

export const AppTextarea = forwardRef((props: any, ref?: any) => {
  const dispatch = useDispatch();

  const size: "full" | "md" | "sm" | "xs" = props.size || "full";

  const inputControl: React.MutableRefObject<any> = useRef();
  useImperativeHandle(ref, () => inputControl.current);

  return (
    <>
      {props.label && (
        <>
          <IonLabel className={styles.input_label}>{props.label}</IonLabel>
          <div className="page-spacer-15" />
        </>
      )}

      <div className={`${styles.inputWrapper} ${props.error ? styles.shake : ""}`}>
        <IonTextarea
          rows={10}
          {...props}
          className={`${styles.textArea} ${styles[size]}
          ${props.className ? props.className : ""}`}
          onIonChange={(event) => {
            props.onIonChange && props.onIonChange(event);
          }}
          ref={inputControl}
        />

        {props.error && (
          <>
            <IonLabel className="p-input-label-error">
              {props.errorMsg || props.error.message}
            </IonLabel>
          </>
        )}
      </div>
    </>
  );
});
