import { IonGrid, IonRow, IonHeader, isPlatform } from "@ionic/react";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { AppIcon } from "../AppIcon/appIcon";
import { ICONS } from "../../../constants";
import styles from "./appHeader.module.css";

export function AppHeader({
  children,
  backAction,
  isCenterChild,
  isTopNoPad = true,
  isDisplayBorder = false,
  isDisplayBackground = false,
}: {
  children?: any;
  backAction?: () => void;
  isCenterChild?: Boolean;
  isTopNoPad?: Boolean;
  isDisplayBorder?: Boolean;
  isDisplayBackground?: Boolean;
}) {
  const ionRouter = useAppRouter();

  const isShowHeaderBorder = useSelector((state: RootState) => state.isShowHeaderBoarder);

  return (
    <IonHeader className="ion-no-border">
      <div
        className={`${isPlatform("ios") ? styles.iosheader : ""} ${
          isDisplayBackground ? styles.topBackground : ""
        }`}
      >
        <IonGrid>
          <IonRow
            className={`ion-justify-content-between ion-align-items-center ${
              isTopNoPad ? styles.topBtnRow : styles.topBtnRowNoPad
            } ${
              isDisplayBorder || isShowHeaderBorder
                ? isTopNoPad
                  ? styles.topBottomLine
                  : styles.topBottomLineNoPad
                : ""
            }`}
          >
            <AppIcon
              icon={ICONS.ICON.ARROW_CIRCLE_BACK}
              className={styles.backBtn}
              onClick={() => {
                // 自分定義の返却動作を実施する
                if (backAction) {
                  backAction();
                } else {
                  ionRouter.goBack();
                }
              }}
            />
            {children}
            {isCenterChild && <div className={styles.rightDiv}></div>}
          </IonRow>
        </IonGrid>
      </div>
    </IonHeader>
  );
}
