import React, { createRef, useEffect } from "react";

// To use Html5Qrcode (more info below)
import { Html5Qrcode } from "html5-qrcode";
import { Html5QrcodeResult, Html5QrcodeError } from "html5-qrcode/core";
// import { BarcodeScanner, ScanOptions, CameraDirection, SupportedFormat } from '@capacitor-community/barcode-scanner';

import { IonModal } from "@ionic/react";

import styles from "./styles.module.css";

interface QRConfig {
  isOpen: boolean;
  onClose?: (csv: string) => void;
}

export function AppQR(props: QRConfig) {
  // const prepare = async () => {
  //     // backのカメラを利用する
  //     // qr codeだけをスキャンします。
  //     let config: ScanOptions = {
  //         cameraDirection: CameraDirection.BACK,
  //         targetedFormats: [SupportedFormat.QR_CODE]
  //     };
  //     await BarcodeScanner.prepare(config);
  // };

  // const checkPermission = async () => {
  //     // check or request permission
  //     const status = await BarcodeScanner.checkPermission({ force: true });

  //     if (status.granted) {
  //         // the user granted permission
  //         return true;
  //     }

  //     return false;
  // };

  // 初期処理
  useEffect(() => {
    // prepare();
  }, []);

  const qrCodeSuccessCallback = (decodedText: string, result: Html5QrcodeResult) => {
    alert(decodedText);
    /* handle success */
  };

  const qrCodeErrorCallback = (errorMessage: string, error: Html5QrcodeError) => {
    console.error("error");
    /* handle success */
  };

  return (
    <IonModal
      isOpen={props.isOpen}
      className={styles.model}
      onDidPresent={async () => {
        // // チェック権限ある
        // let permission = await checkPermission();
        // if (!permission) {
        //     // 権限なしの場合、画面を閉じる
        //     props.onClose && props.onClose("");
        //     return;
        // }

        // // 背景色をけします。
        // await BarcodeScanner.hideBackground();
        // const result = await BarcodeScanner.startScan();
        // if (result.hasContent) {
        //     console.log(result.content);
        //     alert(result.content);
        //     props.onClose && props.onClose(result.content as string);
        //     return;
        // }

        const html5QrCode = new Html5Qrcode("qrReader");
        const config = { fps: 1, qrbox: { width: 250, height: 250 } };

        // If you want to prefer front camera
        html5QrCode.start(
          { facingMode: "environment" },
          config,
          qrCodeSuccessCallback,
          qrCodeErrorCallback,
        );
      }}
    >
      <div
        className={`${styles.divContainer} div_center`}
        onClick={() => {
          props.onClose && props.onClose("");
        }}
      >
        <div id="qrReader" className={styles.divQR} />
      </div>
    </IonModal>
  );
}
