import styles from './screenLocker.module.css'

interface KeyboardButtonType {
  onClick: () => void,
  children: any
}

export function KeyboardButton({
  onClick,
  children
}: KeyboardButtonType) {
  return (
    <div className={`div_center p-total-score-diagram ${styles.keyboardButton}`} onClick={onClick}>
      {children}
    </div>
  )
}
