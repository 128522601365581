import { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../firebase";
import { Storage } from "@capacitor/storage";
import { useQueryString } from "src/web/hooks/useQueryString";
import {
  AppLoading,
  AppTitleWithLine,
  AppSecondaryButton,
  STATEFUL_STATES,
  AppArrowSwitcher,
} from "../../../../components/UiComponents";
import {
  getDateStrByDateNoPad,
  getToday,
  getNDaysBeforeOrAfterByDay,
  getNDaysBeforeOrAfterToday,
  formatTime,
} from "../../../../utils/common";
import { useCommon } from "src/web/utils/hooks/common";
import { useAlarm } from "src/web/utils/hooks/alarm";
import { MedicationStatusCard } from "../MedicationStatusCard/medicationStatusCard";
import {
  getCheckMedicinesMonth,
  getCurrentDateMedicine,
  saveCheckMedicineInfo,
} from "../../../../services";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import {
  LocalStorageKey,
  MEDICINE_CATEGORY,
  DEFAULT_MEDICINE_ONEDAY_QUANTITY,
  DEFAULT_MEDICINE_GAP_TIME,
  IMAGES_FOLDER,
  FIREBASE_FILE_NAMES,
} from "../../../../constants";
import { CurrentDateMedicineResType, CheckMedicineInfoCardType } from "./types";
import { AppCalendarFullStatusCommonSheet } from "../../../../components/UiComponents/AppCalendar/appCalendarFullStatusCommonSheet";
import { MedicineCheckSheet } from "../MedicineCheckSheet/medicineCheckSheet";
import { DailyAlarmRedirectType } from "../../../../types";
import { RootState } from "../../../../reducer";
import { useDispatch, useSelector } from "react-redux";
import { clearDailyAlarmRedirect } from "../../../../actions/updateDailyAlarmRedirectAction";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

const DEFAULT_TYPE_FOR_UNDEFILED_RECORD = "0";

interface MedicationManagementProps {
  outOpFlg?: number;
}

export function MedicationManagement({ outOpFlg }: MedicationManagementProps) {
  const intl = useIntl();
  const { generateLabeledEmptyOptions } = useCommon();
  const { getTitleByType } = useAlarm();
  const { DAY_CONVERT, MEDICINE_TIMING_OPTIONS } = useTextConstants();
  const location = useLocation();
  const dispatch = useDispatch();
  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter.routeInfo.search);
  const alarm: string = params.alarm as string;

  // 全画面の情報を取得する
  const dailyAlarmRedirect: DailyAlarmRedirectType = useSelector(
    (state: RootState) => state.dailyAlarmRedirect,
  );

  let lastTypeCode = "";

  const [currentDate, setCurrentDate] = useState((params.date as string) ?? getToday());
  const [dataList, setDataList] = useState<CheckMedicineInfoCardType[]>([]);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);

  const [showMedicineCheck, setShowMedicineCheck] = useState({
    isOpen: false,
    dayTimeAndType: "",
  });

  const [showMedicineData, setShowMedicineData] = useState<CheckMedicineInfoCardType>({});

  const [medicineIndex, setMedicineIndex] = useState(-1);

  const doFetch = async () => {
    const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });

    setLoading(true);
    try {
      const { checkMedicineInfo, medicineInfo, alarmInfo }: CurrentDateMedicineResType =
        await getCurrentDateMedicine({
          currentDate: currentDate,
        });

      /* load medicine images from firebase */
      const promises: any[] = [];

      Object.entries(medicineInfo).map(([medicineId, medicineDetails]) => {
        const imageRef = ref(
          storage,
          `${IMAGES_FOLDER}/${userId.value}/${medicineDetails.prescription_id}/${medicineId}/${FIREBASE_FILE_NAMES.medicineFileName}`,
        );
        promises.push(getDownloadURL(imageRef));
      });

      Promise.allSettled(promises).then((res) => {
        /* load medicine images from firebase */
        Object.keys(medicineInfo).map((key, index) => {
          if (res[index].status === "fulfilled") {
            medicineInfo[key].imgUrl = (res[index] as any).value;
          }
        });

        // sort the records as the option sort
        let sortedOptions = generateLabeledEmptyOptions(MEDICINE_TIMING_OPTIONS, true);
        Object.keys(checkMedicineInfo).forEach((key) => {
          const find = sortedOptions.find((opt) => opt.value === key);
          if (!find) {
            sortedOptions.push({ value: key, text: key });
          }
        });

        // restruct the data to array
        let records: CheckMedicineInfoCardType[] = [];
        sortedOptions.forEach((sort) => {
          if (checkMedicineInfo[sort.value]) {
            Object.entries(checkMedicineInfo[sort.value]).forEach((medicineItem) => {
              Object.entries(medicineItem[1]).forEach((item: any, index: number) => {
                let type = item[1].type ? item[1].type : DEFAULT_TYPE_FOR_UNDEFILED_RECORD;
                records.push({
                  ...item[1],
                  type: type,
                  id: parseInt(medicineItem[0]),
                  check_index: parseInt(item[0]),
                  medicineDetails: medicineInfo[medicineItem[0]],
                  alarm_time: alarmInfo[type] ? formatTime(alarmInfo[type].alarm_clock, true) : "",
                });

                /* check the last record, append a new unchecked item if: 
                  1. is 頓用薬
                  2. the last record was checked
                  3. the records were not reached the max one day quantity
                  4. the next medicine time is today
                */
                if (item[1].category === MEDICINE_CATEGORY.COUNTER_MEDICINE) {
                  const ondDayQuantity =
                    medicineInfo[medicineItem[0]].one_day_quantity ||
                    DEFAULT_MEDICINE_ONEDAY_QUANTITY;
                  const gapTime =
                    medicineInfo[medicineItem[0]]?.gap_time || DEFAULT_MEDICINE_GAP_TIME;

                  if (
                    index === Object.keys(medicineItem[1]).length - 1 &&
                    !!item[1].status &&
                    Object.keys(medicineItem[1]).length < ondDayQuantity &&
                    parseInt(item[1].check_time.split(":")[0]) + gapTime < 24
                  ) {
                    records.push({
                      ...item[1],
                      type: type,
                      id: parseInt(medicineItem[0]),
                      check_index: parseInt(item[0]) + 1,
                      check_time: "",
                      status: STATEFUL_STATES.unchecked,
                      medicineDetails: medicineInfo[medicineItem[0]],
                      alarm_time: alarmInfo[type]
                        ? formatTime(alarmInfo[type].alarm_clock, true)
                        : "",
                    });
                  }
                }
              });
            });
          }
        });

        setDataList(records);
        setLoading(false);
        if (alarm) {
          const ele = document.getElementById(alarm);
          if (ele) {
            ele.scrollIntoView();
          }
        }
      });
    } catch (error) {
      setLoading(false);
    }
  };

  // 日常薬 && その他の薬
  const handleStatusUpdate = (index: number, status: string, time: string) => {
    dataList[index].status = status;
    dataList[index].check_time = time;
    setDataList([...dataList]);
  };

  // 頓用薬
  const handleStatusUpdateForCounterMedicine = (index: number, status: string, time: string) => {
    const medicineId = dataList[index].id!;
    const ondDayQuantity =
      dataList[index].medicineDetails?.one_day_quantity || DEFAULT_MEDICINE_ONEDAY_QUANTITY;
    const gapTime = dataList[index].medicineDetails?.gap_time || DEFAULT_MEDICINE_GAP_TIME;
    const allRecordsForCurrentMedicine = dataList.filter(
      (data) => data.id === medicineId && data.category === MEDICINE_CATEGORY.COUNTER_MEDICINE,
    );

    // the last record of the current medicine
    const theLastRecord = allRecordsForCurrentMedicine.slice(-1)[0];

    /* append one more record if 
      1. current record is the last record 
      2. last record is not reached the max one day quantity
      3. the next medicine time is today.
    */
    if (
      status &&
      dataList[index].check_index === theLastRecord.check_index &&
      allRecordsForCurrentMedicine.length < ondDayQuantity &&
      parseInt(time.split(":")[0]) + gapTime < 24
    ) {
      let newRecord: CheckMedicineInfoCardType = JSON.parse(JSON.stringify(dataList[index]));
      newRecord.status = "";
      newRecord.check_index = allRecordsForCurrentMedicine.length;
      newRecord.check_time = "";

      const positionIndex = dataList.findIndex(
        (item) =>
          item.category === theLastRecord.category &&
          item.id === theLastRecord.id &&
          item.check_index === theLastRecord.check_index,
      );
      dataList.splice(positionIndex + 1, 0, newRecord);
    } else if (!status && dataList[index].check_index !== theLastRecord.check_index) {
      /* remove the last record if both of the last 2 records were unchecked */
      const positionIndex = dataList.findIndex(
        (item) =>
          item.category === theLastRecord.category &&
          item.id === theLastRecord.id &&
          item.check_index === theLastRecord.check_index,
      );
      dataList.splice(positionIndex, 1);
    }

    dataList[index].status = status;
    dataList[index].check_time = status ? time : "";

    setDataList([...dataList]);
  };

  useEffect(() => {
    // 初期日付は変換するとき、再設定する
    if (dailyAlarmRedirect?.date) {
      setCurrentDate(dailyAlarmRedirect?.date);
      // 一回利用した後で、reduxの値をクリアする
      dispatch(clearDailyAlarmRedirect());
    }
  }, [dailyAlarmRedirect?.date]);

  useEffect(() => {
    setDisabled(currentDate <= getNDaysBeforeOrAfterToday(-2));
    doFetch();
  }, [currentDate]);

  useEffect(() => {
    const params = parseQs(window.location.search);
    if (params.date) {
      setCurrentDate(params.date as string);
    }
  }, [location]);

  return (
    <>
      <div className="page-spacer-45" />

      <div className="flex-row-between">
        <AppArrowSwitcher
          text={`${getDateStrByDateNoPad(new Date(currentDate))}(${
            DAY_CONVERT[new Date(currentDate).getDay()]
          })`}
          handleLeftClick={() => {
            ionRouter.push(
              `/home/medication-record?date=${getNDaysBeforeOrAfterByDay(-1, currentDate)}`,
              "forward",
              "push",
            );
          }}
          handleRightClick={() => {
            ionRouter.push(
              `/home/medication-record?date=${getNDaysBeforeOrAfterByDay(1, currentDate)}`,
              "forward",
              "push",
            );
          }}
          handleLabelClick={() => {
            setShowCalendar(true);
          }}
          // disableRight={currentDate >= getToday()}
        />

        <AppSecondaryButton
          small={true}
          transparent={true}
          onClick={() => ionRouter.push("/home/notification-setting")}
        >
          {intl.formatMessage({ id: "page.medication_management.button.alarm" })}
        </AppSecondaryButton>
      </div>

      <div className="page-spacer-15" />

      {!!dataList.length &&
        dataList.map((data: CheckMedicineInfoCardType, index: number) => {
          const showTitle = lastTypeCode !== data.type;
          if (showTitle) {
            lastTypeCode = data.type!;
          }
          // console.log(data)
          return (
            <Fragment key={index}>
              {showTitle ? (
                <>
                  <div className="page-spacer-30" />
                  <AppTitleWithLine title={getTitleByType(data.type!)} subTitle={data.alarm_time} />
                  <div className="page-spacer-30" />
                </>
              ) : (
                <div className="page-spacer-15" />
              )}

              <MedicationStatusCard
                data={data}
                dataList={dataList}
                medicineCategory={data.category}
                currentDate={currentDate}
                disabled={disabled}
                showCheckbox={currentDate <= getToday()}
                saveUpdate={(status: string, time: string) => {
                  if (
                    data.category === MEDICINE_CATEGORY.EVERYDAY_MEDICINE ||
                    data.category === MEDICINE_CATEGORY.OTHERS
                  ) {
                    // 日常薬 && その他の薬
                    handleStatusUpdate(index, status, time);
                  } else {
                    // 頓用薬
                    handleStatusUpdateForCounterMedicine(index, status, time);
                  }
                }}
                handleCardClick={(id: number) =>
                  ionRouter.push(`/home/medicine-information-details?id=${id}`, "forward", "push")
                }
                outOpFlg={outOpFlg}
                handleEditClick={() => {
                  setShowMedicineCheck({
                    isOpen: true,
                    dayTimeAndType: `${getDateStrByDateNoPad(new Date(currentDate))}(${
                      DAY_CONVERT[new Date(currentDate).getDay()]
                    }) ${getTitleByType(data.type!)}`,
                  });
                  setShowMedicineData(data);
                  setMedicineIndex(index);
                }}
              />
            </Fragment>
          );
        })}

      <div className="page-spacer-45" />
      <AppLoading isOpen={loading} />

      <AppCalendarFullStatusCommonSheet
        isOpen={showCalendar}
        currentDate={currentDate}
        handleClose={() => setShowCalendar(false)}
        handleConfirm={(val) => {
          setCurrentDate(val);
          setShowCalendar(false);
        }}
        doFetch={(date: string) => getCheckMedicinesMonth({ current_date: date })}
        refactRes={(res: any) =>
          res.map((data: any) => {
            return {
              check_date: data.check_date.split("T")[0],
              cnt: data.cnt,
            };
          })
        }
        isFutureClickable={true}
      />

      <MedicineCheckSheet
        isOpen={showMedicineCheck.isOpen}
        dayTimeAndType={showMedicineCheck.dayTimeAndType}
        data={showMedicineData}
        handleClose={() =>
          setShowMedicineCheck({
            isOpen: false,
            dayTimeAndType: "",
          })
        }
        handleConfirm={(status, time) => {
          saveCheckMedicineInfo({
            id: showMedicineData!.id!,
            status,
            check_index: showMedicineData!.check_index!,
            current_date: currentDate,
            current_time: time,
          });

          if (
            showMedicineData.category === MEDICINE_CATEGORY.EVERYDAY_MEDICINE ||
            showMedicineData.category === MEDICINE_CATEGORY.OTHERS
          ) {
            // 日常薬 && その他の薬
            handleStatusUpdate(medicineIndex, status, time);
          } else {
            // 頓用薬
            handleStatusUpdateForCounterMedicine(medicineIndex, status, time);
          }
          setShowMedicineCheck({
            isOpen: false,
            dayTimeAndType: "",
          });
        }}
      />
    </>
  );
}
