import React from "react";
import { IonPage, IonFab, IonFabButton } from "@ionic/react";
import { ICONS } from "../../../constants";
import { AppLoading, AppLogo, AppIcon, AppContent } from "../../../components/UiComponents";
import { CurrentSymptomActionsheet } from "./components/CurrentSymptomActionsheet/currentSymptomActionsheet";
import {
  HomeTitle,
  HomeScore,
  CarouselView,
  CarouselWideView,
} from "src/web/components/UiComponents/organisms";
import { useDiseaseMainControl } from "./hooks/useDiseaseMainControl";
import { useIntl } from "react-intl-phraseapp";

export const DiseaseMain: React.FC = () => {
  const {
    isLoading,
    score,
    symptomAdvice,
    isShowReport,
    symptomSliderData,
    actionList,
    openCurrentSymptonActionSheet,
    videoSlidersList,
    handleNavToSymptomRecordMain,
    handleNavToSymptomEnteringPage,
    handleCloseActionSheet,
  } = useDiseaseMainControl();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppLogo />

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <HomeTitle />

            <div className="page-spacer-45" />

            <HomeScore
              score={score}
              symptomAdvice={symptomAdvice}
              handleNavToSymptomRecordMain={isShowReport ? handleNavToSymptomRecordMain : undefined}
            />
          </div>

          {/* 今の症状 */}
          {symptomSliderData.length > 0 && (
            <>
              <div className="page-spacer-45" />
              <CarouselView
                title={intl.formatMessage({
                  id: "page.main_tab.disease_main_tab.disease_main.label.current_symptom",
                })}
                subTitle={intl.formatMessage({
                  id: "page.main_tab.disease_main_tab.disease_main.label.symptom_batch_registeration",
                })}
                handleSubTitleClick={handleNavToSymptomEnteringPage}
                dataList={symptomSliderData}
              />
            </>
          )}

          {/* アクションリスト */}
          {actionList.length > 0 && (
            <>
              <div className="page-spacer-45" />
              <CarouselView
                title={intl.formatMessage({
                  id: "page.main_tab.disease_main_tab.disease_main.label.action_list",
                })}
                dataList={actionList}
              />
            </>
          )}

          {/* おすすめのアクティビティ */}
          {videoSlidersList.length > 0 && (
            <>
              <div className="page-spacer-45" />
              <CarouselWideView
                title={intl.formatMessage({
                  id: "page.main_tab.disease_main_tab.disease_main.label.recommended_activity",
                })}
                dataList={videoSlidersList}
              />
            </>
          )}

          <div className="page-spacer-60" />

          <AppLoading isOpen={isLoading} />

          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton className="fab-button" color="primary" href="/chat">
              <AppIcon icon={ICONS.FLOATING.CHAT_CIRCLE} size="xl" />
            </IonFabButton>
          </IonFab>
        </AppContent>
      </IonPage>

      <CurrentSymptomActionsheet
        data={openCurrentSymptonActionSheet}
        handleClose={handleCloseActionSheet}
      />
    </>
  );
};
