import { useState } from "react";
import { useSymptomRecordsModel } from "src/web/hooks/models";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { useQueryString } from "src/web/hooks/useQueryString";
import { PageActionCode, PageCode } from "src/web/types";

export function useAlertModalControl() {
  const { parseQs } = useQueryString();
  const ionRouter = useAppRouter();

  const { fetchDailyActivityTimeline, fetchSymptomQuestionnaireAnswerByDate } =
    useSymptomRecordsModel();

  const params = parseQs(ionRouter.routeInfo.search);
  const isFromHome = params.isFromHome === "true";

  const [isShowAlert, setIsShowAlert] = useState(false);

  const handlePostSave = () => {
    setIsShowAlert(true);
  };

  const refreshAndNavToReport = (currentDate: string) => {
    setIsShowAlert(false);
    try {
      // refresh timeline data
      fetchDailyActivityTimeline(
        {
          pageCode: PageCode.T03,
          pageActionCode: PageActionCode.T03_A01,
        },
        { currentDate },
      );
      // refresh score calculation
      fetchSymptomQuestionnaireAnswerByDate(
        {
          pageCode: PageCode.T03,
          pageActionCode: PageActionCode.T03_A01,
        },
        { currentDate },
      );
    } catch (error) {
    } finally {
      /*
        There is only 2 ways to open the blood preessure page right now
        1. from the home page
        2. from the report page
        which means if not from the home page, it must be from the report page.
        In this case, calling the "ionRouter.goBack()" will navigate to the report page.
      */
      if (isFromHome) {
        ionRouter.push(`/home/reports?date=${currentDate}`, "forward", "push");
      } else {
        ionRouter.goBack();
      }
    }
  };

  const closeAfterSubmit = (currentDate: string) => {
    if (!isFromHome) {
      refreshAndNavToReport(currentDate);
    } else {
      setIsShowAlert(false);
      ionRouter.goBack();
    }
  };

  return {
    isShowAlert,
    handlePostSave,
    closeAfterSubmit,
    refreshAndNavToReport,
  };
}
