import React from "react";
import { AppIcon } from "../../../../components/UiComponents";
import styles from "./actionSheetCard.module.css";

interface ActionSheetCardProps {
  title: string;
  icon: string;
  desc: string;
  onClick: any;
}

export const ActionSheetCard = ({ title, icon, desc, onClick }: ActionSheetCardProps) => {
  return (
    <div className={styles.root} onClick={onClick}>
      <div className={styles.shadowDiv} />

      <div className={styles.card}>
        <div className={styles.title}>{title}</div>

        <AppIcon icon={icon} size="lg" className={styles.icon} />

        <div className={styles.desc}>{desc}</div>
      </div>
    </div>
  );
};
