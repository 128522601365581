import { useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DraggableSettingItem } from "../DraggableSettingItem/draggableSettingItem";
import { useSymptomSettingControl } from "../../hooks/useSymptomSettingControl";
import { RootState } from "../../../../../reducer";
import { SymptionSettingDataType } from "../../../../../types";

export function DraggableSettingList() {
  const symptomSettingData: SymptionSettingDataType = useSelector(
    (state: RootState) => state.symptomSetting,
  );

  const { onDragEnd, handleToggleChange, isDisableDrag } = useSymptomSettingControl();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="home">
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {!!symptomSettingData?.homeList?.length &&
              symptomSettingData?.homeList.map((item, index) => (
                <Draggable
                  key={item.symptomId}
                  draggableId={`${item.symptomId}`}
                  index={index}
                  isDragDisabled={isDisableDrag(symptomSettingData?.homeList)}
                >
                  {(provided, snapshot) => (
                    <DraggableSettingItem
                      ref={provided.innerRef}
                      draggableProps={provided.draggableProps}
                      dragHandleProps={provided.dragHandleProps}
                      data={item}
                      handleToggleChange={handleToggleChange}
                      isHomeList
                      isDisabled={isDisableDrag(symptomSettingData?.homeList)}
                    />
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div className="page-spacer-13" />
      {!!symptomSettingData?.otherList?.length && <hr />}
      <div className="page-spacer-13" />

      <Droppable droppableId="others">
        {(provided, snapshot) => (
          <div ref={provided.innerRef}>
            {!!symptomSettingData?.otherList?.length &&
              symptomSettingData?.otherList.map((item, index) => (
                <Draggable key={item.symptomId} draggableId={`${item.symptomId}`} index={index}>
                  {(provided, snapshot) => (
                    <DraggableSettingItem
                      ref={provided.innerRef}
                      draggableProps={provided.draggableProps}
                      dragHandleProps={provided.dragHandleProps}
                      handleToggleChange={handleToggleChange}
                      data={item}
                    />
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
