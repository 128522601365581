import { useEffect } from "react";
import { IonContent, IonPage, IonProgressBar, IonList, IonItem, IonLabel } from "@ionic/react";
import { Capacitor } from "@capacitor/core";
import { useAppRouter } from "../../hooks/useAppRouter";
import { useDispatch } from "react-redux";
import { analytics, auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { isScreenLockerEnabled } from "../../utils/common";
import { updatePausePasscodeStatus } from "../../actions";
import "./index.css";
import { setUserId } from "firebase/analytics";
import { useAppUpdater } from "../../../web/hooks/useAppUpdater";
import { getUserInfo } from "src/web/services";
import { UserBaseInfoType } from "src/web/types";

const Loading = () => {
  const router = useAppRouter();

  const dispatch = useDispatch();
  const { updateApp } = useAppUpdater();

  const checkScreenLocker = async () => {
    const passCodeAvailable = await isScreenLockerEnabled();
    if (passCodeAvailable) {
      router.push("/screen-locker-passcode-confirm?redirectUrl=/home/main");
    } else {
      const userInfoRes: UserBaseInfoType = await getUserInfo();
      if (
        !userInfoRes.userName ||
        !userInfoRes.birthday ||
        !userInfoRes.gender ||
        !userInfoRes.prefecture
      ) {
        router.push("/create-user-base-info");
      } else {
        if (Capacitor.isNativePlatform()) {
          await updateApp();
        }
        router.push("/home/main");
      }
    }
  };

  // ignore the conditions that don't need to auto nav to login page
  const checkPagesAutoNavToLogin = () => {
    return (
      !window.location.pathname.includes("/settings/accounts/change-password") && // パスワード変更
      !window.location.pathname.includes("/settings/accounts/withdraw") // 退会
    ); // 退会
  };

  useEffect(() => {
    // restore pausePasscode status
    dispatch(updatePausePasscodeStatus(false));

    // No need to manually check the network status
    // Network.getStatus()
    //   .then((netStatus) => {
    //     if (netStatus.connected) {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // 設定id
        setUserId(analytics, user.uid);
        // do the auto navigation to the screen locker page only on loading page
        if (window.location.pathname === "/") {
          checkScreenLocker();
        }
      } else if (checkPagesAutoNavToLogin()) {
        router.push("/login", "forward", "replace");
      }
    });
    // } else {
    //   router.push("/pinCode", "forward", "push");
    // }
    // })
    // .catch();
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen className="center">
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IonList style={{ width: "80%" }}>
            <IonItem lines="none">
              <IonLabel>Loading</IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonProgressBar type="indeterminate"></IonProgressBar>
            </IonItem>
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Loading;
