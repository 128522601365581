import { rootReducers } from "../reducer";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "@redux-devtools/extension";
import logger from "redux-logger";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "medicineInformation",
    "prescriptionInformation",
    "refreshInfo",
    "graphInfo",
    "dailyAlarmRedirect",
    "isPasscodePausedForUsingCamera",
    "alertInfo",
    "timelineScrollHook",
    "dailyActivityTimeline",
    "dailyAnswersAndStatus",
    "scoreTransitionWeekChart",
    "scoreTransitionMonthChart",
    "avgCurrentScore",
    "newUserEmailAddress",
    "contact",
    "capacityKeyboardHeight",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store =
  process.env.NODE_ENV === `development`
    ? createStore(persistedReducer, composeWithDevTools(applyMiddleware(logger, thunk)))
    : createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

export const persistorStore = persistStore(store);
