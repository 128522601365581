import { AppArrowSwitcher } from "../../AppArrowSwitcher/appArrowSwitcher";
import { AppSecondaryButton } from "../../AppSecondaryButton/appSecondaryButton";
import { AdvicePanel } from "../../moleclues";

interface DateSwitcherWithCommentsProps {
  text: string;
  disableLeft?: boolean;
  disableRight?: boolean;
  btnLabel: string;
  comments?: string;
  handleLeftClick: () => void;
  handleRightClick: () => void;
  handleLabelClick?: () => void;
  handleBtnClick: () => void;
}

export function DateSwitcherWithComments({
  text,
  disableLeft,
  disableRight,
  btnLabel,
  comments,
  handleLeftClick,
  handleRightClick,
  handleLabelClick,
  handleBtnClick,
}: DateSwitcherWithCommentsProps) {
  return (
    <>
      <div className="flex-row-between">
        <AppArrowSwitcher
          text={text}
          handleLeftClick={handleLeftClick}
          handleRightClick={handleRightClick}
          handleLabelClick={handleLabelClick}
          disableLeft={disableLeft}
          disableRight={disableRight}
        />

        <AppSecondaryButton isDynamicWidth transparent onClick={handleBtnClick}>
          {btnLabel}
        </AppSecondaryButton>
      </div>

      {!!comments && (
        <>
          <div className="page-spacer-15" />
          <AdvicePanel contents={comments} />
        </>
      )}
    </>
  );
}
