import { actionConst } from "../constants";
import { GraphInfoType } from "../types";

const initialState: GraphInfoType = {};

interface Action {
  type: actionConst;
  state: any;
}

export default function graphInfoReducer(state = initialState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_GRAPH_INFO:
      return actions.state;
    case actionConst.CLEAR_GRAPH_INFO:
      return { ...initialState };
    default:
      return state;
  }
}
