import { useState } from "react";
import { IonContent, IonLabel, IonPage, useIonViewWillEnter } from "@ionic/react";
import { AppHeader, AppToggleButton } from "../../components/UiComponents";
import { useAppRouter } from "../../hooks/useAppRouter";
import { disableScreenLocker, isScreenLockerEnabled } from "../../utils/common";
import { useIntl } from "react-intl-phraseapp";

export function ScreenLocker() {
  const ionRouter = useAppRouter();
  const intl = useIntl();

  const [checked, setChecked] = useState(false);

  const handleCheck = async (nextStatus: boolean) => {
    setChecked(nextStatus);
    if (nextStatus) {
      ionRouter.push("/screen-locker-passcode/first");
    } else {
      await disableScreenLocker();
    }
  };

  const readCheckStatus = async () => {
    const thePasscodeEnabled = await isScreenLockerEnabled();
    setChecked(thePasscodeEnabled);
    if (!thePasscodeEnabled) {
      await disableScreenLocker();
    }
  };

  useIonViewWillEnter(() => {
    readCheckStatus();
  });

  return (
    <>
      <IonPage>
        <AppHeader />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="p-header1">
              {intl.formatMessage({ id: "page.screen_locker.title" })}
            </IonLabel>

            <div className="page-spacer-45" />

            <div className="flex-row-between">
              <IonLabel className="p-body">
                {intl.formatMessage({ id: "page.screen_locker.label.toggle" })}
              </IonLabel>

              <AppToggleButton checked={checked} onClick={() => handleCheck(!checked)} />
            </div>

            <div className="page-spacer-45" />

            <div>
              <IonLabel className="p-body">
                {intl.formatMessage({ id: "page.screen_locker.label.description.line1" })}
              </IonLabel>
            </div>

            <div>
              <IonLabel className="p-body">
                {intl.formatMessage({ id: "page.screen_locker.label.description.line2" })}
              </IonLabel>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
