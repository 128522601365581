import { SubMenuType } from "src/web/types";
import { LineItemWithIcon } from "../../moleclues";

interface MenuItemProps {
  item: SubMenuType;
  isGray?: boolean;
}

export function MenuItem({ item, isGray }: MenuItemProps) {
  return <LineItemWithIcon item={item} isGray={isGray} />;
}
