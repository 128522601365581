import { useEffect, useRef, useState, Fragment } from "react";

import { AppTabType } from "../../../types";
import styles from "./styles.module.css";

interface AppScrollableTabProps {
  tabs: AppTabType[];
  onChange?: React.Dispatch<React.SetStateAction<string>>;
}

export function AppScrollableTab({ tabs, onChange }: AppScrollableTabProps) {
  const tabRef = useRef<any>();

  const [currentTab, setCurrentTab] = useState(tabs[0].name);

  const switchTab = (e: any, tabName: string) => {
    setCurrentTab(tabName);
    const { left, right } = e.target.getBoundingClientRect();
    if (left < 0) {
      tabRef.current.scrollTo(tabRef.current.scrollLeft + left, 0);
    } else if (right > window.innerWidth) {
      tabRef.current.scrollTo(tabRef.current.scrollLeft + (right - window.innerWidth), 0);
    }
  };

  const handleOnChange = () => {
    onChange && onChange(currentTab);
  };

  useEffect(() => {
    handleOnChange();
  }, [currentTab]);

  return (
    <div className={styles.root} ref={tabRef}>
      {tabs.map((tab: AppTabType, index: number) => (
        <Fragment key={index}>
          {index > 0 && <div className={styles.tabSpace}>&nbsp;</div>}
          <div
            className={`${styles.tabItem} ${
              (currentTab === tab.name || (index === 0 && currentTab === "")) && styles.active
            }`}
            key={tab.name}
            onClick={(e) => switchTab(e, tab.name)}
          >
            {tab.text}
          </div>
        </Fragment>
      ))}
    </div>
  );
}
