import React from "react";
import { useIntl } from "react-intl-phraseapp";
import { ChartDataTypeV2 } from "src/web/components/Charts";
import { getToday } from "src/web/utils/common";
import { AppSecondaryButton } from "../../AppSecondaryButton/appSecondaryButton";
import { IonCol, IonGrid, IonRow, useIonRouter } from "@ionic/react";
import { RECORD } from "src/web/pages/SymptomRecordsTab";
import styles from "./weeklySummaryChart.module.css";

interface WeeklySummaryChartProps {
  data: ChartDataTypeV2;
  radarChartRef: React.MutableRefObject<any>;
  levelRank: string[];
  switchTab: (tab: any) => void;
}

export function WeeklySummaryChart({
  data,
  radarChartRef,
  levelRank,
  switchTab,
}: WeeklySummaryChartProps) {
  const ionRouter = useIonRouter();
  const intl = useIntl();

  return (
    <>
      <div className={styles.displayTitleDiv}>
        <div className={styles.displayTitle}>
          {intl.formatMessage({
            id: "components.charts.chart_summary_panel.summary_panel.report",
          })}
        </div>
        {data.date <= getToday() && (
          <AppSecondaryButton
            isDynamicWidth
            transparent={true}
            onClick={() => {
              switchTab(RECORD);
              if (data.date < getToday()) {
                ionRouter.push(
                  `/home/symptom-records-main?date=${data.date}`,
                  "forward",
                  "replace",
                );
              }
            }}
          >
            {intl.formatMessage({
              id: "components.charts.chart_summary_panel.summary_panel.report.view_button",
            })}
          </AppSecondaryButton>
        )}
      </div>
      <div className="page-spacer-15" />
      <hr className="title-hr" />
      <div className="page-spacer-45" />
      <IonGrid>
        <IonRow>
          <IonCol size="4">
            <div className="record_item_chart-parent" id="charts">
              <div
                ref={radarChartRef}
                className="record_item_chart"
                style={{ margin: 0, padding: 0, height: "68px", width: "68px" }}
              ></div>
            </div>
          </IonCol>
          <IonCol size="8">
            <div className={`${styles.rankDiv} score_rank_column`}>
              <IonGrid>
                <IonRow className="score_rank_title">
                  <IonCol>
                    {intl.formatMessage({
                      id: "components.charts.chart_summary_panel.summary_panel.score.symptom",
                    })}
                  </IonCol>
                  <IonCol size="4">
                    {intl.formatMessage({
                      id: "components.charts.chart_summary_panel.summary_panel.score.vital",
                    })}
                  </IonCol>
                  <IonCol>
                    {intl.formatMessage({
                      id: "components.charts.chart_summary_panel.summary_panel.score.activity",
                    })}
                  </IonCol>
                  <IonCol>
                    {intl.formatMessage({
                      id: "components.charts.chart_summary_panel.summary_panel.score.heart_body",
                    })}
                  </IonCol>
                </IonRow>
                <div className="page-spacer-8"></div>
                <IonRow className="score_rank">
                  <IonCol>{levelRank[0]}</IonCol>
                  <IonCol size="4">{levelRank[1]}</IonCol>
                  <IonCol>{levelRank[2]}</IonCol>
                  <IonCol>{levelRank[3]}</IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
}
