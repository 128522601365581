import { IonLabel } from "@ionic/react";
import { MedicineInfoType } from "src/web/types";
import { generateBlankEmptyOptions } from "../../../../utils/common";
import { SelectOption } from "../../../../types";
import { AppMedicineDetail1 } from "../../../../components/UiComponents/moleclues";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

interface DetailsInfoProps {
  data?: MedicineInfoType;
}

const TitleLine = ({ title }: { title: string }) => {
  return (
    <>
      <IonLabel className="p-header3">{title}</IonLabel>
      <div className="page-spacer-15" />
      <hr />
    </>
  );
};

const SubTitleLine = ({ title }: { title: string }) => {
  return (
    <>
      <div className="page-spacer-30" />
      <IonLabel className="p-body">{title}</IonLabel>
      <div className="page-spacer-15" />
    </>
  );
};

export function DetailsInfo({ data }: DetailsInfoProps) {
  const intl = useIntl();
  const { EMPTY_OPTION_TEXT, MEDICINE_INFO_SHAPE_CODE_OPTIONS } = useTextConstants();
  const getShapeNameByCode = (code?: string) => {
    if (code) {
      const options = generateBlankEmptyOptions(MEDICINE_INFO_SHAPE_CODE_OPTIONS, true);
      const selectedShapeItem: SelectOption = options.find((opt) => opt.value === code);
      return selectedShapeItem.text;
    } else {
      return EMPTY_OPTION_TEXT;
    }
  };

  return (
    <div>
      <TitleLine
        title={intl.formatMessage({
          id: "page.medicine_information_details.details_info.title.product",
        })}
      />
      <div className="page-spacer-30" />
      <AppMedicineDetail1
        title={intl.formatMessage({
          id: "page.medicine_information_details.details_info.title.product_name",
        })}
      >
        {data?.m_name}
      </AppMedicineDetail1>

      <div className="page-spacer-60" />

      <TitleLine
        title={intl.formatMessage({
          id: "page.medicine_information_details.details_info.title.component",
        })}
      />
      <div className="page-spacer-30" />
      <AppMedicineDetail1
        title={intl.formatMessage({
          id: "page.medicine_information_details.details_info.title.shape",
        })}
      >
        {getShapeNameByCode(data?.m_shape_code)}
      </AppMedicineDetail1>
    </div>
  );
}
