import { useState } from "react";
import { AppIcon } from "../";
import { ICONS } from "../../../constants";
import styles from "./styles.module.css";
import { useIntl } from "react-intl-phraseapp";

interface AppPNButtonProps {
  onPreClick?: (e?: any) => void;
  onNextClick?: (e?: any) => void;
}

let preBtnAnimationTimeout: any = null;
let nextBtnAnimationTimeout: any = null;
const initPreClickingState = 0;
const initNextClickingState = 0;

export function AppPNButton({ onPreClick, onNextClick }: AppPNButtonProps) {
  const intl = useIntl();
  const [isPreClicking, setIsPreClicking] = useState<number>(initPreClickingState);
  const [isNextClicking, setIsNextClicking] = useState<number>(initNextClickingState);

  const handlePreClick = (e: any) => {
    //動画を追加する
    clearTimeout(preBtnAnimationTimeout);
    setIsPreClicking(isPreClicking + 1);
    preBtnAnimationTimeout = setTimeout(() => {
      setIsPreClicking(initPreClickingState);
    }, 500);
    onPreClick && onPreClick(e);
  };

  const handleNextClick = () => {
    //動画を追加する
    clearTimeout(nextBtnAnimationTimeout);
    setIsNextClicking(isNextClicking + 1);
    nextBtnAnimationTimeout = setTimeout(() => {
      setIsNextClicking(initPreClickingState);
    }, 500);
    onNextClick && onNextClick();
  };

  return (
    <div
      className={`${styles.root}
        ${isPreClicking !== initPreClickingState ? styles.preBtnAnimation : ""}
        ${isNextClicking !== initNextClickingState ? styles.nextBtnAnimation : ""}`}
      key={isPreClicking + isNextClicking}
    >
      <div className={styles.leftBtn} onClick={handlePreClick}>
        <AppIcon size="sm" icon={ICONS.ICON.ARROW_CIRCLE_BACK} className={styles.icon} />
        <div className={styles.label}>
          <div className={styles.title}>
            {intl.formatMessage({ id: "ui_components.app_pn_button.previous_article" })}
          </div>
          <div className={styles.content}>検査と診断</div>
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.rightBtn} onClick={handleNextClick}>
        <div className={styles.label}>
          <div className={styles.title}>
            {intl.formatMessage({ id: "ui_components.app_pn_button.next_article" })}
          </div>
          <div className={styles.content}>薬物治療</div>
        </div>
        <AppIcon size="sm" icon={ICONS.ICON.ARROW_CIRCLE_FORWARD} className={styles.icon} />
      </div>
    </div>
  );
}
