import { Fragment } from "react";
import { IonRow, IonLabel } from "@ionic/react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { useTextConstants } from "src/web/constants/hooks";
import { MappingItemType, AnswerType, QuestionAnswerType } from "src/web/types";
import { useCategoryDataControl } from "./hooks/useCategoryDataControl";
import { useIntl } from "react-intl-phraseapp";
import { CategoryHeader } from "src/web/components/UiComponents/moleclues";
import styles from "./categoryData.module.css";

export const CategoryData = ({
  category,
  dataKey,
  data,
  currentDate,
  isReadonly,
}: {
  category: MappingItemType;
  dataKey: string;
  data: AnswerType;
  currentDate: string;
  isReadonly: boolean;
}) => {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const { NOT_INPUTED } = useTextConstants();

  const { getRank, getQuestions, getAnswer } = useCategoryDataControl();

  const rank: string = getRank(data, dataKey);

  const questions: QuestionAnswerType[] = getQuestions(data, dataKey);

  const btnLabel =
    JSON.stringify(questions) === "{}"
      ? intl.formatMessage({ id: "page.symptom_records_main.category.questions.button.new" })
      : intl.formatMessage({ id: "page.symptom_records_main.category.questions.button.edit" });

  return (
    <>
      <CategoryHeader
        rank={rank}
        isShowRank
        header={category.label}
        isReadonly={isReadonly}
        btnText={btnLabel}
        btnClick={() => {
          // 遷移画面のキーを取得する
          if (category.key === "bloodPressure") {
            ionRouter.push(`/blood-pressure?currentDate=${currentDate}&reportFlg=1`);
          } else if (category.key === "symptomQuestionnaire") {
            ionRouter.push(`/symptom-questionnaire?currentDate=${currentDate}&reportFlg=1`);
          }
        }}
      />

      {questions && Array.isArray(questions) ? (
        questions.map((qa: QuestionAnswerType) => {
          const displayAnswers = getAnswer(qa);
          return (
            <Fragment key={qa.inquiry_id}>
              {!!qa.answer && (
                <>
                  <div className="page-spacer-45" />
                  <IonRow className={`ion-justify-content-between `}>
                    <IonLabel color="black" className={`p-body ${styles.question}`}>
                      {qa.inquiry}
                    </IonLabel>
                    <IonLabel color="black">
                      {!qa.multiselection_flag ? (
                        <div className={`p-header4 ${styles.answer}`}>
                          {qa.answer ? qa.answer : NOT_INPUTED}
                        </div>
                      ) : (
                        <>
                          {displayAnswers.map((dislayAnswer: string) =>
                            !!dislayAnswer && data.hasContent ? (
                              <div
                                className={`p-header4 ${styles.answer} ${styles.multiAnswer}`}
                                key={dislayAnswer}
                              >
                                {dislayAnswer}
                              </div>
                            ) : (
                              ""
                            ),
                          )}
                        </>
                      )}
                    </IonLabel>
                  </IonRow>
                </>
              )}
            </Fragment>
          );
        })
      ) : (
        <>
          <div className="page-spacer-15" />
          <IonLabel className="p-body">{NOT_INPUTED}</IonLabel>
        </>
      )}
    </>
  );
};
