import { actionConst } from "../constants";
import { RefreshType } from "../types";

export const clearRefreshInfo = () => {
  return {
    type: actionConst.CLEAR_REFRESH_INFO,
  };
};

export const updateRefreshInfo = (refreshInfo: RefreshType) => {
  return {
    type: actionConst.UPDATE_REFRESH_INFO,
    state: refreshInfo,
  };
};
