import { IonLabel, IonPage } from "@ionic/react";
import {
  AppContent,
  AppHeader,
  AppHomeIndicatorBar,
  AppLoading,
} from "../../../components/UiComponents";
import { ListWithTitle } from "src/web/components/UiComponents/organisms";
import { useSupportAnnouncementIndexControl } from "./hooks/useSupportAnnouncementIndexControl";
import { useIntl } from "react-intl-phraseapp";

export function SupportAnnouncementIndex() {
  const { isLoading, resultList, navToDetails } = useSupportAnnouncementIndexControl();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppHeader />

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="p-header1">
              {intl.formatMessage({ id: "page.support_notice.title" })}
            </IonLabel>

            <div className="page-spacer-30" />

            <ListWithTitle list={resultList} handleOnClick={navToDetails} />

            <AppHomeIndicatorBar />
          </div>
        </AppContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
