import { useState } from "react";
import { Storage } from "@capacitor/storage";
import { LocalStorageKey } from "../../constants";
import { isExpired } from "react-jwt";
import { auth } from "../../firebase";

export function useTokenRefresh() {
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  /*
    Since there is no interceptor for @capacitor-community/http
    The auto refresh token function may still not working and causes 401 error
    So when you'd like to call multi-API at the same time, the token has to be checked and refreshed manually by calling this function before calling your APIs
  */
  const checkAndAutoRefreshTokenIfNecessary = async () => {
    const tkn = (await Storage.get({ key: LocalStorageKey.LOGIN_ACCESS_TOKEN })).value ?? "";
    if (tkn && isExpired(tkn)) {
      try {
        setIsLoadingToken(true);
        const newTkn = await auth.currentUser?.getIdToken(true);
        await Storage.set({
          key: LocalStorageKey.LOGIN_ACCESS_TOKEN,
          value: newTkn || tkn,
        });
      } catch (error) {
      } finally {
        setIsLoadingToken(false);
      }
    }
  };

  return {
    isLoadingToken,
    checkAndAutoRefreshTokenIfNecessary,
  };
}
