export function useQueryString() {
  function parseQs(search: string) {
    if (!search) {
      return {};
    }

    const targetSearch = search.substring(1);
    const searchs = targetSearch.split("&");
    let queryParams: any = {};
    searchs.forEach((param) => {
      const paramArr = param.split("=");
      queryParams = { ...queryParams, [paramArr[0]]: paramArr[1] };
    });
    return queryParams;
  }

  return {
    parseQs,
  };
}
