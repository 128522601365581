import { IonContent, IonLabel, IonPage } from "@ionic/react";
import { AppHeader, AppPrimaryButton } from "../../components/UiComponents";
import { useAppRouter } from "../../hooks/useAppRouter";
import { useIntl } from "react-intl-phraseapp";

export function ResetPasswordSuccess() {
  const ionRouter = useAppRouter();
  const intl = useIntl();

  return (
    <IonPage>
      <AppHeader />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <IonLabel className="text-center-block p-header2">
            {intl.formatMessage({ id: "page.reset_password_success.description.line1" })}
          </IonLabel>
          <IonLabel className="text-center-block p-header2">
            {intl.formatMessage({ id: "page.reset_password_success.description.line2" })}
          </IonLabel>

          <div className="page-spacer-60" />

          <AppPrimaryButton full onClick={() => ionRouter.push("/login")}>
            {intl.formatMessage({ id: "page.reset_password_success.button.back" })}
          </AppPrimaryButton>
        </div>
      </IonContent>
    </IonPage>
  );
}
