import {
  EMPTY_OPTION_STRING_VALUE,
  DEFAULT_ALARM_TIMING,
  SYSTEM_TYPE_ALARM,
  CUSTOM_TYPE_ALARM,
  LocalStorageKey,
  NOTIFICATION_LIMIT_ANDROID,
  NOTIFICATION_LIMIT_IOS,
} from "src/web/constants";
import {
  alarmSettingResType,
  alarmMedicineInfoItemType,
  medicineInfoType,
} from "src/web/types";
import { useTextConstants } from "src/web/constants/hooks";
import { getToday } from "src/web/utils/common";
import { getAlarmSetting } from "src/web/services";
import { useCommon } from "src/web/utils/hooks/common";
import { Storage } from "@capacitor/storage";
import { MedicineInfo } from "src/web/utils/common";
import {
  LocalNotifications,
  ActionPerformed,
  LocalNotificationSchema,
  PendingLocalNotificationSchema,
} from "@capacitor/local-notifications";
import { isPlatform } from "@ionic/react";
import { useIntl } from "react-intl-phraseapp";

export function useAlarm() {
  const intl = useIntl();
  const NOTIFICATION_BODY = intl.formatMessage({ id: "common.medication_alarm_notification.body" });
  const NOTIFICATION_TITLE = intl.formatMessage({ id: "common.medication_alarm_notification.title" });
  const { EMPTY_OPTION_TEXT, MEDICINE_TIMING_OPTIONS, MEDICINE_TIMING_UNIT_OPTIONS } = useTextConstants();

  const { generateLabeledEmptyOptions } = useCommon();
  // get medicine 服用タイミング by type
  const getTitleByType = (type: string) => {
    if (type === EMPTY_OPTION_STRING_VALUE) {
      return `時間${EMPTY_OPTION_TEXT}`;
    } else {
      const titleData = MEDICINE_TIMING_OPTIONS.find((opt) => opt.value === type);
      if (titleData) {
        return titleData?.text ? titleData?.text : "";
      } else {
        return type;
      }
    }
  };


  const getScheduleAlarm = async () => {
    const { alarmInfo, alarmMedicineInfos }: alarmSettingResType = await getAlarmSetting({
      currentDate: getToday(),
    });

    let tmpMedicineInfos: medicineInfoType[] = [];

    if (alarmMedicineInfos && Object.keys(alarmMedicineInfos).length !== 0) {
      // reflect the records in the timing option
      generateLabeledEmptyOptions(MEDICINE_TIMING_OPTIONS, true).forEach(({ value }) => {
        let infoArr: alarmMedicineInfoItemType[] = [];

        if (alarmMedicineInfos[value]) {
          // medicine details for every alarm timing record
          Object.values(alarmMedicineInfos[value]).forEach((info: alarmMedicineInfoItemType) => {
            if (info.unit) {
              const unit = MEDICINE_TIMING_UNIT_OPTIONS.find((opt) => opt.value === info.unit);
              info.unit_name = unit ? unit.text : "";
            }
            // 時間のタイプを転換する
            info.start = new Date(Date.parse(info.start! as any));
            info.end = new Date(Date.parse(info.end! as any));

            infoArr.push(info);
          });

          // alarm details
          let timeItem: medicineInfoType = alarmInfo[value]
            ? {
                ...alarmInfo[value],
                medicineDetails: infoArr,
              }
            : {
                alarm_clock: DEFAULT_ALARM_TIMING[value] ? DEFAULT_ALARM_TIMING[value] : "",
                alarm_flg: false,
                alarm_title: "",
                time_id: value,
                type: SYSTEM_TYPE_ALARM,
                medicineDetails: infoArr,
              };

          // init alarm_title
          if (timeItem.type !== CUSTOM_TYPE_ALARM && !timeItem.alarm_title) {
            const timingOpt = generateLabeledEmptyOptions(MEDICINE_TIMING_OPTIONS, true).find(
              (opt) => opt.value === value,
            );
            timeItem.alarm_title = timingOpt ? timingOpt.text : "";
          }

          tmpMedicineInfos.push(timeItem);

          delete alarmMedicineInfos[value];
          delete alarmInfo[value];
        }
      });

      // reflect the records out of the timing options
      Object.entries(alarmMedicineInfos).forEach(([key, value]) => {
        let infoArr: alarmMedicineInfoItemType[] = [];

        Object.values(value).forEach((info: alarmMedicineInfoItemType) => {
          if (info.unit) {
            const unit = MEDICINE_TIMING_UNIT_OPTIONS.find((opt) => opt.value === info.unit);
            info.unit_name = unit ? unit.text : "";
          }
          infoArr.push(info);
        });

        let timeItem: medicineInfoType = alarmInfo[key]
          ? {
              ...alarmInfo[key],
              alarm_title: alarmInfo[key].alarm_title ? alarmInfo[key].alarm_title : key,
              medicineDetails: infoArr,
            }
          : {
              alarm_clock: key,
              alarm_flg: false,
              alarm_title: key,
              time_id: key,
              type: SYSTEM_TYPE_ALARM,
              medicineDetails: infoArr,
            };

        tmpMedicineInfos.push(timeItem);

        delete alarmInfo[key];
      });
    }

    Object.entries(alarmInfo).forEach(([key, value]) => {
      tmpMedicineInfos.push(alarmInfo[key]);
    });

    return tmpMedicineInfos;
  };


  const refreshAlarm = async (forceRefresh?: boolean) => {
    if (!forceRefresh) {
      // check global medicine notification flg, skipping setting alarms if the flag is false
      const globalMedicineNotificationEnabled = await (
        await Storage.get({ key: LocalStorageKey.GLOBAL_MEDICINE_NOTIFICATION_ENABLED })
      ).value;
      if (globalMedicineNotificationEnabled !== "true") {
        return;
      }
    }

    let tmpMedicineInfos = await getScheduleAlarm();
    let dataArray: any = [];

    if (tmpMedicineInfos) {
      for (let data of tmpMedicineInfos) {
        dataArray.push({
          title: data.alarm_title,
          time: data.alarm_clock,
          isNew: data.type === "1",
          time_id: data.time_id,
          deleteFlg: !data.alarm_flg,
          medicineInfos: data.medicineDetails,
        });
      }
      await setScheduleAlarm(dataArray, false);
    }
  };

  const diffDay = (start: Date, end: Date) => {
    const diffTime = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const checkDate = (currentDate: Date, medicineInfos: MedicineInfo[]) => {
    // 月の最後日を取得する
    let lastDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  
    for (let medicineInfo of medicineInfos) {
      // 時間範囲外の場合
      if (
        currentDate.getTime() < medicineInfo.start.getTime() ||
        currentDate.getTime() > medicineInfo.end.getTime()
      ) {
        // 次の薬へ
        continue;
      }
  
      // 頻度内の場合直接ok
      if (medicineInfo.frequency === null || medicineInfo.frequency === undefined) {
        return true;
      }
      // なしと毎にの場合
      if (medicineInfo.frequency.type === "0" || medicineInfo.frequency.type === "01") {
        return true;
      }
      // 日おき
      if (medicineInfo.frequency.type === "02" || medicineInfo.frequency.type === "03") {
        let diff = diffDay(medicineInfo.start, currentDate);
        if (diff % parseInt(medicineInfo.frequency.type) === 0) {
          return true;
        }
        continue;
      }
  
      // 毎週の曜日
      if (medicineInfo.frequency.type === "04") {
        if (!medicineInfo.frequency.week_day) {
          continue;
        }
  
        if (medicineInfo.frequency.week_day.includes(currentDate.getDay().toString())) {
          return true;
        }
        continue;
      }
  
      // カスタマイズ
      if (medicineInfo.frequency.type === "05") {
        // 日毎
        if (medicineInfo.frequency.unit === "01") {
          if (medicineInfo.frequency.every_gap === 1) {
            return true;
          }
          let diff = diffDay(medicineInfo.start, currentDate);
          if (diff % medicineInfo.frequency.every_gap === 0) {
            return true;
          }
          continue;
          // 週置き
        } else if (medicineInfo.frequency.unit === "02") {
          let diff = diffDay(medicineInfo.start, currentDate);
          let addDay = 6 - medicineInfo.start.getDay();
  
          if (!medicineInfo.frequency.week_day) {
            continue;
          }
  
          if (diff <= addDay) {
            if (medicineInfo.frequency.week_day.includes(currentDate.getDay().toString())) {
              return true;
            }
            continue;
          }
  
          if (Math.ceil((diff - addDay) / 7) % medicineInfo.frequency.every_gap === 0) {
            if (medicineInfo.frequency.week_day.includes(currentDate.getDay().toString())) {
              return true;
            }
            continue;
          }
          continue;
          // 月置き
        } else if (medicineInfo.frequency.unit === "03") {
          // 時間が満足の場合
          if (
            medicineInfo.start.getFullYear() < currentDate.getFullYear() ||
            (medicineInfo.start.getFullYear() === currentDate.getFullYear() &&
              medicineInfo.start.getMonth() <= currentDate.getMonth())
          ) {
            let months = 0;
            // 年内を算出する
            if (currentDate.getFullYear() === medicineInfo.start.getFullYear()) {
              months = currentDate.getMonth() - medicineInfo.start.getMonth();
              // 年内出ない場合
            } else {
              months = currentDate.getMonth() + 1 + 11 - medicineInfo.start.getMonth();
              let addMonths = (currentDate.getFullYear() - medicineInfo.start.getFullYear() - 2) * 12;
              if (addMonths > 0) {
                months += addMonths;
              }
            }
  
            if (months % medicineInfo.frequency.every_gap !== 0) {
              continue;
            }
            let date = medicineInfo.start.getDate();
            // 日付違い場合
            if (currentDate.getDate() === date) {
              return true;
            } else if (lastDate < date && lastDate === currentDate.getDate()) {
              return true;
            }
            continue;
          }
          continue;
          // 年毎
        } else if (medicineInfo.frequency.unit === "04") {
          let years = currentDate.getFullYear() - medicineInfo.start.getFullYear();
          if (years % medicineInfo.frequency.every_gap !== 0) {
            continue;
          }
  
          if (
            currentDate.getMonth() === medicineInfo.start.getMonth() &&
            currentDate.getDate() === medicineInfo.start.getDate()
          ) {
            return true;
          }
          // 二月の最終日と同じなったらok
          else if (
            currentDate.getMonth() === medicineInfo.start.getMonth() &&
            currentDate.getMonth() === 1 &&
            currentDate.getDate() === lastDate &&
            medicineInfo.start.getDate() === 29
          ) {
            return true;
          }
          continue;
        }
        continue;
      }
      continue;
    }
    return false;
  };
  const setScheduleAlarm = async (
    dataArray: [
      {
        title: string;
        time: string;
        isNew: boolean;
        time_id: string;
        deleteFlg: boolean;
        medicineInfos: MedicineInfo[];
      },
    ],
    isCheckNew: boolean = true,
  ) => {
    // 権限チェックを実行する
    let permission = await LocalNotifications.checkPermissions();
    if (permission.display !== "granted") {
      permission = await LocalNotifications.requestPermissions();
      if (permission.display !== "granted") {
        return;
      }
    }
  
    // ペンディング中の情報を全部削除する
    let pendingData = await LocalNotifications.getPending();
    let cancelIds: { id: number }[] = [];
    let leftCnt = 0; //残りの通知
    for (let pendingNotification of pendingData.notifications) {
      // 新規できる以外の内容を全部削除する
      if (isCheckNew && pendingNotification.extra.type === "1") {
        leftCnt++;
        continue;
      }
  
      cancelIds.push({ id: pendingNotification.id });
    }
  
    // 削除データが存在している場合
    if (cancelIds.length !== 0) {
      await LocalNotifications.cancel({
        notifications: cancelIds,
      });
    }
  
    let allLimit = NOTIFICATION_LIMIT_ANDROID;
    if (isPlatform("ios")) {
      allLimit = NOTIFICATION_LIMIT_IOS;
    }
  
    allLimit -= leftCnt;
    // 制御値になる
    if (allLimit <= 0) {
      return;
    }
  
    /* check flag for お薬記録 - 通知サウンド (the flag only available for android, there is no such flag for IOS)
       if the flag is "true", set application sound as notification sound
     */
    const globalMedicineNotificationSoundEnabled = await (
      await Storage.get({ key: LocalStorageKey.GLOBAL_MEDICINE_NOTIFICATION_SOUND_ENABLED })
    ).value;
  
    if (!isCheckNew) {
      // 新規のidは30000000001始まりです
      // id一致の情報をキャンセル
      let newId: number = -1;
      let newCount: number = 1;
  
      for (let playLoad of dataArray) {
        // 削除或いはnew以外の場合
        if (!playLoad.isNew || playLoad.deleteFlg) {
          // 処理がい
          continue;
        }
  
        let timeArray = playLoad.time.split(":");
        let hour = parseInt(timeArray[0]);
        let minute = parseInt(timeArray[1]);
        // 時間不正のデータを除外する
        if (isNaN(hour) || isNaN(minute)) {
          continue;
        }
  
        // スケジュールのidを順番に設定する
        newId = 3000000000 + newCount;
  
        await LocalNotifications.schedule({
          notifications: [
            {
              id: newId,
              title: `${playLoad.title}${NOTIFICATION_TITLE}`,
              body: NOTIFICATION_BODY,
              schedule: {
                repeats: true,
                on: {
                  hour: hour,
                  minute: minute,
                },
              },
              extra: {
                id: playLoad.time_id,
                scheduleId: newId,
                type: "1",
              },
              sound:
                globalMedicineNotificationSoundEnabled === "true"
                  ? "mixkit-alert-quick-chime.wav"
                  : undefined,
            },
          ],
        });
        newCount++;
        allLimit--;
  
        if (allLimit <= 0) {
          return;
        }
      }
    }
  
    let currentTime = new Date();
    let now = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
    let maxEndDate = now;
    for (let playLoad of dataArray) {
      // 削除或いはnewの場合
      if (playLoad.isNew || playLoad.deleteFlg || !playLoad.medicineInfos) {
        // 処理がい
        continue;
      }
  
      for (let medicineInfo of playLoad.medicineInfos) {
        if (medicineInfo.end > maxEndDate) {
          maxEndDate = medicineInfo.end;
        }
      }
    }
  
    let newId: number = -1;
    let newCount: number = 1;
    // To calculate the no. of days between two dates
    var Difference_In_Days = diffDay(now, maxEndDate);
    let addNotifications: LocalNotificationSchema[] = [];
    let fullYear = currentTime.getFullYear();
    let fullMonth = currentTime.getMonth();
    let fullDate = currentTime.getDate();
  
    for (let i = 0; i < Difference_In_Days && allLimit > 0; i++) {
      let currentDate = new Date(Date.UTC(fullYear, fullMonth, fullDate + i));
  
      for (let playLoad of dataArray) {
        // 削除或いはnewの場合
        if (playLoad.isNew || playLoad.deleteFlg) {
          // 処理がい
          continue;
        }
  
        let timeArray = playLoad.time.split(":");
        let hour = parseInt(timeArray[0]);
        let minute = parseInt(timeArray[1]);
        // 時間不正のデータを除外する
        if (isNaN(hour) || isNaN(minute)) {
          continue;
        }
  
        if (checkDate(currentDate, playLoad.medicineInfos ?? [])) {
          // スケジュールのidを順番に設定する
          newId = 2000000000 + newCount;
          addNotifications.push({
            id: newId,
            title: `${playLoad.title}${NOTIFICATION_TITLE}`,
            body: NOTIFICATION_BODY,
            schedule: {
              at: new Date(fullYear, fullMonth, fullDate + i, hour, minute),
            },
            extra: {
              id: playLoad.time_id,
              scheduleId: newId,
              type: "0",
            },
            sound:
              globalMedicineNotificationSoundEnabled === "true"
                ? "mixkit-alert-quick-chime.wav"
                : undefined,
          });
          newCount++;
          allLimit--;
        }
  
        if (allLimit <= 0) {
          break;
        }
      }
    }
  
    if (addNotifications.length > 0) {
      LocalNotifications.schedule({ notifications: addNotifications });
    }
  };
  return {
    getTitleByType,
    refreshAlarm,
    setScheduleAlarm
  };
}