import { useIntl } from "react-intl-phraseapp";
import styles from "./dateWithScoreHeaderLine.module.css";

interface DateWithScoreHeaderLineProps {
  date: string;
  label?: string;
  score: string | number;
}

export function DateWithScoreHeaderLine({ date, label, score }: DateWithScoreHeaderLineProps) {
  const intl = useIntl();

  return (
    <div className={styles.weekTitleDiv}>
      <div className="p-body">{date}</div>
      <div className={styles.weekTitleScoreDiv}>
        <div className="p-smallbody">
          {!!label
            ? label
            : intl.formatMessage({
                id: "components.charts.chart_summary_panel.summary_panel.score",
              })}
        </div>
        <div className="page-width-spacer-10" />
        <div className="p-score-panel item_group">{score}</div>
      </div>
    </div>
  );
}
