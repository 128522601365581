import { actionConst } from "../constants";
import { SymptionSettingDataType } from "../types";

export const clearSymptomSetting = () => {
  return {
    type: actionConst.CLEAR_SYMPTOM_SETTING,
  };
};

export const updateSymptomSetting = (state: SymptionSettingDataType) => {
  return {
    type: actionConst.UPDATE_SYMPTOM_SETTING,
    state,
  };
};
