import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { IonLabel, IonInput, isPlatform } from "@ionic/react";
import { Keyboard } from "@capacitor/keyboard";
import { AppIcon } from "../AppIcon/appIcon";
import { generateBlankEmptyOptions } from "../../../utils/common";
import { ICONS, EMPTY_OPTION_STRING_VALUE, EMPTY_OPTION_NUMBER_VALUE } from "../../../constants";
import styles from "./appInput.module.css";
import { SelectOption } from "../../../types";
import { AppSelectOptions } from "../";

interface AppSelectInputProp {
  label?: string;
  placeholder?: string;
  className?: string;
  value: any;
  defaultValue?: any;
  separatedBy?: string;
  isLoop?: boolean;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  options: SelectOption[];
  options2?: SelectOption[];
  error?: any;
  errorMsg?: string;
  needClear?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  getDefaultValue?: () => string;
  isDisabledOpenEvent?: boolean;
  size?: "full" | "md" | "sm" | "xs";
}

export const AppSelectInput = forwardRef(
  (
    {
      label,
      placeholder,
      className,
      value,
      // 初期表示値
      // 利用箇所：
      // string変数ので、state利用しない場合、
      // 初回設定すると、値は変更しない。
      // defaultValueとgetDefaultValueは同時設定する時、優先defaultValueの値を利用します。
      defaultValue,
      separatedBy,
      isLoop,
      setValue,
      options,
      options2,
      error,
      errorMsg,
      needClear = true, // defalut to true to add "指定しない" option
      disabled,
      readonly,
      // 初期表示値の取得関数
      // 利用箇所：
      // 毎回選択画面が表示されるとき、
      // 初期値は変更したい場合、関数を利用し、毎回表示の初期値を変更します。
      getDefaultValue,
      isDisabledOpenEvent,
      size = "full",
      ...rest
    }: AppSelectInputProp,
    ref?: any,
  ) => {
    const inputControl: React.MutableRefObject<any> = useRef();
    useImperativeHandle(ref, () => ({
      focus: () => focus(),
      scrollIntoView: () => scrollIntoView(),
    }));

    const [isOpen, setIsOpen] = useState(false);

    const focus = () => {
      if (isDisabledOpenEvent) {
        return;
      }
      setIsOpen(true);
    };

    const scrollIntoView = () => {
      inputControl.current.scrollIntoView({ behavior: "smooth" });
    };

    const getTextByValue = (value: string | number, separatedBy?: string) => {
      const getText = (value: string | number, options: SelectOption[]) => {
        if (
          (value === EMPTY_OPTION_STRING_VALUE || value === EMPTY_OPTION_NUMBER_VALUE || value) &&
          options &&
          options.length > 0
        ) {
          if (typeof options[0] === "string") {
            return value;
          } else {
            const result: any = (options as SelectOption[]).find((opt: any) => {
              return (opt as SelectOption).value === value;
            });
            return result?.text;
          }
        } else {
          return "";
        }
      };

      const allOptions = generateBlankEmptyOptions(options, needClear);

      if (separatedBy && options2) {
        if (value) {
          const allOptions2 = generateBlankEmptyOptions(options2, needClear);
          const values = `${value}`.split(separatedBy);
          const text1 = getText(values[0], allOptions);
          const text2 = getText(values[1], allOptions2);
          return text1 || text2 ? `${text1}${separatedBy}${text2}` : "";
        } else {
          return "";
        }
      } else {
        return getText(value, allOptions);
      }
    };

    useEffect(() => {
      // handle cancel modal: no action is required for native back
      const handleNativeBack = (ev: any) => {
        ev.detail.register(3, (processNextHandler: any) => {
          if (isOpen) {
            setIsOpen(false);
          } else {
            processNextHandler();
          }
        });
      };

      if (isOpen) {
        document.addEventListener("ionBackButton", handleNativeBack);
      } else {
        document.removeEventListener("ionBackButton", handleNativeBack);
      }

      return () => {
        document.removeEventListener("ionBackButton", handleNativeBack);
      };
    }, [isOpen]);

    return (
      <>
        {label && (
          <>
            <IonLabel className={styles.input_label}>{label}</IonLabel>
            <div className="page-spacer-15" />
          </>
        )}
        <div className={`${styles.inputWrapper} ${error ? styles.shake : ""}`}>
          <div
            className={`${styles.input_div} ${styles[size]}`}
            onClick={() => !disabled && !readonly && !isDisabledOpenEvent && setIsOpen(true)}
          >
            <IonInput
              ref={inputControl}
              placeholder={placeholder}
              inputmode="none"
              className={` ${error && styles.input_error} ${
                isOpen ? styles.input_focus : styles.input_no_focus
              } ${styles.input} ${className}`}
              value={getTextByValue(value, separatedBy)}
              {...rest}
              onIonFocus={() => {
                if (!disabled && !readonly && isPlatform("capacitor")) {
                  Keyboard.hide();
                }
              }}
              disabled={disabled}
              readonly={readonly}
            />
            {!disabled && !readonly && (
              <AppIcon
                key={isOpen ? 1 : 0}
                size="sm"
                icon={ICONS.ICON.ARROW_DOWN}
                className={`${styles.input_icon} ${/*isOpen ? styles.rotateIcon : */ ""}`}
              />
            )}
          </div>
          {error && (
            <>
              <IonLabel className="p-input-label-error">{errorMsg || error.message}</IonLabel>
            </>
          )}
        </div>

        <AppSelectOptions
          isOpen={!disabled && !readonly && isOpen}
          value1={value || defaultValue || (getDefaultValue && getDefaultValue())}
          separatedBy={separatedBy}
          isLoop={isLoop}
          options={generateBlankEmptyOptions(options, needClear)}
          options2={options2 ? generateBlankEmptyOptions(options2, needClear) : undefined}
          onClose={() => {
            setIsOpen(false);
          }}
          setValue={(value: any) => {
            setValue(value);
          }}
        />
      </>
    );
  },
);
