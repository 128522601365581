import { useState, useMemo } from "react";
import { useRouteMatch } from "react-router";
import { useIonViewWillEnter } from "@ionic/react";
import { USER_BASIC_INFO_TYPE } from "src/web/constants";
import { useUserBaseInfoModel } from "src/web/hooks/models/useUserBaseInfoModel";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { ErrorForm } from "src/web/types";
import { getDateStrByDate } from "src/web/utils/common";
import { useTextConstants } from "src/web/constants/hooks";

export function useModifyUserBaseInfoControl() {
  const ionRouter = useAppRouter();
  const { FORM_ERROR_TYPES } = useTextConstants();

  const match: any = useRouteMatch();
  const type = match.params.type;

  const { isLoading, fetchUserBaseInfo, editUserBaseInfo } = useUserBaseInfoModel();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [value, setValue] = useState<any>();
  const [valueBeforeChange, setValueBeforeChange] = useState<any>();
  const [errors, setErrors] = useState<ErrorForm>({});

  const isSubmitDisabled = useMemo(
    () => !value || value === valueBeforeChange,
    [value, valueBeforeChange],
  );

  const setFormData = (field: string, value: string) => {
    if (
      field === USER_BASIC_INFO_TYPE.BIRTHDAY &&
      value &&
      value !== getDateStrByDate(new Date(value))
    ) {
      errors[field] = { ...FORM_ERROR_TYPES.DATE };
    } else {
      delete errors[field];
    }
    setErrors({ ...errors });
    setValue(value);
  };

  const doFetch = async () => {
    const userInfoRes = await fetchUserBaseInfo();
    if (!userInfoRes) {
      return;
    }

    setValue(userInfoRes[type]);
    setValueBeforeChange(userInfoRes[type]);
  };

  const doSave = async () => {
    if (Object.keys(errors).length === 0) {
      setIsSubmitted(false);

      try {
        await editUserBaseInfo(type, value);
        ionRouter.goBack();
      } catch (error) {}
    } else {
      setIsSubmitted(true);
    }
  };

  const FIELDS_CONFIG = {
    [USER_BASIC_INFO_TYPE.NAME]: {
      value,
      onChange: (e: any) => setFormData(USER_BASIC_INFO_TYPE.NAME, e.detail.value),
      error: isSubmitted && !!errors ? errors[USER_BASIC_INFO_TYPE.NAME] : null,
    },
    [USER_BASIC_INFO_TYPE.BIRTHDAY]: {
      value,
      onChange: (val: string) => setFormData(USER_BASIC_INFO_TYPE.BIRTHDAY, val),
      error: isSubmitted && !!errors ? errors[USER_BASIC_INFO_TYPE.BIRTHDAY] : null,
    },
    [USER_BASIC_INFO_TYPE.GENDER]: {
      value,
      onChange: (val: string) => setFormData(USER_BASIC_INFO_TYPE.GENDER, val),
      error: isSubmitted && !!errors ? errors[USER_BASIC_INFO_TYPE.GENDER] : null,
    },
    [USER_BASIC_INFO_TYPE.PREFECTURE]: {
      value,
      onChange: (val: string) => setFormData(USER_BASIC_INFO_TYPE.PREFECTURE, val),
      error: isSubmitted && !!errors ? errors[USER_BASIC_INFO_TYPE.PREFECTURE] : null,
    },
  };

  useIonViewWillEnter(() => {
    doFetch();
  });

  return {
    type,
    FIELDS_CONFIG,
    isLoading,
    isSubmitDisabled,
    doSave,
  };
}
