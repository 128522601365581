import { useState } from "react";
import { useRouteMatch } from "react-router";
import { isPlatform, useIonViewDidEnter } from "@ionic/react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import HealthCareManager from "src/web/plugin/healthcare-manager-plugin";
import { Storage } from "@capacitor/storage";
import { DEVICE_CONNECTION_APP_TYPE, LocalStorageKey } from "src/web/constants";
import { getToday } from "src/web/utils/common";

export function useIndexSettingControl() {
  const ionRouter = useAppRouter();
  const match: any = useRouteMatch();

  const type = match.params.type;

  const deviceId = isPlatform("ios") ? "DEVICE_0009" : "DEVICE_0002";

  const [checked, setChecked] = useState(false);
  const [dataResult, setDataResult] = useState<any>("");

  const doConnectAndFetchData = async () => {
    try {
      const res: any = await HealthCareManager.requestPermission({ deviceId });
      if (res?.permissionErrors) {
        throw res?.permissionErrors;
      }
    } catch (error) {
      return "connect_failed";
    }

    const getData: any = {
      [DEVICE_CONNECTION_APP_TYPE.STEPS]: HealthCareManager.getSteps,
      [DEVICE_CONNECTION_APP_TYPE.SLEEP]: HealthCareManager.getTimeOfSleeping,
    };

    const payload = {
      [DEVICE_CONNECTION_APP_TYPE.STEPS]: {
        type: "day",
        deviceId,
        from: `${getToday()} 00:00`,
        to: `${getToday()} 23:59`,
      },
      [DEVICE_CONNECTION_APP_TYPE.SLEEP]: {
        deviceId,
        from: `${getToday()} 00:00`,
        to: `${getToday()} 23:59`,
      },
    };

    try {
      let res = await getData[type](payload[type]);
      setDataResult(res);
    } catch (error) {}

    return null;
  };

  const doFetchCheckStatusAndDeviceRecords = async () => {
    const isStepsChecked = await (
      await Storage.get({ key: LocalStorageKey.DEVICE_CONNECTED_FOR_STEPS })
    ).value;
    const isSleepChecked = await (
      await Storage.get({ key: LocalStorageKey.DEVICE_CONNECTED_FOR_SLEEP })
    ).value;
    const checkedFlg =
      (type === DEVICE_CONNECTION_APP_TYPE.STEPS && isStepsChecked === "true") ||
      (type === DEVICE_CONNECTION_APP_TYPE.SLEEP && isSleepChecked === "true");
    setChecked(checkedFlg);

    if (checkedFlg) {
      await doConnectAndFetchData();
    }
  };

  const handleCheck = async (nextStatus: boolean) => {
    if (nextStatus) {
      const result = await doConnectAndFetchData();
      if (result === "connect_failed") {
        return;
      }
    }

    setChecked(nextStatus);
    if (type === DEVICE_CONNECTION_APP_TYPE.STEPS) {
      await Storage.set({
        key: LocalStorageKey.DEVICE_CONNECTED_FOR_STEPS,
        value: `${nextStatus}`,
      });
    } else if (type === DEVICE_CONNECTION_APP_TYPE.SLEEP) {
      await Storage.set({
        key: LocalStorageKey.DEVICE_CONNECTED_FOR_SLEEP,
        value: `${nextStatus}`,
      });
    }
  };

  const toggleCheck = () => {
    handleCheck(!checked);
  };

  const handleNavToSupportList = () => {
    ionRouter.push("/settings/auto-data-fetching/supported-devices");
  };

  useIonViewDidEnter(() => {
    doFetchCheckStatusAndDeviceRecords();
  });

  return {
    isStep: type === DEVICE_CONNECTION_APP_TYPE.STEPS,
    checked,
    dataResult,
    toggleCheck,
    handleNavToSupportList,
  };
}
