import { actionConst } from "../constants";

interface Actions {
  type: actionConst;
  state: any;
}

export default function alarmScheduleReducer(state = "", actions: Actions) {
  switch (actions.type) {
    case actionConst.UPDATE_ALARM_SCHEDULE:
      return actions.state;
    default:
      return state;
  }
}
