export default class LocalStorageKey {
  static LOGIN_USER_INFO = "LOGIN_USER_INFO";
  static LOGIN_UID = "LOGIN_UID";
  static LOGIN_ACCESS_TOKEN = "LOGIN_ACCESS_TOKEN";

  static MEDICINE_INFO_MEMO = "MEDICINE_INFO_MEMO";
  static CREATE_USER_EMAIL_ADDRESS = "CREATE_USER_EMAIL_ADDRESS";

  static PRESCRIPTION_LOCAL_PHOTOS = "PRESCRIPTION_LOCAL_PHOTOS";

  static BLOOD_PRESSURE_DEVICE_DATA = "BLOOD_PRESSURE_DEVICE_DATA";

  static DEVICE_CONNECTED_FOR_STEPS = "DEVICE_CONNECTED_FOR_STEPS";
  static DEVICE_CONNECTED_FOR_SLEEP = "DEVICE_CONNECTED_FOR_SLEEP";

  static GLOBAL_MEDICINE_NOTIFICATION_ENABLED = "GLOBAL_MEDICINE_NOTIFICATION_ENABLED";
  static GLOBAL_MEDICINE_NOTIFICATION_SOUND_ENABLED = "GLOBAL_MEDICINE_NOTIFICATION_SOUND_ENABLED";
  static GLOBAL_SYMPTOM_NOTIFICATION_ENABLED = "GLOBAL_SYMPTOM_NOTIFICATION_ENABLED";
  static GLOBAL_SYMPTOM_NOTIFICATION_SOUND_ENABLED = "GLOBAL_SYMPTOM_NOTIFICATION_SOUND_ENABLED";

  static SCREEN_LOCKER_PASS = "SCREEN_LOCKER_PASS";

  static ALEXA_TOKEN = "ALEXA_TOKEN";

  static PATCH_VERSION = "PATCH_VERSION";
}
