import { getSymptomSetting, saveSymptomSetting } from "../services";
import { AlertType, SymptionSettingDataType, SymptomRecordType } from "../types";

export default class SymptomSettingModel {
  async fetchAllSymptomList() {
    try {
      const res: SymptomRecordType[] = await getSymptomSetting();

      return {
        homeList: res.filter((data) => data.isActiveOnHome),
        otherList: res.filter((data) => !data.isActiveOnHome),
      };
    } catch (error) {
      return {};
    }
  }

  async saveSymptomSetting(data: SymptionSettingDataType, symptomId?: number) {
    // combine and reset sortOrder
    const symptomSettings = [...(data?.homeList ?? []), ...(data.otherList ?? [])];
    symptomSettings.forEach((item, index) => (item.sortOrder = index + 1));

    const singleSetting = symptomId
      ? symptomSettings.filter((item) => item.symptomId === symptomId)
      : undefined;

    try {
      await saveSymptomSetting({
        symptomSettings: singleSetting || symptomSettings,
      });
      return null;
    } catch (error: any) {
      return {
        status: AlertType.ERROR,
        msg: error.message.message,
      };
    }
  }
}
