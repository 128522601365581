import { Fragment } from "react";
import { useIntl } from "react-intl-phraseapp";
import { CategoryData } from "../CategoryData/categoryData";
import { MappingItemType, MappingType } from "src/web/types";
import { LoadingAnswerAndStatusType } from "src/web/types";

interface ReportDataListProps {
  dailyAnswersAndStatus: LoadingAnswerAndStatusType;
  currentDate: string;
  isReadonly: boolean;
}

export function ReportDataList({
  dailyAnswersAndStatus,
  currentDate,
  isReadonly,
}: ReportDataListProps) {
  const intl = useIntl();

  const CATEGORY_MAPPING: MappingType = {
    a: {
      key: "bodyTemperature",
      label: intl.formatMessage({ id: "common.score.category.acitivity" }),
    },
    b: {
      key: "symptomQuestionnaire",
      label: intl.formatMessage({ id: "common.score.category.condition" }),
    },
  };

  return (
    <>
      {Object.entries(CATEGORY_MAPPING).map(([key, value]: [string, MappingItemType]) => {
        if (dailyAnswersAndStatus?.data?.questionnaireAndScore) {
          return (
            <Fragment key={key}>
              <div className="page-spacer-60" />

              <CategoryData
                category={value}
                dataKey={key}
                data={dailyAnswersAndStatus?.data?.questionnaireAndScore}
                currentDate={currentDate}
                isReadonly={isReadonly}
              />
            </Fragment>
          );
        } else {
          return "";
        }
      })}
    </>
  );
}
