import { actionConst } from "../constants";
import { GraphInfoType } from "../types";

export const clearGraphInfo = () => {
  return {
    type: actionConst.CLEAR_GRAPH_INFO,
  };
};

export const updateGraphInfo = (graphInfo: GraphInfoType) => {
  return {
    type: actionConst.UPDATE_GRAPH_INFO,
    state: graphInfo,
  };
};
