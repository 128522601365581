import { IonHeader, isPlatform } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import styles from "./appLogo.module.css";

export function AppLogo({
  children,
  isDisplayBorder = false,
}: {
  children?: any;
  isDisplayBorder?: boolean;
}) {
  const isShowHeaderBorder = useSelector((state: RootState) => state.isShowHeaderBoarder);

  return (
    <IonHeader className="ion-no-border">
      <div
        className={`${isPlatform("ios") ? styles.iosheader : ""} ${
          isDisplayBorder || isShowHeaderBorder ? styles.topBottomLine : ""
        }`}
      >
        <div
          className={`${styles.logo} ${
            isDisplayBorder || isShowHeaderBorder ? styles.topBottomSize : ""
          }`}
        >
          <div>APP LOGO</div>
        </div>
        {children}
      </div>
    </IonHeader>
  );
}
