import { actionConst } from "../constants";
import { AlertInfoType } from "../types";

export const clearAlertInfo = () => {
  return {
    type: actionConst.CLEAR_ALERT_INFO,
  };
};

export const updateAlertInfo = (alertInfo: AlertInfoType) => {
  return {
    type: actionConst.UPDATE_ALERT_INFO,
    state: alertInfo,
  };
};
