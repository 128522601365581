import { useRef, forwardRef, useImperativeHandle } from "react";
import { IonLabel } from "@ionic/react";
import { AppIcon, AppToggleButton } from "../../../../../components/UiComponents";
import { ICONS } from "../../../../../constants";
import { SymptomRecordType } from "../../../../../types";
import styles from "./draggableSettingItem.module.css";

interface DraggableSettingItemProps {
  data: SymptomRecordType;
  handleToggleChange: (data: SymptomRecordType) => void;
  isHomeList?: boolean;
  isDisabled?: boolean;
  draggableProps: any;
  dragHandleProps: any;
}

export const DraggableSettingItem = forwardRef(
  (
    {
      data,
      handleToggleChange,
      isHomeList,
      isDisabled,
      draggableProps,
      dragHandleProps,
    }: DraggableSettingItemProps,
    ref: any,
  ) => {
    const myRef: React.MutableRefObject<any> = useRef();
    useImperativeHandle(ref, () => myRef.current);

    return (
      <div ref={myRef} className={styles.root} {...draggableProps}>
        <div className="flex-row-between">
          <div className={styles.label}>
            <IonLabel className="p-body">{data.content}</IonLabel>
          </div>
          <div className="div_center">
            {isHomeList && <AppIcon icon={ICONS.ICON.HOME} size="sm" />}
            <div className={styles.toggle}>
              <AppToggleButton
                checked={data.isActive}
                onClick={() => handleToggleChange(data)}
                disabled={isHomeList}
              />
            </div>
            <div {...dragHandleProps} className={styles.handle}>
              <AppIcon icon={ICONS.ICON.DRAG_HANDLE} size="sm" disabled={isDisabled} />
            </div>
          </div>
        </div>
      </div>
    );
  },
);
