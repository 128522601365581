import { useState, useEffect, useRef } from "react";
import {
  IonPage,
  IonContent,
  IonLabel,
  useIonViewWillEnter,
  ScrollDetail,
  useIonAlert,
  useIonViewDidEnter,
} from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import {
  AppHeader,
  AppSecondaryButton,
  AppInput,
  AppLoading,
  AppFloatButton,
  AppCalendarNoStatusCommonSheet,
} from "../../components/UiComponents";
import { PRESCRIPTION_LIST } from "../../constants";
import { clearMedicineInfo, updatePrescriptionInfo, updateRefreshInfo } from "../../actions";
import { RootState } from "../../reducer";
import { useQueryString } from "src/web/hooks/useQueryString";
import { useAppRouter } from "../../hooks/useAppRouter";
import { PrescriptionInfoType } from "../PrescriptionInformation/type";
import { RefreshType } from "../../types";
import styles from "./prescriptionHospital.module.css";
import { MEDICINE_TYPE } from "../../constants";
import { useIntl } from "react-intl-phraseapp";
import { updatePrescriptionHospitalById } from "src/web/services";
import { useCommon } from "src/web/utils/hooks/common";

export function PrescriptionHospital() {
  const intl = useIntl();
  const { confirmNotSave } = useCommon();
  const [presentAlert] = useIonAlert();

  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  useIonViewWillEnter(() => {
    try {
      scrollToTop();
    } catch {}
  });

  // ロード情報
  const [loading, setLoading] = useState(false);

  const params = parseQs(ionRouter.routeInfo.search);
  // 遷移の位置
  const type = params.type;
  // フラグ
  const updFlg = params.updFlg;

  const dispatch = useDispatch();
  // 全画面の情報を取得する
  const currentData: PrescriptionInfoType = useSelector((state: RootState) => {
    return state.prescriptionInformation;
  });

  const refreshInfo: RefreshType = useSelector((state: RootState) => {
    return state.refreshInfo;
  });

  // カレンダー表示
  const [openCarlendar, setOpenCarlendar] = useState({
    isOpen: false,
    selectedDate: new Date(),
    field: "dispensing_date",
  });

  // ごの画面の情報を設定する
  const [formData, setFormData] = useState({
    dispensing_date: currentData.dispensing_date,
    pharmacy: currentData.pharmacy,
    pharmacist: currentData.pharmacist,
    hospital: currentData.hospital,
  });

  // 初期時の情報
  const [initialValue, setInitialValue] = useState("");
  useIonViewDidEnter(() => {
    // 初期の画面情報を記録する
    setInitialValue(JSON.stringify(formData));
  });

  const setFormDataValue = (field: string, value: any) => {
    const newData = {
      ...formData,
      [field]: value,
    };
    setFormData(newData);
  };

  // カレンダー情報チェックフラグ
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {}, []);

  // スクロール関連
  const [isDisplayBorder, setIsDisplayBorder] = useState(false);

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    // setIsDisplayBorder(true);
    if (ev.detail.scrollTop === 0) {
      setIsDisplayBorder(false);
    } else {
      setIsDisplayBorder(true);
    }
  };

  return (
    <>
      <IonPage>
        <AppHeader isDisplayBorder={isDisplayBorder}>
          <AppSecondaryButton
            isDynamicWidth
            transparent={true}
            onClick={() => {
              // 新規だけの場合、トップに移動します。
              if (type === "new") {
                // true(いつも確認メッセージを表示する)の理由は新規の場合、前の薬情報が存在するので、変更のことは判断する必要がないです。
                // urlはtop画面のurlです。
                confirmNotSave(true, presentAlert, ionRouter, "home/medication-record?refresh=1");
                return;
              }

              // 編集からの場合
              // 変更を判断し、変更があれば、メッセージ表示します。
              // 戻るの先は前画面です。
              confirmNotSave(initialValue !== JSON.stringify(formData), presentAlert, ionRouter);
            }}
          >
            {intl.formatMessage({ id: "page.prescription_fee.button.cancel" })}
          </AppSecondaryButton>
        </AppHeader>
        <IonContent ref={contentRef} scrollEvents={true} onIonScroll={handleScroll}>
          <div className="root-content">
            {/* データタイトル */}
            <div className="page-spacer-45" />
            {/* 画面のタイトルを変更する */}
            <IonLabel className={styles.pageTitle}>
              {currentData.type === MEDICINE_TYPE.PRESCRIPTION
                ? intl.formatMessage({ id: "page.prescription_hospital.title.prescription" })
                : intl.formatMessage({ id: "page.prescription_hospital.title.market" })}
            </IonLabel>
            <div className="page-spacer-45" />
            <IonLabel className={styles.displayTitle}>
              {currentData.type === MEDICINE_TYPE.PRESCRIPTION
                ? intl.formatMessage({ id: "page.prescription_hospital.date.prescription" })
                : intl.formatMessage({ id: "page.prescription_hospital.date.market" })}{" "}
              {intl.formatMessage({ id: "page.prescription_hospital.necessary" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <div style={{ width: "162px" }}>
              <AppInput
                errorMsg={errorMsg}
                error={isError}
                value={formData.dispensing_date}
                onIonChange={(event: any) => {
                  setFormDataValue("dispensing_date", event.detail.value);
                  setIsError(false);
                }}
                handleCalendar={() => {
                  setOpenCarlendar({
                    ...openCarlendar,
                    isOpen: true,
                    selectedDate: formData.dispensing_date
                      ? new Date(formData.dispensing_date)
                      : new Date(),
                    field: "dispensing_date",
                  });
                }}
              />
            </div>
            <div className="page-spacer-45" />
            <IonLabel className={styles.displayTitle}>
              {currentData.type === MEDICINE_TYPE.PRESCRIPTION
                ? intl.formatMessage({ id: "page.prescription_hospital.place.prescription" })
                : intl.formatMessage({ id: "page.prescription_hospital.place.market" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <AppInput
              maxlength="255"
              value={formData.pharmacy}
              onIonChange={(event: any) => {
                setFormDataValue("pharmacy", event.detail.value);
              }}
            />
            <div className="page-spacer-45" />
            <IonLabel className={styles.displayTitle}>
              {currentData.type === MEDICINE_TYPE.PRESCRIPTION
                ? intl.formatMessage({ id: "page.prescription_hospital.pharmacist.prescription" })
                : intl.formatMessage({ id: "page.prescription_hospital.pharmacist.market" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <AppInput
              maxlength="45"
              value={formData.pharmacist}
              onIonChange={(event: any) => {
                setFormDataValue("pharmacist", event.detail.value);
              }}
            />
            <div className="page-spacer-45" />
            <IonLabel className={styles.displayTitle}>
              {currentData.type === MEDICINE_TYPE.PRESCRIPTION
                ? intl.formatMessage({
                    id: "page.prescription_hospital.hospital_name.prescription",
                  })
                : intl.formatMessage({ id: "page.prescription_hospital.hospital_name.market" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <AppInput
              value={formData.hospital}
              onIonChange={(event: any) => {
                setFormDataValue("hospital", event.detail.value);
              }}
            />

            <div className="page-spacer-30" />
          </div>

          <AppFloatButton
            label={
              updFlg
                ? intl.formatMessage({ id: "page.prescription_hospital.button.complete" })
                : intl.formatMessage({ id: "page.prescription_hospital.button.confirm" })
            }
            onClick={async () => {
              // エラーの時処理なし
              if (isError) {
                return;
              }

              // カレンダーの情報チェック
              if (!formData.dispensing_date) {
                setErrorMsg(
                  intl.formatMessage({
                    id: "page.prescription_hospital.validation.no_input.error",
                  }),
                );
                setIsError(true);
                return;
              }

              if (
                /^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/.test(
                  formData.dispensing_date,
                ) === false ||
                isNaN(Date.parse(formData.dispensing_date))
              ) {
                setErrorMsg(
                  intl.formatMessage({ id: "page.prescription_hospital.validation.invalid.error" }),
                );
                setIsError(true);
                return;
              }

              const newData = {
                ...currentData,
                pharmacy: formData.pharmacy,
                pharmacist: formData.pharmacist,
                hospital: formData.hospital,
              };

              if (formData.dispensing_date) {
                newData.dispensing_date = formData.dispensing_date;
              } else {
                newData.dispensing_date = undefined;
              }

              if (type === "new") {
                // 新規情報
                // clear medicine info
                dispatch(clearMedicineInfo());
                dispatch(updatePrescriptionInfo(newData));
                ionRouter.push(`/prescription-information`, "forward", "push");
              } else {
                // idは存在する場合
                if (currentData.id) {
                  const updateDate = {
                    id: newData.id,
                    dispensing_date: newData.dispensing_date,
                    pharmacy: newData.pharmacy,
                    pharmacist: newData.pharmacist,
                    hospital: newData.hospital,
                  };

                  setLoading(true);
                  try {
                    // 新規
                    // let jsonData = await postData(
                    //   "prescription",
                    //   "/api/medicine/updatePrescriptionHospital",
                    //   updateDate,
                    // );
                    await updatePrescriptionHospitalById(currentData.id, updateDate);
                    // リスト最新する
                    let new_refreshInfo = {
                      ...refreshInfo,
                    };
                    new_refreshInfo[PRESCRIPTION_LIST] = true;

                    dispatch(updateRefreshInfo(new_refreshInfo));

                    dispatch(updatePrescriptionInfo(newData));
                  } finally {
                    setLoading(false);
                  }
                } else {
                  dispatch(updatePrescriptionInfo(newData));
                }
                ionRouter.goBack();
              }
            }}
          />
        </IonContent>
      </IonPage>

      <AppLoading isOpen={loading} />

      <AppCalendarNoStatusCommonSheet
        isOpen={openCarlendar.isOpen}
        closeCarlendar={() =>
          setOpenCarlendar({
            ...openCarlendar,
            isOpen: false,
            field: "",
          })
        }
        handleConfirmDate={(dateStr: string) => {
          if (openCarlendar.field) {
            setFormDataValue(openCarlendar.field, dateStr);
            setIsError(false);
          }
        }}
        selectedDate={openCarlendar.selectedDate}
      />
    </>
  );
}
