import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { auth } from "src/web/firebase";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  signOut,
} from "firebase/auth";
import { updateAlertInfo } from "src/web/actions";
import { AlertType } from "src/web/types";
import { useCommon } from "src/web/utils/hooks/common";

export function useChangePasswordControl() {
  const iRouter = useAppRouter();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [formData, setFormData] = useState({
    oldPass: "",
    firstPass: "",
    secondPass: "",
  });
  const { doPasswordValidation } = useCommon();

  const isSubmitDisabled = useMemo(() => {
    return (
      !formData.oldPass ||
      !formData.firstPass ||
      !formData.secondPass ||
      formData.firstPass !== formData.secondPass
    );
  }, [formData]);

  const handleInputChange = (field: string, value: string) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const doSubmit = async () => {
    let tmpIsValid = doPasswordValidation(formData.firstPass, formData.secondPass);
    setIsError(!tmpIsValid.isValid);
    setErrorMsg(tmpIsValid.errorMsg);

    if (tmpIsValid.isValid && auth?.currentUser?.email) {
      const credential = EmailAuthProvider.credential(auth.currentUser.email, formData.oldPass);

      try {
        setIsLoading(true);
        // verify old password
        await reauthenticateWithCredential(auth.currentUser, credential);
      } catch (error: any) {
        setIsError(true);
        setIsLoading(false);
        if (error?.code === "auth/wrong-password") {
          setErrorMsg("パスワードが間違っています");
        } else {
          dispatch(
            updateAlertInfo({
              status: AlertType.ERROR,
              msg: error.message.message,
            }),
          );
        }
        return;
      }

      // update password
      try {
        await updatePassword(auth.currentUser, formData.firstPass);
        // auto logout
        await signOut(auth);

        setIsLoading(false);
        iRouter.push("/settings/accounts/change-password/complete");
      } catch (error: any) {
        setIsError(true);
        setIsLoading(false);
        if (error?.code) {
          dispatch(
            updateAlertInfo({
              status: AlertType.ERROR,
              msg: error.message.message,
            }),
          );
        }
      }
    }
  };

  return {
    isLoading,
    isError,
    errorMsg,
    formData,
    isSubmitDisabled,
    handleInputChange,
    doSubmit,
  };
}
