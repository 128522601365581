import { useEffect, useState } from "react";
import { useAdviceModel } from "src/web/hooks/models";
import {
  getDateStrByDate,
  getDateStrByDateNoPad,
  getStartEndDatesOfWeek,
} from "src/web/utils/common";

export function useChangeWeekControl(
  weekStartDateStr: string,
  setCurrentWeekStartDate: React.Dispatch<React.SetStateAction<string>>,
) {
  const { fetchWeeklyIngestionAdvice } = useAdviceModel();

  const [weeklyIngestionAdvice, setWeeklyIngestionAdvice] = useState<string>("");

  const changeWeek = (days: number) => {
    const weekStartDate = new Date(weekStartDateStr);
    let newWeekStartDate = new Date(
      weekStartDate.getFullYear(),
      weekStartDate.getMonth(),
      weekStartDate.getDate() + days,
    );
    setCurrentWeekStartDate(getDateStrByDate(newWeekStartDate));
  };

  const gotoPreviousWeek = () => {
    changeWeek(-7);
  };

  const gotoNextWeek = () => {
    changeWeek(7);
  };

  const getFormattedWeekPeriod = () => {
    const weekStartDate = new Date(weekStartDateStr);
    const [_, weekEndDate] = getStartEndDatesOfWeek(weekStartDate);
    return `${getDateStrByDateNoPad(weekStartDate)}～${weekEndDate.getDate()}`;
  };

  const getWeeklyAdvice = async () => {
    const advice = await fetchWeeklyIngestionAdvice(weekStartDateStr);
    setWeeklyIngestionAdvice(advice);
  };

  useEffect(() => {
    getWeeklyAdvice();
  }, [weekStartDateStr]);

  return {
    weeklyIngestionAdvice,
    formattedWeekPeriod: getFormattedWeekPeriod(),
    gotoPreviousWeek,
    gotoNextWeek,
  };
}
