export const ICONS: any = {
  ICON: {
    ALARM: "icon_alarm",
    ARROW_CIRCLE_BACK: "icon_arrow_back_circle",
    ARROW_CIRCLE_FORWARD: "icon_arrow_forward_circle",
    ARROW_BACK: "icon_arrow_back",
    ARROW_DOWN: "icon_arrow_down",
    ARROW_FORWARD: "icon_arrow_forward",
    ARROW_UP: "icon_arrow_up",
    BACK_TO_TOP: "icon_back_to_top",
    CALENDAR: "icon_calendar",
    CAMERA: "icon_camera",
    CHECK_CIRCLE_ACTIVE: "icon_check_circle_active",
    CHECK_CIRCLE_IDLE: "icon_check_circle_idle",
    CHECK: "icon_check",
    CIRCLE: "icon_circle",
    CLOSE_CIRCLE: "icon_close_circle",
    CLOSE: "icon_close",
    COMMENT: "icon_comment",
    CONNECT: "icon_connect",
    CREDIT_CARD: "icon_credit_card",
    DELETE: "icon_delete",
    DRAG_HANDLE: "icon_drag_handle",
    EQUAL: "icon_equal",
    FACE_0: "icon_face_scale1",
    FACE_1: "icon_face_scale2",
    FACE_2: "icon_face_scale3",
    FACE_3: "icon_face_scale4",
    FACE_4: "icon_face_scale5",
    FACE_5: "icon_face_scale6",
    FAVORITE_FILLED: "icon_favorite_filled",
    FAVORITE: "icon_favorite",
    HOME: "icon_home",
    LINK_OUT: "icon_link_out",
    LOCATION: "icon_location",
    MAIL: "icon_mail",
    MAP: "icon_map",
    MEDICINE: "icon_medicine",
    MINUS_CIRCLE: "icon_minus_circle",
    MINUS: "icon_minus",
    MORE_HORIZONTAL: "icon_more_horizontal",
    MORE_VERTICAL: "icon_more_vertical",
    MY_LOCATION: "icon_my_location",
    ORIENTATION_HORIZONTAL: "icon_orientation_horizontal",
    ORIENTATION_VERTICAL: "icon_orientation_vertical",
    PLAY_CIRCLE: "icon_play_circle",
    PLUS_CIRCLE: "icon_plus_circle",
    PLUS: "icon_plus",
    PRINT: "icon_print",
    SEARCH: "icon_search",
    SHARE: "icon_share",
    STAR_EMPTY: "icon_star_empty",
    STAR_FILLED: "icon_star_filled",
    STAR_HALF: "icon_star_half",
    TOOTIP_FILLED: "icon_tooltip_filled",
    TOOTIP: "icon_tooltip",
    TRAIN: "icon_train",
    TRIANGLE: "icon_triangle",
    VISIBILITY_OFF: "icon_visibility_off",
    VISIBILITY_ON: "icon_visibility_on",
    WRITE: "icon_write",
  },
  FLOATING: {
    CHAT_CIRCLE: "floating_icon_chat_circle",
    MEDICINE_CIRCLE: "floating_icon_add_medicine_circle",
    MENU_CIRCLE: "floating_icon_menu_circle",
  },
  IMAGE: {
    THERMOMETER: "image_thermometer",
    MEDICINE: "image_medicine",
    BLOOD_PRESSURE: "image_blood_pressure",
    ELECTRO_CARDIOGRAM: "image_electro_cardiogram",
    MIND_CONDITION: "image_mind_condition",
    BODY_CONDITION: "image_body_condition",
    MIND_AND_BODY_CONDITION: "image_mind_and_body_condition",
    ACTIVITIES: "image_activities",
    BODY_WEIGHT: "image_body_weight",
    EXERCISE: "image_exercise",
    MEAL: "image_meal",
    BLOOD_GLUCOSE_LEVEL: "image_blood_glucose_level",
    GRIP_STRENGTH: "image_grip_strength",
    DATA_INPUT: "image_data_input",
    PHARMACY_DRUG: "image_pharmacy_drug",
    PRESCRIPTION: "image_prescription",
    QRCODE: "image_qrcode",
    SPECIAL_RECORD_COMPLETED: "image_special_record_completed",
    SPECIAL_COMPLETE: "image_special_complete",
    SPECIAL_ERROR: "image_special_error",
  },
  TRACKING: {
    BLOOD_PRESSURE: "tracking_icon_blood_pressure",
    BODY_CONDITION: "tracking_icon_body_condition",
    EXERCISE: "tracking_icon_exercise",
    MEDICINE: "tracking_icon_medicine",
    THERMOMETER: "tracking_icon_thermometer",
  }
};
