import { Fragment, useState, useEffect } from "react";
import {
  AppFixedActionSheet,
  AppIcon,
  AppCheckbox,
  AppDoubleSelectInput,
} from "../../../../components/UiComponents";
import { IonLabel } from "@ionic/react";
import {
  ICONS,
  EMPTY_OPTION_STRING_VALUE,
  NUMBER_OPTIONS,
} from "../../../../constants";
import { generateBlankEmptyOptions } from "src/web/utils/common";
import { useCommon } from "src/web/utils/hooks/common";
import { INIT_FREQUENCY } from "../../medicineInformation";
import { SelectOption } from "../../../../types";
import { FrequencyType } from "src/web/types/medicineTypes";

import styles from "./frequencyActionSheet.module.css";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

interface FrequencyActionSheetProps {
  isOpen: boolean;
  initValue?: FrequencyType;
  handleClose: () => void;
  handleConfirm: (value: any) => void;
  needClear?: boolean;
}

export function FrequencyActionSheet({
  isOpen,
  initValue,
  handleClose,
  handleConfirm,
  needClear = true,
}: FrequencyActionSheetProps) {
  const intl = useIntl();
  const { generateLabeledEmptyOptions } = useCommon();
  const { MEDICINE_REPEATING_PERIOD_OPTIONS, MEDICINE_FREQUENCY_OPTIONS, DAY_CONVERT } = useTextConstants();
  const frquencyOptions = needClear
    ? generateLabeledEmptyOptions(MEDICINE_FREQUENCY_OPTIONS, needClear)
    : MEDICINE_FREQUENCY_OPTIONS;

  const [show2ndStep, setShow2ndStep] = useState(false);

  const [current, setCurrent] = useState<FrequencyType>(JSON.parse(JSON.stringify(INIT_FREQUENCY)));

  const daysOptions = Object.entries(DAY_CONVERT).map(([key, value]: [string, string]) => {
    return {
      value: key,
      text: `${value}${intl.formatMessage({
        id: "page.medicine_information.frequency_action_sheet.weekday",
      })}`,
    };
  });

  const handleCheckOnDay = (val: string) => {
    const index = current.week_day.findIndex((day) => day === val);
    if (index > -1) {
      current.week_day.splice(index, 1);
    } else {
      current.week_day.push(val);
    }
    setCurrent({
      ...current,
      week_day: [...current.week_day],
    });
  };

  const handleConfirmOnStep1 = () => {
    switch (current.type) {
      case EMPTY_OPTION_STRING_VALUE: // 指定しない
      case "01": // 毎日
      case "02": // 1日おき
      case "03": // 2日おき
        handleConfirmOnStep2();
        break;
      case "04": // 曜日
      case "05": // カスタマイズ
        setShow2ndStep(true);
        break;
      default:
        break;
    }
  };

  // confirm and close
  const handleConfirmOnStep2 = () => {
    // 曜日の場合
    // 実際の曜日がない場合、空値を設定する
    if (current.type === "04") {
      if (current.week_day.length === 0) {
        handleConfirm(null);
        handleClose();
        return;
      }
    } else if (current.type === "05") {
      // 選択しない場合、
      // 時間選択しない
      if (current.every_gap === 0) {
        handleConfirm(null);
        handleClose();
        return;
        // 週ごと
      }
      if (current.unit === "02" && current.week_day.length === 0) {
        handleConfirm(null);
        handleClose();
        return;
      }
      // 週単位をクリアする
      if (current.unit !== "02" && current.week_day.length > 0) {
        current.week_day = [];
      }
    }

    handleConfirm(current);
    handleClose();
  };

  useEffect(() => {
    if (!isOpen) {
      setShow2ndStep(false);
      setCurrent(JSON.parse(JSON.stringify(INIT_FREQUENCY)));
    } else {
      if (initValue) {
        setCurrent(JSON.parse(JSON.stringify(initValue)));
      }
    }
  }, [isOpen]);

  return (
    <AppFixedActionSheet isOpen={isOpen} handleClose={handleClose}>
      <div className={`${styles.root}`}>
        <div
          className={`${styles.sliderWrapper} ${styles.slider1} ${
            show2ndStep ? styles.moveLeft : ""
          }`}
          style={{ background: "white" }}
        >
          <div className={styles.header}>
            <IonLabel className={styles.cancel} onClick={handleClose}>
              {intl.formatMessage({
                id: "page.medicine_information.frequency_action_sheet.header.cancel",
              })}
            </IonLabel>
            <IonLabel className={styles.title}>
              {intl.formatMessage({
                id: "page.medicine_information.frequency_action_sheet.header.frequency",
              })}
            </IonLabel>
            <IonLabel className={styles.confrim} onClick={handleConfirmOnStep1}>
              {intl.formatMessage({
                id: "page.medicine_information.frequency_action_sheet.header.confirm",
              })}
            </IonLabel>
          </div>

          <div className="page-spacer-45" />

          <div className={`${styles.scrollable}`}>
            {frquencyOptions.map((frq: SelectOption, index: number) => (
              <Fragment key={frq.value}>
                <div
                  className={styles.optionLine}
                  onClick={() => {
                    if (frq.value !== current.type) {
                      setCurrent({
                        type: `${frq.value}`,
                        week_day: [],
                        every_gap: 0,
                        unit: "",
                      });
                    }

                    // nav to step 2
                    switch (frq.value) {
                      case "04": // 曜日で選択...
                      case "05": // カスタム...
                        setShow2ndStep(true);
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <IonLabel className={styles.item}>{frq.text}</IonLabel>
                  {frq.value === current.type && <AppIcon icon={ICONS.ICON.CHECK} size="sm" />}
                </div>
                {index !== frquencyOptions.length - 1 && <div className="page-spacer-30" />}
              </Fragment>
            ))}
          </div>
        </div>

        <div
          className={`${styles.sliderWrapper} ${styles.slider2} ${
            show2ndStep ? styles.moveLeft : ""
          }`}
        >
          <div className={styles.header}>
            <AppIcon
              icon={ICONS.ICON.ARROW_CIRCLE_BACK}
              className={styles.backIcon}
              onClick={() => {
                // nav to step 1
                setShow2ndStep(false);
              }}
            />
            <IonLabel className={styles.title}>
              {current.type === "04"
                ? intl.formatMessage({
                    id: "page.medicine_information.frequency_action_sheet.select_by_weekday",
                  })
                : intl.formatMessage({
                    id: "page.medicine_information.frequency_action_sheet.custom",
                  })}
            </IonLabel>
            <IonLabel className={styles.confrim} onClick={handleConfirmOnStep2}>
              {intl.formatMessage({
                id: "page.medicine_information.frequency_action_sheet.confirm",
              })}
            </IonLabel>
          </div>

          <div className="page-spacer-45" />

          <div className={`${styles.scrollable}`}>
            {current.type === "04" ? (
              daysOptions.map((dayOp, index) => (
                <Fragment key={index}>
                  <AppCheckbox
                    label={dayOp.text}
                    checked={
                      !!(current.week_day && current.week_day.find((day) => day === dayOp.value))
                    }
                    onClick={() => handleCheckOnDay(dayOp.value)}
                    size="small"
                  />
                  {index !== daysOptions.length - 1 && <div className="page-spacer-5" />}
                </Fragment>
              ))
            ) : (
              <>
                <div className="flex-row-start">
                  <IonLabel className={styles.label}>
                    {intl.formatMessage({
                      id: "page.medicine_information.frequency_action_sheet.repeat_duration",
                    })}
                  </IonLabel>
                </div>

                <div className="page-spacer-15" />

                <AppDoubleSelectInput
                  value1={current.every_gap}
                  value2={current.unit}
                  options={generateBlankEmptyOptions(NUMBER_OPTIONS, true)}
                  options2={MEDICINE_REPEATING_PERIOD_OPTIONS}
                  setValue={(val: any[]) => {
                    setCurrent({
                      ...current,
                      every_gap: val[0],
                      unit: val[0] ? val[1] : EMPTY_OPTION_STRING_VALUE,
                    });
                  }}
                  showBoarder={true}
                  size="md"
                />

                {/* if 単位 is 週間ごと, show 曜日 options */}
                {current.unit === "02" && (
                  <>
                    <div className="page-spacer-45" />

                    <div className="flex-row-start">
                      <IonLabel className={styles.label}>
                        {intl.formatMessage({
                          id: "page.medicine_information.frequency_action_sheet.weekday",
                        })}
                      </IonLabel>
                    </div>

                    <div className="page-spacer-15" />

                    {daysOptions.map((dayOp, index) => (
                      <Fragment key={index}>
                        <AppCheckbox
                          label={dayOp.text}
                          checked={
                            !!(
                              current.week_day &&
                              current.week_day.find((day) => day === dayOp.value)
                            )
                          }
                          onClick={() => handleCheckOnDay(dayOp.value)}
                          size="small"
                        />
                        {index !== daysOptions.length - 1 && <div className="page-spacer-5" />}
                      </Fragment>
                    ))}

                    <div className="page-spacer-45" />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </AppFixedActionSheet>
  );
}
