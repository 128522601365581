import {
  IonPage,
  IonFooter,
} from "@ionic/react";
import omronSphygmomanometerImg from "../../assets/images/omron_sphygmomanometer.png";
import omronConnectingImg from "../../assets/images/omron_connecting.png";
import { AppContent, AppHeader, AppHomeIndicatorBar, AppModalTemplate, AppPrimaryButton, AppTextLink } from "src/web/components/UiComponents";
import { useIntl } from "react-intl-phraseapp";
import { ConnectingStatus } from "src/web/components/ConnectingStatus/connectingStatus";
import { ModalTitleWithDesc } from "src/web/components/UiComponents/organisms";
import { useFetchBloodPressureControl } from "./hooks/useFetchBloodPressureControl";
import styles from "./fetchBloodPressure.module.css";

export function FetchBloodPressure() {
  const intl = useIntl();

  const {
    device,
    showFetchingAlert,
    handleDoFetch,
    handleCancel,
    handleCloseAlert,
  } = useFetchBloodPressureControl()

  return (
    <>
      <IonPage>
        <AppHeader backAction={handleCancel}>
          <ConnectingStatus device={device} />
        </AppHeader>

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <ModalTitleWithDesc
              title={intl.formatMessage({ id: "page.fetch_blood_pressure.message.line1" })}
              desc={intl.formatMessage({ id: "page.fetch_blood_pressure.message.line2" })}
            />

            <div className="page-spacer-50" />

            <div className={styles.deviceImgWrapper}>
              <img src={omronSphygmomanometerImg} className={styles.deviceImg} alt="" />
            </div>
          </div>
        </AppContent>

        <IonFooter className="ion-no-border">
          <div className={`root-content ${styles.submitWrapper}`}>
            <AppPrimaryButton
              full
              onClick={handleDoFetch}
            >
              {intl.formatMessage({ id: "page.fetch_blood_pressure.button.search" })}
            </AppPrimaryButton>

            <div className="page-spacer-15" />

            <AppTextLink hasNoArrow onClick={handleCancel}>
              {intl.formatMessage({ id: "page.fetch_blood_pressure.button.cancel" })}
            </AppTextLink>

            <AppHomeIndicatorBar />
          </div>
        </IonFooter>
      </IonPage>

      <AppModalTemplate
        isOpen={showFetchingAlert}
        handleClose={handleCloseAlert}
      >
        <div className="modal-content">
          <ModalTitleWithDesc
            title={intl.formatMessage({
              id: "page.fetch_blood_pressure.modal.fetching.message.line1",
            })}
            desc={intl.formatMessage({
              id: "page.fetch_blood_pressure.modal.fetching.message.line2",
            })}
          />

          <div className="page-spacer-35" />

          <div className={styles.deviceImgWrapper}>
            <img src={omronConnectingImg} className={styles.deviceConnectingImg} alt="" />
          </div>

          <div className="page-spacer-45" />
        </div>
      </AppModalTemplate>
    </>
  );
}
