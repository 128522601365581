import { IonContent, IonPage } from "@ionic/react";
import { AppLogo, AppPrimaryButton } from "../../components/UiComponents";
import { WelcomeView } from "src/web/components/UiComponents/organisms";
import { useWelcomeControl } from "./hooks/useWelcomeControl";
import welcomeImg from "src/web/assets/images/welcome.svg";
import { useIntl } from "react-intl-phraseapp";

export default function Welcome() {
  const { goNext } = useWelcomeControl();
  const intl = useIntl();

  return (
    <IonPage>
      <AppLogo />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <WelcomeView
            title={intl.formatMessage({ id: "page.welcome.title" }, { app: "xxx" })}
            subTitle={`${intl.formatMessage({
              id: "page.welcome.description.line1",
            })}\n${intl.formatMessage({ id: "page.welcome.description.line2" })}`}
            imgSrc={welcomeImg}
          />

          <div className="page-spacer-60" />

          <AppPrimaryButton full onClick={goNext}>
            {intl.formatMessage({ id: "page.welcome.button.next" })}
          </AppPrimaryButton>
        </div>
      </IonContent>
    </IonPage>
  );
}
