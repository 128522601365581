import { IonImg, IonLabel } from "@ionic/react";
import { AppModalTemplate, AppPrimaryButton, AppTextLink, AppIcon } from "..";

import styles from "./appModal.module.css";
import { useIntl } from "react-intl-phraseapp";

interface AppModalProps {
  isOpen: boolean;
  className?: string;
  title?: string;
  desc?: string;
  img?: string;
  icon?: string;
  confirmBtnText?: string;
  textLink?: string;
  handleConfirm?: () => void;
  handleTextLink?: () => void;
  handleClose?: () => void;
}

export function AppModal({
  isOpen,
  className,
  img,
  icon,
  title,
  desc,
  confirmBtnText,
  textLink,
  handleConfirm,
  handleTextLink,
  handleClose,
}: AppModalProps) {
  const intl = useIntl();
  return (
    <AppModalTemplate isOpen={isOpen} className={className} handleClose={handleClose}>
      <div className="modal-content">
        {img && (
          <>
            <div className="page-spacer-15" />
            <IonImg src={img} className={styles.img} />
          </>
        )}

        {icon && (
          <>
            <div className="page-spacer-15" />
            <AppIcon icon={icon} size="special" />
          </>
        )}

        {title && (
          <>
            <div className="page-spacer-30" />
            <IonLabel color="black" className={styles.title}>
              {title}
            </IonLabel>
          </>
        )}

        {desc && (
          <>
            <div className="page-spacer-15" />
            <IonLabel color="black" className={styles.desc}>
              {desc}
            </IonLabel>
          </>
        )}
        <div className="page-spacer-30" />

        {!!handleConfirm && (
          <AppPrimaryButton onClick={handleConfirm} full={true}>
            {confirmBtnText || intl.formatMessage({ id: "ui_components.app_modal.button.confirm" })}
          </AppPrimaryButton>
        )}

        {textLink && handleTextLink ? (
          <>
            <div className="page-spacer-30" />
            <AppTextLink onClick={handleTextLink}>{textLink}</AppTextLink>
          </>
        ) : (
          <div className="page-spacer-15" />
        )}

        <div className="page-spacer-45" />
      </div>
    </AppModalTemplate>
  );
}
