import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/web/reducer";
import {
  CheckContentType,
  LoadingSymptonQuestionAndAnswerType,
  RecordedAnswerType,
  SymptonQuestionType,
} from "src/web/types";
import { useIntl } from "react-intl-phraseapp";

export function useQuestionSwitchControl(
  currentQuestionIndex: number,
  checkedAnswers?: RecordedAnswerType,
) {
  const { symptomQuestions }: { symptomQuestions: LoadingSymptonQuestionAndAnswerType } =
    useSelector((state: RootState) => state);
  const intl = useIntl();

  const isPreviousDisabled = useMemo(() => currentQuestionIndex === 0, [currentQuestionIndex]);

  const isNextDisabled = useMemo(
    () => currentQuestionIndex === symptomQuestions?.data?.questionList.length,
    [currentQuestionIndex, symptomQuestions?.data?.questionList],
  );

  // display "特にないので次へ" only for multi-select and nothing selected
  const dynamicNextLabel = (question: SymptonQuestionType) => {
    if (question && checkedAnswers?.checkContent) {
      const theMultiAnswer = checkedAnswers.checkContent.find(
        (item: CheckContentType) => item.inquiryId === question.id,
      );
      if (theMultiAnswer) {
        if (theMultiAnswer.answerIds && theMultiAnswer.answerIds.length > 0) {
          return intl.formatMessage({ id: "page.questionaire.button.next" });
        } else {
          return intl.formatMessage({ id: "page.questionaire.button.next_with_no_selection" });
        }
      } else {
        return intl.formatMessage({ id: "page.questionaire.button.next_with_no_selection" });
      }
    } else {
      return intl.formatMessage({ id: "page.questionaire.button.save" });
    }
  };

  return {
    isPreviousDisabled,
    isNextDisabled,
    dynamicNextLabel,
  };
}
