import styles from "./styles.module.css";

export function AppToggleButton({
  checked,
  onClick,
  disabled,
}: {
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
}) {
  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        className={styles.input}
        checked={checked}
        onChange={onClick}
        disabled={disabled}
      />
      <div className={`${styles.wrapper}`} />
    </label>
  );
}
