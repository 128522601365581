import { DEFAULT_LANGUAGE } from "src/web/constants";
import { getFaqList } from "src/web/services";
import { FaqType, FaqContentType, MenuType } from "src/web/types";
import { useAppRouter } from "../useAppRouter";

export function useFaqsModel() {
  const iRouter = useAppRouter();

  const fetchFaqList = async () => {
    try {
      let res: FaqType = await getFaqList({
        language: DEFAULT_LANGUAGE,
      });

      let menuList: MenuType[] = [];
      res.category.forEach((item) => {
        menuList.push({
          id: item.id,
          title: item.faq_category,
          subTitles: [],
        });
      });
      menuList.forEach((item: MenuType) => {
        item.subTitles = res.content
          .filter((content: FaqContentType) => content.faq_category_id === item.id)
          .map((item) => ({
            title: item.title,
            event: () => iRouter.push(`/settings/faqs/${item.faq_id}`),
          }));
      });
      res.menuList = menuList;
      return res;
    } catch (error) {
      throw error;
    }
  };

  const fetchFaqContents = async (id: string) => {
    try {
      const res: FaqType = await getFaqList({
        language: DEFAULT_LANGUAGE,
      });
      const currentFaq = res.content.find((faq: FaqContentType) => `${faq.faq_id}` === id);
      return currentFaq;
    } catch (error) {
      throw error;
    }
  };

  return {
    fetchFaqList,
    fetchFaqContents,
  };
}
