import React, { useState, useEffect } from "react";
import { IonLabel, IonPage, IonContent, ScrollDetail } from "@ionic/react";
import {
  AppHeader,
  AppLoading,
  AppSecondaryButton,
  AppSheetInput,
  AppToggleButton,
} from "../../components/UiComponents";
import { useAppRouter } from "../../hooks/useAppRouter";
import { getAlarmSetting, upsertAlarmSetting, deleteAlarmSetting } from "../../services";
import { getToday } from "../../utils/common";
import { useCommon } from "src/web/utils/hooks/common";
import { useAlarm } from "src/web/utils/hooks/alarm";
import { CollapseItem, EditAlarmSheetLite, EditAlarmSheet } from "./components";
import { Storage } from "@capacitor/storage";
import {
  LocalStorageKey,
} from "../../constants";
import {
  alarmSettingResType,
  alarmMedicineInfoItemType,
  medicineInfoType,
  alarmInfoItemType,
} from "./types";
import styles from "./notificationSetting.module.css";
import { useIntl } from "react-intl-phraseapp";
import { useTextConstants } from "src/web/constants/hooks";

export function NotificationSetting() {
  const intl = useIntl();
  const {
    MEDICINE_TIMING_OPTIONS,
    MEDICINE_TIMING_UNIT_OPTIONS
  } = useTextConstants();
  const { generateLabeledEmptyOptions } = useCommon();
  const { setScheduleAlarm } = useAlarm();
  const ionRouter = useAppRouter();

  const DEFAULT_ALARM_TIMING: any = {
    "01": "7:00",
    "02": "7:30",
    "03": "8:30",
    "04": "10:00",
    "05": "11:30",
    "06": "12:30",
    "07": "14:00",
    "08": "18:30",
    "09": "19:30",
    "10": "21:00",
    "11": "23:00",
  };

  const CUSTOM_TYPE_ALARM = "1";
  const SYSTEM_TYPE_ALARM = "0";

  const [isLoading, setIsLoading] = useState(false);
  const [openSheet, setOpenSheet] = useState<{
    isOpen: boolean;
    alarm?: alarmInfoItemType;
    medicineDetails?: alarmMedicineInfoItemType[];
  }>({
    isOpen: false,
  });
  const [openCustomTimingSheet, setOpenCustomTimingSheet] = useState<{
    isOpen: boolean;
    alarm?: alarmInfoItemType;
  }>({
    isOpen: false,
  });

  const [medicineInfos, setMedicineInfos] = useState<medicineInfoType[]>([]);

  const doFetch = async () => {
    try {
      setIsLoading(true);
      const { alarmInfo, alarmMedicineInfos }: alarmSettingResType = await getAlarmSetting({
        currentDate: getToday(),
      });

      let tmpMedicineInfos: medicineInfoType[] = [];
      if (alarmMedicineInfos) {
        // reflect the records in the timing option
        generateLabeledEmptyOptions(MEDICINE_TIMING_OPTIONS, true).forEach(({ value }) => {
          let infoArr: alarmMedicineInfoItemType[] = [];

          if (alarmMedicineInfos[value]) {
            // medicine details for every alarm timing record
            Object.values(alarmMedicineInfos[value]).forEach((info: alarmMedicineInfoItemType) => {
              if (info.unit) {
                const unit = MEDICINE_TIMING_UNIT_OPTIONS.find((opt) => opt.value === info.unit);
                info.unit_name = unit ? unit.text : "";
              }
              // 時間のタイプを転換する
              info.start = new Date(Date.parse(info.start! as any));
              info.end = new Date(Date.parse(info.end! as any));

              infoArr.push(info);
            });

            // alarm details
            let timeItem: medicineInfoType = alarmInfo[value]
              ? {
                  ...alarmInfo[value],
                  medicineDetails: infoArr,
                }
              : {
                  alarm_clock: DEFAULT_ALARM_TIMING[value] ? DEFAULT_ALARM_TIMING[value] : "",
                  alarm_flg: false,
                  alarm_title: "",
                  time_id: value,
                  type: SYSTEM_TYPE_ALARM,
                  medicineDetails: infoArr,
                };

            // init alarm_title
            if (timeItem.type !== CUSTOM_TYPE_ALARM && !timeItem.alarm_title) {
              const timingOpt = generateLabeledEmptyOptions(MEDICINE_TIMING_OPTIONS, true).find(
                (opt) => opt.value === value,
              );
              timeItem.alarm_title = timingOpt ? timingOpt.text : "";
            }

            tmpMedicineInfos.push(timeItem);

            delete alarmMedicineInfos[value];
            delete alarmInfo[value];
          }
        });

        // reflect the records out of the timing options
        Object.entries(alarmMedicineInfos).forEach(([key, value]) => {
          let infoArr: alarmMedicineInfoItemType[] = [];

          Object.values(value).forEach((info: alarmMedicineInfoItemType) => {
            if (info.unit) {
              const unit = MEDICINE_TIMING_UNIT_OPTIONS.find((opt) => opt.value === info.unit);
              info.unit_name = unit ? unit.text : "";
            }
            infoArr.push(info);
          });

          let timeItem: medicineInfoType = alarmInfo[key]
            ? {
                ...alarmInfo[key],
                alarm_title: alarmInfo[key].alarm_title ? alarmInfo[key].alarm_title : key,
                medicineDetails: infoArr,
              }
            : {
                alarm_clock: key,
                alarm_flg: false,
                alarm_title: key,
                time_id: key,
                type: SYSTEM_TYPE_ALARM,
                medicineDetails: infoArr,
              };

          tmpMedicineInfos.push(timeItem);

          delete alarmInfo[key];
        });
      }

      Object.entries(alarmInfo).forEach(([key, value]) => {
        tmpMedicineInfos.push(alarmInfo[key]);
      });

      tmpMedicineInfos = tmpMedicineInfos.sort((x: medicineInfoType, y: medicineInfoType) => {
        // タイプ一致しない場合、システムタイプ優先
        if (x.type !== y.type) {
          // type 0 はシステムタイプ優先表示
          // type 1 は手動入力です
          return x.type.localeCompare(y.type);
        }

        // タイプ一致の場合idを利用して、優先順位を決める
        // id長さが低いのは優先表示
        if (x.time_id.length !== y.time_id.length) {
          return x.time_id.length < y.time_id.length ? -1 : 1;
        }

        // 手動追加の部分はタイトルを比較する
        if (x.type === "1") {
          return x.alarm_title.localeCompare(y.alarm_title);
        }
        // システムの場合、idを比較する
        return x.time_id.localeCompare(y.time_id);
      });
      setMedicineInfos(tmpMedicineInfos);

      setIsLoading(false);

      return tmpMedicineInfos;
    } catch (error) {
      setIsLoading(false);
    }
  };

  const updateAlarmFlg = async (payload: alarmInfoItemType, medicineDetails: any) => {
    try {
      setIsLoading(true);
      const res = await upsertAlarmSetting(payload);
      let tmpMedicineInfos = await doFetch();

      // check global medicine notification flg, skipping setting alarms if the flag is false
      const globalMedicineNotificationEnabled = await (
        await Storage.get({ key: LocalStorageKey.GLOBAL_MEDICINE_NOTIFICATION_ENABLED })
      ).value;
      if (globalMedicineNotificationEnabled !== "true") {
        setIsLoading(false);
        return;
      }

      let dataArray: any = [];

      if (tmpMedicineInfos) {
        for (let data of tmpMedicineInfos) {
          dataArray.push({
            title: data.alarm_title,
            time: data.alarm_clock,
            isNew: data.type === "1",
            time_id: data.time_id,
            deleteFlg: !data.alarm_flg,
            medicineInfos: data.medicineDetails,
          });
        }
        await setScheduleAlarm(dataArray);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const deleteAlarm = async (time_id: string) => {
    try {
      setIsLoading(true);
      const res = await deleteAlarmSetting({ time_id });
      let tmpMedicineInfos = await doFetch();

      // check global medicine notification flg, skipping setting alarms if the flag is false
      const globalMedicineNotificationEnabled = await (
        await Storage.get({ key: LocalStorageKey.GLOBAL_MEDICINE_NOTIFICATION_ENABLED })
      ).value;
      if (globalMedicineNotificationEnabled !== "true") {
        setIsLoading(false);
        return;
      }

      let dataArray: any = [];

      if (tmpMedicineInfos) {
        for (let data of tmpMedicineInfos) {
          dataArray.push({
            title: data.alarm_title,
            time: data.alarm_clock,
            isNew: data.type === "1",
            time_id: data.time_id,
            deleteFlg: !data.alarm_flg,
            medicineInfos: data.medicineDetails,
          });
        }
        await setScheduleAlarm(dataArray);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    doFetch();
  }, []);

  // スクロール関連
  const [isDisplayBorder, setIsDisplayBorder] = useState(false);

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    // setIsDisplayBorder(true);
    if (ev.detail.scrollTop === 0) {
      setIsDisplayBorder(false);
    } else {
      setIsDisplayBorder(true);
    }
  };

  return (
    <>
      <IonPage>
        <AppHeader isDisplayBorder={isDisplayBorder} />

        <IonContent scrollEvents={true} onIonScroll={handleScroll}>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="p-header1">
              {intl.formatMessage({ id: "page.notification_setting.title" })}
            </IonLabel>

            <div className="page-spacer-45" />

            {medicineInfos.map((info, index) => (
              <React.Fragment key={index}>
                <IonLabel className="p-header3">{info.alarm_title}</IonLabel>
                <div className="page-spacer-15" />
                <hr />

                <div className="page-spacer-30" />

                <CollapseItem
                  label={
                    <>
                      <div>
                        <AppSheetInput
                          value={info.alarm_clock}
                          hideArrow={true}
                          handleActionSheet={() => {
                            const { medicineDetails, ...alarm } = info;
                            if (info.type === CUSTOM_TYPE_ALARM) {
                              setOpenCustomTimingSheet({
                                isOpen: true,
                                alarm,
                              });
                            } else {
                              setOpenSheet({
                                isOpen: true,
                                alarm,
                                medicineDetails: medicineDetails,
                              });
                            }
                          }}
                          size="sm"
                        />
                      </div>

                      <div className={styles.toggle}>
                        <AppToggleButton
                          checked={!!info.alarm_flg}
                          onClick={() => {
                            const { medicineDetails, ...alarm } = info;
                            updateAlarmFlg(
                              {
                                ...alarm,
                                alarm_flg: !alarm.alarm_flg,
                              },
                              medicineDetails,
                            );
                          }}
                        />
                      </div>
                    </>
                  }
                  content={
                    !!info.medicineDetails ? (
                      <>
                        <div className="page-spacer-30" />
                        <IonLabel className="p-body">
                          {intl.formatMessage({ id: "page.notification_setting.medicine" })}
                        </IonLabel>
                        <div className="page-spacer-15" />
                        {!!info.medicineDetails &&
                          info.medicineDetails.map((details, index) => (
                            <div key={index}>
                              <IonLabel className="p-smallbody">
                                {details.m_name} / {details.quantity} {details.unit_name}
                              </IonLabel>
                            </div>
                          ))}
                      </>
                    ) : undefined
                  }
                />

                <div className="page-spacer-45" />
              </React.Fragment>
            ))}

            <div className="page-spacer-45" />

            <AppSecondaryButton
              transparent={true}
              full={true}
              onClick={() => {
                setOpenCustomTimingSheet({
                  isOpen: true,
                  alarm: {
                    alarm_clock: "",
                    alarm_flg: false,
                    alarm_title: "",
                    time_id: "",
                    type: "1",
                  },
                });
              }}
            >
              {intl.formatMessage({ id: "page.notification_setting.button.add_alert" })}
            </AppSecondaryButton>

            <div className="page-spacer-60" />
          </div>
        </IonContent>
      </IonPage>

      <EditAlarmSheetLite
        isOpen={openSheet.isOpen}
        alarm={openSheet.alarm}
        handleClose={() => setOpenSheet({ isOpen: false })}
        handleConfirm={(data: alarmInfoItemType) => {
          updateAlarmFlg(data, openSheet.medicineDetails);
          setOpenSheet({ isOpen: false });
        }}
      />

      <EditAlarmSheet
        isOpen={openCustomTimingSheet.isOpen}
        alarm={openCustomTimingSheet.alarm}
        handleClose={() => setOpenCustomTimingSheet({ isOpen: false })}
        handleConfirm={(data: alarmInfoItemType) => {
          updateAlarmFlg(data, null);
          setOpenCustomTimingSheet({ isOpen: false });
        }}
        handleDelete={(time_id: string) => {
          deleteAlarm(time_id);
          setOpenCustomTimingSheet({ isOpen: false });
        }}
      />

      <AppLoading isOpen={isLoading} />
    </>
  );
}
