import { useRef, useEffect, useState } from "react";
import {
  ChartRadarDataType,
  ChartRadarConfigType,
  START_ANGLE,
  SYMBOL_POSITION,
} from "./appChartRadarType";
import { IonGrid, IonCol, IonRow, IonButton } from "@ionic/react";
import styles from "./styles.module.css";
import { getDateStrByDate } from "../../../utils/common";
import { useIntl } from "react-intl-phraseapp";

const ECharts = global.echarts;

const AVG_COLOR = "--graph-avg-color";
const CURRENT_COLOR = "--graph-current-color";
const BACKGROUND_COLOR = "--graph-background-color";
const TEXT_COLOR = "--text-color";
const SYMBOL_COLOR = "--graph-symbol-color";

// 表示内容を設置する
interface ContainerProps {
  score: ChartRadarDataType;
  config: ChartRadarConfigType;
}

function animateValue(obj: Element, start: number, end: number, duration: number) {
  let startTimestamp: number = -1;
  const step = (timestamp: number) => {
    if (startTimestamp < 0) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.innerHTML = Math.floor(progress * (end - start) + start).toString();
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

function getColor(key: string) {
  let root_style = document.querySelector(":root");
  let rs = getComputedStyle(root_style!);
  return rs.getPropertyValue(key).trim();
}

export const AppChartRadar: React.FC<ContainerProps> = ({ score, config }) => {
  const intl = useIntl();
  const radar_chart_cust: React.MutableRefObject<any> = useRef();

  const radar_avg_score: React.MutableRefObject<any> = useRef();
  const radar_current_score: React.MutableRefObject<any> = useRef();

  const [showNoRecordFlg, setShowRecordFlg] = useState<boolean>(false);
  // 初期表示の
  const [currentFlg, setCurrentFlg] = useState<string>("avg");

  useEffect(() => {
    let option = {
      // 動画の内容を定義する
      animationDuration: (index: number) => {
        if (index === 0) {
          return 1000;
        } else {
          return 700;
        }
      },
      animationDelay: (index: number) => {
        if (index === 1) {
          return 300;
        } else {
          return 0;
        }
      },
      textStyle: {
        fontWeight: 500,
        fontSize: 13,
      },
      radar: {
        nameGap: 5,
        indicator: config.indicator,
        // 固定最大サイズになる
        radius: 100,
        startAngle: START_ANGLE[config.splitNumber],
        splitNumber: config.splitNumber,
        // shape: "",
        axisName: {
          show: false,
          formatter: "{value}",
          color: getColor(TEXT_COLOR),
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: getColor(BACKGROUND_COLOR),
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          type: "radar",
          emphasis: {
            lineStyle: {
              width: 4,
            },
          },
          symbol: "text",
          chartWrap: "charts",
          data: [],
        },
      ],
    };
    let local_echart =
      ECharts.getInstanceByDom(radar_chart_cust.current) || ECharts.init(radar_chart_cust.current);

    local_echart && local_echart.setOption(option);
    local_echart.resize({ width: 300 });
    return () => {
      try {
        ECharts.dispose(radar_chart_cust.current);
      } catch {}
    };
  }, []);

  const putData = (
    showData: any[],
    name: string,
    value: any,
    symbol: any,
    color: string,
    isCurrent: boolean,
    radaSplit: number,
  ) => {
    let opacity = 0.2;
    if (isCurrent) {
      opacity = 0.6;
    }

    showData.push({
      emphasis: {
        disabled: true,
      },
      isCurrent: isCurrent,
      value: value,
      name: name,
      lineStyle: {
        color: `${color}`,
        width: 1,
        opacity: 0,
      },
      areaStyle: {
        color: `${color}`,
        opacity: opacity,
      },
      symbolLetter: symbol,
      symbolFont: {
        color: getColor(SYMBOL_COLOR),
        fontStyle: "normal",
        fontWeight: 700,
        fontFamily: "Inter",
        fontSize: 10,

        duration: 500,
        delay: 1000,
      },
      symbolOffsetPosition: SYMBOL_POSITION[radaSplit],
    });
  };

  useEffect(() => {
    let avg = score.avg;
    let local_echart =
      ECharts.getInstanceByDom(radar_chart_cust.current) || ECharts.init(radar_chart_cust.current);
    let setValue = false;
    if (avg && avg.length > 0) {
      setValue = true;
      setShowRecordFlg(false);
      let showData: any[] = [];
      let avg = score.avg;
      let current = score.current;

      let avgRank = score.avgRank;
      let currentRank = score.currentRank;
      let isCurrent = false;
      if (current && current.length > 0) {
        setCurrentFlg("current");
        isCurrent = true;
      } else {
        setCurrentFlg("avg");
      }

      putData(showData, "Avg", avg, avgRank, getColor(AVG_COLOR), !isCurrent, config.splitNumber);
      putData(
        showData,
        "Current",
        current,
        currentRank,
        getColor(CURRENT_COLOR),
        isCurrent,
        config.splitNumber,
      );

      local_echart &&
        local_echart.setOption({
          radar: {
            axisName: {
              show: true,
            },
          },
          series: [
            {
              type: "radar",
              emphasis: {
                lineStyle: {
                  width: 4,
                },
              },
              symbol: "text",
              chartWrap: "charts",
              data: showData,
            },
          ],
        });
    } else {
      let showData: any[] = [];
      local_echart &&
        local_echart.setOption({
          radar: {
            axisName: {
              show: false,
            },
          },
          series: [
            {
              type: "radar",
              emphasis: {
                lineStyle: {
                  width: 4,
                },
              },
              symbol: "text",
              chartWrap: "charts",
              data: showData,
            },
          ],
        });

      setShowRecordFlg(true);
    }

    if (setValue) {
      setTimeout(() => {
        // 点数設定
        if (score.avg && score.avg.length > 0) {
          if (radar_avg_score.current !== null)
            animateValue(radar_avg_score.current, 0, score.avgScore, 1000);
        } else {
          if (radar_avg_score.current !== null) radar_avg_score.current.innerHTML = "-";
        }

        // 点数設定
        // 当日のスコアを調整します。
        let isNoScore = false;
        if (score.current && score.current.length > 0) {
          // ランクをループする
          for (let value of score.currentRank) {
            // 空値がある場合
            if (value === "") {
              isNoScore = true;
              break;
            }
          }

          // 空値がある場合、-を設定する。
          if (isNoScore) {
            radar_current_score.current.innerHTML = "-";
          } else {
            // 数値がある場合動画を放送します。
            if (radar_current_score.current !== null)
              if (typeof score.currentScore === "number") {
                animateValue(radar_current_score.current, 0, score.currentScore, 1000);
              } else {
                radar_current_score.current.innerHTML = score.currentScore;
              }
          }
        } else {
          if (radar_current_score.current !== null) radar_current_score.current.innerHTML = "-";
        }
      }, 0);
    }
  }, [score]);

  let isCurrent = score.current_date === getDateStrByDate(new Date());

  return (
    <>
      <div className={styles.chart_parent} id="charts">
        <div
          ref={radar_chart_cust}
          className={`${styles.chart} div_center`}
          style={{ margin: 0, padding: 0, height: "260px" }}
        ></div>
        {showNoRecordFlg ? (
          <div className={`${styles.no_record_warn} div_center`}>
            <div>
              {intl.formatMessage({ id: "ui_components.app_chart_rader.no_record_line1" })}
              <br />
              {intl.formatMessage({ id: "ui_components.app_chart_rader.no_record_line2" })}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* データ存在の場合設定 */}
      {!showNoRecordFlg && (
        <IonGrid className={styles.gridPosition}>
          <IonRow>
            <IonCol>
              <div className={styles.leftScoreDiv}>
                <IonButton
                  className={
                    currentFlg === "avg"
                      ? `${styles.leftButton}`
                      : `${styles.leftButton} ${styles.buttonBorderColor}`
                  }
                  onClick={() => {
                    if (score.avg && score.avg.length > 0) {
                      setCurrentFlg("avg");
                      let local_echart = (ECharts.getInstanceByDom(radar_chart_cust.current) ||
                        ECharts.init(radar_chart_cust.current)) as any;

                      local_echart._chartsViews[0].setGraphicDsp(0, 1);
                    }
                  }}
                >
                  <div
                    className={`${styles.leftScore} p-score-panel chart_group`}
                    ref={radar_avg_score}
                  >
                    {/* {score.avg && score.avg.length > 0 ? score.avgScore : "-"} */}
                  </div>
                </IonButton>
              </div>
            </IonCol>
            <IonCol>
              <div className={styles.rightScoreDiv}>
                <IonButton
                  className={
                    currentFlg === "current"
                      ? `${styles.rightButton}`
                      : `${styles.rightButton} ${styles.buttonBorderColor}`
                  }
                  onClick={() => {
                    if (score.current && score.current.length > 0) {
                      setCurrentFlg("current");
                      let local_echart = (ECharts.getInstanceByDom(radar_chart_cust.current) ||
                        ECharts.init(radar_chart_cust.current)) as any;

                      local_echart._chartsViews[0].setGraphicDsp(1, 0);
                    }
                  }}
                >
                  <div
                    className={`${styles.rightScore} p-score-panel chart_group`}
                    ref={radar_current_score}
                  >
                    {/* {score.current && score.current.length > 0 ? score.currentScore : "-"} */}
                  </div>
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div className={styles.font}>
                {intl.formatMessage({ id: "ui_components.app_chart_rader.score.average" })}
              </div>
            </IonCol>
            <IonCol>
              <div className={styles.font}>
                {isCurrent
                  ? intl.formatMessage({ id: "ui_components.app_chart_rader.score.today" })
                  : intl.formatMessage({ id: "ui_components.app_chart_rader.score.the_day" })}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};
export default AppChartRadar;
