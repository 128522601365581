import { useMemo } from "react";
import { MenuItem } from "src/web/components/UiComponents/organisms";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { FaqContentType } from "src/web/types";

interface LineItemProps {
  item: FaqContentType;
  filterBy: string;
}

export function LineItem({ item, filterBy }: LineItemProps) {
  const iRouter = useAppRouter();

  const menuItem = useMemo(() => {
    return {
      title: item.title,
      event: () => iRouter.push(`/settings/faqs/${item.faq_id}?filterBy=${filterBy}`),
    };
  }, [item, filterBy]);

  return <MenuItem item={menuItem} isGray />;
}
