import styles from "./styles.module.css";

interface SmallImage {
  src: string;
  title: string;
  className?: string;
}

export function AppSmallImage(props: SmallImage) {
  return (
    <div className={`${styles.divContainer} div_center ${props.className ? props.className : ''}`}>
      <div>
        <img src={props.src} className={`${styles.img}`} alt="" />
        <div className="page-spacer-15"/>
        <div className={styles.title}>
          {props.title}
        </div>
      </div>
    </div>
  );
}
