import { IonLabel } from "@ionic/react";
import { AppInput, AppPasswordInput, AppTextLink } from "../..";
import { PageTitleCenter } from "../../moleclues";
import { useIntl } from "react-intl-phraseapp";

interface LoginFormProps {
  pageConfig: {
    title: string;
    imgSrc?: string;
  };
  userInfo: {
    userName: string;
    password: string;
  };
  hasLoginError: boolean;
  handleUserNameInputChange: (val: string) => void;
  handlePasswordInputChange: (val: string) => void;
  handleNavToForgetPassword: () => void;
}

export function LoginForm({
  pageConfig,
  userInfo,
  hasLoginError,
  handleUserNameInputChange,
  handlePasswordInputChange,
  handleNavToForgetPassword,
}: LoginFormProps) {
  const intl = useIntl();
  return (
    <>
      <PageTitleCenter title={pageConfig.title} imgSrc={pageConfig.imgSrc} />

      <div className="page-spacer-45" />

      <AppInput
        type="email"
        value={userInfo.userName}
        autocomplete="email"
        label={intl.formatMessage({ id: "page.login.label.email" })}
        error={hasLoginError ? { type: undefined } : undefined}
        onIonChange={(e: any) => {
          handleUserNameInputChange(e.detail.value);
        }}
      />

      <div className="page-spacer-45" />

      <AppPasswordInput
        id="pwd"
        value={userInfo.password}
        autocomplete="password"
        label={intl.formatMessage({ id: "page.login.label.password" })}
        error={hasLoginError ? { type: undefined } : undefined}
        onIonChange={(e: any) => {
          handlePasswordInputChange(e.detail.value);
        }}
      />

      <div className="page-spacer-15" />

      <div className="div_center">
        <AppTextLink onClick={handleNavToForgetPassword}>
          {intl.formatMessage({ id: "page.login.link.forget_password" })}
        </AppTextLink>
      </div>

      {/* エラー発生の時表示します。 */}
      {hasLoginError && (
        <>
          <div className="page-spacer-45" />

          <IonLabel className="p-page-label-error-block">
            {intl.formatMessage({ id: "page.login.error.general.message.line1" })}
          </IonLabel>
          <IonLabel className="p-page-label-error-block">
            {intl.formatMessage({ id: "page.login.error.general.message.line2" })}
          </IonLabel>
        </>
      )}
    </>
  );
}
