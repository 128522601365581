import { IonPage, IonContent, IonLabel } from "@ionic/react";
import { AppLogo, AppTextLink } from "../../components/UiComponents";
import { useAppRouter } from "../../hooks/useAppRouter";
import { useIntl } from "react-intl-phraseapp";

export default function CreateUserExpired() {
  const intl = useIntl();
  const iRouter = useAppRouter();

  return (
    <IonPage>
      <AppLogo />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <IonLabel className="p-header2 text-center-block">
            {intl.formatMessage({ id: "page.create_user_expired.message.line1" })}
          </IonLabel>

          <div className="page-spacer-60" />

          <AppTextLink onClick={() => iRouter.push("/create-user-email")}>
            {intl.formatMessage({ id: "page.create_user_expired.resend_email" })}
          </AppTextLink>
        </div>
      </IonContent>
    </IonPage>
  );
}
