import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { MONTH_PERIOD } from "src/web/constants";
import { RootState } from "src/web/reducer";
import { getDateStrByDate, getToday } from "src/web/utils/common";

export function useChangeMonthControl(
  currentMonthStartDate: string,
  setCurrentMonthStartDate: React.Dispatch<React.SetStateAction<string>>,
) {
  const {
    userStartDate,
  }: {
    userStartDate: string;
  } = useSelector((state: RootState) => state);

  const formattedMonthPeriod = useMemo(() => {
    const monthStartDate = new Date(currentMonthStartDate);
    return `${monthStartDate.getFullYear()}/${monthStartDate.getMonth() < 6 ? "1~6" : "7~12"}`;
  }, [currentMonthStartDate]);

  const isPreMonthDisabled = useMemo(() => {
    return !!userStartDate && currentMonthStartDate <= userStartDate;
  }, [currentMonthStartDate]);

  const isNextMonthDisabled = useMemo(() => {
    const periodEndDate = new Date(currentMonthStartDate);
    periodEndDate.setMonth(periodEndDate.getMonth() + MONTH_PERIOD);
    periodEndDate.setDate(periodEndDate.getDate() - 1);
    return !!periodEndDate && getToday() <= getDateStrByDate(periodEndDate);
  }, [currentMonthStartDate]);

  const handleSwitchMonth = (countOfMonth: number) => {
    const nextStartdate = new Date(currentMonthStartDate);
    nextStartdate.setMonth(nextStartdate.getMonth() + countOfMonth);
    setCurrentMonthStartDate(getDateStrByDate(nextStartdate));
  };

  const gotoPreviousMonth = () => {
    handleSwitchMonth(-MONTH_PERIOD);
  };

  const gotoNextMonth = () => {
    handleSwitchMonth(MONTH_PERIOD);
  };

  return {
    formattedMonthPeriod,
    isPreMonthDisabled,
    isNextMonthDisabled,
    gotoPreviousMonth,
    gotoNextMonth,
  };
}
