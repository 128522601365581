import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateAlertInfo, updateSymptomQuestions } from "src/web/actions";
import { DEFAULT_DISEASE_ID } from "src/web/constants";
import { getDiseaseInquiry, getAnswerByDateV2, saveSymptomV2 } from "src/web/services";
import {
  AlertType,
  RecordedAnswerType,
  SymptonQuestionType,
  CheckContentType,
} from "src/web/types";

export function useSymptomQuestionsModel() {
  const dispatch = useDispatch();

  const [isLoadingSymptomAnswers, setIsLoadingSymptomAnswers] = useState(false);
  const [isSavingLoading, setIsSavingLoading] = useState(false);

  // get symptom answers
  const fetchSymptomAnswers = async (currentDate: string) => {
    const initAnswer = {
      currentDate,
      checkContent: [],
      daily: "",
      status: false, // default set as temp save
    };

    try {
      setIsLoadingSymptomAnswers(true);
      const answersRes: any = await getAnswerByDateV2({
        currentDate,
      });
      return answersRes ?? initAnswer;
    } catch (error) {
      return initAnswer;
    } finally {
      setIsLoadingSymptomAnswers(false);
    }
  };

  // get symptom quesions
  const fetchSymptomQuestionsAndAnswers = async (currentDate: string) => {
    dispatch(
      updateSymptomQuestions({
        isLoading: true,
      }),
    );
    try {
      // fetch questions
      const questionList: SymptonQuestionType[] = await getDiseaseInquiry({
        id: DEFAULT_DISEASE_ID,
        currentDate,
      });
      // fetch answers
      const checkedAnswers: RecordedAnswerType = await fetchSymptomAnswers(currentDate);

      // init the answer by looping all the questions
      questionList.forEach((question: SymptonQuestionType) => {
        const skipToTheNext = checkedAnswers.checkContent.some(
          (answer: CheckContentType) => question.id === answer.inquiryId,
        );
        if (!skipToTheNext) {
          checkedAnswers.checkContent.push({
            inquiryId: question.id,
            answerIds: null,
          });
        }
      });

      dispatch(
        updateSymptomQuestions({
          isLoading: false,
          data: {
            questionList,
            checkedAnswers,
          },
        }),
      );
    } catch (error) {
      dispatch(
        updateSymptomQuestions({
          isLoading: false,
        }),
      );
      return;
    }
  };

  const saveQuestionnaireAnswer = async (checkedAnswers: RecordedAnswerType, status: boolean) => {
    // saveSymptomV2()
    setIsSavingLoading(true);
    try {
      await saveSymptomV2({
        ...checkedAnswers,
        status,
      });
    } catch (error: any) {
      dispatch(
        updateAlertInfo({
          status: AlertType.ERROR,
          msg: error.message.message,
        }),
      );
      throw error;
    } finally {
      setIsSavingLoading(false);
    }
  };

  return {
    isLoadingSymptomAnswers,
    isSavingLoading,
    fetchSymptomQuestionsAndAnswers,
    saveQuestionnaireAnswer,
  };
}
