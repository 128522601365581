import { useState, useEffect } from "react";
import styles from "./appRadiobox.module.css";

export function AppRadiobox({
  label,
  checked,
  onClick,
  disabled,
  type = "horizontal",
}: {
  label?: string;
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
  type?: "horizontal" | "vertical";
}) {
  const [checkStatus, setCheckStatus] = useState({
    checking: false,
    unchecking: false,
    checked,
  });
  const [lastCheckedState, setLastCheckedState] = useState(checked); // the logic for block animation in some scenario

  useEffect(() => {
    if (checked && checked !== lastCheckedState) {
      setCheckStatus({
        checking: true,
        unchecking: false,
        checked: false,
      });
    } else if (!checked && checked !== lastCheckedState) {
      setCheckStatus({
        checking: false,
        unchecking: true,
        checked: false,
      });
    } else {
      setCheckStatus({
        checking: false,
        unchecking: false,
        checked,
      });
    }
    setLastCheckedState(checked);
  }, [checked]);

  return (
    <label
      className={`${styles.root} ${type === "vertical" ? styles.vertical : ""} ${styles.label}`}
    >
      <input type="radio" className={styles.input} checked={checked} disabled={disabled} />
      <div
        className={`${styles.init} 
            ${
              checkStatus.checking
                ? styles.checking
                : checkStatus.unchecking
                ? styles.unchecking
                : checkStatus.checked
                ? styles.checked
                : ""
            }
            ${disabled ? styles.disabled : ""}
        `}
        onClick={() => {
          if (!disabled) {
            onClick && onClick();
          }
        }}
      ></div>
      {label}
    </label>
  );
}
