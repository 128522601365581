import styles from "./styles.module.css";

interface TagTime {
  textTitle?: string;
  className?: string;
}

export function AppTagTime(props: TagTime) {
  return (
    <div className={`${styles.divTagTimeContainer} div_center ${props.className?props.className:''}`}>
      <div>{props.textTitle}</div>
    </div>
  );
}
