import { AnouncementType } from "src/web/types";
import { LabelWithContents } from "../../moleclues";
import { Fragment } from "react";

interface ListWithTitleProps {
  list: AnouncementType[];
  handleOnClick: (id: number) => void;
}

export function ListWithTitle({ list, handleOnClick }: ListWithTitleProps) {
  return (
    <>
      {list.map((item, index) => (
        <Fragment key={index}>
          <div className="page-spacer-15" />
          <hr />
          <div className="page-spacer-15" />

          <div onClick={() => handleOnClick(item.id)}>
            <LabelWithContents title={item.title} text={item.update_time} />
          </div>
        </Fragment>
      ))}
    </>
  );
}
