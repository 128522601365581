import { actionConst } from "../constants";
import { RefreshType } from "../types";

const initialState: RefreshType = {};

interface Action {
  type: actionConst;
  state: any;
}

export default function refreshInfoReducer(state = initialState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_REFRESH_INFO:
      return actions.state;
    case actionConst.CLEAR_REFRESH_INFO:
      return { ...initialState };
    default:
      return state;
  }
}
