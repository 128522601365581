import { actionConst } from "../constants";

import { PrescriptionInfoType } from "../pages/PrescriptionInformation/type";

// 処方箋情報を初期化する
const initialState: PrescriptionInfoType = {};

interface Action {
  type: actionConst;
  state: any;
}

export default function prescriptionInfoReducer(state = initialState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_PRESCRIPTION_INFO:
      return actions.state;
    case actionConst.CLEAR_PRESCRIPTION_INFO:
      return { ...initialState };
    default:
      return state;
  }
}
