import { setLocalData, getLocalData } from "../request/apiManager";
import { STORAGE_REQ_KEY } from "../../constants";

export const storeRequest = async (url: string, type: string, data: any) => {
  const req = {
    url,
    headers: {
      "content-type": "application/json",
      uuid: "uuid",
      accesstoken: "accesstoken",
    },
    type,
    data,
    time: new Date().getTime(),
    id: Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substring(0, 5),
  };
  return getLocalData(STORAGE_REQ_KEY).then((reqs) => {
    if (reqs) {
      reqs.push(req);
    } else {
      reqs = [req];
    }
    return setLocalData(STORAGE_REQ_KEY, reqs);
  });
};
