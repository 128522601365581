import { useState, forwardRef, useImperativeHandle } from "react";
import { IonSlides, IonSlide } from "@ionic/react";
import { SelectOption } from "../../../types";
import styles from "./appPicker.module.css";

interface AppDoublePickerProps {
  initVal?: string | number;
  initVal2?: string | number;
  defaultVal?: string | number;
  options: SelectOption[];
  options2: SelectOption[];
  separatedBy?: string;
  isLoop?: boolean;
}

export const AppDoublePicker = forwardRef(
  (
    {
      initVal,
      initVal2,
      defaultVal,
      options,
      options2,
      separatedBy,
      isLoop = false,
    }: AppDoublePickerProps,
    ref: any,
  ) => {
    useImperativeHandle(ref, () => ({
      getSelectedData: () => getSelectedData(),
    }));

    const opts = {
      direction: "vertical",
      slidesPerView: 7,
      centeredSlides: true,
      spaceBetween: 7,
    };

    let initLeftValIndex, initRightValIndex;

    if (separatedBy) {
      const val =
        initVal || initVal === 0
          ? `${initVal}`.split(separatedBy)
          : defaultVal
          ? `${defaultVal}`.split(separatedBy)
          : undefined;
      initLeftValIndex = val ? options.findIndex((opt) => opt.value === val[0]) : 0;
      initRightValIndex = val ? options2.findIndex((opt) => opt.value === val[1]) : 0;
    } else {
      initLeftValIndex = initVal ? options.findIndex((opt) => opt.value === initVal) : 0;
      initRightValIndex = initVal2 ? options2.findIndex((opt) => opt.value === initVal2) : 0;
    }
    const [pairVal, setPairVal] = useState({
      left: initLeftValIndex,
      right: initRightValIndex,
    });

    const slideLeftOpts = {
      ...opts,
      initialSlide: initLeftValIndex,
      loopAdditionalSlides: isLoop ? 300 : 0,
      loop: isLoop,
    };

    const slideRightOpts = {
      ...opts,
      initialSlide: initRightValIndex,
      loopAdditionalSlides: isLoop ? 300 : 0,
      loop: isLoop,
    };

    const getSelectedData = () => {
      if (separatedBy) {
        return `${options[pairVal.left].value}${separatedBy}${options2[pairVal.right].value}`;
      } else {
        return [options[pairVal.left].value, options2[pairVal.right].value];
      }
    };

    return (
      <>
        <div className={`app-picker ${styles.pickerRoot}`}>
          <div className="flex-row-between">
            <IonSlides
              options={slideLeftOpts}
              className={styles.pickerWrapper}
              onIonSlideDidChange={(e: any) => {
                setPairVal({
                  ...pairVal,
                  left: e.target.swiper.realIndex,
                });
              }}
            >
              {options.map((opt: SelectOption) => (
                <IonSlide className={`${styles.pickerItem} `} key={opt.value}>
                  <div className={styles.left}>{opt.text}</div>
                </IonSlide>
              ))}
            </IonSlides>

            <div className={styles.divider} />

            {options2 && (
              <IonSlides
                options={slideRightOpts}
                className={styles.pickerWrapper}
                onIonSlideDidChange={(e: any) => {
                  setPairVal({
                    ...pairVal,
                    right: e.target.swiper.realIndex,
                  });
                }}
              >
                {options2.map((opt: SelectOption) => (
                  <IonSlide className={`${styles.pickerItem} ${styles.right}`} key={opt.value}>
                    <div className={styles.right}>{opt.text}</div>
                  </IonSlide>
                ))}
              </IonSlides>
            )}
          </div>
        </div>
      </>
    );
  },
);
