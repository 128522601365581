import { IonContent, IonPage } from "@ionic/react";
import { AppHeader, AppHomeIndicatorBar } from "../../../components/UiComponents";
import { MenuItemWithToggleGroup } from "src/web/components/UiComponents/organisms";
import { useNotificationConfigControl } from "./hooks/useNotificationConfigControl";
import { useIntl } from "react-intl-phraseapp";

export function NotificationConfig() {
  const { dynimicMenus } = useNotificationConfigControl();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppHeader />

        <IonContent>
          <div className="root-content">
            {dynimicMenus().map((menu, index) => (
              <MenuItemWithToggleGroup
                pageTitle={
                  index === 0
                    ? intl.formatMessage({ id: "page.notification_config.title" })
                    : undefined
                }
                menuData={menu}
              />
            ))}

            <AppHomeIndicatorBar />
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
