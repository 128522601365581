import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../web/firebase";
import { Storage } from "@capacitor/storage";
import { LocalStorageKey } from "../../web/constants";
import { App as CapApp } from "@capacitor/app";
import { CapacitorUpdater } from "@capgo/capacitor-updater";

export function useVersion() {
  const regexStr = /^([0-9]+)\.([0-9]+)\.([0-9]+)$/;
  const regexStrFile = /^([0-9]+)\.([0-9]+)\.([0-9]+)\.zip$/;

  const extractVersionNumberFromFilename = (item: { name: string; fullPath: string }) => {
    const result: any = extractVersionNumber(item.name, regexStrFile);
    result.fullPath = item.fullPath;
    return result;
  };

  const extractVersionNumber = (currentVersion: any, regex = regexStr) => {
    const regexResult = currentVersion.match(regex);
    if (regexResult == null) {
      throw new Error("version is not found.");
    }

    const major = parseInt(regexResult[1]);
    const minor = parseInt(regexResult[2]);
    const patch = parseInt(regexResult[3]);

    return {
      version: `${major}.${minor}.${patch}`,
      major,
      minor,
      patch,
    };
  };

  const getLatestVersion = async (currentVersionInfo: any) => {
    const listRef = ref(storage, "patches");
    const response = await listAll(listRef);

    const versionNumbers = response.items
      .map(extractVersionNumberFromFilename)
      .filter((v) => v.major === currentVersionInfo.major);
    versionNumbers.sort(
      // sort desc
      (a, b) => {
        return b.major - a.major || b.minor - a.minor || b.patch - a.patch;
      },
    );
    if (versionNumbers.length == 0) {
      throw Error("latest version not found");
    }
    if (versionNumbers[0].major === -1) {
      throw Error("latest version not found");
    }
    return versionNumbers[0];
  };

  const getCurrentVersion = async () => {
    let currentVersion = (await Storage.get({ key: LocalStorageKey.PATCH_VERSION })).value;

    if (currentVersion == null) {
      currentVersion = (await CapApp.getInfo()).version;
    }
    return extractVersionNumber(currentVersion);
  };

  const checkIsUpdatable = (newVersionInfo: any, currentVersionInfo: any) => {
    if (
      newVersionInfo.patch > currentVersionInfo.patch &&
      newVersionInfo.major == currentVersionInfo.major
    ) {
      return true;
    }
    return false;
  };

  const updateVersion = async (versionInfo: any, beforeUpdateHook?: { (): Promise<void> }) => {
    const url = await getDownloadURL(ref(storage, versionInfo.fullPath));
    const versionData = await CapacitorUpdater.download({ url });
    if (versionData.version !== "") {
      await Storage.set({ key: LocalStorageKey.PATCH_VERSION, value: versionInfo.version });
      if (beforeUpdateHook) {
        await beforeUpdateHook();
      }
      await CapacitorUpdater.set(versionData); // sets the new version, and reloads the app
    } else {
      throw new Error("update is failed");
    }
  };

  return {
    getLatestVersion,
    getCurrentVersion,
    checkIsUpdatable,
    updateVersion,
    extractVersionNumber,
  };
}
