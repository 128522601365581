import { IonGrid, IonLabel, IonRow } from "@ionic/react";
import { AppCheckbox, AppTrackingButton } from "../..";
import styles from "./optionButton.module.css";

export const OptionButton = ({
  label,
  isActive,
  isMultiSelect,
  onClick,
  inquiryId,
  isHideTopSpace = false,
}: {
  label: string;
  isActive?: boolean;
  isMultiSelect: boolean;
  onClick: () => void;
  inquiryId: number;
  isHideTopSpace?: boolean;
}) => {
  return (
    <>
      {isMultiSelect ? (
        <IonGrid>
          {!isHideTopSpace && <div className="page-spacer-60" />}
          <IonRow
            className={`ion-justify-content-center ion-align-items-center ${styles.optionMultiBtnWrapper}`}
          >
            <AppCheckbox checked={!!isActive} onClick={onClick} />

            <div className="page-spacer-15" />

            <IonLabel className="p-body">{label}</IonLabel>
          </IonRow>
        </IonGrid>
      ) : (
        <AppTrackingButton label={label} checked={!!isActive} onClick={onClick} key={inquiryId} />
      )}
    </>
  );
};
