import { Fragment } from "react";
import { IonLabel } from "@ionic/react";
import { MenuWithToggleType } from "src/web/types";
import { AppToggleButton } from "../../AppToggleButton/appToggleButton";

interface MenuItemWithToggleGroupProps {
  pageTitle?: string;
  menuData: MenuWithToggleType;
}

export function MenuItemWithToggleGroup({ pageTitle, menuData }: MenuItemWithToggleGroupProps) {
  return (
    <>
      {pageTitle ? (
        <>
          <div className="page-spacer-45" />
          <IonLabel className="p-header1">{pageTitle}</IonLabel>
        </>
      ) : (
        <div className="page-spacer-15" />
      )}

      <div className="page-spacer-50" />

      <IonLabel className="p-header3">{menuData.title}</IonLabel>

      <div className="page-spacer-15" />

      <hr />

      {menuData.subItems.map((subItem) => (
        <Fragment key={subItem.title}>
          <div className="page-spacer-45" />

          <div className="flex-row-between">
            <IonLabel className="p-body">{subItem.title}</IonLabel>
            <AppToggleButton
              checked={subItem.value}
              onClick={() => subItem.setValue(!subItem.value)}
            />
          </div>
        </Fragment>
      ))}
    </>
  );
}
