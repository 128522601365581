import { IonLabel } from "@ionic/react";
import { AppInput } from "../../AppInput/appInput";

interface PageTitleWithSearchProps {
  title: string;
  value: string;
  handleChange: (e: any) => void;
  handleFilter: () => void;
}

export function PageTitleWithSearch({
  title,
  value,
  handleChange,
  handleFilter,
}: PageTitleWithSearchProps) {
  return (
    <>
      <IonLabel className="p-header1">{title}</IonLabel>

      <div className="page-spacer-45" />

      <AppInput
        value={value}
        maxlength={30}
        onIonChange={handleChange}
        handleSearch={handleFilter}
        enterkeyhint="search"
        handleEnter={handleFilter}
        onIonBlur={(e: any) => {
          handleFilter();
        }}
      />
    </>
  );
}
