import { actionConst } from "../constants";

import { MedicineInfoType } from "src/web//types";

export const clearMedicineInfo = () => {
  return {
    type: actionConst.CLEAR_MEDICINE_INFO,
    state: {},
  };
};

export const updateMedicineInfo = (medicineInfo: MedicineInfoType) => {
  return {
    type: actionConst.UPDATE_MEDICINE_INFO,
    state: medicineInfo,
  };
};
