import { useState, useEffect } from "react";
import {
  getDateStrByDate,
  getStartEndDatesOfWeek,
  getToday,
  loopEmptyCheckForObjectAndArray,
} from "src/web/utils/common";
import { useSelector } from "react-redux";
import { RootState } from "src/web/reducer";
import { LoadingWeekChartType, WeekChartType } from "src/web/types";
import { ChartDataTypeV2 } from "src/web/components/Charts";
import { useTextConstants } from "src/web/constants/hooks";

export function useWeekChartControl(weekStartDateStr: string) {
  const {
    scoreTransitionWeekChart,
    userStartDate,
  }: { scoreTransitionWeekChart: LoadingWeekChartType; userStartDate: string } = useSelector(
    (state: RootState) => state,
  );
  const { DAY_CONVERT } = useTextConstants();
  const [lineKeys, setLineKeys] = useState<{ [id: string]: number }>();
  const [chartData, setChartData] = useState<ChartDataTypeV2[]>();
  const [isNoData, setIsNoData] = useState(false);

  const disablePreArrow = () => {
    if (scoreTransitionWeekChart.data) {
      const tmpMinDate = new Date(scoreTransitionWeekChart.data.minDate);
      const weekStartDate = new Date(weekStartDateStr);
      return (
        (tmpMinDate && tmpMinDate >= weekStartDate) ||
        (!!userStartDate && new Date(userStartDate) >= weekStartDate)
      );
    } else {
      return false;
    }
  };

  const disableNextArrow = () => {
    const weekStartDate = new Date(weekStartDateStr);
    const [_, weekEndDate] = getStartEndDatesOfWeek(weekStartDate);
    return new Date(getToday()) <= weekEndDate;
  };

  const getKeyDay = (val: number) => {
    const weekStartDate = new Date(weekStartDateStr);
    let date = new Date(
      weekStartDate.getFullYear(),
      weekStartDate.getMonth(),
      weekStartDate.getDate() + val,
    );
    return [`${getDateStrByDate(date)}`, `${date.getDate().toString().padStart(2, "0")}`];
  };

  // check for no data
  const checkIsNoData = (data: any) => {
    const params = ["barData", "lineData", "overallDataEveryDay", "weekdayTimeline"];
    const isEmpty =
      params.every((p: string) => loopEmptyCheckForObjectAndArray(data[p])) ||
      Object.keys(data.overallData || {}).length === 0;

    return isEmpty;
  };

  useEffect(() => {
    if (scoreTransitionWeekChart.data) {
      const weekData = scoreTransitionWeekChart.data;

      setIsNoData(checkIsNoData(weekData));

      if (!weekData) {
        let arrData: ChartDataTypeV2[] = [];
        let retLineKeys: { [id: string]: number } = {};
        for (let i = 0; i < 7; i++) {
          let [key, date] = getKeyDay(i);
          arrData[i] = {
            name: `${date} ${DAY_CONVERT[i]}`,
            score: 0,
            dspScore: 0,
            date: key,
            barTooltipVal: undefined,
          };
        }

        // キー設定
        setLineKeys(retLineKeys);

        setChartData(arrData);
        return;
      }
      // 棒図のデータ
      let barData = weekData["barData"];
      // 折れ線図
      let lineData = weekData["lineData"];

      // 体温, 血圧 and 活動
      let overallData = weekData["overallDataEveryDay"];

      // timeline
      let weekdayTimeline = weekData["weekdayTimeline"];

      let arrData: ChartDataTypeV2[] = [];
      for (let i = 0; i < 7; i++) {
        let [key, date] = getKeyDay(i);
        let scoreVal = barData[key];
        let overallVal = overallData[key];
        let timelineVal = weekdayTimeline ? weekdayTimeline[key] : [];

        // 注意：値は100から120に拡張する
        // そのため表示するときdspScoreを利用する
        arrData[i] = {
          name: `${date} ${DAY_CONVERT[i]}`,
          dspScore:
            scoreVal && typeof scoreVal["score"] === "number" ? (scoreVal["score"] / 100) * 120 : 0,
          score: scoreVal && typeof scoreVal["score"] === "number" ? scoreVal["score"] : 0,
          date: key,
          barTooltipVal: scoreVal,
          temperature: overallVal?.BODY_TEMPERATURE?.temperature ?? undefined, // 体温
          sbp: overallVal?.BLOOD_PRESSURE?.sbp ?? undefined, // 最高血圧値
          dbp: overallVal?.BLOOD_PRESSURE?.dbp ?? undefined, // 最低血圧値
          activityDuration: overallVal?.ACTIVITY?.duration ?? 0, // 活動
          overallData: {
            BODY_TEMPERATURE: {
              ...weekData.overallData.BODY_TEMPERATURE,
              temperatureToday: overallVal?.BODY_TEMPERATURE?.temperature,
            },
            BLOOD_PRESSURE: {
              ...weekData.overallData.BLOOD_PRESSURE,
              sbpToday: overallVal?.BLOOD_PRESSURE?.sbp,
              dbpToday: overallVal?.BLOOD_PRESSURE?.dbp,
              hrToday: overallVal?.BLOOD_PRESSURE?.hr,
            },
            ACTIVITY: {
              ...weekData.overallData.ACTIVITY,
              durationToday: overallVal?.ACTIVITY?.duration,
              stepsToday: overallVal?.ACTIVITY?.steps,
            },
          },
          weekdayTimeline: timelineVal,
        };

        // 折れ線
        let lineVal = lineData[key];
        arrData[i].lineTooltipVal = lineVal;
        // 存在する
        if (lineVal) {
          for (let key in lineVal as any) {
            arrData[i][key] = lineVal[key][0]["time"];
          }
        }
      }

      // lineDate
      let lineKey = weekData["lineKey"] as [];

      let retLineKeys: { [id: string]: number } = {};
      for (let i = 0; i < lineKey.length; i++) {
        retLineKeys[lineKey[i]] = i + 1;
      }
      // キー設定
      setLineKeys(retLineKeys);

      setChartData(arrData);
    }
  }, [scoreTransitionWeekChart.data]);

  return {
    isPreDateDisabled: disablePreArrow(),
    isNextDateDisabled: disableNextArrow(),
    lineKeys,
    chartData,
    isNoData,
  };
}
