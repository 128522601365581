import { useSelector } from "react-redux";
import { RootState } from "../../../reducer";
import { AppHomeIndicatorBar, AppPrimaryButton, AppSecondaryButton } from "../";
import styles from "./appFloatButton.module.css";

interface AppFloatButtonProps {
  label: string;
  onClick: () => void;
  isStatic?: boolean;
  isSecond?: boolean;
  disabled?: boolean;
}

export function AppFloatButton({
  label,
  onClick,
  isStatic = false,
  isSecond = false,
  disabled = false,
}: AppFloatButtonProps) {
  const capacityKeyboardHeight = useSelector((state: RootState) => state.capacityKeyboardHeight);

  return (
    <>
      <div
        className={` ${styles.divBackGround} ${!isStatic ? styles.confirmBtn : ""} ${
          !isStatic && capacityKeyboardHeight !== 0 ? styles.staticBtn : ""
        }`}
      >
        <div className="page-spacer-30" />

        <div className={styles.btnWrapper}>
          {isSecond ? (
            <AppSecondaryButton
              disabled={disabled}
              onClick={onClick}
              full={true}
              transparent={true}
            >
              {label}
            </AppSecondaryButton>
          ) : (
            <AppPrimaryButton disabled={disabled} onClick={onClick} full={true}>
              {label}
            </AppPrimaryButton>
          )}
        </div>

        <AppHomeIndicatorBar />
      </div>
    </>
  );
}
