import { useState, useEffect, useMemo } from "react";
import { IonTabs, IonTabBar, IonRouterOutlet, IonTabButton, IonRoute } from "@ionic/react";
import { SymptomRecordsMain } from "../SymptomRecordsTab";
import { MedicationRecordTab } from "../MedicationRecordTab";
import { DiseaseMain } from "../MainTab";
import { BasicInfo } from "../BasicInfo/basicInfo";
import { MenuTab } from "../MenuTab/menuTab";
import { MedicineInformationDetails } from "../MedicineInformationDetails";
import { PrescriptionInformation } from "../PrescriptionInformation";
import { NotificationSetting } from "../NotificationSetting";

import { navAnimationBuilder } from "../../utils/common";
import { useAppRouter } from "../../hooks/useAppRouter";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/web/reducer";
import { clearTimelineScrollHook } from "src/web/actions/timelineScrollHookAction";

import Lottie from "react-lottie";
import tabIconHomeIn from "../../assets/lotties/js_home_in.json";
import tabIconHomeOut from "../../assets/lotties/js_home_out.json";
import tabIconContentsIn from "../../assets/lotties/js_contents_in.json";
import tabIconContentsOut from "../../assets/lotties/js_contents_out.json";
import tabIconMedicineIn from "../../assets/lotties/js_medicine_in.json";
import tabIconMedicineOut from "../../assets/lotties/js_medicine_out.json";
import tabIconMenuIn from "../../assets/lotties/js_menu_in.json";
import tabIconMenuOut from "../../assets/lotties/js_menu_out.json";
import tabIconReportIn from "../../assets/lotties/js_report_in.json";
import tabIconReportOut from "../../assets/lotties/js_report_out.json";

import styles from "./styles.module.css";
import { useIntl } from "react-intl-phraseapp";

const options = {
  loop: 0,
  autoplay: false,
};

const Home = () => {
  const intl = useIntl();
  const FOOTER_BAR = useMemo(
    () => [
      {
        tab: "main",
        href: "/home/main",
        label: intl.formatMessage({ id: "page.home.footer_menu.home" }),
        optionsIn: {
          ...options,
          animationData: tabIconHomeIn,
        },
        optionsOut: {
          ...options,
          animationData: tabIconHomeOut,
        },
      },
      {
        tab: "medication-record",
        href: "/home/medication-record",
        label: intl.formatMessage({ id: "page.home.footer_menu.medicine_records" }),
        optionsIn: {
          ...options,
          animationData: tabIconMedicineIn,
        },
        optionsOut: {
          ...options,
          animationData: tabIconMedicineOut,
        },
      },
      {
        tab: "symptom-records-main",
        href: "/home/reports",
        label: intl.formatMessage({ id: "page.home.footer_menu.symptom_records" }),
        optionsIn: {
          ...options,
          animationData: tabIconReportIn,
        },
        optionsOut: {
          ...options,
          animationData: tabIconReportOut,
        },
      },
      {
        tab: "basic-info",
        href: "/home/basic-info",
        label: intl.formatMessage({ id: "page.home.footer_menu.basic_info" }),
        optionsIn: {
          ...options,
          animationData: tabIconContentsIn,
        },
        optionsOut: {
          ...options,
          animationData: tabIconContentsOut,
        },
      },
      {
        tab: "menu",
        href: "/home/menu",
        label: intl.formatMessage({ id: "page.home.footer_menu.menu" }),
        optionsIn: {
          ...options,
          animationData: tabIconMenuIn,
        },
        optionsOut: {
          ...options,
          animationData: tabIconMenuOut,
        },
      },
    ],
    [],
  );
  const ionRouter = useAppRouter();

  const dispatch = useDispatch();

  const timelineScrollHook = useSelector((state: RootState) => state.timelineScrollHook);

  const EXTEA_PAGES_WITH_FOOTER = [
    { path: "/home/medicine-information-details", highlightIndex: 1 },
    { path: "/home/prescription-information", highlightIndex: 1 },
  ];

  const [pageNavAnimationIsInProgress, setPageNavAnimationIsInProgress] = useState(false);

  const [tabState, setTabState] = useState({
    currentTab: "",
    active: [false, false, false, false, false],
  });

  const switchTabState = (tabName: string, currentIndex: number) => {
    if (currentIndex >= 0) {
      if (!tabState.active[currentIndex]) {
        for (let i = 0; i < tabState.active.length; i++) {
          if (tabState.active[i]) {
            tabState.active[i] = false;
          } else if (i === currentIndex) {
            tabState.active[i] = true;
          }
        }
        setTabState({
          currentTab: tabName,
          active: tabState.active,
        });
      }
    }
  };

  useEffect(() => {
    const currentRouterInfo = FOOTER_BAR.find(
      (tabInfo) => tabInfo.href === ionRouter.routeInfo.pathname,
    );
    if (currentRouterInfo) {
      const index = FOOTER_BAR.findIndex((footerBar) => footerBar.tab === currentRouterInfo.tab);
      switchTabState(currentRouterInfo.tab, index);
    } else {
      const pageToHighlight: any = EXTEA_PAGES_WITH_FOOTER.find(
        (page) => page.path === ionRouter.routeInfo.pathname,
      );
      if (pageToHighlight) {
        switchTabState(
          FOOTER_BAR[pageToHighlight.highlightIndex].tab,
          pageToHighlight.highlightIndex,
        );
      }
    }
  }, [ionRouter.routeInfo.pathname]);

  return (
    <IonTabs>
      <IonRouterOutlet animation={navAnimationBuilder}>
        <IonRoute exact path="/home/main" render={() => <DiseaseMain />} />
        <IonRoute exact path="/home/medication-record" render={() => <MedicationRecordTab />} />
        <IonRoute exact path="/home/reports" render={() => <SymptomRecordsMain />} />
        <IonRoute exact path="/home/basic-info" render={() => <BasicInfo />} />
        <IonRoute exact path="/home/menu" render={() => <MenuTab />} />

        <IonRoute
          exact
          path="/home/medicine-information-details"
          render={() => <MedicineInformationDetails />}
        />
        <IonRoute
          exact
          path="/home/prescription-information"
          render={() => <PrescriptionInformation />}
        />
        <IonRoute exact path="/home/notification-setting" render={() => <NotificationSetting />} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom" className={`${styles.tab_bar}`}>
        {FOOTER_BAR.map(({ tab, href, label, optionsIn, optionsOut }, index) => (
          <IonTabButton
            key={tab}
            tab={tab}
            className={styles.tab}
            onClick={async () => {
              if (!pageNavAnimationIsInProgress) {
                setPageNavAnimationIsInProgress(true);
                setTimeout(() => {
                  setPageNavAnimationIsInProgress(false);
                }, 500);
                // clear the timeline hook for page: /home/reports
                if (timelineScrollHook) {
                  dispatch(clearTimelineScrollHook());
                }
                ionRouter.push(href, "forward", "replace");
              }
            }}
          >
            {tabState.active[index] ? (
              <Lottie options={optionsIn} height={40} width={40} />
            ) : (
              <Lottie options={optionsOut} height={40} width={40} />
            )}
            <div className="page-spacer-5" />
            <div className={styles.tab_button_text}>{label}</div>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};

export default Home;
