import { useRef } from "react";
import { IonPage, useIonViewWillEnter } from "@ionic/react";
import { AppContent, AppLogo, AppTab, AppLoading } from "../../../components/UiComponents";
import { useSymptomRecordsMainControl, useToggleWeekAndMonth } from "./hooks";
import { getNDaysBeforeOrAfterToday } from "../../../utils/common";
import { RecordTab } from "./components/RecordTab/recordTab";
import { WeekChart } from "./components/WeekChart/weekChart";
import { MonthChart } from "./components/MonthChart/monthChart";
import styles from "./symptomRecordsMain.module.css";
import { useIntl } from "react-intl-phraseapp";

export const RECORD = "record";
export const SCORE = "score";

export function SymptomRecordsMain() {
  const topRef = useRef<any>();
  const intl = useIntl();
  const tabs = [
    { name: RECORD, text: intl.formatMessage({ id: "page.symptom_records_main.tab.record" }) },
    { name: SCORE, text: intl.formatMessage({ id: "page.symptom_records_main.tab.score" }) },
  ];

  const {
    currentDateForRecordTab,
    showRecordOrScoreFlg,
    isLoading,
    currentWeekStartDate,
    setCurrentWeekStartDate,
    currentMonthStartDate,
    setCurrentMonthStartDate,
    switchTab,
    hideChartTooltip,
  } = useSymptomRecordsMainControl();

  const { isWeekChart, toggleWeekAndMonth } = useToggleWeekAndMonth();

  useIonViewWillEnter(() => {
    if (topRef.current.scrollIntoView) {
      topRef.current.scrollIntoView();
    }
  });

  return (
    <>
      <IonPage onClick={hideChartTooltip}>
        <AppLogo />

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-30" ref={topRef} />

            <AppTab
              key={showRecordOrScoreFlg}
              tabs={tabs}
              initialTab={showRecordOrScoreFlg}
              onChange={(type: any) => {
                switchTab(type);
              }}
            />

            <div className="page-spacer-50" />
            {showRecordOrScoreFlg === RECORD ? (
              <RecordTab
                currentDate={currentDateForRecordTab}
                isReadonly={currentDateForRecordTab <= getNDaysBeforeOrAfterToday(-2)}
              />
            ) : isWeekChart ? (
              <WeekChart
                currentWeekStartDate={currentWeekStartDate}
                setCurrentWeekStartDate={setCurrentWeekStartDate}
                toggleWeekAndMonth={toggleWeekAndMonth}
                switchTab={switchTab}
              />
            ) : (
              <MonthChart
                currentMonthStartDate={currentMonthStartDate}
                setCurrentMonthStartDate={setCurrentMonthStartDate}
                toggleWeekAndMonth={toggleWeekAndMonth}
              />
            )}

            <div className="page-spacer-60" />
          </div>
        </AppContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
