import { IonLabel } from "@ionic/react";
import styles from "./advicePanel.module.css";

interface AdvicePanelProps {
  contents: string;
}

export function AdvicePanel({ contents }: AdvicePanelProps) {
  return (
    <div className={styles.root}>
      <IonLabel className="p-smallbody">{contents}</IonLabel>
    </div>
  );
}
