import { actionConst } from "../constants";

import { DailyAlarmRedirectType } from "../types";

export const updateDailyAlarmRedirect = (alarm: DailyAlarmRedirectType) => {
  return {
    type: actionConst.UPDATE_DAILY_ALARM_REDIRECT,
    state: alarm,
  };
};

export const clearDailyAlarmRedirect = () => {
  return {
    type: actionConst.CLEAR_DAILY_ALARM_REDIRECT,
  };
};
