import { useState } from "react";
import checkmarkBlackIcon from "../../../assets/svgs/icons/icon_check.svg";
import styles from "./styles.module.css";

interface AppPageSelectorProps {
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

let pageSelectorTimeout: any = null;

export function AppPageSelector({ checked, onClick, disabled }: AppPageSelectorProps) {
  const [status, setStatus] = useState({
    checking: false,
    // unchecking: false,
  });

  const preClick = () => {
    if (!disabled) {
      clearTimeout(pageSelectorTimeout);
      if (checked) {
        setStatus({
          checking: false,
          // unchecking: true,
        });
      } else {
        setStatus({
          checking: true,
          // unchecking: false,
        });
      }
      pageSelectorTimeout = setTimeout(() => {
        setStatus({
          checking: false,
          // unchecking: false,
        });
      }, 600);
      onClick && onClick();
    }
  };

  return (
    <div
      className={`${styles.root} 
    ${
      status.checking
        ? styles.checking
        : // : status.unchecking
        // ? styles.unchecking
        checked
        ? styles.checked
        : ""
    }`}
      onClick={preClick}
    >
      <div className={styles.text}>1/4</div>
      <div className={styles.circle}>
        <img src={checkmarkBlackIcon} alt="" className={`${styles.checkedImg}`} />
      </div>
    </div>
  );
}
