import { DEVICE_CATEGORY } from "src/web/constants";
import { useIntl } from "react-intl-phraseapp";
import { ModalTitleWithDesc } from "../../UiComponents/organisms";
import { ModalWrapper } from "./ModalWrapper";
import { AppTextLink } from "../../UiComponents";
import styles from "../styles.module.css";
import omronConnectingImg from "../../../assets/images/omron_connecting.png";

export function DeviceConnecting({
  isOpen,
  deviceCategory,
  handleCancel,
}: {
  isOpen: boolean;
  deviceCategory: string;
  handleCancel: () => void;
}) {
  const intl = useIntl();

  return (
    <ModalWrapper isOpen={isOpen} screenHeight={500} handleCancel={handleCancel}>
      <div className="page-spacer-45" />

      <ModalTitleWithDesc
        title={`${
          deviceCategory === DEVICE_CATEGORY.BLOOD_PRESSURE
            ? intl.formatMessage({
                id: "components.device_modal.device.blood_pressure.finding.message",
              })
            : intl.formatMessage({
                id: "components.device_modal.device.temperature.finding.message",
              })
        }`}
        desc={`${
          deviceCategory === DEVICE_CATEGORY.BLOOD_PRESSURE
            ? intl.formatMessage({
                id: "components.device_modal.device.blood_pressure.put_close.message",
              })
            : intl.formatMessage({
                id: "components.device_modal.device.temperature.put_close.message",
              })
        }`}
      />

      <div className="page-spacer-50" />

      <div className={styles.deviceImgWrapper}>
        <img src={omronConnectingImg} className={styles.deviceConnectingImg} alt="" />
      </div>

      <div className="page-spacer-60" />

      <AppTextLink hasNoArrow onClick={handleCancel}>
        {intl.formatMessage({ id: "components.device_modal.device_connecting.button.cancel" })}
      </AppTextLink>
    </ModalWrapper>
  );
}
