import { IonLabel } from "@ionic/react";

interface TitleWithDescriptionProps {
  title: string;
  text: string;
}

export function TitleWithDescription({ title, text }: TitleWithDescriptionProps) {
  return (
    <>
      <IonLabel className="p-body gray text-block pre-wrap">{title}</IonLabel>
      <div className="page-spacer-15" />

      <IonLabel className="p-body text-block pre-wrap">{text}</IonLabel>
    </>
  );
}
