import { IonPage } from "@ionic/react";
import { AppContent, AppLogo, AppPrimaryButton } from "../../../components/UiComponents";
import { PageTitleCenter } from "src/web/components/UiComponents/moleclues";
import { useContactCompleteControl } from "./hooks/useContactCompleteControl";
import { useIntl } from "react-intl-phraseapp";

export function ContactComplete() {
  const intl = useIntl();
  const { no, handleFinish } = useContactCompleteControl();

  return (
    <IonPage>
      <AppLogo />

      <AppContent>
        <div className="root-content">
          <div className="page-spacer-45" />

          <PageTitleCenter
            title={intl.formatMessage({ id: "page.menu.inquiry_send_success.title" })}
            subTitle={`${intl.formatMessage({
              id: "page.menu.inquiry_send_success.description.line1",
            })}\n${intl.formatMessage({
              id: "page.menu.inquiry_send_success.description.line2",
            })}${no}`}
          />

          <div className="page-spacer-60" />

          <AppPrimaryButton full onClick={handleFinish}>
            {intl.formatMessage({ id: "page.menu.inquiry_send_success.button.complete" })}
          </AppPrimaryButton>
        </div>
      </AppContent>
    </IonPage>
  );
}
