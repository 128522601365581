import { useEffect, useState } from "react";
import { IonGrid, IonRow, IonCol, IonLabel } from "@ionic/react";
import { AppTabType } from "../../../types";
import styles from "./styles.module.css";

interface AppTabProps {
  tabs: AppTabType[];
  // onChange?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: any;
  initialTab?: string;
}

export function AppTab({ tabs, onChange, initialTab }: AppTabProps) {
  const [currentTab, setCurrentTab] = useState(initialTab ? initialTab : tabs[0].name);

  const switchTab = (tabName: string) => {
    setCurrentTab(tabName);
  };

  const handleOnChange = () => {
    onChange && onChange(currentTab);
  };

  useEffect(() => {
    handleOnChange();
  }, [currentTab]);

  return (
    <IonGrid className={styles.tabWrapper}>
      <IonRow>
        {tabs.map((tab: AppTabType, index: number) => (
          <IonCol
            key={tab.name}
            className={`${styles.tabItem} ${
              (currentTab === tab.name || (index === 0 && currentTab === "")) && styles.active
            }`}
            onClick={() => switchTab(tab.name)}
          >
            <IonLabel className="p-header4">{tab.text}</IonLabel>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
}
