import {
  PrescriptionInfoType,
  getDateStrByDate
} from "src/web/utils/common";
import {
  useTextConstants
} from "src/web/constants/hooks";

export interface CsvResult {
  result: boolean;
  hasFinish: boolean;
  errorMsg: string;
}

export function useJaHisProcess() {
  const {
    MEDICINE_INFO_SHAPE_CODE_OPTIONS,
    MEDICINE_QUANTITY_UNIT,
    MEDICINE_TIMING_UNIT_OPTIONS
  } = useTextConstants();
  const warekiToYear = (reki: string, year: number) => {
    if (reki == "R" && year > 0) {
      // 令和
      return year + 2018;
    } else if (reki == "H" && year > 0 && year <= 32) {
      // 平成
      return year + 1988;
    } else if (reki == "S" && year > 0 && year <= 64) {
      // 昭和
      return year + 1925;
    } else if (reki == "T" && year > 0 && year <= 15) {
      // 大正
      return year + 1911;
    } else if (reki == "M" && year > 0 && year <= 45) {
      // 明治
      return year + 1867;
    } else {
      return 0;
    }
  };

  /*
    薬情報
   薬の名前：No.201 薬品レコード / 薬品名称 
   剤形：No.301 用法レコード / 剤形コード→内容を選択
   1回あたりの服用量：No.201 薬品レコード / 用量、単位名※頓服のみ
   調剤数量：No.301 用法レコード / 調剤数量、調剤単位
   診療科：No.55 処方-医師レコード / 診療科名
   処方医師名：No.55 処方-医師レコード / 医師名
   服用メモ：No.301 用法レコード / 用法名称
       No.311 用法補足レコード / 用法補足情報
       No.391 処方服用注意レコード / 内容
         No.281 薬品補足レコード / 薬品補足情報
       No.291 薬品服用注意レコード / 内容 　　　　　　→複数項目を表示する際は項目毎に1行あけて配置する
   ※複数の薬品情報に1つの用法情報が紐づいている場合には、薬品毎に用法情報をそれぞれ記載する(同じ内容)
   病院・薬局情報　調剤日：No.5 調剤等年月日レコード / 調剤等年月日
   薬局名：No.11 調剤ー医療機関等レコード / 医療機関等名称(医療機関または薬局名称)
   担当薬剤師名：No.15 調剤-医師・薬剤師レコード / 医師・薬剤師氏名
   病院名：No.51 処方-医療機関レコード / 医療機関名称
  その他　服用上の注意等：No.391 服用注意レコード / 内容
           No.411 医療機関等提供情報レコード / 内容
  */
  class JaHisProcess {
    // 改行
    // readonly NEWLINE = "\n";
    // 区切り文字
    readonly DELIMITER = ",";
    // 開始ヘッダー
    readonly HEAD: string = "JAHISTC";
    // 患者情報
    readonly FORMAT: { [name: number]: number } = {
      // 患者情報レコード
      1: 10,
      // 患者特記レコード
      2: 3,
      // 一般用医薬品服用レコード
      3: 4,
      // 手帳メモレコード
      4: 3,
      // 調剤等年月日レコード
      5: 2,
      // 調剤－医療機関等レコード
      11: 8,
      // 調剤－医師・薬剤師レコード
      15: 3,
      // 処方－医療機関レコード
      51: 5,
      // 処方－医師レコード
      55: 3,
      // 薬品レコード
      201: 7,
      // 薬品補足レコード
      281: 3,
      // 薬品服用注意レコード
      291: 3,
      // 用法レコード
      301: 8,
      // 用法補足レコード
      311: 3,
      // 処方服用注意レコード
      391: 3,
      // 服用注意レコード
      401: 2,
      // 医療機関等提供情報レコード
      411: 3,
      // 残薬確認レコード
      421: 2,
      // 備考レコード
      501: 2,
      // 患者等記入レコード
      601: 2,
      // かかりつけ薬剤師レコード
      701: 6,
      // 分割制御レコード
      911: 3,
    };
    
    public getRestCnt(): number[] {
      // タイプ1以外
      if (this.type !== 1) {
        return [];
      }
    
      // 部数を返却する
      let ret: number[] = [];
      for (let i = 0; i < this.csvCnt; i++) {
        // データ取得できない場合
        if (!this.read_csv[i]) {
          ret.push(i + 1);
        }
      }
    
      return ret;
    }
    
    // 文字列
    private read_csv: string[][] = [];
    // type 1 最後分割内容がある 2最後分割内容がない
    private type: 0 | 1 | 2 = 0;
    // csvの行数
    private csvCnt: number = 0;
    // type 1のデータid
    private dataId: string = "";
    
    public getType(): 0 | 1 | 2 {
      return this.type;
    }
    
    // 全部の部数を返却する
    public getReadCnt(): number[] {
      // タイプ1以外
      if (this.type !== 1) {
        return [this.read_csv.length, -1];
      }
      return [this.csvCnt - this.getRestCnt().length, this.csvCnt];
    }
    
    public clearData(): void {
      // データ初期化する
      this.read_csv = [];
      this.type = 0;
      this.csvCnt = 0;
      this.dataId = "";
    }
    
    private setHeadData(currentData: PrescriptionInfoType, lineStr: string): void {
      let lineData = lineStr.split(this.DELIMITER);
      let key = parseInt(lineData[0]);
      switch (key) {
        case 5:
          // 調剤日: 和暦転換
          let dispensing_date: Date | undefined = undefined;
          if (lineData[1].length === 8) {
            dispensing_date = new Date(
              parseInt(lineData[1].substring(0, 4)),
              parseInt(lineData[1].substring(4, 6)),
              parseInt(lineData[1].substring(6, 8)),
            );
          } else {
            const year = warekiToYear(lineData[1].charAt(0), parseInt(lineData[1].substring(1, 3)));
            dispensing_date = year
              ? new Date(
                  year,
                  parseInt(lineData[1].substring(3, 5)),
                  parseInt(lineData[1].substring(5, 7)),
                )
              : undefined;
          }
          currentData.dispensing_date = getDateStrByDate(dispensing_date);
          break;
        // 薬局名
        case 11:
          currentData.pharmacy = lineData[1];
          break;
        // 薬剤師
        case 15:
          currentData.pharmacist = lineData[1];
          break;
        // 処方－医療機関
        case 51:
          currentData.hospital = lineData[1];
          break;
        // その他　服用上の注意等
        case 391:
        case 411:
          currentData.notes = currentData.notes
            ? `${currentData.notes}，${lineData[2]}`
            : lineData[2];
          break;
      }
    }
    
    public getInfo(): PrescriptionInfoType[] {
      let basicReg = /^5,|11,|15,|51,|391,|411,/;
      // let mdReg = /^55,|201,|281,|291,|301,|311,|391,/;
      let medicineDetails: any = {};
      let all_csvLine: string[] = [];
      let currentMedicineGroupNo: string = "";
    
      let indexPrescription = -1;
      let countPrescription = 0;
      let countClinicalDepartment = 0;
    
      // リストデータに合併する
      for (let i = 0; i < this.read_csv.length; i++) {
        all_csvLine = all_csvLine.concat(this.read_csv[i]);
      }
    
      all_csvLine.forEach((data) => {
        // check if is "例9:薬局から複数調剤日をまとめて出力"
        if (data.startsWith("5,")) {
          countPrescription += 1;
        }
        // check if is "例4:薬局で出力(複数診療科での出力の場合)"
        if (data.startsWith("55,")) {
          countClinicalDepartment += 1;
        }
      });
    
      let ret: PrescriptionInfoType[] = [];
      Array.from(Array(countPrescription)).forEach(() => {
        ret.push({ medicines: [] });
      });
    
      for (let i = 0; i < all_csvLine.length; i++) {
        let lineData = all_csvLine[i];
        let lineRecord = lineData.split(this.DELIMITER);
        let key = parseInt(lineRecord[0]);
    
        // only output the fisrt prescription if multiple prescription records existed
        // if (key < lastLineDataKey && key < 201) {
        //   break;
        // }
    
        // track every prescription
        if (lineData.startsWith("5,")) {
          indexPrescription += 1;
          // if is another prescription, reset the tmp data
          if (parseInt(all_csvLine[i - 1].split(this.DELIMITER)[0]) > 5) {
            medicineDetails = {};
          }
        }
    
        if (basicReg.test(lineData)) {
          this.setHeadData(ret[indexPrescription], lineData);
        }
    
        if (key !== 201 && key !== 281 && key !== 291) {
          medicineDetails[key] = lineData.split(this.DELIMITER);
        }
    
        // if current record is a 201 medicine record, append medicine record
        if (key === 201) {
          currentMedicineGroupNo = lineRecord[1];
          ret[indexPrescription].medicines.push({
            rpNo: lineRecord[1],
            m_name: lineRecord[2],
            once_quantity: lineRecord[3] ? parseInt(lineRecord[3]) : undefined,
            once_quantity_unit: lineRecord[4] ? lineRecord[4] : undefined,
          });
          ret[indexPrescription].medicineNames = ret[indexPrescription].medicineNames
            ? `${ret[indexPrescription].medicineNames} / ${lineRecord[2]}`
            : lineRecord[2];
        } else if (key === 281 || key === 291) {
          ret[indexPrescription].medicines[ret[indexPrescription].medicines.length - 1][key] =
            lineRecord[2];
        } else if (
          /*
          refact the medicine details information
          set the medicine details, if:
          1. current record is the last record of the csv
          2. current record is the last record of the medicine group
          3. a new prescription is to start
        */
          key > 201 &&
          (!all_csvLine[i + 1] ||
            (all_csvLine[i + 1] && all_csvLine[i + 1].startsWith("201,")) ||
            (all_csvLine[i + 1] && parseInt(all_csvLine[i + 1].split(this.DELIMITER)[0]) < 201))
        ) {
          ret[indexPrescription].medicines = ret[indexPrescription].medicines.map((med) => {
            if (med.rpNo === currentMedicineGroupNo) {
              if (medicineDetails["301"]) {
                const isSharpCodeIsOneShotMedicine =
                  parseInt(medicineDetails["301"][5]) ===
                  parseInt(MEDICINE_INFO_SHAPE_CODE_OPTIONS[2].value);
                const dispensing_quantity_unit = MEDICINE_QUANTITY_UNIT.find(
                  (unit) => unit.text === medicineDetails["301"][4],
                );
                const once_quantity_unit =
                  isSharpCodeIsOneShotMedicine && med.once_quantity_unit
                    ? MEDICINE_TIMING_UNIT_OPTIONS.find(
                        (unit) => unit.text === med.once_quantity_unit,
                      )
                    : undefined;
                med = {
                  ...med,
                  m_name:
                    countClinicalDepartment > 1 && countPrescription <= 1
                      ? `${med.m_name}：${
                          medicineDetails["55"]
                            ? `${medicineDetails["55"][2]}・${medicineDetails["55"][1]}`
                            : ""
                        }`
                      : med.m_name,
                  m_shape_code: medicineDetails["301"][5].padStart(2, "0"),
                  dispensing_quantity: parseInt(medicineDetails["301"][3]),
                  dispensing_quantity_unit: dispensing_quantity_unit
                    ? dispensing_quantity_unit.value
                    : undefined,
                  clinical_department: medicineDetails["55"] ? medicineDetails["55"][2] : undefined,
                  prescribing_doctor: medicineDetails["55"] ? medicineDetails["55"][1] : undefined,
                  memos: `${medicineDetails["301"][2] ? `${medicineDetails["301"][2]}\n` : ""}${
                    medicineDetails["311"] && medicineDetails["311"][2]
                      ? `${medicineDetails["311"][2]}\n`
                      : ""
                  }${
                    medicineDetails["391"] && medicineDetails["391"][2]
                      ? `${medicineDetails["391"][2]}\n`
                      : ""
                  }${med["281"] ? `${med["281"]}\n` : ""}${med["291"] ? `${med["291"]}` : ""}`,
                  once_quantity_unit: once_quantity_unit
                    ? once_quantity_unit.value
                    : isSharpCodeIsOneShotMedicine && med.once_quantity_unit
                    ? MEDICINE_TIMING_UNIT_OPTIONS[14].value
                    : undefined,
                };
                if (!isSharpCodeIsOneShotMedicine) {
                  delete med.once_quantity;
                  delete med.once_quantity_unit;
                }
                delete med[281];
                delete med[291];
              }
              return med;
            } else {
              return med;
            }
          });
        }
      }
      // console.info(`ret ->`, ret)
      return ret;
    }
    
    private checkLastLine(line: string, newLine: string): boolean {
      // 最後の完了は改行
      if (line.endsWith(newLine)) {
        return true;
      }
    
      let key = parseInt(line);
      if (key in this.FORMAT) {
        // データのサイズは同じ
        if (this.FORMAT[key] + 1 === line.split(this.DELIMITER).length) {
          return true;
        }
      }
    
      return false;
    }
    
    public readCsvData(lineStr: string, newLine: string): CsvResult {
      let ret: CsvResult = {
        result: false,
        hasFinish: false,
        errorMsg: "",
      };
      // データ存在チェック
      if (!lineStr) {
        ret.errorMsg = "no data";
        return ret;
      }
    
      // データを分割する
      let lineData = lineStr.split(newLine);
      // 初行データを取得する
      let firstLine = lineData[0];
      if (!firstLine) {
        ret.errorMsg = "no data";
        return ret;
      }
    
      let lastLineDelete = false;
    
      let isDeleteLast = false;
      // 最後の一行内容を取得する
      let lastLine = lineData[lineData.length - 1];
      if (!lastLine) {
        isDeleteLast = true;
        // 空行を削除する
        lineData = lineData.slice(0, lineData.length - 1);
        // 最後の行データを設定する
        lastLine = lineData[lineData.length - 1];
      }
    
      // 初回読み込みの場合
      if (this.read_csv.length === 0) {
        // ヘッダーデータをチェックする
        if (!firstLine.startsWith(this.HEAD)) {
          ret.errorMsg = `wrong head [${firstLine}] [${!firstLine.startsWith(this.HEAD)}]`;
          return ret;
        }
    
        // 最後一行の内容を確認する
        if (lastLine.startsWith("911,")) {
          // 改行情報がある場合
          // タイプ
          this.type = 1;
    
          let lineInfo = lastLine.split(this.DELIMITER);
          let dataId = lineInfo[1];
          // id チェックします。
          if (!dataId) {
            ret.errorMsg = "wrong data id";
            return ret;
          }
          // pageの分数を取得する
          let csvCnt = parseInt(lineInfo[2]);
    
          if (!csvCnt || csvCnt <= 0) {
            ret.errorMsg = "wrong page count";
            return ret;
          }
          // pageのindexを取得する
          let pageIndex = parseInt(lineInfo[3]);
          if (!pageIndex || pageIndex <= 0) {
            ret.errorMsg = "wrong page index";
            return ret;
          }
          // idとcsv件数を設定する
          this.dataId = dataId;
          this.csvCnt = csvCnt;
    
          if (csvCnt === 1) {
            // 一部だけの場合
            ret.hasFinish = true;
          }
    
          // データを設定する
          this.read_csv[pageIndex - 1] = lineData;
          ret.result = true;
          return ret;
        } else {
          // タイプ
          this.type = 2;
    
          ret.hasFinish = isDeleteLast || this.checkLastLine(lastLine, newLine);
    
          // データを設定する
          this.read_csv[0] = lineData;
          ret.result = true;
          lastLineDelete = isDeleteLast;
          return ret;
        }
      }
    
      // 第二行以降の場合
      // 911がある場合
      if (this.type === 1) {
        // 最後一行の内容を確認する
        if (!lastLine.startsWith("911,")) {
          ret.errorMsg = "wrong data format";
          return ret;
        }
    
        let lineInfo = lastLine.split(this.DELIMITER);
        let dataId = lineInfo[1];
        // id チェックします。
        if (dataId !== this.dataId) {
          ret.errorMsg = "wrong data id";
          return ret;
        }
    
        // pageのindexを取得する
        let pageIndex = parseInt(lineInfo[3]);
        if (!pageIndex || pageIndex <= 0) {
          ret.errorMsg = "wrong page index";
          return ret;
        }
    
        // if (firstLine.startsWith(this.HEAD)) {
        //   // 初行のデータを削除する
        //   lineData.pop();
        // }
    
        // データを設定する
        this.read_csv[pageIndex - 1] = lineData;
        ret.hasFinish = this.getRestCnt().length === 0;
        ret.result = true;
        return ret;
      }
    
      // 普通のcsv
      // 前回の内容と次の内容とチェックする
      let lastData = this.read_csv[this.read_csv.length - 1];
      // 最後の一行を取得する
      let comLastLine = lastData[lastData.length - 1];
      // 前回最後は改行終了ではない
      if (!lastLineDelete) {
        let combineLastLine = comLastLine + firstLine;
        let dataLine = combineLastLine.split(this.DELIMITER);
        let lineType = parseInt(dataLine[0]);
        if (!lineType) {
          ret.errorMsg = `wrong line type [${dataLine[0]}]`;
          return ret;
        }
    
        if (lineType in this.FORMAT) {
          let dataLength = this.FORMAT[lineType] + 1;
          if (dataLength !== dataLine.length) {
            ret.errorMsg = `wrong line format [${dataLine[0]}] [${dataLength}] [${dataLine.length}]`;
            return ret;
          }
    
          // 前回データの最終行を更新する
          lastData[lastData.length - 1] = combineLastLine;
          lineData.pop();
        } else {
          ret.errorMsg = `wrong line type [${dataLine[0]}]`;
          return ret;
        }
      }
    
      ret.hasFinish = isDeleteLast || this.checkLastLine(lastLine, newLine);
    
      // 内容を設置する
      this.read_csv.push(lineData);
      lastLineDelete = isDeleteLast;
      ret.result = true;
      return ret;
    }
  }
  return {
    JaHisProcess
  }
};
    