import { useTextConstants } from "src/web/constants/hooks";
import { AnswerType, QuestionAnswerType } from "src/web/types";
import { useIntl } from "react-intl-phraseapp";

export function useCategoryDataControl() {
  const intl = useIntl();
  const { NOT_INPUTED } = useTextConstants();
  const getRank = (data: AnswerType, dataKey: string): string => {
    if (!data?.summary_score[`${dataKey}_r`]) {
      return "-";
    } else {
      return data?.summary_score[`${dataKey}_r`] === ""
        ? "-"
        : `${data?.summary_score[`${dataKey}_r`]}`;
    }
  };

  const getQuestions = (data: AnswerType, dataKey: string): QuestionAnswerType[] => {
    return dataKey !== "c" ? (data.check_content_display[dataKey] as QuestionAnswerType[]) : [];
  };

  const getAnswer = (qa: QuestionAnswerType): string[] => {
    return qa.answer === null
      ? [NOT_INPUTED]
      : qa.answer
      ? qa.answer.split(",")
      : [intl.formatMessage({ id: "page.symptom_records_main.answer.no_notes" })];
  };

  return {
    getRank,
    getQuestions,
    getAnswer,
  };
}
