import { actionConst } from "../constants";
import { LoadingDisplay } from "../types";

const initialState = false;

interface Action {
  type: actionConst;
  state: boolean;
};

export default function loadingDisplayReducer(state = initialState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_LOADING_DISPLAY:
      return actions.state;
    case actionConst.CLEAR_LOADING_DISPLAY:
      return false;
    default:
      return state;
  }
};

