import { Http, HttpOptions, HttpParams, HttpResponse } from "@capacitor-community/http";
import { Capacitor } from "@capacitor/core";
import { Storage } from "@capacitor/storage";
import { Network } from "@capacitor/network";
import { isExpired } from "react-jwt";
import { storeRequest } from "../offline/offlineManager";
import { auth } from "../../firebase";
import {
  LocalStorageKey,
  IS_LOCAL_SAVE,
  PENDING_REQ_SYNCED_STATUS,
  STORAGE_REQ_KEY,
  DEFAULT_LANGUAGE,
} from "../../constants";
import { PspHttpAuthError, PspHttpError } from "../../errors";

// 環境変数に設定しました。
const prefix_url =
  process.env["REACT_APP_WEB_API_PREFIX"] ?? (process.env["react_app_web_api_prefix"] as string);
// is web call
const isWebCall = Capacitor.getPlatform() === "web";

export const getHttpUserHead = async () => {
  let additional_head: { [key: string]: string } = {};
  additional_head["uuid"] = (await Storage.get({ key: LocalStorageKey.LOGIN_UID })).value ?? "";
  additional_head["psp-language"] = DEFAULT_LANGUAGE;

  const tkn = (await Storage.get({ key: LocalStorageKey.LOGIN_ACCESS_TOKEN })).value ?? "";

  if (tkn && isExpired(tkn)) {
    try {
      const newTkn = await auth.currentUser?.getIdToken(true);
      additional_head["authorization"] = newTkn || tkn;
      await Storage.set({
        key: LocalStorageKey.LOGIN_ACCESS_TOKEN,
        value: newTkn || tkn,
      });
    } catch (error) {
      additional_head["authorization"] = tkn;
    }
  } else {
    additional_head["authorization"] = tkn;
  }

  return additional_head;
};

const checkResponse = (response: HttpResponse) => {
  if (response.status !== 200 && response.status !== 201) {
    // トークン問題
    if (response.status === 401) {
      // TODO LOGIN ?
      throw new PspHttpAuthError();
    }

    // TODO Firebase Analysis
    throw new PspHttpError(response.status);
  }
};

export const getAlexaData = async (url: string, alexa_auth: string) => {
  // get data from api
  const options: HttpOptions = {
    url: (isWebCall ? "" : prefix_url) + url,
    headers: {
      ...(await getHttpUserHead()),
      "content-type": "application/json",
      "alexa-authorization": alexa_auth,
    },
  };

  const response = await Http.get(options);

  checkResponse(response);

  return response;
};

export const postAlexaData = async (url: string, alexa_auth: string, data: any) => {
  // get data from api
  const options: HttpOptions = {
    url: (isWebCall ? "" : prefix_url) + url,
    headers: {
      ...(await getHttpUserHead()),
      "content-type": "application/json",
      "alexa-authorization": alexa_auth,
    },
    data,
  };

  const response = await Http.post(options);
  checkResponse(response);

  return response;
};

export const deleteAlexaData = async (url: string, alexa_auth: string) => {
  // delete data from api
  // get data from api
  const options: HttpOptions = {
    url: (isWebCall ? "" : prefix_url) + url,
    headers: {
      ...(await getHttpUserHead()),
      "content-type": "application/json",
      "alexa-authorization": alexa_auth,
    },
  };

  const response = await Http.post(options);

  checkResponse(response);

  return response;
};

// export const getData = async (key: string, url: string, params: HttpParams) => {
//   // TODO get data from local storage

//   // get data from api
//   const options: HttpOptions = {
//     url: (isWebCall ? "" : prefix_url) + url,
//     params: params,
//     headers: await getHttpUserHead(),
//   };

//   let response = await Http.get(options);

//   if (response.status !== 200 && response.status !== 201) {
//     // error msg
//     throw {
//       status: response.status,
//       message: response.data,
//     };
//   }

//   return response.data;
// };

// export const postData = async (key: string, url: string, data: any) => {
//   const options = {
//     url: (isWebCall ? "" : prefix_url) + url,
//     headers: { ...(await getHttpUserHead()), "content-type": "application/json" },
//     data,
//   };
//   const response = await Http.post(options);
//   if (response.status !== 200 && response.status !== 201) {
//     // error msg
//     throw {
//       status: response.status,
//       message: response.data,
//     };
//   }

//   return response.data;
// };

export const getDataFromApiOrLocal = async (url: string, params?: HttpParams) => {
  const networkStatus = await Network.getStatus();

  if (networkStatus.connected) {
    const headers = await getHttpUserHead();
    // get data from api
    const options = {
      url: (isWebCall ? "" : prefix_url) + url,
      params,
      headers,
    };
    const response = await Http.get(options);
    if (response.status !== 200 && response.status !== 201) {
      // error msg
      throw {
        status: response.status,
        message: response.data,
      };
    } else {
      setLocalData(`${url}${JSON.stringify(params)}`, response.data);
      return response.data;
    }
  } else {
    // get data from storage
    return getLocalData(`${url}${JSON.stringify(params)}`);
  }
};

export const getDataForceFromLocal = async (
  url: string,
  params: HttpParams,
  isForceLocal: Boolean,
) => {
  const networkStatus = await Network.getStatus();

  if (!isForceLocal && networkStatus.connected) {
    // get data from api
    const options = {
      url: (isWebCall ? "" : prefix_url) + url,
      params,
      headers: await getHttpUserHead(),
    };
    const response = await Http.get(options);
    if (response.status !== 200 && response.status !== 201) {
      // error msg
      throw {
        status: response.status,
        message: response.data,
      };
    } else {
      setLocalData(`${url}${JSON.stringify(params)}`, response.data);
      return response.data;
    }
  } else {
    // get data from storage
    return getLocalData(`${url}${JSON.stringify(params)}`);
  }
};

export const postDataForFetch = async (url: string, data: any) => {
  const networkStatus = await Network.getStatus();

  if (networkStatus.connected) {
    const options = {
      url: (isWebCall ? "" : prefix_url) + url,
      headers: { ...(await getHttpUserHead()), "content-type": "application/json" },
      data,
    };
    const response = await Http.post(options);
    if (response.status !== 200 && response.status !== 201) {
      // error msg
      throw {
        status: response.status,
        message: response.data,
      };
    } else {
      setLocalData(`${url}${JSON.stringify(data)}`, response.data);
      return response.data;
    }
  } else {
    // get data from storage
    return getLocalData(`${url}${JSON.stringify(data)}`);
  }
};

export const postDataForSave = async (url: string, data: any) => {
  const networkStatus = await Network.getStatus();

  if (networkStatus.connected) {
    const options = {
      url: (isWebCall ? "" : prefix_url) + url,
      headers: {
        "content-type": "application/json",
        ...(await getHttpUserHead()),
      },
      data,
    };
    const response = await Http.post(options);
    if (response.status !== 200 && response.status !== 201) {
      // error msg
      throw {
        status: response.status,
        message: response.data,
      };
    } else {
      return response.data;
    }
  } else {
    await storeRequest(url, "POST", data);
    return { [IS_LOCAL_SAVE]: true };
  }
};

export const patchDataForSave = async (url: string, data: any) => {
  const networkStatus = await Network.getStatus();

  if (networkStatus.connected) {
    const options = {
      url: (isWebCall ? "" : prefix_url) + url,
      headers: {
        "content-type": "application/json",
        ...(await getHttpUserHead()),
      },
      data,
    };
    const response = await Http.patch(options);
    if (response.status !== 200 && response.status !== 201) {
      // error msg
      throw {
        status: response.status,
        message: response.data,
      };
    } else {
      return response.data;
    }
  } else {
    await storeRequest(url, "PATCH", data);
    return { [IS_LOCAL_SAVE]: true };
  }
};

export const putDataForSave = async (url: string, data: any) => {
  const networkStatus = await Network.getStatus();

  if (networkStatus.connected) {
    const options = {
      url: (isWebCall ? "" : prefix_url) + url,
      headers: {
        "content-type": "application/json",
        ...(await getHttpUserHead()),
      },
      data,
    };
    const response = await Http.put(options);
    if (response.status !== 200 && response.status !== 201) {
      // error msg
      throw {
        status: response.status,
        message: response.data,
      };
    } else {
      return response.data;
    }
  } else {
    await storeRequest(url, "PUT", data);
    return { [IS_LOCAL_SAVE]: true };
  }
};

export const deleteData = async (url: string, data?: any) => {
  const options = {
    url: (isWebCall ? "" : prefix_url) + url,
    headers: {
      "content-type": "application/json",
      ...(await getHttpUserHead()),
    },
    data,
  };
  const response = await Http.del(options);
  if (response.status !== 200 && response.status !== 201) {
    // error msg
    throw {
      status: response.status,
      message: response.data,
    };
  } else {
    return response.data;
  }
};

export const batchPostData = async () => {
  const networkStatus = await Network.getStatus();
  if (networkStatus.connected) {
    const reqs = await getLocalData(STORAGE_REQ_KEY);
    let failedReqs = [];
    if (reqs) {
      for (const req of reqs) {
        const response = await Http.post(req);
        if (response.status !== 200 && response.status !== 201) {
          failedReqs.push(req);
        }
      }
      if (failedReqs.length === reqs.length) {
        // all pending requests failed to synced to API
        return PENDING_REQ_SYNCED_STATUS.ALL_FAILED;
      } else if (failedReqs.length > 0) {
        // parital requests synced to API successfully
        setLocalData(STORAGE_REQ_KEY, failedReqs);
        return PENDING_REQ_SYNCED_STATUS.PARTIAL_SUCCESS;
      } else {
        // all pending requests synced to API successfully
        removeLocalData(STORAGE_REQ_KEY);
        return PENDING_REQ_SYNCED_STATUS.ALL_SUCCESS;
      }
    }
  } else {
    return PENDING_REQ_SYNCED_STATUS.NOT_TRIGGERED; // is still offline
  }
};

// set to storage
export const setLocalData = async (key: string, data: any) => {
  return await Storage.set({
    key,
    value:
      typeof data === "string" || data instanceof String ? (data as string) : JSON.stringify(data),
  });
};

// get from storage
export const getLocalData = async (key: string) => {
  const { value } = await Storage.get({ key });
  let returnValue: any;
  try {
    returnValue = JSON.parse(value ?? "");
  } catch (e) {
    returnValue = value;
  }
  return returnValue;
};

// remove key value in storage
export const removeLocalData = async (key: string) => {
  await Storage.remove({ key });
};
