import { useState, useEffect } from "react";
import { useIonAlert, useIonViewWillEnter } from "@ionic/react";
import { useDispatch } from "react-redux";
import { useAppRouter } from "../../../../hooks/useAppRouter";
import {
  clearTimelineScrollHook,
  updateTimelineScrollHook,
} from "src/web/actions/timelineScrollHookAction";
import { useSymptomModel, useSymptomRecordsModel } from "../../../../hooks/models";
import { getNow, getToday } from "../../../../utils/common";
import { useQueryString } from "src/web/hooks/useQueryString";
import { SymptomModelType, SymptomModelListType } from "../../DiseaseMainTab/types";
import { PageActionCode, PageCode } from "../../../../types";
import { useIntl } from "react-intl-phraseapp";
import { useCommon } from "src/web/utils/hooks/common";

export function useSymptomEnteringDetailsControl() {
  const intl = useIntl();
  const { convertToDate, confirmNotSave } = useCommon();
  const [presentAlert] = useIonAlert();

  const dispatch = useDispatch();

  const ionRouter = useAppRouter();
  const { parseQs } = useQueryString();

  const params = parseQs(ionRouter?.routeInfo?.search);
  const dateParam = params.date as string;
  const timeParam = params.time as string;

  const {
    isLoadingSymptom,
    fetchSymptomListWithScoreAllActive,
    batchSaveForAllSymptoms,
    deleteSymptomsById,
    duplicatedCheck,
  } = useSymptomModel();

  const { fetchDailyActivityTimeline, fetchSymptomQuestionnaireAnswerByDate } =
    useSymptomRecordsModel();

  const currentDate = dateParam ?? getToday();
  const [currentTime, setCurrentTime] = useState<string>(
    timeParam ? `${parseInt(timeParam.slice(0, 2))}:${timeParam.slice(2)}` : getNow(true),
  );
  const [symptomData, setSymptomData] = useState<SymptomModelType>();
  const [symptomDetails, setSymptomDetails] = useState<SymptomModelType>();
  const [isDeletable, setIsDeletable] = useState(symptomData?.id && !!dateParam && !!timeParam);

  const checkDisableSubmit = (): boolean => {
    return !symptomDetails?.recordDetails.some((item) => item.painScaleValue !== undefined);
  };

  const handleDel = () => {
    if (symptomData?.id) {
      presentAlert({
        header: intl.formatMessage({
          id: "page.symptom_entering_details.hooks.modal.delete.header",
        }),
        mode: "ios",
        buttons: [
          {
            text: intl.formatMessage({
              id: "page.symptom_entering_details.hooks.modal.delete.cancel",
            }),
            role: "cancel",
            handler: () => {},
          },
          {
            text: intl.formatMessage({
              id: "page.symptom_entering_details.hooks.modal.delete.confirm",
            }),
            role: "confirm",
            handler: async () => {
              if (symptomData?.id) {
                try {
                  await deleteSymptomsById(
                    {
                      pageCode: PageCode.T01_1,
                      pageActionCode: PageActionCode.T01_1_A03,
                    },
                    symptomData.id,
                  );
                  // refresh data
                  fetchDailyActivityTimeline(
                    {
                      pageCode: PageCode.T03,
                      pageActionCode: PageActionCode.T03_A01,
                    },
                    { currentDate },
                  );
                  dispatch(clearTimelineScrollHook());
                  ionRouter.goBack();
                } catch (error) {}
              }
            },
          },
        ],
      });
    }
  };

  const handleDuplicatedCheckAndSave = async () => {
    const isDuplicated = await duplicatedCheck(
      {
        registeredDate: currentDate,
        time: currentTime.replace(":", "").padStart(4, "0"),
      },
      symptomData?.id,
    );

    const data = {
      ...symptomDetails,
      time: currentTime.replace(":", "").padStart(4, "0"),
      registeredDate: currentDate,
      isMerge: false,
      id: symptomData?.id && !!dateParam && !!timeParam ? symptomData?.id : undefined,
    };

    const saveAndNavAfterSuccessSave = async () => {
      try {
        await batchSaveForAllSymptoms(
          {
            pageCode: PageCode.T01_1,
            pageActionCode: PageActionCode.T01_1_A02,
          },
          data,
        );
        // refresh timeline data
        fetchDailyActivityTimeline(
          {
            pageCode: PageCode.T03,
            pageActionCode: PageActionCode.T03_A01,
          },
          { currentDate },
        );
        // refresh score calculation
        fetchSymptomQuestionnaireAnswerByDate(
          {
            pageCode: PageCode.T03,
            pageActionCode: PageActionCode.T03_A01,
          },
          { currentDate },
        );
        dispatch(updateTimelineScrollHook(`?time=${data.time}&type=SYMPTOM`));
        if (dateParam && timeParam) {
          ionRouter.goBack();
        } else {
          ionRouter.push("/home/reports");
        }
      } catch (error) {}
    };

    if (isDuplicated) {
      presentAlert({
        header: intl.formatMessage({
          id: "page.symptom_entering_details.hooks.modal.overwrite.header",
        }),
        mode: "ios",
        buttons: [
          {
            text: intl.formatMessage({
              id: "page.symptom_entering_details.hooks.modal.delete.cancel",
            }),
            role: "cancel",
            handler: () => {
              return;
            },
          },
          {
            text: intl.formatMessage({
              id: "page.symptom_entering_details.hooks.modal.delete.confirm",
            }),
            role: "confirm",
            handler: async () => {
              await saveAndNavAfterSuccessSave();
            },
          },
        ],
      });
    } else {
      await saveAndNavAfterSuccessSave();
    }
  };

  const init = async () => {
    const data: SymptomModelType | undefined = await fetchSymptomListWithScoreAllActive(
      {
        pageCode: PageCode.T01_1,
        pageActionCode: PageActionCode.T01_1_A01,
      },
      {
        registeredDate: dateParam,
        time: timeParam,
      },
    );
    if (data) {
      if (!dateParam || !timeParam) {
        data.id = null;
      }
      setSymptomData(data);
    }
  };

  const handleBack = () => {
    if (isLoadingSymptom) return;

    confirmNotSave(
      JSON.stringify(symptomDetails) !== JSON.stringify(symptomData),
      presentAlert,
      ionRouter,
    );
  };

  const handleScoreChange = (score: number, item: SymptomModelListType, data: SymptomModelType) => {
    item.painScaleValue = item.painScaleValue === score ? undefined : score;
    setSymptomDetails({ ...data });
  };

  const handleCommentChange = (text: string) => {
    if (symptomDetails) {
      setSymptomDetails({
        ...symptomDetails,
        comment: text,
      });
    }
  };

  useIonViewWillEnter(() => {
    init();
  }, [timeParam]);

  useEffect(() => {
    setIsDeletable(symptomData?.id && !!dateParam && !!timeParam);

    if (symptomData) {
      if (dateParam && timeParam) {
        setSymptomDetails(JSON.parse(JSON.stringify(symptomData)));
        if (symptomData.time) {
          setCurrentTime(`${parseInt(symptomData.time.slice(0, 2))}:${symptomData.time.slice(2)}`);
        }
      } else {
        // if is from home page, reset comment and painScaleValue for all the symptoms
        const tmpSymptomData: SymptomModelType = JSON.parse(JSON.stringify(symptomData));
        tmpSymptomData.recordDetails.forEach((item) => {
          item.painScaleValue = undefined;
        });
        tmpSymptomData.comment = "";
        setSymptomDetails(tmpSymptomData);
      }
    }
  }, [symptomData]);

  return {
    isLoading: isLoadingSymptom,
    symptomDetails,
    formattedCurrentDateWithDay: convertToDate(currentDate),
    currentTime,
    isDeletable,
    setCurrentTime,
    handleBack,
    handleDel,
    handleScoreChange,
    handleCommentChange,
    checkDisableSubmit,
    handleDuplicatedCheckAndSave,
  };
}
