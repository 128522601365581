import styles from "./styles.module.css";
import { MedicineInfoType } from "src/web/types";
import { AppMedicineImage } from "../../../../components/UiComponents";
import { MEDICINE_TYPE } from "../../../../constants";
import { useIntl } from "react-intl-phraseapp";

export function MedicineCopyItem({ info, imgPath }: { info?: MedicineInfoType; imgPath?: string }) {
  const intl = useIntl();
  return (
    <div className={styles.medicine_item_iconCard}>
      <div className={styles.medicineList}>
        {/* データのイメージ */}
        <div className={styles.medicineDiv}>
          <AppMedicineImage src={imgPath} size="MediumBig" grayBg={true} hideFrame={true} />
        </div>

        <div className={styles.medicineInfo}>
          {/* 横方向中詰め */}
          <div className={`p-body ${styles.medicineTitle}`}>{info?.m_name}</div>
          {/* 値存在の場合表示 */}
          <div className={`p-smallbody ${styles.medicineFrequency}`}>
            {info?.type === MEDICINE_TYPE.PRESCRIPTION
              ? intl.formatMessage({
                  id: "page.medicine_information.medicine_copy_item.prescription.previous_dispensing_date",
                })
              : intl.formatMessage({
                  id: "page.medicine_information.medicine_copy_item.over_the_counter.previous_dispensing_date",
                })}
            {info?.dispensing_date}
          </div>
        </div>
      </div>
    </div>
  );
}
