import { useIonViewDidEnter } from "@ionic/react";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { Storage } from "@capacitor/storage";
import { LocalStorageKey } from "src/web/constants";

export function useWelcomeControl() {
  const iRouter = useAppRouter();

  const goNext = () => {
    iRouter.push("/create-user-set-password", "forward", "push");
  };

  useIonViewDidEnter(() => {
    const clearEmail = async () => {
      await Storage.set({ key: LocalStorageKey.CREATE_USER_EMAIL_ADDRESS, value: "" });
    };
    clearEmail();
  });

  return {
    goNext,
  };
}
