import { actionConst } from "../constants";
import { SymptionSettingDataType } from "../types";

interface Action {
  type: actionConst;
  state: SymptionSettingDataType;
}

const initState = {
  isLoading: false,
  // homeList: [],
  // otherList: [],
};

export default function symptomSettingReducer(state = initState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_SYMPTOM_SETTING:
      return actions.state;
    case actionConst.CLEAR_SYMPTOM_SETTING:
      return initState;
    default:
      return state;
  }
}
