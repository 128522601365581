import { DialogHeadline } from "../../moleclues";

interface ModalTitleWithDescProps {
  title: string;
  desc: string;
}

export function ModalTitleWithDesc({ title, desc }: ModalTitleWithDescProps) {
  return <DialogHeadline header2={title} body={desc} />;
}
