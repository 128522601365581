import { DEFAULT_LANGUAGE } from "src/web/constants";
import { OthersInfoType, SubMenuType } from "src/web/types";
import { getOtherDocumentList } from "src/web/services";

export function useMenuModel() {
  const fetchOtherDocumentForMenu = async () => {
    try {
      const others: OthersInfoType[] = await getOtherDocumentList({
        language: DEFAULT_LANGUAGE,
      });
      if (others) {
        let othersList: SubMenuType[] = [];
        others.forEach((other: OthersInfoType) => {
          othersList.push({
            title: other.title,
            url: `/menu/others/${other.id}`,
          });
        });
        return othersList;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };

  return {
    fetchOtherDocumentForMenu,
  };
}
