import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIonAlert } from "@ionic/react";
import { ContactType } from "src/web/types";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { auth } from "src/web/firebase";
import { RootState } from "src/web/reducer";
import { clearContact, updateContact } from "src/web/actions";
import { useIntl } from "react-intl-phraseapp";

export function useContactControl() {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const [presentAlert] = useIonAlert();
  const dispatch = useDispatch();

  const contactData = useSelector((state: RootState) => state.contact);

  const [formData, setFormData] = useState<ContactType>({
    ...contactData,
    mail: contactData.mail ?? auth.currentUser?.email,
  });

  const handleValueChange = (field: string, value: string) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const isSubmitDisabled = useMemo(
    () => !formData.name || !formData.mail || !formData.title || !formData.content,
    [formData],
  );

  const doSubmit = async () => {
    dispatch(updateContact(formData));
    ionRouter.push("/settings/contacts/confirm");
  };

  const handleBack = async () => {
    if (formData.name || formData.title || formData.content) {
      presentAlert({
        header: intl.formatMessage({ id: "page.menu.inquiry.back_alert.title" }),
        mode: "ios",
        buttons: [
          {
            text: intl.formatMessage({ id: "page.menu.inquiry.back_alert.cancel" }),
            role: "cancel",
            handler: () => {},
          },
          {
            text: intl.formatMessage({ id: "page.menu.inquiry.back_alert.confirm" }),
            role: "confirm",
            handler: async () => {
              dispatch(clearContact());
              ionRouter.goBack();
            },
          },
        ],
      });
    } else {
      dispatch(clearContact());
      ionRouter.goBack();
    }
  };

  const navToFaq = () => {
    ionRouter.push("/settings/faqs");
  };

  useEffect(() => {
    const handleNativeBack = (ev: any) => {
      ev.detail.register(11, () => {
        handleBack();
      });
    };
    document.addEventListener("ionBackButton", handleNativeBack);

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, [formData]);

  return {
    formData,
    isSubmitDisabled,
    handleBack,
    navToFaq,
    handleValueChange,
    doSubmit,
  };
}
