import { IonPage } from "@ionic/react";
import { AppHeader, AppContent, AppHomeIndicatorBar } from "src/web/components/UiComponents";
import { ICONS } from "src/web/constants";
import { MenuType } from "src/web/types";
import { MenuItemGroup } from "src/web/components/UiComponents/organisms";
import { useIntl } from "react-intl-phraseapp";

export function SupportedDevices() {
  const intl = useIntl();
  const machineList: MenuType[] = [
    {
      title: intl.formatMessage({ id: "page.device_connection_machine_list.label.device" }),
      subTitles: [
        {
          title: "オムロン",
          hideIcon: true,
        },
        {
          title: "血圧計 HCR-7800T",
          iconSrc: ICONS.ICON.LINK_OUT,
          event: () =>
            window.open(
              "https://www.healthcare.omron.co.jp/medical/products/HCR-7800T/index.html",
              "_system",
              "location=yes",
            ),
        },
        {
          title: "血圧計 HCR-7501T",
          iconSrc: ICONS.ICON.LINK_OUT,
          event: () =>
            window.open(
              "https://www.healthcare.omron.co.jp/product/hem/hcr-7501t.html",
              "_system",
              "location=yes",
            ),
        },
        {
          title: "血圧計 HCR-750AT",
          iconSrc: ICONS.ICON.LINK_OUT,
          event: () =>
            window.open(
              "https://www.healthcare.omron.co.jp/product/hem/hcr-750at.html",
              "_system",
              "location=yes",
            ),
        },
        {
          title: "体温計 MC-6810T2",
          iconSrc: ICONS.ICON.LINK_OUT,
          event: () =>
            window.open(
              "https://www.healthcare.omron.co.jp/product/mc/mc-6810t2.html",
              "_system",
              "location=yes",
            ),
        },
        {
          title: "体温計 MC-6800B",
          iconSrc: ICONS.ICON.LINK_OUT,
          event: () =>
            window.open(
              "https://www.healthcare.omron.co.jp/product/mc/mc-6800b.html",
              "_system",
              "location=yes",
            ),
        },
        {
          title: "タニタ",
          hideIcon: true,
        },
        {
          title: "血圧計 BP-302",
          iconSrc: ICONS.ICON.LINK_OUT,
          event: () =>
            window.open(
              "http://info.tanita-thl.co.jp/guide_common/guide/setting_bp302.php?ref=kk",
              "_system",
              "location=yes",
            ),
        },
        {
          title: "血圧計 BP-224L",
          iconSrc: ICONS.ICON.LINK_OUT,
          event: () =>
            window.open(
              "https://www.tanita.co.jp/product/bloodpressuremonitor/3221/",
              "_system",
              "location=yes",
            ),
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "page.device_connection_machine_list.label.app" }),
      subTitles: [
        {
          title: intl.formatMessage({ id: "page.device_connection_machine_list.label.ios.app" }),
          iconSrc: ICONS.ICON.LINK_OUT,
        },
      ],
    },
  ];

  return (
    <IonPage>
      <AppHeader />

      <AppContent>
        <div className="root-content">
          {machineList.map((item: MenuType, index: number) => (
            <MenuItemGroup
              pageTitle={
                index === 0
                  ? intl.formatMessage({ id: "page.device_connection_machine_list.header" })
                  : undefined
              }
              menuData={item}
              isGray
              key={index}
            />
          ))}

          <AppHomeIndicatorBar />
        </div>
      </AppContent>
    </IonPage>
  );
}
