import { IonLabel, IonModal } from "@ionic/react";
import { getWindowDimensions } from "src/web/utils/common";
import { AppIcon } from "../../UiComponents";
import { ICONS } from "src/web/constants";
import styles from "../styles.module.css";

export function ModalWrapper({
  isOpen,
  screenHeight,
  title,
  handleCancel,
  children,
}: {
  isOpen: boolean;
  screenHeight?: number;
  title?: string;
  handleCancel: () => void;
  children: any;
}) {
  return (
    <IonModal
      isOpen={isOpen}
      breakpoints={
        screenHeight ? [0, screenHeight / getWindowDimensions().height, 0.98] : [0, 0.98]
      }
      initialBreakpoint={screenHeight ? screenHeight / getWindowDimensions().height : 0.98}
      className={styles.root}
      backdropDismiss={false}
    >
      <div className="root-content">
        {/* 分割 */}
        <div className="page-spacer-45" />

        <div className={styles.headerWrapper}>
          <AppIcon
            icon={ICONS.ICON.ARROW_CIRCLE_BACK}
            className={styles.backBtn}
            onClick={handleCancel}
          />

          {!!title ? (
            <IonLabel className="p-header3 text-center-block">{title}</IonLabel>
          ) : (
            <div className="page-spacer-25" />
          )}
        </div>

        {children}
      </div>
    </IonModal>
  );
}
