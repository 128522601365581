import styles from "./styles.module.css";

import { AppIcon } from "../AppIcon/appIcon";
import { ICONS } from "../../../constants";

interface AppTextLinkProps {
  small?: boolean;
  underLine?: boolean;
  className?: string;
  children?: any;
  onClick?: () => void;
  hasNoArrow?: boolean;
  align?: "center" | "left";
}

export function AppTextLink({
  small,
  underLine,
  className,
  onClick,
  children,
  hasNoArrow,
  align = "center",
}: AppTextLinkProps) {
  return (
    <div
      className={`${align === "left" ? "flex-row-start" : styles.centerText} ${
        !underLine ? styles.TextLink : ""
      } 
      ${small ? styles.Small : ""} ${className ? className : ""}`}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className={underLine ? styles.TextLinkUnderLine : ""}>{children}</div>
      {!underLine && !hasNoArrow && (
        <>
          <div className="page-width-spacer-5"></div>
          <AppIcon icon={ICONS.ICON.ARROW_FORWARD} size="xs"></AppIcon>
        </>
      )}
    </div>
  );
}
