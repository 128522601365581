import { useState } from "react";
import { IonContent, IonPage } from "@ionic/react";
import { updatePassword, confirmPasswordReset } from "firebase/auth";
import { AppLogo, AppPrimaryButton, AppLoading } from "../../components/UiComponents";
import { auth } from "../../firebase";
import { PasswordForm } from "src/web/components/UiComponents/organisms";
import { useQueryString } from "src/web/hooks/useQueryString";
import { useIntl } from "react-intl-phraseapp";
import { useAppRouter } from "../../hooks/useAppRouter";
import { useCommon } from "src/web/utils/hooks/common";

export default function CreateUserSetPassword() {
  const iRouter = useAppRouter();
  const intl = useIntl();

  const { parseQs } = useQueryString();
  const params = parseQs(window.location.search);
  const code = params.code as string;
  const createOrReset = !!code ? "RESET" : "CREATE";
  const { doPasswordValidation } = useCommon();

  const PASSWORD_TYPES_INFO = {
    RESET: {
      title: `${intl.formatMessage({
        id: "page.reset_password.description.line1",
      })}\n${intl.formatMessage({ id: "page.reset_password.description.line1" })}`,
      url: "/reset-password-success",
    },
    CREATE: {
      title: `${intl.formatMessage({
        id: "page.create_user_set_password.message.line1",
      })}\n${intl.formatMessage({ id: "page.create_user_set_password.message.line2" })}`,
      url: "/create-user-base-info",
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [firstPass, setFirstPass] = useState<string>("");
  const [secondPass, setSecondPass] = useState<string>("");

  const doChange = async () => {
    const tmpIsValid = doPasswordValidation(firstPass, secondPass);
    setIsValid(tmpIsValid.isValid);
    setErrorMsg(tmpIsValid.errorMsg);

    if (tmpIsValid.isValid && auth && (!!code || auth.currentUser)) {
      try {
        setIsLoading(true);
        if (createOrReset === "CREATE" && auth.currentUser) {
          // create password
          await updatePassword(auth.currentUser, firstPass);
        } else {
          // reset password
          await confirmPasswordReset(auth, code, firstPass);
        }
        iRouter.push(PASSWORD_TYPES_INFO[createOrReset].url);
      } catch (error: any) {
        setIsValid(false);
        if (error?.code) {
          setErrorMsg(
            intl.formatMessage(
              { id: "page.change_password.submit.error.general.message" },
              { error_code: error?.code },
            ),
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <IonPage>
        <AppLogo />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <PasswordForm
              title={PASSWORD_TYPES_INFO[createOrReset].title}
              subTitle={intl.formatMessage({
                id: "page.create_user_set_password.password_information",
              })}
              firstPass={firstPass}
              secondPass={secondPass}
              handleFirstPassInputChange={(val: string) => setFirstPass(val)}
              handleSecondPassInputChange={(val: string) => setSecondPass(val)}
              error={!isValid}
              errorMsg={errorMsg}
            />

            <div className="page-spacer-60" />

            <AppPrimaryButton full disabled={!firstPass || !secondPass} onClick={doChange}>
              {intl.formatMessage({ id: "page.create_user_set_password.button.next" })}
            </AppPrimaryButton>
          </div>
        </IonContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
