import { useIntl } from "react-intl-phraseapp";
import { DeviceInfoType } from "src/web/plugin/healthcare-manager-plugin";
import { ModalWrapper } from "./ModalWrapper";
import { ModalTitleWithDesc } from "../../UiComponents/organisms";
import { DEVICE_CATEGORY } from "src/web/constants";
import { AppPrimaryButton, AppTextLink } from "../../UiComponents";
import styles from "../styles.module.css";
import omronSphygmomanometerImg from "../../../assets/images/omron_sphygmomanometer.png";

export function DeviceSelected({
  isOpen,
  deviceCategory,
  device,
  handleCancel,
  handleNext,
}: {
  isOpen: boolean;
  deviceCategory: string;
  device?: DeviceInfoType;
  handleCancel: () => void;
  handleNext: () => void;
}) {
  const intl = useIntl();

  return (
    <ModalWrapper isOpen={isOpen} handleCancel={handleCancel}>
      <div className="page-spacer-45" />

      <ModalTitleWithDesc
        title={device?.deviceName ?? ""}
        desc={
          deviceCategory === DEVICE_CATEGORY.BLOOD_PRESSURE
            ? intl.formatMessage(
                { id: "components.device_modal.device_selected.blood_pressure" },
                { name: device?.deviceName ?? "" },
              )
            : intl.formatMessage(
                { id: "components.device_modal.device_selected.temperature" },
                { name: device?.deviceName ?? "" },
              )
        }
      />

      <div className="page-spacer-50" />

      <div className={styles.deviceImgWrapper}>
        <img src={omronSphygmomanometerImg} className={styles.deviceImg} alt="" />
      </div>

      <div className="page-spacer-60" />

      <AppPrimaryButton full onClick={handleNext}>
        {intl.formatMessage({ id: "components.device_modal.device_selected.button.pairing" })}
      </AppPrimaryButton>

      <div className="page-spacer-15" />

      <AppTextLink hasNoArrow onClick={handleCancel}>
        {intl.formatMessage({ id: "components.device_modal.device_selected.button.cancel" })}
      </AppTextLink>
    </ModalWrapper>
  );
}
