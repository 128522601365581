import { IonContent, IonLabel, IonPage } from "@ionic/react";
import { useSelector } from "react-redux";
import { AppHeader, AppTextLink } from "../../../components/UiComponents";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { RootState } from "src/web/reducer";
import { useIntl } from "react-intl-phraseapp";

export function ChangeEmailConfirm() {
  const iRouter = useAppRouter();
  const intl = useIntl();

  const newUserEmailAddress = useSelector((state: RootState) => state.newUserEmailAddress);

  return (
    <>
      <IonPage>
        <AppHeader />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="text-center-block p-header2">
              {intl.formatMessage({ id: "page.change_email_confirm.message.line1" })}
            </IonLabel>
            <IonLabel className="text-center-block p-header2">
              {intl.formatMessage({ id: "page.change_email_confirm.message.line2" })}
            </IonLabel>
            <IonLabel className="text-center-block p-header2">
              {intl.formatMessage({ id: "page.change_email_confirm.message.line3" })}
            </IonLabel>

            <div className="page-spacer-45" />

            <IonLabel className="text-center-block p-body">{newUserEmailAddress}</IonLabel>

            <div className="page-spacer-45" />

            <AppTextLink onClick={() => iRouter.goBack()}>
              {intl.formatMessage({ id: "page.change_email_confirm.resend_mail" })}
            </AppTextLink>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
