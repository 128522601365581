export { default as UserInfoActionConst } from "./userInfoActionConst";
export { default as LocalStorageKey } from "./localStorageKey";
export { default as actionConst } from "./actionConst";
export { AlexaConfig } from "./alexaConfig";
export * from "./icons";

export const DEFAULT_LANGUAGE = "ja";

export const DEFAULT_DISEASE_ID = "1";

export const FIREBASE_FILE_NAMES = {
  medicineFileName: "medicineFile",
  prescriptionFileName: "prescriptionFile",
};

export const DEFAULT_SHEET_MODAL_SAFE_TOP = 50;

export const NOTIFICATION_LIMIT_ANDROID = 50;
export const NOTIFICATION_LIMIT_IOS = 64;

export const STORAGE_REQ_KEY = "STORAGE_REQ_KEY";
export const IS_LOCAL_SAVE = "IS_LOCAL_SAVE";
export const PENDING_REQ_SYNCED_STATUS = {
  ALL_SUCCESS: 2,
  PARTIAL_SUCCESS: 1,
  ALL_FAILED: 0,
  NOT_TRIGGERED: -1,
};

export const ALARM_READY_TIME_IN_MINUTES = 60;

export const SYMPTOM_REPORT_DETAILS_DATE = "SYMPTOM_REPORT_DETAILS_DATE";

export const EMPTY_OPTION_NO_TEXT = "";
export const EMPTY_OPTION_STRING_VALUE = "0";
export const EMPTY_OPTION_NUMBER_VALUE = 0;

export const MMHG_OPTIONS = [
  { value: 50, text: "50" },
  { value: 51, text: "51" },
  { value: 52, text: "52" },
  { value: 53, text: "53" },
  { value: 54, text: "54" },
  { value: 55, text: "55" },
  { value: 56, text: "56" },
  { value: 57, text: "57" },
  { value: 58, text: "58" },
  { value: 59, text: "59" },
  { value: 60, text: "60" },
  { value: 61, text: "61" },
  { value: 62, text: "62" },
  { value: 63, text: "63" },
  { value: 64, text: "64" },
  { value: 65, text: "65" },
  { value: 66, text: "66" },
  { value: 67, text: "67" },
  { value: 68, text: "68" },
  { value: 69, text: "69" },
  { value: 70, text: "70" },
  { value: 71, text: "71" },
  { value: 72, text: "72" },
  { value: 73, text: "73" },
  { value: 74, text: "74" },
  { value: 75, text: "75" },
  { value: 76, text: "76" },
  { value: 77, text: "77" },
  { value: 78, text: "78" },
  { value: 79, text: "79" },
  { value: 80, text: "80" },
  { value: 81, text: "81" },
  { value: 82, text: "82" },
  { value: 83, text: "83" },
  { value: 84, text: "84" },
  { value: 85, text: "85" },
  { value: 86, text: "86" },
  { value: 87, text: "87" },
  { value: 88, text: "88" },
  { value: 89, text: "89" },
  { value: 90, text: "90" },
  { value: 91, text: "91" },
  { value: 92, text: "92" },
  { value: 93, text: "93" },
  { value: 94, text: "94" },
  { value: 95, text: "95" },
  { value: 96, text: "96" },
  { value: 97, text: "97" },
  { value: 98, text: "98" },
  { value: 99, text: "99" },
  { value: 100, text: "100" },
  { value: 101, text: "101" },
  { value: 102, text: "102" },
  { value: 103, text: "103" },
  { value: 104, text: "104" },
  { value: 105, text: "105" },
  { value: 106, text: "106" },
  { value: 107, text: "107" },
  { value: 108, text: "108" },
  { value: 109, text: "109" },
  { value: 110, text: "110" },
  { value: 111, text: "111" },
  { value: 112, text: "112" },
  { value: 113, text: "113" },
  { value: 114, text: "114" },
  { value: 115, text: "115" },
  { value: 116, text: "116" },
  { value: 117, text: "117" },
  { value: 118, text: "118" },
  { value: 119, text: "119" },
  { value: 120, text: "120" },
  { value: 121, text: "121" },
  { value: 122, text: "122" },
  { value: 123, text: "123" },
  { value: 124, text: "124" },
  { value: 125, text: "125" },
  { value: 126, text: "126" },
  { value: 127, text: "127" },
  { value: 128, text: "128" },
  { value: 129, text: "129" },
  { value: 130, text: "130" },
  { value: 131, text: "131" },
  { value: 132, text: "132" },
  { value: 133, text: "133" },
  { value: 134, text: "134" },
  { value: 135, text: "135" },
  { value: 136, text: "136" },
  { value: 137, text: "137" },
  { value: 138, text: "138" },
  { value: 139, text: "139" },
  { value: 140, text: "140" },
  { value: 141, text: "141" },
  { value: 142, text: "142" },
  { value: 143, text: "143" },
  { value: 144, text: "144" },
  { value: 145, text: "145" },
  { value: 146, text: "146" },
  { value: 147, text: "147" },
  { value: 148, text: "148" },
  { value: 149, text: "149" },
  { value: 150, text: "150" },
  { value: 151, text: "151" },
  { value: 152, text: "152" },
  { value: 153, text: "153" },
  { value: 154, text: "154" },
  { value: 155, text: "155" },
  { value: 156, text: "156" },
  { value: 157, text: "157" },
  { value: 158, text: "158" },
  { value: 159, text: "159" },
  { value: 160, text: "160" },
  { value: 161, text: "161" },
  { value: 162, text: "162" },
  { value: 163, text: "163" },
  { value: 164, text: "164" },
  { value: 165, text: "165" },
  { value: 166, text: "166" },
  { value: 167, text: "167" },
  { value: 168, text: "168" },
  { value: 169, text: "169" },
  { value: 170, text: "170" },
  { value: 171, text: "171" },
  { value: 172, text: "172" },
  { value: 173, text: "173" },
  { value: 174, text: "174" },
  { value: 175, text: "175" },
  { value: 176, text: "176" },
  { value: 177, text: "177" },
  { value: 178, text: "178" },
  { value: 179, text: "179" },
  { value: 180, text: "180" },
  { value: 181, text: "181" },
  { value: 182, text: "182" },
  { value: 183, text: "183" },
  { value: 184, text: "184" },
  { value: 185, text: "185" },
  { value: 186, text: "186" },
  { value: 187, text: "187" },
  { value: 188, text: "188" },
  { value: 189, text: "189" },
  { value: 190, text: "190" },
  { value: 191, text: "191" },
  { value: 192, text: "192" },
  { value: 193, text: "193" },
  { value: 194, text: "194" },
  { value: 195, text: "195" },
  { value: 196, text: "196" },
  { value: 197, text: "197" },
  { value: 198, text: "198" },
  { value: 199, text: "199" },
  { value: 200, text: "200" },
  { value: 201, text: "201" },
  { value: 202, text: "202" },
  { value: 203, text: "203" },
  { value: 204, text: "204" },
  { value: 205, text: "205" },
  { value: 206, text: "206" },
  { value: 207, text: "207" },
  { value: 208, text: "208" },
  { value: 209, text: "209" },
  { value: 210, text: "210" },
  { value: 211, text: "211" },
  { value: 212, text: "212" },
  { value: 213, text: "213" },
  { value: 214, text: "214" },
  { value: 215, text: "215" },
  { value: 216, text: "216" },
  { value: 217, text: "217" },
  { value: 218, text: "218" },
  { value: 219, text: "219" },
  { value: 220, text: "220" },
];

export const PULSE_OPTIONS = [
  { value: 30, text: "30" },
  { value: 31, text: "31" },
  { value: 32, text: "32" },
  { value: 33, text: "33" },
  { value: 34, text: "34" },
  { value: 35, text: "35" },
  { value: 36, text: "36" },
  { value: 37, text: "37" },
  { value: 38, text: "38" },
  { value: 39, text: "39" },
  { value: 40, text: "40" },
  { value: 41, text: "41" },
  { value: 42, text: "42" },
  { value: 43, text: "43" },
  { value: 44, text: "44" },
  { value: 45, text: "45" },
  { value: 46, text: "46" },
  { value: 47, text: "47" },
  { value: 48, text: "48" },
  { value: 49, text: "49" },
  { value: 50, text: "50" },
  { value: 51, text: "51" },
  { value: 52, text: "52" },
  { value: 53, text: "53" },
  { value: 54, text: "54" },
  { value: 55, text: "55" },
  { value: 56, text: "56" },
  { value: 57, text: "57" },
  { value: 58, text: "58" },
  { value: 59, text: "59" },
  { value: 60, text: "60" },
  { value: 61, text: "61" },
  { value: 62, text: "62" },
  { value: 63, text: "63" },
  { value: 64, text: "64" },
  { value: 65, text: "65" },
  { value: 66, text: "66" },
  { value: 67, text: "67" },
  { value: 68, text: "68" },
  { value: 69, text: "69" },
  { value: 70, text: "70" },
  { value: 71, text: "71" },
  { value: 72, text: "72" },
  { value: 73, text: "73" },
  { value: 74, text: "74" },
  { value: 75, text: "75" },
  { value: 76, text: "76" },
  { value: 77, text: "77" },
  { value: 78, text: "78" },
  { value: 79, text: "79" },
  { value: 80, text: "80" },
  { value: 81, text: "81" },
  { value: 82, text: "82" },
  { value: 83, text: "83" },
  { value: 84, text: "84" },
  { value: 85, text: "85" },
  { value: 86, text: "86" },
  { value: 87, text: "87" },
  { value: 88, text: "88" },
  { value: 89, text: "89" },
  { value: 90, text: "90" },
  { value: 91, text: "91" },
  { value: 92, text: "92" },
  { value: 93, text: "93" },
  { value: 94, text: "94" },
  { value: 95, text: "95" },
  { value: 96, text: "96" },
  { value: 97, text: "97" },
  { value: 98, text: "98" },
  { value: 99, text: "99" },
  { value: 100, text: "100" },
  { value: 101, text: "101" },
  { value: 102, text: "102" },
  { value: 103, text: "103" },
  { value: 104, text: "104" },
  { value: 105, text: "105" },
  { value: 106, text: "106" },
  { value: 107, text: "107" },
  { value: 108, text: "108" },
  { value: 109, text: "109" },
  { value: 110, text: "110" },
  { value: 111, text: "111" },
  { value: 112, text: "112" },
  { value: 113, text: "113" },
  { value: 114, text: "114" },
  { value: 115, text: "115" },
  { value: 116, text: "116" },
  { value: 117, text: "117" },
  { value: 118, text: "118" },
  { value: 119, text: "119" },
  { value: 120, text: "120" },
  { value: 121, text: "121" },
  { value: 122, text: "122" },
  { value: 123, text: "123" },
  { value: 124, text: "124" },
  { value: 125, text: "125" },
  { value: 126, text: "126" },
  { value: 127, text: "127" },
  { value: 128, text: "128" },
  { value: 129, text: "129" },
  { value: 130, text: "130" },
  { value: 131, text: "131" },
  { value: 132, text: "132" },
  { value: 133, text: "133" },
  { value: 134, text: "134" },
  { value: 135, text: "135" },
  { value: 136, text: "136" },
  { value: 137, text: "137" },
  { value: 138, text: "138" },
  { value: 139, text: "139" },
  { value: 140, text: "140" },
];

export const HOUR_KV_OPTIONS = [
  { value: "0", text: "0" },
  { value: "1", text: "1" },
  { value: "2", text: "2" },
  { value: "3", text: "3" },
  { value: "4", text: "4" },
  { value: "5", text: "5" },
  { value: "6", text: "6" },
  { value: "7", text: "7" },
  { value: "8", text: "8" },
  { value: "9", text: "9" },
  { value: "10", text: "10" },
  { value: "11", text: "11" },
  { value: "12", text: "12" },
  { value: "13", text: "13" },
  { value: "14", text: "14" },
  { value: "15", text: "15" },
  { value: "16", text: "16" },
  { value: "17", text: "17" },
  { value: "18", text: "18" },
  { value: "19", text: "19" },
  { value: "20", text: "20" },
  { value: "21", text: "21" },
  { value: "22", text: "22" },
  { value: "23", text: "23" },
];

export const MINUTE_KV_OPTIONS = [
  { value: "00", text: "00" },
  { value: "01", text: "01" },
  { value: "02", text: "02" },
  { value: "03", text: "03" },
  { value: "04", text: "04" },
  { value: "05", text: "05" },
  { value: "06", text: "06" },
  { value: "07", text: "07" },
  { value: "08", text: "08" },
  { value: "09", text: "09" },
  { value: "10", text: "10" },
  { value: "11", text: "11" },
  { value: "12", text: "12" },
  { value: "13", text: "13" },
  { value: "14", text: "14" },
  { value: "15", text: "15" },
  { value: "16", text: "16" },
  { value: "17", text: "17" },
  { value: "18", text: "18" },
  { value: "19", text: "19" },
  { value: "20", text: "20" },
  { value: "21", text: "21" },
  { value: "22", text: "22" },
  { value: "23", text: "23" },
  { value: "24", text: "24" },
  { value: "25", text: "25" },
  { value: "26", text: "26" },
  { value: "27", text: "27" },
  { value: "28", text: "28" },
  { value: "29", text: "29" },
  { value: "30", text: "30" },
  { value: "31", text: "31" },
  { value: "32", text: "32" },
  { value: "33", text: "33" },
  { value: "34", text: "34" },
  { value: "35", text: "35" },
  { value: "36", text: "36" },
  { value: "37", text: "37" },
  { value: "38", text: "38" },
  { value: "39", text: "39" },
  { value: "40", text: "40" },
  { value: "41", text: "41" },
  { value: "42", text: "42" },
  { value: "43", text: "43" },
  { value: "44", text: "44" },
  { value: "45", text: "45" },
  { value: "46", text: "46" },
  { value: "47", text: "47" },
  { value: "48", text: "48" },
  { value: "49", text: "49" },
  { value: "50", text: "50" },
  { value: "51", text: "51" },
  { value: "52", text: "52" },
  { value: "53", text: "53" },
  { value: "54", text: "54" },
  { value: "55", text: "55" },
  { value: "56", text: "56" },
  { value: "57", text: "57" },
  { value: "58", text: "58" },
  { value: "59", text: "59" },
];

export const MINUTE_STEP_KV_OPTIONS = [
  { value: "00", text: "00" },
  { value: "05", text: "05" },
  { value: "10", text: "10" },
  { value: "15", text: "15" },
  { value: "20", text: "20" },
  { value: "25", text: "25" },
  { value: "30", text: "30" },
  { value: "35", text: "35" },
  { value: "40", text: "40" },
  { value: "45", text: "45" },
  { value: "50", text: "50" },
  { value: "55", text: "55" },
];

export const DEVICE_CATEGORY = {
  BLOOD_PRESSURE: "bp",
  TEMPERATURE: "tp",
};

export const BLUETOOTH = {
  [DEVICE_CATEGORY.BLOOD_PRESSURE]: [
    {
      deviceId: "DEVICE_0003",
      deviceName: "オムロン上腕式血圧計",
    },
    {
      deviceId: "",
      deviceName: "タニタ手首式血圧計",
    },
    {
      deviceId: "",
      deviceName: "パナソニック上腕式血圧計",
    },
  ],
  [DEVICE_CATEGORY.TEMPERATURE]: [
    {
      deviceId: "DEVICE_0001",
      deviceName: "体温計 MC-6810T2",
    },
  ],
};

export const PICKER_TIMEOUT = 200;

export const BUTTON_TIMEOUT = 500;

export const IMAGES_FOLDER = "images/";

export const MEDICINE_CATEGORY = {
  EVERYDAY_MEDICINE: 0, // 日常薬: 内服, 注射, 外用
  COUNTER_MEDICINE: 1, // 頓用薬: 内滴, 頓服
  OTHERS: 2, // その他の薬
};

export const DEFAULT_MEDICINE_ONEDAY_QUANTITY = 12;

export const DEFAULT_MEDICINE_GAP_TIME = 6;

// 1日あたりの最大用法回数
export const MEDICINE_INFO_USES_PRE_DAY_OPTIONS = [
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" },
  { value: 11, text: "11" },
  { value: 12, text: "12" },
];

// 最低時間間隔
export const MEDICINE_INFO_USES_INTERVAL_OPTIONS = [
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" },
  { value: 11, text: "11" },
  { value: 12, text: "12" },
  { value: 13, text: "13" },
  { value: 14, text: "14" },
  { value: 15, text: "15" },
  { value: 16, text: "16" },
  { value: 17, text: "17" },
  { value: 18, text: "18" },
  { value: 19, text: "19" },
  { value: 20, text: "20" },
  { value: 21, text: "21" },
  { value: 22, text: "22" },
  { value: 23, text: "23" },
  { value: 24, text: "24" },
];

// 服薬回数
export const MEDICINE_INFO_NUMBER_OF_DOSES_OPTION = [
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" },
  { value: 11, text: "11" },
  { value: 12, text: "12" },
];

export const MEDICINE_TYPE = {
  PRESCRIPTION: "0", // 処方薬
  MARKETING: "1", // 市販薬
};

export const NUMBER_OPTIONS = [
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" },
  { value: 11, text: "11" },
  { value: 12, text: "12" },
  { value: 13, text: "13" },
  { value: 14, text: "14" },
  { value: 15, text: "15" },
  { value: 16, text: "16" },
  { value: 17, text: "17" },
  { value: 18, text: "18" },
  { value: 19, text: "19" },
  { value: 20, text: "20" },
  { value: 21, text: "21" },
  { value: 22, text: "22" },
  { value: 23, text: "23" },
  { value: 24, text: "24" },
  { value: 25, text: "25" },
  { value: 26, text: "26" },
  { value: 27, text: "27" },
  { value: 28, text: "28" },
  { value: 29, text: "29" },

  { value: 30, text: "30" },
  { value: 31, text: "31" },
  { value: 32, text: "32" },
  { value: 33, text: "33" },
  { value: 34, text: "34" },
  { value: 35, text: "35" },
  { value: 36, text: "36" },
  { value: 37, text: "37" },
  { value: 38, text: "38" },
  { value: 39, text: "39" },

  { value: 40, text: "40" },
  { value: 41, text: "41" },
  { value: 42, text: "42" },
  { value: 43, text: "43" },
  { value: 44, text: "44" },
  { value: 45, text: "45" },
  { value: 46, text: "46" },
  { value: 47, text: "47" },
  { value: 48, text: "48" },
  { value: 49, text: "49" },

  { value: 50, text: "50" },
  { value: 51, text: "51" },
  { value: 52, text: "52" },
  { value: 53, text: "53" },
  { value: 54, text: "54" },
  { value: 55, text: "55" },
  { value: 56, text: "56" },
  { value: 57, text: "57" },
  { value: 58, text: "58" },
  { value: 59, text: "59" },

  { value: 60, text: "60" },
  { value: 61, text: "61" },
  { value: 62, text: "62" },
  { value: 63, text: "63" },
  { value: 64, text: "64" },
  { value: 65, text: "65" },
  { value: 66, text: "66" },
  { value: 67, text: "67" },
  { value: 68, text: "68" },
  { value: 69, text: "69" },

  { value: 70, text: "70" },
  { value: 71, text: "71" },
  { value: 72, text: "72" },
  { value: 73, text: "73" },
  { value: 74, text: "74" },
  { value: 75, text: "75" },
  { value: 76, text: "76" },
  { value: 77, text: "77" },
  { value: 78, text: "78" },
  { value: 79, text: "79" },

  { value: 80, text: "80" },
  { value: 81, text: "81" },
  { value: 82, text: "82" },
  { value: 83, text: "83" },
  { value: 84, text: "84" },
  { value: 85, text: "85" },
  { value: 86, text: "86" },
  { value: 87, text: "87" },
  { value: 88, text: "88" },
  { value: 89, text: "89" },

  { value: 90, text: "90" },
  { value: 91, text: "91" },
  { value: 92, text: "92" },
  { value: 93, text: "93" },
  { value: 94, text: "94" },
  { value: 95, text: "95" },
  { value: 96, text: "96" },
  { value: 97, text: "97" },
  { value: 98, text: "98" },
  { value: 99, text: "99" },

  { value: 100, text: "100" },
  { value: 101, text: "101" },
  { value: 102, text: "102" },
  { value: 103, text: "103" },
  { value: 104, text: "104" },
  { value: 105, text: "105" },
  { value: 106, text: "106" },
  { value: 107, text: "107" },
  { value: 108, text: "108" },
  { value: 109, text: "109" },

  { value: 110, text: "110" },
  { value: 111, text: "111" },
  { value: 112, text: "112" },
  { value: 113, text: "113" },
  { value: 114, text: "114" },
  { value: 115, text: "115" },
  { value: 116, text: "116" },
  { value: 117, text: "117" },
  { value: 118, text: "118" },
  { value: 119, text: "119" },

  { value: 120, text: "120" },
  { value: 121, text: "121" },
  { value: 122, text: "122" },
  { value: 123, text: "123" },
  { value: 124, text: "124" },
  { value: 125, text: "125" },
  { value: 126, text: "126" },
  { value: 127, text: "127" },
  { value: 128, text: "128" },
  { value: 129, text: "129" },

  { value: 130, text: "130" },
  { value: 131, text: "131" },
  { value: 132, text: "132" },
  { value: 133, text: "133" },
  { value: 134, text: "134" },
  { value: 135, text: "135" },
  { value: 136, text: "136" },
  { value: 137, text: "137" },
  { value: 138, text: "138" },
  { value: 139, text: "139" },

  { value: 140, text: "140" },
  { value: 141, text: "141" },
  { value: 142, text: "142" },
  { value: 143, text: "143" },
  { value: 144, text: "144" },
  { value: 145, text: "145" },
  { value: 146, text: "146" },
  { value: 147, text: "147" },
  { value: 148, text: "148" },
  { value: 149, text: "149" },

  { value: 150, text: "150" },
  { value: 151, text: "151" },
  { value: 152, text: "152" },
  { value: 153, text: "153" },
  { value: 154, text: "154" },
  { value: 155, text: "155" },
  { value: 156, text: "156" },
  { value: 157, text: "157" },
  { value: 158, text: "158" },
  { value: 159, text: "159" },

  { value: 160, text: "160" },
  { value: 161, text: "161" },
  { value: 162, text: "162" },
  { value: 163, text: "163" },
  { value: 164, text: "164" },
  { value: 165, text: "165" },
  { value: 166, text: "166" },
  { value: 167, text: "167" },
  { value: 168, text: "168" },
  { value: 169, text: "169" },

  { value: 170, text: "170" },
  { value: 171, text: "171" },
  { value: 172, text: "172" },
  { value: 173, text: "173" },
  { value: 174, text: "174" },
  { value: 175, text: "175" },
  { value: 176, text: "176" },
  { value: 177, text: "177" },
  { value: 178, text: "178" },
  { value: 179, text: "179" },
];

export const PRESCRIPTION_LIST = "PRESCRIPTION_LIST";
export const MEDICINE_LIST = "MEDICINE_LIST";
export const MEDICINE_CHECK_LIST = "MEDICINE_CHECK_LIST";

export const WEEK_GRAPH_DISPLAY = "WEEK_GRAPH_DISPLAY";
export const MONTH_GRAPH_DISPLAY = "MONTH_GRAPH_DISPLAY";

export const MEDICINE_ITEM_SWIPE = "MEDICINE_ITEM_SWIPE";
// レポート詳細の更新フラグ
export const REPORT_DETAIL_REFRESH = "REPORT_DETAIL_REFRESH";

/* ==================================
  | Category    | Name        |
  | ----------- | ----------- |
  | a           | 活動         |
  | b           | 心・体       |
  | c           | バイタル     |
  | d           | 症状        |
 ------------------------------------
  | Position    | Name        |
  | ----------- | ----------- |
  | 0           | top         |
  | 1           | left        |
  | 2           | bottom      |
  | 3           | right       |
===================================== */
export const RADAR_MAP: { [id: string]: number } = {
  a: 0,
  b: 3,
  c: 2,
  d: 1,
};

// 0, 1, 2, 3 are the position index, which means from left to right
export const LEVEL_POSITION_MAP: { [name: string]: number } = {
  a_r: 2,
  b_r: 3,
  c_r: 1,
  d_r: 0,
};

export const NO_RANK = "未";

export const DEFAULT_ALARM_TIMING: any = {
  "01": "7:00",
  "02": "7:30",
  "03": "8:30",
  "04": "10:00",
  "05": "11:30",
  "06": "12:30",
  "07": "14:00",
  "08": "18:30",
  "09": "19:30",
  "10": "21:00",
  "11": "23:00",
};

export const CUSTOM_TYPE_ALARM = "1";
export const SYSTEM_TYPE_ALARM = "0";

export const GENDER_OPTIONS = [
  { value: "1", text: "女" },
  { value: "2", text: "男" },
  { value: "3", text: "その他" },
  { value: "4", text: "回答しない" },
];

export const ALL_MONTH_OPTIONS = [
  { value: "01", text: "1月" },
  { value: "02", text: "2月" },
  { value: "03", text: "3月" },
  { value: "04", text: "4月" },
  { value: "05", text: "5月" },
  { value: "06", text: "6月" },
  { value: "07", text: "7月" },
  { value: "08", text: "8月" },
  { value: "09", text: "9月" },
  { value: "10", text: "10月" },
  { value: "11", text: "11月" },
  { value: "12", text: "12月" },
];

export const ALL_DATE_OPTIONS = [
  { value: "01", text: "1日" },
  { value: "02", text: "2日" },
  { value: "03", text: "3日" },
  { value: "04", text: "4日" },
  { value: "05", text: "5日" },
  { value: "06", text: "6日" },
  { value: "07", text: "7日" },
  { value: "08", text: "8日" },
  { value: "09", text: "9日" },
  { value: "10", text: "10日" },
  { value: "11", text: "11日" },
  { value: "12", text: "12日" },
  { value: "13", text: "13日" },
  { value: "14", text: "14日" },
  { value: "15", text: "15日" },
  { value: "16", text: "16日" },
  { value: "17", text: "17日" },
  { value: "18", text: "18日" },
  { value: "19", text: "19日" },
  { value: "20", text: "20日" },
  { value: "21", text: "21日" },
  { value: "22", text: "22日" },
  { value: "23", text: "23日" },
  { value: "24", text: "24日" },
  { value: "25", text: "25日" },
  { value: "26", text: "26日" },
  { value: "27", text: "27日" },
  { value: "28", text: "28日" },
  { value: "29", text: "29日" },
  { value: "30", text: "30日" },
  { value: "31", text: "31日" },
];

export const DATE_FORMAT_SPLIT = "/";

export const USER_BASIC_INFO_MIN_BIRTHDAY_YEAR = 1950;

export const USER_BASIC_INFO_TYPE = {
  NAME: "userName",
  BIRTHDAY: "birthday",
  GENDER: "gender",
  PREFECTURE: "prefecture",
};

export const DEVICE_CONNECTION_APP_TYPE = {
  STEPS: "steps",
  SLEEP: "sleep",
};

export const ACTION_SHEET_MIDDLE_SIZE = 520;

export const SCORE_TAB_KEY = [
  "score_tab",
  "medicine_tab",
  "body_temperature_tab",
  "blood_pressure_tab",
  "activity_tab",
];

export const WEEK_MONTH_TAB = {
  WEEK: "WEEK",
  MONTH: "MONTH",
};

export const MONTH_PERIOD = 6; // 症状記録 > 月表示
