import { IonContent, IonLabel, IonPage } from "@ionic/react";
import { AppLogo, AppPrimaryButton } from "../../../components/UiComponents";
import { useAppRouter } from "../../../hooks/useAppRouter";
import { useIntl } from "react-intl-phraseapp";

export function ChangeEmailSuccess() {
  const iRouter = useAppRouter();
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppLogo />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="text-center-block p-header2">
              {intl.formatMessage({ id: "page.change_email_success.message.line1" })}
            </IonLabel>
            <IonLabel className="text-center-block p-header2">
              {intl.formatMessage({ id: "page.change_email_success.message.line2" })}
            </IonLabel>

            <div className="page-spacer-60" />

            <AppPrimaryButton full onClick={() => iRouter.push("/login", "forward", "replace")}>
              {intl.formatMessage({ id: "page.change_email_success.login" })}
            </AppPrimaryButton>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
}
