import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGraphInfo } from "src/web/actions";
import { WEEK_GRAPH_DISPLAY } from "src/web/constants";
import { RootState } from "src/web/reducer";
import { GraphInfoType } from "src/web/types";
import { ChartDataTypeV2 } from "../lineBarScatterChartV2";

export function useLineBarScatterChartV2Control() {
  const dispatch = useDispatch();

  // 図の表示フラグ
  const graphInfo: GraphInfoType = useSelector((state: RootState) => {
    return state.graphInfo;
  });

  const [activeNodeData, setActiveNodeData] = useState<ChartDataTypeV2>();

  const activeGraphTooltip = (event: any, notActive: boolean = false) => {
    event.preventDefault();

    // 図の非表示フラグ
    let new_graphInfo = {
      ...graphInfo,
    };
    new_graphInfo[WEEK_GRAPH_DISPLAY] = notActive;
    dispatch(updateGraphInfo(new_graphInfo));
  };

  const activeChartFocus = (data: any, event: any) => {
    activeGraphTooltip(event);
    if (data.activePayload) {
      setActiveNodeData(data.activePayload[0].payload);
    }
  };

  const inactiveChartFocus = (event: any) => {
    activeGraphTooltip(event, true);
    setActiveNodeData(undefined);
  };

  return {
    activeNodeData,
    activeChartFocus,
    inactiveChartFocus,
  };
}
