import { IonPage, IonContent, IonLabel } from "@ionic/react";
import { AppHeader, AppTextLink } from "../../components/UiComponents";
import { useAppRouter } from "../../hooks/useAppRouter";
import { Storage } from "@capacitor/storage";
import { LocalStorageKey } from "../../constants";
import { useState } from "react";
import { useIntl } from "react-intl-phraseapp";

export default function CreateUserEmailConfirm() {
  const intl = useIntl();
  const [mailAddress, setMailAddress] = useState<string | null>("");
  const iRouter = useAppRouter();

  Storage.get({ key: LocalStorageKey.CREATE_USER_EMAIL_ADDRESS }).then((createMailAddress) => {
    setMailAddress(createMailAddress?.value);
  });
  return (
    <IonPage>
      <AppHeader />

      <IonContent>
        <div className="root-content">
          <div className="page-spacer-45" />
          <IonLabel className="p-header4 text-center-block">{mailAddress}</IonLabel>
          <div className="page-spacer-45" />

          <IonLabel className="p-header2 text-center-block">
            {intl.formatMessage({ id: "page.create_user_email_confirm.message.line1" })}
          </IonLabel>
          <IonLabel className="p-header2 text-center-block">
            {intl.formatMessage({ id: "page.create_user_email_confirm.message.line2" })}
          </IonLabel>
          <IonLabel className="p-header2 text-center-block">
            {intl.formatMessage({ id: "page.create_user_email_confirm.message.line3" })}
          </IonLabel>

          <div className="page-spacer-45" />

          <AppTextLink onClick={() => iRouter.goBack()}>
            {intl.formatMessage({ id: "page.create_user_email_confirm.resend_email" })}
          </AppTextLink>
        </div>
      </IonContent>
    </IonPage>
  );
}
