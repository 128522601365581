import { actionConst } from "../constants";
import { LoadingAvgCurrentScoreType } from "../types";

interface Action {
  type: actionConst;
  state: LoadingAvgCurrentScoreType;
}

const initState: LoadingAvgCurrentScoreType = {
  isLoading: false,
};

export default function avgCurrentScoreReducer(state = initState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_AVG_CURRENT_SCORE:
      return actions.state;
    default:
      return state;
  }
}
