import { actionConst } from "../constants";

import { MedicineInfoType } from "src/web/types";

const initialState: MedicineInfoType = {
  // photoWebviewPath: "", // お薬のPhoto
  // m_name: "", // お薬の名前
  // m_shape_code: "", // 剤形
  // frequency: "", // 服薬頻度
  // takingTiming: [], // 服用タイミング
  // dispensing_quantity: "", // 調剤数量
  // start_date: "", // 開始日時 - 日付
  // start_time: "", // 開始日時 - 時間帯
  // end_date: "", // 終了日時 - 日付
  // end_time: "", // 終了日時 - 時間帯
  // end_flg: false, // 終了日時 - 要否設定
  // clinical_department: "", // 診療科
  // prescribing_doctor: "", // 処方医師名
  // memos: "", // 服薬メモ
};

interface Action {
  type: actionConst;
  state: any;
}

export default function medicineInfoReducer(state = initialState, actions: Action) {
  switch (actions.type) {
    case actionConst.UPDATE_MEDICINE_INFO:
      return actions.state;
    case actionConst.CLEAR_MEDICINE_INFO:
      return actions.state;
    default:
      return state;
  }
}
