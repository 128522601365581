import { IonLabel } from "@ionic/react";
import { ICONS } from "../../../constants";
import { AppIcon } from "../AppIcon/appIcon";

import styles from "./appTitleWithLine.module.css";

interface AppTitleWithLineProps {
  title: string;
  subTitle?: string;
  icon?: string;
}

export function AppTitleWithLine({ title, subTitle, icon }: AppTitleWithLineProps) {
  return (
    <div className="flex-row-between">
      <IonLabel className={styles.title}>{title}</IonLabel>
      <hr className={styles.hr} />
      {subTitle && (
        <div className={`${styles.subTitleWrapper}`}>
          <AppIcon icon={icon ?? ICONS.ICON.ALARM} size="xs" />
          <IonLabel className={styles.subTitle}>{subTitle}</IonLabel>
        </div>
      )}
    </div>
  );
}
