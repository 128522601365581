import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGraphInfo } from "src/web/actions";
import { MONTH_GRAPH_DISPLAY, MONTH_PERIOD } from "src/web/constants";
import { RootState } from "src/web/reducer";
import { GraphInfoType } from "src/web/types";

import styles from "./highlightCover.module.css";

interface HighlightCoverProps {
  index: number;
  isMedicineChart: boolean;
  highlightBarIndex?: number;
  setHighlightBarIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export function HighlightCover({
  index,
  isMedicineChart,
  highlightBarIndex,
  setHighlightBarIndex,
}: HighlightCoverProps) {
  const dispatch = useDispatch();

  // 図の表示フラグ
  const graphInfo: GraphInfoType = useSelector((state: RootState) => {
    return state.graphInfo;
  });

  const chartMargin = 5;
  const defaultPageSpace = 25;
  const widthOfEveryHighlightDiv = 34;
  const ionPagePadding = getComputedStyle(document.documentElement).getPropertyValue(
    "--psp-page-left-right-space",
  );
  const leftPaddingPx = isMedicineChart ? 65 : 25;
  const yAxisWidth = isMedicineChart ? 60 : 50;
  const chartWidth = window.innerWidth - parseInt(ionPagePadding) * 2;
  const barWidth =
    (chartWidth -
      yAxisWidth -
      chartMargin * 2 -
      (defaultPageSpace - parseInt(ionPagePadding)) * 2) /
    MONTH_PERIOD;

  return (
    <div
      className={`${styles.chartBarArea} ${highlightBarIndex === index ? styles.highlight : ""}`}
      style={{ width: barWidth, left: leftPaddingPx + barWidth * index }}
      onClick={(e) => {
        e.stopPropagation();
        setHighlightBarIndex(index);
        dispatch(
          updateGraphInfo({
            ...graphInfo,
            [MONTH_GRAPH_DISPLAY]: false,
          }),
        );
      }}
      key={index}
    >
      {highlightBarIndex === index && !graphInfo.MONTH_GRAPH_DISPLAY && (
        <div
          className={styles.highlightBar}
          style={{ left: (barWidth - widthOfEveryHighlightDiv) / 2 }}
        />
      )}
    </div>
  );
}
