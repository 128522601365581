import { useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { IonInput, IonLabel } from "@ionic/react";
import { AppIcon } from "../AppIcon/appIcon";
import { ICONS } from "../../../constants";
import styles from "./appInput.module.css";

export const AppPasswordInput = forwardRef((props: any, ref?: any) => {
  const dispatch = useDispatch();

  const size: "full" | "md" | "sm" | "xs" = props.size || "full";

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const [inputType, setInputType] = useState("password");

  const inputControl: React.MutableRefObject<any> = useRef();
  useImperativeHandle(ref, () => inputControl.current);

  return (
    <>
      {props.label && (
        <>
          <IonLabel className={styles.input_label}>{props.label}</IonLabel>
          <div className="page-spacer-15" />
        </>
      )}
      <div className={`${styles.inputWrapper} ${props.error ? styles.shake : ""}`}>
        <div className={`${styles.input_div} ${styles[size]}`}>
          <IonInput
            {...props}
            className={` ${props.error && styles.input_error} ${
              isFocus || props.showBoarder ? styles.input_focus : styles.input_no_focus
            } ${styles.input}`}
            onIonChange={(event) => {
              props.onIonChange && props.onIonChange(event);
            }}
            onIonFocus={(event) => {
              setIsFocus(true);
              props.onIonFocus && props.onIonFocus(event);
            }}
            onIonBlur={(event) => {
              setIsFocus(false);
              props.onIonBlur && props.onIonBlur(event);
            }}
            ref={inputControl}
            inputmode={props.inputmode || undefined}
            type={inputType}
          />
          <AppIcon
            size="sm"
            icon={inputType === "password" ? ICONS.ICON.VISIBILITY_OFF : ICONS.ICON.VISIBILITY_ON}
            className={`${props.error && styles.icon_error} ${styles.icon_close} ${
              styles.icon_close_focus
            }`}
            onMouseDown={(e) => {
              e.preventDefault();
              setInputType(inputType === "password" ? "text" : "password");
            }}
          />
        </div>
        {props.error && props.errorMsg && (
          <>
            <IonLabel className="p-input-label-error">{props.errorMsg}</IonLabel>
          </>
        )}
      </div>
    </>
  );
});
