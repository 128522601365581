import {
  deleteUser,
  signupEmailVerification,
  forgetPasswordEmailVerification,
  changeEmailVerification,
} from "src/web/services";

export function useUserModel() {
  const handleSendSignInLinkEmail = async (email: string) => {
    try {
      await signupEmailVerification({ email });
    } catch (error: any) {
      // dispatch(
      //   updateAlertInfo({
      //     status: AlertType.ERROR,
      //     msg: error.message.message,
      //   }),
      // );
      throw error;
    }
  };

  const handleSendPasswordResetEmail = async (email: string) => {
    try {
      await forgetPasswordEmailVerification({ email });
    } catch (error: any) {
      // dispatch(
      //   updateAlertInfo({
      //     status: AlertType.ERROR,
      //     msg: error.message.message,
      //   }),
      // );
      throw error;
    }
  };

  const handlechangeEmailVerification = async (email: string, newEmail: string) => {
    try {
      await changeEmailVerification({ email, newEmail });
    } catch (error: any) {
      // dispatch(
      //   updateAlertInfo({
      //     status: AlertType.ERROR,
      //     msg: error.message.message,
      //   }),
      // );
      throw error;
    }
  };

  const handleDeleteUser = async (dataReserveFlg: boolean) => {
    try {
      await deleteUser({
        data_reserve_flg: dataReserveFlg,
      });
    } catch (error: any) {
      // dispatch(
      //   updateAlertInfo({
      //     status: AlertType.ERROR,
      //     msg: error.message.message,
      //   }),
      // );
      throw error;
    }
  };

  return {
    handleDeleteUser,
    handleSendSignInLinkEmail, // send mail for new user registation
    handleSendPasswordResetEmail, // send mail for forgetting password
    handlechangeEmailVerification, // send verification mail to new email
  };
}
