import AppChartRadar from "../../AppChartRadar/appChartRadar";
import { ChartRadarDataType } from "../../AppChartRadar/appChartRadarType";
import { AdvicePanel } from "../../moleclues";
import { AppSecondaryButton } from "../../AppSecondaryButton/appSecondaryButton";
import { useIntl } from "react-intl-phraseapp";

interface HomeScoreProps {
  score: ChartRadarDataType;
  symptomAdvice: string;
  handleNavToSymptomRecordMain?: () => void;
}

export function HomeScore({ score, symptomAdvice, handleNavToSymptomRecordMain }: HomeScoreProps) {
  const intl = useIntl();
  return (
    <>
      <div className="score-chart-panel">
        <AppChartRadar
          config={{
            indicator: [
              {
                name: intl.formatMessage({
                  id: "page.main_tab.disease_main_tab.disease_main.score.activity",
                }),
                max: 100,
                color: "black",
              },
              {
                name: intl.formatMessage({
                  id: "page.main_tab.disease_main_tab.disease_main.score.symptom",
                }),
                max: 100,
                color: "black",
              },
              {
                name: intl.formatMessage({
                  id: "page.main_tab.disease_main_tab.disease_main.score.vital",
                }),
                max: 100,
                color: "black",
              },
              {
                name: intl.formatMessage({
                  id: "page.main_tab.disease_main_tab.disease_main.score.condition",
                }),
                max: 100,
                color: "black",
              },
            ],
            splitNumber: 4,
          }}
          score={score}
        />
      </div>

      {score.avg.length > 0 && !!symptomAdvice && (
        <>
          <div className="page-spacer-45" />
          <AdvicePanel contents={symptomAdvice} />
        </>
      )}

      {!!handleNavToSymptomRecordMain && (
        <>
          <div className="page-spacer-45" />
          <div className="div_center">
            <AppSecondaryButton isDynamicWidth transparent onClick={handleNavToSymptomRecordMain}>
              {intl.formatMessage({
                id: "page.main_tab.disease_main_tab.disease_main.button.view_symptom_record",
              })}
            </AppSecondaryButton>
          </div>
        </>
      )}
    </>
  );
}
