import { actionConst } from "../constants";
import { ContactType } from "../types";

export const clearContact = () => {
  return {
    type: actionConst.CLEAR_CONTACT,
    state: {},
  };
};

export const updateContact = (state: ContactType) => {
  return {
    type: actionConst.UPDATE_CONTACT,
    state,
  };
};
