import { useState } from "react";
import { useRouteMatch } from "react-router";
import { useIonViewWillEnter } from "@ionic/react";
import { FaqContentType } from "src/web/types";
import { useFaqsModel } from "src/web/hooks/models";

export function useFaqDetailControl() {
  const match: any = useRouteMatch();
  const faqId: string = match.params.faqId;

  const { fetchFaqContents } = useFaqsModel();

  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState<FaqContentType>();

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const currentFaq = await fetchFaqContents(faqId);
      if (currentFaq) {
        setInfo(currentFaq);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useIonViewWillEnter(() => {
    fetchData();
  });

  return {
    isLoading,
    info,
  };
}
