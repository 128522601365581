export function MarkdownA(props: any) {
  return (
    <a
      onClick={() => {
        window.open(props.children, "_system", "location=yes");
      }}
    >
      {props.children}
    </a>
  );
}
