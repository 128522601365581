import { IonLabel } from "@ionic/react";
import { useState, useRef, useEffect } from "react";
import {
  AppDoublePicker,
  AppFixedActionSheet,
  AppIcon,
  AppStatefulCheckbox,
} from "../../../../components/UiComponents";
import { HOUR_KV_OPTIONS, ICONS, MINUTE_KV_OPTIONS } from "../../../../constants";
import { getNow, trimLeftZero } from "../../../../utils/common";
import { CheckMedicineInfoCardType } from "../MedicationManagement/types";
import { MedicationStatusCard } from "../MedicationStatusCard/medicationStatusCard";
import styles from "./medicineCheckSheet.module.css";
import { useIntl } from "react-intl-phraseapp";

interface MedicineCheckModalProps {
  isOpen: boolean;
  dayTimeAndType?: string;
  data?: CheckMedicineInfoCardType;
  handleClose: () => void;
  handleConfirm: (status: string, time: string) => void;
}

export function MedicineCheckSheet({
  isOpen,
  dayTimeAndType,
  data,
  handleClose,
  handleConfirm,
}: MedicineCheckModalProps) {
  const intl = useIntl();
  const timeRef = useRef<any>();
  // 服用状態
  const [status, setStatus] = useState("");
  // 服用時間
  const [time, setTime] = useState("");

  useEffect(() => {
    setStatus(data?.status ?? "");
    setTime(data?.check_time ? trimLeftZero(data.check_time) : "");
  }, [data?.status, data?.check_time]);

  return (
    <AppFixedActionSheet
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={() => {
        handleConfirm(status, timeRef?.current?.getSelectedData());
      }}
      title={intl.formatMessage({ id: "page.medicine_check_sheet.title" })}
    >
      <div className={styles.content}>
        <div className={`${styles.scrollable}`}>
          <div className={`${styles.scrollContent}`}>
            {dayTimeAndType && (
              <>
                <IonLabel className="p-body">{dayTimeAndType}</IonLabel>
                <div className="page-spacer-30" />
              </>
            )}
            <MedicationStatusCard
              showCheckbox={false}
              showEditBox={false}
              showWhite={false}
              data={data}
            />
            <div className="page-spacer-45" />
            <div className={styles.checkTitle}>
              {intl.formatMessage({ id: "page.medicine_check_sheet.label.status" })}
            </div>
            <div className="page-spacer-35" />

            <div
              className={styles.checkDiv}
              onClick={() => {
                if (status !== "0") {
                  setStatus("0");
                } else {
                  setStatus("");
                }
              }}
            >
              <div className="flex-row-start">
                <AppIcon icon={ICONS.ICON.CHECK_CIRCLE_ACTIVE} />
                <IonLabel className={`p-smallbody ${styles.titleWithIcon}`}>
                  {intl.formatMessage({ id: "page.medicine_check_sheet.status.recorded" })}
                </IonLabel>
              </div>
              {status === "0" && <AppIcon icon={ICONS.ICON.CHECK} size="sm" />}
            </div>
            {data?.category === 0 && (
              <>
                <div className="page-spacer-20" />
                <div
                  className={styles.checkDiv}
                  onClick={() => {
                    if (status !== "1") {
                      setStatus("1");
                    } else {
                      setStatus("");
                    }
                  }}
                >
                  <div className="flex-row-start">
                    <AppIcon icon={ICONS.ICON.CLOSE_CIRCLE} />
                    <IonLabel className={`p-smallbody ${styles.titleWithIcon}`}>
                      {intl.formatMessage({ id: "page.medicine_check_sheet.status.forgot" })}
                    </IonLabel>
                  </div>
                  {status === "1" && <AppIcon icon={ICONS.ICON.CHECK} size="sm" />}
                </div>
                <div className="page-spacer-20" />
                <div
                  className={styles.checkDiv}
                  onClick={() => {
                    if (status !== "2") {
                      setStatus("2");
                    } else {
                      setStatus("");
                    }
                  }}
                >
                  <div className="flex-row-start">
                    <AppIcon icon={ICONS.ICON.MINUS_CIRCLE} />
                    <IonLabel className={`p-smallbody ${styles.titleWithIcon}`}>
                      {intl.formatMessage({ id: "page.medicine_check_sheet.status.skip" })}
                    </IonLabel>
                  </div>
                  {status === "2" && <AppIcon icon={ICONS.ICON.CHECK} size="sm" />}
                </div>
              </>
            )}

            <div className="page-spacer-50" />
            {status === "0" && (
              <>
                <div className={styles.checkTitle}>
                  {intl.formatMessage({ id: "page.medicine_check_sheet.medication_time" })}
                </div>
                <div className="page-spacer-30" />
                <AppDoublePicker
                  ref={timeRef}
                  initVal={time}
                  defaultVal={getNow(true)}
                  options={HOUR_KV_OPTIONS}
                  options2={MINUTE_KV_OPTIONS}
                  separatedBy=":"
                  isLoop={true}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </AppFixedActionSheet>
  );
}
