import { IonLabel } from "@ionic/react";
import styles from "./styles.module.css";

interface AppMedicineDetail1Props {
  title: string;
  children: any;
}

export const AppMedicineDetail1 = ({
  title,
  children
}: AppMedicineDetail1Props,
) => {

  return (
    <div className="listTitle">
      <IonLabel className="p-body">{title}</IonLabel>
      <div className="page-spacer-15" />
      <IonLabel className={`p-smallbody ${styles.displayWrap}`}>
        {children}
      </IonLabel>
    </div>
  );
};
