import { useState } from "react";
import { useSelector } from "react-redux";
import {
  AppFixedActionSheet,
  ActionSheetCard,
  AppCalendarNoStatusCommonSheet,
} from "src/web/components/UiComponents";
import { getNDaysBeforeOrAfterByDay, getToday } from "src/web/utils/common";
import { LoadingTimelineType, LoadingAnswerAndStatusType } from "src/web/types";
import { useAppRouter } from "src/web/hooks/useAppRouter";
import { RootState } from "src/web/reducer";
import { useRecordTabControl } from "./hooks/useRecordTabControl";
import { useIntl } from "react-intl-phraseapp";
import {
  DateSwitcherWithSummaryCard,
  ReportContent,
  ReportDataList,
  OverallTimeline,
} from "src/web/components/UiComponents/organisms";
import styles from "./recordTab.module.css";

interface RecordTabProps {
  currentDate: string;
  isReadonly: boolean;
}

// default to all null
const default_summary_score = {
  a: 0,
  b: 0,
  c: 0,
  d: 0,
  a_r: "",
  b_r: "",
  c_r: "",
  d_r: "",
  score: 0,
};

export function RecordTab({ currentDate, isReadonly }: RecordTabProps) {
  const intl = useIntl();
  const ionRouter = useAppRouter();

  const {
    dailyActivityTimeline,
    dailyAnswersAndStatus,
  }: {
    dailyActivityTimeline: LoadingTimelineType;
    dailyAnswersAndStatus: LoadingAnswerAndStatusType;
  } = useSelector((state: RootState) => state);

  const {
    actionSheets,
    formattedCurrentDateWithDay,
    isDisableNextDay,
    showActionSheet,
    handleOpenActionSheet,
    handleCloseActionSheet,
    handleActionsheetClick,
  } = useRecordTabControl(currentDate);

  const [isOpenCalender, setIsOpenCalender] = useState(false);

  return (
    <>
      <DateSwitcherWithSummaryCard
        text={formattedCurrentDateWithDay}
        isDisableNextDay={isDisableNextDay}
        currentDate={currentDate}
        summary_score={
          dailyAnswersAndStatus?.data?.questionnaireAndScore?.summary_score ?? default_summary_score
        }
        handleLeftClick={() => {
          ionRouter.push(`/home/reports?date=${getNDaysBeforeOrAfterByDay(-1, currentDate)}`);
        }}
        handleRightClick={() => {
          ionRouter.push(`/home/reports?date=${getNDaysBeforeOrAfterByDay(1, currentDate)}`);
        }}
        handleLabelClick={() => {
          setIsOpenCalender(true);
        }}
      />

      <div className="page-spacer-60" />

      <OverallTimeline
        header={intl.formatMessage({ id: "page.symptom_records.day_records.title" })}
        btnText={intl.formatMessage({ id: "page.symptom_records.day_records.button.register" })}
        currentDate={currentDate}
        isReadonly={isReadonly}
        dailyActivityTimeline={dailyActivityTimeline}
        btnClick={handleOpenActionSheet}
      />

      <ReportDataList
        dailyAnswersAndStatus={dailyAnswersAndStatus}
        currentDate={currentDate}
        isReadonly={isReadonly}
      />

      <div className="page-spacer-60" />

      <ReportContent
        header={intl.formatMessage({ id: "page.symptom_records.diary.label" })}
        isReadonly={isReadonly}
        btnText={intl.formatMessage({ id: "page.symptom_records.diary.button.edit" })}
        btnClick={() => {
          ionRouter.push(`/symptom-questionnaire?currentDate=${currentDate}&isDaily=true`);
        }}
        contents={dailyAnswersAndStatus?.data?.questionnaireAndScore?.daily}
      />

      <AppFixedActionSheet
        isOpen={showActionSheet}
        sheetHeight={520}
        handleClose={handleCloseActionSheet}
        title={intl.formatMessage({
          id: "page.main_tab.disease_main_tab.disease_main.label.action_list",
        })}
      >
        <div className={styles.cardActionSheetRoot}>
          {actionSheets.map((action: any, index: any) => (
            <ActionSheetCard
              title={action.title}
              icon={action.icon}
              desc={action.desc}
              key={index}
              onClick={() => {
                handleActionsheetClick(action);
              }}
            />
          ))}
        </div>
      </AppFixedActionSheet>

      <AppCalendarNoStatusCommonSheet
        isOpen={isOpenCalender}
        selectedDate={new Date(currentDate)}
        disabledAfterDate={getToday()}
        closeCarlendar={() => setIsOpenCalender(false)}
        handleConfirmDate={(val) => {
          setIsOpenCalender(false);
          ionRouter.push(`/home/reports?date=${val}`);
        }}
      />
    </>
  );
}
