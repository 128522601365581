import { CartesianGrid } from "recharts";
import { getColor } from "../../../utils/common";

const PSP_GRAY_01_COLOR = "--psp-gray01";
const PSP_GRAY_02_COLOR = "--psp-gray02";
const CURRENT_TEXT_COLOR = "--ion-text-color";

export class CustomCartesianGrid extends CartesianGrid {
  // 線の色を修正します。
  override renderHorizontal(horizontalPoints: number[]): any {
    const { x, width, horizontal } = this.props;

    if (!horizontalPoints?.length) {
      return null;
    }

    const items = horizontalPoints.map((entry, i) => {
      let strokeWidth = 1;
      // 他の線の色はGRAY_02
      // 真ん中の線色はGRAY_01
      // 最後の線はblack
      let strokeColor = getColor(PSP_GRAY_02_COLOR);
      if (i === 4) {
        strokeColor = getColor(PSP_GRAY_01_COLOR);
      } else if (i === 8) {
        strokeColor = getColor(CURRENT_TEXT_COLOR);
        strokeWidth = 2;
      }

      const props = {
        ...this.props,
        x1: x,
        y1: entry,
        x2: x! + width!,
        y2: entry,
        key: `line-${i}`,
        index: i,
        stroke: strokeColor,
        strokeWidth: strokeWidth,
        strokeLinecap: "round",
      };

      return CartesianGrid.renderLineItem(horizontal!, props);
    });

    return <g className="recharts-cartesian-grid-horizontal">{items}</g>;
  }
}
