import { useEffect } from "react";
import { useAppRouter } from "src/web/hooks/useAppRouter";

export function useAccountsWithdrawCompleteControl() {
  const iRouter = useAppRouter();

  const handleBackToLogin = () => {
    iRouter.push("/login");
  };

  useEffect(() => {
    // handle native back:
    const handleNativeBack = (ev: any) => {
      ev.detail.register(10, () => {
        // nav to login page
        handleBackToLogin();
      });
    };
    document.addEventListener("ionBackButton", handleNativeBack);

    return () => {
      document.removeEventListener("ionBackButton", handleNativeBack);
    };
  }, []);

  return {
    handleBackToLogin,
  };
}
