import { useState } from "react";
import { useSelector } from "react-redux";
import { ICONS } from "../../../../../../constants";
import { useAppRouter } from "../../../../../../hooks/useAppRouter";
import { RootState } from "../../../../../../reducer";
import { getNow, getToday } from "../../../../../../utils/common";
import { LoadingAnswerAndStatusType } from "src/web/types";
import { useIntl } from "react-intl-phraseapp";
import { useCommon } from "src/web/utils/hooks/common";

export function useRecordTabControl(currentDate: string) {
  const ionRouter = useAppRouter();
  const intl = useIntl();
  const { convertToDate } = useCommon();

  const {
    dailyAnswersAndStatus,
  }: {
    dailyAnswersAndStatus: LoadingAnswerAndStatusType;
  } = useSelector((state: RootState) => state);

  const [showActionSheet, setShowActionSheet] = useState(false);

  const handleOpenActionSheet = () => {
    setShowActionSheet(true);
  };

  const handleCloseActionSheet = () => {
    setShowActionSheet(false);
  };

  const handleActionsheetClick = (action: any) => {
    handleCloseActionSheet();
    if (action.onClick) {
      action.onClick(currentDate);
    }
  };

  const getActionSheet = () => {
    const now = getNow();
    const timeStr = now.replace(":", "");
    let actions = [
      {
        key: "bloodPressure",
        title: intl.formatMessage({
          id: "page.main_tab.disease_main_tab.disease_main.blood_pressure.menu.title",
        }),
        icon: ICONS.IMAGE.BLOOD_PRESSURE,
        desc: intl.formatMessage({
          id: "page.main_tab.disease_main_tab.disease_main.blood_pressure.menu.night",
        }),
        onClick: (date: string) => {
          ionRouter.push(`/reports/vitals/blood-pressure-records/edit?currentDate=${date}`);
        },
      },
      {
        key: "bodyTemperature",
        title: intl.formatMessage({
          id: "page.main_tab.disease_main_tab.disease_main..menu.title",
        }),
        icon: ICONS.IMAGE.THERMOMETER,
        desc: intl.formatMessage(
          { id: "page.main_tab.disease_main_tab.disease_main.temperature.menu.sub_title" },
          { hours: 1 },
        ),
        onClick: () => {},
      },
      {
        key: "symptomQuestionnaire",
        title: intl.formatMessage({
          id: "page.main_tab.disease_main_tab.disease_main.questionnaire.menu.title",
        }),
        icon: ICONS.IMAGE.MIND_AND_BODY_CONDITION,
        desc: intl.formatMessage({
          id: "page.main_tab.disease_main_tab.disease_main.blood_pressure.menu.morning",
        }),
        onClick: (date: string) => {
          ionRouter.push(`/symptom-questionnaire?currentDate=${date}&reportFlg=1`);
        },
      },
      {
        key: "dailySymptom",
        title: intl.formatMessage({
          id: "page.main_tab.disease_main_tab.disease_main.daily_symptom.menu.title",
        }),
        icon: ICONS.IMAGE.BODY_CONDITION,
        desc: now,
        onClick: (date: string) => {
          ionRouter.push(`/symptom-entering-details?date=${date}&time=${timeStr}`);
        },
      },
    ];

    if (!dailyAnswersAndStatus?.data?.isSymptomQuestionnaireUnfinished) {
      actions.splice(2, 1);
    }

    if (!dailyAnswersAndStatus?.data?.isBloodPressureUnfinished) {
      actions.shift();
    }

    return actions;
  };

  return {
    actionSheets: getActionSheet(),
    formattedCurrentDateWithDay: convertToDate(currentDate),
    isDisableNextDay: currentDate >= getToday(),
    showActionSheet,
    handleOpenActionSheet,
    handleCloseActionSheet,
    handleActionsheetClick,
  };
}
