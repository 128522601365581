import { useMemo } from "react";
import { LineItemWithIcon } from "../../moleclues";
import { DeviceInfoType } from "src/web/plugin/healthcare-manager-plugin";
import { SubMenuType } from "src/web/types";

interface ModalListItemGroupProps {
  list: DeviceInfoType[];
  handleNext: (device: DeviceInfoType) => void;
}

export function ModalListItemGroup({ list, handleNext }: ModalListItemGroupProps) {
  const optionList: SubMenuType[] = useMemo(() => {
    return list.map((item) => ({
      id: item.deviceId,
      title: item.deviceName,
    }));
  }, [list]);

  return (
    <>
      {optionList.map((item, index) => (
        <div
          onClick={() => {
            !!item.id && handleNext(list[index]);
          }}
        >
          <LineItemWithIcon item={item} isGray />
        </div>
      ))}
    </>
  );
}
