import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonBackButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonFooter,
  IonInput,
  IonFab,
  IonFabButton,
  IonModal,
  IonText,
  IonLabel,
} from "@ionic/react";
import { AppIcon } from "../components/UiComponents";
import { closeOutline, close, send, appsOutline, apps, add, settings } from "ionicons/icons";
import { useState } from "react";
import "./Chat.css";
import ChatButton from "../components/ChatButton";
import ChatMenu from "../components/ChatMenu";
import { ICONS } from "../constants";

const openChat = () => {};

export const ButtonList = [
  "症状について",
  "薬について",
  "手術について",
  "検査について",
  "治癒について",
  "生活について",
];

const Chat: React.FC = () => {
  const [buttonState, setButtonState] = useState(false);
  const [showModal, setShowModal] = useState(false);

  let date = new Date();
  const handleAppClick = () => {
    setShowModal(true);
  };
  const handleButtonClick = () => {
    setButtonState(true);
  };
  return (
    <IonPage>
      <IonHeader className="chatHead">
        <IonToolbar className="chatToolBar">
          <IonTitle>
            CHATBOT <br />
            SUPPORT
          </IonTitle>

          <IonButtons slot="end">
            <IonButton href="/home/main">
              <IonIcon slot="start" icon={close} size="large"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonGrid>
          <IonRow>
            <IonCol size="10" className="my-message">
              <IonCard onClick={handleButtonClick}>
                {/* <IonCardHeader className="CardTitle">
                                    <IonCardTitle>Card Title</IonCardTitle>
                                </IonCardHeader> */}
                <IonCardContent className="cardContent">
                  最近は調子が安定しているようですね!何かお困りですか?
                  <IonGrid fixed={true}>
                    <IonRow>
                      {ButtonList.slice(0, 3).map((content) => {
                        return <ChatButton content={content}></ChatButton>;
                      })}

                      {ButtonList.slice(3, 6).map((content) => {
                        return <ChatButton content={content}></ChatButton>;
                      })}
                    </IonRow>
                  </IonGrid>
                  その他のご用件やご質問も自由に入力してください。
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol size="" className="timeCol">
              <IonText className="timeText">10:13</IonText>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="" className="timeCol">
              <IonText hidden={!buttonState} className="timeText">
                10:14
              </IonText>
            </IonCol>
            <IonCol size="8">
              <IonCard hidden={!buttonState} color="dark">
                <IonCardContent>症状について</IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          {/* <IonRow>
                        <IonCol size=""></IonCol>
                        <IonCol size="8">
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Card Title</IonCardTitle>
                                </IonCardHeader>

                                <IonCardContent>
                                    Keep close to Nature's heart... and break
                                    clear away, once in awhile, and climb a
                                    mountain or spend a week in the woods. Wash
                                    your spirit clean.
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size=""></IonCol>
                        <IonCol size="8">
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Card Title</IonCardTitle>
                                </IonCardHeader>

                                <IonCardContent>
                                    Keep close to Nature's heart... and break
                                    clear away, once in awhile, and climb a
                                    mountain or spend a week in the woods. Wash
                                    your spirit clean.
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size=""></IonCol>
                        <IonCol size="8">
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Card Title</IonCardTitle>
                                </IonCardHeader>

                                <IonCardContent>
                                    Keep close to Nature's heart... and break
                                    clear away, once in awhile, and climb a
                                    mountain or spend a week in the woods. Wash
                                    your spirit clean.
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow> */}
        </IonGrid>
        {/* <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton color="medium">
                        <IonIcon name={add} size="large" />
                    </IonFabButton>
                </IonFab> */}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton className="fab-button" color="primary" onClick={handleAppClick}>
            <AppIcon icon={ICONS.FLOATING.MENU_CIRCLE} size="xl" />
          </IonFabButton>
        </IonFab>

        <IonModal
          isOpen={showModal}
          initialBreakpoint={0.5}
          breakpoints={[0, 0.5, 1]}
          onDidDismiss={() => setShowModal(false)}
        >
          <IonGrid fixed={true} className="chatModal">
            <IonRow>
              <IonTitle color="light" className="questionText">
                何について質問しますか？
              </IonTitle>
            </IonRow>
            <IonRow>
              {ButtonList.slice(0, 3).map((content) => {
                return <ChatButton content={content}></ChatButton>;
              })}

              {ButtonList.slice(3, 6).map((content) => {
                return <ChatButton content={content}></ChatButton>;
              })}
            </IonRow>
          </IonGrid>
        </IonModal>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonCard>
            <IonInput placeholder="input text"></IonInput>
          </IonCard>
          <IonButtons slot="end">
            <IonButton>
              <IonIcon slot="start" icon={send} size="large"></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Chat;
