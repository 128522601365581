import { IonGrid } from "@ionic/react";
import { RecordedAnswerType } from "src/web/types";
import { AppTextarea } from "../..";
import { DateWithInstruction } from "../../moleclues";
import { useIntl } from "react-intl-phraseapp";

export const QuestionTextArea = ({
  dateStr,
  checkedAnswers,
  setCheckedAnswers,
}: {
  dateStr: string;
  checkedAnswers?: RecordedAnswerType;
  setCheckedAnswers?: React.Dispatch<React.SetStateAction<RecordedAnswerType | undefined>>;
}) => {
  const intl = useIntl();
  return (
    <IonGrid>
      <DateWithInstruction
        dateStr={dateStr}
        header2={intl.formatMessage({ id: "components.question.question_text_area.title" })}
      />

      <div className="page-spacer-45" />

      <AppTextarea
        value={checkedAnswers?.daily}
        onIonChange={(e: any) => {
          setCheckedAnswers &&
            checkedAnswers &&
            setCheckedAnswers({
              ...checkedAnswers,
              daily: e.detail.value,
            });
        }}
        placeholder={intl.formatMessage({
          id: "components.question.question_text_area.place_holder",
        })}
      />
    </IonGrid>
  );
};
