import { actionConst } from "../constants";

export const clearTimelineScrollHook = () => {
  return {
    type: actionConst.CLEAR_TIMELINE_SCROLL_HOOK,
  };
};

export const updateTimelineScrollHook = (hook: string) => {
  return {
    type: actionConst.UPDATE_TIMELINE_SCROLL_HOOK,
    hook,
  };
};
