import { IonContent, IonPage } from "@ionic/react";
import {
  AppHeader,
  AppHomeIndicatorBar,
  AppLoading,
  AppPrimaryButton,
} from "../../components/UiComponents";
import { UserBaseInfoForm } from "src/web/components/UiComponents/organisms";
import { useModifyUserBaseInfoControl } from "./hooks/useModifyUserBaseInfoControl";
import { useIntl } from "react-intl-phraseapp";

export default function ModifyUserBaseInfo() {
  const { type, FIELDS_CONFIG, isLoading, isSubmitDisabled, doSave } =
    useModifyUserBaseInfoControl();

  const intl = useIntl();
  return (
    <>
      <IonPage>
        <AppHeader />

        <IonContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <UserBaseInfoForm fieldsConfig={FIELDS_CONFIG} type={type} />

            <div className="page-spacer-60" />

            <AppPrimaryButton full disabled={isSubmitDisabled} onClick={doSave}>
              {intl.formatMessage({ id: "page.modify_user_base_info.button.save" })}
            </AppPrimaryButton>
          </div>

          <AppHomeIndicatorBar />
        </IonContent>
      </IonPage>

      <AppLoading isOpen={isLoading} />
    </>
  );
}
