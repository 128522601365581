import { IonLabel } from "@ionic/react";
import { useIntl } from "react-intl-phraseapp";
import { CategoryHeader } from "../../moleclues";

interface ReportContentProps {
  header: string;
  isReadonly: boolean;
  btnText: string;
  contents?: string;
  btnClick: () => void;
}

export function ReportContent({
  header,
  isReadonly,
  btnText,
  contents,
  btnClick,
}: ReportContentProps) {
  const intl = useIntl();

  return (
    <>
      <CategoryHeader
        header={header}
        isReadonly={isReadonly}
        btnText={btnText}
        btnClick={btnClick}
      />

      <div className="page-spacer-45" />

      <IonLabel className="p-smallbody text-block pre-wrap">
        {contents || intl.formatMessage({ id: "common.not_inputed" })}
      </IonLabel>
    </>
  );
}
