import { useState, useEffect, useRef } from "react";
import { IonPage, IonContent, IonLabel, useIonViewWillEnter, ScrollDetail } from "@ionic/react";
import { useSelector, useDispatch } from "react-redux";
import { AppFloatButton, AppHeader, AppInput, AppLoading } from "../../components/UiComponents";
import { MEDICINE_TYPE } from "../../constants";
import { updatePrescriptionInfo } from "../../actions";
import { RootState } from "../../reducer";
import { PrescriptionInfoType } from "../PrescriptionInformation/type";
import { useAppRouter } from "../../hooks/useAppRouter";
import styles from "./prescriptionFee.module.css";
import { useIntl } from "react-intl-phraseapp";
import { updatePrescriptionFeeById } from "src/web/services";

const convertStringNum = (val: string) => {
  if (!val) {
    return undefined;
  }

  let ret = Number.parseInt(val);

  if (isNaN(ret)) {
    return 0;
  }

  return ret;
};

const convertValNum = (val?: number) => {
  if (!Number.isInteger(val)) {
    return "";
  }
  return String(val);
};

export function PrescriptionFee() {
  const intl = useIntl();
  const num_error = intl.formatMessage({ id: "page.prescription_fee.number.error_message" });

  const ionRouter = useAppRouter();

  const dispatch = useDispatch();

  const convertDisplayNum = (val?: number) => {
    // 数字を文字単位に変更する
    if (!Number.isInteger(val)) {
      return "0 " + intl.formatMessage({ id: "page.prescription_fee.cost.unit" });
    }

    let formatData = String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formatData + " " + intl.formatMessage({ id: "page.prescription_fee.cost.unit" });
  };

  const currentData: PrescriptionInfoType = useSelector((state: RootState) => {
    return state.prescriptionInformation;
  });

  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  useIonViewWillEnter(() => {
    try {
      scrollToTop();
    } catch {}
  });

  const [loading, setLoading] = useState(false);

  // 配列計算
  const arrField = ["hospital_fee", "pharmacy_fee", "moving_fee"];

  const calSummary = (notKey: string, value?: string) => {
    // 定義する
    let sum: number = 0;
    for (let key of arrField) {
      if (key === notKey) {
        continue;
      }

      let val = (formData as any)[key];
      if (!val) {
        continue;
      }

      let numVal = Number.parseInt(val);
      if (numVal) {
        sum += numVal;
      }
    }

    if (value) {
      let numVal = Number.parseInt(value);
      if (numVal) {
        sum += numVal;
      }
    }

    setFormDataValue(notKey, value, sum);
  };

  const [formData, setFormData] = useState({
    hospital_fee: convertValNum(currentData.hospital_fee),
    pharmacy_fee: convertValNum(currentData.pharmacy_fee),
    moving_fee: convertValNum(currentData.moving_fee),
    summary_fee: currentData.summary_fee,
  });

  const [formError, setFormError] = useState({
    isHospitalFeeError: false,
    isPharmacyFeeError: false,
    isMovingFeeError: false,
  });

  const setFormDataValue = (field: string, value: any, summary?: number) => {
    const newData = {
      ...formData,
      [field]: value,
      summary_fee: summary,
    };
    setFormData(newData);
  };

  useEffect(() => {}, []);

  // スクロール関連
  const [isDisplayBorder, setIsDisplayBorder] = useState(false);

  const handleScroll = (ev: CustomEvent<ScrollDetail>) => {
    // setIsDisplayBorder(true);
    if (ev.detail.scrollTop === 0) {
      setIsDisplayBorder(false);
    } else {
      setIsDisplayBorder(true);
    }
  };

  return (
    <>
      <IonPage>
        <AppHeader isDisplayBorder={isDisplayBorder} />
        <IonContent ref={contentRef} scrollEvents={true} onIonScroll={handleScroll}>
          <div className="root-content">
            {/* データタイトル */}
            <div className="page-spacer-45" />
            <IonLabel className={styles.pageTitle}>
              {intl.formatMessage({ id: "page.prescription_fee.title" })}
            </IonLabel>
            {/* 処方薬の場合表示 */}
            {currentData.type === MEDICINE_TYPE.PRESCRIPTION && (
              <>
                <div className="page-spacer-45" />
                <IonLabel className={styles.displayTitle}>
                  {intl.formatMessage({ id: "page.prescription_fee.hospital_fee" })}
                </IonLabel>
                <div className="page-spacer-15" />
                <div className="flex-row-start">
                  <div className={styles.inputWithUnit}>
                    <AppInput
                      error={formError.isHospitalFeeError}
                      errorMsg={num_error}
                      maxlength="10"
                      inputmode="numeric"
                      value={formData.hospital_fee}
                      onIonChange={(event: any) => {
                        let val = event.detail.value;
                        calSummary("hospital_fee", val);
                        setFormError({
                          ...formError,
                          isHospitalFeeError: false,
                        });
                      }}
                    />
                  </div>
                  <IonLabel className={styles.unitDiv}>
                    {intl.formatMessage({ id: "page.prescription_fee.cost.unit" })}
                  </IonLabel>
                </div>
              </>
            )}
            <div className="page-spacer-45" />
            <IonLabel className={styles.displayTitle}>
              {currentData.type === MEDICINE_TYPE.PRESCRIPTION
                ? intl.formatMessage({ id: "page.prescription_fee.total.prescription" })
                : intl.formatMessage({ id: "page.prescription_fee.total.market" })}
            </IonLabel>
            <div className="page-spacer-15" />
            <div className="flex-row-start">
              <div className={styles.inputWithUnit}>
                <AppInput
                  error={formError.isPharmacyFeeError}
                  errorMsg={num_error}
                  maxlength="10"
                  inputmode="numeric"
                  value={formData.pharmacy_fee}
                  onIonChange={(event: any) => {
                    let val = event.detail.value;
                    calSummary("pharmacy_fee", val);
                    setFormError({
                      ...formError,
                      isPharmacyFeeError: false,
                    });
                  }}
                />
              </div>
              <IonLabel className={styles.unitDiv}>
                {intl.formatMessage({ id: "page.prescription_fee.cost.unit" })}
              </IonLabel>
            </div>
            {/* 処方薬の場合表示 */}
            {currentData.type === MEDICINE_TYPE.PRESCRIPTION && (
              <>
                <div className="page-spacer-45" />
                <IonLabel className={styles.displayTitle}>
                  {intl.formatMessage({ id: "page.prescription_fee.moving_fee" })}
                </IonLabel>
                <div className="page-spacer-15" />
                <div className="flex-row-start">
                  <div className={styles.inputWithUnit}>
                    <AppInput
                      error={formError.isMovingFeeError}
                      errorMsg={num_error}
                      maxlength="10"
                      inputmode="numeric"
                      value={formData.moving_fee}
                      onIonChange={(event: any) => {
                        let val = event.detail.value;
                        calSummary("moving_fee", val);
                        setFormError({
                          ...formError,
                          isMovingFeeError: false,
                        });
                      }}
                    />
                  </div>
                  <IonLabel className={styles.unitDiv}>
                    {intl.formatMessage({ id: "page.prescription_fee.cost.unit" })}
                  </IonLabel>
                </div>
              </>
            )}
            {/* 処方薬の場合表示 */}
            {currentData.type === MEDICINE_TYPE.PRESCRIPTION && (
              <>
                <div className="page-spacer-45" />
                <IonLabel className={styles.displayTitle}>
                  {intl.formatMessage({ id: "page.prescription_fee.total" })}
                </IonLabel>
                <div className="page-spacer-15" />
                <div className={styles.summaryDiv}>{convertDisplayNum(formData.summary_fee)}</div>
              </>
            )}
            <div className="page-spacer-30" />
          </div>

          <AppFloatButton
            label={intl.formatMessage({ id: "page.prescription_fee.button.complete" })}
            onClick={async () => {
              for (let data in formError) {
                if ((formError as any)[data]) {
                  return;
                }
              }

              // チェック数値エラー
              if (formData.hospital_fee && !/^\d+$/.test(formData.hospital_fee)) {
                setFormError({
                  ...formError,
                  isHospitalFeeError: true,
                });
                return;
              }

              if (formData.pharmacy_fee && !/^\d+$/.test(formData.pharmacy_fee)) {
                setFormError({
                  ...formError,
                  isPharmacyFeeError: true,
                });
                return;
              }

              if (formData.moving_fee && !/^\d+$/.test(formData.moving_fee)) {
                setFormError({
                  ...formError,
                  isMovingFeeError: true,
                });
                return;
              }

              const newData = {
                ...currentData,
                hospital_fee: convertStringNum(formData.hospital_fee),
                pharmacy_fee: convertStringNum(formData.pharmacy_fee),
                moving_fee: convertStringNum(formData.moving_fee),
                summary_fee: formData.summary_fee,
              };

              // idは存在する場合
              if (currentData.id) {
                const updateDate = {
                  id: newData.id,
                  hospital_fee: newData.hospital_fee,
                  pharmacy_fee: newData.pharmacy_fee,
                  moving_fee: newData.moving_fee,
                  summary_fee: newData.summary_fee,
                };

                setLoading(true);
                try {
                  // 新規
                  await updatePrescriptionFeeById(currentData.id, updateDate);
                  // error 処理
                } catch {
                  // todo
                } finally {
                  setLoading(false);
                }
              }

              dispatch(updatePrescriptionInfo(newData));
              ionRouter.goBack();
            }}
          />
        </IonContent>
      </IonPage>
      <AppLoading isOpen={loading} />
    </>
  );
}
