import { useState } from "react";
import { IonButton } from "@ionic/react";
import { AppIcon } from "../";
import { BUTTON_TIMEOUT } from "../../../constants";
import styles from "./styles.module.css";

let primaryBtnAnimationTimeout: any = null;
const primaryBtnInitClickingState = 0;

export function AppPrimaryButton(props: any) {
  const btnSmall = props.small as boolean;

  const [isClicking, setIsClicking] = useState<number>(primaryBtnInitClickingState);

  const click = (event: any) => {
    //動画を追加する
    clearTimeout(primaryBtnAnimationTimeout);
    setIsClicking(isClicking + 1);
    primaryBtnAnimationTimeout = setTimeout(() => {
      setIsClicking(primaryBtnInitClickingState);
      props.onClick && props.onClick(event);
    }, BUTTON_TIMEOUT);
  };

  return (
    <div className={props.full ? "div_center" : ""}>
      <IonButton
        key={isClicking}
        {...props}
        className={`${btnSmall ? styles.PrimaryButtonSmall : styles.PrimaryButton} ${
          props.className
        } ${props.icon ? styles.iconBtn : ""} ${
          props.disabled === true && styles.PrimaryButtonDisabled
        } ${
          isClicking !== primaryBtnInitClickingState
            ? btnSmall
              ? styles.PrimaryButtonSmallAnimation
              : styles.PrimaryButtonAnimation
            : ""
        } ${props.full ? styles.fullWidth : ""}`}
        onClick={click}
      >
        <div className={styles.label}>
          {props.children}
          {props.icon && <AppIcon size="sm" icon={props.icon} className={styles.icon} />}
        </div>
      </IonButton>
    </div>
  );
}
