import { useRef, useState, useEffect } from "react";
import { IonLabel } from "@ionic/react";
import {
  AppDoublePicker,
  AppFixedActionSheet,
  AppInput,
  AppSecondaryButton,
  AppToggleButton,
} from "../../../../components/UiComponents";
import { HOUR_KV_OPTIONS, MINUTE_STEP_KV_OPTIONS } from "../../../../constants";
import { useTextConstants } from "src/web/constants/hooks";
import { alarmInfoItemType } from "../../types";
import styles from "./editAlarmSheet.module.css";
import { useIntl } from "react-intl-phraseapp";

interface EditAlarmSheetProps {
  isOpen: boolean;
  alarm?: alarmInfoItemType;
  handleClose: () => void;
  handleConfirm: (value: alarmInfoItemType) => void;
  handleDelete: (time_id: string) => void;
}

export function EditAlarmSheet({
  isOpen,
  alarm,
  handleClose,
  handleConfirm,
  handleDelete,
}: EditAlarmSheetProps) {
  const intl = useIntl();
  const { FORM_ERROR_TYPES } = useTextConstants();
  const timeRef = useRef<any>();
  const titleRef = useRef<any>();

  const [title, setTitle] = useState("");
  const [alarm_flg, setAlarm_flg] = useState<boolean>(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (alarm) {
      setTitle(alarm.alarm_title);
      setAlarm_flg(alarm.alarm_flg);
    }
  }, [alarm]);

  useEffect(() => {
    setIsSubmitted(!isOpen);
  }, [isOpen]);

  return (
    <AppFixedActionSheet
      isOpen={isOpen}
      handleClose={handleClose}
      handleConfirm={() => {
        if (alarm) {
          if (title) {
            const data: alarmInfoItemType = {
              ...alarm,
              alarm_flg,
              alarm_clock: timeRef.current.getSelectedData(),
              alarm_title: title,
            };
            handleConfirm(data);
          } else {
            setIsSubmitted(true);
            titleRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }
      }}
      title={intl.formatMessage({
        id: "page.notification_setting.collapse_item.action_sheet.title",
      })}
      scrollable={true}
    >
      <div className={`${styles.scrollable}`}>
        <div className="page-spacer-45" ref={titleRef} />

        <IonLabel className="p-body">
          {intl.formatMessage({ id: "page.notification_setting.collapse_item.action_sheet.name" })}
        </IonLabel>

        <div className="page-spacer-15" />

        <AppInput
          value={title}
          placeholder={intl.formatMessage({
            id: "page.notification_setting.collapse_item.action_sheet.name.placeholder",
          })}
          showBoarder={true}
          onIonChange={(data: any) => {
            setTitle(data.detail.value);
          }}
          error={isSubmitted && !title ? FORM_ERROR_TYPES.REQUIRED : null}
        />

        <div className="page-spacer-45" />

        <IonLabel className="p-body">
          {intl.formatMessage({ id: "page.notification_setting.collapse_item.action_sheet.time" })}
        </IonLabel>

        <div className="page-spacer-15" />

        <AppDoublePicker
          ref={timeRef}
          initVal={alarm?.alarm_clock}
          defaultVal="7:00"
          options={HOUR_KV_OPTIONS}
          options2={MINUTE_STEP_KV_OPTIONS}
          separatedBy=":"
          isLoop={true}
        />

        <div className="page-spacer-45" />

        <div className="flex-row-between">
          <IonLabel className="p-body">
            {intl.formatMessage({
              id: "page.notification_setting.collapse_item.action_sheet.button.snooze",
            })}
          </IonLabel>

          <AppToggleButton checked={!!alarm_flg} onClick={() => setAlarm_flg(!alarm_flg)} />
        </div>

        {alarm?.time_id && (
          <>
            <div className="page-spacer-45" />

            <AppSecondaryButton
              transparent
              full={true}
              onClick={() => {
                alarm?.time_id && handleDelete(alarm?.time_id);
              }}
            >
              {intl.formatMessage({
                id: "page.notification_setting.collapse_item.action_sheet.button.delete",
              })}
            </AppSecondaryButton>
          </>
        )}

        <div className="page-spacer-45" />
      </div>
    </AppFixedActionSheet>
  );
}
