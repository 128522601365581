import React from "react";
import { IonLoading } from "@ionic/react";
import loading from "../../../assets/svgs/loading.svg";
import styles from "./styles.module.css";

interface LoadingProps {
  onDidDismiss?: () => void;
  duration?: number;
  isOpen: boolean;
}

export function AppLoading({ isOpen, duration = 0, onDidDismiss }: LoadingProps) {
  return (
    <div role="loading">
      <IonLoading
        cssClass={styles.loading}
        spinner={null}
        isOpen={isOpen}
        onDidDismiss={() => {
          onDidDismiss && onDidDismiss();
        }}
        message={`<img src="${loading}"></img>`}
        duration={duration}
      />
    </div>
  );
}
