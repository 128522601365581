import { useState, useEffect } from "react";
import { IonPage, IonButton, IonGrid, IonRow, IonCol, IonContent } from "@ionic/react";
import { usePhotoGallery } from "../../hooks/usePhotoGallery";
import { storage } from "../../firebase";
import { Storage } from "@capacitor/storage";
import { ref, listAll, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { AppPrimaryButton } from "../../components/UiComponents";
import { LocalStorageKey, IMAGES_FOLDER } from "../../constants";
import styles from "./basicInfo.module.css";
import { LocalNotifications, LocalNotificationSchema } from "@capacitor/local-notifications";

export function BasicInfo() {
  const { photos, takePhoto, takeGallery } = usePhotoGallery();

  const [imgList, setImgList] = useState<string[]>([]);

  const handleImgUpload = async () => {
    if (photos.length > 0) {
      const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });
      const promises: any[] = [];

      photos.forEach(async (photo) => {
        const imageRef = ref(storage, `${IMAGES_FOLDER}/${userId.value}/${photo.fileName}`);

        const response = await fetch(photo.webviewPath!);
        const blob = await response.blob();

        const uploadTask = uploadBytesResumable(imageRef, blob);
        promises.push(uploadTask);

        uploadTask.on(
          "state_changed",
          ({ bytesTransferred, totalBytes }) => {
            const progress = (bytesTransferred / totalBytes) * 100;
            console.info(progress);
          },
          (error) => {
            console.info(error);
          },
          () => {
            getDownloadURL(imageRef).then((url) => {
              setImgList((prev) => [...prev, url]);
            });
          },
        );
      });

      Promise.all(promises).then(() => {
        console.info("done");
      });
    }
  };

  const listAllImgsOnCloud = async () => {
    const userId = await Storage.get({ key: LocalStorageKey.LOGIN_UID });
    const imageRef = ref(storage, `${IMAGES_FOLDER}/${userId.value}/`);
    listAll(imageRef).then((res) => {
      res.items.forEach((img, index) => {
        getDownloadURL(img).then((url) => {
          setImgList((prev) => [...prev, url]);
        });
      });
    });
  };

  useEffect(() => {
    listAllImgsOnCloud();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className="page-spacer-50" />
        <AppPrimaryButton
          disabled="false"
          small="true"
          onClick={async () => {
            let permission = await LocalNotifications.checkPermissions();
            if (permission.display !== "granted") {
              permission = await LocalNotifications.requestPermissions();
              if (permission.display !== "granted") {
                return;
              }
            }

            let now = new Date();

            let schema: LocalNotificationSchema = {
              title: "朝食後：服薬時間になりましたテスト1",
              body: "服薬が完了したらアプリに登録しましょうテスト1",
              id: 9998,
              schedule: {
                at: new Date(
                  now.getFullYear(),
                  now.getMonth(),
                  now.getDate(),
                  now.getHours(),
                  now.getMinutes() + 1,
                  0,
                ),
              },
            };
            let result = await LocalNotifications.schedule({ notifications: [schema] });

            schema = {
              title: "朝食後：服薬時間になりましたテスト2",
              body: "服薬が完了したらアプリに登録しましょうテスト2",
              id: 9999,
              schedule: {
                at: new Date(
                  now.getFullYear(),
                  now.getMonth(),
                  now.getDate(),
                  now.getHours(),
                  now.getMinutes() + 3,
                  0,
                ),
              },
            };
            result = await LocalNotifications.schedule({ notifications: [schema] });

            schema = {
              title: "朝食後：服薬時間になりましたテストnow",
              body: "服薬が完了したらアプリに登録しましょうテストnow",
              id: 99999,
            };
            result = await LocalNotifications.schedule({ notifications: [schema] });
          }}
        >
          Notifications
        </AppPrimaryButton>

        <div className="page-spacer-50" />

        <div style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}>
          <IonButton onClick={() => takePhoto()}>Open Camera</IonButton>

          <IonButton onClick={() => takeGallery()}>Open Photo</IonButton>
        </div>

        <div className={styles.title}>Local:</div>

        <IonGrid>
          <IonRow>
            {photos.map((photo, index) => (
              <IonCol size="2" key={index}>
                <img src={photo.webviewPath} />
                <div style={{ fontSize: 12 }}>{photo.fileName}</div>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>

        <div className="page-spacer-20" />

        <div style={{ display: "flex", justifyContent: "space-evenly", justifyItems: "center" }}>
          <IonButton onClick={() => handleImgUpload()}>Upload</IonButton>
        </div>

        <div className={styles.title}>Cloud:</div>

        <IonGrid>
          <IonRow>
            {imgList.map((url) => (
              <IonCol size="2" className={styles.imgCol} key={url}>
                <img src={url} />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
