import { useState } from "react";
// import { isPlatform } from "@ionic/react";
import { useDispatch } from "react-redux";
import { Camera, CameraResultType, CameraSource, Photo } from "@capacitor/camera";
import { updatePausePasscodeStatus } from "../actions";
// import { Filesystem, Directory } from "@capacitor/filesystem";
// import { Capacitor } from "@capacitor/core";

export interface UserPhoto {
  fileName: string;
  webviewPath?: string;
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      } else {
        reject("method did not return a string");
      }
    };
    reader.readAsDataURL(blob);
  });
}

export function usePhotoGallery() {
  const dispatch = useDispatch();

  const [photos, setPhotos] = useState<UserPhoto[]>([]);

  // const savePicture = async (photo: Photo, fileName: string): Promise<UserPhoto> => {
  //   let base64Data: string;
  //   if(isPlatform('hybrid')) {
  //     const file = Filesystem.readFile({
  //       path: photo.path!,
  //     })
  //     base64Data = (await file).data
  //   } else {
  //     base64Data = await base64FromPath(photo.webPath!)
  //   }

  //   const saveFile = await Filesystem.writeFile({
  //     path: fileName,
  //     data: base64Data,
  //     directory: Directory.Data,
  //   })

  // if(isPlatform('hybrid')) {
  //   return {
  //     fileName: saveFile.uri,
  //     webviewPath: Capacitor.convertFileSrc(saveFile.uri)
  //   }
  // } else {
  //   return {
  //     fileName: fileName,
  //     webviewPath: photo.webPath
  //   }
  // }
  // }

  const restorePasscodeWhenCloseCamera = async () => {
    dispatch(updatePausePasscodeStatus(false));
  };

  const pausePassCodeWhenUsingCamera = async () => {
    dispatch(updatePausePasscodeStatus(true));
  };

  const takeGallery = async (data?: {
    limit?: number;
    handleUpload?: (uploadPhotoList: any) => void;
  }) => {
    pausePassCodeWhenUsingCamera();
    try {
      const images = await Camera.pickImages({
        quality: 10,
        limit: data?.limit ? data?.limit - photos.length : undefined, // limit flag only available on ios
      });
      const newPhotos = [...photos];

      images.photos.forEach((photo) => {
        if (!data?.limit || (data?.limit > 0 && newPhotos.length < data?.limit + photos.length)) {
          const fileName = `${photo.webPath.split("/").pop()}-${new Date().getTime()}.${
            photo.format
          }`;
          newPhotos.push({
            fileName,
            webviewPath: photo.webPath,
          });
        }
      });

      if (data?.handleUpload) {
        data?.handleUpload(newPhotos);
      } else {
        setPhotos(newPhotos);
      }
    } finally {
      restorePasscodeWhenCloseCamera();
    }
  };

  const takePhoto = async (data?: {
    type?: CameraSource;
    handleUpload?: (uploadPhotoList: any) => void;
  }) => {
    pausePassCodeWhenUsingCamera();
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: data?.type ?? CameraSource.Camera,
        quality: 10,
      });

      const fileName = new Date().getTime() + ".jpeg";
      // const savedFileImage = await savePicture(photo, fileName);
      // const newPhotos = [savedFileImage, ...photos];
      const newPhotos: UserPhoto[] = [
        {
          fileName,
          webviewPath: photo.webPath,
        },
      ];
      // console.info(newPhotos)
      // const base64Data = await base64FromPath(photo.webPath!);
      // console.info(base64Data)
      if (data?.handleUpload) {
        data?.handleUpload(newPhotos);
      } else {
        setPhotos(newPhotos);
      }
    } finally {
      restorePasscodeWhenCloseCamera();
    }
  };

  return {
    photos,
    setPhotos,
    takePhoto,
    takeGallery,
  };
}
