import styles from "./styles.module.css";

interface TagColor {
  textTitle?: string;
  className?: string;
  color:string;
}

export function AppTagColor(props: TagColor) {
  return (
    <div className={`${styles.divTagColorContainer} ion-color-${props.color} div_center ${props.className?props.className:''}`}>
      <div>{props.textTitle}</div>
    </div>
  );
}
