import { useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
import { IonInput, IonLabel } from "@ionic/react";
import { AppIcon } from "../AppIcon/appIcon";
import { ICONS, PICKER_TIMEOUT } from "../../../constants";
import styles from "./appInput.module.css";

export const AppInput = forwardRef((props: any, ref?: any) => {
  const dispatch = useDispatch();

  const size: "full" | "md" | "sm" | "xs" = props.size || "full";

  const [isFocus, setIsFocus] = useState<boolean>(false);

  const inputControl: React.MutableRefObject<any> = useRef();
  useImperativeHandle(ref, () => inputControl.current);

  return (
    <>
      {props.label && (
        <>
          <IonLabel className={styles.input_label}>{props.label}</IonLabel>
          <div className="page-spacer-15" />
        </>
      )}
      <div className={`${styles.inputWrapper} ${props.error ? styles.shake : ""}`}>
        <div className={`${styles.input_div} ${styles[size]}`}>
          <IonInput
            {...props}
            className={`${props.error && styles.input_error} ${
              isFocus || props.showBoarder ? styles.input_focus : styles.input_no_focus
            } ${styles.input} ${props.className ? props.className : ""}`}
            onIonChange={(event) => {
              props.onIonChange && props.onIonChange(event);
            }}
            onIonFocus={(event) => {
              setIsFocus(true);
              props.onIonFocus && props.onIonFocus(event);

              if (props.handleCalendar) {
                setTimeout(() => {
                  props.handleCalendar && props.handleCalendar();
                }, PICKER_TIMEOUT);
              }
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                props.handleEnter && props.handleEnter();
              }
            }}
            onIonBlur={(event) => {
              setIsFocus(false);
              props.onIonBlur && props.onIonBlur(event);
            }}
            ref={inputControl}
            inputmode={props.handleCalendar ? "none" : props.inputmode || undefined}
          />
          {props.handleCalendar ? (
            <AppIcon
              size="sm"
              icon={`${props.value ? ICONS.ICON.CLOSE : ICONS.ICON.CALENDAR}`}
              color="others"
              className={`${props.error && styles.icon_error} 
                ${styles.icon_close_focus} 
                ${styles.icon_close}`}
              onMouseDown={(e) => {
                e.preventDefault();
                // 空値の場合、カレンダーを起動する
                if (!props.value) {
                  if (props.handleCalendar) {
                    setTimeout(() => {
                      props.handleCalendar && props.handleCalendar();
                    }, PICKER_TIMEOUT);
                  }
                  return;
                }

                inputControl.current.value = "";
                // setTimeout(() => inputControl.current.setFocus(), 0);
              }}
            />
          ) : props.handleSearch ? (
            <AppIcon
              size="sm"
              icon={ICONS.ICON.SEARCH}
              className={`${props.error && styles.icon_error} ${
                isFocus && styles.icon_close_focus
              } ${styles.input_icon}`}
              onMouseDown={(e) => {
                e.preventDefault();
                props.handleSearch(inputControl.current.value);
              }}
            />
          ) : (
            <AppIcon
              size="sm"
              icon={ICONS.ICON.CLOSE}
              color="others"
              className={`${props.error && styles.icon_error} ${
                isFocus && styles.icon_close_focus
              } ${styles.icon_close}`}
              onMouseDown={(e) => {
                e.preventDefault();
                inputControl.current.value = "";
                setTimeout(() => inputControl.current.setFocus(), 0);
              }}
            />
          )}
        </div>
        {props.error && (
          <>
            {/* <div className="page-spacer-5"/> */}
            <IonLabel className="p-input-label-error">
              {props.errorMsg || props.error.message}
            </IonLabel>
          </>
        )}
      </div>
    </>
  );
});
