import { useState } from "react";
import { AppTab } from "../../AppTab/appTab";
import { ChartDataTypeV2, LineBarScatterChartV2 } from "src/web/components/Charts";
import { useTextConstants } from "src/web/constants/hooks";

interface WeekScoreChartWithSummaryProps {
  chartData?: ChartDataTypeV2[];
  lineKeys?: { [id: string]: number };
  isNoData: boolean;
  switchTab: (tab: any) => void;
}

export function WeekScoreChartWithSummary({
  chartData,
  lineKeys,
  isNoData,
  switchTab,
}: WeekScoreChartWithSummaryProps) {
  const {
    WEEK_MONTH_CHART_TABS
  } = useTextConstants();
  const [weekTab, setWeekTab] = useState(WEEK_MONTH_CHART_TABS[0].name);

  return (
    <>
      <AppTab tabs={WEEK_MONTH_CHART_TABS} onChange={setWeekTab} />

      <div className="page-spacer-15" />

      <LineBarScatterChartV2
        data={chartData}
        dataNumber={lineKeys}
        scoreType={weekTab}
        isNoData={isNoData}
        switchTab={switchTab}
      />
    </>
  );
}
