import { IonLabel } from "@ionic/react";
import { AppIcon } from "..";
import { ICONS } from "../../../constants";

import styles from "./appArrowSwitcher.module.css";

interface AppArrowSwitcherProps {
  text: string;
  handleLeftClick: () => void;
  handleRightClick: () => void;
  disableLeft?: boolean;
  disableRight?: boolean;
  handleLabelClick?: () => void;
}

export function AppArrowSwitcher({
  text,
  handleLeftClick,
  handleRightClick,
  disableLeft,
  disableRight,
  handleLabelClick,
}: AppArrowSwitcherProps) {
  return (
    <div className="flex-row-start">
      <AppIcon
        icon={ICONS.ICON.ARROW_CIRCLE_BACK}
        size="sm"
        disabled={disableLeft}
        onClick={handleLeftClick}
      />

      <IonLabel
        className={`p-body ${styles.text}`}
        onClick={() => handleLabelClick && handleLabelClick()}
      >
        {text}
      </IonLabel>

      <AppIcon
        icon={ICONS.ICON.ARROW_CIRCLE_FORWARD}
        size="sm"
        disabled={disableRight}
        onClick={handleRightClick}
      />
    </div>
  );
}
