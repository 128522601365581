import { DEFAULT_LANGUAGE } from "src/web/constants";
import { getNoticeList } from "src/web/services";
import { AnouncementType } from "src/web/types";

export function useAnnouncementModel() {
  const fetchAnnouncemenList = async () => {
    try {
      const res: AnouncementType[] = await getNoticeList({
        language: DEFAULT_LANGUAGE,
      });
      return res;
    } catch (error) {
      throw error;
    }
  };

  const fetchAnnouncemenContents = async (id: string) => {
    try {
      const res: AnouncementType[] = await getNoticeList({
        language: DEFAULT_LANGUAGE,
      });
      const anouncementInfo = res.find(
        (anouncement: AnouncementType) => `${anouncement.id}` === id,
      );
      return anouncementInfo;
    } catch (error) {
      throw error;
    }
  };

  return {
    fetchAnnouncemenList,
    fetchAnnouncemenContents,
  };
}
