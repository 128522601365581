import { IonLabel } from "@ionic/react";

interface DialogHeadlineProps {
  body: string;
  header2: string;
}

export function DialogHeadline({ body, header2 }: DialogHeadlineProps) {
  return (
    <>
      <IonLabel className="p-header2 text-center-block pre-wrap">{header2}</IonLabel>

      <div className="page-spacer-15" />

      <IonLabel className="p-body text-center-block pre-wrap">{body}</IonLabel>
    </>
  );
}
