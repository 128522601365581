import { useAppRouter } from "src/web/hooks/useAppRouter";

export function useLoginRedirectControl() {
  const router = useAppRouter();

  const handleNavToCreateNewUser = () => {
    router.push("/create-user-email", "forward", "push");
  };

  const handleNavToForgetPassword = () => {
    router.push("/forget-password", "forward", "push");
  };

  return {
    handleNavToCreateNewUser,
    handleNavToForgetPassword,
  };
}
