import { PageTitleCenter } from "../../moleclues";

interface WelcomeViewProps {
  title: string;
  subTitle: string;
  imgSrc: string;
}

export function WelcomeView({ title, subTitle, imgSrc }: WelcomeViewProps) {
  return <PageTitleCenter title={title} subTitle={subTitle} imgSrc={imgSrc} />;
}
