import { actionConst } from "../constants";

interface Actions {
  type: string;
  address: Date;
}

const initialState: string = "";

export default function newUserEmailAddressReducer(state: string = initialState, actions: Actions) {
  switch (actions.type) {
    case actionConst.UPDATE_NEW_USER_EMAIL_ADDRESS:
      return actions.address;
    case actionConst.CLEAR_NEW_USER_EMAIL_ADDRESS:
      return initialState;
    default:
      return state;
  }
}
