import { useSelector } from "react-redux";
import { IonPage, IonLabel } from "@ionic/react";
import {
  AppHeader,
  AppContent,
  AppLoading,
  AppTitleWithLine,
  AppHomeIndicatorBar,
} from "../../../components/UiComponents";
import { DraggableSettingList } from "./components/DraggableSettingList/draggableSettingList";
import { RootState } from "../../../reducer";
import { useIntl } from "react-intl-phraseapp";

export function SymptomSetting() {
  const symptomSettingData = useSelector((state: RootState) => state.symptomSetting);
  const intl = useIntl();

  return (
    <>
      <IonPage>
        <AppHeader />

        <AppContent>
          <div className="root-content">
            <div className="page-spacer-45" />

            <IonLabel className="p-header1">
              {intl.formatMessage({ id: "page.menu.symptom_setting.title" })}
            </IonLabel>

            <div className="page-spacer-45" />

            <AppTitleWithLine
              title={intl.formatMessage({ id: "page.menu.symptom_setting.toggle.label" })}
            />

            <div className="page-spacer-13" />

            <DraggableSettingList />

            <AppHomeIndicatorBar />
          </div>
        </AppContent>
      </IonPage>

      <AppLoading isOpen={symptomSettingData?.isLoading} />
    </>
  );
}
